const email = (value: string): string | null => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) ||
    value === ''
  ) {
    return null;
  }

  return 'E-mail inválido';
};

export default email;
