import React from 'react';
import useCheckPassword from './hooks/useCheckPassword';
import { Checkbox } from 'vkit/lib/components';
import { GridGroup } from '@/components';

interface CheckPasswordProps {
  password: string;
  onChange?: (isValid: boolean) => void;
}

const CheckPassword: React.FC<CheckPasswordProps> = ({ password = '', onChange }) => {
  const { useChecks, checkPassword } = useCheckPassword({
    password,
    onChange,
  });

  return (
    <GridGroup
      gap={4}
      body={useChecks.map(({ text, ruleName }) => ({
        default: 100,
        middle: 100,
        component: (
          <Checkbox
            small
            rounded
            key={text}
            label={text}
            checked={checkPassword(ruleName, password)}
          />
        ),
      }))}
    />
  );
};

export default CheckPassword;
