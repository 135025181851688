import { Rating } from '@/modules/feedback/entities';
import React, { useMemo } from 'react';
import { Colors } from '@/shareds/types';
import { Button, Icon } from 'vkit/lib/components';

interface RatingItemProps {
  rating: Rating;
  handleSelected: (value: number) => void;
}

const RatingItem: React.FC<RatingItemProps> = ({ rating, handleSelected }) => {
  const setCurrentColor = useMemo((): Colors => {
    return rating.isChecked ? 'warning' : 'light';
  }, [rating.isChecked]);

  return (
    <Button
      solo
      color={setCurrentColor}
      size='xLarger'
      onClick={() => handleSelected(rating.value)}>
      <Icon name='star' color={setCurrentColor} />
    </Button>
  );
};

export default RatingItem;
