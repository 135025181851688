import { Avatar, ButtonDialog } from '@/components';
import { User } from '@/services/users/endpoints/users';
import { decodedJwtToken } from '@/utils/authentication';
import React, { useMemo } from 'react';
import { Grid } from 'vkit/lib/context';

const LoggedUser: React.FC = () => {
  const user = useMemo(() => {
    const data = decodedJwtToken();
    return data.user as User;
  }, []);
  return (
    <Avatar name={user.name}>
      <Grid margin='0 -8px -4px 0'>
        <ButtonDialog
          color='danger'
          label='Sair'
          onClick={() => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('company');
            window.location.replace('/auth/login');
          }}
          size='small'
          solo
          text='Tem certeza de que quer sair?'
        />
      </Grid>
    </Avatar>
  );
};

export default LoggedUser;
