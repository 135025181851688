import { Markup, Over } from '../entities/over';
import { OverModel } from '../entities/over.const';

export const normalizeOver = (data: Markup[]) => {
  return OverModel.map((item: Over) => {
    const currMarkup = data.find((markup: Markup) => {
      const { ageBegin, ageEnd } = markup;
      const fixedId = `${ageBegin}-${ageEnd}`;

      return fixedId === item.id || (ageBegin === 59 && item.id.includes('59'));
    });

    if (currMarkup) {
      return {
        ...item,
        id: currMarkup.id,
        markup: currMarkup.markup,
        hasChange: false,
      };
    }
    return item;
  });
};
