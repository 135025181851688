import { AttendanceCreateFormData } from '../interfaces';

export const ATTENDANCE_FORM_SUBJECT_MAX_LENGTH = 255;
export const ATTENDANCE_FORM_DESCRIPTION_MAX_LENGTH = 32000;
export const ATTENDANCE_FORM_ATTACHMENT_ALLOWED_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'pdf',
  'doc',
  'docx',
  'txt',
  'tsv',
];

export const ATTENDANCE_INITIAL_FORM: AttendanceCreateFormData = {
  subject: '',
  reason: '',
  subReason: '',
  description: '',
};

export const attachmentToCreateErrors = {
  'Invalid file type': 'Arquivo inválido',
  'request entity too large': 'Arquivo muito grande para ser anexado.',
};
