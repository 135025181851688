import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import { Main } from '@/core/views';
import { USER_TYPE } from '@/modules/user';
import CreateOrUpdate from '../pages/createOrUpdate/createOrUpdate';
import HomeUserCompany from '@/modules/user/pages/home/homeUserCompany';
import HomeUserBroker from '@/modules/user/pages/home/homeUserBroker';

const userRouteIcon = 'person-outline';

export enum ROUTE_USER {
  COMPANY_USER = 'company-user',
  BROKER_USER = 'broker-user',
  COMPANY_USER_CREATE = 'company-user-create',
  BROKER_USER_CREATE = 'broker-user-create',
  COMPANY_USER_DETAILS = 'company-user-details',
  BROKER_USER_DETAILS = 'broker-user-details',
}

const route: Route[] = [
  {
    Component: Main,
    path: '/companies/:companyId/users',
    id: ROUTE_USER.COMPANY_USER,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      icon: userRouteIcon,
      nameMenu: 'Usuários',
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.COMPANY,
        icon: userRouteIcon,
        name: 'Usuários',
        title: 'Usuários',
      });
    },
    children: [
      {
        index: true,
        Component: HomeUserCompany,
      },
      {
        id: ROUTE_USER.COMPANY_USER_CREATE,
        path: 'create',
        Component: CreateOrUpdate,
        loader() {
          return {
            name: 'Novo usuário',
            icon: userRouteIcon,
            hasGoBackAction: true,
            title: 'Usuários | Novo',
          };
        },
      },
      {
        id: ROUTE_USER.COMPANY_USER_DETAILS,
        path: ':userId/details',
        Component: CreateOrUpdate,
        loader() {
          return {
            name: 'Detalhes do usuário',
            icon: userRouteIcon,
            hasGoBackAction: true,
            title: 'Usuários | Detalhes',
          };
        },
      },
    ],
  },
  {
    Component: Main,
    path: '/administrators',
    id: ROUTE_USER.BROKER_USER,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.BROKER,
      showApplicationMenu: true,
      icon: userRouteIcon,
      nameMenu: 'Administradores',
      permission: {
        type: USER_TYPE.BROKER,
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.BROKER,
        icon: userRouteIcon,
        name: 'Administradores',
        title: 'Administradores',
      });
    },
    children: [
      {
        index: true,
        Component: HomeUserBroker,
      },
      {
        id: ROUTE_USER.BROKER_USER_CREATE,
        path: 'create',
        Component: CreateOrUpdate,
        loader() {
          return {
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            name: 'Novo usuário',
            icon: userRouteIcon,
            hasGoBackAction: true,
            title: 'Administradores | Novo',
          };
        },
      },
      {
        id: ROUTE_USER.BROKER_USER_DETAILS,
        path: ':userId/details',
        Component: CreateOrUpdate,
        loader() {
          return {
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            name: 'Detalhes do usuário',
            icon: userRouteIcon,
            hasGoBackAction: true,
            title: 'Administradores | Detalhes',
          };
        },
      },
    ],
  },
];

export default route;
