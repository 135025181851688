import { sortBy } from 'lodash';

import { useCallback, useMemo, useState } from 'react';

import DataType from 'vkit/lib/components/webform/select/types/data.type';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import {
  AttendanceCaseAttribute,
  AttendanceReasonAndSubReasonNormalized,
  ControllerValue,
} from '../../entities';
import { AttendanceService } from '../../services';

interface UseReasonAndSubReason {
  reason?: string | null;
}

const attendanceService = new AttendanceService();

const useReasonAndSubReason = ({ reason }: UseReasonAndSubReason) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [reasonOptions, setReasonOptions] = useState<DataType[] | null>(null);
  const [subReasonsController, setSubReasonsController] = useState<ControllerValue>({});
  const [subReasons, setSubReasons] = useState<AttendanceCaseAttribute[]>([]);

  const setReasonsSelectable = useCallback((reasons: AttendanceCaseAttribute[] | null) => {
    const options = reasons?.map((reason) => ({
      ...reason,
      text: reason.label || '',
      value: reason.value || '',
    }));
    setReasonOptions(sortBy(options, ['text']));
  }, []);

  const subReasonOptions = useMemo(() => {
    if (!reason) {
      return null;
    }

    const controllerValue = subReasonsController[reason];

    const options = subReasons
      .filter(({ validFor }) => validFor?.includes(controllerValue))
      .map((subReasons) => ({
        ...subReasons,
        text: subReasons.label || '',
        value: subReasons.value || '',
      }));

    return sortBy(options, ['text']);
  }, [reason, subReasonsController, subReasons]);

  const loadReasonAndSubReason = useCallback(async () => {
    setLoading(true);

    try {
      const { reason, subReasonsController, subReason } =
        await attendanceService.getReasonsAndSubReasons<AttendanceReasonAndSubReasonNormalized>();

      setReasonsSelectable(reason);
      setSubReasonsController(subReasonsController || {});
      setSubReasons(subReason || []);
    } catch (e) {
      console.error(e);

      dispatchToast({
        text: 'Ops! Devido a um erro, não conseguimos buscar os motivos de atendimento.',
      });
    } finally {
      setLoading(false);
    }
  }, [setReasonsSelectable]);

  return {
    isLoading,
    loadReasonAndSubReason,
    reasonOptions,
    subReasonOptions,
  };
};

export default useReasonAndSubReason;
