import { classesBuilder } from '@/utils';
import { capitalize } from 'lodash';
import React from 'react';
import style from './tooltip.module.scss';

interface TooltipProps {
  children: React.ReactNode;
  title?: string;
  align?: 'left' | 'right' | 'center';
  position?: 'top' | 'bottom';
}

const Tooltip: React.FC<TooltipProps> = ({ children, title, align, position = 'top' }) => (
  <div
    className={classesBuilder(style, {
      tooltip: true,
      [`align${capitalize(align)}`]: align,
      [`position${capitalize(position)}`]: position,
    })}>
    {title && (
      <div className={style.baseTitle}>
        <div className={style.limitTitle}>
          <div className={style.title}>{title}</div>
        </div>
      </div>
    )}
    {children}
  </div>
);

export default Tooltip;
