import { Divider, ShowHide } from '@/components';
import { Main } from '@/core/views';
import { useHistoryLocation, useHistoryQuery } from '@/navigation';
import React from 'react';

import { Navigator } from '../../components';
import useFilesCategories from '../../hooks/useFilesCategories/useFilesCategories';

const Home: React.FC = () => {
  const { idCategory } = useHistoryQuery();
  const { pathname } = useHistoryLocation();

  const { breadcrumbCategories } = useFilesCategories({
    selectedCategory: idCategory || null,
    isUploadPage: idCategory && pathname.includes('upload'),
  });

  return (
    <Main>
      <ShowHide transition='slideToDown' visible={!!idCategory}>
        <Divider space={1} />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={!!idCategory}>
        <Navigator navigation={breadcrumbCategories} />
      </ShowHide>
    </Main>
  );
};

export default Home;
