import { BoxInfo, GridGroup } from '@/components';
import { EventLogParams } from '@/modules/audit/entities';
import { SelectCompany } from '@/modules/company/views';
import SelectUser from '@/modules/user/components/selectUser/selectUser';
import { useHistoryQuery } from '@/navigation';
import React, { useMemo } from 'react';
import { TextField } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import SelectOptions from '../selectOptions/selectOptions';
import SelectRangeDate, { RangeDate } from '../selectRangeDate/selectRangeDate';

interface FiltersProps {
  filters?: EventLogParams;
  handleChangeFilters?: (params: EventLogParams) => void;
}

const Filters: React.FC<FiltersProps> = ({ filters, handleChangeFilters }) => {
  const { companyId } = useHistoryQuery();

  const useDateRangeValues = useMemo(
    () => ({
      min: filters?.['eventTime.GTE'] || '',
      max: filters?.['eventTime.LTE'] || '',
    }),
    [filters],
  );

  const handleChangeFilter = (key: keyof EventLogParams, value: string) => {
    handleChangeFilters?.({
      ...filters,
      [key]: value,
      page: 1,
    });
  };

  const onChangeRangeDate = ({ min, max }: RangeDate) => {
    handleChangeFilters?.({
      ...filters,
      'eventTime.LTE': max,
      'eventTime.GTE': min,
      page: 1,
    });
  };

  return (
    <BoxInfo>
      <Grid column gap={24}>
        <Row style={{ padding: 0 }}>
          <Grid gap={24}>
            <SelectUser
              small
              label='Usuário'
              selectedUserId={filters?.userId}
              onChangeSelectedUser={(value) => handleChangeFilter('userId', value)}
            />
            <SelectCompany
              small
              label='Empresa'
              selectedCompanyId={filters?.companyId || companyId}
              onChangeSelectedCompany={(value) => handleChangeFilter('companyId', value)}
            />
            <SelectOptions
              endpoint='/event-log-actions'
              small
              label='Ação'
              placeholder='Filtre por ação'
              value={filters?.eventLogActionId}
              onChangeValue={(value) => handleChangeFilter('eventLogActionId', value)}
            />
          </Grid>
        </Row>
        <Row style={{ padding: 0 }}>
          <GridGroup
            body={[
              {
                default: 25,
                middle: 25,
                component: (
                  <SelectOptions
                    endpoint='/event-log-entities'
                    small
                    label='Entidade'
                    placeholder='Filtre por entidade'
                    value={filters?.eventLogEntityId}
                    onChangeValue={(value) => handleChangeFilter('eventLogEntityId', value)}
                  />
                ),
              },
              {
                default: 25,
                middle: 25,
                component: (
                  <TextField
                    small
                    clearable
                    label='Id da entidade'
                    value={filters?.entityRecordId}
                    onChange={(value) => handleChangeFilter('entityRecordId', value)}
                  />
                ),
              },
              {
                default: 50,
                middle: 50,
                component: (
                  <SelectRangeDate
                    small
                    labelMin='Data inicial'
                    labelMax='Data final'
                    values={useDateRangeValues}
                    onChange={onChangeRangeDate}
                  />
                ),
              },
            ]}
          />
        </Row>
      </Grid>
    </BoxInfo>
  );
};

export default Filters;
