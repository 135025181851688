import formatText from '@/utils/formatText';
import { useMemo } from 'react';

import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

import { getFormattedDate } from '@/utils/format';

import { COMPANY_COLUMNS } from '@/modules/company/entities/company.enum';
import { Company } from '@/modules/company/entities/company.type';
import RedirectCompanyLink from '../components/redirectCompanyLink/redirectCompanyLink';

interface UseCompanyColumns {
  columns?: COMPANY_COLUMNS[];
}

const useCompanyColumns = ({ columns }: UseCompanyColumns) => {
  const AVALIBLE_COLUMNS: Record<COMPANY_COLUMNS, DataTableColumn> = useMemo(
    () => ({
      [COMPANY_COLUMNS.NAME]: {
        name: 'NOME',
        path: '',
        format: (company: Company) => <RedirectCompanyLink company={company} />,
      },
      [COMPANY_COLUMNS.SUPPORT_NAME]: {
        name: 'EXECUTIVO DE RELACIONAMENTO',
        path: 'supportName',
      },
      [COMPANY_COLUMNS.CNPJ]: {
        name: 'CNPJ',
        path: 'cnpj',
        format: ({ cnpj }: Company) => formatText(cnpj, 'cnpj'),
      },
      [COMPANY_COLUMNS.CREATED_AT]: {
        name: 'CRIADA EM',
        path: 'createdAt',
        minWidth: '180px',
        format: ({ createdAt }: Company) =>
          createdAt ? getFormattedDate(createdAt, { showHours: true }) : null,
      },
    }),
    [],
  );

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  return {
    useColumns,
  };
};

export default useCompanyColumns;
