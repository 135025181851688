import { BoxInfo, TextData } from '@/components';
import React from 'react';
import { ADDRESS_LABEL, Address } from '@/shared/modules/address';

interface DisplayAddressProps {
  title: string;
  icon: string;
  address?: Address;
  isLoading: boolean;
}

const Display: React.FC<DisplayAddressProps> = ({ title, icon, isLoading, address }) => {
  return (
    <BoxInfo title={title} icon={icon}>
      <TextData
        loading={isLoading}
        data={[
          {
            label: ADDRESS_LABEL.ZIPCODE,
            text: address?.zipCode || '-',
            type: 'cep',
            sizeMobile: 100,
            sizeMedium: 50,
          },
          {
            label: ADDRESS_LABEL.LOCATION,
            text: address?.location || address?.street || '-',
            sizeMobile: 100,
            sizeMedium: 50,
            sizeDefault: 50,
          },
          {
            label: ADDRESS_LABEL.NUMBER,
            text: address?.number || '-',
            sizeMobile: 33.33,
            sizeMedium: 20,
            sizeDefault: 16.66,
          },
          {
            label: ADDRESS_LABEL.NEIGHBORHOOD,
            text: address?.neighborhood || '-',
            sizeMobile: 66.66,
            sizeMedium: 40,
            sizeDefault: 40,
          },
          {
            label: ADDRESS_LABEL.CITY,
            text: address?.city || '-',
            sizeMobile: 50,
            sizeMedium: 40,
            sizeDefault: 40,
          },
          {
            label: ADDRESS_LABEL.STATE_ABBR,
            text: address?.state || '-',
            sizeMobile: 50,
            sizeMedium: 30,
            sizeDefault: 20,
          },
          {
            label: ADDRESS_LABEL.COMPLEMENT,
            text: address?.complement || '-',
            sizeMobile: 100,
            sizeMedium: 70,
            sizeDefault: 100,
          },
        ]}
      />
    </BoxInfo>
  );
};

export default Display;
