const phoneNumber = (value: string): string | null => {
  if (value === '') {
    return null;
  }

  const onlyNumbers = value ? value.replace(/(^55|\D)/g, '') : '';

  if ([9, 10].includes(onlyNumbers.length)) {
    return null;
  }

  return 'Telefone inválido';
};

export default phoneNumber;
