import React from 'react';

import { AttendanceTable } from '../../entities';
import { Chip } from 'vkit/lib/components';
import { STATUS, RH_STATUS_MAP, STATUS_COLOR } from '../../entities/status';

interface AttendancesStatusProps {
  attendance: AttendanceTable;
}

const AttendanceStatus: React.FC<AttendancesStatusProps> = ({ attendance }) => {
  const status = attendance?.status as STATUS;
  return (
    <Chip
      size='medium'
      label={RH_STATUS_MAP[status]}
      rounded
      color={STATUS_COLOR[status] ?? 'light'}
      invertColor={STATUS_COLOR[status] === 'colorDefault'}
    />
  );
};
export default AttendanceStatus;
