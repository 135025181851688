export interface Rating {
  type: string;
  isChecked: boolean;
  value: number;
}

export const InitialRating: Rating[] = [
  {
    type: 'FEEDBACK_RATING_1_STAR',
    isChecked: false,
    value: 1,
  },
  {
    type: 'FEEDBACK_RATING_2_STAR',
    isChecked: false,
    value: 2,
  },
  {
    type: 'FEEDBACK_RATING_3_STAR',
    isChecked: false,
    value: 3,
  },
  {
    type: 'FEEDBACK_RATING_4_STAR',
    isChecked: false,
    value: 4,
  },
  {
    type: 'FEEDBACK_RATING_5_STAR',
    isChecked: false,
    value: 5,
  },
];
