import { BodyForm } from '@/components/formValidate';
import React, { useRef, useEffect, useState } from 'react';
import { Dialog, Text, Button, TextArea } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { FormValidate, ToastStatusGlobal } from '@/components';
import { StatusEnum } from 'vkit/lib/components/webform/builder/enums';
import { FormValidateHandler } from '@/components/formValidate/types/formValidate.type';
import { MoveStatusDialogConfirmProps, MoveStatusDialog } from '@/modules/move/entities';

const DialogConfirmUpdateStatusForm: React.FC<MoveStatusDialogConfirmProps> = ({
  open,
  fnCallback,
}) => {
  const MAX_LENGTH_REASON = 1000;

  const { toast } = ToastStatusGlobal();
  const dialogRef = useRef({ open: () => ({}), dismiss: () => ({}) });
  const formRef = useRef<FormValidateHandler>(null);

  const [formData, setFormData] = useState<MoveStatusDialog>({
    reason: '',
  });

  useEffect(() => {
    if (open !== undefined) {
      setFormData({ reason: '' });
      open ? dialogRef.current.open() : dialogRef.current.dismiss();
    }
  }, [open]);

  const getFields = ({ onBlur, onChange, useErrors, useValues }: BodyForm) => (
    <TextArea
      rows={3}
      required
      label='Motivo'
      maxLength={MAX_LENGTH_REASON}
      value={useValues.reason}
      textHelper={useErrors.reason}
      onChange={(value) => {
        onChange('reason', value.target.value);
      }}
      onBlur={(value) => {
        onBlur('reason', value.target.value);
      }}
      status={useErrors.reason ? StatusEnum.error : undefined}
    />
  );

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent
      instanceEmitter={dialogRef.current}
      elevation={2}
      size='larger'
      title={<Text value='Motivo da rejeição' title size='medium' textAlign='left' iconSize={20} />}
      body={
        <div>
          <Text
            applyBaseColor
            rounded
            padding='8px'
            color='warning'
            size='medium'
            value='Ao alterar o status para <b>Rejeitado</b> você não poderá alterá-lo novamente. Quer mesmo fazer isso?'
            margin='0 0 16px'
          />

          <Text
            size='medium'
            value={
              'Insira o motivo da rejeição dessa movimentação para que o profissional de RH entenda o que está acontecendo.'
            }
            margin='0 0 16px'
          />

          <FormValidate
            ref={formRef}
            resource={formData}
            fields={{
              reason: ['required'],
            }}
            onChangeForm={setFormData}
            onError={() =>
              toast(
                'Puxa!',
                'Preencha o campo de motivo da rejeição dessa movimentação.',
                'warning',
              )
            }
            onSubmit={() => fnCallback?.({ confirm: true, reason: formData.reason })}
            body={getFields}
          />
        </div>
      }
      footer={
        <Grid alignContent='right' gap={8}>
          <Button
            label='Deixa pra lá'
            solo
            color='light'
            onClick={() => fnCallback?.({ confirm: false, reason: '' })}
          />
          <Button label='Alterar status' color='danger' onClick={() => formRef.current?.submit()} />
        </Grid>
      }
    />
  );
};

export default DialogConfirmUpdateStatusForm;
