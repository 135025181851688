import { TextFieldValidate } from '@/components';
import React from 'react';
import { ObjectType } from '@/shareds/types';
import { Grid } from 'vkit/lib/context';

export interface RangeDate {
  min?: string;
  max?: string;
}

interface SelectRangeDateProps {
  small?: boolean;
  labelMin?: string;
  labelMax?: string;
  values: RangeDate;
  onChange: (range: RangeDate) => void;
}

const SelectRangeDate: React.FC<SelectRangeDateProps> = ({
  small,
  labelMin,
  labelMax,
  values,
  onChange,
}) => {
  const handleChange = (name: string, value: string) => {
    onChange({
      ...values,
      [name]: value,
    });
  };

  return (
    <Grid gap={24}>
      <TextFieldValidate
        small={small}
        name='min'
        label={labelMin}
        useValues={values as unknown as ObjectType}
        onChange={handleChange}
        type='date'
      />
      <TextFieldValidate
        small={small}
        name='max'
        label={labelMax}
        useValues={values as unknown as ObjectType}
        onChange={handleChange}
        type='date'
      />
    </Grid>
  );
};

export default SelectRangeDate;
