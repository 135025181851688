import { TextData } from '@/components';
import React from 'react';
import {
  CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED,
  CadastralDataMatching,
  DEFAULT_EMPTY_CADASTRAL_DATA_MATCHING,
} from '../../entities';

interface InformationDataProps {
  loading: boolean;
  data: CadastralDataMatching;
}

export const InformationData: React.FC<InformationDataProps> = ({
  loading,
  data = DEFAULT_EMPTY_CADASTRAL_DATA_MATCHING,
}) => {
  return (
    <TextData
      loading={loading}
      data={[
        {
          label: 'Nome do arquivo',
          text: data.name,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Código do arquivo',
          text: data.id,
          sizeDefault: 50,
          sizeMedium: 100,
        },
        {
          label: 'Status',
          text: CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED[data.status],
          sizeDefault: 25,
          sizeMedium: 100,
        },
        {
          label: 'Quantidade de beneficiários',
          text: data.lines.toString(),
          sizeDefault: 25,
          sizeMedium: 100,
        },
        {
          label: 'Formato do arquivo',
          text: data.extension,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Criado por',
          text: data.user?.name || undefined,
          sizeDefault: 75,
          sizeMedium: 100,
        },
        {
          label: 'Criada em',
          text: data.uploadedAt,
          type: 'dateTime',
          sizeDefault: 25,
          sizeMedium: 100,
        },
        {
          hidden: !data.rejectionReason,
          label: 'Motivo da rejeição',
          text: data.rejectionReason || undefined,
          sizeDefault: 100,
          sizeMedium: 100,
        },
      ]}
    />
  );
};

export default InformationData;
