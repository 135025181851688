import { useValidateType } from '@/components/formValidate/types/formValidate.type';

export const doValidates = async (
  name: string,
  value: string = '',
  validatesByField: useValidateType = {},
): Promise<string | null> => {
  if (!validatesByField[name]) {
    return null;
  }

  for (const validate of validatesByField[name]) {
    const error = await validate(value);
    if (error) {
      return error;
    }
  }

  return null;
};
