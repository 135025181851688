import { IconBenefit } from '@/components';
import { useCountersBeneficiary } from '@/modules/beneficiary/hooks';
import { Benefit } from '@/modules/benefit/entities';
import { Negotiation } from '@/modules/negotiation';
import { useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { BenefitSlugType } from '@/shareds/types/beneficary.type';
import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import slugify from 'slugify';
import { Text } from 'vkit/lib/components';
import { Box, Grid, Row } from 'vkit/lib/context';

export interface CardProps {
  benefit?: Benefit;
  negotiations?: Negotiation[];
}

const Card: React.FC<CardProps> = ({ benefit, negotiations }) => {
  const { company } = useCompany();
  const { counters, loadCounters, isLoadingCounter } = useCountersBeneficiary();
  const providers = useMemo(() => benefit?.providers || [], [benefit]);
  const benefitSlug = useMemo(() => slugify(benefit?.name || '', { lower: true }), [benefit]);
  const providerTitle = useMemo(
    () => (providers.length > 1 ? 'Fornecedores' : 'Fornecedor'),
    [providers],
  );
  const firstProvider = useMemo(
    () => providers[0]?.name,
    [providers],
  );
  const navigate = useHistoryNavigator();

  const loading = useMemo(
    () => isLoadingCounter || !Boolean(benefit) || !Boolean(negotiations),
    [benefit, isLoadingCounter, negotiations],
  );

  const useCountLives = useMemo(() => {
    if ((counters?.lives || counters.lives === 0) && !loading) {
      return `${counters.lives.toString()} vidas`;
    }
    return '';
  }, [counters.lives, loading]);

  const beneficiaryQueryStringFilter = useMemo(() => {
    return qs.stringify(
      {
        page: 1,
        pageSize: 10,
        status: 'ACTIVE',
        showMoreFilters: true,
        benefit: benefit?.id,
        negotiationId: negotiations?.map((negotiation) => negotiation.id),
      },
      { arrayFormat: 'brackets', allowDots: true },
    );
  }, [benefit?.id, negotiations]);

  useEffect(() => {
    if (!company.id || !benefit?.id) {
      return;
    }
    loadCounters(company.id, {
      benefitId: benefit?.id,
      status: 'ACTIVE',
    });
  }, [benefit?.id, company.id, loadCounters]);

  return (
    <Box
      type='card'
      rounded
      outlined
      onClick={
        benefit
          ? () => {
              navigate.push(`beneficiaries?${beneficiaryQueryStringFilter}`);
            }
          : undefined
      }>
      <Row style={{ gap: '16px' }}>
        <Grid alignItems='top'>
          <IconBenefit benefitSlug={loading ? undefined : (benefitSlug as BenefitSlugType)} />

          <Grid column alignItems='top' gap={4} growing>
            <Text rounded value={loading ? '' : benefit?.name || ''} title size='medium' />
            <Text size='larger' rounded value={useCountLives} />
          </Grid>
        </Grid>
        <Grid margin='0' column>
          <Text
            margin='0 0 4px 0'
            rounded
            value={providers && !loading ? `<strong>${providerTitle}: </strong>` : ''}
            size='medium'
          />

          <Grid
            wrap
            gap={8}
            alignItems='center'
          >
            <Text rounded value={providers && !loading ? firstProvider : ''} size='medium' />
            {
              !loading && providers.length > 1 && (
                <a
                  onClick={ (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    navigate.push(`/companies/${ company.id }/contracts`);
                  } }
                >
                  <Text color='colorDefault' value='Ver mais' size='medium' />
                </a>
              )
            }
          </Grid>
        </Grid>
      </Row>
    </Box>
  );
};

export default Card;
