import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';
import { LIFE_DOCUMENT_TYPE, LifeDocument } from '@/services/beneficiaries/endpoints/lifeDocuments';
import { get } from 'lodash';

export type LifeDocumentByType = { [K in LIFE_DOCUMENT_TYPE]: string };

export const extractDocumentsFromBeneficiary = (beneficiary: Beneficiary) =>
  Object.values(LIFE_DOCUMENT_TYPE).reduce((acc, type) => {
    const lifeDocumentFound: LifeDocument | undefined = get(beneficiary, 'life.documents', []).find(
      (beneficiaryDocument: LifeDocument) => beneficiaryDocument.type === type,
    );

    return {
      ...acc,
      [type]: lifeDocumentFound?.value,
    };
  }, {}) as LifeDocumentByType;
