import ICONS from '@/shareds/constants/icons';
import React, { ReactNode } from 'react';

import BoxInfo from '@/components/boxInfo';

import Empty, { EmptyProps } from './components/empty/empty';
import Group, { GroupProps } from './components/group/group';
import Loading, { LoadingProps } from './components/loading/loading';

interface ReportProps extends LoadingProps, EmptyProps, Omit<GroupProps, 'contentLinesLoader'> {
  icon?: string;
  title?: string;
  header?: ReactNode;
}

const Report: React.FC<ReportProps> = ({
  icon = ICONS.FILE_VALIDATION,
  title = 'Validação do arquivo',
  header,

  isLoadingReport,
  numberLinesLoader = 5,

  isEmptyReport,
  emptyTitle,
  emptyMessage,

  showPaginationReport,
  handleChangePageSize,
  handleChangePage,
  showEmptyList,

  data,
  isExpandedGroup,

  showGroupMarker,
  showGroupMarkerWhenKey,
  groupMarkerColor,
  groupMarkerText,

  columns,
  handleExpandReportGroup,
}) => {
  return (
    <BoxInfo icon={icon} title={title} header={header}>
      <Loading isLoadingReport={isLoadingReport} numberLinesLoader={numberLinesLoader} />

      <Empty isEmptyReport={isEmptyReport} emptyTitle={emptyTitle} emptyMessage={emptyMessage} />

      {data?.length && !isLoadingReport && (
        <Group
          data={data}
          isExpandedGroup={isExpandedGroup}
          showGroupMarkerWhenKey={showGroupMarkerWhenKey}
          showGroupMarker={showGroupMarker}
          showEmptyList={showEmptyList}
          groupMarkerColor={groupMarkerColor}
          groupMarkerText={groupMarkerText}
          columns={columns}
          contentLinesLoader={numberLinesLoader}
          handleExpandReportGroup={handleExpandReportGroup}
          showPaginationReport={showPaginationReport}
          handleChangePageSize={handleChangePageSize}
          handleChangePage={handleChangePage}
        />
      )}
    </BoxInfo>
  );
};

export default Report;
