import FinancialAdjustmentCreate from '@/modules/financialAdjustment/views/financialAdjustmentCreate';
import { useHistoryParams } from '@/navigation';
import React from 'react';

const Create: React.FC = () => {
  const { companyGroupId } = useHistoryParams();

  return <FinancialAdjustmentCreate companyGroupId={companyGroupId} />;
};

export default Create;
