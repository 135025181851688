import { ObjectType } from '@/shareds/types';
import { debounce } from 'lodash';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

type useDataListType = {
  loadData?: Function;
  totalPages?: number;
  initialValues?: ObjectType;
};

const useDataList = ({
  loadData,
  totalPages,
  initialValues = {},
}: useDataListType): {
  onChangeFilter: Function;
  onSearch: (newFilters: ObjectType) => void;
  setFilters: Function;
  setListBlur: Function;
  setPaginate: Function;
  useFilters: ObjectType;
  useListBlur: boolean;
  useListRef: MutableRefObject<HTMLDivElement | null>;
  usePagination: ObjectType;
} => {
  const paginationInitial = useMemo(
    () => ({
      page: 1,
      pageSize: 10,
      total: 1,
      totalPages: 1,
    }),
    [],
  );
  const [useListBlur, setListBlur] = useState(false);
  const [useFilters, setFilters] = useState<ObjectType>(initialValues);
  const useListRef = useRef(null);
  const useInitialValuesRef = useRef(initialValues);
  const [usePagination, setPagination] = useState(paginationInitial);

  const setPaginate = (option: ObjectType): void => {
    const pagination = {
      ...usePagination,
      ...option,
    };

    setPagination(pagination);

    loadData?.({
      ...useFilters,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  };

  const onChangeFilter = (paramsFilter: ObjectType): void => {
    const paramsPaginate = {
      page: 1,
      pageSize: usePagination.pageSize,
    };

    const pagination = {
      ...usePagination,
      ...paramsPaginate,
    };

    setFilters(paramsFilter);

    setPagination(pagination);

    loadData?.({
      ...paramsFilter,
      ...paramsPaginate,
    });
  };

  const onSearch = debounce(
    (newFilters: ObjectType) => onChangeFilter({ ...useFilters, ...newFilters }),
    500,
  );

  useEffect(() => {
    loadData?.({
      ...useInitialValuesRef.current,
      page: paginationInitial.page,
      pageSize: paginationInitial.pageSize,
    });
  }, [loadData, paginationInitial]);

  useEffect(() => {
    setPagination((pagination) => ({
      ...pagination,
      total: (totalPages || 0) * usePagination.pageSize,
    }));
  }, [totalPages, usePagination.pageSize]);

  return {
    onChangeFilter,
    onSearch,
    setFilters,
    setListBlur,
    setPaginate,
    useFilters,
    useListBlur,
    useListRef,
    usePagination,
  };
};

export default useDataList;
