import { ButtonActions } from '@/components';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { analytics } from '@/shared/telemetry/analytics/analytics';
import React from 'react';
import { Button } from 'vkit/lib/components';

const ButtonInsights: React.FC = () => {
  const { getAllCompanyConfigsByKey } = useCompany();
  const externalLinkInsights = getAllCompanyConfigsByKey(COMPANY_CONFIG.EXTERNAL_LINK_INSIGHTS);

  const hasExternalLinkInsights = externalLinkInsights.length > 0;

  const renderInsightsAction = () => {
    if (externalLinkInsights.length === 1) {
      const { label, link } = JSON.parse(externalLinkInsights[0]);
      return (
        <Button
          label={label}
          onClick={() => {
            analytics.track('INSIGHTS_ACCESS', { label: label });
            window.open(link || '', 'new');
          }}
        />
      );
    }
    return (
      <ButtonActions
        invertColor
        color='default'
        label='Acessar Insights'
        actionOptions={externalLinkInsights.map((externalLink) => {
          const { label, link } = JSON.parse(externalLink);
          return {
            label,
            onClick: () => {
              analytics.track('INSIGHTS_ACCESS', { label: label });
              window.open(link || '', 'new');
            },
          };
        })}
      />
    );
  };

  return hasExternalLinkInsights ? renderInsightsAction() : null;
};

export default ButtonInsights;
