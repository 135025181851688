import { FormValidate, GridGroup, PinCode, ShowHide, Space, TextFieldValidate } from '@/components';
import useAuthLogin from '@/components/forms/auth/login/hooks/useAuthLogin';
import { BodyForm } from '@/components/formValidate';
import { AUTH_STEP } from '@/modules/authentication/types/authSteps.enum';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import useAuthLoginCountdown from './hooks/useAuthLoginCountdown';

interface AuthLoginProps {
  description: string;
  onLogin?: () => void;
}

const AuthLogin: React.FC<AuthLoginProps> = ({ description, onLogin }) => {
  const [pinCodeKey, setPinCodeKey] = useState(0);
  const reloadPinCode = () => {
    setPinCodeKey(pinCodeKey + 1);
  };
  const {
    isCodeValid,
    login,
    loginCheck,
    setCurrentStep,
    setData,
    setMessageError,
    useData,
    useLoading,
    useMessageError,
    useCurrentStep,
  } = useAuthLogin();
  const { seconds, isActive, startCountdown, isCountDownActive } = useAuthLoginCountdown({});

  const handleClickResendCode = async () => {
    if (isCountDownActive) {
      return;
    }
    try {
      reloadPinCode();
      startCountdown();
      await loginCheck();
    } catch (e) {
      console.error(e);
    }
  };

  const resendButtonLabel = useMemo(() => {
    return seconds > 0 ? `Reenviar código em ${seconds} segundos` : 'Reenviar código';
  }, [seconds]);

  useEffect(() => {
    if (useCurrentStep === AUTH_STEP.OTP && !isActive) {
      startCountdown();
    }
  }, [isActive, startCountdown, useCurrentStep]);

  const getCredentialFields = ({ onChange, useErrors, onBlur, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          middle: 100,
          default: 100,
          component: (
            <Text
              size='medium'
              textAlign='center'
              value={description}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              icon='email-outline'
              label='E-mail'
              name='email'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Informe seu e-mail'
              required
              useErrors={useErrors}
              useValues={useValues}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              header={
                <Link to='/auth/forgot-password'>
                  <Text size='small' color='colorDefault' value='Esqueceu sua senha?' />
                </Link>
              }
              icon='lock-outline'
              label='Senha'
              name='password'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Digite uma senha'
              required
              type='password'
              useErrors={useErrors}
              useValues={useValues}
            />
          ),
        },
      ]}
    />
  );

  const getCodeField = ({ onChange, useErrors }: BodyForm) => (
    <GridGroup
      body={[
        {
          middle: 100,
          default: 100,
          component: (
            <>
              <div style={{ maxWidth: '71.2%', margin: 'auto' }}>
                <Text
                  size='larger'
                  textAlign='justify'
                  value={'Por favor, digite o código que enviamos agora para o e-mail:'}
                />
              </div>

              <Space horizontal height={28} />
              <Text
                color='colorDefault'
                size='xLarger'
                fontWeight='bold'
                textAlign='center'
                value={useData?.email}
              />
              <Space horizontal height={4} />
            </>
          ),
        },
        {
          middle: 100,
          default: 100,
          component: (
            <PinCode
              label={!!useMessageError ? 'Código inválido' : 'Código'}
              digits={6}
              onChange={(code: string) => onChange('otp', code)}
              errorMessage={useErrors.otp}
              required
              labelError={!!useMessageError}
              key={pinCodeKey}
            />
          ),
        },
        {
          middle: 100,
          default: 100,
          component: (
            <ShowHide transition='slideToDown' visible={!!useMessageError}>
              <Text
                margin='-8px 0 4px 0'
                value={useMessageError}
                color='danger'
                rounded
                size='medium'
              />
            </ShowHide>
          ),
        },
      ]}
    />
  );

  return (
    <div
      style={{
        maxWidth: 400,
        width: '100%',
      }}>
      <ShowHide transition='slideToDown' visible={useCurrentStep === AUTH_STEP.EMAIL_PASSWORD}>
        <FormValidate
          resource={useData}
          fields={{
            email: ['required', 'email'],
            password: ['required'],
          }}
          onChangeForm={setData}
          onError={() => setMessageError('Existem alguns campos com preenchimento irregular.')}
          onSubmit={loginCheck}
          // https://vitta-dev.atlassian.net/browse/HEF-1563 task para estudar como resolver este problema.
          // até lá, a desativação do sonar para este formulário deve continuar.
          body={getCredentialFields}
          footer={
            <Row style={{ padding: '32px 0 0' }}>
              <Button submit full bold loading={useLoading} color='colorDefault'>
                <Row>Próximo</Row>
              </Button>
            </Row>
          }
        />
      </ShowHide>
      <ShowHide transition='slideToDown' visible={useCurrentStep === AUTH_STEP.OTP}>
        <FormValidate
          resource={useData}
          fields={{
            otp: ['required', isCodeValid],
          }}
          onChangeForm={setData}
          onError={() => setMessageError('Existem alguns campos com preenchimento irregular.')}
          onSubmit={() => login(onLogin)}
          body={getCodeField}
          footer={
            <Row style={{ padding: '16px 0 0 0' }}>
              <Button submit full bold loading={useLoading}>
                <Row>Acessar portal</Row>
              </Button>
              <Space horizontal height={16} />
              <Button
                full
                bold
                invertColor
                disabled={useLoading}
                onClick={() => {
                  setData({});
                  setCurrentStep('emailPassword');
                }}>
                <Row>Acessar com outro e-mail ou senha</Row>
              </Button>
              <Space horizontal height={28} />
              <Grid gap={8} alignItems='center' alignContent='center'>
                <Text size='larger' textAlign='center' value={'Não recebeu o código por e-mail?'} />
              </Grid>
              <Space horizontal height={4} />
              <Row
                style={{
                  padding: 0,
                  margin: 0,
                  pointerEvents: isCountDownActive ? 'none' : 'auto',
                }}>
                <Button
                  key={seconds}
                  label={resendButtonLabel}
                  invertColor
                  solo
                  size='medium'
                  onClick={handleClickResendCode}
                />
              </Row>
            </Row>
          }
        />
      </ShowHide>
      <ShowHide
        transition='slideToDown'
        visible={!!useMessageError && useCurrentStep === AUTH_STEP.EMAIL_PASSWORD}>
        <Text
          value={useMessageError}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          margin='16px 0 0 '
          size='medium'
        />
      </ShowHide>
    </div>
  );
};

export default AuthLogin;
