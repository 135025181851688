export enum CADASTRAL_POSITION_LIST_FILTERS {
  SEARCH = 'search',
  STATUS = 'status',
  CREATED_AT_MIN = 'createdAtMin',
  CREATED_AT_MAX = 'createdAtMax',
  COMPANIES = 'companies',
}

export enum CADASTRAL_POSITION_STATUS {
  NOT_INITIALIZED = 'not-initialized',
  IN_PROCESS = 'in-process',
  PROCESS_ERROR = 'process-error',
  PROCESS_PROVIDER_ERROR = 'process-provider-error',
  PROCESSED = 'processed',
  REJECTED = 'rejected',
}

export enum CADASTRAL_POSITION_COLUMNS {
  FILE_NAME = 'file-name',
  STATUS = 'status',
  UPLOADED_AT = 'uploaded-at',
  CREATED_AT = 'created-at',
  COMPANY_NAME = 'company-name',
}

export enum CADASTRAL_POSITION_LIST_ACTIONS {
  DETAILS = 'details',
  DOWNLOAD = 'download',
}

export enum CADASTRAL_POSITION_UPLOAD_STEPS {
  FORM = 'form',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum CADASTRAL_POSITION_PERMISSIONS {
  CREATE = 'pc:create',
}
