import React, { ReactNode } from 'react';
import { Grid, Row } from 'vkit/lib/context';
import { Button, Text, FloatLayer } from 'vkit/lib/components';

import useFilter from '../../hooks/useFilter';
import { getBgStyle } from '@/utils/style';
import { Divider } from '@/components';
import { ObjectType } from '@/shareds/types';

type FilterType = {
  loading?: boolean;
  onChange?: Function;
  onShow: Function;
  initialValues?: ObjectType;
  fields?: (...itens: any) => ReactNode;
};

const Filter: React.FC<FilterType> = ({ loading, onChange, onShow, initialValues, fields }) => {
  const { setShow, useShow, useData, onChangeData, resetData } = useFilter(initialValues || {});

  return (
    <Row style={{ flex: 1, alignSelf: 'center', padding: '0 0 16px' }}>
      <Grid alignItems='center'>
        <Button
          outlined={useShow}
          solo={useShow}
          iconColor={useShow ? 'colorDefault' : 'clear'}
          onClick={() => {
            setShow(!useShow);
            onShow(!useShow);
          }}
          label={useShow ? 'Menos filtros' : 'Mais filtros'}
          color='colorDefault'
          icon='funnel-outline'
        />
      </Grid>

      <FloatLayer
        maxWidth='305px'
        height={window.innerHeight - 100}
        show={useShow}
        elevation={2}
        onClose={() => {
          setShow(!useShow);
          onShow(!useShow);
        }}>
        <Row
          style={{
            backgroundColor: getBgStyle(),
            padding: window.innerWidth > 768 ? 0 : '16px 0px 0px 0px',
          }}>
          <Row style={{ padding: 0, flex: 1 }}>
            <Grid alignContent='justify'>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1,
                  padding: '16px 16px 0px 16px',
                }}>
                <Text title value='FILTRAR POR' color='colorDefault' textAlign='center' size='minor' />
              </Row>

              <Row style={{ padding: '16px 16px 0px 16px' }}>
                <Button
                  solo
                  color='light'
                  label='Limpar todos'
                  icon='close-circle-outline'
                  onClick={() => {
                    setShow(false);
                    onShow(false);
                    resetData();
                    onChange?.({});
                  }}
                />
              </Row>
            </Grid>
          </Row>

          <Divider />

          <Row style={{ padding: 0, flex: 1 }}>
            {fields?.({
              loading,
              onChangeData,
              values: useData,
            })}
          </Row>

          <Row>
            <Button
              outlined
              solo
              iconColor='colorDefault'
              onClick={() => {
                setShow(false);
                onShow(false);
                onChange?.(useData);
              }}
              label='BUSCAR'
              icon='search-outline'
            />
          </Row>
        </Row>
      </FloatLayer>
    </Row>
  );
};

export default Filter;
