import React, { useEffect, useMemo } from 'react';

import { BoxInfo, Divider, EmptyMessage } from '@/components';
import { ContentListLines } from '@/components/dataTable/components';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { Size } from '@/shareds/types';
import { Button, Pagination } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import {
  ACTION,
  ATTENDANCE_PAZE_SIZE,
  AVAILABLE_COLUMNS,
  Action,
  AttendanceFilter,
  AttendanceFiltersName,
  AttendanceTable,
  Column,
  COLUMN
} from '../../entities';
import { useAttendances } from '../../hooks';
import AttendanceStatus from '../../components/attendanceStatus/attendanceStatus';

export interface TableProps {
  filters: AttendanceFilter;
  linesLoader?: number;
  actions: Action[];
  columns: Column[];
  onChangePagination: (name: AttendanceFiltersName, value: string | number) => void;
}

const Table: React.FC<TableProps> = ({
  filters,
  linesLoader,
  columns,
  actions,
  onChangePagination,
}) => {
  const { pathname } = useHistoryLocation();
  const linesLoaderSize = linesLoader || 10;

  const AVAILABLE_ACTIONS = {
    [ACTION.DETAIL]: (id: string) => ({
      size: 'medium',
      label: 'Ver detalhes',
      onClick: () => navigate.push(`${pathname}/${id}`),
    }),
  };

  const navigate = useHistoryNavigator();
  const { company } = useCompany();
  const {
    totalAttendances,
    isLoading,
    loadAttendances,
    attendances,
    isEmpty,
    hasPagination,
    totalPages,
  } = useAttendances({ filters });

  const getAttendanceStatus = (attendance: AttendanceTable) => (
    <AttendanceStatus attendance={attendance} />
  );

  const useColumns = useMemo(
    () =>
      columns.map((column) => {
        if (column === COLUMN.STATUS) {
          return {
            ...AVAILABLE_COLUMNS[column],
            custom: getAttendanceStatus,
          };
        }

        return AVAILABLE_COLUMNS[column];
      }),
    [columns],
  );

  const getActions = (id: string) => {
    if (actions.length === 1 && actions.includes(ACTION.DETAIL)) {
      const action = AVAILABLE_ACTIONS[ACTION.DETAIL](id);
      return (
        <Button
          outlined
          solo
          size={action.size as Size}
          label={action.label}
          onClick={action.onClick}
        />
      );
    }
  };

  const onChangePageSize = (value: number) => {
    onChangePagination('pageSize', value);
  };

  const onChangePage = (value: number) => {
    onChangePagination('page', value);
  };

  useEffect(() => {
    if (!company.cnpj) {
      return;
    }
    loadAttendances(company.cnpj);
  }, [company.cnpj, loadAttendances]);

  return (
    <BoxInfo elevation={undefined} margin={0} padding={1}>
      {attendances.length || isLoading ? (
        <ContentListLines
          actionsByLine={({ id }: AttendanceTable) => getActions(id)}
          columns={useColumns}
          data={attendances}
          linesLoader={linesLoaderSize}
          loading={isLoading}
        />
      ) : null}

      {isEmpty ? (
        <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
      ) : null}

      {hasPagination && !isLoading ? (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px' }}>
            <Pagination
              page={filters?.page || 1}
              totalPages={totalPages}
              total={totalAttendances}
              pageSize={filters?.pageSize || ATTENDANCE_PAZE_SIZE}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </Row>
        </>
      ) : null}
    </BoxInfo>
  );
};

export default Table;
