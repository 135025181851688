import { ObjectType } from '@/shareds/types';

export interface InputClickRefInterface {
  onHandleFileSelect: Function;
}

export enum STATUS_FILE {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface StatusFile {
  value: STATUS_FILE;
  messageError?: string;
}

export interface FileUploadInterface {
  onChange: Function;
  onDelete?: Function;
  allowedExtensions?: string[];
  notAllowedExtensions?: string[];
  formValidatorErrors: ObjectType;
  name: string;
  textHelper?: string;
  multiple?: boolean;
  label?: string;
  statusFiles?: StatusFile[];
  maxCharacterLimit?: number;
  required?: boolean;
}
