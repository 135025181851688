import React, { ChangeEvent } from 'react';
import style from './style/pincode.module.scss';
import usePinCode from './hooks/usePinCode';

interface PinCodeProps {
  digits?: number;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange?: Function;
  onFinish?: Function;
  required?: boolean;
  triggered?: number;
  labelError?: boolean;
}

const PinCode: React.FC<PinCodeProps> = ({
  digits,
  errorMessage,
  label,
  onChange,
  onFinish,
  required,
  triggered = 0,
  disabled,
  labelError,
}) => {
  const {
    checkKeyboardEvent,
    handlePinCodeChange,
    itemEls,
    selectInput,
    usePins,
    useFocusedElement,
    setFocusedElement,
    handlePaste,
  } = usePinCode({
    digits,
    onFinish,
    onChange,
    triggered,
  });

  return (
    <div className={style.pinWrapper}>
      {label && (
        <span className={`${style?.label} ${labelError ? style?.labelError : ''}`}>
          {label}
          {required && !labelError && <span className={style?.requiredSign}>*</span>}
        </span>
      )}

      <div className={style.pin}>
        {usePins.map((pin, index) => (
          <input
            inputMode='numeric'
            className={`${style.input}
            ${errorMessage ? style.error : ''}
            ${useFocusedElement === index ? style.focus : ''}`}
            key={index}
            value={pin.value}
            onBlur={() => setFocusedElement(-1)}
            ref={(element: HTMLInputElement) => {
              itemEls.current[index] = element;
              element?.addEventListener('paste', handlePaste);
            }}
            onKeyUp={checkKeyboardEvent}
            onFocus={selectInput}
            onInput={(event: ChangeEvent<HTMLInputElement>) => handlePinCodeChange(event, index)}
            disabled={disabled}
          />
        ))}
      </div>

      <div className={style?.textHelper}>{errorMessage}</div>
    </div>
  );
};

export default PinCode;
