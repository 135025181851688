import restfulBase from '@/services/negotiations/core/base';
import { Base } from '@/shareds/types/base.type';

const apiService = restfulBase('providers');

export interface Provider extends Base {
  name: string;
  cnpj: string | number;
}

export const providerInitalState: Provider = {
  name: '',
  cnpj: '',
};

export default apiService;
