import { IconSquare } from '@/components';
import { Company } from '@/modules/company';
import { CustomSelectCompany } from '@/modules/company/components';
import { useCurrentUser } from '@/modules/profile';
import ICONS from '@/shareds/constants/icons';
import storage from '@/utils/storage/storage';
import React from 'react';
import { Text } from 'vkit/lib/components';
import { Box, Grid, Row } from 'vkit/lib/context';

interface RecentCompaniesProps {
  onClick: (company: Company) => void;
}

const RecentCompanies: React.FC<RecentCompaniesProps> = ({ onClick }) => {
  const { user } = useCurrentUser();
  const { isBrokerUser } = useCurrentUser();
  const recentCompanies = storage.get<Company[]>(`${user.id}.recentCompanies`) ?? [];

  return recentCompanies?.length ? (
    <Grid column gap={16} stretch>
      <Text value='Recentes' size='medium' />
      {recentCompanies.map((recentCompany) => (
        <Box key={recentCompany.id} rounded type='sheet' onClick={() => onClick(recentCompany)}>
          <Row>
            <Grid row alignItems='center' gap={16}>
              <IconSquare icon={ICONS.COMPANY} size={40} />
              <CustomSelectCompany showCompanyStatus={isBrokerUser} option={recentCompany} />
            </Grid>
          </Row>
        </Box>
      ))}
    </Grid>
  ) : null;
};

export default RecentCompanies;
