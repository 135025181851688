import { BoxInfo, DataTable } from '@/components';
import React, { useCallback } from 'react';
import { AttendanceAttachment } from '@/services/salesforce/attendances';
import ICONS from '@/shareds/constants/icons';
import DownloadAttachment from '../downloadAttachment/downloadAttachment';

interface InformationAttachmentsProps {
  attachments?: AttendanceAttachment[];
  isLoading?: boolean;
}

const InformationAttachments: React.FC<InformationAttachmentsProps> = ({
  isLoading,
  attachments,
}) => {
  const downloadAttachmentButton = useCallback((attachment: AttendanceAttachment) => (
    <DownloadAttachment
      label='Baixar'
      solo
      outlined
      attachment={attachment}
      iconColor='colorDefault'
    />
  ), [])

  return (
    <BoxInfo title="Anexos" icon={ ICONS.ATTACHMENTS }>
      <DataTable
        elevation={ 0 }
        loading={ isLoading }
        data={ attachments }
        columns={ [
          {
            title: 'NOME DO ARQUIVO',
            path: 'title',
          },
        ] }
        actionsByLine={ downloadAttachmentButton }
      />
    </BoxInfo>
  );
};

export default InformationAttachments;
