import { useNegotiations } from '@/modules/negotiation';
import React from 'react';

import { BoxInfo, EmptyMessage, GridGroup } from '@/components';
import { BENEFITS_SORT_MAPPING } from '@/modules/benefit/entities';
import { useBenefits } from '@/modules/benefit/hooks';

import { Card } from '../../components';

const BenefitsBox: React.FC = () => {
  const { getNegotiationsIdByBenefitId } = useNegotiations();
  const { isLoading, benefits } = useBenefits(BENEFITS_SORT_MAPPING);

  return (
    <BoxInfo title='Benefícios' icon='heart-outline'>
      {(isLoading || !!benefits?.length) && (
        <GridGroup
          body={
            isLoading
              ? Array.from({ length: 3 }).map(() => ({
                  component: <Card />,
                }))
              : benefits.map((benefit) => ({
                  component: (
                    <Card
                      benefit={benefit}
                      negotiations={benefit.id ? getNegotiationsIdByBenefitId(benefit.id) : []}
                    />
                  ),
                }))
          }
        />
      )}
      {!isLoading && !benefits?.length && <EmptyMessage title='Nenhum benefício encontrado' />}
    </BoxInfo>
  );
};

export default BenefitsBox;
