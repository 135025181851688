import { ButtonActions } from '@/components';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Icon } from 'vkit/lib/components';

const SupportActions: React.FC = () => {
  const filesSupport = [
    {
      label: 'Guia rápido',
      icon: 'hash',
      onClick: () => {
        const link = `${window.location.origin}/guia-rapido.pdf`;
        window.open(link, 'new', 'noreferrer');
      },
    },
    {
      label: 'Perguntas frequentes',
      icon: 'hash',
      onClick: () => {
        const link = `${window.location.origin}/perguntas-frequentes.pdf`;
        window.open(link, 'new', 'noreferrer');
      },
    },
  ];

  return (
    <ButtonActions color='transparent' actionOptions={filesSupport} size='xLarger'>
      <Icon name={ICONS.HELP} size={512} solo color='light' />
    </ButtonActions>
  );
};

export default SupportActions;
