import { Main } from '@/core/views';
import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import NoCompany from '@/modules/company/views/noCompany/noCompany';
import Home from '../pages/home/home';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';

export enum COMPANY_ROUTE_NAME {
  HOME = 'home',
  COMPANY = 'company',
}

const companyRoute: Route[] = [
  {
    path: '/companies',
    Component: Main,
    id: COMPANY_ROUTE_NAME.HOME,

    children: [
      {
        index: true,
        Component: NoCompany,
      },
      {
        path: ':companyId',
        Component: Home,
        id: COMPANY_ROUTE_NAME.COMPANY,
        meta: {
          groupedBy: APPLICATION_GROUP_BY.COMPANY,
          icon: 'briefcase-outline',
          nameMenu: 'Sua Empresa',
          showApplicationMenu: true,
        },
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Sua Empresa',
          });
        },
      },
    ],
  },
];

export default companyRoute;
