import { BoxInfo, GridGroup, SelectValidate, TextFieldValidate } from '@/components';
import { BodyForm } from '@/components/formValidate';
import TooltipDescription from '@/pages/beneficiaries/createOrUpdate/components/tooltipDescription';
import {
  BENEFICIARY_OWNERSHIP,
  COMPANY_LINK,
  GENDER,
  KINSHIP_DEGREE,
  MARITAL_STATUS,
  MOVE_FILE_COLUMN_DESCRIPTION,
  MOVE_FILE_COLUMN_NAME,
} from '@/pages/moves/shareds/constants';
import React, { useMemo } from 'react';

interface AboutBeneficiaryFormProps extends BodyForm {
  isDependent: boolean;
  isCanvasserShow: boolean;
}

const AboutBeneficiaryForm: React.FC<AboutBeneficiaryFormProps> = ({
  isDependent,
  isCanvasserShow,
  onChange,
  onBlur,
  useErrors,
  useValues,
}) => {
  const isHolder = useMemo(
    () => useValues[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.HOLDER,
    [useValues],
  );

  return (
    <BoxInfo margin={0} title='Informações do beneficiário' icon='person-outline'>
      <GridGroup
        body={[
          {
            default: isHolder ? 100 : 75,
            middle: 100,
            component: (
              <TextFieldValidate
                maxLength={260}
                required
                label={MOVE_FILE_COLUMN_NAME.NOME}
                name={MOVE_FILE_COLUMN_NAME.NOME}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            hidden: isHolder,
            default: 25,
            middle: 100,
            component: (
              <SelectValidate
                required={
                  useValues[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT
                }
                label='Grau de parentesco com o titular'
                name={MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                data={[
                  {
                    text: 'Cônjuge',
                    value: KINSHIP_DEGREE.SPOUSE,
                  },
                  {
                    text: 'Filho',
                    value: KINSHIP_DEGREE.CHILD,
                  },
                  {
                    text: 'Mãe',
                    value: KINSHIP_DEGREE.MOTHER,
                  },
                  {
                    text: 'Pai',
                    value: KINSHIP_DEGREE.DAD,
                  },
                  {
                    text: 'Sogro',
                    value: KINSHIP_DEGREE.FATHER_IN_LAW,
                  },
                  {
                    text: 'Sogra',
                    value: KINSHIP_DEGREE.MOTHER_IN_LAW,
                  },
                  {
                    text: 'Tutelado',
                    value: KINSHIP_DEGREE.PUPIL,
                  },
                  {
                    text: 'Outros',
                    value: KINSHIP_DEGREE.OTHERS,
                  },
                ]}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <TextFieldValidate
                required
                type='date'
                label={MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO}
                name={MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <SelectValidate
                required
                label={MOVE_FILE_COLUMN_NAME.SEXO}
                name={MOVE_FILE_COLUMN_NAME.SEXO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                data={[
                  {
                    text: 'Masculino',
                    value: GENDER.MALE,
                  },
                  {
                    text: 'Feminino',
                    value: GENDER.FEMALE,
                  },
                ]}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <SelectValidate
                required
                label={MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL}
                name={MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                data={[
                  {
                    text: 'Solteiro',
                    value: MARITAL_STATUS.SINGLE,
                  },
                  {
                    text: 'Casado',
                    value: MARITAL_STATUS.MARRIED,
                  },
                  {
                    text: 'Viúva(o)',
                    value: MARITAL_STATUS.WIDOWER,
                  },
                  {
                    text: 'Outros',
                    value: MARITAL_STATUS.OTHERS,
                  },
                ]}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <TextFieldValidate
                required={isHolder}
                type='cpf'
                label={MOVE_FILE_COLUMN_NAME.CPF}
                name={MOVE_FILE_COLUMN_NAME.CPF}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                mask='999.999.999-99'
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <SelectValidate
                disabled={isDependent}
                required={!isDependent}
                label={MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA}
                name={MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                data={[
                  {
                    text: 'Funcionário',
                    value: COMPANY_LINK.EMPLOYEE,
                  },
                  {
                    text: 'Sócio',
                    value: COMPANY_LINK.PARTNER,
                  },
                  {
                    text: 'Estagiário',
                    value: COMPANY_LINK.INTERN,
                  },
                  {
                    text: 'Aprendiz',
                    value: COMPANY_LINK.APPRENTICE,
                  },
                  {
                    text: 'Temporário',
                    value: COMPANY_LINK.TEMPORARY,
                  },
                  {
                    text: 'Sem identificação de vínculo',
                    value: COMPANY_LINK.NO_LINK_IDENTIFICATION,
                  },
                ]}
              />
            ),
          },
          {
            default: 33.33,
            middle: 50,
            component: (
              <TextFieldValidate
                required={
                  useValues[MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO] === KINSHIP_DEGREE.SPOUSE
                }
                label={MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE}
                name={MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },

          {
            hidden: !isCanvasserShow,
            default: 50,
            middle: 100,
            component: (
              <TextFieldValidate
                header={
                  <TooltipDescription
                    description={
                      MOVE_FILE_COLUMN_DESCRIPTION[MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]
                    }
                  />
                }
                required={isCanvasserShow}
                type='number'
                label={MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR}
                name={MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            hidden: !isCanvasserShow,
            default: 50,
            middle: 100,
            component: (
              <TextFieldValidate
                required={isCanvasserShow}
                type='date'
                label={MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO}
                name={MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 100,
            middle: 100,
            component: (
              <TextFieldValidate
                required
                label={MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO}
                name={MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 100,
            middle: 100,
            component: (
              <TextFieldValidate
                maxLength={260}
                required
                label={MOVE_FILE_COLUMN_NAME.NOME_DA_MAE}
                name={MOVE_FILE_COLUMN_NAME.NOME_DA_MAE}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 33.33,
            middle: 50,
            component: (
              <TextFieldValidate
                header={
                  <TooltipDescription
                    description={MOVE_FILE_COLUMN_DESCRIPTION[MOVE_FILE_COLUMN_NAME.CNS]}
                  />
                }
                maxLength={32}
                label={MOVE_FILE_COLUMN_NAME.CNS}
                name={MOVE_FILE_COLUMN_NAME.CNS}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 33.33,
            middle: 50,
            component: (
              <TextFieldValidate
                type='date'
                label={MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO}
                name={MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 33.33,
            middle: 50,
            component: (
              <TextFieldValidate
                required
                type='date'
                label={MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA}
                name={MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
        ]}
      />
    </BoxInfo>
  );
};

export default AboutBeneficiaryForm;
