import { get, hasIn } from 'lodash';

import { Negotiation, isBradesco } from '@/modules/negotiation';
import { BENEFICIARY_STATUS_ALIAS_TRANSLATED } from '../entities';
import { BeneficiaryNormalized } from '../entities/interfaces/beneficiary';

export const isRemovableBeneficiary = (
  beneficary: BeneficiaryNormalized,
  negotiation: Negotiation,
) => {
  if (beneficary.status === BENEFICIARY_STATUS_ALIAS_TRANSLATED.CANCELLED) {
    return false;
  }

  const hasEnoughAttributesInNegotiation = [
    'costCenter.name',
    'contract.code',
    'product.providerId',
    'product.benefit.name',
    'product.provider.name',
  ].every((key) => hasIn(negotiation, key));
  if (!hasEnoughAttributesInNegotiation) {
    return false;
  }

  const costCenterName = get(negotiation, 'costCenter.name');
  if (isBradesco(negotiation) && isNaN(Number(costCenterName))) {
    return false;
  }

  return ['name', 'cpf'].every((key) => hasIn(beneficary.life, key));
};
