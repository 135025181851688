import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { Company } from '../../entities/company.type';
import { useHistoryNavigator } from '@/navigation';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
interface RedirectCompanyProps {
  company?: Company;
}

const RedirectCompanyLink: React.FC<RedirectCompanyProps> = ({ company }) => {
  const { propagateCompany } = useCompanyPropagate();
  const { replace } = useHistoryNavigator();
  const onClick = useCallback(() => {
    if (!company?.id) return;
    const url = `/companies/${company.id}`;
    propagateCompany(company);
    replace(url);
  }, [company, propagateCompany, replace]);

  return (
    <Link to='' onClick={onClick} style={{ textDecorationColor: '#929292' }}>
      <Grid gap={6} alignItems='center' alignContent='left'>
        <div style={{ wordBreak: 'break-all', color: '#929292', textDecorationColor: '#929292' }}>
          {' '}
          {company?.name}{' '}
        </div>
        <Icon name='external-link-outline' color='colorDefault' />
      </Grid>
    </Link>
  );
};

export default RedirectCompanyLink;
