import HttpClientAdapter from '@/shared/services/httpClientAdapter';

class FilesService extends HttpClientAdapter {
  constructor() {
    super('FILES');
  }

  processSheet<Response>(params: FormData, layoutType: string) {
    return this.post<Response, FormData>(`/process-sheet/${layoutType}`, params);
  }

  sendCadastralPosition<Response>(params: FormData) {
    return this.post<Response, FormData>('/cadastral-positions', params);
  }
}

export default FilesService;
