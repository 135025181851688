import { BoxInfo } from '@/components';
import React, { useMemo } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

import { safeJsonParse } from '@/utils/validations/safeJsonParse';
import { format } from 'date-fns';
import { TFormData } from '../../types/FormData.type';

const { REACT_APP_MOVE_UPLOAD_FILE_ALERT = '' } = process.env;

interface EnvoirementConfig {
  type: string[];
  initialDate: string;
  endDate?: string;
}

interface SelectTypeProps {
  formData: TFormData;
}

const Alert: React.FC<SelectTypeProps> = ({ formData }) => {
  const title = 'Importante: Novo Layout para Movimentações Cadastrais';

  const envConfig = useMemo((): EnvoirementConfig | null => {
    return safeJsonParse(REACT_APP_MOVE_UPLOAD_FILE_ALERT) as EnvoirementConfig | null;
  }, []);

  const showAlert = useMemo(() => {
    if (!envConfig) {
      return false;
    }

    const { movimentationType } = formData;
    const { type, initialDate, endDate } = envConfig;

    if (
      !type ||
      !type.length ||
      !initialDate ||
      !new Date(initialDate).getTime() ||
      !movimentationType
    ) {
      return false;
    }

    if (
      type.includes(movimentationType) &&
      new Date(initialDate).getTime() <= new Date().getTime() &&
      (!endDate ||
        !new Date(endDate).getTime() ||
        new Date(endDate).getTime() >= new Date().getTime())
    ) {
      return true;
    }

    return false;
  }, [envConfig, formData]);

  const message = useMemo((): string => {
    if (!envConfig) return '';

    const { initialDate } = envConfig;

    return `Olá! A partir de ${format(
      new Date(initialDate),
      'dd/MM/yyyy',
    )}, implementamos mudanças no layout das Movimentações Cadastrais.
    Removemos campos desnecessários e renomeamos algumas colunas para simplificar a sua experiência.
    Por favor, faça o download da nova planilha modelo atualizada para continuar usando nosso serviço sem problemas. Obrigado!`;
  }, [envConfig]);

  return (
    <>
      {showAlert && (
        <BoxInfo elevation={0}>
          <Grid column gap={8}>
            <Row style={{ padding: 0 }}>
              <Text
                value={title}
                icon='alert-triangle-outline'
                iconColor='warning'
                color='warning'
                applyBaseColor
                padding='8px'
                rounded
                size='medium'
                title
              />
            </Row>
            <Text value={message} size='medium' />
          </Grid>
        </BoxInfo>
      )}
    </>
  );
};

export default Alert;
