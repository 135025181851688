import { TextData } from '@/components';
import {
  MOVE_STATUS,
  MOVE_STATUS_TRANSLATED_REVERSE,
  MoveNormalized,
} from '@/modules/move/entities';
import React, { useMemo } from 'react';
import { useCurrentUser } from '@/modules/profile';
import { TextItemDataType } from '@/components/textData/types/textData.type';

interface InformationDataProps {
  loading: boolean;
  loadingStatus?: boolean;
  move?: MoveNormalized;
  onChangeStatus?: (status: MOVE_STATUS) => void;
}

export const InformationData: React.FC<InformationDataProps> = ({
  loading,
  loadingStatus,
  move,
  onChangeStatus,
}) => {
  const { isCompanyUser } = useCurrentUser();

  const moveStatus = useMemo((): TextItemDataType => {
    if (isCompanyUser || loading || !onChangeStatus) {
      return {
        label: 'Status',
        text: move?.status,
        sizeDefault: 33.33,
        sizeMedium: 100,
      };
    }

    const selectableStatus = Object.entries(MOVE_STATUS_TRANSLATED_REVERSE).map(([key, value]) => {
      return {
        text: key,
        value,
      };
    });

    const statusKey =
      move?.status && move.status in MOVE_STATUS_TRANSLATED_REVERSE
        ? MOVE_STATUS_TRANSLATED_REVERSE[move.status]
        : '';

    return {
      label: 'Status',
      sizeDefault: 33.33,
      sizeMedium: 100,
      type: 'select',
      data: selectableStatus,
      selected: statusKey,
      onSelectectChange: onChangeStatus,
      disabled: move?.status
        ? [MOVE_STATUS.PROCESSED, MOVE_STATUS.REJECTED].includes(statusKey as MOVE_STATUS)
        : false,
      loading: loadingStatus,
    };
  }, [isCompanyUser, loading, loadingStatus, move?.status, onChangeStatus]);

  return (
    <TextData
      loading={loading}
      data={[
        {
          label: 'Nome do arquivo',
          text: move?.name,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Tipo',
          text: move?.type || undefined,
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
        {
          label: 'Criada em',
          text: move?.uploadedAt,
          type: 'dateTime',
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
        moveStatus,
        {
          hidden: !move?.rejectionReason,
          label: 'Motivo da rejeição',
          text: move?.rejectionReason || undefined,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Código do arquivo',
          text: move?.id,
          sizeDefault: 50,
          sizeMedium: 75,
        },
        {
          label: 'Formato do arquivo',
          text: move?.extension,
          sizeDefault: 25,
          sizeMedium: 25,
        },
        {
          label: 'Quantidade de beneficiários',
          text: move?.lines.toString(),
          sizeDefault: 25,
          sizeMedium: 33.33,
        },
        {
          label: 'Criado por',
          text: move?.user?.name || undefined,
          sizeDefault: 50,
          sizeMedium: 33.33,
        },

        {
          label: 'Fornecedor',
          text: move?.provider || undefined,
          sizeDefault: 50,
          sizeMedium: 33.33,
        },
      ]}
    />
  );
};
