import { get } from 'lodash';
import {
  authConfirmEmailError,
  authConfirmEmailValidationError,
} from '@/services/accessControl/endpoints/authConfirmEmail';
import { getMessageError } from '@/utils/getMessageError';

interface SearchKey {
  origin: Record<string, string>;
  details: Record<string, string>;
}

const searchKey = ({ origin, details }: SearchKey) => {
  return Object.keys(origin).find((key) =>
    Object.keys(details).find((responseKey) => responseKey === key),
  );
};

export const getAuthConfirmEmailError = (error: unknown) => {
  let message = '';
  const objectErrorMessage: string = get(error, 'response.data.message', '');
  const objectErrorDetails: Record<string, any> = get(error, 'response.data.details', {});
  const isValidationError = objectErrorMessage === 'Validation Error';

  if (isValidationError) {
    if (objectErrorDetails?.userId || objectErrorDetails?.code) {
      return authConfirmEmailValidationError.userId;
    }

    if (objectErrorDetails?.email) {
      const details = objectErrorDetails.email as Record<string, string>;
      const key = searchKey({ origin: authConfirmEmailValidationError.email, details });
      message = key && authConfirmEmailValidationError.email[key];
    }

    if (objectErrorDetails?.newPassword) {
      const details = objectErrorDetails.newPassword as Record<string, string>;
      const key = searchKey({ origin: authConfirmEmailValidationError.newPassword, details });
      message = key && authConfirmEmailValidationError.newPassword[key];
    }

    return message || 'Falha ao confirmar a conta.';
  }

  return getMessageError({
    error,
    messageDefault: 'Falha ao confirmar a conta.',
    messageErrorByMessageErrorRequest: authConfirmEmailError,
  });
};
