import ICONS from '@/shareds/constants/icons';
import React, { ReactNode, useMemo } from 'react';
import { Button } from 'vkit/lib/components';

import { BoxInfo } from '@/components';

import DialogConfirmUpdateStatusForm from '@/modules/move/components/dialogConfirmUpdateStatusForm/dialogConfirmUpdateStatusForm';

import {
  MOVE_CUSTOM_SHEET,
  MOVE_STATUS,
  MOVE_STATUS_TRANSLATED,
  MOVE_TYPE,
  MoveNormalized,
} from '@/modules/move/entities';
import useMoveStatus from '@/modules/move/hooks/useMoveStatus/useMoveStatus';
import { useCurrentUser } from '@/modules/profile';
import { Grid } from 'vkit/lib/context';
import { Attachments, InformationData } from '..';
import { useMove } from '../../hooks';

import TransformedDataAction from '../transformedDataAction/transformedDataAction';
import WrapperTransformedDataAction from '../wrapperTransformedDataAction/wrapperTransformedDataAction';
interface InformationProps {
  moveId: string;
  children?: ReactNode;
}

export const Information: React.FC<InformationProps> = ({ moveId, children }) => {
  const { isBrokerUser } = useCurrentUser();
  const { isLoading, move, setMove, transformedSheets, observationSheets, attachments } =
    useMove(moveId);

  const {
    onChangeStatus,
    dialogConfirm,
    openDialogConfirmUpdateStatusForm,
    callbackDialogConfirmUpdateStatusForm,
    loadingStatus,
  } = useMoveStatus({
    move,
    onChangeStatusCallback: (status: MOVE_STATUS) => {
      setMove(
        (currentMove) =>
          ({ ...currentMove, status: MOVE_STATUS_TRANSLATED[status] }) as MoveNormalized,
      );
    },
  });

  const showTransformedObservationSheetsDownload = useMemo(() => {
    return isBrokerUser && move?.typeKey === MOVE_TYPE.DEFAULT_CREATION_STONE;
  }, [isBrokerUser, move?.typeKey]);

  return (
    <>
      {dialogConfirm}

      <DialogConfirmUpdateStatusForm
        open={openDialogConfirmUpdateStatusForm}
        fnCallback={callbackDialogConfirmUpdateStatusForm}
      />

      <BoxInfo
        title='Dados da movimentação'
        icon='info-outline'
        header={
          <Grid gap={12}>
            <Button
              loading={isLoading}
              disabled={isLoading || !move?.link}
              icon={ICONS.DOWNLOAD}
              label='Download da planilha original'
              onClick={() => (move ? window.open(move.link) : null)}
            />
            {showTransformedObservationSheetsDownload && (
              <>
                <WrapperTransformedDataAction
                  count={transformedSheets.length}
                  type={MOVE_CUSTOM_SHEET.TRANSFORMED}>
                  <TransformedDataAction
                    type={MOVE_CUSTOM_SHEET.TRANSFORMED}
                    attachments={transformedSheets}
                  />
                </WrapperTransformedDataAction>
                <WrapperTransformedDataAction
                  count={observationSheets.length}
                  type={MOVE_CUSTOM_SHEET.OBSERVATION}>
                  <TransformedDataAction
                    type={MOVE_CUSTOM_SHEET.OBSERVATION}
                    attachments={observationSheets}
                  />
                </WrapperTransformedDataAction>
              </>
            )}
          </Grid>
        }>
        <InformationData
          move={move}
          loading={isLoading}
          loadingStatus={loadingStatus}
          onChangeStatus={onChangeStatus}
        />
      </BoxInfo>

      {children}

      <Attachments attachments={attachments} />
    </>
  );
};
