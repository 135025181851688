import React, { useState } from 'react';

import { Button, Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

import { BoxInfo } from '@/components';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';

import {
  ACTION,
  ATTENDANCE_ICON,
  ATTENDANCE_PAZE_SIZE,
  AttendanceFilter,
  AttendanceFiltersName,
  COLUMN,
  FIELDS,
} from '../../entities';
import { Filter, Table } from '../../views';

const List: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { pathname } = useHistoryLocation();

  const [filters, setFilter] = useState<AttendanceFilter>({
    page: 1,
    pageSize: ATTENDANCE_PAZE_SIZE,
  });

  const onChangeFilter = (name: AttendanceFiltersName, value: string | number) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      page: 1,
      [name]: value || null,
    }));
  };

  const onChangePagination = (name: AttendanceFiltersName, value: string | number) => {
    if (name === 'pageSize') {
      onChangeFilter(name, value);
    } else {
      setFilter((oldFilter) => ({
        ...oldFilter,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <Row
        style={{
          padding: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
        <Grid gap={4}>
          <Icon name={ATTENDANCE_ICON} color='colorDefault' />
          <Text color='colorDefault' value={'Atendimentos'} title />
        </Grid>
        <Button
          onClick={() => navigate.push(`${pathname}/actions/create`)}
          label='Solicitar atendimento'
          color='colorDefault'
          icon={ATTENDANCE_ICON}
        />
      </Row>
      <BoxInfo>
        <Filter
          fields={[FIELDS.SUBJECT, FIELDS.REASON_SUBREASON]}
          extraFields={[FIELDS.STATUS, FIELDS.CREATED_AT_MIN, FIELDS.CREATED_AT_MAX]}
          values={filters}
          onChangeFilter={onChangeFilter}
        />
      </BoxInfo>

      <Table
        onChangePagination={onChangePagination}
        filters={filters}
        actions={[ACTION.DETAIL]}
        columns={[COLUMN.SUBJECT, COLUMN.CREATED_AT, COLUMN.STATUS, COLUMN.FORECAST, COLUMN.REASON]}
      />
    </>
  );
};

export default List;
