import { DataSelectType } from '@/shareds/types';
import { StatusEnum } from '@/shareds/types/status.type';
import { getToken } from '@/utils/authentication';
import React, { useMemo } from 'react';
import { Select } from 'vkit/lib/components';
import { useCustomOptionCompany } from '../../hooks/useCustomOptionCompany';

export interface SelectCompanyProps {
  small?: boolean;
  label?: string;
  selectedCompanyId?: string;
  onChangeSelectedCompany: (...item: any) => void;
  data?: DataSelectType[];
  clearable?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const { REACT_APP_API_NEGOTIATIONS_URL = '' } = process.env;

const SelectCompany: React.FC<SelectCompanyProps> = ({
  small,
  label,
  selectedCompanyId,
  onChangeSelectedCompany,
  data,
  clearable,
  required,
  errorMessage,
}) => {
  const customOptionCompany = useCustomOptionCompany();
  const useSelectProps = useMemo(() => {
    if (data?.length) {
      return {
        data,
      };
    }
    return {
      autoRequest: true,
      requestPageParam: 'page',
      requestResponseRootPath: 'data',
      requestResponseText: 'name',
      requestResponseValue: 'id',
      requestRouter: '/companies',
      requestSearchParam: 'q',
      requestUri: REACT_APP_API_NEGOTIATIONS_URL,
      requestHeaders: { authorization: `Bearer ${getToken()}` },
    };
  }, [data]);

  return (
    <Select
      fixedList
      searchable
      clearable={clearable}
      small={small}
      label={label}
      placeholder='Selecione uma empresa'
      customOption={(option, isSelected) => customOptionCompany({ option, isSelected })}
      value={selectedCompanyId ?? ''}
      onChange={onChangeSelectedCompany}
      required={required}
      status={errorMessage ? StatusEnum.error : undefined}
      textHelper={errorMessage ? errorMessage ?? '' : undefined}
      {...useSelectProps}
    />
  );
};

export default SelectCompany;
