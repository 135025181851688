import { BaseListResponse } from '@/shared/entities';
import { FileCategory } from './fileCategories';

export const MAX_UPLOAD_FILE_SIZE_MB: Readonly<number> = 10;

export interface SystemOrigin {
  id: number;
  name: string;
  files: FileEntity[];
}

export interface FileParams {
  q?: string;
  'categories.id'?: string;
  createdBy?: string | string[];
  createdAt?: Date | Date[];
  systemOrigin?: SystemOrigin;
  systemOriginId?: number | number[];
  relationChildren?: FileEntity;
  relationParent?: FileEntity;
  relatedTo?: string | string[];
  mimetype?: string | string[];
  name?: string | string[];
  id?: string | string[];
  deletedAt?: Date | Date[];
  page?: number;
  pageSize?: number;
  sort?: keyof FileEntity | `-${keyof FileEntity}`
}

export interface FileEntity {
  id: string;
  name: string;
  link: string;
  mimetype: string;
  relatedTo: string;
  relationParent: FileEntity[];
  relationChildren: FileEntity[];
  companyId: string;
  systemOriginId: number;
  systemOrigin: string;
  createdAt: Date;
  createdBy: string;
  deletedAt?: Date | null;
  deletedBy?: string | null;
  categories: FileCategory[];
  updatedAt: string;
}

export interface FileResponse extends BaseListResponse<FileEntity> {}

export interface FileToUpload {
  file: File;
  categoryIds: string[];
}

export enum FILE_PERMISSION_ID {
  CREATE = 'files/dir:create',
  READ = 'files/dir:read',
  DELETE = 'files/dir:delete',
}
