import { USER_TYPE } from '@/services/users/endpoints/users';
import ICONS from '@/shareds/constants/icons';
import { Home } from '../pages';

import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import { Main } from '@/core/views';
import { AUDIT_PERMISSION } from '../entities';

export enum ROUTE_AUDIT {
  LIST = 'audit-list',
}

const Routes: Route[] = [
  {
    path: '/audit',
    Component: Main,
    id: ROUTE_AUDIT.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.BROKER,
      showApplicationMenu: true,
      customImageUrl: ICONS.AUDIT,
      nameMenu: 'Auditoria',
      permission: {
        type: USER_TYPE.BROKER,
        values: [AUDIT_PERMISSION.READ],
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.BROKER,
        customImageUrl: ICONS.AUDIT,
        name: 'Auditoria',
        title: 'Auditoria',
      });
    },
    children: [
      {
        index: true,
        Component: Home,
      },
    ],
  },
];

export default Routes;
