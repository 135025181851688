import {
  AuditEventLogAction,
  AuditEventLogEntity,
  EventLog,
  EventLogData,
} from '@/modules/audit/entities';
import {
  BENEFICIARY_STATUS_ALIAS_TRANSLATED,
  BankAccount,
  Beneficiary,
  BeneficiaryHolder,
  BeneficiaryNormalized,
  HealthCard,
  Life,
  Address as LifeAddress,
  LifeNormalized,
} from '@/modules/beneficiary/entities';
import { Benefit } from '@/modules/benefit/entities';
import { Company } from '@/modules/company';
import { CompanyGroup } from '@/modules/companyGroup/entities/companyGroup.interface';
import { Markup } from '@/modules/contract/entities/over';
import { FINANCIAL_ADJUSTMENT_UNIT } from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import {
  FinancialAdjustment,
  FinancialAdjustmentToTable,
} from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import { MOVE_TYPE, MoveAttachment, MoveNormalized, MoveUserEntity } from '@/modules/move/entities';
import { Negotiation, Product } from '@/modules/negotiation';
import { User } from '@/modules/profile';
import { BANK_ACCOUNT_TYPE, MARITAL_STATUS } from '@/pages/moves/shareds/constants';
import { BENEFICIARY_STATUS } from '@/services/beneficiaries/endpoints/beneficiaries';
import { CostCenter } from '@/services/negotiations/endpoints/costCenter';
import { Provider } from '@/services/negotiations/endpoints/providers';
import { Address, AddressViaCep } from '@/shared/modules/address';
import { ObjectType } from '@/shareds/types';
import { Base } from '@/shareds/types/base.type';
import { BenefitSlugType } from '@/shareds/types/beneficary.type';
import { SexType, StatusMaritalType } from '@/shareds/types/life.type';
import MoveType from './move.type';

export const generateMockBase = (override?: Partial<Base>): Base => ({
  id: '1d5e49dc-d775-4abc-8a2d-19c78406e876',
  createdAt: '2000-06-13T17:50:25.057Z',
  updatedAt: '2001-06-13T17:50:25.057Z',
  ...override,
});

export const createCompany = (override?: Partial<Company>) => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  name: 'name 09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  cnpj: '00000000000000',
  ...override,
});

export const createMoveAttachment = (override?: Partial<MoveAttachment>): MoveAttachment => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  createdAt: '2002-06-13T17:50:25.057Z',
  updatedAt: '2003-06-13T17:50:25.057Z',
  fileId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  name: 'name',
  extension: 'extension',
  link: 'https://link.com',
  ...override,
});

export const createUser = (override?: Partial<MoveUserEntity>): MoveUserEntity => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  email: 'user@email.com',
  name: 'name',
  createdAt: '2004-06-13T17:50:25.057Z',
  updatedAt: '2005-06-13T17:50:25.057Z',
  ...override,
});

export const createMoveNormalized = (override?: Partial<MoveNormalized>): MoveNormalized => {
  const company = createCompany();
  const rejectUser = createUser();
  const moveUser = createUser();
  const dateString = '2006-06-13T17:50:25.057Z';

  return {
    attachments: [createMoveAttachment()],
    company,
    companyId: company.id,
    createdAt: dateString,
    deletionReason: 'deletionReason',
    extension: 'extension',
    id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    lines: 10,
    link: 'https://link.com',
    name: 'name',
    rejectedBy: rejectUser.name,
    rejectedById: rejectUser.id!,
    rejectionReason: 'rejectionReason',
    status: 'success',
    type: MOVE_TYPE.BRADESCO_CREATION,
    typeKey: MOVE_TYPE.BRADESCO_CREATION,
    updatedAt: dateString,
    uploadedAt: dateString,
    user: moveUser,
    userId: moveUser.id!,
    provider: 'Bradesco',
    ...override,
  };
};

export const createMarkup = (override?: Partial<Markup>): Markup => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  negotiationId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  ageBegin: 0,
  ageEnd: 10,
  markup: 2,
  ...override,
});

export const createEventLogData = (override?: Partial<EventLogData>): EventLogData => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  eventLogId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  field: 'field',
  newValue: 'newValue',
  oldValue: 'oldValue',
  createdAt: '2007-06-13T17:50:25.057Z',
  ...override,
});

export const createAuditEventLogAction = (
  override?: Partial<AuditEventLogAction>,
): AuditEventLogAction => ({
  id: 1,
  name: 'action name',
  ...override,
});

export const createAuditEventLogEntity = (
  override?: Partial<AuditEventLogEntity>,
): AuditEventLogEntity => ({
  id: 1,
  name: 'entity name',
  ...override,
});

export function createEventLog(override?: Partial<EventLog>): EventLog {
  const eventLogEntity = createAuditEventLogEntity();
  const eventLogAction = createAuditEventLogAction();

  return {
    action: 'action',
    companyCnpj: '00000000000000',
    companyId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    companyName: 'companyName',
    createdAt: '2015-06-13T17:50:25',
    entity: 'entity',
    entityRecordId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    eventLogAction,
    eventLogActionId: eventLogAction.id,
    eventLogData: [createEventLogData()],
    eventLogEntity,
    eventLogEntityId: eventLogEntity.id,
    eventTime: '2015-06-13T17:50:25',
    id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    scope: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    userAgent: 'userAgent',
    userEmail: 'email@user.com',
    userId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    userIp: '',
    userName: 'userName',
    ...override,
  };
}

export const createBeneficiaryType = (override?: Partial<ObjectType>) => {
  const dateString = '2008-06-13T17:50:25.057Z';
  const company = createCompany();

  return {
    beneficiaryHolder: {
      beneficiaryId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
      holderId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    },
    life_id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    mobileNumber: '00000000000',
    motherName: 'motherName',
    name: 'name',
    phoneNumber: '0000000000',
    productName: 'productName',
    rg: '000000000000',
    sex: 'Feminino' as SexType,
    statusMarital: 'Casado(a)' as StatusMaritalType,
    validityStartAt: dateString,
    benefitSlug: 'seguro-de-vida' as BenefitSlugType,
    benefitName: 'benefitName',
    providerName: 'providerName',
    code: 'code',
    expiresAt: 'expiresAt',
    id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    bornAt: dateString,
    canceledAt: '',
    cardCode: '000000',
    companyId: company.id,
    companyName: company.name,
    costCenterName: '',
    cpf: '00000000000',
    email: 'email@user.com',
    status: BENEFICIARY_STATUS.ACTIVE,
    validFrom: dateString,
    negotiationId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    lifeId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
    address: [
      {
        street: 'street',
        number: 'number',
        complement: 'complement',
        neighborhood: 'neighborhood',
        city: 'city',
        state: 'state',
        zipcode: 'zipcode',
      },
    ],
    attachments: [],
    ...override,
  };
};

export const createBeneficiary = (override?: Partial<Beneficiary>): Beneficiary => {
  const dateString = '2009-06-13T17:50:25.057Z';
  const beneficiaryId = '517e0c25-9416-486a-99db-c391133140b6';

  return {
    bankAccount: createBankAccount({ beneficiaryId }),
    beneficiaryHolder: createBeneficiaryHolder({ beneficiaryId, holderId: beneficiaryId }),
    companyId: '0b29c287-8155-4a17-9ea6-20b65a9c3e7a',
    createdAt: '2010-06-13T17:50:25.057Z',
    deletedAt: null,
    deletionReason: null,
    id: beneficiaryId,
    lifeId: '43970c21-964d-4d6a-b4eb-c844f7ea8099',
    updatedAt: null,
    validFrom: dateString,
    validUntil: null,
    healthCard: createHealthCard(),
    status: BENEFICIARY_STATUS.ACTIVE,
    negotiationId: 'e9139e1f-fc62-4c70-bb3a-c1f7dbf29ce1',
    life: createLifeNormalized(),
    ...override,
  };
};

export const createBeneficiaryNormalied = (
  override?: Partial<BeneficiaryNormalized>,
): BeneficiaryNormalized => {
  const negotiation = createNegotiation();

  return {
    ...createBeneficiary(),
    ownership: 'Titular',
    isHolder: true,
    healthCard: createHealthCard(),
    healthCardNumber: '764157641576415',
    status: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
    negotiation: negotiation,
    negotiationId: String(negotiation.id),
    life: createLifeNormalized(),
    ...override,
  };
};

export const createBenefit = (override?: Partial<Benefit>) => ({
  name: 'name',
  providers: [],
  baseId: 1,
  ...override,
});

export const createMoveType = (override?: Partial<MoveType>): MoveType => ({
  _id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  action: 'action',
  createdAt: '2011-06-13T17:50:25.057Z',
  status: 'active',
  ...override,
});

export const createProvider = (override?: Partial<Provider>): Provider => ({
  name: 'name',
  cnpj: '00000000000000',
  ...override,
});

export const createProduct = (override?: Partial<Product>): Product => ({
  name: 'name',
  baseId: 1,
  benefitId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  providerId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  benefit: createBenefit(),
  provider: createProvider(),
  negotiations: [],
  ...override,
});

export const createContract = (override?: any) => ({
  code: 'code',
  salesforceId: 'salesforceId',
  providerCnpj: '00000000000000',
  companyId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  status: 'active',
  type: 'type',
  model: {
    id: 1,
    name: 'name',
  },
  rules: [],
  provider: createProvider(),
  validFrom: '2012-06-13T17:50:25.057Z',
  validUntil: '2013-06-13T17:50:25.057Z',
  ...override,
});

export const createCostCenter = (override?: Partial<CostCenter>): CostCenter => ({
  name: 'name',
  isDefault: false,
  companyId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  ...override,
});

export const createNegotiation = (override?: Partial<Negotiation>): Negotiation => ({
  contractId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  productId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  brokerId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  costCenterId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  product: createProduct(),
  contract: createContract(),
  costCenter: createCostCenter(),
  ...override,
});

export const createAddressViaCep = (override?: Partial<AddressViaCep>): AddressViaCep => ({
  cep: '00000000',
  logradouro: 'logradouro',
  bairro: 'bairro',
  localidade: 'localidade',
  uf: 'SP',
  complemento: 'complemento',
  ...override,
});

export const createAddress = (override?: Partial<Address>): Address => ({
  id: 'e21eec14-0569-49e2-bbab-347e55ba20f3',
  zipCode: '12312123',
  city: 'city',
  companyId: '28854f4c-e91e-4738-84f3-aea6795665d0',
  complement: 'complement',
  neighborhood: 'neighborhood',
  number: '249',
  location: 'location',
  street: 'street',
  state: 'state',
  state_abbr: 'state_abbr',
  ...override,
});

export const createLifeAddress = (override?: Partial<LifeAddress>): LifeAddress => ({
  ...createAddress(override),
  createdAt: '2014-06-13T17:50:25.057Z',
  updatedAt: '2015-06-13T17:50:25.057Z',
  deletedAt: null,
  deletionReason: null,
  lifeId: 'c92ae2ad-7746-4df3-b36b-d7c9cf2f4632',
  ...override,
});

export const createAuthenticatedUser = (override?: Partial<User>): User => ({
  id: '',
  name: '',
  email: '',
  type: 'company',
  scopeIds: [],
  status: '',
  createdAt: new Date(),
  updatedAt: null,
  deletedAt: null,
  userPermissions: [],
  permissions: [],
  ...override,
});

export const createFinancialAdjustment = (
  override?: Partial<FinancialAdjustment>,
): FinancialAdjustment => ({
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  description: 'description',
  value: 2,
  date: '2016-06-13T17:50:25.057Z',
  unit: FINANCIAL_ADJUSTMENT_UNIT.CURRENCY,
  contractId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  companyGroupId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  contract: createContract(),
  ...override,
});

export const createFinancialAdjustmentToTable = (
  override?: Partial<FinancialAdjustmentToTable>,
): FinancialAdjustmentToTable => ({
  key: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  description: 'description',
  value: 2,
  date: '2017-06-13T17:50:25.057Z',
  unit: FINANCIAL_ADJUSTMENT_UNIT.CURRENCY,
  contractId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  companyGroupId: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  contract: createContract(),
  ...override,
});

export const createCompanyGroup = (override?: Partial<CompanyGroup>): CompanyGroup => ({
  createdAt: '2018-06-13T17:50:25.057Z',
  updatedAt: '2019-06-13T17:50:25.057Z',
  id: '09f20d76-00ab-4d4e-ae4e-87d1c61576a9',
  name: 'name',
  supportEmail: 'supportEmail@email.com',
  salesforceCompanyId: '123',
  companies: [createCompany()],
  adjustments: [createFinancialAdjustment()],
  ...override,
});

export const createBankAccount = (override?: Partial<BankAccount>): BankAccount => ({
  ...generateMockBase(),
  bank: 'bank',
  type: BANK_ACCOUNT_TYPE.INDIVIDUAL_CHECKING_ACCOUNT,
  agencyNumber: '12345',
  agencyDigit: '12',
  accountNumber: '12345',
  accountDigit: '12',
  beneficiaryId: 'b7511075-c645-4f8e-bf06-a8d732b43799',
  ...override,
});

export const createBeneficiaryHolder = (
  override?: Partial<BeneficiaryHolder>,
): BeneficiaryHolder => ({
  createdAt: '2020-06-13T17:50:25.057Z',
  updatedAt: '2021-06-13T17:50:25.057Z',
  deletedAt: null,
  deletionReason: null,
  beneficiaryId: '97243de8-d197-4a76-8e67-4569b6643dbb',
  holderId: '8b816dca-1197-41d4-bf85-7b45b22e6a65',
  ...override,
});

export const createHealthCard = (override?: Partial<HealthCard>): HealthCard => ({
  ...generateMockBase(),
  status: 'active',
  number: '123456789',
  validUntil: null,
  beneficiaryId: 'bcf838b8-653f-4a85-816b-1bb9a696d4c9',
  ...override,
});

export const createLife = (override?: Partial<Life>): Life => ({
  ...generateMockBase(),
  name: 'nome',
  motherName: 'motherName',
  gender: 'male',
  birthDate: '1997-06-13T17:50:25.057Z',
  maritalStatus: MARITAL_STATUS.MARRIED,
  addresses: [createLifeAddress()],
  ...override,
});

export const createLifeNormalized = (override?: Partial<LifeNormalized>): LifeNormalized => ({
  ...createLife(),
  cpf: '12312312312',
  rg: '1212312312',
  cns: '849',
  ...override,
});
