import { ContentListLines } from '@/components/dataTable/components';
import {
  MoveSheetNormalizedContent,
  MoveSheetNormalizedContentMessages,
} from '@/modules/move/entities';
import React, { useMemo } from 'react';
import { Grid } from 'vkit/lib/context';

interface ReportListItemContentProps {
  sheetLineId: string;
  content?: MoveSheetNormalizedContent[];
  showOnlyErrors?: boolean;
  isLoading: boolean;
}

export const ReportListItemContent: React.FC<ReportListItemContentProps> = ({
  content,
  showOnlyErrors,
  isLoading,
}) => {
  const showContent = useMemo(() => {
    if (!content) {
      return [];
    }
    if (showOnlyErrors) {
      return content.filter((item) => item.hasError);
    }
    return content;
  }, [content, showOnlyErrors]);

  const getValue = ({ value, hasError }: MoveSheetNormalizedContent) => (
    <p style={{ color: hasError ? '#ff413a' : 'inherit' }}>{value?.toString() || '-'}</p>
  );

  const getMessage = ({ messages }: MoveSheetNormalizedContent) => {
    return (
      <Grid column>
        {messages.map(({ value }: MoveSheetNormalizedContentMessages) => {
          const message = { __html: value };
          return <p key={value} dangerouslySetInnerHTML={message} />;
        })}
      </Grid>
    );
  };

  const columns = [
    {
      title: 'Coluna',
      path: 'column',
      width: '250px',
    },
    {
      title: 'Valor recebido',
      path: 'value',
      width: '200px',
      custom: getValue,
    },
    {
      title: 'Mensagem de erro',
      custom: getMessage,
    },
  ];

  return (
    <ContentListLines columns={columns} linesLoader={3} loading={isLoading} data={showContent} />
  );
};
