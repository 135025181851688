import React from 'react';
import { Radio, Text } from 'vkit/lib/components';
import { BENEFICIARY_OWNERSHIP, MOVE_FILE_COLUMN_NAME } from '@/pages/moves/shareds/constants';
import { Grid } from 'vkit/lib/context';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import ButtonCards from '@/components/buttonCardGroup';
import { ShowHide } from '@/components';

interface OwnershipRadioGroupProps {
  dataToMove: BeneficiaryToMove;
  onChangeForm: (data: BeneficiaryToMove) => void;
}

const ChooseOwnership: React.FC<OwnershipRadioGroupProps> = ({ dataToMove, onChangeForm }) => (
  <>
    <ShowHide transition='slideToDown' visible={!dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE]}>
      <Text value='Selecione a titularidade do beneficiário' size='larger' padding='0 0 8px' />
      <ButtonCards
        data={[
          {
            icon: 'person-outline',
            onClick: () =>
              onChangeForm({
                [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: BENEFICIARY_OWNERSHIP.HOLDER,
              }),
            title: 'Titular',
          },
          {
            icon: 'person-outline',
            onClick: () =>
              onChangeForm({
                [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: BENEFICIARY_OWNERSHIP.DEPENDENT,
              }),
            title: 'Dependente',
          },
        ]}
      />
    </ShowHide>

    <ShowHide transition='slideToDown' visible={dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE]}>
      <Grid gap={8} alignItems='center'>
        <Text value='Titularidade do beneficiário' size='larger' />

        <Radio
          checked={dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.HOLDER}
          onChange={() =>
            onChangeForm({
              [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: BENEFICIARY_OWNERSHIP.HOLDER,
              [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: '',
              beneficiaryHolderId: '',
            })
          }
          value={BENEFICIARY_OWNERSHIP.HOLDER}
          label={BENEFICIARY_OWNERSHIP.HOLDER}
        />

        <Radio
          checked={
            dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT
          }
          onChange={() =>
            onChangeForm({
              [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: BENEFICIARY_OWNERSHIP.DEPENDENT,
            })
          }
          value={BENEFICIARY_OWNERSHIP.DEPENDENT}
          label={BENEFICIARY_OWNERSHIP.DEPENDENT}
        />
      </Grid>
    </ShowHide>
  </>
);

export default ChooseOwnership;
