import { useState, FormEvent, useRef, ChangeEvent, useEffect, KeyboardEvent } from 'react';
import { cloneDeep, isNumber } from 'lodash';

interface IUsePinCode {
  checkKeyboardEvent: (event: KeyboardEvent) => void;
  handlePaste: (event: ClipboardEvent) => void;
  handlePinCodeChange: Function;
  itemEls: { current: HTMLInputElement[] };
  selectInput: (event: FormEvent<HTMLInputElement>) => void;
  setFocusedElement: Function;
  useFocusedElement: number;
  usePins: { value: string }[];
}

interface UsePincode {
  digits?: number;
  onFinish?: Function;
  onChange?: Function;
  triggered?: number;
}

const DEFAULT_PIN_SIZE = 1;

const usePinCode = ({
  digits = DEFAULT_PIN_SIZE,
  onFinish,
  onChange,
  triggered,
}: UsePincode): IUsePinCode => {
  const itemEls = useRef(new Array(0));
  const [usePins, setPins] = useState([{ value: '' }]);
  const [useFocusedElement, setFocusedElement] = useState(-1);

  const removeNotNumbers = (value: string): string => {
    return value.replace(/\D/g, '');
  };

  const validateValue = (value: string): boolean => {
    const regexTest = /^\d*$/;

    return regexTest.test(value);
  };

  const handlePinCodeFinish = (finalValue: string): void => {
    onFinish?.(finalValue);
    onChange?.(finalValue);
  };

  const getElementIndexByAttribute = (element: HTMLInputElement): number =>
    Array.prototype.indexOf.call(element.parentElement?.children, element);

  const selectInput = (event: FormEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    const index = getElementIndexByAttribute(target);

    setFocusedElement(index);

    if (target) {
      target.select();
    }
  };

  const checkPinCodeProgress = (pinCode: { value: string }[]): void => {
    const isPinCodeCompleted = pinCode.every((pin) => pin.value !== '');
    const currentPinCode = pinCode.map(({ value }) => value).join('');

    if (isPinCodeCompleted) {
      handlePinCodeFinish(currentPinCode);
    } else {
      onChange?.(currentPinCode);
    }
  };

  const handleBackSpaceEvent = (index: number): void => {
    const pinCode = usePins.map((pinCode, pinIndex) =>
      index === pinIndex ? { ...pinCode, value: '' } : pinCode,
    );

    const currentPinCode = pinCode.map(({ value }) => value).join('');

    setPins([...pinCode]);
    onChange?.(currentPinCode);

    if (index > 0) {
      const nextIndex = index - 1;
      setFocusedElement(nextIndex);
      itemEls.current[nextIndex].focus();
    }
  };

  const handlePinCodeChange = (event: ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = event.target.value;
    const valueLength = value.length;

    if (value !== '') {
      const currentValue = valueLength > 1 ? value.charAt(1) : value;
      const valid = validateValue(currentValue);
      const onlyNumbers = valid ? value : removeNotNumbers(value);
      const parsedValue =
        valueLength > DEFAULT_PIN_SIZE ? value?.charAt(valueLength - 1) : onlyNumbers;
      const nextIndex = index === usePins.length - 1 ? index : index + 1;
      const pinCode = usePins.map((pinCode, pinIndex) =>
        index === pinIndex ? { ...pinCode, value: parsedValue } : pinCode,
      );

      setPins([...pinCode]);

      if (nextIndex && valid) {
        setFocusedElement(nextIndex);
        itemEls.current[nextIndex].focus();
      }

      checkPinCodeProgress(pinCode);
    }
  };

  const checkKeyboardEvent = (event: KeyboardEvent): void => {
    const target = event.target as HTMLInputElement;

    if (target) {
      const index = getElementIndexByAttribute(target);

      if (isNumber(index)) {
        const action = {
          Backspace: () => handleBackSpaceEvent(index),
          ArrowRight: () => {
            const nextIndex = index === usePins.length - 1 ? index : index + 1;
            itemEls.current[nextIndex].focus();
          },
          ArrowLeft: () => {
            const nextIndex = index === 0 ? index : index - 1;
            itemEls.current[nextIndex].focus();
          },
        }[event.code];

        action?.();
      }
    }
  };

  const handlePaste = (event: ClipboardEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    const pastedData = event.clipboardData?.getData('Text') || '';
    const pastedNumbers = removeNotNumbers(pastedData);
    const target = event.target as HTMLInputElement;
    const index = getElementIndexByAttribute(target);
    const newPins = pastedNumbers.split('', digits - index).map((pinText) => ({ value: pinText }));
    const pinsClone = cloneDeep(usePins);
    pinsClone.splice(index, newPins.length, ...newPins);
    setPins(pinsClone);
    const currentPinCode = pinsClone.map(({ value }) => value).join('');
    onChange?.(currentPinCode);
  };

  useEffect(() => {
    const pinCode = Array.from(
      {
        length: digits,
      },
      () => ({
        value: '',
      }),
    );

    setPins([...pinCode]);
  }, [digits, triggered]);

  return {
    checkKeyboardEvent,
    handlePaste,
    handlePinCodeChange,
    itemEls,
    selectInput,
    setFocusedElement,
    useFocusedElement,
    usePins,
  };
};

export default usePinCode;
