import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { TextField } from 'vkit/lib/components';

import { SelectCadastralDataMatchingStatus } from '@/modules/cadastralDataMatching/components';
import {
  CADASTRAL_DATA_MATCHING_LIST_FILTERS,
  CadastralDataMatchingParamsToFilter,
} from '@/modules/cadastralDataMatching/entities';

import { TextFieldDebounce } from '@/components';
import { removeEmptyParams } from '@/utils';

interface UseCadastralDataMatchingFilters {
  setFilters: Dispatch<SetStateAction<CadastralDataMatchingParamsToFilter>>;
}

const useCadastralDataMatchingFilters = ({ setFilters }: UseCadastralDataMatchingFilters) => {
  const [fieldValues, setFieldValues] = useState<CadastralDataMatchingParamsToFilter>({});

  const onChange = useCallback(
    (key: string, value: string) => {
      setFilters((oldValues) =>
        removeEmptyParams({
          ...oldValues,
          [key]: value,
          page: 1,
        }),
      );
      setFieldValues((oldValues) => ({
        ...oldValues,
        [key]: value,
        page: 1,
      }));
    },
    [setFilters],
  );

  const fieldsTochoose = {
    [CADASTRAL_DATA_MATCHING_LIST_FILTERS.SEARCH]: {
      component: (
        <TextFieldDebounce
          clearable
          small
          iconDir='right'
          icon='search-outline'
          label='Buscar'
          value={fieldValues.q}
          onChange={(value: string) => onChange('q', value)}
        />
      ),
    },
    [CADASTRAL_DATA_MATCHING_LIST_FILTERS.STATUS]: {
      component: (
        <SelectCadastralDataMatchingStatus
          name='status'
          value={fieldValues.status}
          onChange={onChange}
          small
        />
      ),
    },
    [CADASTRAL_DATA_MATCHING_LIST_FILTERS.CREATED_AT_MIN]: {
      component: (
        <TextField
          clearable
          small
          label='Data de criação'
          value={fieldValues.createdAtMin}
          type='date'
          onChange={(value: string) => onChange('createdAtMin', value)}
        />
      ),
    },
  };

  return {
    fieldsTochoose,
  };
};

export default useCadastralDataMatchingFilters;
