import React from 'react';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { useHistoryNavigator } from '@/navigation';
import { RuleType } from '@/modules/contract/entities/contract';

interface RuleDoubtProps {
  contractId: string;
  ruleType: RuleType;
  baseId: string;
}

const RuleDoubt: React.FC<RuleDoubtProps> = ({ contractId, ruleType, baseId }) => {
  const navigate = useHistoryNavigator();

  return (
    <Grid gap={8} alignItems='center'>
      <Text
        size='medium'
        value={`Para saber mais sobre a regra de <b>${ruleType.title}</b> solicite um atendimento.`}
      />

      <Button
        size='small'
        label='Solicitar um atendimento'
        onClick={() =>
          navigate.push(
            `/attendances/actions/create?contractId=${contractId}&ruleType=${ruleType.type}&baseId=${baseId}`,
          )
        }
      />
    </Grid>
  );
};

export default RuleDoubt;
