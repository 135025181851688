import { ObjectType } from '@/shareds/types';
import { get } from 'lodash';
import { SelectOption } from '@/components/selectValidate';

export const extractSelectOptions = <T = ObjectType>(
  data: T[],
  pathText: keyof T,
  pathValue: keyof T,
): SelectOption<T>[] =>
  data.map((item) => ({
    text: get(item, pathText),
    value: get(item, pathValue),
    data: item,
  }));
