import { BoxInfo } from '@/components';
import React from 'react';
import { Grid, Row } from 'vkit/lib/context';

export interface OverHeaderProps {
  negotiation: string;
  plan: string;
  costCenter: string;
}

const OverHeader: React.FC<OverHeaderProps> = ({ negotiation, plan, costCenter }) => {
  return (
    <BoxInfo>
      <Row style={{ gap: '8px' }}>
        <Grid gap={4}>
          <strong>Negociação:</strong> {negotiation}
        </Grid>
        <Grid gap={4}>
          <strong>Plano:</strong> {plan}
        </Grid>
        <Grid gap={4}>
          <strong>Centro de custo:</strong> {costCenter}
        </Grid>
      </Row>
    </BoxInfo>
  );
};

export default OverHeader;
