import { useEffect, useCallback, useState, DragEventHandler } from 'react';

interface UseDropZone {
  useIsDragging: boolean;
  onFileDrop: DragEventHandler<HTMLDivElement>;
  onDragEnterDropzone: DragEventHandler<HTMLDivElement>;
  onDragLeaveDropzone: DragEventHandler<HTMLDivElement>;
}

const useDropzone = (onDrop: Function, onDragChange: Function): UseDropZone => {
  const [useIsDragging, setIsDragging] = useState(false);

  const drag = useCallback((e: any): void => {
    e.preventDefault();
  }, []);

  const onFileDrop = (e: any): void => {
    e.preventDefault();

    onDrop(e);
    onDragChange(false);
    setIsDragging(false);
  };

  const onDragEnterDropzone = (): void => {
    setIsDragging(true);
    onDragChange(true);
  };

  const onDragLeaveDropzone = (): void => {
    setIsDragging(false);
    onDragChange(false);
  };

  useEffect(() => {
    document.addEventListener('dragover', drag);
    document.addEventListener('drop', drag);

    return () => {
      document.removeEventListener('dragover', drag);
      document.removeEventListener('drop', drag);
    };
  }, [drag]);

  return {
    useIsDragging,
    onFileDrop,
    onDragEnterDropzone,
    onDragLeaveDropzone,
  };
};

export default useDropzone;
