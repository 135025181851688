import * as datadog from '@/datadog';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { analytics } from './analytics/analytics';
import { errorReports } from './errorReports/errorReports';
import { UserIdentity } from './telemetry.type';

export const telemetryInit = (): void => {
  analytics.init();
  errorReports.init();
  datadog.init();
};

export const telemetryIdentifyUser = (user: UserIdentity): void => {
  analytics.identifyUser(user);
  errorReports.identifyUser(user);
  datadogRum.setUser({ ...user });
  datadogLogs.setUser({ ...user });
};

export { analytics, errorReports };
