import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { analytics } from '@/shared/telemetry/analytics/analytics';
import { useState } from 'react';

import {
  CADASTRAL_DATA_MATCHING_FORM_DATA,
  CADASTRAL_DATA_MATCHING_UPLOAD_STEPS,
  CadastralDataMatchingParamsToUpload,
} from '@/modules/cadastralDataMatching/entities';
import { FilesService } from '@/shared/services';

import { ToastStatusGlobal } from '@/components';
import {
  MovesValidationError,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';
import { getMessageError } from '@/utils/getMessageError';

import useCompany from '@/shared/hooks/useCompany/useCompany';

interface UseCadastralDataMatchingUpload {
  setCurrentStep: (step: CADASTRAL_DATA_MATCHING_UPLOAD_STEPS) => void;
  setErrors: (errors: MovesValidationError) => void;
}

interface ProcessSheetResponse {
  statusCode: number;
  body: Object;
  headers: Record<string, string>;
}

const filesService = new FilesService();

const useCadastralDataMatchingUpload = ({
  setCurrentStep,
  setErrors,
}: UseCadastralDataMatchingUpload) => {
  const { company, getFirstCompanyConfigByKey } = useCompany();
  const [formValues, setFormValues] = useState<CadastralDataMatchingParamsToUpload>(
    CADASTRAL_DATA_MATCHING_FORM_DATA,
  );
  const [isLoading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [showMessageError, setShowMessageError] = useState(false);
  const { toast } = ToastStatusGlobal();
  const layoutType = getFirstCompanyConfigByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);

  const getFormDataValues = async (): Promise<FormData> => {
    const formData = new FormData();

    if (!company.id || !formValues.files) {
      return formData;
    }

    formData.append('files', formValues.files);
    formData.append('companyId', company.id);

    return formData;
  };

  const handleError = () => {
    toast('Puxa!', 'É necessário adicionar um arquivo para fazer o upload.', 'warning');
  };

  const handleSubmit = async () => {
    setShowMessageError(false);

    if (!layoutType) {
      toast(
        'Puxa!',
        'Esta funcionalidade não possui as informações de configuração necessárias para habilitar o envio de arquivos.',
        'warning',
      );
      return;
    }

    setLoading(true);
    analytics.track('[START] - Bate cadastral');

    try {
      const formData = await getFormDataValues();
      const { statusCode, body } = await filesService.processSheet<ProcessSheetResponse>(
        formData,
        layoutType,
      );

      if (Number(statusCode) !== 204) {
        throw body;
      }

      toast('Feito!', 'O arquivo foi enviado.', 'success');
      setCurrentStep(CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.SUCCESS);
      analytics.track('[SUCCESS] - Bate cadastral');
    } catch (error) {
      console.error(error);
      const errorResponse = (error as MovesValidationError) || movesValidationErrorInitialState;
      const worksheetNotFound = errorResponse.message?.split(': ')[1] || 'Não identificadas';
      const messageErrorFound = getMessageError({
        error,
        pathMessageRequest: 'message',
        messageErrorByMessageErrorRequest: {
          ['The following worksheet names were not found in the file']: `Erro no bate cadastral, a operação não foi concluída devido ao nome incorreto da aba. Certifique-se de que a aba esteja nomeada corretamente e contenha registros. As seguintes abas não foram encontradas no arquivo: ${worksheetNotFound}.`,
        },
        messageDefault: 'Falha ao fazer upload do arquivo!',
      });
      setMessageError(messageErrorFound);
      setShowMessageError(true);

      if (errorResponse.details?.length) {
        setErrors(errorResponse);
        setCurrentStep(CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.ERROR);
        setFormValues(CADASTRAL_DATA_MATCHING_FORM_DATA);
        analytics.track('[ERROR] - Bate cadastral', {
          countDetailErrors: errorResponse.details?.length,
        });
      }
    } finally {
      setLoading(false);
      analytics.track('[END] - Bate cadastral');
    }
  };

  return {
    formValues,
    isLoading,
    setFormValues,
    handleSubmit,
    handleError,
    messageError,
    showMessageError,
    setShowMessageError,
  };
};

export default useCadastralDataMatchingUpload;
