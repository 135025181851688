import { useCallback, useEffect, useState } from 'react';
import { ProcessedFamilyGroupCounter } from '@/modules/cadastralPosition/entities/familyGroup.interface';
import { ToastStatusGlobal } from '@/components';
import FamilyGroupsService from '@/modules/cadastralPosition/service/familyGroupsService';

interface UseFamilyGroup {
  cadastralPositionId: string;
}

const familyGroupsService = new FamilyGroupsService();

const useFamilyGroup = ({ cadastralPositionId }: UseFamilyGroup) => {
  const [processedFamilyGroupsCounter, setProcessedFamilyGroupsCounter] = useState<
    ProcessedFamilyGroupCounter[]
  >([]);
  const [loading, setLoading] = useState(false);

  const { toast } = ToastStatusGlobal();

  const loadProcessedfamilyGroupCounter = useCallback(
    async (cadastralPositionIdToSearch: string) => {
      setLoading(true);

      try {
        const result = await familyGroupsService.getProcessResult(cadastralPositionIdToSearch);
        setProcessedFamilyGroupsCounter(result);
      } catch (e) {
        toast('Oops', 'Falha ao carregar o resultado do processo dos grupos familiares.', 'error');
      } finally {
        setLoading(false);
      }
    },
    [toast],
  );

  useEffect(() => {
    if (!cadastralPositionId) {
      return;
    }

    loadProcessedfamilyGroupCounter(cadastralPositionId);
  }, [loadProcessedfamilyGroupCounter, cadastralPositionId]);

  return {
    loading,
    processedFamilyGroupsCounter,
  };
};

export default useFamilyGroup;
