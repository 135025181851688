import React from 'react';
import { Chip } from 'vkit/lib/components';

interface ChipBooleanProps {
  value: boolean;
}

const ChipBoolean: React.FC<ChipBooleanProps> = ({ value }) => (
  <Chip
    rounded
    size='small'
    invertColor={value}
    label={value ? 'Sim' : 'Não'}
    color={value ? 'colorDefault' : 'danger'}
  />
);

export default ChipBoolean;
