export enum ADDRESS {
  CITY = 'CITY',
  COMPLEMENT = 'COMPLEMENT',
  STATE = 'STATE',
  STATE_ABBR = 'STATE_ABBR',
  ZIPCODE = 'ZIPCODE',
  LOCATION = 'LOCATION',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  NUMBER = 'NUMBER',
}

export const generateAddressLabel = (): { [key in ADDRESS]: string } => {
  return {
    [ADDRESS.CITY]: 'Cidade',
    [ADDRESS.COMPLEMENT]: 'Complemento',
    [ADDRESS.STATE]: 'Estado',
    [ADDRESS.STATE_ABBR]: 'UF',
    [ADDRESS.ZIPCODE]: 'CEP',
    [ADDRESS.LOCATION]: 'Logradouro',
    [ADDRESS.NEIGHBORHOOD]: 'Bairro',
    [ADDRESS.NUMBER]: 'Número',
  } as const;
};

export const generateAddressName = (): { [key in ADDRESS]: string } => {
  return {
    [ADDRESS.CITY]: ADDRESS.CITY.toLowerCase(),
    [ADDRESS.COMPLEMENT]: ADDRESS.COMPLEMENT.toLowerCase(),
    [ADDRESS.STATE]: ADDRESS.STATE.toLowerCase(),
    [ADDRESS.STATE_ABBR]: ADDRESS.STATE_ABBR.toLowerCase(),
    [ADDRESS.ZIPCODE]: ADDRESS.ZIPCODE.toLowerCase(),
    [ADDRESS.LOCATION]: ADDRESS.LOCATION.toLowerCase(),
    [ADDRESS.NEIGHBORHOOD]: ADDRESS.NEIGHBORHOOD.toLowerCase(),
    [ADDRESS.NUMBER]: ADDRESS.NUMBER.toLowerCase(),
  } as const;
};

export const ADDRESS_LABEL = generateAddressLabel();
export const ADDRESS_NAME = generateAddressName();
