import React from 'react';
import { TRANSLATED_CONTRACT_STATUS } from '@/modules/contract/entities/contract.const';
import { Contract } from '@/modules/contract/entities/contract';
import { Chip } from 'vkit/lib/components';
import { Color } from 'vkit/lib/shared/types';
import { CONTRACT_STATUS } from '../../entities';

interface ContractStatusProps {
  contract: Contract;
}

const chipColors: Record<CONTRACT_STATUS, Color> = {
  [CONTRACT_STATUS.ACTIVE]: 'success',
  [CONTRACT_STATUS.CANCELLED]: 'danger',
  [CONTRACT_STATUS.EXPIRED]: 'warning',
};

const ContractStatus: React.FC<ContractStatusProps> = ({ contract }) => {
  return (
    <Chip
      size='medium'
      label={TRANSLATED_CONTRACT_STATUS[contract.status]}
      rounded
      color={chipColors[contract.status]}
    />
  );
};

export default ContractStatus;
