import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { TextField } from 'vkit/lib/components';

import { removeEmptyParams } from '@/utils';
import { TextFieldDebounce } from '@/components';
import SelectCadastralPositionStatus from '@/modules/cadastralPosition/components/selectCadastralPositionStatus/selectCadastralPositionStatus';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import { CADASTRAL_POSITION_LIST_FILTERS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import SelectCompanyChips from '@/modules/company/views/selectCompanyChips/selectCompanyChips';

interface UseCadastralPositionFilters {
  setFilters: Dispatch<SetStateAction<ParamsToFilter>>;
  filters: ParamsToFilter;
}

const useCadastralPositionFilters = ({ setFilters, filters }: UseCadastralPositionFilters) => {
  const onChange = useCallback(
    (key: string, value: string | string[]) => {
      setFilters((oldValues) =>
        removeEmptyParams({
          ...oldValues,
          [key]: Array.isArray(value) ? value.filter(Boolean) : value,
          page: 1,
        }),
      );
    },
    [setFilters],
  );

  const fieldsToChoose = {
    [CADASTRAL_POSITION_LIST_FILTERS.SEARCH]: {
      component: (
        <TextFieldDebounce
          clearable
          small
          iconDir='right'
          icon='search-outline'
          label='Buscar'
          value={filters.q}
          onChange={(value: string) => onChange('q', value)}
        />
      ),
    },
    [CADASTRAL_POSITION_LIST_FILTERS.STATUS]: {
      component: (
        <SelectCadastralPositionStatus
          name='status'
          value={filters.status}
          onChange={onChange}
          small
        />
      ),
    },
    [CADASTRAL_POSITION_LIST_FILTERS.CREATED_AT_MIN]: {
      component: (
        <TextField
          clearable
          small
          label='Data mínima de criação'
          value={filters.createdAtMin}
          type='date'
          onChange={(value: string) => onChange('createdAtMin', value)}
        />
      ),
    },
    [CADASTRAL_POSITION_LIST_FILTERS.CREATED_AT_MAX]: {
      component: (
        <TextField
          clearable
          small
          label='Data máxima de criação'
          value={filters.createdAtMax}
          type='date'
          onChange={(value: string) => onChange('createdAtMax', value)}
        />
      ),
    },
    [CADASTRAL_POSITION_LIST_FILTERS.COMPANIES]: {
      component: (
        <SelectCompanyChips
          label='Empresas'
          maxSelecteds={10}
          value={filters.companyId || []}
          onChange={(value: string[]) => onChange('companyId', value)}
          size='small'
        />
      ),
    },
  };

  return {
    fieldsToChoose,
  };
};

export default useCadastralPositionFilters;
