import { USER_TYPE } from '@/modules/user';
import { User } from '@/modules/user/entities/user';
import { difference, intersection } from 'lodash';

export const getCommomCompanyIds = (user: User, otherUser: Pick<User, 'type' | 'scopes'>) => {
  const otherUserScopeIds = otherUser.scopes?.map(({ id }) => id);
  return otherUser.type === USER_TYPE.COMPANY
    ? intersection(user.scopeIds, otherUserScopeIds)
    : user.scopeIds;
};

export const getDifferenceCompanyIdsFromFirstUser = (
  user: User,
  otherUser: Pick<User, 'type' | 'scopes'>,
) => {
  const otherUserScopeIds = otherUser.scopes?.map(({ id }) => id) || [];
  return otherUser.type === USER_TYPE.COMPANY ? difference(user.scopeIds, otherUserScopeIds) : [];
};
