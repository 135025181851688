import { Tooltip } from '@/components';
import { findRoutePathByName } from '@/core/utils';
import { INITIAL_PAGINATION_PARAMS, USER_TYPE, UserSearchParams } from '@/modules/user';
import { Filter } from '@/modules/user/components';
import { ROUTE_USER } from '@/modules/user/routes';
import TableUsers from '@/modules/user/views/tableUsers/tableUsers';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import { useChangeState } from '@/shared/hooks/useChangeState';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import ICONS from '@/shareds/constants/icons';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

const initialQueryParams: Partial<UserSearchParams> = {
  page: INITIAL_PAGINATION_PARAMS.PAGE,
  pageSize: INITIAL_PAGINATION_PARAMS.PAGE_SIZE,
  status: 'all',
};

interface TableAndFilterUserProps {
  userType: USER_TYPE;
  disabledCreateAction?: boolean;
  routeIdToDetails: ROUTE_USER;
}

const TableAndFilterUser: React.FC<TableAndFilterUserProps> = ({
  userType,
  disabledCreateAction,
  routeIdToDetails,
}) => {
  const [filters, setFilters] = useQueryStringState<Partial<UserSearchParams>>(initialQueryParams);
  const changeFilters = useChangeState(setFilters);
  const { companyId } = useHistoryParams();
  const navigate = useHistoryNavigator();

  const tooltipMessage = disabledCreateAction
    ? 'Você não tem permissão para criar um novo usuário'
    : undefined;

  const creationRoute = useMemo(() => {
    if (disabledCreateAction) {
      return;
    }

    const routeFound = findRoutePathByName(routeIdToDetails);

    if (routeFound) {
      if (companyId) {
        return generatePath(routeFound, { companyId });
      }

      return generatePath(routeFound);
    }
  }, [companyId, disabledCreateAction, routeIdToDetails]);

  return (
    <>
      <Filter filters={filters} onChange={changeFilters} />

      <Grid alignContent='right' stretch>
        <Tooltip title={tooltipMessage}>
          <Button
            label='Novo usuário'
            icon={ICONS.USER}
            disabled={disabledCreateAction}
            onClick={() => navigate.push(creationRoute)}
          />
        </Tooltip>
      </Grid>

      <TableUsers type={userType} filters={filters} onChange={changeFilters} />
    </>
  );
};

export default TableAndFilterUser;
