import { getFormattedDate, maskMobileNumber, maskPhoneNumber, maskText } from '@/utils/format';
import translate from '@/components/textData/utils';

const formatters = {
  cardCode: (value: string) => maskText(value, '9999 '.repeat(20)),
  cep: (value: string) => maskText(value, '99999-999'),
  cpf: (value: string) => maskText(value, '999.999.999-99'),
  cnpj: (value: string) => maskText(value, '99.999.999/9999-99'),
  date: (value: string) => getFormattedDate(value),
  dateWithoutTZ: (value: string) => getFormattedDate(value, { discardTimezone: true }),
  dateTime: (value: string) => getFormattedDate(value, { showHours: true }),
  marital: (value: string) => translate.marital(value),
  mobileNumber: (value: string) => maskMobileNumber(value),
  phoneNumber: (value: string) => maskPhoneNumber(value),
  relationship: (value: string) => translate.relationship(value),
  boolean: (value: string) => translate.boolean(value),
  rg: (value: string) => maskText(value, '99.999.999-9'),
  sex: (value: string) => translate.sex(value),
  text: (value: string) => value,
}

export type FormatData = keyof typeof formatters

const formatText = (value: any, key: FormatData, textDefault = 'Não encontrado'): string => {
  if (!value && !['boolean', 'number'].includes(typeof value)) {
    return '';
  }

  return (
    {
      boolean: translate.boolean,
      cardCode: (value: any) => maskText(value, '9999 '.repeat(20)),
      cep: (value: any) => maskText(value, '99999-999'),
      cnpj: (value: any) => maskText(value, '99.999.999/9999-99'),
      cpf: (value: any) => maskText(value, '999.999.999-99'),
      date: (value: any) => getFormattedDate(value),
      dateWithoutTZ: (value: any) => getFormattedDate(value, { discardTimezone: true }),
      dateTime: (value: any) => getFormattedDate(value, { showHours: true }),
      marital: translate.marital,
      mobileNumber: maskMobileNumber,
      phoneNumber: maskPhoneNumber,
      relationship: translate.relationship,
      rg: (value: any) => maskText(value, '99.999.999-9'),
      sex: translate.sex,
      text: (value: any) => value,
    }[key](value) || textDefault
  );
};

export default formatText;
