import { FINANCIAL_ADJUSTMENT_ACTIONS } from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import { FinancialAdjustmentToTable } from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import ICONS from '@/shareds/constants/icons';
import ButtonDialog from '@/components/buttonDialog';
import React from 'react';
import { ActionsLayer, Button } from 'vkit/lib/components';

interface UseFinancialAdjustmentActionsProps {
  onRemove?: (financialAdjustment: FinancialAdjustmentToTable) => void;
  actions?: FINANCIAL_ADJUSTMENT_ACTIONS[];
}

export const useFinancialAdjustmentTableActions = ({
  actions,
  onRemove,
}: UseFinancialAdjustmentActionsProps) => {
  const getActions = (financialAdjustment: FinancialAdjustmentToTable) => {
    const AVALIBLE_ACTIONS = {
      [FINANCIAL_ADJUSTMENT_ACTIONS.REMOVE]: {
        label: 'Excluir',
        onClick: () => onRemove?.(financialAdjustment),
        icon: ICONS.REMOVE,
      },
    };

    const listActions = actions ? actions.map((action) => AVALIBLE_ACTIONS[action]) : [];

    if (listActions.length === 1) {
      return [
        actions?.[0] === FINANCIAL_ADJUSTMENT_ACTIONS.REMOVE ? (
          <ButtonDialog
            key={financialAdjustment.key || financialAdjustment.id}
            solo
            outlined
            color='danger'
            iconColor='danger'
            label={listActions[0].label}
            icon={listActions[0].icon}
            text='Tem certeza que deseja excluir este ajuste financeiro?'
            onClick={listActions[0].onClick}
            modalType='danger'
          />
        ) : (
          <Button
            key={financialAdjustment.key || financialAdjustment.id}
            solo
            outlined
            label={listActions[0].label}
            onClick={listActions[0].onClick}
            color='colorDefault'
            iconColor='colorDefault'
          />
        ),
      ];
    }

    return [
      <ActionsLayer
        key={financialAdjustment.key || financialAdjustment.id}
        vertical
        actions={listActions}
      />,
    ];
  };

  return {
    getActions,
  };
};

export default useFinancialAdjustmentTableActions;
