import { CADASTRAL_POSITION_ROW_STATUS } from '@/modules/cadastralPosition/entities/cadastralPositionRow.enum';

export const CADASTRAL_POSITION_ROW_STATUS_TRANSLATED: Record<
  CADASTRAL_POSITION_ROW_STATUS,
  string
> = {
  [CADASTRAL_POSITION_ROW_STATUS.NOT_INITIALIZED]: 'Não iniciado',
  [CADASTRAL_POSITION_ROW_STATUS.PROCESSED]: 'Processado',
  [CADASTRAL_POSITION_ROW_STATUS.PROCESSED_WITH_ERROR]: 'Processado com erro',
  [CADASTRAL_POSITION_ROW_STATUS.VALIDATED]: 'Validado',
  [CADASTRAL_POSITION_ROW_STATUS.VALIDATED_WITH_ERROR]: 'Validado com erro',
  [CADASTRAL_POSITION_ROW_STATUS.UNKNOWN]: 'Desconhecido',
};
