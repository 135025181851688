import React, { useMemo } from 'react';
import { Icon } from 'vkit/lib/components';
import style from './iconSquare.module.scss';

interface IconSquareProps {
  size?: number;
  icon?: string;
  iconImageUrl?: string;
  bgTransparent?: boolean;
  backgroundColor?: string;
}

const IconSquare: React.FC<IconSquareProps> = ({
  size = 40,
  icon,
  iconImageUrl,
  bgTransparent,
  backgroundColor = 'var(--vkit-color-default)',
}) => {
  const iconSize = useMemo(() => size - 20, [size]);

  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
      }}
      className={`${style.iconSquare} ${bgTransparent ? style.transparent : ''}`}>
      {icon ? (
        <Icon color='colorDefault' name={icon} size={iconSize} />
      ) : (
        <div
          style={{
            width: iconSize,
            height: iconSize,
            mask: `url(${iconImageUrl}) no-repeat center / contain`,
            WebkitMask: `url(${iconImageUrl}) no-repeat center / contain`,
            backgroundColor,
          }}
        />
      )}
    </div>
  );
};

export default IconSquare;
