import restfulBase from '@/services/accessControl/base';

export interface AuthOTPRecovery {
  userEmail: string;
}

export const authOTPRecoveryInitState = {
  userEmail: '',
};

export const authOTPRecoveryService = restfulBase<AuthOTPRecovery>('auth/otp-reset-requests');
