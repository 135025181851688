import { Route, RouterLoaderPageDetails } from '@/core/entities';
import { decodedJwtToken } from '@/utils/authentication';
import { redirect as reactRouterDomRedirect } from 'react-router-dom';

import { Routes } from '../routes';

/**
 * Function to perform a route redirect
 *
 * @param {string} url - The URL to which the redirection should occur.
 */
export const redirect = (url: string) => {
  /**
   * @see https://reactrouter.com/en/main/fetch/redirect
   */
  return reactRouterDomRedirect(url);
};

/**
 * Function to obtain route permissions when available, and remove routes that the logged in user does not have permission to use.
 */
export const validateRoutes = (routes: Route[]): Route[] => {
  const { user } = decodedJwtToken();
  return routes.reduce((filteredRoutes: Route[], route: Route) => {
    const hasMeta = !!route.meta;
    const hasPermissions = !!route.meta && !!route.meta.permission;

    if (!hasMeta || !hasPermissions) {
      filteredRoutes.push(route);
      return filteredRoutes;
    }

    const currRouteType = route?.meta?.permission?.type;
    const currRoutePermissions = route?.meta?.permission?.values || [];

    const isValid =
      currRouteType === user.type &&
      (currRoutePermissions.length === 0 ||
        currRoutePermissions.some((value: string) => user.permissions.includes(value)));

    if (isValid) {
      filteredRoutes.push(route);
      return filteredRoutes;
    }

    if (route.children && isValid) {
      const validChildren = validateRoutes(route.children);

      if (validChildren.length > 0) {
        route.children = validChildren;
        filteredRoutes.push(route);
      }

      return filteredRoutes;
    }

    return filteredRoutes;
  }, []);
};

export const findRoutePathByName = (nameToFind: string): string | null => {
  const recursiveSearch = (routes: Route[], currentPath = ''): string | null => {
    for (const route of routes) {
      const path = `${currentPath}/${route.path}`;

      if (route.id === nameToFind) {
        return path.replaceAll('//', '/'); // Retorna o caminho quando o nome é encontrado
      }

      if (route.children) {
        const foundInChildren = recursiveSearch(route.children, path);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  };

  return recursiveSearch(Routes);
};

export const createRouteLoaderPageDetails = (props: RouterLoaderPageDetails) => props;
