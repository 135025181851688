import { useNegotiations } from '@/modules/negotiation/hooks';
import { StatusEnum } from '@/shareds/types/status.type';
import React from 'react';
import { Select } from 'vkit/lib/components';
import DataType from 'vkit/lib/components/webform/select/types/data.type';
import useSelectNegotiations from '@/modules/negotiation/hooks/useSelectNegotiations/useSelectNegotiations';
import { SELECT_NEGOTIATION_COLUMNS } from '@/modules/negotiation/entities/enums/selectNegotiation';
import SelectType from 'vkit/lib/components/webform/select/types/select.type';

export interface SelectNegotiationsProps extends Omit<SelectType, 'columns' | 'onChange'> {
  onChange: (negotiationId: string) => void;
  columns?: SELECT_NEGOTIATION_COLUMNS[];
  companyId?: string;
  errorMessage?: string;
  autoload?: boolean;
  onlyActiveContract?: boolean;
}

const SelectNegotiations: React.FC<SelectNegotiationsProps> = ({
  value,
  disabled,
  onChange,
  columns,
  companyId,
  errorMessage,
  autoload,
  onlyActiveContract,
  ...props
}) => {
  const { negotiations } = useNegotiations({
    companyId,
    onlyActiveContract
  });

  const { negotiationOptions, columnSelectOptions } = useSelectNegotiations({
    negotiations,
    columns,
  });

  return (
    <Select
      data={(negotiationOptions.length ? negotiationOptions : null) as DataType[]}
      columns={columnSelectOptions}
      onChange={onChange}
      value={value || ''}
      label='Selecione uma negociação'
      clearable
      disabled={disabled}
      status={errorMessage ? StatusEnum.error : undefined}
      textHelper={errorMessage ? errorMessage ?? '' : undefined}
      {...props}
    />
  );
};

export default SelectNegotiations;
