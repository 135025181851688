import HttpClientAdapter from '@/shared/services/httpClientAdapter';

import { MoveSheet, MoveSheetNormalized, MoveSheetParams, MoveSheetResponse } from '../entities';
import { normalizeSheetLine } from '../utils';

class MoveSheetService extends HttpClientAdapter {
  constructor() {
    super('MONITORING');
  }

  getMoveSheet<MoveSheetResponse>(params: MoveSheetParams) {
    return this.get<MoveSheetResponse>('/rows', params, {
      transformResponse: [this.normalizeMoveSheet],
    });
  }

  getMoveSheetLine<MoveSheet>(idRow: string) {
    return this.get<MoveSheet>(
      `/rows/${idRow}`,
      {},
      {
        transformResponse: [this.normalizeMoveSheetRow],
      },
    );
  }

  private normalizeMoveSheetRow(response: MoveSheet): MoveSheetNormalized {
    return normalizeSheetLine(response);
  }

  private normalizeMoveSheet(response: MoveSheetResponse) {
    let normalized;
    if (response.page) {
      normalized = response.data.map((line) => normalizeSheetLine(line));
    } else {
      const items = response as unknown as MoveSheet[];
      normalized = items.map((line) => normalizeSheetLine(line));
    }

    if (response.page) {
      return {
        ...response,
        data: normalized,
      };
    }
    return {
      data: normalized,
    };
  }
}

export default MoveSheetService;
