import ICONS from '@/shareds/constants/icons';

export enum BENEFICIARY_AVAILABLE_HOME_TABS_ID {
  BENEFICIARY,
  MOVES,
}

export enum BENEFICIARY_MAIN_FILTERS {
  NAME = 'name',
  CPF = 'cpf',
  STATUS = 'status',
}

export enum BENEFICIARY_EXTRA_FILTERS {
  OWNERSHIP = 'ownership',
  HEALTH_CARD = 'health_card',
  BENEFIT = 'benefit',
}

export enum BENEFICIARY_TABLE_COLUMN {
  NAME = 'name',
  CPF = 'cpf',
  HEALTH_CARD = 'healthCard',
  BENEFIT = 'benefit',
  STATUS = 'status',
}

export enum BENEFICIARY_TABLE_ACTION {
  DETAIL = 'detail',
}

export enum OWNERSHIP_TYPE {
  HOLDER = 'holder',
  DEPENDENT = 'dependent',
}

export const BENEFICIARY_AVAILABLE_DETAILS = [
  {
    label: 'Info',
    icon: 'info-outline',
    active: false,
  },
  {
    label: 'Endereço',
    icon: 'pin-outline',
    active: false,
  },
  {
    label: 'Contatos',
    icon: 'phone-call-outline',
    active: false,
  },
  {
    label: 'Benefício',
    icon: 'activity-outline',
    active: false,
  },
  {
    label: 'Dependentes',
    icon: 'people-outline',
    active: false,
  },
  {
    label: 'Titular',
    icon: 'person-outline',
    active: false,
  },
];

export const BENEFICIARY_AVAILABLE_HOME_TABS = [
  {
    id: BENEFICIARY_AVAILABLE_HOME_TABS_ID.BENEFICIARY,
    label: 'Beneficiários',
    icon: 'people-outline',
    active: false,
  },
  {
    id: BENEFICIARY_AVAILABLE_HOME_TABS_ID.MOVES,
    label: 'Histórico de movimentações',
    icon: ICONS.MOVE,
    active: false,
  },
];

export enum SELECT_BENEFICIARY_AVAILABLE_COLUMNS {
  NAME = 'name',
  CPF = 'cpf',
  PLAN = 'plan',
  BENEFIT = 'benefit',
}

export const MAX_BENEFICIARIES_EXPORTED_FILENAME_LENGTH = 255;
