const checkRepeatingChars = (str: string): void => {
  const hasRepeatingChars = str.split('').every((elem) => elem === str[0]);

  if (hasRepeatingChars) {
    throw new Error('CNPJ nãpo de ter todos os números iguais');
  }
};

const checkCountNumbers = (cnpj: string = ''): void => {
  if (cnpj.length !== 14) {
    throw new Error('CNPJ deve conter 14 números');
  }
};

const digit = (numbers: string): number => {
  let index = 2;

  const reverse = numbers
    .split('')
    .reduce((acc: number[], number: string) => [+number].concat(acc), []);

  const sum = reverse.reduce((acc, number) => {
    acc += number * index;
    index = index === 9 ? 2 : index + 1;
    return acc;
  }, 0);

  const mod = sum % 11;

  return mod < 2 ? 0 : 11 - mod;
};

const checkCount = (cnpj: string = ''): void => {
  let registration = cnpj.substring(0, 12);
  registration += digit(registration);
  registration += digit(registration);

  const isValid = registration.substring(-2) === cnpj.substring(-2);

  if (!isValid) {
    throw new Error('CNPJ inválido');
  }
};

const CNPJ = (cnpj: string = ''): string | null => {
  const cleaned = cnpj.toString().replace(/[^\d]+/g, '');

  try {
    checkCountNumbers(cleaned);
    checkRepeatingChars(cleaned);
    checkCount(cleaned);
  } catch (error: any) {
    return error?.message;
  }

  return null;
};

export default CNPJ;
