import { useState, useRef, MutableRefObject, ChangeEventHandler } from 'react';
import { deepClone } from '@/utils';

export interface ValidatedFile {
  name: string;
  valid: boolean;
  errors: string[];
}

interface UseFileUpload {
  useIsDragging: boolean;
  setIsDragging: Function;
  setValidatedFiles: Function;
  onFileDrop: Function;
  handleFileUpload: ChangeEventHandler<HTMLInputElement>;
  handleUploadClick: Function;
  inputFileRef: MutableRefObject<HTMLInputElement | null>;
  validatedFiles: ValidatedFile[];
  removeFile: (fileIndex: number) => void;
}

interface useFileUploadProps {
  name: string;
  onDelete?: Function;
  onChange: Function;
  allowedExtensions: string[];
  notAllowedExtensions: string[];
  multiple?: boolean;
  maxCharacterLimit?: number;
}

const useFileUpload = ({
  name,
  allowedExtensions,
  notAllowedExtensions,
  onChange,
  onDelete,
  multiple,
  maxCharacterLimit,
}: useFileUploadProps): UseFileUpload => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const originFilesRef = useRef<File[]>([]);
  const [validatedFiles, setValidatedFiles] = useState<ValidatedFile[]>([]);
  const [useIsDragging, setIsDragging] = useState(false);

  const getFileExt = (fileName: any): string => fileName.split('.').pop();

  const validate = (file: ValidatedFile | File) => {
    const fileExt = getFileExt(file.name);
    const errors = [];

    if (allowedExtensions.length && !allowedExtensions.includes(fileExt.toLowerCase())) {
      errors.push('Este arquivo não está no formato correto');
    }

    if (notAllowedExtensions.length && notAllowedExtensions.includes(fileExt.toLowerCase())) {
      errors.push('Este formato de arquivo não é permitido');
    }

    if (maxCharacterLimit && file.name.length > maxCharacterLimit) {
      errors.push(
        `Limite de caracteres excedido. Favor reduzir o nome do arquivo para até ${maxCharacterLimit} caracteres`,
      );
    }

    return errors;
  };

  const validateFiles = (files: ValidatedFile[] | File[]) => {
    const validateds = [];
    let hasError = false;

    for (const file of files) {
      const errors = validate(file);

      if (errors.length) {
        hasError = true;
      }

      validateds.push({
        name: file.name,
        valid: !errors.length,
        errors,
      });
    }

    return {
      validateds,
      hasError,
    };
  };

  const changeFile = (file: File, validatedFile: ValidatedFile, isValid: boolean) => {
    onChange(name, file, isValid);
    setValidatedFiles([validatedFile]);
  };

  const addFiles = (files: File[], validatedFiles: ValidatedFile[]) => {
    originFilesRef.current = originFilesRef.current.concat(...files);

    setValidatedFiles((oldFiles) => {
      const currentFiles = oldFiles.concat(validatedFiles);
      const isValid = currentFiles.every((currentFile) => currentFile.valid);
      onChange(name, originFilesRef.current, isValid);
      return currentFiles;
    });
  };

  const onFileDrop = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    const { validateds, hasError } = validateFiles(files);

    if (multiple) {
      addFiles(files, validateds);
    } else {
      changeFile(files[0], validateds[0], !hasError);
    }
  };

  const handleFileUpload = (): any => {
    const files = inputFileRef.current?.files || null;
    if (!files) {
      return;
    }

    const parsedFiles = Array.from(files);
    const { validateds, hasError } = validateFiles(parsedFiles);

    if (multiple) {
      addFiles(parsedFiles, validateds);
    } else {
      changeFile(parsedFiles[0], validateds[0], !hasError);
    }
  };

  const handleUploadClick = (): void => {
    inputFileRef?.current?.click();
  };

  const removeFile = (fileIndex: number) => {
    setValidatedFiles((files) => {
      const clonedfiles = deepClone<ValidatedFile[]>(files);
      clonedfiles.splice(fileIndex, 1);
      originFilesRef.current.splice(fileIndex, 1);
      const { validateds, hasError } = validateFiles(clonedfiles);
      onChange(name, originFilesRef.current, !hasError);
      onDelete?.(files[fileIndex], fileIndex);
      return validateds;
    });
  };

  return {
    inputFileRef,
    useIsDragging,
    setIsDragging,
    validatedFiles,
    setValidatedFiles,
    onFileDrop,
    handleFileUpload,
    handleUploadClick,
    removeFile,
  };
};

export default useFileUpload;
