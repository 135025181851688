import CompaniesService from '@/modules/company/services/companiesService';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Company, ParamsToFilterCompanies } from '@/modules/company';
import { ToastStatusGlobal } from '@/components';
import { maskText } from '@/utils/format';

interface UseSelectCompanyChipsProps {
  value: string[];
  onChange: (value: string[]) => void;
  filters?: Partial<ParamsToFilterCompanies>;
}

const companiesService = new CompaniesService();

const useSelectCompanyChips = ({ value, onChange, filters }: UseSelectCompanyChipsProps) => {
  const [loading, setLoading] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState<Company[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [term, setTerm] = useState('');

  const debounceTimer = useRef(0);

  const { toast } = ToastStatusGlobal();

  const message = useMemo(() => {
    if (term.length > 0 && term.length < 3) {
      return 'Digite pelo menos 3 caracteres para realizar a busca';
    }

    if (term && loadedCompanies.length === 0) {
      return `Nenhuma empresa encontrada com o termo "${term}"`;
    }

    return 'Digite para buscar uma empresa';
  }, [loadedCompanies.length, term]);

  const loadCompanies = useCallback(
    async (filters?: Partial<ParamsToFilterCompanies>) => {
      if (filters?.q && filters?.q.length > 0 && filters?.q.length < 3) {
        return setLoadedCompanies([]);
      }

      setLoading(true);
      try {
        const response = await companiesService.getCompanies(filters);
        setLoadedCompanies(response.data);
      } catch (e) {
        console.error(e);
        toast(
          'Oops',
          'Devido a um erro, não conseguimos buscar as informações da empresa.',
          'error',
        );
      } finally {
        setLoading(false);
      }
    },
    [toast],
  );

  const getCompanies = useCallback(
    async (filters?: Partial<ParamsToFilterCompanies>) => {
      if (filters?.q && filters?.q.length > 0 && filters?.q.length < 3) {
        return [];
      }

      setLoading(true);
      let companies: Company[] = [];

      try {
        const response = await companiesService.getCompanies(filters);
        companies = response.data;
      } catch (e) {
        console.error(e);
        toast(
          'Oops',
          'Devido a um erro, não conseguimos buscar as informações da empresa.',
          'error',
        );
      } finally {
        setLoading(false);
      }

      return companies;
    },
    [toast],
  );

  const addSelected = useCallback(
    (value: Company[]) => {
      onChange(value.map(({ id }) => id));
    },
    [onChange],
  );

  const removeSelected = useCallback(
    (value: Company[]) => {
      onChange(value.map(({ id }) => id));
    },
    [onChange],
  );

  const getTextFromSelected = useCallback((value: Company) => {
    return `${value.name} | ${maskText(value?.cnpj, '99.999.999/9999-99')}`;
  }, []);

  const onSearch = useCallback(
    (value: string) => {
      setTerm(value);

      window.clearTimeout(debounceTimer.current);

      debounceTimer.current = window.setTimeout(() => {
        loadCompanies({ q: value, ...filters });
      }, 500);
    },
    [filters, loadCompanies],
  );

  const loadSelecteds = useCallback(
    async (companyIds: string[]) => {
      const companies = await getCompanies({ id: companyIds });

      if (!companies.length) {
        return;
      }

      setSelectedCompanies(companies);
      setLoadedCompanies((currentCompanies) => {
        const companiesNotFoundInCurrentCompanies = companies.filter((company) => {
          return !currentCompanies.some((currentCompany) => currentCompany.id === company.id);
        });

        return [...companiesNotFoundInCurrentCompanies, ...currentCompanies];
      });
    },
    [getCompanies],
  );

  useEffect(() => {
    loadCompanies(filters);
  }, [filters, loadCompanies]);

  useEffect(() => {
    if (!value.length || selectedCompanies.length) {
      return;
    }

    loadSelecteds(value);
  }, [selectedCompanies, loadSelecteds, value]);

  useEffect(() => {
    const multSelectInputElement = document.querySelector('#search_input');
    multSelectInputElement?.addEventListener('blur', () => loadCompanies());

    return multSelectInputElement?.removeEventListener('blur', () => loadCompanies());
  }, [loadCompanies]);

  return {
    selectedCompanies,
    message,
    addSelected,
    removeSelected,
    onSearch,
    loading,
    loadedCompanies,
    getTextFromSelected,
  };
};

export default useSelectCompanyChips;
