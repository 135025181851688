import noImage from '@/assets/images/no-camera.png';
import React, { useMemo } from 'react';
import { Image } from 'vkit/lib/components';

enum Size {
  Minor = 'minor',
  Small = 'small',
  Medium = 'medium',
  Larger = 'larger',
  XLarger = 'xLarger',
}
interface LogoProviderProps {
  providerCnpj: string;
  size?: `${Size}`;
}

const LogoProvider: React.FC<LogoProviderProps> = ({ providerCnpj, size }) => {
  const imageSrc = useMemo(() => {
    if (providerCnpj) {
      return `https://s3.sa-east-1.amazonaws.com/app.vitta/provider-logos/${providerCnpj}.png`;
    }
    return noImage;
  }, [providerCnpj]);

  return (
    <div style={{ width: '40px' }}>
      <Image type='rounded' size={size} src={imageSrc} imageDefaultUrl={noImage} />
    </div>
  );
};

export default LogoProvider;
