import { Life } from '@/services/beneficiaries/endpoints/lives';
import { Base } from '@/shareds/types/base.type';

export enum LIFE_DOCUMENT_TYPE {
  CPF = 'cpf',
  RG = 'rg',
  CARTAO_SUS = 'cartao_sus',
}

export interface LifeDocument extends Base {
  type: LIFE_DOCUMENT_TYPE;
  value: string;
  lifeId: string;
  life?: Life;
}
