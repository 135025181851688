import { Tooltip } from '@/components';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import React, { useMemo } from 'react';
import { Button, Tabs } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { BENEFICIARY_AVAILABLE_DETAILS } from '../../entities';

interface BeneficiaryDetailsActionsProps {
  isLoading?: boolean;
  isHolder?: boolean;
  showUpdateAndRemoveActions?: boolean;
  disableRemoveAction?: boolean;
  disableUpdateAction?: boolean;
  currentTab: number;
  setCurrentTab: (tab: number) => void;
}

const BeneficiaryDetailsActions: React.FC<BeneficiaryDetailsActionsProps> = ({
  isLoading,
  isHolder,
  showUpdateAndRemoveActions,
  disableUpdateAction,
  disableRemoveAction,
  currentTab,
  setCurrentTab,
}) => {
  const navigate = useHistoryNavigator();
  const { pathname } = useHistoryLocation();

  const baseUrl = pathname.replace('/details', '');

  const tooltipMessage = disableRemoveAction
    ? 'Este beneficiário não pode ser excluído pois está cancelado ou em processamento.'
    : '';

  const tabsToRender = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return BENEFICIARY_AVAILABLE_DETAILS.filter((tab) => {
      if ((isHolder && tab.label === 'Titular') || (!isHolder && tab.label === 'Dependentes')) {
        return false;
      }
      return true;
    }).map((tab, index) => ({
      ...tab,
      active: index === currentTab,
    }));
  }, [currentTab, isHolder, isLoading]);

  return (
    <Row style={{ padding: 0 }}>
      <Grid alignContent='justify' gap={24} alignItems='center'>
        <Tabs iconDir='left' elements={[]} actions={tabsToRender} onChange={setCurrentTab} />

        <Row style={{ padding: '10px 0 0' }}>
          {showUpdateAndRemoveActions && !isLoading && (
            <Grid gap={24} alignContent='right'>
              <Button
                disabled={disableUpdateAction}
                label='Solicitar alteração de beneficiário'
                onClick={() => navigate.push(`${baseUrl}/update`)}
                solo
                outlined
              />
              <Tooltip title={tooltipMessage}>
                <Button
                  disabled={disableRemoveAction}
                  label='Solicitar exclusão de beneficiário'
                  onClick={() => navigate.push(`${baseUrl}/removal`)}
                  color='danger'
                  outlined
                />
              </Tooltip>
            </Grid>
          )}
        </Row>
      </Grid>
    </Row>
  );
};

export default BeneficiaryDetailsActions;
