/**
 * @namespace httpClient
 * @version 2.0.0
 *
 * The `HttpClientConfig` class provides a configuration for making HTTP requests using Axios.
 * It sets up the Axios instance with a base URL and authorization header.
 * Subclasses can extend this class to customize the Axios instance further.
 *
 * @remarks
 * It is important to configure the API keys in the `apis.ts` configuration file for proper usage.
 */
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { DOMAIN_URL, DomainKey } from './domain';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';

// Unificar pontos de requisição de dados, task https://vitta-dev.atlassian.net/browse/HEF-1831
class HttpClientConfig {
  private domain: string;
  private axiosInstance: AxiosInstance;

  /**
   * Creates an instance of `HttpClientConfig` with the provided API key.
   * @param apiKey - The API key to configure the base URL for the Axios instance.
   * @throws Error if the provided API key is not configured in the `apis.ts` file.
   * @throws Error if no authentication token is found.
   */
  constructor(domain: DomainKey) {
    this.domain = this.getDomainAddress(domain);
    this.axiosInstance = axios.create({
      baseURL: this.domain,
      validateStatus: (status) => {
        return status >= 200 && status < 400;
      },
    });

    this.setAuthorizationHeader();
    this.setResponseTimeoutInterceptor();
  }

  private getDomainAddress(domain: DomainKey): string {
    if (!DOMAIN_URL[domain]) {
      throw Error(`The given API key (${domain}) is not configured.\n
      Add a new key to the 'apis.ts' configuration file to be able to use it.`);
    }

    return DOMAIN_URL[domain];
  }

  private setAuthorizationHeader(token?: string | null): void {
    this.axiosInstance.interceptors.request.use((config) => {
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
      }
      config.headers.Authorization = `Bearer ${token ?? localStorage.getItem('token')}`;
      return config;
    });
  }

  private setResponseTimeoutInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 408) {
          dispatchToast({
            text: 'Ops! Problemas de conexão. recarregue a página.',
            showJustOne: true,
          });
          return new Promise(() => {});
        }
        if (error.code === 'ECONNABORTED') {
          dispatchToast({
            text: 'Ops! Problemas de conexão. Verifique sua internet e recarregue a página.',
            showJustOne: true,
          });
          return new Promise(() => {});
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * Returns the Axios instance configured by this `HttpClientConfig`.
   * Subclasses can override this method to provide additional customization.
   * @returns The configured Axios instance.
   */
  protected getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  protected getDomain(): string {
    return this.domain;
  }
}

export default HttpClientConfig;
