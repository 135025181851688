import restfulBase from '@/services/negotiations/core/base';

export interface BenefitsProviders {
  id: string;
  name: string;
}

export interface CompanyBenefits {
  id: string;
  name: string;
  providers: BenefitsProviders[];
}

const apiService = (companyId: string) => restfulBase(`companies/${companyId}/benefits`);

export default apiService;
