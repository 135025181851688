import { ShowHide } from '@/components';
import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { authService } from '@/services/accessControl/endpoints/authConfirm';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Button, Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

type Steps = 'message' | 'success';

const AuthConfirmationExpiredOrNotExist: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const navigate = useHistoryNavigator();
  const [currentStep, setCurrentStep] = useState<Steps>('message');
  const { userId, code } = useHistoryQuery();

  const requestNewConfirmation = async (): Promise<void> => {
    try {
      setLoading(true);
      setMessageError('');

      await authService.createEmailConfirm({ userId, code });

      setCurrentStep('success');
    } catch (error) {
      console.error(error);
      const messageError = get(
        'error',
        'error.response.data.message',
        'Falha ao solicitar nova confirmação de e-mail.',
      );
      setMessageError(messageError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        maxWidth: 350,
        width: '100%',
      }}>
      <ShowHide transition='slideToDown' visible={currentStep === 'message'}>
        <Grid alignContent='center'>
          <Icon color='warning' size={64} name='alert-triangle-outline' />
        </Grid>

        <Row style={{ textAlign: 'center' }}>
          <Text title color='warning' value='Link expirado ou inexistente' margin='0 0 8px' />

          <Text size='medium' value='Este link para a confirmação do seu usuário está expirado.' />
        </Row>

        <Grid column gap={16}>
          <Button full bold loading={loading} onClick={requestNewConfirmation}>
            <Row>Solicitar nova confirmação</Row>
          </Button>
          <Button
            full
            bold
            invertColor
            disabled={loading}
            onClick={() => navigate.push('/auth/login')}>
            <Row>Já confirmei meu e-mail</Row>
          </Button>
        </Grid>
      </ShowHide>

      <ShowHide transition='slideToDown' visible={currentStep === 'success'}>
        <Grid alignContent='center'>
          <Icon color='success' size={64} name='done-all-outline' />
        </Grid>

        <Row style={{ textAlign: 'center' }}>
          <Text
            title
            color='success'
            value='Seu link foi solicitado com sucesso'
            margin='0 0 8px'
          />

          <Text
            size='medium'
            value='Em instantes você receberá um e-mail com um novo link para confirmar o seu usuário.'
          />
        </Row>

        <Button
          full
          bold
          invertColor
          disabled={loading}
          onClick={() => navigate.push('/auth/login')}>
          <Row>Ir para tela de login</Row>
        </Button>
      </ShowHide>

      <ShowHide transition='slideToDown' visible={!!messageError}>
        <Text
          value={messageError}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          margin='16px 0 0 '
          size='medium'
        />
      </ShowHide>
    </div>
  );
};

export default AuthConfirmationExpiredOrNotExist;
