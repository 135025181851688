import { FileUpload, ShowHide } from '@/components';
import { CADASTRAL_DATA_MATCHING_ALLOWED_EXTENSIONS } from '@/modules/cadastralDataMatching';
import { ObjectType } from '@/shareds/types';
import React from 'react';
import { Text } from 'vkit/lib/components';

interface UploadAreaProps {
  onChange: (...item: any) => void;
  onBlur: (...item: any) => void;
  useErrors: ObjectType;
  setShowMessageError: (showMessageError: boolean) => void;
  showMessageError: boolean;
  messageError: string | null;
}

const UploadArea: React.FC<UploadAreaProps> = ({
  onChange,
  onBlur,
  useErrors,
  setShowMessageError,
  showMessageError,
  messageError,
}) => {
  return (
    <>
      <FileUpload
        label='Selecione a planilha correspondente'
        name='files'
        allowedExtensions={CADASTRAL_DATA_MATCHING_ALLOWED_EXTENSIONS}
        onChange={(name: string, value: File) => {
          onChange(name, value);
          onBlur(name, value);
          setShowMessageError(false);
        }}
        formValidatorErrors={useErrors}
      />

      <ShowHide transition='fade' visible={showMessageError}>
        <Text
          margin='16px 0 0'
          padding='8px 16px'
          size='medium'
          rounded
          color='danger'
          applyBaseColor
          value={messageError || ''}
        />
      </ShowHide>
    </>
  );
};

export default UploadArea;
