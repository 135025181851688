import React, { useEffect, useState } from 'react';

import { Grid } from 'vkit/lib/context';

import { analytics } from '@/shared/telemetry/telemetry';
import { Filters, Table, TableProps } from '../../components';
import {
  AUDIT_LOGS_DEFAULT_INITIAL_PAGE,
  AUDIT_LOGS_DEFAULT_PAGE_SIZE,
  EventLogParams,
} from '../../entities';

interface DataTableProps extends Pick<TableProps, 'columns'> {}

const DataTable: React.FC<DataTableProps> = ({ columns }) => {
  const [filters, setFilters] = useState<EventLogParams>({
    pageSize: AUDIT_LOGS_DEFAULT_PAGE_SIZE,
    page: AUDIT_LOGS_DEFAULT_INITIAL_PAGE,
    include: ['eventLogData', 'eventLogAction', 'eventLogEntity'],
    sort: '-eventTime',
  });

  useEffect(() => {
    analytics.track('AUDIT_FILTERS', {
      ...filters,
    });
  }, [filters]);

  return (
    <Grid gap={24} column>
      <Filters filters={filters} handleChangeFilters={setFilters} />

      <Table columns={columns} filters={filters} handleFilters={setFilters} />
    </Grid>
  );
};

export default DataTable;
