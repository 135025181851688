import { useMemo } from 'react';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { ADDITIONAL_SERVICES_TABLE_COLUMN } from '../entities/constants';
import { AdditionalServicesNormalized } from '../entities/interfaces';
import Status from '../components/status/Status';
import LogoSvas from '../components/sva/logoSvas';

interface UseAdditionalServicesColumnsProps {
  columns?: ADDITIONAL_SERVICES_TABLE_COLUMN[];
}

const useAdditionalServicesTableColumns = ({ columns }: UseAdditionalServicesColumnsProps) => {
  const AVAILABLE_COLUMNS: Record<ADDITIONAL_SERVICES_TABLE_COLUMN, DataTableColumn> = useMemo(
    () => ({
      [ADDITIONAL_SERVICES_TABLE_COLUMN.LOGO]: {
        name: '',
        path: 'provider',
        format: ({ provider }: AdditionalServicesNormalized) => (
          <LogoSvas providerCnpj={String(provider.cnpj)} size='small' />
        ),
      },
      [ADDITIONAL_SERVICES_TABLE_COLUMN.PROVIDER]: {
        name: 'Fornecedor',
        path: 'provider.name',
      },
      [ADDITIONAL_SERVICES_TABLE_COLUMN.BENEFIT]: {
        name: 'Benefício',
        path: 'sva.name',
      },
      [ADDITIONAL_SERVICES_TABLE_COLUMN.STATUS]: {
        name: 'Status',
        path: 'status',
        format: ({ status }: AdditionalServicesNormalized) => <Status label={status} />,
      },
      [ADDITIONAL_SERVICES_TABLE_COLUMN.ACTIVATION]: {
        name: 'Ativação',
        path: 'validFrom',
      },
    }),
    [],
  );

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVAILABLE_COLUMNS);
    }
    return columns.map((column) => AVAILABLE_COLUMNS[column]);
  }, [columns, AVAILABLE_COLUMNS]);

  return {
    useColumns,
  };
};

export default useAdditionalServicesTableColumns;
