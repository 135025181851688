import { FormValidate, GridGroup, ShowHide, Space, TextFieldValidate } from '@/components';
import { BodyForm } from '@/components/formValidate';
import { useHistoryNavigator } from '@/navigation';
import {
  AuthPasswordRecovery,
  authPasswordRecoveryService,
} from '@/services/accessControl/endpoints/authPasswordRecovery';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

type Steps = 'email' | 'success';

interface AuthLoginProps {
  email?: string;
}

const AuthLogin: React.FC<AuthLoginProps> = ({ email }) => {
  const [useData, setData] = useState<AuthPasswordRecovery>({
    email: email || '',
  });
  const [useLoading, setLoading] = useState(false);
  const [useMessageError, setMessageError] = useState('');
  const [useCurrentStep] = useState<Steps>('email');
  const navigate = useHistoryNavigator();

  const sendLink = async (): Promise<void> => {
    try {
      setLoading(true);
      setMessageError('');
      await authPasswordRecoveryService.create(useData);
      navigate.push('/auth/success?recovery=email');
    } catch (error) {
      console.error(error);
      const messageError = get(
        'error',
        'error.response.data.message',
        'Falha ao enviar o link de recuperação de senha',
      );
      setMessageError(messageError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const getFields = ({ onChange, useErrors, onBlur, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          hidden: !!email,
          middle: 100,
          default: 100,
          component: (
            <Text
              size='medium'
              textAlign='center'
              value={'Informe seu e-mail e receba um link para alterar sua senha.'}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              icon='email-outline'
              label='E-mail'
              name='email'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Informe seu e-mail'
              required
              useErrors={useErrors}
              useValues={useValues}
            />
          ),
        },
      ]}
    />
  );

  return (
    <div
      style={{
        width: '100%',
      }}>
      <ShowHide transition='slideToDown' visible={useCurrentStep === 'email'}>
        <FormValidate
          resource={useData}
          fields={{
            email: ['required', 'email'],
          }}
          onChangeForm={setData}
          onError={() => setMessageError('Existem alguns campos com preenchimento irregular.')}
          onSubmit={sendLink}
          body={getFields}
          footer={
            <Row style={{ padding: '32px 0 0' }}>
              <Button submit full bold loading={useLoading}>
                <Row>Quero alterar minha senha</Row>
              </Button>
            </Row>
          }
        />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={useCurrentStep === 'success'}>
        <Grid alignContent='center'>
          <Icon color='success' size={64} name='done-all-outline' />
        </Grid>

        <Row style={{ textAlign: 'center' }}>
          <Text title color='success' value='Tudo certo' margin='0 0 8px' />

          <Text
            size='medium'
            value={`Um link para alterar sua senha foi enviado para o e-mail <b>${useData.email}</b>.`}
          />
        </Row>
      </ShowHide>

      <ShowHide transition='slideToDown' visible order={2}>
        <Space horizontal />
        <Button
          full
          bold
          invertColor
          disabled={useLoading}
          onClick={() => navigate.push('/auth/login')}>
          <Row>Acessar com outro e-mail ou senha</Row>
        </Button>
      </ShowHide>

      <ShowHide transition='slideToDown' visible={!!useMessageError}>
        <Text
          value={useMessageError}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          margin='16px 0 0 '
          size='medium'
        />
      </ShowHide>
    </div>
  );
};

export default AuthLogin;
