import { format, parseISO } from 'date-fns';
import { Unit } from '@/shareds/enums/unit';

interface FormattedDateOptions {
  showHours: boolean;
  discardTimezone: boolean;
}

export function getFormattedDate(value: string, options?: Partial<FormattedDateOptions>) {
  if (value && value.length >= 10) {
    const dateToParse = options?.discardTimezone ? value.replace(/z$/i, '') : value;

    const parsedDate = parseISO(dateToParse);
    if (parsedDate?.getDate()) {
      return format(parsedDate, `dd/MM/yyyy${options?.showHours ? '\', às\' HH:mm' : ''}`);
    }
  }

  return value ?? '';
}

export function getFormattedDateEUA(value?: string): string {
  if (value && value.length >= 10) {
    const [dateFound, day, month, year] = value.match(/(\d{2})\/(\d{2})\/(\d{4})/) || [];
    const parsedDate = new Date(`${year}-${month}-${day}`);

    return dateFound && parsedDate?.getDate() ? `${year}-${month}-${day}` : value;
  }

  return value ?? '';
}

export function maskCpf(cpf: string): string {
  const parsedCpf = `${cpf}`;

  if (parsedCpf.length !== 11) return '';

  return (
    `${parsedCpf.substring(0, 3)}.` +
    `${parsedCpf.substring(3, 6)}.` +
    `${parsedCpf.substring(6, 9)}-` +
    parsedCpf.substring(9, 11)
  );
}

export function maskText(value: string, format: string): string {
  if (!value || !format) {
    return '';
  }

  let result = '';
  let indexFormatSplit = 0;
  let indexValueSplit = 0;
  const valueNumbers = `${value}`.replace(/\D/g, '');

  while (indexValueSplit < valueNumbers.length && indexFormatSplit < format.length) {
    const charFormat: string = format[indexFormatSplit];
    const charValue: string = valueNumbers[indexValueSplit];
    const replaceble: boolean = charFormat === '9';

    result += replaceble ? charValue : charFormat;

    if (replaceble) {
      indexValueSplit += 1;
    }

    indexFormatSplit += 1;
  }

  return result;
}

export function maskMobileNumber(value?: string): string {
  if (!value) {
    return '';
  }

  const number = value.length > 11 ? value.replace(/^(?:(?:\+|00)?(55)\s?)/, '') : value;

  return maskText(number, '(99) 9 9999-9999');
}

export function maskPhoneNumber(value?: string): string {
  if (!value) {
    return '';
  }

  const number = value.length > 10 ? value.replace(/^(?:(?:\+|00)?(55)\s?)/, '') : value;

  return maskText(number, '(99) 9999-9999');
}

export function getCurrencyFormat(value = 0, code = 'BRL', maskReverse?: boolean): string {
  let newValue = value;

  if (maskReverse) {
    let newValueSplited = String(newValue).split('');
    if (newValueSplited.length === 1) newValueSplited.unshift('0');
    newValueSplited.splice(-2, 0, '.');
    newValue = +newValueSplited.join('');
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: code,
  }).format(newValue);
}

export const formatToUnit = (value: number, unit: Unit) => {
  return unit === Unit.CURRENCY ? getCurrencyFormat(value) : `${value}%`;
};
