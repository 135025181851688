import useBeneficiariesTableActions from '@/modules/beneficiary/hooks/useBeneficiariesTableActions/useBeneficiariesTableActions';
import useBeneficiariesTableColumns from '@/modules/beneficiary/hooks/useBeneficiariesTableColumns/useBeneficiariesTableColumns';
import React from 'react';

import { DataTable } from 'vkit/lib/components';
import {
  BENEFICIARY_TABLE_ACTION,
  BENEFICIARY_TABLE_COLUMN,
  BeneficiarySearchParams,
} from '../../entities';
import { useBeneficiaries } from '../../hooks';

interface TableProps {
  actions?: BENEFICIARY_TABLE_ACTION[];
  columns?: BENEFICIARY_TABLE_COLUMN[];
  filters?: BeneficiarySearchParams;
  handleChange: (params: Partial<BeneficiarySearchParams>) => void;
  linesLoader?: number;
}

const Table: React.FC<TableProps> = ({ actions, linesLoader, columns, filters, handleChange }) => {
  const { isLoading, beneficiaries, pagination } = useBeneficiaries(filters);

  const { useColumns } = useBeneficiariesTableColumns({
    columns,
  });

  const { getActions } = useBeneficiariesTableActions({
    actions,
  });

  const handleChangePage = (value: number): void => {
    handleChange({
      ...filters,
      page: value,
    });
  };
  const handleChangePageSize = (value: number): void => {
    handleChange({
      ...filters,
      pageSize: value,
      page: 1,
    });
  };

  return (
    <DataTable
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={!isLoading ? beneficiaries : []}
      isLoading={isLoading}
      action={{ items: getActions }}
      showPagination={!isLoading}
      total={pagination.total}
      totalPages={pagination.totalPages}
      page={pagination.page}
      pageSize={pagination.pageSize}
      onChangePage={handleChangePage}
      onChangePageSize={handleChangePageSize}
    />
  );
};

export default Table;
