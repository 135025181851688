import restfulService from '@/utils/restful';
import { AxiosRequestHeaders } from 'axios';

type ParamType = { [key: string]: any };

export const restfulBase = (
  service: string,
): {
  create: Function;
  find: Function;
  list: Function;
  listAll: Function;
  remove: Function;
  update: Function;
  index: Function;
} => {
  const { REACT_APP_API_NEGOTIATIONS_URL = '' } = process.env;

  const api = restfulService({
    service,
    uri: REACT_APP_API_NEGOTIATIONS_URL,
  });

  api.apiInstance.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  });

  return {
    create: async (params: ParamType, url?: string): Promise<any> => {
      const response = await api.post(params, url);
      return response?.data || response;
    },

    find: async (id: string, params?: ParamType): Promise<any> => {
      const response = await api.get(params, id);
      return response?.data || response;
    },

    list: async (params: ParamType, url?: string): Promise<any> => {
      const response = await api.get(params, url);
      return response?.data?.results || response?.data || response;
    },

    listAll: async (params: ParamType, uri?: string): Promise<any> => {
      const restful = restfulBase(service);
      const { totalPages, results, data } = (await restful.index(params, uri)) || {};
      const list = results || data;
      const pages = Array.from({ length: totalPages - 1 }, (_, num) => num + 2);

      if (list?.length < 10) {
        return list;
      }

      for (const page of pages) {
        const items = (await restful.list({ ...params, page }, uri)) || [];
        list.push(...items);
      }

      return list;
    },

    index: async (params: ParamType, uri?: string): Promise<any> => {
      const response = await api.get(params, uri);
      return Array.isArray(response?.data) ? response : response?.data;
    },

    remove: async (id: string, url?: string): Promise<any> => api.remove(id, url),

    update: async (id: string, params: ParamType, uri?: string): Promise<any> =>
      api.update(params, id, uri),
  };
};

export default restfulBase;
