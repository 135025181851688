import jwtDecode from 'jwt-decode';

export const getToken = (): string | null => localStorage.getItem('token');

export const decodedJwtToken = (token = getToken()): any | null => {
  if (token) {
    const decoded = jwtDecode(token) as any;
    if (Date.now() <= decoded.exp * 1000) {
      return decoded;
    }
  }

  return {
    user: {},
  };
};

export const removeStoredData = (): void => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('company');
};

export const isAuthenticated = (): boolean => {
  const data = decodedJwtToken();
  if (data) {
    return Date.now() <= data?.exp * 1000;
  }

  return false;
};
