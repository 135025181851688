import { ObjectType } from '@/shareds/types';
import React from 'react';
import SelectValidate from '@/components/selectValidate';

export interface SelectPortionTypeProps {
  name?: string;
  onBlur?: Function;
  onChange?: Function;
  useErrors?: ObjectType;
  useValues: ObjectType;
  label?: string;
  required?: boolean;
  small?: boolean;
}

const SelectFinancialUnit: React.FC<SelectPortionTypeProps> = ({
  onBlur,
  onChange,
  useErrors,
  useValues,
  required,
  name = 'unit',
  label = 'Tipo de valor',
  small,
}) => {
  return (
    <SelectValidate
      name={name}
      label={label}
      useValues={useValues}
      onChange={onChange}
      onBlur={onBlur}
      useErrors={useErrors}
      data={[
        {
          text: 'Porcentagem',
          value: 'percentage',
        },
        {
          text: 'Moeda',
          value: 'currency',
        },
      ]}
      required={required}
      small={small}
    />
  );
};

export default SelectFinancialUnit;
