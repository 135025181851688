import React from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import WordBreak from '@/components/wordBreak/wordBreak';

interface EntityProps {
  data: {
    userIp?: string;
    userAgent?: string;
  };
}

const Device: React.FC<EntityProps> = ({ data }) => {
  return (
    <Grid column gap={4}>
      {data.userIp && <Text value={data.userIp} size='medium' />}
      {data.userAgent && (
        <WordBreak>
          <Text value={data.userAgent} size='small' />
        </WordBreak>
      )}
    </Grid>
  );
};

export default Device;
