import { ChipStatus } from '@/modules/user/components';
import { getFormattedDate } from '@/utils/format';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { USER_AVAILABLE_TABLE_COLUMNS } from '../../../entities';

interface UseTableUserColumns {
  columns?: `${USER_AVAILABLE_TABLE_COLUMNS}`[];
}

const useTableUserColumns = ({ columns }: UseTableUserColumns) => {
  const availableColumns: Record<`${USER_AVAILABLE_TABLE_COLUMNS}`, DataTableColumn> = {
    [USER_AVAILABLE_TABLE_COLUMNS.NAME]: {
      name: 'Nome',
      path: 'name',
    },
    [USER_AVAILABLE_TABLE_COLUMNS.EMAIL]: {
      name: 'E-mail',
      path: 'email',
    },
    [USER_AVAILABLE_TABLE_COLUMNS.STATUS]: {
      name: 'Status',
      path: 'status',
      format: (row) => {
        return <ChipStatus status={row.status} />;
      },
    },
    [USER_AVAILABLE_TABLE_COLUMNS.CREATED_AT]: {
      name: 'Criado em',
      path: 'createdAt',
      format: (row) => {
        return getFormattedDate(row?.createdAt);
      },
    },
  };

  const currentColumns = columns
    ? columns.map((column) => availableColumns[column])
    : Object.values(availableColumns);

  return {
    currentColumns,
  };
};

export default useTableUserColumns;
