import { BROKER_PERMISSION, COMPANY_PERMISSION } from '@/core/entities/interfaces/permissions';
import { useCurrentUser } from '@/modules/profile';
import { USER_STATUS, USER_TYPE } from '@/modules/user/entities';
import { useMemo } from 'react';

interface UseRecoveryActionsRulesProps {
  status?: `${USER_STATUS}`;
  scope: `${USER_TYPE}`;
  userId: string;
}

const useRecoveryActionsRules = ({ status, scope, userId }: UseRecoveryActionsRulesProps) => {
  const { checkUserHasPermission, user } = useCurrentUser();

  const hasPermissionUnblock = useMemo((): boolean => {
    return (
      (scope === USER_TYPE.BROKER &&
        checkUserHasPermission(BROKER_PERMISSION.BROKER_USER_UNBLOCK)) ||
      (scope === USER_TYPE.COMPANY &&
        checkUserHasPermission(COMPANY_PERMISSION.COMPANY_USER_UNBLOCK))
    );
  }, [checkUserHasPermission, scope]);

  const showUnblock = Boolean(status) && status !== USER_STATUS.INACTIVATED;

  const disableUnblockAction = useMemo(() => {
    return !hasPermissionUnblock || status !== USER_STATUS.BLOCKED;
  }, [hasPermissionUnblock, status]);

  const tooltipMessageUnblock = useMemo((): string | undefined => {
    if (status !== USER_STATUS.BLOCKED) {
      return 'Você não pode desloquear um usuário que não está bloqueado.';
    }
    if (!hasPermissionUnblock) {
      return 'Você não tem permissão para desloquear usuários.';
    }
    return undefined;
  }, [hasPermissionUnblock, status]);

  const hasPermissionInative = useMemo((): boolean => {
    return (
      (scope === USER_TYPE.BROKER &&
        checkUserHasPermission(BROKER_PERMISSION.BROKER_USER_DELETE)) ||
      (scope === USER_TYPE.COMPANY &&
        checkUserHasPermission(COMPANY_PERMISSION.COMPANY_USER_DELETE))
    );
  }, [checkUserHasPermission, scope]);

  const showInactive = Boolean(status) && status !== USER_STATUS.INACTIVATED;

  const disableInativeAction = useMemo((): boolean => {
    return !hasPermissionInative || userId === user.id;
  }, [hasPermissionInative, user.id, userId]);

  const tooltipMessageInactive = useMemo((): string | undefined => {
    if (!hasPermissionInative) {
      return 'Você não tem permissão para inativar usuários.';
    }
    if (userId === user.id) {
      return 'Você não pode inativar seu próprio usuário.';
    }
    return undefined;
  }, [hasPermissionInative, user.id, userId]);

  const hasPermissionRestore = useMemo((): boolean => {
    return (
      (scope === USER_TYPE.BROKER &&
        checkUserHasPermission(BROKER_PERMISSION.BROKER_USER_RESTORE)) ||
      (scope === USER_TYPE.COMPANY &&
        checkUserHasPermission(COMPANY_PERMISSION.COMPANY_USER_RESTORE))
    );
  }, [checkUserHasPermission, scope]);

  const showRestore = Boolean(status) && status == USER_STATUS.INACTIVATED;

  const disableRestoreAction = useMemo(() => !hasPermissionRestore, [hasPermissionRestore]);

  const tooltipMessageRestore = useMemo((): string | undefined => {
    if (!hasPermissionRestore) {
      return 'Você não tem permissão para restaurar usuários.';
    }
    return undefined;
  }, [hasPermissionRestore]);

  return {
    showUnblock,
    disableUnblockAction,
    tooltipMessageUnblock,
    showInactive,
    disableInativeAction,
    tooltipMessageInactive,
    showRestore,
    disableRestoreAction,
    tooltipMessageRestore,
  };
};

export default useRecoveryActionsRules;
