import React, { ReactNode, useMemo } from 'react';
import { get } from 'lodash';
import formatText from '@/utils/formatText';
import { ActionsLayer, Icon, Text } from 'vkit/lib/components';
import { classesBuilder } from '@/utils';
import { ObjectType } from '@/shareds/types';
import { EmptyMessage, ShowHide } from '@/components';
import { Grid } from 'vkit/lib/context';
import { TableType } from '@/components/dataTable/hooks/useDataList';
import style from './style/contentListLines.module.scss';
import { Action, Column } from '@/components/dataTable';
import verifyPermissions from '@/utils/verifyPermissions';

type SortDirection = 'ASC' | 'DESC' | 'NONE';
type OnSort = (value?: string | null) => void;

export interface ColumnSort {
  path?: string;
  direction?: SortDirection;
}

interface ContentListLinesProps {
  actionsByLine?: (...itens: any) => Action[] | ReactNode;
  columnSort?: ColumnSort;
  columns: Column[];
  data: ObjectType[];
  hideColumns?: string[];
  linesLoader?: number;
  refreshData?: (updateFilters?: ObjectType) => Promise<void>;
  loading?: boolean;
  onChangeType?: (type: TableType) => void;
  onShowSettings?: (show: boolean) => void;
  onSort?: OnSort;
  onToggleHideColumn?: (index: number) => void;
  showSettings?: boolean;
  totalRows?: number;
}

export interface ButtonSortProps {
  column: Column;
  onSort?: OnSort;
  direction: SortDirection;
}

export interface ActionsByLineProps {
  actions: (...itens: any) => Action[] | ReactNode;
  refreshData?: (updateFilters?: ObjectType) => Promise<void>;
  objectLine: ObjectType;
}

const ButtonSort: React.FC<ButtonSortProps> = ({ column, onSort, direction }) => {
  let value: string | null = null;

  switch (direction) {
    case 'NONE':
      value = column.path!;
      break;
    case 'ASC':
      value = `-${column.path}`;
      break;
  }

  return (
    <div className={style.buttonSort} onClick={() => onSort?.(value)}>
      <Grid alignContent='center' alignItems='center'>
        {column.title}

        <div className={style.iconOrder}>
          <ShowHide absolute transition='fade' visible={direction === 'DESC'}>
            <Icon name='arrow-down' />
          </ShowHide>

          <ShowHide absolute transition='fade' visible={direction === 'NONE'}>
            <div className={style.iconOrderNone}>
              <Icon name='arrow-up-outline' />
              <Icon name='arrow-down-outline' />
            </div>
          </ShowHide>

          <ShowHide absolute transition='fade' visible={direction === 'ASC'}>
            <Icon name='arrow-up' />
          </ShowHide>
        </div>
      </Grid>
    </div>
  );
};

const ActionsByLine: React.FC<ActionsByLineProps> = ({ actions, objectLine, refreshData }) => {
  const actionsByLine = useMemo(
    () => actions(objectLine, refreshData),
    [actions, objectLine, refreshData],
  );

  return (
    <>
      {Array.isArray(actionsByLine) ? (
        <ActionsLayer vertical actions={actionsByLine?.filter(verifyPermissions)} />
      ) : (
        actionsByLine
      )}
    </>
  );
};

const LINES_LOADER_DEFAULT = 3;
const ContentListLines: React.FC<ContentListLinesProps> = ({
  actionsByLine,
  columnSort,
  columns = [],
  data = [],
  linesLoader = LINES_LOADER_DEFAULT,
  refreshData,
  loading,
  onSort,
}) => {
  const generateLineKey = (indexLine: number) => `line_${indexLine}`;
  const generateCellKey = (indexLine: number, indexCell: number) =>
    `line_${indexLine}_cell_${indexCell}`;

  return (
    <>
      <div className={style.contentList}>
        {!!data.length && (
          <div className={style.header}>
            <div className={style.line}>
              {columns.map((column, index) => (
                <div
                  style={{ width: column.width || 'auto' }}
                  key={`${column.title}_${index}`}
                  className={classesBuilder(style, {
                    cell: true,
                    [column.align || 'left']: column.align,
                  })}>
                  <Grid alignContent={column.align} alignItems='center'>
                    {column.sortable ? (
                      <ButtonSort
                        direction={
                          columnSort && columnSort.path === column.path
                            ? columnSort.direction!
                            : 'NONE'
                        }
                        column={column}
                        onSort={onSort}
                      />
                    ) : (
                      column.title
                    )}
                  </Grid>
                </div>
              ))}

              {actionsByLine && (
                <div
                  className={classesBuilder(style, {
                    cell: true,
                    right: true,
                  })}
                />
              )}
            </div>
          </div>
        )}

        {!loading && !!data.length && (
          <div className={style.body}>
            {(data || []).map((item, indexLine) => (
              <div key={generateLineKey(indexLine)} className={style.line}>
                {columns.map((column, indexCell) => (
                  <div
                    key={generateCellKey(indexLine, indexCell)}
                    className={classesBuilder(style, {
                      cell: true,
                      [column.align || 'left']: column.align,
                    })}>
                    {column.custom?.(item) ||
                      (column.path &&
                        formatText(get(item, column.path, ''), column.type || 'text')) ||
                      '-'}
                  </div>
                ))}

                {actionsByLine && (
                  <div
                    style={{ minWidth: 20, width: 20 }}
                    className={classesBuilder(style, {
                      cell: true,
                      right: true,
                      actions: true,
                    })}>
                    <ActionsByLine
                      refreshData={refreshData}
                      actions={actionsByLine}
                      objectLine={item}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {loading && (
          <div className={style.body}>
            {Array.from({ length: linesLoader }).map((_, indexLine) => (
              <div key={generateLineKey(indexLine)} className={style.line}>
                {columns.map((_, indexCell) => (
                  <div key={generateCellKey(indexLine, indexCell)} className={style.cell}>
                    <Text value='' rounded />
                  </div>
                ))}

                {actionsByLine && (
                  <div style={{ minWidth: 20, width: 20 }} className={style.cell}>
                    <Text value='' rounded />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {!loading && !data.length && (
        <Grid margin='-20px 0 0'>
          <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
        </Grid>
      )}
    </>
  );
};

export default ContentListLines;
