import {
  CONFIRMATION_TABLE_COLUMNS,
  Confirmation,
  TYPES_TRANSLATE,
} from '@/modules/user/entities/confirmation';
import { getFormattedDate } from '@/utils/format';
import { useMemo } from 'react';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import Timer from '../../Timer/Timer';
import ConfirmationStatus from '../../confirmationStatus/confirmationStatus';

interface UseUserConfirmationTableColumns {
  columns?: `${CONFIRMATION_TABLE_COLUMNS}`[];
}

const useUserConfirmationTableColumns = ({ columns }: UseUserConfirmationTableColumns) => {
  const availableColumns = useMemo((): DataTableColumn<Confirmation>[] => {
    const available = {
      [CONFIRMATION_TABLE_COLUMNS.OPERATION]: {
        name: 'Operação',
        path: 'type',
        format: ({ type }: Confirmation) => TYPES_TRANSLATE[type],
      },
      [CONFIRMATION_TABLE_COLUMNS.CREATED_AT]: {
        name: 'Criado em',
        path: 'createdAt',
        format: ({ createdAt }: Confirmation) =>
          createdAt ? getFormattedDate(createdAt, { showHours: true }) : '-',
      },
      [CONFIRMATION_TABLE_COLUMNS.CONFIRMED_AT]: {
        name: 'Confirmado em',
        path: 'confirmedAt',
        format: ({ confirmedAt }: Confirmation) =>
          confirmedAt ? getFormattedDate(confirmedAt, { showHours: true }) : '-',
      },
      [CONFIRMATION_TABLE_COLUMNS.STATUS]: {
        name: 'Status',
        path: 'status',
        format: (confirmation: Confirmation) => {
          return (
            <>
              <ConfirmationStatus confirmation={confirmation} />
              {confirmation.expiresAt && !confirmation.confirmedAt && !confirmation.isExpired && (
                <Timer dateEnd={new Date(confirmation.expiresAt)} />
              )}
            </>
          );
        },
      },
      [CONFIRMATION_TABLE_COLUMNS.CREATED_BY]: {
        name: 'Solicitado por',
        path: 'createdByUser.name',
      },
    };

    return columns ? columns.map((column) => available[column]) : Object.values(available);
  }, [columns]);

  return {
    availableColumns,
  };
};

export default useUserConfirmationTableColumns;
