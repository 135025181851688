import BoxInfo from '@/components/boxInfo';
import FormValidate, { BodyForm } from '@/components/formValidate';
import GridGroup from '@/components/gridGroup';
import TextFieldValidate from '@/components/textFieldValidate';
import ToastStatusGlobal from '@/components/toastStatusGlobal';
import { Contract } from '@/modules/contract';
import SelectContract from '@/modules/financialAdjustment/components/selectContract';
import { FINANCIAL_ADJUSTMENT_UNIT } from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import { ParamsToCrateFinancialAdjustment } from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import SelectFinancialUnit from '@/modules/financialAdjustment/components/selectFinancialUnit/selectFinancialUnit';
import SelectPortionType from '@/modules/financialAdjustment/components/selectPortionType/selectPortionType';
import ICONS from '@/shareds/constants/icons';
import React, { Dispatch, SetStateAction } from 'react';

interface FinancialAdjustmentFormProps {
  processParamsFromForm: (paramsFromForm: ParamsToCrateFinancialAdjustment) => void;
  loading: boolean;
  paramsFromForm: ParamsToCrateFinancialAdjustment;
  setParamsFromForm: Dispatch<SetStateAction<ParamsToCrateFinancialAdjustment>>;
}

const FinancialAdjustmentForm: React.FC<FinancialAdjustmentFormProps> = ({
  processParamsFromForm,
  loading,
  paramsFromForm,
  setParamsFromForm,
}) => {
  const { toast } = ToastStatusGlobal();

  const getFields = ({ onBlur, onChange, useErrors, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          default: 100,
          component: (
            <SelectContract
              onChange={(name: string, value: string, contract: Contract) => {
                setParamsFromForm((current) => ({
                  ...current,
                  [name]: value,
                  contract,
                }));
              }}
              onBlur={onBlur}
              useErrors={useErrors}
              useValues={useValues}
              required
            />
          ),
        },

        {
          default: 20,
          component: (
            <TextFieldValidate
              label='Quantidade de meses'
              name='countMonths'
              onBlur={onBlur}
              onChange={onChange}
              useErrors={useErrors}
              useValues={useValues}
              mask='99'
              placeholder='1'
            />
          ),
        },

        {
          default: 20,
          component: (
            <TextFieldValidate
              label='Data'
              name='date'
              onBlur={onBlur}
              onChange={onChange}
              useErrors={useErrors}
              useValues={useValues}
              type='date'
              required
            />
          ),
        },

        {
          default: 20,
          component: (
            <SelectPortionType
              useValues={useValues}
              onChange={onChange}
              onBlur={onBlur}
              useErrors={useErrors}
              required
            />
          ),
        },

        {
          default: 20,
          component: (
            <SelectFinancialUnit
              useValues={useValues}
              onChange={onChange}
              useErrors={useErrors}
              onBlur={onBlur}
              required
            />
          ),
        },

        {
          default: 20,
          component: (
            <TextFieldValidate
              label='Valor'
              name='value'
              onBlur={onBlur}
              onChange={onChange}
              placeholder={useValues.unit === FINANCIAL_ADJUSTMENT_UNIT.CURRENCY ? 'R$ 0,00' : '0%'}
              useErrors={useErrors}
              useValues={useValues}
              money={useValues.unit === FINANCIAL_ADJUSTMENT_UNIT.CURRENCY}
              required
              mask={
                useValues.unit === FINANCIAL_ADJUSTMENT_UNIT.CURRENCY ? 'R$ 99.999,99' : '999999999'
              }
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              label='Descrição'
              name='description'
              onBlur={onBlur}
              onChange={onChange}
              useErrors={useErrors}
              useValues={useValues}
              multiline
              maxLength={256}
              required
              header={`${useValues.description.length} de 256`}
            />
          ),
        },
      ]}
    />
  );

  return (
    <BoxInfo icon={ICONS.FINANCIAL_ADJUSTMENTS} title='Dados do ajuste financeiro'>
      <FormValidate
        resource={paramsFromForm}
        fields={{
          contractId: ['required'],
          description: ['required'],
          countMonths: [],
          portionType: ['required'],
          unit: ['required'],
          date: ['required'],
          value: ['required'],
        }}
        onChangeForm={setParamsFromForm}
        onError={() => {
          toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning');
        }}
        onSubmit={processParamsFromForm}
        body={getFields}
        buttons={{
          submit: {
            loading,
            text: 'Adicionar ajuste financeiro',
          },
        }}
      />
    </BoxInfo>
  );
};

export default FinancialAdjustmentForm;
