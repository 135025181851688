import { ShowHide } from '@/components';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import React, { useCallback, useEffect } from 'react';
import { Button, Dialog, Icon, Loader, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { BeneficiarySearchParams } from '../../entities';
import useExportBeneficiriesModal from './hook/useExportBeneficiariesModal';

interface ExportBeneficiariesModalProps {
  isOpen: boolean;
  handleClose: (value: boolean) => void;
  filters: BeneficiarySearchParams;
}

const ExportBeneficiariesModal: React.FC<ExportBeneficiariesModalProps> = ({
  isOpen,
  handleClose,
  filters,
}) => {
  const { companyId } = useCompany();
  const { loading, error, success, disableCloseButton, getExportedData, resetAll } =
    useExportBeneficiriesModal();

  const handleCloseModal = useCallback(() => {
    if (disableCloseButton) {
      return;
    }
    resetAll();
    handleClose(false);
  }, [disableCloseButton, handleClose, resetAll]);

  const exportData = useCallback(() => {
    if (companyId) {
      getExportedData({
        companyId,
        ...filters,
      });
    }
  }, [companyId, filters, getExportedData]);

  useEffect(() => {
    if (isOpen) {
      exportData();
    }
  }, [exportData, isOpen]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      open={isOpen}
      elevation={2}
      size='larger'
      persistent
      title={
        <Text
          value='Exportar beneficiários'
          title
          size='larger'
          icon='people-outline'
          iconColor='colorDefault'
          color='colorDefault'
          iconSize={32}
          textAlign='left'
        />
      }
      body={
        <>
          <ShowHide transition='fade' visible={loading}>
            <Row>
              <Grid alignContent='center'>
                <Loader color='colorDefault' />
              </Grid>
              <Text
                margin='12px 0'
                size='larger'
                textAlign='center'
                value='Processando sua solicitação'
              />
            </Row>
            <Row>
              <Text
                margin='12px 0'
                size='medium'
                textAlign='center'
                value='Aguarde alguns instantes, estamos processando sua solicitação e realizando a geração dos dados. Em alguns instantes o download solicitado será inicializado automaticamente.'
              />
            </Row>
          </ShowHide>

          <ShowHide transition='fade' visible={!loading && success}>
            <Row>
              <Grid alignContent='center'>
                <Icon name='done-all-outline' color='success' size={48} />
              </Grid>
              <Text
                margin='12px 0'
                size='larger'
                textAlign='center'
                value='Solicitação realizada com sucesso!'
                color='success'
              />
            </Row>
            <Row>
              <Text
                margin='12px 0'
                size='medium'
                textAlign='center'
                value='Sua solicitação foi atendida com sucesso e o download do arquivo já foi iniciado.'
              />
            </Row>
          </ShowHide>

          <ShowHide transition='fade' visible={!loading && error}>
            <Row>
              <Grid alignContent='center'>
                <Icon name='slash-outline' color='danger' size={48} />
              </Grid>
              <Text
                margin='12px 0'
                size='larger'
                textAlign='center'
                value='Ocorreu um erro e sua solicitação não foi atendida!'
                color='danger'
              />
            </Row>
            <Row>
              <Text
                margin='12px 0'
                size='medium'
                textAlign='center'
                value='Um erro aconteceu e não conseguimos processar sua solicitação com sucesso. Caso queira tentar novamente utilize a ação abaixo.'
              />

              <Grid alignContent='center'>
                <Button
                  label='Tentar novamente'
                  size='medium'
                  color='danger'
                  outlined
                  solo
                  onClick={exportData}
                />
              </Grid>
            </Row>
          </ShowHide>
        </>
      }
      footer={
        <Grid alignContent='right' alignItems='center' gap={24}>
          <Button
            disabled={disableCloseButton}
            label='Fechar'
            icon='close'
            iconColor='colorDefault'
            size='medium'
            color='colorDefault'
            outlined
            solo
            onClick={handleCloseModal}
          />
        </Grid>
      }
    />
  );
};

export default ExportBeneficiariesModal;
