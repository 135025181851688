import { ShowHide } from '@/components';
import { InitialRating, Rating } from '@/modules/feedback/entities';
import React, { useState } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { Message, RatingList } from '..';

interface FeedbackProps {
  setFeedback: (rating?: Rating, message?: string) => void;
}

const Feedback: React.FC<FeedbackProps> = ({ setFeedback }) => {
  const [rating, setRating] = useState<Rating[]>(InitialRating);

  const [selectedRating, setSelectedRating] = useState<number>(0);

  const [message, setMessage] = useState<string>('');

  const handleSelectRating = (value: number) => {
    setSelectedRating(value);
    const updatedRating = rating.map((item) => ({
      ...item,
      isChecked: item.value <= value,
    }));
    setFeedback(rating.find((item) => item.value === value));
    setRating(updatedRating);
  };

  const handleMessageChange = (value: string) => {
    setMessage(value);
    setFeedback(undefined, value);
  };

  return (
    <>
      <Row>
        <Grid alignContent='left' column gap={6}>
          <Row style={{ padding: 0 }}>
            <Text textAlign='center' value='Como está sendo sua experiência?' size='larger' />
          </Row>
          <Text
            textAlign='center'
            size='medium'
            value='Este é mais uma forma de nos ajudar a melhorar sua experiência.'
          />
        </Grid>
      </Row>
      <Row>
        <RatingList data={rating} handleRatingCheck={handleSelectRating} />
      </Row>
      <ShowHide visible={selectedRating > 0} transition='slideToDown'>
        <Message ratingValue={selectedRating} value={message} setValue={handleMessageChange} />
      </ShowHide>
    </>
  );
};

export default Feedback;
