import React, { useEffect, useMemo, useState } from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import TextType from 'vkit/lib/shared/components/text/types/text.type';
import { Confirmation, TYPES } from '../../entities/confirmation';

const ConfirmationStatus: React.FC<{ confirmation: Confirmation }> = ({ confirmation }) => {
  const { type, isExpired, confirmedAt } = useMemo(() => confirmation, [confirmation]);
  const [statusOptions, setStatusOptions] = useState<TextType>({
    color: 'warning',
    value: 'Aguardando confirmação',
  });

  useEffect(() => {
    if (confirmedAt) {
      return setStatusOptions({
        color: 'success',
        value: 'Confirmado',
      });
    }

    if (isExpired && type !== TYPES.REGISTRATION) {
      return setStatusOptions({
        color: 'danger',
        value: 'Expirado',
      });
    }
  }, [confirmedAt, isExpired, type]);

  return (
    <Grid>
      <Chip rounded size='small' label={statusOptions.value} color={statusOptions.color} />
    </Grid>
  );
};

export default ConfirmationStatus;
