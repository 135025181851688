import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { useEffect, useMemo, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { useCurrentUser } from '@/modules/profile';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { isEmpty } from 'lodash';
import { MoveNormalized, MoveParams, MoveReponseNormalized } from '../../entities';
import { MoveService } from '../../services';

interface UseMove {
  moves: MoveNormalized[];
  isEmptyMoves: boolean;
  isLoading: boolean;
  showPagination: boolean;
  pagination: BasePagination;
}

const moveService = new MoveService();

const useMoveList = (params?: MoveParams): UseMove => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [moves, setMoves] = useState<MoveNormalized[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const { user } = useCurrentUser();

  const isEmptyMoves = useMemo(() => !moves.length && !isLoading, [isLoading, moves.length]);

  useEffect(() => {
    const fetchData = async (queryParams: MoveParams) => {
      if (!user) {
        return;
      }

      setLoading(true);

      try {
        const { data, page, totalPages, total, pageSize } =
          await moveService.getMoves<MoveReponseNormalized>(queryParams);
        setMoves(data);
        setPagination({
          page,
          totalPages,
          pageSize,
          total,
        });
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! Não foi possível buscar as movimentações cadastrais.',
        });
      } finally {
        setLoading(false);
      }
    };

    if (isEmpty(params)) {
      return;
    }

    if (company?.id) {
      fetchData({
        companyId: company.id,
        ...params,
      });
    }

    return () => {
      setMoves([]);
    };
  }, [company.id, params, user]);

  const showPagination = useMemo(() => {
    return (
      (pagination?.totalPages !== undefined && pagination.totalPages > 1) ||
      (moves.length > 10 && !isLoading)
    );
  }, [isLoading, moves.length, pagination.totalPages]);

  return {
    isLoading,
    moves,
    isEmptyMoves,
    showPagination,
    pagination,
  };
};

export default useMoveList;
