import { TextData } from '@/components';
import { MoveNormalized } from '@/modules/move/entities';
import React from 'react';

interface InformationDataProps {
  loading: boolean;
  data?: MoveNormalized;
}

export const InformationData: React.FC<InformationDataProps> = ({ loading, data }) => {
  return (
    <TextData
      loading={loading}
      data={[
        {
          label: 'Nome do arquivo',
          text: data?.name,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Formato do arquivo',
          text: data?.extension,
          sizeDefault: 33.33,
          sizeMedium: 50,
        },
        {
          label: 'Data upload',
          text: data?.uploadedAt,
          type: 'dateTime',
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
        {
          label: 'Status',
          text: data?.status,
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
      ]}
    />
  );
};

export default InformationData;
