import React from 'react';
import { Chip, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface ReportListItemHeaderProps {
  line: string | number;
  hasError: boolean;
}

export const ReportListItemHeader: React.FC<ReportListItemHeaderProps> = ({ line, hasError }) => {
  return (
    <Grid gap={24} alignItems='center'>
      <Grid gap={8}>
        <Text value='Linha:' size='medium' />
        <Text fontWeight='bold' value={line.toString()} size='medium' />
      </Grid>
      {hasError && (
        <Grid gap={8}>
          <Chip size='small' rounded color='danger' label='Contém erro(s)' />
        </Grid>
      )}
    </Grid>
  );
};
