import { SelectValidate } from '@/components';
import { ReasonAndSubReason, ReasonAndSubReasonValues } from '@/modules/attendance/entities';
import { useReasonAndSubReason } from '@/modules/attendance/hooks';
import React, { useEffect } from 'react';
import { ObjectType } from '@/shareds/types';

export interface SelectReasonAndSubReasonProps {
  isDisabled?: boolean;
  values: ReasonAndSubReasonValues;
  onChangeSelect: (name: ReasonAndSubReason, value: string) => void;
  small?: boolean;
  showLabel?: boolean;
  showPlaceholder?: boolean;
  required?: boolean;
  useErrors?: ObjectType;
  onBlur?: Function;
  onChange?: Function;
}

const SelectReasonAndSubReason: React.FC<SelectReasonAndSubReasonProps> = ({
  isDisabled,
  values,
  onChangeSelect,
  small,
  showLabel,
  showPlaceholder,
  required,
  useErrors,
  onBlur,
  onChange,
}) => {
  const { isLoading, loadReasonAndSubReason, reasonOptions, subReasonOptions } =
    useReasonAndSubReason({ reason: values.reason });

  const handlerReasonSubReasonChange = (name: ReasonAndSubReason, value: string) => {
    if (name === 'reason' && values.subReason) {
      onChangeSelect('subReason', '');
    }
    onChangeSelect(name, value);
  };

  useEffect(() => {
    loadReasonAndSubReason();
  }, [loadReasonAndSubReason]);

  return (
    <>
      <SelectValidate
        loading={isLoading}
        disabled={isDisabled}
        data={reasonOptions?.length ? reasonOptions : null}
        label={showLabel ? 'Motivo' : undefined}
        placeholder={showPlaceholder ? 'Motivo' : undefined}
        name='reason'
        useValues={values}
        small={small}
        required={required}
        useErrors={useErrors}
        onBlur={onBlur}
        onChange={(name: string, value: any) => {
          onChange?.(name, value);
          onBlur?.(name, value);
          handlerReasonSubReasonChange(name as ReasonAndSubReason, value);
        }}
      />
      <SelectValidate
        disabled={isLoading || !values.reason || !subReasonOptions?.length || isDisabled}
        data={subReasonOptions?.length ? subReasonOptions : null}
        label={showLabel ? 'Submotivo' : undefined}
        placeholder={showPlaceholder ? 'Submotivo' : undefined}
        name='subReason'
        useValues={values}
        small={small}
        required={required && !!values.reason}
        useErrors={useErrors}
        onBlur={onBlur}
        key={`subreason-${values.reason}`}
        onChange={(name: string, value: any) => {
          onChange?.(name, value);
          onBlur?.(name, value);
          handlerReasonSubReasonChange(name as ReasonAndSubReason, value);
        }}
      />
    </>
  );
};

export default SelectReasonAndSubReason;
