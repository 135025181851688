import { BoxInfo, FormValidate, ToastStatusGlobal } from '@/components';
import { BodyForm } from '@/components/formValidate';
import {
  CADASTRAL_DATA_MATCHING_UPLOAD_STEPS,
  useCadastralDataMatchingUpload,
} from '@/modules/cadastralDataMatching';
import UploadArea from '@/modules/cadastralDataMatching/components/uploadArea/uploadArea';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { useHistoryNavigator } from '@/navigation';
import { MovesValidationError } from '@/services/files/endpoints/moves';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import React, { useCallback } from 'react';
import { Button } from 'vkit/lib/components';
import { downloadFileLink } from '@/utils/common';

interface UploadFormProps {
  setCurrentStep: (step: CADASTRAL_DATA_MATCHING_UPLOAD_STEPS) => void;
  setErrors: (errors: MovesValidationError) => void;
}

const UploadForm: React.FC<UploadFormProps> = ({ setCurrentStep, setErrors }) => {
  const navigate = useHistoryNavigator();
  const { hasCompanyConfig , hasSettingsDataMatchingDefaultLayout } = useCompany();
  const { toast } = ToastStatusGlobal();
  const {
    formValues,
    isLoading,
    setFormValues,
    handleSubmit,
    handleError,
    messageError,
    showMessageError,
    setShowMessageError,
  } = useCadastralDataMatchingUpload({
    setCurrentStep,
    setErrors,
  });

  const downloadTemplateFile = (): void => {
    const cadastralDataMatchingTemplate =
      process.env.REACT_APP_API_CADASTRAL_DATA_MATCHING_TEMPLATE_FILE_URL;
    if (cadastralDataMatchingTemplate) {
      downloadFileLink(cadastralDataMatchingTemplate, 'bate-cadastral-template.csv');
    } else {
      toast('Oops!', 'Falha ao baixar arquivo.', 'error');
    }
  };

  const bodyForm = useCallback(
    ({ onChange, onBlur, useErrors }: BodyForm) => (
      <UploadArea
        onChange={onChange}
        onBlur={onBlur}
        useErrors={useErrors}
        setShowMessageError={setShowMessageError}
        showMessageError={showMessageError}
        messageError={messageError}
      />
    ),
    [messageError, setShowMessageError, showMessageError],
  );

  return (
    <BoxInfo
      title='Upload de bate cadastral'
      iconImageUrl={ICONS.CADASTRAL_DATA_MATCHING}
      header={
        hasSettingsDataMatchingDefaultLayout() ? (
          <Button
            bold
            invertColor
            onClick={downloadTemplateFile}
            label='Download do arquivo modelo'
          />
        ) : null
      }>
      <FormValidate
        resource={formValues}
        onChangeForm={setFormValues}
        onBack={navigate.goBack}
        onSubmit={handleSubmit}
        onError={handleError}
        fields={{
          files: ['required'],
        }}
        body={bodyForm}
        buttons={{
          submit: {
            disabled: !hasCompanyConfig(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT),
            text: 'Concluir',
            loading: isLoading,
          },
          back: {
            text: 'Voltar',
          },
        }}
      />
    </BoxInfo>
  );
};

export default UploadForm;
