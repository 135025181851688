import useCompanyApplication from '@/core/hooks/useCompanyApplication';
import { findRoutePathByName } from '@/core/utils';
import { Main } from '@/core/views';
import { ROUTE_MOVES } from '@/modules/move/routes/moveRoute';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import React, { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Button, Tabs } from 'vkit/lib/components';
import { Box, Grid, Row } from 'vkit/lib/context';
import {
  BENEFICIARY_AVAILABLE_HOME_TABS,
  BENEFICIARY_AVAILABLE_HOME_TABS_ID,
} from '../../entities';
import { ROUTES_BENEFICIARY } from '../../routes';
import { BlockingMessage, Tooltip } from '@/components';
import { COMPANY_STATUS } from '@/modules/company/entities/company.enum';
import { useCurrentUser } from '@/modules/profile';
import { HubActionsModal } from '../../components'
import { analytics } from '@/shared/telemetry/telemetry';

const Home: React.FC = () => {
  const { pathname } = useHistoryLocation();
  const navigate = useHistoryNavigator();
  const { companyId, statusCompany } = useCompanyApplication();
  const { isCompanyUser } = useCurrentUser();

  const [isOpenHubAction, setOpenHubAction] = useState<boolean>(false);

  const handleHubBeneficiaries = () => {
    analytics.track('[START] - Hub Beneficiários');
    setOpenHubAction(!isOpenHubAction);
  };

  const renderTabs = useMemo(() => {
    return BENEFICIARY_AVAILABLE_HOME_TABS.map((tab) => ({
      ...tab,
      active: tab.id === BENEFICIARY_AVAILABLE_HOME_TABS_ID.MOVES && pathname.includes('moves'),
    }));
  }, [pathname]);

  const handleChangeTabs = (newTab: number) => {
    const beneficiaryRouteFinded = findRoutePathByName(ROUTES_BENEFICIARY.LIST);
    const moveRouteFinded = findRoutePathByName(ROUTE_MOVES.LIST);
    if (newTab === BENEFICIARY_AVAILABLE_HOME_TABS_ID.BENEFICIARY && beneficiaryRouteFinded) {
      return navigate.push(generatePath(beneficiaryRouteFinded, { companyId }));
    }
    if (newTab === BENEFICIARY_AVAILABLE_HOME_TABS_ID.MOVES && moveRouteFinded) {
      return navigate.push(generatePath(moveRouteFinded, { companyId }));
    }
  };

  return (
    <>
      {statusCompany === COMPANY_STATUS.INACTIVE && isCompanyUser ? (
        <Box elevation={4} type='sheet' outlined rounded>
          <Grid alignContent='center' alignItems='center' padding='0 0 32px'>
            <BlockingMessage
              vertical
              title=''
              description='Esta empresa não possui contratos ativos com a Vitta. Portanto, não é possível realizar movimentações de beneficiários.'
            />
          </Grid>
        </Box>
      ) : (
        <Main invertPageHeaderAndChildren data-testid='main'>
          <Row style={{ padding: 0 }}>
            <Grid alignContent='justify' alignItems='center'>
              <Tabs iconDir='left' elements={[]} actions={renderTabs} onChange={handleChangeTabs} />
              <Tooltip title={'Faça inclusões, alterações e exclusões de beneficiários'}>
                <Button label='Atualizar beneficiários' onClick={handleHubBeneficiaries} outlined />
              </Tooltip>
            </Grid>
          </Row>
          <HubActionsModal isOpen={isOpenHubAction} callbackAction={setOpenHubAction} />
        </Main>
      )}
    </>
  );
};

export default Home;
