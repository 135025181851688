import { BoxInfo, TextData } from '@/components';
import { TextItemDataType } from '@/components/textData/types/textData.type';
import { TRANSLATED_CONTRACT_STATUS } from '@/modules/contract/entities/contract.const';
import { LogoProvider } from '@/modules/provider';
import React, { useMemo } from 'react';
import ICONS from '@/shareds/constants/icons';
import { formatToUnit } from '@/utils/format';
import { Contract } from '@/modules/contract';

type InfoKey =
  | 'code'
  | 'provider'
  | 'copay'
  | 'contribution'
  | 'status'
  | 'validFrom'
  | 'validUntil'
  | 'modalName'
  | 'reimbursementCoefficient';

interface DetailsProps {
  loading?: boolean;
  contract: Contract;
  items: {
    key: InfoKey;
    size: number;
  }[];
}

const Infos: React.FC<DetailsProps> = ({ loading, contract, items }) => {
  const infosToChoose = useMemo<{ [key in InfoKey]: TextItemDataType }>(
    () => ({
      code: {
        label: 'Código do contrato',
        text: contract.code,
      },
      provider: {
        label: 'Fornecedor',
        text: contract.provider?.name,
        component: contract.provider?.cnpj ? (
          <LogoProvider size='small' providerCnpj={String(contract.provider?.cnpj)} />
        ) : null,
      },
      copay: {
        label: 'Coparticipação',
        text:
          contract.copayValue && contract.copayUnit
            ? formatToUnit(contract.copayValue, contract.copayUnit)
            : '-',
      },
      contribution: {
        label: 'Contribuição',
        text:
          contract.contributionValue && contract.contributionUnit
            ? formatToUnit(contract.contributionValue, contract.contributionUnit)
            : '-',
      },
      status: {
        label: 'Status',
        text: TRANSLATED_CONTRACT_STATUS[contract.status],
      },
      validFrom: {
        label: 'Início de vigência',
        text: contract.validFrom,
        type: 'date',
      },
      validUntil: {
        label: 'Fim de vigência',
        text: contract.validUntil,
        type: 'date',
      },
      modalName: {
        label: 'Modelo de contrato',
        text: contract.model?.name,
      },
      reimbursementCoefficient: {
        label: 'Coeficiente de reembolso',
        text: contract.reimbursementCoefficient ? String(contract.reimbursementCoefficient) : '-',
      },
    }),
    [contract],
  );

  const data = useMemo(() => {
    return items.map(({ key, size }) => ({
      ...infosToChoose[key],
      sizeDefault: size,
    }));
  }, [infosToChoose, items]);

  return (
    <BoxInfo title='Dados do contrato ' icon={ICONS.CONTRACT_DATA}>
      <TextData loading={loading} data={data} />
    </BoxInfo>
  );
};

export default Infos;
