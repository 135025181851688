import React from 'react';

import { BoxInfo, EmptyMessage, TextData } from '@/components';

import { ParsedTextItemDataType } from '@/components/textData/types/textData.type';
import { LIFE_CONTACT_TYPE_LABEL, LifeNormalized } from '@/modules/beneficiary/entities';

type LifeContactsProps = {
  life?: Partial<LifeNormalized>;
};

const LifeContacts: React.FC<LifeContactsProps> = ({ life }) => {
  const contacts = life?.contacts || [];
  const hasContacts = contacts.length > 0;

  const lifeContactsMap =
    (hasContacts &&
      contacts.map(
        (contact): ParsedTextItemDataType => ({
          label: LIFE_CONTACT_TYPE_LABEL[contact?.type],
          text: contact.value,
          type: contact?.type === 'phone' ? 'mobileNumber' : 'text',
        }),
      )) ||
    [];

  return (
    <BoxInfo title='Contatos' icon='phone-call-outline'>
      {hasContacts && <TextData loading={!life} data={lifeContactsMap} />}

      {!hasContacts && (
        <EmptyMessage title='Nada por aqui!' description='Nenhum contato cadastrado.' />
      )}
    </BoxInfo>
  );
};

export default LifeContacts;
