import { Address } from '../entities';
import { AddressViaCep } from './viacep';

export const normalizeSearchedAddress = (address: AddressViaCep): Address => ({
  zipCode: address.cep.replace('-', ''),
  city: address.localidade,
  complement: address.complemento,
  neighborhood: address.bairro,
  location: address.logradouro,
  state_abbr: address.uf,
});
