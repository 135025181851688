import { Color } from 'vkit/lib/shared/types';

/**
 * Enum defining the possible beneficiary status list.
 */
export enum BENEFICIARY_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'canceled',
  DELETED = 'deleted',
  EXTENDED = 'extended',
  PRE_ACTIVE = 'pre-active',
  PRE_CANCELLED = 'pre-canceled',
  WAITING = 'waiting',
}

/**
 * Enum defining the translated aliases for beneficiary status list.
 */
export enum BENEFICIARY_STATUS_ALIAS_TRANSLATED {
  ACTIVE = 'Ativo',
  CANCELLED = 'Cancelado',
}

export const BENEFICIARY_STATUS_COLOR: Record<BENEFICIARY_STATUS_ALIAS_TRANSLATED, Color> = {
  [BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE]: 'success',
  [BENEFICIARY_STATUS_ALIAS_TRANSLATED.CANCELLED]: 'danger',
};

/**
 * Object mapping the translated beneficiary status list to their corresponding aliases.
 */
export const BENEFICIARY_STATUS_TRANSLATED: Record<
  BENEFICIARY_STATUS,
  BENEFICIARY_STATUS_ALIAS_TRANSLATED
> = Object.freeze({
  [BENEFICIARY_STATUS.ACTIVE]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
  [BENEFICIARY_STATUS.CANCELLED]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.CANCELLED,
  [BENEFICIARY_STATUS.DELETED]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.CANCELLED,
  [BENEFICIARY_STATUS.EXTENDED]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
  [BENEFICIARY_STATUS.PRE_ACTIVE]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
  [BENEFICIARY_STATUS.PRE_CANCELLED]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
  [BENEFICIARY_STATUS.WAITING]: BENEFICIARY_STATUS_ALIAS_TRANSLATED.ACTIVE,
});

/**
 * Object mapping the beneficiary status list to their corresponding arrays of status values.
 */
export const BENEFICIARY_STATUS_REFER: Record<'ACTIVE' | 'CANCELLED', BENEFICIARY_STATUS[]> =
  Object.freeze({
    ACTIVE: [
      BENEFICIARY_STATUS.WAITING,
      BENEFICIARY_STATUS.PRE_CANCELLED,
      BENEFICIARY_STATUS.EXTENDED,
      BENEFICIARY_STATUS.ACTIVE,
      BENEFICIARY_STATUS.PRE_ACTIVE,
    ],
    CANCELLED: [BENEFICIARY_STATUS.CANCELLED, BENEFICIARY_STATUS.DELETED],
  });
