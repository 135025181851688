import React, { useEffect, useRef, useState } from 'react';
import { Text } from 'vkit/lib/components';

interface ChipTimerDownProps {
  dateEnd: Date;
}

interface CounterTime {
  text: string;
  hour: number;
  distance: number;
}

const counterTime = (dateEnd: Date): CounterTime => {
  const countDownDate = dateEnd.getTime();
  const now = new Date().getTime();
  const distance = countDownDate - now;
  const hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    text: `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`,
    hour,
    distance,
  };
};

const TimerDown: React.FC<ChipTimerDownProps> = ({ dateEnd }) => {
  const [time, setTime] = useState('00:00');
  const timeInterval = useRef<number>();
  const dateEndRef = useRef(dateEnd);

  useEffect(() => {
    clearInterval(timeInterval.current);

    const counter = counterTime(dateEndRef.current);
    setTime(`Expira em: ${counter.text}`);

    timeInterval.current = window.setInterval(() => {
      const { text, distance } = counterTime(dateEndRef.current);
      const isExpired = distance <= 0;
      setTime(isExpired ? 'Expirado' : `Expira em: ${text}`);

      if (isExpired) {
        clearInterval(timeInterval.current);
      }
    }, 1000);
  }, []);

  return <Text padding='4px 0 0' rounded value={time} />;
};

export default TimerDown;
