import React, { ForwardRefRenderFunction, useImperativeHandle, useMemo } from 'react';
import { Button, Icon, Text } from 'vkit/lib/components';
import { Box, Grid, Row } from 'vkit/lib/context';
import { Dropzone } from '@/components';
import useFileUpload from './hooks/useFileUpload';
import style from './style/fileUpload.module.scss';
import { MultipleFiles } from '@/components/fileUpload/components/multipleFiles';
import { SingleFile } from '@/components/fileUpload/components/singleFile';
import { FileUploadInterface, InputClickRefInterface } from './types/fileUpload.types';

const FileUpload: ForwardRefRenderFunction<InputClickRefInterface, FileUploadInterface> = (
  props,
  forwardedRef,
) => {
  const {
    onChange,
    allowedExtensions = [],
    notAllowedExtensions = [],
    formValidatorErrors,
    name,
    textHelper,
    multiple,
    label,
    statusFiles,
    onDelete,
    maxCharacterLimit,
    required,
  } = props;

  const {
    handleFileUpload,
    handleUploadClick,
    inputFileRef,
    onFileDrop,
    setIsDragging,
    validatedFiles,
    useIsDragging,
    removeFile,
  } = useFileUpload({
    name,
    allowedExtensions,
    notAllowedExtensions,
    onChange,
    onDelete,
    multiple,
    maxCharacterLimit,
  });

  useImperativeHandle(forwardedRef, () => ({
    onHandleFileSelect(e: any) {
      return handleUploadClick(e);
    },
  }));

  const hasError = useMemo(
    () => validatedFiles.some((validatedFile) => !validatedFile.valid),
    [validatedFiles],
  );

  return (
    <div>
      {label && (
        <Grid gap={4}>
          <Text value={label} title padding='0 0 8px' />
          {required && <span style={{ color: '#ff413a' }}>*</span>}
        </Grid>
      )}
      <Dropzone
        onDrop={(dropEvent: any) => onFileDrop(dropEvent)}
        onDragChange={(isDragging: boolean) => setIsDragging(isDragging)}>
        <Box
          type='sheet'
          {...(hasError || formValidatorErrors[name]
            ? {
                elevation: 2,
              }
            : {
                type: 'sheet',
                outlined: true,
                rounded: true,
              })}>
          <div
            className={`${style.fileUpload} ${
              (formValidatorErrors[name] || hasError) && !useIsDragging ? style.danger : ''
            }`}>
            <Row
              style={{
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                alignSelf: 'auto',
                width: '100%',
                height: '100%',
                padding: 0,
              }}>
              <Row
                style={{
                  padding: 10,
                  width: '180px',
                  height: '100%',
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}>
                <Icon name={'file-outline'} size={42} />
              </Row>
              <Row
                style={{
                  padding: 0,
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'flex-start',
                }}>
                <Grid alignItems='center' alignContent='center' wrap>
                  <input
                    key={`input_file_${validatedFiles.length}`}
                    multiple={multiple}
                    type='file'
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
                  />

                  {!validatedFiles.length && (
                    <Row>
                      <Text
                        value={`${useIsDragging ? 'Solte' : 'Arraste'}  ${
                          textHelper ?? multiple ? 'seus arquivos até aqui' : 'seu arquivo até aqui'
                        }`}
                        textAlign='center'
                        size='larger'
                        fontWeight={'bold'}
                        color='colorDefault'
                        padding='0 0 10px'
                      />
                      <Text
                        value={
                          multiple
                            ? 'ou selecione os arquivos que estão no seu computador'
                            : 'ou selecione o arquivo que está no seu computador'
                        }
                        textAlign='center'
                        size='larger'
                      />
                      <Row
                        style={{
                          marginTop: 20,
                          padding: 0,
                          alignItems: 'center',
                        }}>
                        <Button
                          label='Selecionar'
                          onClick={() => {
                            handleUploadClick();
                          }}
                          solo
                          outlined
                          size='xLarger'
                        />
                      </Row>
                    </Row>
                  )}

                  {validatedFiles.length && !multiple ? (
                    <SingleFile
                      handleUploadClick={() => {
                        handleUploadClick();
                      }}
                      hasError={hasError}
                      validatedFiles={validatedFiles}
                    />
                  ) : null}

                  {validatedFiles.length && multiple ? (
                    <MultipleFiles
                      validatedFiles={validatedFiles}
                      removeFile={removeFile}
                      statusFiles={statusFiles}
                      hasError={hasError}
                      formValidatorErrors={formValidatorErrors}
                      handleUploadClick={() => {
                        handleUploadClick();
                      }}
                      name={name}
                    />
                  ) : null}
                </Grid>
              </Row>
            </Row>
          </div>
        </Box>
      </Dropzone>
      {formValidatorErrors[name] && (
        <Grid gap={4}>
          <Text value={'Campo obrigatório'} color='danger' size='medium' padding='4px 0' />
        </Grid>
      )}
    </div>
  );
};

export default React.forwardRef(FileUpload);
