import { Route } from '@/core/entities';
import { validatePermission } from '@/core/utils/validatePermissionRoute';
import { User } from '@/modules/profile';
import { useCallback } from 'react';
import { RouteObject } from 'react-router-dom';

interface UseHandleRoutesProps {
  routes: Route[];
  user: User;
}

export const useHandleRoutes = ({ routes, user }: UseHandleRoutesProps) => {
  const handleRoutes = useCallback(
    (items: Route[]): RouteObject[] => {
      const filteredItems: RouteObject[] = [];
      for (const item of items) {
        const hasPermissionToAccess = validatePermission(item, user);

        if (hasPermissionToAccess) {
          if (item.children) {
            item.children = handleRoutes(item.children) as Route[];
          }

          filteredItems.push(item as RouteObject);
        }
      }

      return filteredItems;
    },
    [user],
  );

  return {
    handledRoutes: handleRoutes(routes),
  };
};
