import { Benefit } from '@/modules/benefit/entities';
import { companyBenefitsService } from '@/services/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isSimilarStringLocaleCompare } from '@/utils/validations/isSimilarStringLocaleCompare';

interface UseBenefitsReturn {
  isLoading: boolean;
  benefits: Benefit[];
  loadBenefits: (companyId: string) => void;
  isEmpty: boolean;
}

const useBenefits = (benefitsSortMapping?: string[]): UseBenefitsReturn => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [benefits, setBenefits] = useState<Benefit[]>([]);

  const isEmpty = useMemo(() => !isLoading && !benefits.length, [benefits.length, isLoading]);

  const filterAndSortBenefits = useCallback(
    (benefits: Benefit[], orderMap: string[]): Benefit[] => {
      return orderMap.reduce((acc: Benefit[], sortMapValue) => {
        const benefit = benefits.find((item) =>
          isSimilarStringLocaleCompare(item.name, sortMapValue),
        );
        return benefit ? [...acc, benefit] : acc;
      }, []);
    },
    [],
  );

  const loadBenefits = useCallback(
    async (companyId: string) => {
      await tryOrCatchMessageError(
        async () => {
          const benefitsFound = await companyBenefitsService(companyId).listAll();
          if (benefitsSortMapping) {
            return setBenefits(filterAndSortBenefits(benefitsFound, benefitsSortMapping));
          }
          setBenefits(benefitsFound);
        },
        {
          messageErrorDefault: 'Erro ao buscar benefícios.',
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
        },
      );
    },
    [benefitsSortMapping, filterAndSortBenefits],
  );

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadBenefits(company.id);
  }, [company.id, loadBenefits]);

  return {
    isLoading,
    loadBenefits,
    benefits,
    isEmpty,
  };
};

export default useBenefits;
