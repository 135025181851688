import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseAuthLoginCountdonw {
  timer?: number;
}

const useAuthLoginCountdown = ({ timer = 60 }: UseAuthLoginCountdonw) => {
  const [seconds, setSeconds] = useState(timer);
  const [isActive, setIsActive] = useState(false);

  const startCountdown = useCallback(() => {
    setSeconds(timer);
    setIsActive(true);
  }, [timer]);

  const stopCountdown = useCallback(() => {
    setIsActive(false);
  }, []);

  const resetCountdown = useCallback(() => {
    setIsActive(false);
    setSeconds(timer);
  }, [timer]);

  const isCountDownActive = useMemo(() => {
    return seconds > 0;
  }, [seconds]);

  useEffect(() => {
    let interval: any;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, seconds, stopCountdown]);

  return {
    seconds,
    isActive,
    startCountdown,
    stopCountdown,
    resetCountdown,
    isCountDownActive,
  };
};

export default useAuthLoginCountdown;
