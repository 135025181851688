import { useCurrentUser } from '@/modules/profile';
import { useHistoryQuery } from '@/navigation';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import { analytics } from '@/shared/telemetry/telemetry';
import React, { useState } from 'react';
import { Button, Text } from 'vkit/lib/components';
import { Grid, Tooltip } from 'vkit/lib/context';
import ExportBeneficiariesModal from '../../components/exportBeneficiariesModal/exportBeneficiariesModal';
import {
  BENEFICIARY_TABLE_ACTION,
  BENEFICIARY_TABLE_COLUMN,
  BeneficiarySearchParams,
} from '../../entities';
import { Counter, Filters, Table } from '../../views';

interface BeneficiaryQueryParams extends BeneficiarySearchParams {
  showMoreFilters?: boolean;
}

const List: React.FC = () => {
  const { showMoreFilters } = useHistoryQuery();
  const { isBrokerUser } = useCurrentUser();
  const [showExportBeneficiaries, setShowExportBeneficiaries] = useState<boolean>(false);

  const [moreFilters, setMoreFilters] = useState(Boolean(showMoreFilters) || false);

  const [filters, setParams] = useQueryStringState<BeneficiaryQueryParams>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    status: 'ACTIVE',
    sort: 'life.name',
  });

  const handleExportBeneficiaries = () => {
    analytics.track('[START] - Exportação de beneficiários');
    setShowExportBeneficiaries(true);
  };

  return (
    <>
      <Grid gap={8}>
        <Text color='colorDefault' title size='medium' value='Lista de beneficiários' />
        {isBrokerUser && (
          <Tooltip title={'Exportação feita de acordo com o filtro da lista'}>
            <Button
              onClick={handleExportBeneficiaries}
              solo={true}
              icon='download-outline'
              invertColor={true}
              iconColor={'light'}
              size='xLarger'
            />
          </Tooltip>
        )}
      </Grid>

      <ExportBeneficiariesModal
        isOpen={showExportBeneficiaries}
        handleClose={setShowExportBeneficiaries}
        filters={filters as unknown as BeneficiarySearchParams}
      />

      <Counter filters={filters} />

      <Filters
        values={filters as unknown as BeneficiarySearchParams}
        handleChange={setParams}
        showMoreFilters={moreFilters}
        setShowMoreFilters={(showMoreFilters) => setMoreFilters(showMoreFilters)}
      />

      <Table
        filters={filters}
        handleChange={setParams}
        columns={[
          BENEFICIARY_TABLE_COLUMN.NAME,
          BENEFICIARY_TABLE_COLUMN.CPF,
          BENEFICIARY_TABLE_COLUMN.HEALTH_CARD,
          BENEFICIARY_TABLE_COLUMN.BENEFIT,
          BENEFICIARY_TABLE_COLUMN.STATUS,
        ]}
        actions={[BENEFICIARY_TABLE_ACTION.DETAIL]}
      />
    </>
  );
};

export default List;
