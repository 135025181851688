import { ToastStatusGlobal } from '@/components';
import {
  USER_BROKER_INITIAL_STATE,
  USER_COMPANY_INITIAL_STATE,
  USER_INITIAL_STATE,
  USER_TYPE,
  User,
  UserPayload,
  UserPermissionPayload,
  userMessageErrorByMessageErrorRequest,
} from '@/modules/user/entities';
import useAuthenticatedUser from '@/core/hooks/useAuthenticatedUser';
import { UserPermission } from '@/modules/user/entities/permission';
import { UserService } from '@/modules/user/services';
import {
  getCommomCompanyIds,
  getDifferenceCompanyIdsFromFirstUser,
} from '@/modules/user/utils/compareCompanyIds';
import { useHistoryNavigator } from '@/navigation';
import { ObjectType } from '@/shareds/types';
import { getMessageError } from '@/utils/getMessageError';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useState } from 'react';

interface UseCreateOrUpdateFormProps {
  user?: User;
  scope: `${USER_TYPE}`;
  scopeId: string;
}

const userService = new UserService();

function defineUserInitialState(scope: `${USER_TYPE}`, user?: User): User {
  if (scope === 'company' && !user?.id) {
    return USER_COMPANY_INITIAL_STATE;
  }
  if (scope === 'broker' && !user?.id) {
    return USER_BROKER_INITIAL_STATE;
  }
  return USER_INITIAL_STATE;
}

const useCreateOrUpdateForm = ({ user, scope, scopeId }: UseCreateOrUpdateFormProps) => {
  const navigate = useHistoryNavigator();
  const { toast } = ToastStatusGlobal();
  const [isSavingForm, setIsSavingForm] = useState<boolean>(false);
  const userInitialState = defineUserInitialState(scope, user);
  const [formData, setFormData] = useState<User>({ ...userInitialState, scopeIds: [scopeId] });
  const [scopedPermission, setScopedPermission] = useState<UserPermission[]>([]);
  const [fileFoldersPermission, setFileFoldersPermission] = useState<UserPermissionPayload[]>([]);
  const { user: authenticatedUser } = useAuthenticatedUser();

  const checkEmailBroker = (email: string): string | null => {
    if (!email) {
      return null;
    }

    if (!/^[a-zA-Z0-9.+=?_{|}-]+@(vitta|stone)+(\.me|\.com\.br)$/.test(email)) {
      return 'E-mail inválido';
    }

    return null;
  };

  const onChangeForm = useCallback((update: ObjectType) => {
    setFormData((currentData) => ({
      ...currentData,
      ...update,
    }));
  }, []);

  const handleUserToUpdate = useCallback(
    (user: User) => {
      if (!Array.isArray(user.userPermissions)) {
        return;
      }

      const permissionsWithoutScope = user.userPermissions
        .filter((permission) => !permission.scope)
        .map((permission) => permission.permissionId);
      const permissionWithScope = user.userPermissions.filter((permission) => !!permission.scope);

      setScopedPermission(permissionWithScope);

      onChangeForm({
        ...user,
        scopeIds: getCommomCompanyIds(user, authenticatedUser),
        permissions: permissionsWithoutScope,
      });
    },
    [authenticatedUser, onChangeForm],
  );

  const getInfoDTO = (userRecord: User): UserPayload => {
    const refPermissions = userRecord?.permissions?.map((permission) => ({
      permissionId: permission,
    }));

    return {
      email: userRecord.email,
      name: userRecord.name,
      userPermissions: [...(refPermissions ?? []), ...fileFoldersPermission],
      type: scope,
      scopeIds: userRecord.scopeIds,
    };
  };

  const createOrUpdate = async () => {
    try {
      setIsSavingForm(true);
      const userDTO = getInfoDTO(formData);
      if (formData.id && user) {
        // Adicionando empresas que o usuário editor não tem acesso
        // Isso é feito por que a API bloqueio remoção de empresas
        // que o usuário editor não tem acesso.
        const forbiddenScopeIds = getDifferenceCompanyIdsFromFirstUser(user, authenticatedUser);
        userDTO.scopeIds = [...forbiddenScopeIds, ...formData.scopeIds];
        await userService.update(formData.id, userDTO);
        toast('Feito', 'Dados de usuário alterados.', 'success');
      } else {
        await userService.create(userDTO);
        toast(
          'Feito',
          `Enviamos uma mensagem de confirmação para o e-mail: <b>${userDTO.email}</b>`,
          'success',
        );
        navigate.goBack();
      }
      onChangeForm({ user: userDTO });
    } catch (error) {
      const messageError = getMessageError({
        error,
        messageDefault: 'Falha ao salvar usuário',
        messageErrorByMessageErrorRequest: userMessageErrorByMessageErrorRequest,
      });
      dispatchToast({
        text: messageError,
      });
    } finally {
      setIsSavingForm(false);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    handleUserToUpdate(user);
  }, [handleUserToUpdate, scopeId, user]);

  return {
    isSavingForm,
    formData,
    checkEmailBroker,
    onChangeForm,
    createOrUpdate,
    scopedPermission,
    setFileFoldersPermission,
  };
};

export default useCreateOrUpdateForm;
