import { IconSquare, Space, Tooltip } from '@/components';
import React from 'react';
import { Button, Icon, Text, Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { COMPANY_STATUS } from '@/modules/company/entities/company.enum';
/**
 * Props for the Selected component.
 */
interface SelectedProps {
  /**
   * The name of the selected company to be displayed.
   */
  name: string;

  /**
   * A boolean value indicating whether the company switch button should be displayed.
   */
  showSwitcherButton: boolean;

  /**
   * A callback function that will be executed when the switch company button is clicked.
   */
  handleSwitcher: () => void;

  /**
   * The main company is the one that has no parent on salesforce.
   */
  isMainCompany?: boolean;

  /**
   * The company status.
   */
  statusCompany?: COMPANY_STATUS | null;
  /**
   * The company status tag.
   */
  showStatusChip?: boolean;
}

const Selected: React.FC<SelectedProps> = ({
  name,
  showSwitcherButton,
  handleSwitcher,
  isMainCompany,
  statusCompany,
  showStatusChip,
}: SelectedProps) => {
  return (
    <Grid row alignItems='center'>
      <IconSquare icon='briefcase-outline' />
      <Space />
      <Grid column>
        <Grid>
          <Text title color={'colorDefault'} value={name} />
          {isMainCompany && (
            <Tooltip title='Empresa principal de um grupo.' position='bottom'>
              <Icon name='award-outline' color={'colorDefault'} />
            </Tooltip>
          )}
          <div style={{ margin: '0 0 0 12px' }}>
            {statusCompany === COMPANY_STATUS.INACTIVE && showStatusChip ? (
              <Tooltip
                title='Esta empresa está com todos os contratos cancelados.'
                position='bottom'>
                <Chip
                  size='small'
                  label='Inativa'
                  icon='slash-outline'
                  rounded
                  invertColor
                  color='danger'
                />
              </Tooltip>
            ) : null}
            {statusCompany === COMPANY_STATUS.PENDING && showStatusChip ? (
              <Tooltip
                title='Empresa possui contratos expirados, caso a informação esteja incorreta verificar contratos no Salesforce.'
                position='bottom'>
                <Chip
                  size='small'
                  label='Pendente'
                  icon='alert-triangle-outline'
                  rounded
                  invertColor
                  color='warning'
                />
              </Tooltip>
            ) : null}
          </div>
        </Grid>
        <Space horizontal height={4} />
        {showSwitcherButton && (
          <Button solo size='small' onClick={handleSwitcher}>
            Trocar de empresa
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Selected;
