import { APPLICATION_GROUP_BY } from '@/core/entities';
import { useHistoryLocation } from '@/navigation';
import { RouteData } from '@sentry/react/types/types';
import { useMatches } from 'react-router-dom';

export const useBrokerRoute = () => {
  const matchedRoute = useMatches();
  const { pathname } = useHistoryLocation();

  const isBrokerRoute = !!matchedRoute.find((route) => {
    const { pathname: routePathname, data } = route;
    const customData = data as unknown as RouteData;

    return (
      routePathname === pathname &&
      customData &&
      customData.groupedBy === APPLICATION_GROUP_BY.BROKER
    );
  });

  return {
    isBrokerRoute,
  };
};
