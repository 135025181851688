import React from 'react';
import { Grid, Row } from 'vkit/lib/context';
import { Text } from 'vkit/lib/components';
import { BoxInfo, IconSquare, Space } from '@/components/index';

export type CardNumberProps = {
  number: number;
  text: string;
  icon: string;
  loading?: boolean;
};

export const CardNumber: React.FC<CardNumberProps> = ({
  number,
  text,
  icon,
  loading,
}: CardNumberProps) => (
  <BoxInfo>
    <Grid row alignItems='center'>
      <IconSquare icon={icon} />
      <Space />
      <Row style={{ padding: 0, width: '100%' }}>
        <Text title color='colorDefault' value={loading ? '' : String(number)} rounded />
        <Space horizontal height={4} />
        <Text title size='minor' value={loading ? '' : text} rounded />
      </Row>
    </Grid>
  </BoxInfo>
);

export default CardNumber;
