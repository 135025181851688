import { Attendance, attendanceInitialState } from '@/modules/attendance/entities';
import { AttendanceService } from '@/modules/attendance/services';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useState } from 'react';

interface UseAttendance {
  attendanceId?: string;
}

const attendanceService = new AttendanceService();

const useAttendance = ({ attendanceId }: UseAttendance) => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [attendance, setAttendance] = useState<Attendance>(attendanceInitialState);

  const loadAttendance = useCallback(
    async (companyCNPJ: string) => {
      if (!attendanceId || !companyCNPJ) {
        return;
      }
      setLoading(true);
      try {
        const result = await attendanceService.getAttendance(companyCNPJ, attendanceId);
        setAttendance(result);
      } catch (e) {
        console.error(e);

        dispatchToast({
          text: 'Ops! Falha ao carregar detalhes deste atendimento.',
        });
      } finally {
        setLoading(false);
      }
    },
    [attendanceId],
  );

  useEffect(() => {
    if (!company.cnpj) {
      return;
    }
    loadAttendance(company.cnpj);
  }, [company.cnpj, loadAttendance]);

  return {
    isLoading,
    attendance,
  };
};

export default useAttendance;
