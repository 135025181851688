import formatText from '@/utils/formatText';

import { Attendance, AttendanceTable } from '../entities';

export const normalizeAttendances = (attendances: Attendance[]): AttendanceTable[] => {
  if (!attendances || !attendances.length) {
    return [];
  }

  return attendances.map((attendance: Attendance): AttendanceTable => {
    const { id, subject, createdAt, status, expectedSolutionTime, reason, updatedAt } = attendance;
    let forecast = 'Em análise';

    if (expectedSolutionTime) {
      forecast =
        Number(expectedSolutionTime) > 1
          ? `${expectedSolutionTime} dias úteis`
          : `${expectedSolutionTime} dia útil`;
    }

    return {
      id: id || '',
      subject,
      createdAt: formatText(createdAt || '', 'dateWithoutTZ'),
      status,
      forecast,
      reason,
      updatedAt: formatText(updatedAt || '', 'dateWithoutTZ'),
    };
  });
};
