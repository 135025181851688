import React from 'react';

import { useHistoryNavigator } from '@/navigation';
import { Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

interface ButtonGoBackProps {
  arrowDirection?: 'left' | 'bottom';
}

const ButtonGoBack: React.FC<ButtonGoBackProps> = ({ arrowDirection }) => {
  const navigator = useHistoryNavigator();

  return (
    <Row
      style={{
        display: 'block',
        padding: '0',
        marginLeft: -8,
      }}>
      <Grid alignContent='center'>
        <div
          className='buttonGoBack'
          onClick={() => navigator.goBack()}
          style={{ cursor: 'pointer' }}>
          <Text
            icon={
              arrowDirection === 'bottom' ? 'arrow-ios-downward-outline' : 'arrow-ios-back-outline'
            }
            iconSize={30}
            size='medium'
            title
            value='Voltar'
          />
        </div>
      </Grid>
    </Row>
  );
};

export default ButtonGoBack;
