import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import {
  AuthConfirmEmail,
  authConfirmEmailInitState,
  authConfirmEmailService,
} from '@/services/accessControl/endpoints/authConfirmEmail';
import { ObjectType } from '@/shareds/types';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { getAuthConfirmEmailError } from '../utils';

const useAuthConfirmEmail = () => {
  const [useData, setData] = useState<AuthConfirmEmail>(authConfirmEmailInitState);
  const [useLoading, setLoading] = useState(false);
  const [useDisabledForm, setDisabledForm] = useState(false);
  const [useMessageError, setMessageError] = useState('');
  const navigate = useHistoryNavigator();
  const queryString = useHistoryQuery();

  const checkConfirmPasswordsValid = (confirmPassword: string): string | null => {
    if (confirmPassword !== useData.newPassword) {
      return 'Senhas não conferem';
    }

    return null;
  };

  const authConfirmEmailDTO = (data: ObjectType): AuthConfirmEmail => ({
    code: queryString.code,
    email: data.email,
    newPassword: data.newPassword,
    userId: queryString.userId,
  });

  const confirmEmail = async (): Promise<void> => {
    try {
      setLoading(true);
      setMessageError('');
      await authConfirmEmailService.create(authConfirmEmailDTO(useData));
      navigate.push('/login');
    } catch (error) {
      console.error(error);
      const messageError = getAuthConfirmEmailError(error as AxiosError);
      setMessageError(messageError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { code, userId } = queryString;

    if (!code || !userId) {
      setMessageError(
        'Falha nos dados desta conta, não temos informações suficientes para confirmar o e-mail.',
      );
      setDisabledForm(true);
    }
  }, [queryString]);

  return {
    checkConfirmPasswordsValid,
    confirmEmail,
    setData,
    setMessageError,
    useData,
    useDisabledForm,
    useLoading,
    useMessageError,
    queryString,
    setLoading,
  };
};

export default useAuthConfirmEmail;
