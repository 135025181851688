import { useCallback, useEffect, useMemo, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { useCurrentUser } from '@/modules/profile';
import { MOVE_CUSTOM_SHEET, MoveAttachment, MoveNormalized } from '../../entities';
import { MoveService } from '../../services';

const moveService = new MoveService();

const useMove = (moveId: string) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [move, setMove] = useState<MoveNormalized>();
  const { user } = useCurrentUser();

  const getCustomSheets = useCallback(
    (type: MOVE_CUSTOM_SHEET): MoveAttachment[] => {
      return move?.attachments
        ? move.attachments.filter((attachment) => attachment.name.startsWith(type))
        : [];
    },
    [move?.attachments],
  );

  const transformedSheets = useMemo((): MoveAttachment[] => {
    return getCustomSheets(MOVE_CUSTOM_SHEET.TRANSFORMED);
  }, [getCustomSheets]);

  const observationSheets = useMemo((): MoveAttachment[] => {
    return getCustomSheets(MOVE_CUSTOM_SHEET.OBSERVATION);
  }, [getCustomSheets]);

  const attachments = useMemo((): MoveAttachment[] => {
    if (move?.attachments) {
      return move.attachments.filter(
        (attachment) =>
          !attachment.name.startsWith(MOVE_CUSTOM_SHEET.TRANSFORMED) &&
          !attachment.name.startsWith(MOVE_CUSTOM_SHEET.OBSERVATION),
      );
    }
    return [];
  }, [move]);

  const loadMove = useCallback(
    async (moveFileId: string) => {
      if (!moveFileId || !user) {
        return;
      }

      setLoading(true);

      try {
        const response = await moveService.getMove<MoveNormalized>(moveFileId);
        setMove(response);
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! Não conseguimos buscar as informações desta movimentação.',
        });
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  useEffect(() => {
    if (!moveId) {
      return;
    }
    loadMove(moveId);
  }, [loadMove, moveId]);

  return {
    isLoading,
    move,
    setMove,
    transformedSheets,
    observationSheets,
    attachments,
  };
};

export default useMove;
