export const WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT = 5 * 60 * 1000;

export const waitLogin = (options?: {
  timeoutInMilliseconds: number
}) => {
  return new Promise((resolve) => {
    window.addEventListener('sov-v2:auth', resolve)

    setTimeout(resolve, options?.timeoutInMilliseconds || WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT)
  })
}
