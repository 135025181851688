import { BoxInfo } from '@/components';
import ChipBoolean from '@/components/chipBoolean';
import { sortBy } from 'lodash';
import RuleDoubt from '@/modules/contract/components/ruleDoubt/ruleDoubt';
import RuleList from '@/modules/contract/components/ruleList/ruleList';
import React, { useMemo } from 'react';
import ICONS from '@/shareds/constants/icons';
import { Accordion } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { Rule, RuleType } from '@/modules/contract/entities/contract';

interface RulesByType {
  [key: string]: Rule[];
}

interface RulesProps {
  items: Rule[];
  ruleTypes: RuleType[];
  contractId: string;
  baseId: string;
}

const Rules: React.FC<RulesProps> = ({ items = [], ruleTypes, contractId, baseId }) => {
  const rulesByType = useMemo(
    () =>
      items.reduce((acc: RulesByType, item) => {
        if (!acc[item.type]) {
          acc[item.type] = [item];
          return acc;
        }

        acc[item.type].push(item);
        return acc;
      }, {}),
    [items],
  );

  const sortedRuleTypes = useMemo<RuleType[]>(() => {
    return sortBy(ruleTypes, (ruleType) => !rulesByType[ruleType.type]);
  }, [rulesByType, ruleTypes]);

  return (
    <BoxInfo title='Regras' icon={ICONS.RULES}>
      <Accordion
        full
        collapse
        box
        group={sortedRuleTypes.map((ruleType) => (
          <Accordion
            key={ruleType.type}
            disabled={!rulesByType[ruleType.type]}
            title={
              <Grid gap={8}>
                {ruleType.title}
                <ChipBoolean value={!!rulesByType[ruleType.type]} />
              </Grid>
            }
            body={
              rulesByType[ruleType.type]?.some((rule) => rule.description) ? (
                <RuleList rules={rulesByType[ruleType.type]} />
              ) : (
                <RuleDoubt contractId={contractId} ruleType={ruleType} baseId={baseId} />
              )
            }
          />
        ))}
      />
    </BoxInfo>
  );
};

export default Rules;
