import { Extras } from '@sentry/types';
import Mixpanel from 'mixpanel-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Metric } from 'web-vitals';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { errorReports, telemetryInit } from './shared/telemetry/telemetry';

interface WebVitalsMetrics extends Extras {
  metric: Metric;
}

const { REACT_APP_ENVIRONMENT = 'development' } = process.env;

telemetryInit();

Mixpanel.init('77ea83dadd28d26ba8d46954f408fb2b', {
  debug: REACT_APP_ENVIRONMENT !== 'production',
});

Mixpanel.register_once({
  ENVIRONMENT: REACT_APP_ENVIRONMENT.toUpperCase(),
});

// Monitoramento dos Web Vitals
reportWebVitals((report: Metric) => {
  if (REACT_APP_ENVIRONMENT === 'production') {
    if (report.name) {
      errorReports.category({
        name: 'Web Vitals',
        description: report.name,
        level: 'info',
      });
    }

    const extrasConvert: WebVitalsMetrics = {
      metric: report,
    };

    errorReports.event({
      name: 'Web Vitals',
      content: extrasConvert,
      level: 'info',
    });
  }
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error('Element with id #root not found');
}
