import { SelectOption } from '@/components/selectValidate';
import { ObjectType } from '@/shareds/types';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useMemo } from 'react';
import { Grid } from 'vkit/lib/context';
import { USER_TYPE } from '../../entities';
import useSelectPermission from './hooks/useSelectPermission';
interface SelectPermissionsProps {
  scope: `${USER_TYPE}`;
  onBlur?: Function;
  onChange?: Function;
  useValues: ObjectType;
  isDisabled?: boolean;
}

const SelectPermission: React.FC<SelectPermissionsProps> = ({
  scope,
  onBlur,
  onChange,
  useValues,
  isDisabled,
}) => {
  const { isLoading, permissionOptions } = useSelectPermission({ scope });

  const disabled = useMemo(() => isDisabled ?? isLoading, [isDisabled, isLoading]);

  const selectedValues = useMemo(() => {
    if (disabled) return;
    if (!useValues?.permissions?.length) {
      return [];
    }
    return permissionOptions.filter(({ value }: SelectOption) =>
      useValues?.permissions?.includes(value),
    );
  }, [permissionOptions, useValues?.permissions, disabled]);

  const getSelectedText = (value: SelectOption) => {
    return value?.text;
  };

  const mapSelectOptionsToValues = (value: any) => {
    onChange?.(
      'permissions',
      value.map(({ value }: SelectOption) => value),
    );
  };

  const handleSelect = (value: any) => {
    mapSelectOptionsToValues(value);
  };

  const removeSelected = (value: any) => {
    mapSelectOptionsToValues(value);
  };

  const selectedValue = (option: any) => {
    return <div>{getSelectedText(option)}</div>;
  };

  const placeholder = () => {
    if (useValues?.permissions?.length === permissionOptions?.length) {
      return '';
    }
    return 'Selecione as permissões do usuário';
  };

  useEffect(() => {
    const multSelectInputElement = document.querySelector('#search_input');
    multSelectInputElement?.addEventListener('blur', () => onBlur?.());

    return multSelectInputElement?.removeEventListener('blur', () => onBlur?.());
  }, [onBlur]);

  return (
    <Grid column stretch gap={4}>
      <Grid alignContent='justify' alignItems='bottom' stretch>
        <span style={{ fontSize: 14, fontFamily: 'Ubuntu-Bold' }}>Permissões</span>
      </Grid>
      <div style={{ width: '100%' }}>
        <Multiselect
          key={selectedValues?.length}
          loading={isLoading}
          disable={disabled}
          displayValue='text'
          options={permissionOptions}
          selectedValueDecorator={(_value, option) => selectedValue(option)}
          emptyRecordMsg='Você já selecionou todas as permissões disponíveis'
          onSelect={handleSelect}
          onRemove={removeSelected}
          placeholder={placeholder()}
          closeIcon='circle'
          selectedValues={isLoading ? [] : selectedValues}
          avoidHighlightFirstOption
          style={{
            searchBox: {
              borderColor: 'var(--vkit-color-contrast-9)',
              minHeight: 46,
              padding: 6,
              borderRadius: 5,
              gap: 8,
              display: 'flex',
              flexWrap: 'wrap',
            },
            inputField: {
              color: 'var(--vkit-color-default)',
              margin: '-3px 0',
              flexGrow: 1,
            },
            chips: {
              margin: 0,
              whiteSpace: 'break-spaces',
            },
            optionListContainer: {
              background: 'transparent',
            },
            optionContainer: {
              marginTop: 8,
              background: 'var(--vkit-color-contrast)',
              boxShadow: '0 5px 15px var(--vkit-color-blackless)',
              border: '1px solid var(--vkit-color-contrast-5)',
              width: '100%',
              borderRadius: 9,
              position: 'absolute',
            },
            option: {
              borderBottom: '1px solid var(--vkit-color-contrast-2)',
            },
          }}
        />
      </div>
    </Grid>
  );
};

export default SelectPermission;
