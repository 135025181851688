const { REACT_APP_RULE_TYPES = '{}' } = process.env;

export const getAutofillMessages = (ruleType: string, baseId: string, contractCode: string) => {
  let subject = '';
  let description = '';
  const baseRulesType = JSON.parse(REACT_APP_RULE_TYPES);
  const ruleByBase = baseRulesType[baseId];
  const rule = ruleByBase.find(
    (option: { title: string; type: string }) => option.type === ruleType,
  );

  if (rule.type && rule.title) {
    subject = `Dúvida quanto ao ${rule.title.toLowerCase()}`;
    description = `Olá, executiva! 
Gostaria de uma explicação sobre a regra contratual de ${rule.title.toLowerCase()} do meu contrato de código ${contractCode}`;
  }

  return {
    subject,
    description,
  };
};
