import { get } from 'lodash';
import { AttendanceReasonAndSubReason, AttendanceReasonAndSubReasonNormalized } from '../entities';

export const normalizeReasonAndSubReasonResponse = (
  reasonAndSubReason: AttendanceReasonAndSubReason,
): AttendanceReasonAndSubReasonNormalized => {
  const reason = get(reasonAndSubReason, 'reason.values', null);
  const subReasonsController = get(reasonAndSubReason, 'subReason.controllerValues', null);
  const subReason = get(reasonAndSubReason, 'subReason.values', null);

  return {
    reason,
    subReasonsController,
    subReason,
  };
};
