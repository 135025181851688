import React from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface EntityProps {
  data: {
    recordId?: string;
    name: string;
  };
}

const EntityInfo: React.FC<EntityProps> = ({ data }) => {
  return (
    <Grid column gap={4}>
      {data.name && <Text value={data.name} size='medium' />}
      {data.recordId && <Text value={data.recordId} size='small' />}
    </Grid>
  );
};

export default EntityInfo;
