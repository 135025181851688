import React, { useMemo } from 'react';
import { BoxInfo, TextData } from '@/components';
import { BeneficiaryWithDocuments } from '@/pages/beneficiaries/shareds/interfaces/beneficiary';

interface InfoBeneficiaryProps {
  loading: boolean;
  beneficiary: BeneficiaryWithDocuments;
  beneficiaryHolder: BeneficiaryWithDocuments;
}

const InfoBeneficiary: React.FC<InfoBeneficiaryProps> = ({
  loading,
  beneficiary,
  beneficiaryHolder,
}) => {
  const hasBeneficiaryHolder = useMemo(
    () => beneficiary.beneficiaryHolder?.holderId !== beneficiary.id,
    [beneficiary.beneficiaryHolder?.holderId, beneficiary.id],
  );

  return (
    <BoxInfo margin={0} title='Dados do beneficiário' icon='info-outline'>
      <TextData
        loading={loading}
        data={[
          {
            label: 'Nome',
            text: beneficiary.life?.name,
            sizeDefault: 70,
          },
          {
            label: 'CPF',
            text: beneficiary.lifeDocuments?.cpf,
            type: 'cpf',
            sizeDefault: 30,
          },
          {
            hidden: !hasBeneficiaryHolder,
            label: 'Nome do titular',
            text: beneficiaryHolder.life?.name,
            sizeDefault: 70,
          },
          {
            hidden: !hasBeneficiaryHolder,
            label: 'CPF do titular',
            text: beneficiaryHolder.lifeDocuments?.cpf,
            type: 'cpf',
            sizeDefault: 30,
          },
        ]}
      />
    </BoxInfo>
  );
};

export default InfoBeneficiary;
