import React, { useState } from 'react';

import { useMove } from '@/modules/move';
import { useHistoryParams } from '@/navigation';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';

import { CADASTRAL_POSITION_ROW_COLUMNS } from '@/modules/cadastralPosition/entities/cadastralPositionRow.enum';
import { ParamsToFilterRows } from '@/modules/cadastralPosition/entities/cadastralPositionRow.interface';
import CadastralPositionInfo from '@/modules/cadastralPosition/views/cadastralPositionInfo/cadastralPositionInfo';
import CadastralPositionRowTable from '@/modules/cadastralPosition/views/cadastralPositionRowTable/cadastralPositionRowTable';
import ProcessedFamilyGroupsCounter from '@/modules/cadastralPosition/views/processedFamilyGroupsCounter/processedFamilyGroupsCounter';

const Details: React.FC = () => {
  const { cadastralPositionId } = useHistoryParams();
  const { isLoading: loading, move: cadastralPosition } = useMove(cadastralPositionId);
  const [filters, setFilters] = useState<ParamsToFilterRows>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    fileId: cadastralPositionId,
  });

  return (
    <>
      <CadastralPositionInfo
        loading={loading}
        cadastralPosition={cadastralPosition}
        cadastralPositionId={cadastralPositionId}
      />

      <ProcessedFamilyGroupsCounter cadastralPositionId={cadastralPositionId} />

      <CadastralPositionRowTable
        columns={[
          CADASTRAL_POSITION_ROW_COLUMNS.LINE,
          CADASTRAL_POSITION_ROW_COLUMNS.LIFE_STATUS,
          CADASTRAL_POSITION_ROW_COLUMNS.BENEFICIARY_STATUS,
        ]}
        filters={filters}
        handleFilters={setFilters}
      />
    </>
  );
};

export default Details;
