import { ShowHide } from '@/components';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import React, { useState } from 'react';
import { Text } from 'vkit/lib/components';

import { Grid } from 'vkit/lib/context';

import {
  ButtonGoToUpload,
  CADASTRAL_DATA_MATCHING_COLUMNS,
  CADASTRAL_DATA_MATCHING_LIST_ACTIONS,
  CADASTRAL_DATA_MATCHING_LIST_FILTERS,
  Filters,
  Table,
  CadastralDataMatchingParamsToFilter,
} from '@/modules/cadastralDataMatching';

import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';

const List: React.FC = () => {
  const { getFirstCompanyConfigByKey } = useCompany();
  const [filters, setFilters] = useState<CadastralDataMatchingParamsToFilter>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
  });
  const layoutType = getFirstCompanyConfigByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);

  return (
    <>
      <Filters
        fields={[
          CADASTRAL_DATA_MATCHING_LIST_FILTERS.SEARCH,
          CADASTRAL_DATA_MATCHING_LIST_FILTERS.STATUS,
          CADASTRAL_DATA_MATCHING_LIST_FILTERS.CREATED_AT_MIN,
        ]}
        setFilters={setFilters}
      />

      <Grid alignContent='right' stretch>
        <ButtonGoToUpload />
      </Grid>

      <ShowHide visible={!layoutType} transition='slideToDown'>
        <Text
          rounded
          size='medium'
          applyBaseColor
          color='warning'
          iconColor='warning'
          padding='8px 12px'
          icon={ICONS.WARNING}
          value='Esta empresa não possui as configurações necessárias para visualização da lista de bates cadastrais.'
        />
      </ShowHide>

      <Table
        columns={[
          CADASTRAL_DATA_MATCHING_COLUMNS.FILE_NAME,
          CADASTRAL_DATA_MATCHING_COLUMNS.STATUS,
          CADASTRAL_DATA_MATCHING_COLUMNS.CREATED_AT,
        ]}
        actions={[CADASTRAL_DATA_MATCHING_LIST_ACTIONS.DETAILS]}
        filters={filters}
        handleFilters={setFilters}
      />
    </>
  );
};

export default List;
