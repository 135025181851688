import { useCallback, useEffect, useMemo, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty } from 'lodash';
import { ActionsLayer, Button, Chip } from 'vkit/lib/components';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import { Move, MoveResponse } from '@/modules/move/entities';
import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';

import { WordBreak } from '@/components';
import { useHistoryNavigator } from '@/navigation';
import { getFormattedDate, maskText } from '@/utils/format';

import { CADASTRAL_POSITION_STATUS_COLOR, CADASTRAL_POSITION_STATUS_TRANSLATED } from '@/modules/cadastralPosition/entities/cadastralPosition.const';
import {
  CADASTRAL_POSITION_COLUMNS,
  CADASTRAL_POSITION_LIST_ACTIONS,
} from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { LAYOUT_SHEET_TYPE } from '@/shared/entities/enums/layoutSheet';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import { format } from 'date-fns';
import { Grid } from 'vkit/lib/context';

interface UseCadastralPosition {
  actions?: CADASTRAL_POSITION_LIST_ACTIONS[];
  columns?: CADASTRAL_POSITION_COLUMNS[];
  filters: ParamsToFilter;
  handleFilters: (params: ParamsToFilter) => void;
}

const monitoringService = new MonitoringService();

const useCadastralPositionTable = ({
  actions,
  columns,
  filters,
  handleFilters,
}: UseCadastralPosition) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cadastralPositions, setCadastralPositions] = useState<Move[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const { company } = useCompany();

  const isEmptyMoves = useMemo(
    () => !cadastralPositions?.length && !loading,
    [loading, cadastralPositions?.length],
  );

  const AVALIBLE_COLUMNS: Record<CADASTRAL_POSITION_COLUMNS, DataTableColumn> = useMemo(
    () => ({
      [CADASTRAL_POSITION_COLUMNS.COMPANY_NAME]: {
        name: 'EMPRESA',
        path: 'company.name',
        minWidth: '160px',
        format: ({ company }: Move) => (
          <div>
            <WordBreak>{company.name}</WordBreak>
            <small>{maskText(company?.cnpj, '99.999.999/9999-99')}</small>
          </div>
        ),
      },
      [CADASTRAL_POSITION_COLUMNS.FILE_NAME]: {
        name: 'NOME DO ARQUIVO',
        path: 'name',
        format: ({ name }: Move) => <WordBreak>{name}</WordBreak>,
      },
      [CADASTRAL_POSITION_COLUMNS.STATUS]: {
        name: 'STATUS',
        path: 'status',
        minWidth: '100px',
        format: ({ status }: Move) => (
          <Grid>
            <Chip
              rounded
              size='small'
              label={CADASTRAL_POSITION_STATUS_TRANSLATED[status]}
              color={CADASTRAL_POSITION_STATUS_COLOR[status]}
            />
          </Grid>
        ),
      },
      [CADASTRAL_POSITION_COLUMNS.UPLOADED_AT]: {
        name: 'DATA DO UPLOAD',
        path: 'updatedAt',
        minWidth: '180px',
        format: ({ updatedAt }: Move) =>
          updatedAt ? getFormattedDate(updatedAt, { showHours: true }) : null,
      },
      [CADASTRAL_POSITION_COLUMNS.CREATED_AT]: {
        name: 'DATA DA CRIAÇÃO',
        path: 'createdAt',
        minWidth: '220px',
        format: ({ createdAt }: Move) =>
          createdAt ? getFormattedDate(createdAt, { showHours: true }) : null,
      },
    }),
    [],
  );

  const navigate = useHistoryNavigator();
  const { downloadFile } = useFileDownload();

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  const getActions = ({ id }: Move) => {
    const AVALIBLE_ACTIONS = {
      [CADASTRAL_POSITION_LIST_ACTIONS.DETAILS]: {
        label: 'Ver detalhes',
        onClick: () => {
          navigate.push(`/cadastral-positions/${id}/details`);
        },
        icon: ICONS.DETAILS,
      },
      [CADASTRAL_POSITION_LIST_ACTIONS.DOWNLOAD]: {
        label: 'Baixar arquivo',
        onClick: () => downloadFile(id),
        icon: ICONS.DOWNLOAD,
      },
    };

    const listActions = actions
      ? actions.map((action) => AVALIBLE_ACTIONS[action])
      : Object.values(AVALIBLE_ACTIONS).map((value) => value);

    if (listActions?.length === 1) {
      return [
        <Button
          key={id}
          solo
          outlined
          label={listActions[0].label}
          onClick={listActions[0].onClick}
          color='colorDefault'
          iconColor='colorDefault'
        />,
      ];
    }

    return [<ActionsLayer key={id} vertical actions={listActions} />];
  };

  const handleChangePageSize = (value: number) => {
    handleFilters({
      ...filters,
      pageSize: value,
      page: 1,
    });
  };

  const handlePageChange = (value: number) => {
    handleFilters({
      ...filters,
      page: value,
    });
  };

  const fetchData = useCallback(async (queryParams: ParamsToFilter) => {
    setLoading(true);

    const filters = {
      ...queryParams,
      type: [LAYOUT_SHEET_TYPE.CADASTRAL_POSITION],
      sort: '-uploadedAt',
    };

    if (queryParams.createdAtMin && !queryParams.createdAtMax) {
      filters.createdAtMax = format(new Date(), 'yyyy-MM-dd');
    }

    if (queryParams.createdAtMax && !queryParams.createdAtMin) {
      filters.createdAtMin = '1970-01-01';
    }

    try {
      const { data, page, totalPages, total, pageSize } = await monitoringService.getFiles<
        MoveResponse,
        ParamsToFilter
      >(filters);
      setCadastralPositions(data);
      setPagination({
        page,
        totalPages,
        pageSize,
        total,
      });
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não foi possível buscar as posições cadastrais.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(filters) || !company.id) {
      return;
    }

    fetchData(filters);

    return () => {
      setCadastralPositions([]);
    };
  }, [filters, company.id, fetchData]);

  const showPagination = useMemo(() => {
    return (
      (pagination?.totalPages !== undefined && pagination.totalPages > 1) ||
      (cadastralPositions?.length > 10 && !loading)
    );
  }, [loading, cadastralPositions?.length, pagination.totalPages]);

  return {
    loading,
    cadastralPositions,
    isEmptyMoves,
    showPagination,
    pagination,
    useColumns,
    getActions,
    handleChangePageSize,
    handlePageChange,
  };
};

export default useCadastralPositionTable;
