import restfulBase from '@/services/negotiations/core/base';
import { Base } from '@/shareds/types/base.type';

export interface Company extends Base {
  name: string;
  cnpj: string;
  startValidityVitta?: string;
  supportName?: string;
}

export const companyInitialState: Company = {
  id: '',
  name: '',
  cnpj: '',
};

const apiService = restfulBase('companies');

export default apiService;
