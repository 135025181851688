import useToastStatus from '@/components/toastStatusGlobal';
import { FileEntity, FileParams } from '@/modules/files/entities/fileEntity';
import FilesService from '@/modules/files/services/filesService';
import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useMemo, useState } from 'react';

const filesService = new FilesService();

interface UseFilesProps {
  idCategory: string;
}

const useFiles = ({ idCategory }: UseFilesProps) => {
  const { company } = useCompany();
  const { toast } = useToastStatus();
  const [filters, setFilters] = useState<FileParams>({
    page: 1,
    pageSize: 50,
  });
  const [files, setFiles] = useState<FileEntity[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currCategoryId, setCurrCategoryId] = useState<string>();

  const isEmpty = useMemo(
    (): boolean => files.length === 0 && !isLoading,
    [files.length, isLoading],
  );

  const showPagination = useMemo(
    (): boolean => pagination?.total > pagination.pageSize,
    [pagination.pageSize, pagination.total],
  );

  const loadFiles = useCallback(
    async (categoryId: string, companyId: string) => {
      if (!categoryId || !companyId) {
        return;
      }

      setLoading(true);
      setFiles([]);
      try {
        const response = await filesService.getFiles(companyId, {
          ...filters,
          'categories.id': categoryId,
          sort: '-updatedAt',
        });
        setFiles(response.data);
        setPagination({
          page: response.page,
          pageSize: response.pageSize,
          total: response.total,
          totalPages: response.totalPages,
        });
      } catch (e) {
        dispatchToast({
          text: 'Ops! Não conseguimos buscar os arquivos desta empresa nesta categoria.',
        });
      } finally {
        setLoading(false);
      }
    },
    [filters],
  );

  const deleteFile = async (id: string) => {
    if (!company.id || !id) {
      return;
    }

    try {
      await filesService.deleteFile(company.id, id);
      toast('Sucesso', 'Arquivo excluído com sucesso!', 'success', undefined, {
        text: 'Clique no botão ao lado para nos contar como está sendo sua experiência com a aplicação.',
        action: {
          label: 'Feedback',
          icon: 'message-circle-outline',
          onClick: () => {
            const feedbackEvent = new CustomEvent('feedbackModal');
            window.dispatchEvent(feedbackEvent);
          },
        },
      });
      loadFiles(idCategory, company.id);
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não foi possível excluir este arquivo!',
      });
    }
  };

  useEffect(() => {
    if (idCategory !== currCategoryId) {
      setCurrCategoryId(idCategory);
      if (filters?.page !== 1) {
        setFilters((prev) => ({ ...prev, page: 1 }));
      }
    }
  }, [currCategoryId, filters?.page, idCategory]);

  useEffect(() => {
    if (!idCategory || !company.id) {
      setFiles([]);
      return;
    }
    loadFiles(idCategory, company.id);
  }, [company.id, idCategory, loadFiles]);

  return {
    filters,
    isLoading,
    isEmpty,
    showPagination,
    pagination,
    files,
    setFilters,
    deleteFile,
  };
};

export default useFiles;
