import { get as _get, omit, set as _set } from 'lodash';

interface RootPath {
  root: string;
  path: string | null;
}

const getRootPath = (key: string): RootPath => {
  const pathSplited = key.split('.');

  return {
    root: pathSplited.shift() || 'root',
    path: pathSplited.length > 0 ? pathSplited.join('.') : null,
  };
};

const extractLastKey = (path: string) => {
  const pathSplited = path.split('.');

  return {
    lastKey: pathSplited.pop() || 'root',
    pathWithoutLastKey: pathSplited.length > 0 ? pathSplited.join('.') : null,
  };
};

export const get = <T = any>(key: string): T | null => {
  const { root, path } = getRootPath(key);
  const { data } = JSON.parse(localStorage.getItem(root) || '{}');

  return path ? _get(data, path) || null : data;
};

export const set = <T = any>(key: string, object: T): T => {
  const { root, path } = getRootPath(key);
  const objectStoraged = get(root) || {};
  const data = path ? _set(objectStoraged, path, object) : object;

  localStorage.setItem(root, JSON.stringify({ data }));

  return data as T;
};

export const remove = <T = any>(key: string): T | null => {
  const { root, path } = getRootPath(key);
  if (!path) {
    localStorage.removeItem(root);
    return null;
  }

  const { data } = JSON.parse(localStorage.getItem(root) || '{}');
  const { lastKey, pathWithoutLastKey } = extractLastKey(path);

  const objectFound = pathWithoutLastKey ? _get(data, pathWithoutLastKey) || null : data;
  const objectWithRemovedKey = omit(objectFound, lastKey);

  if (!objectFound) {
    return null;
  }

  return set(
    pathWithoutLastKey ? `${root}.${pathWithoutLastKey}` : root,
    objectWithRemovedKey || {},
  ) as T;
};

export default { get, set, remove };
