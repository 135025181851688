export enum FINANCIAL_ADJUSTMENT_COLUMNS {
  DATE = 'date',
  VALUE = 'value',
  CONTRACT = 'contract',
  DESCRIPTION = 'description',
  STATUS = 'status',
}

export enum FINANCIAL_ADJUSTMENT_UNIT {
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export enum FINANCIAL_ADJUSTMENT_ACTIONS {
  REMOVE = 'remove',
}

export enum FINANCIAL_ADJUSTMENT_LIST_FILTERS {
  CONTRACT = 'contract',
  FINANCIAL_UNIT = 'financial-unit',
}
