import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import { stringArrayToText } from '@/utils/arrayToText/stringArrayToText';

export const getFileNameByType = (moveType: string): string => {
  if (moveType === 'creation') {
    return 'inclusao';
  }
  if (moveType === 'removal') {
    return 'exclusao';
  }
  return 'alteracao';
};

export const getLines = ({ fields }: MovesValidationErrorDetail, fieldLineSeparator = '.') => {
  const lines = fields.map(field => field.split(fieldLineSeparator).shift() || '-');

  return stringArrayToText(Array.from(new Set(lines)));
}

export const getFieldNames = ({ fields }: MovesValidationErrorDetail, fieldLineSeparator = '.') => {
  const lines = fields.map(field => field.split(fieldLineSeparator).slice(1).join(fieldLineSeparator) || '-');

  return stringArrayToText(Array.from(new Set(lines)));
}
