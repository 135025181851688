import { useCallback } from 'react';

const useSetPageTabTitle = () => {
  const setPageTabTitle = useCallback((title?: string) => {
    const defaultTitle = 'Portal RH';
    if (title) {
      document.title = `${defaultTitle} | ${title}`;
    } else {
      document.title = defaultTitle;
    }
  }, []);

  return { setPageTabTitle };
};

export default useSetPageTabTitle;
