import HttpClientAdapter from '@/shared/services/httpClientAdapter';

class NegotiationService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getNegotiations<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/negotiations`;
    return this.get<T>(url, params);
  }

  getAllNegotiations<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/negotiations`;
    return this.getAll<T>(url, params);
  }

  getContractsActive<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/active-contracts`;
    return this.get<T>(url, params);
  }
}

export default NegotiationService;
