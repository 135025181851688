import React from 'react';

import { Button, Text } from 'vkit/lib/components';

import { downloadFileLink } from '@/utils/common';

import { FormDataSteps } from '../../enum/FormDataSteps';
import { useDownloadTemplate } from '../../hooks';
import { TFormData } from '../../types/FormData.type';

interface TemplateDownloadProps {
  formData: TFormData;
  setData: (key: string, value: any) => void;
  triggerToast: Function;
}

const TemplateDownload: React.FC<TemplateDownloadProps> = ({ formData, setData, triggerToast }) => {
  const { getDownloadLink } = useDownloadTemplate();

  const [downloadLink, setDownloadLink] = React.useState<string | null>(null);

  const handleDownload = React.useCallback((): void => {
    if (downloadLink) {
      setData(FormDataSteps.templateDownloaded, true);
      downloadFileLink(downloadLink);
    } else {
      triggerToast('Oops!', 'Falha ao baixar arquivo.', 'error');
    }
  }, [downloadLink, setData, triggerToast]);

  React.useEffect(() => {
    setDownloadLink(getDownloadLink(formData));
  }, [formData, getDownloadLink]);

  return (
    <div>
      <Text
        value='Clique no botão abaixo para obter o arquivo modelo'
        title
        padding='0 0 18px'
      />
      <Button
        color='colorDefault'
        label='Download do arquivo modelo'
        onClick={() => handleDownload()}
        disabled={!downloadLink}
      />
    </div>
  );
};

export default TemplateDownload;
