import React from 'react';
import { Image, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface ProfileProps {
  name?: string;
}

const Avatar: React.FC<ProfileProps> = ({ name, children }) => {
  return (
    <Grid alignItems='center'>
      <Grid alignItems='bottom' column>
        {name && <Text value={name} textAlign='right' title size='small' rounded />}
        {children}
      </Grid>
      {name && (
        <div
          style={{
            marginLeft: 16,
          }}>
          <Image alt={name} type='rounded' size='small' />
        </div>
      )}
    </Grid>
  );
};

export default Avatar;
