import { useState } from 'react';

import { STATUS_FILE, StatusFile } from '@/components/fileUpload/types/fileUpload.types';
import { attachmentToCreateErrors } from '@/modules/attendance/entities';
import { AttendanceService } from '@/modules/attendance/services';
import { deepClone } from '@/utils';
import { getMessageError } from '@/utils/getMessageError';

interface UseCreateAttendanceAttachmentsProps {
  companyCNPJ?: string;
  attachments?: File[];
}

const attendanceService = new AttendanceService();

const useCreateAttendanceAttachments = ({
  attachments,
  companyCNPJ,
}: UseCreateAttendanceAttachmentsProps) => {
  const [attachmentsStatus, setAttachmentsStatus] = useState<StatusFile[]>([]);
  const [hasAttachmentErrorStatus] = useState<boolean>(true);
  const [isFinishedAttachmentsCreate] = useState<boolean>(false);

  const onChangeStatusFile = (index: number, value: STATUS_FILE, messageError?: string): void => {
    setAttachmentsStatus((status) => {
      status[index] = {
        value,
        messageError,
      };

      return deepClone(status);
    });
  };

  const createAttachment = async (
    index: number,
    attachment: File,
    attendanceId: string,
  ): Promise<boolean | undefined> => {
    if (!companyCNPJ) {
      return;
    }
    onChangeStatusFile(index, STATUS_FILE.UPLOADING);
    try {
      await attendanceService.createAttachment(companyCNPJ, attendanceId, attachment);
      onChangeStatusFile(index, STATUS_FILE.SUCCESS);
      return true;
    } catch (error) {
      console.error(error);
      const messageError = getMessageError({
        error,
        messageErrorByMessageErrorRequest: attachmentToCreateErrors,
        messageDefault: 'Erro ao fazer upload de arquivo.',
      });
      onChangeStatusFile(index, STATUS_FILE.ERROR, messageError);
      return false;
    }
  };

  const createAttachments = async (attendanceId: string): Promise<boolean | undefined> => {
    if (!(attachments?.length && attendanceId && companyCNPJ)) {
      return;
    }

    const promisses = attachments.map(async (attach, index) => {
      await createAttachment(index, attach, attendanceId);
    });

    try {
      await Promise.all(promisses);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return {
    attachmentsStatus,
    hasAttachmentErrorStatus,
    isFinishedAttachmentsCreate,
    createAttachments,
  };
};

export default useCreateAttendanceAttachments;
