import React from 'react';

import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { OverContent, OverContentProps, OverHeader, OverHeaderProps } from './components';

export interface DialogOverProps extends OverHeaderProps, OverContentProps {
  open: boolean;
  onClose: Function;
}

const DialogOver: React.FC<DialogOverProps> = ({
  open,
  onClose,
  negotiation,
  plan,
  costCenter,
  markups,
}) => {
  const useRefDialog = React.useRef({ open: () => ({}), dismiss: () => ({}) });

  React.useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [open]);

  return (
    <Dialog
      elevation={4}
      instanceEmitter={useRefDialog.current}
      outlined
      rounded
      onClose={onClose}
      size='xLarger'
      persistent
      head={<Text size='larger' textAlign='center' title value='Over' />}
      footer={
        <Grid alignContent='right' gap={8}>
          <Button label='Fechar' color='colorDefault' onClick={() => onClose()} />
        </Grid>
      }>
      <OverHeader negotiation={negotiation} plan={plan} costCenter={costCenter} />

      <OverContent markups={markups} />
    </Dialog>
  );
};

export default DialogOver;
