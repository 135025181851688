import { SelectOption } from '@/components/selectValidate';
import { CONTRACT_STATUS } from '@/modules/contract/entities/contract.enum';
import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { Contract } from '@/modules/contract';

interface ContractToForm extends Contract {
  statusToForm: CONTRACT_STATUS;
}

const MultiSelectContractStatusOption: React.FC<{ option: SelectOption<ContractToForm> }> = ({
  option,
}) => {
  return (
    <Grid alignContent='justify' alignItems='center' growing>
      <Grid alignItems='center'>
        <div style={{ minWidth: 100 }}>
          {option.data?.statusToForm === CONTRACT_STATUS.CANCELLED && (
            <Chip color='danger' label='Cancelado' rounded size='small' />
          )}

          {option.data?.statusToForm === CONTRACT_STATUS.EXPIRED && (
            <Chip color='warning' label='Expirado' rounded size='small' />
          )}

          {option.data?.statusToForm === CONTRACT_STATUS.ACTIVE && (
            <Chip color='success' label='Ativo' rounded size='small' />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default MultiSelectContractStatusOption;
