import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

import { BENEFICIARY_STATUS_ALIAS_TRANSLATED, BENEFICIARY_STATUS_COLOR } from '../../entities';
export interface StatusProps {
  label: BENEFICIARY_STATUS_ALIAS_TRANSLATED;
}

const Status: React.FC<StatusProps> = ({ label }) => (
  <Row style={{ padding: '6px 0px', display: 'flex', width: 'max-content' }}>
    <Chip label={label} color={BENEFICIARY_STATUS_COLOR[label]} rounded />
  </Row>
);

export default Status;
