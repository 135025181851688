import React from 'react';

import { DataTable } from 'vkit/lib/components';

import {
  CADASTRAL_DATA_MATCHING_COLUMNS,
  CADASTRAL_DATA_MATCHING_LIST_ACTIONS,
  CadastralDataMatchingParamsToFilter,
  useCadastralDataMatchingTable,
} from '@/modules/cadastralDataMatching';

interface TableProps {
  actions?: CADASTRAL_DATA_MATCHING_LIST_ACTIONS[];
  columns?: CADASTRAL_DATA_MATCHING_COLUMNS[];
  linesLoader?: number;
  filters: CadastralDataMatchingParamsToFilter;
  handleFilters: (params: CadastralDataMatchingParamsToFilter) => void;
}

const Table: React.FC<TableProps> = ({
  actions,
  columns,
  filters,
  handleFilters,
  linesLoader = 5,
}) => {
  const {
    isLoading,
    cadastralDataMatchings,
    showPagination,
    pagination,
    handleChangePageSize,
    handlePageChange,
    getActions,
    useColumns,
  } = useCadastralDataMatchingTable({
    actions,
    columns,
    handleFilters,
    filters,
  });

  return (
    <DataTable
      action={actions && actions.length > 0 ? { items: getActions } : {}}
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={cadastralDataMatchings}
      isLoading={isLoading}
      showPagination={showPagination && !isLoading}
      total={pagination.total}
      totalPages={pagination.totalPages}
      page={pagination.page}
      pageSize={filters?.pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handleChangePageSize}
    />
  );
};

export default Table;
