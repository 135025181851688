export const LIST_INITIAL_PAGE_SIZE = 10;
export const LIST_INITIAL_PAGE = 1;
export const LIST_INITIAL_TOTAL = 0;
export const LIST_INITIAL_TOTAL_PAGES = 1;

export const LIST_INITIAL_PAGINATION = {
  page: LIST_INITIAL_PAGE,
  pageSize: LIST_INITIAL_PAGE_SIZE,
  total: LIST_INITIAL_TOTAL,
  totalPages: LIST_INITIAL_TOTAL_PAGES,
};
