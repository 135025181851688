import React from 'react';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';

import {
  MOVE_LIST_INITIAL_PAGE,
  MOVE_LIST_PAGE_SIZE_DEFAUlT,
  MOVE_TYPE_COLLECTION,
  MoveParams,
  MoveTypeCollection,
} from '../../entities';
import { Filters, Table } from '../../views';

const List: React.FC = () => {
  const [params, setParams] = useQueryStringState<MoveParams>({
    type: MOVE_TYPE_COLLECTION as MoveTypeCollection,
    page: MOVE_LIST_INITIAL_PAGE,
    pageSize: MOVE_LIST_PAGE_SIZE_DEFAUlT,
    sort: '-uploadedAt',
  });

  return (
    <>
      <Filters values={params} setValues={setParams} />

      <Table filters={params} handleFilters={setParams} />
    </>
  );
};

export default List;
