import { CADASTRAL_POSITION_STATUS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import { ParamsToUpload } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import { Colors } from '@/shareds/types';

export const CADASTRAL_POSITION_STATUS_TRANSLATED: Record<string, string> = {
  [CADASTRAL_POSITION_STATUS.NOT_INITIALIZED]: 'Não iniciado',
  [CADASTRAL_POSITION_STATUS.IN_PROCESS]: 'Em processamento',
  [CADASTRAL_POSITION_STATUS.PROCESS_ERROR]: 'Erro de processamento',
  [CADASTRAL_POSITION_STATUS.PROCESS_PROVIDER_ERROR]: 'Erro apontado pelo fornecedor',
  [CADASTRAL_POSITION_STATUS.PROCESSED]: 'Processado',
  [CADASTRAL_POSITION_STATUS.REJECTED]: 'Rejeitado',
};

export const CADASTRAL_POSITION_STATUS_COLOR: Record<string, Colors> = {
  [CADASTRAL_POSITION_STATUS.NOT_INITIALIZED]: 'light',
  [CADASTRAL_POSITION_STATUS.IN_PROCESS]: 'warning',
  [CADASTRAL_POSITION_STATUS.PROCESS_ERROR]: 'danger',
  [CADASTRAL_POSITION_STATUS.PROCESS_PROVIDER_ERROR]: 'danger',
  [CADASTRAL_POSITION_STATUS.PROCESSED]: 'success',
  [CADASTRAL_POSITION_STATUS.REJECTED]: 'danger',
};

export const CADASTRAL_POSITION_ALLOWED_EXTENSIONS = ['csv', 'xls', 'xlsx'];

export const CADASTRAL_POSITION_FORM_DATA: ParamsToUpload = {
  negotiationId: '',
  files: null,
};
