import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { Color } from 'vkit/lib/shared/types';
import { USER_STATUS, USER_STATUS_TRANSLATED } from '../../entities';

interface ChipStatusProps {
  status: USER_STATUS;
}

const ChipStatus: React.FC<ChipStatusProps> = ({ status }) => {
  const color: Record<USER_STATUS, Color> = {
    [USER_STATUS.PENDING]: 'warning',
    [USER_STATUS.ACTIVE]: 'success',
    [USER_STATUS.BLOCKED]: 'light',
    [USER_STATUS.INACTIVATED]: 'danger',
  };

  return (
    <Grid>
      <Chip color={color[status]} label={USER_STATUS_TRANSLATED[status]} size='small' rounded />
    </Grid>
  );
};

export default ChipStatus;
