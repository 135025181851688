import auditIcon from '@/assets/images/audit_icon.svg';
import contractIcon from '@/assets/images/contract-icon.svg';
import planIcon from '@/assets/images/health-svgrepo-com.svg';
import cadastralDataMatchingIcon from '@/assets/images/profile-user.svg';
import vittaIcon from '@/assets/images/vwing.svg';

const ICONS = {
  ADDRESS: 'pin-outline',
  ATTENDANCE: 'message-square-outline',
  ATTACHMENTS: 'attach-outline',
  AUDIT: auditIcon,
  COMPANY: 'briefcase-outline',
  COMPANY_GROUP: 'grid-outline',
  BENEFICIARIES: 'people-outline',
  CADASTRAL_POSITION: 'file-text-outline',
  FAMILY_GROUP: 'people-outline',
  UPLOAD: 'cloud-upload-outline',
  CONTRACT: contractIcon,
  CONTRACT_DATA: 'book-open-outline',
  NEGOTIATION: 'inbox-outline',
  MOVE: 'flip-2-outline',
  USERS: 'people-outline',
  USER: 'person-outline',
  DETAILS: 'eye-outline',
  FINANCIAL_ADJUSTMENTS: 'options-outline',
  LOGOUT: 'log-out-outline',
  PLAN: planIcon,
  RULES: 'book-outline',
  CADASTRAL_DATA_MATCHING: cadastralDataMatchingIcon,
  DOWNLOAD: 'download-outline',
  SEARCH: 'search-outline',
  REMOVE: 'trash-2-outline',
  LINK: 'external-link-outline',
  VITTA: vittaIcon,
  WARNING: 'alert-triangle-outline',
  HELP: 'question-mark-circle-outline',
  FILE_VALIDATION: 'trending-up-outline',
  INFO: 'info-outline',
  FILE_UPLOAD: 'file-outline',
};

export default ICONS;
