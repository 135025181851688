import {
  FINANCIAL_ADJUSTMENT_ACTIONS,
  FINANCIAL_ADJUSTMENT_COLUMNS,
} from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import {
  FinancialAdjustment,
  FinancialAdjustmentToTable,
} from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import useFinancialAdjustmentTableActions from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentTableActions';
import useFinancialAdjustmentTableColumns from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentTableColumns';
import { PERSISTENCE_STATUS } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.enum';
import React from 'react';

import { DataTable } from 'vkit/lib/components';

interface TableProps {
  columns?: FINANCIAL_ADJUSTMENT_COLUMNS[];
  linesLoader?: number;
  financialAdjustments: FinancialAdjustmentToTable[] | FinancialAdjustment[];
  loading?: boolean;
  persistenceStatusByFinancialAdjustmentKey?: Record<string, PERSISTENCE_STATUS>;
  onRemove?: (financialAdjustment: FinancialAdjustmentToTable) => void;
  actions?: FINANCIAL_ADJUSTMENT_ACTIONS[];
}

const FinancialAdjustmentTable: React.FC<TableProps> = ({
  columns,
  linesLoader = 5,
  financialAdjustments,
  loading,
  persistenceStatusByFinancialAdjustmentKey,
  onRemove,
  actions,
}) => {
  const { useColumns } = useFinancialAdjustmentTableColumns({
    columns,
    persistenceStatusByFinancialAdjustmentKey,
  });

  const { getActions } = useFinancialAdjustmentTableActions({
    actions,
    onRemove,
  });

  return (
    <DataTable
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={!loading ? financialAdjustments : []}
      isLoading={Boolean(loading)}
      showPagination={false}
      action={actions && actions.length > 0 ? { items: getActions } : {}}
    />
  );
};

export default FinancialAdjustmentTable;
