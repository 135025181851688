import {
  BENEFICIARY_STATUS,
  BENEFICIARY_STATUS_REFER,
  BeneficiarySearchParams,
  BeneficiaryStatusAlias,
  OWNERSHIP,
} from '../entities';

const filterValidParams = (params: any): any => {
  return Object.entries(params).reduce((acc: Record<string, any>, [key, value]) => {
    if (value || (key === 'isHolder' && value === false)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const normalizeFilterStatus = (status?: BeneficiaryStatusAlias): BENEFICIARY_STATUS[] => {
  if (!status) {
    return [];
  }
  return BENEFICIARY_STATUS_REFER[status] || [];
};

export const normalizeCounterFilters = (filters: BeneficiarySearchParams): Record<string, any> => {
  const params: Record<string, any> = {
    'healthCard.number': filters?.healthCard,
    'life.name': filters?.name,
    negotiationId: filters?.negotiationId,
    benefitId: filters?.benefitId,
  };

  if (filters.status) {
    params.status = normalizeFilterStatus(filters?.status);
  }

  if (filters.type === OWNERSHIP.HOLDER) {
    params.isHolder = true;
  } else if (filters.type === OWNERSHIP.DEPENDENT) {
    params.isHolder = false;
  }

  if (filters.cpf) {
    params['life.documents.type'] = 'cpf';
    params['life.documents.value'] = filters.cpf.replace(/[^0-9]+/g, '');
  }

  return filterValidParams(params);
};

export const normalizeFilters = (filters: BeneficiarySearchParams): Record<string, any> => {
  const validatedFilters = normalizeCounterFilters(filters);
  const params = {
    id: filters.id,
    page: filters.page,
    sort: filters.sort,
    pageSize: filters.pageSize,
    companyId: filters.companyId,
    negotiationId: filters.negotiationId,
    'beneficiaryHolder.holderId': filters?.['beneficiaryHolder.holderId'],
  };

  return {
    ...validatedFilters,
    ...params,
  };
};
