import { ShowHide } from '@/components';
import mixpanel from 'mixpanel-browser';
import { Feedback, Success } from '@/modules/feedback/components';
import { Rating } from '@/modules/feedback/entities';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Colors } from '@/shareds/types';

import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

type DialogConfirmType = {
  open?: boolean;
  title?: string;
  text?: string;
  fnCallback?: (response: boolean) => void;
  modalType?: Colors;
};

const DialogConfirm: React.FC<DialogConfirmType> = ({ open, fnCallback }) => {
  const useRefDialog = useRef({ open: () => {}, dismiss: () => {} });
  const [isSended, setSended] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<{ rating?: Rating; message?: string }>({});

  const handleSetFeedback = (rating?: Rating, message?: string) => {
    if (rating) {
      setFeedback((curr) => ({
        ...curr,
        rating,
      }));
    }
    if (message) {
      setFeedback((curr) => ({
        ...curr,
        message,
      }));
    }
  };

  const submitFeedback = useCallback(() => {
    if (feedback?.rating?.type) {
      mixpanel.track(feedback.rating.type);

      if (feedback?.message && feedback.message.length > 3) {
        mixpanel.track(feedback.rating.type, {
          MESSAGE: feedback?.message,
        });
      }

      setSended(true);
      return;
    }
    setSended(false);
  }, [feedback?.message, feedback?.rating?.type]);

  const handleClose = () => {
    setSended(false);
    setFeedback({});
    fnCallback?.(true);
  };

  const getButtonLabel = useMemo((): string => {
    return isSended ? 'Fechar' : 'Enviar';
  }, [isSended]);

  useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [open]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size='medium'
      title={
        <Text
          value={'Feedback'}
          title
          size='larger'
          icon='message-circle-outline'
          iconColor={'colorDefault'}
          color={'colorDefault'}
          iconSize={32}
          textAlign='left'
        />
      }
      body={
        <>
          <ShowHide visible={!isSended} transition='slideToDown'>
            <Feedback setFeedback={handleSetFeedback} />
          </ShowHide>
          <ShowHide visible={isSended} transition='slideToDown'>
            <Success />
          </ShowHide>
        </>
      }
      footer={
        <Grid alignContent='right' gap={8}>
          {!isSended && (
            <Button label='Avaliar depois' solo color='light' onClick={() => fnCallback?.(false)} />
          )}
          <Button
            label={getButtonLabel}
            color='colorDefault'
            onClick={() => (isSended ? handleClose() : submitFeedback())}
          />
        </Grid>
      }
    />
  );
};

export default DialogConfirm;
