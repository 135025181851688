import { BoxInfo, GridGroup, TextFieldValidate } from '@/components';
import SelectValidate from '@/components/selectValidate';
import { Negotiation } from '@/modules/negotiation';
import TooltipDescription from '@/pages/beneficiaries/createOrUpdate/components/tooltipDescription';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import {
  MOVE_FILE_COLUMN_DESCRIPTION,
  MOVE_FILE_COLUMN_NAME,
} from '@/pages/moves/shareds/constants';
import { ObjectType } from '@/shareds/types';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useContractRules from '../hooks/useContractRules';
import useNegotiationRelationship, { ContractItem } from '../hooks/useNegotiationRelationship';

interface ContractFormProps {
  isDependent: boolean;
  negotiationById: { [key: string]: Negotiation };
  dataToCopy?: BeneficiaryToMove;
  onBlur: (...item: any) => void;
  updateBeneficiaryToMove: (beneficiaryToMove: BeneficiaryToMove) => void;
  useErrors: ObjectType;
  useValues: ObjectType;
}

const contractItemInitialState: ContractItem = {
  benefitId: null,
  providerId: null,
  contractId: null,
  costCenterId: null,
  planId: null,
};

const ContractForm: React.FC<ContractFormProps> = ({
  isDependent,
  negotiationById,
  dataToCopy,
  updateBeneficiaryToMove,
  onBlur,
  useErrors,
  useValues,
}) => {
  const [contractForm, setContractForm] = useState<ContractItem>(contractItemInitialState);

  const {
    benefitsList,
    providersList,
    listContracts,
    listCostCenters,
    listPlans,
    selectedNegotiation,
  } = useNegotiationRelationship({
    negotiationById,
    values: contractForm,
  });

  const {
    isDisabledBenefitSelect,
    isDisabledProviderSelect,
    isDisabledContractSelect,
    isDisabledCostCenterSelect,
    isDisabledPlanSelect,
    handleContractItemSelect,
  } = useContractRules({
    values: contractForm,
    setValues: setContractForm,
    benefitsList,
    providersList,
    listContracts,
    listCostCenters,
    listPlans,
  });

  const disableContractSelect = useMemo(() => {
    return (isDependent && isEmpty(dataToCopy)) || !!dataToCopy?.negotiationId;
  }, [dataToCopy, isDependent]);

  const loadContractInformations = useCallback(
    (negotiationId: string) => {
      const negotiation = negotiationById[negotiationId];
      setContractForm({
        benefitId: get(negotiation, 'product.benefit.id', null),
        providerId: get(negotiation, 'product.provider.id', null),
        contractId: get(negotiation, 'contract.id', null),
        costCenterId: get(negotiation, 'costCenter.id', null),
        planId: get(negotiation, 'product.id', null),
      });
    },
    [negotiationById],
  );

  const setContract = useCallback(
    (negotiation?: Negotiation) => {
      if (!negotiation) {
        updateBeneficiaryToMove({
          negotiationId: '',
          contractId: '',
          [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: '',
          [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: '',
          [MOVE_FILE_COLUMN_NAME.PLANO]: '',
          [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: '',
        });
        return;
      }
      if (negotiation) {
        const { contract, costCenter, product } = negotiation;
        updateBeneficiaryToMove({
          negotiationId: negotiation.id,
          contractId: contract.id,
          [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: contract.code,
          [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: costCenter.name,
          [MOVE_FILE_COLUMN_NAME.PLANO]: product.providerInternalCode,
          [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: product.name,
        });
      }
    },
    [updateBeneficiaryToMove],
  );

  const useContractErrors = useMemo(() => {
    let errors: Record<string, string> = {};
    const isAllSelected = Object.values(contractForm).every((value) => !!value);
    if (isEmpty(useErrors) || isAllSelected) {
      return errors;
    }
    for (const [key, value] of Object.entries(contractForm)) {
      errors[key] = !value ? 'Campo obrigatório' : '';
    }
    return errors;
  }, [contractForm, useErrors]);

  useEffect(() => {
    if (useValues?.beneficiaryId && useValues?.negotiationId) {
      loadContractInformations(useValues.negotiationId);
    }
  }, [loadContractInformations, useValues]);
  useEffect(() => {
    if (disableContractSelect && dataToCopy?.negotiationId) {
      loadContractInformations(dataToCopy.negotiationId);
    }
  }, [dataToCopy?.negotiationId, disableContractSelect, loadContractInformations]);
  useEffect(() => {
    setContract(selectedNegotiation);
  }, [selectedNegotiation, setContract]);

  return (
    <BoxInfo margin={0} title='Contrato' icon='clipboard-outline'>
      <GridGroup
        body={[
          {
            middle: 25,
            default: 25,
            component: (
              <SelectValidate
                key={`benefit-${contractForm['benefitId']}`}
                label='Benefício'
                name='benefitId'
                data={benefitsList}
                onBlur={onBlur}
                onChange={handleContractItemSelect}
                useValues={contractForm}
                disabled={disableContractSelect || isDisabledBenefitSelect}
                required
                useErrors={useContractErrors}
              />
            ),
          },
          {
            middle: 25,
            default: 25,
            component: (
              <SelectValidate
                key={`provider-${contractForm['providerId']}`}
                label='Fornecedor'
                name='providerId'
                data={providersList}
                onBlur={onBlur}
                onChange={handleContractItemSelect}
                useValues={contractForm}
                disabled={disableContractSelect || isDisabledProviderSelect}
                required
                useErrors={useContractErrors}
              />
            ),
          },
          {
            middle: 25,
            default: 25,
            component: (
              <SelectValidate
                key={`contract-${contractForm['contractId']}`}
                label='Código contrato'
                name='contractId'
                data={listContracts}
                onBlur={onBlur}
                onChange={handleContractItemSelect}
                useValues={contractForm}
                disabled={disableContractSelect || isDisabledContractSelect}
                required
                useErrors={useContractErrors}
              />
            ),
          },
          {
            middle: 25,
            default: 25,
            component: (
              <SelectValidate
                key={`costCenter-${contractForm['costCenterId']}`}
                label='Centro de custo'
                name='costCenterId'
                data={listCostCenters}
                onBlur={onBlur}
                onChange={handleContractItemSelect}
                useValues={contractForm}
                disabled={disableContractSelect || isDisabledCostCenterSelect}
                required
                useErrors={useContractErrors}
              />
            ),
          },
          {
            default: 50,
            component: (
              <SelectValidate
                key={`plan-${contractForm['planId']}`}
                data={listPlans}
                disabled={disableContractSelect || isDisabledPlanSelect}
                label='Plano'
                name='planId'
                onBlur={onBlur}
                onChange={handleContractItemSelect}
                required
                useErrors={useContractErrors}
                useValues={contractForm}
              />
            ),
          },
          {
            default: 25,
            component: (
              <TextFieldValidate
                required={!isDependent}
                type='date'
                label={MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO}
                name={MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO}
                useValues={useValues}
                useErrors={useErrors}
                onChange={(key: string, value: string) => {
                  updateBeneficiaryToMove({ [key]: value });
                }}
                onBlur={onBlur}
              />
            ),
          },
          {
            default: 25,
            component: (
              <TextFieldValidate
                header={
                  <TooltipDescription
                    description={MOVE_FILE_COLUMN_DESCRIPTION[MOVE_FILE_COLUMN_NAME.MATRICULA]}
                  />
                }
                type='number'
                required
                label={MOVE_FILE_COLUMN_NAME.MATRICULA}
                name={MOVE_FILE_COLUMN_NAME.MATRICULA}
                useValues={useValues}
                useErrors={useErrors}
                onChange={(key: string, value: string) => {
                  updateBeneficiaryToMove({ [key]: value });
                }}
                onBlur={onBlur}
              />
            ),
          },
        ]}
      />
    </BoxInfo>
  );
};

export default ContractForm;
