export enum COMPANY_GROUP_LIST_FILTERS {
  SEARCH = 'search',
}

export enum COMPANY_GROUP_COLUMNS {
  NAME = 'name',
  CREATED_AT = 'created-at',
}

export enum COMPANY_GROUP_LIST_ACTIONS {
  DETAILS = 'details',
}

export enum COMPANY_GROUP_TAB_IDS {
  COMPANIES,
  FINANCIAL_ADJUSTMENT,
}
