import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { DataType } from 'vkit/lib/shared/components/list/types/list.type';

export interface Column extends DataType {
  showOnMobile?: boolean;
}

const useContentList = (
  columns: Column[],
): {
  useDataList: DataType[];
  useListRef: MutableRefObject<HTMLDivElement | null>;
} => {
  const [useDataList, setDataList] = useState<Column[]>([]);
  const useListRef = useRef(null);

  const getColumns = useCallback((): Column[] => {
    if (window.innerWidth <= 768) {
      return columns.filter((column) => column.showOnMobile);
    }

    return columns;
  }, [columns]);

  useEffect(() => {
    setDataList(getColumns());
  }, [getColumns]);

  return {
    useDataList,
    useListRef,
  };
};

export default useContentList;
