import { BENEFIT_BASE_IDS_FOR_MOVE } from '@/modules/benefit/entities';
import { CONTRACT_STATUS } from '@/modules/contract';
import { Negotiation } from '@/modules/negotiation';
import { useCallback } from 'react';

const useFilterNegotiationMove = () => {
  const filterNegotiationActiveMove = useCallback((negotiations: Negotiation[]) => {
    return negotiations.filter(
      (negotiation) =>
        BENEFIT_BASE_IDS_FOR_MOVE.includes(negotiation.product.benefit.baseId) &&
        negotiation.contract.status === CONTRACT_STATUS.ACTIVE,
    );
  }, []);

  return {
    filterNegotiationActiveMove,
  };
};

export default useFilterNegotiationMove;
