import ShowHide from '@/components/showHide';
import FinancialAdjustmentForm from '@/modules/financialAdjustment/components/financialAdjustmentForm';
import FinancialAdjustmentTable from '@/modules/financialAdjustment/components/financialAdjustmentTable';
import {
  FINANCIAL_ADJUSTMENT_ACTIONS,
  FINANCIAL_ADJUSTMENT_COLUMNS,
} from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import { useFinancialAdjustmentCollection } from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentCollection';
import { useFinancialAdjustmentPersistence } from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentPersistence';
import { useProcessParamsFromForm } from '@/modules/financialAdjustment/hooks/useProcessParamsFromForm';
import { useHistoryNavigator } from '@/navigation';
import React from 'react';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface FinancialAdjustmentCreateProps {
  companyGroupId: string;
}

const FinancialAdjustmentCreate: React.FC<FinancialAdjustmentCreateProps> = ({
  companyGroupId,
}) => {
  const navigate = useHistoryNavigator();

  const { financialAdjustments, setFinancialAdjustments, removeFinancialAdjustment } =
    useFinancialAdjustmentCollection();

  const { processParamsFromForm, paramsFromForm, setParamsFromForm, processKey } =
    useProcessParamsFromForm({
      processParamsFromFormCallback: setFinancialAdjustments,
      companyGroupId,
    });

  const {
    persistenceStatusByFinancialAdjustmentKey,
    updatingPersistence,
    saveManyFinancialAdjustments,
  } = useFinancialAdjustmentPersistence({
    financialAdjustments,
    saveManyFinancialAdjustmentsCallback: () => navigate.goBack(),
  });

  return (
    <Grid column gap={16}>
      <FinancialAdjustmentForm
        key={processKey}
        processParamsFromForm={processParamsFromForm}
        paramsFromForm={paramsFromForm}
        setParamsFromForm={setParamsFromForm}
        loading={updatingPersistence}
      />

      <ShowHide transition='slideToDown' visible={financialAdjustments.length}>
        <FinancialAdjustmentTable
          financialAdjustments={financialAdjustments}
          persistenceStatusByFinancialAdjustmentKey={persistenceStatusByFinancialAdjustmentKey}
          onRemove={({ key }) => key && removeFinancialAdjustment(key)}
          columns={[
            FINANCIAL_ADJUSTMENT_COLUMNS.CONTRACT,
            FINANCIAL_ADJUSTMENT_COLUMNS.DATE,
            FINANCIAL_ADJUSTMENT_COLUMNS.VALUE,
            FINANCIAL_ADJUSTMENT_COLUMNS.DESCRIPTION,
            FINANCIAL_ADJUSTMENT_COLUMNS.STATUS,
          ]}
          actions={[FINANCIAL_ADJUSTMENT_ACTIONS.REMOVE]}
        />

        <Grid alignContent='right' margin='16px 0 0'>
          <Button
            loading={updatingPersistence}
            label='Salvar'
            onClick={() => saveManyFinancialAdjustments(financialAdjustments)}
            color='colorDefault'
          />
        </Grid>
      </ShowHide>
    </Grid>
  );
};

export default FinancialAdjustmentCreate;
