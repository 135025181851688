import NegotiationsService from '@/shared/services/negotiationsService';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ActionsLayer, Button } from 'vkit/lib/components';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty } from 'lodash';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

import { WordBreak } from '@/components';
import { getFormattedDate } from '@/utils/format';
import { useHistoryNavigator } from '@/navigation';

import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { BasePagination } from '@/shared/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import {
  COMPANY_GROUP_COLUMNS,
  COMPANY_GROUP_LIST_ACTIONS,
} from '@/modules/companyGroup/entities/companyGroup.enum';
import {
  CompanyGroup,
  CompanyGroupListResponse,
  ParamsToFilterCompanyGroups,
} from '@/modules/companyGroup/entities/companyGroup.interface';

interface UseCompanyGroupTable {
  actions?: COMPANY_GROUP_LIST_ACTIONS[];
  columns?: COMPANY_GROUP_COLUMNS[];
  filters: ParamsToFilterCompanyGroups;
  setFilters: (params: ParamsToFilterCompanyGroups) => void;
}

const negotiationsService = new NegotiationsService();

const useCompanyGroupTable = ({ actions, columns, filters, setFilters }: UseCompanyGroupTable) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyGroups, setCompanyGroups] = useState<CompanyGroup[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const { company } = useCompany();

  const AVALIBLE_COLUMNS: Record<COMPANY_GROUP_COLUMNS, DataTableColumn> = useMemo(
    () => ({
      [COMPANY_GROUP_COLUMNS.NAME]: {
        name: 'NOME',
        path: 'name',
        format: ({ name }: CompanyGroup) => <WordBreak>{name}</WordBreak>,
      },
      [COMPANY_GROUP_COLUMNS.CREATED_AT]: {
        name: 'CRIADO EM',
        path: 'createdAt',
        minWidth: '180px',
        format: ({ createdAt }: CompanyGroup) =>
          createdAt ? getFormattedDate(createdAt, { showHours: true }) : null,
      },
    }),
    [],
  );

  const navigate = useHistoryNavigator();

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  const getActions = ({ id }: CompanyGroup) => {
    const AVALIBLE_ACTIONS = {
      [COMPANY_GROUP_LIST_ACTIONS.DETAILS]: {
        label: 'Ver detalhes',
        onClick: () => {
          navigate.push(`${id}/details`);
        },
        icon: ICONS.DETAILS,
      },
    };

    const listActions = actions
      ? actions.map((action) => AVALIBLE_ACTIONS[action])
      : Object.values(AVALIBLE_ACTIONS).map((value) => value);

    if (listActions.length === 1) {
      return [
        <Button
          key={id}
          solo
          outlined
          label={listActions[0].label}
          onClick={listActions[0].onClick}
          color='colorDefault'
          iconColor='colorDefault'
        />,
      ];
    }

    return [<ActionsLayer key={id} vertical actions={listActions} />];
  };

  const handleChangePageSize = (value: number) => {
    setFilters({
      ...filters,
      pageSize: value,
      page: 1,
    });
  };

  const handlePageChange = (value: number) => {
    setFilters({
      ...filters,
      page: value,
    });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { data, page, totalPages, pageSize, total } =
        await negotiationsService.getCompanyGroups<
          CompanyGroupListResponse,
          ParamsToFilterCompanyGroups
        >(filters);
      setCompanyGroups(data);
      setPagination({
        page,
        totalPages,
        pageSize,
        total,
      });
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não foi possível buscar os grupos de empresas.',
      });
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (isEmpty(filters) || !company.id) {
      return;
    }

    fetchData();

    return () => {
      setCompanyGroups([]);
    };
  }, [filters, company.id, fetchData]);

  const showPagination = useMemo(() => {
    return (
      (pagination?.totalPages !== undefined && pagination.totalPages > 1) ||
      (companyGroups.length > 10 && !loading)
    );
  }, [loading, companyGroups.length, pagination.totalPages]);

  return {
    loading,
    companyGroups,
    showPagination,
    pagination,
    useColumns,
    getActions,
    handleChangePageSize,
    handlePageChange,
  };
};

export default useCompanyGroupTable;
