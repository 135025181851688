import { SelectOption } from '@/components/selectValidate';
import { Negotiation } from '@/modules/negotiation';
import { get, uniqBy } from 'lodash';
import { useMemo } from 'react';

export type ContractItemKey = 'benefitId' | 'providerId' | 'contractId' | 'costCenterId' | 'planId';

export interface ContractItem {
  benefitId: string | null;
  providerId: string | null;
  contractId: string | null;
  costCenterId: string | null;
  planId: string | null;
}

interface UseNegotiationRelationshipProps {
  negotiationById: { [key: string]: Negotiation };
  values: ContractItem;
}

const useNegotiationRelationship = ({
  negotiationById,
  values,
}: UseNegotiationRelationshipProps) => {
  const benefitsList = useMemo((): SelectOption[] => {
    if (!negotiationById) {
      return [];
    }
    const list = Object.values(negotiationById).map((negotiation: Negotiation) => {
      const {
        product: { benefit },
      } = negotiation;
      return {
        text: benefit.name,
        value: benefit.id,
        ...benefit,
      };
    });
    return uniqBy(list, 'value') as SelectOption[];
  }, [negotiationById]);

  const providersList = useMemo((): SelectOption[] => {
    if (!values?.benefitId || !negotiationById) {
      return [];
    }
    const list = Object.values(negotiationById)
      .filter(
        (negotiation: Negotiation) => get(negotiation, 'product.benefit.id') === values.benefitId,
      )
      .map((negotiation: Negotiation) => {
        const {
          product: { provider },
        } = negotiation;
        return {
          text: provider.name,
          value: provider.id,
          ...provider,
        };
      });
    return uniqBy(list, 'value') as SelectOption[];
  }, [values.benefitId, negotiationById]);

  const listContracts = useMemo((): SelectOption[] => {
    if (!values?.providerId || !negotiationById) {
      return [];
    }
    const list = Object.values(negotiationById)
      .filter((negotiation: Negotiation) => {
        const isSameBenefit = get(negotiation, 'product.benefit.id') === values.benefitId;
        const isSameProvider = get(negotiation, 'product.provider.id') === values.providerId;
        return isSameBenefit && isSameProvider;
      })
      .map((negotiation: Negotiation) => {
        const { contract } = negotiation;
        return {
          text: contract.code,
          value: contract.id,
          ...contract,
        };
      });
    return uniqBy(list, 'value') as SelectOption[];
  }, [values.providerId, values.benefitId, negotiationById]);

  const listCostCenters = useMemo((): SelectOption[] => {
    if (!values?.contractId || !negotiationById) {
      return [];
    }
    const list = Object.values(negotiationById)
      .filter((negotiation: Negotiation) => {
        const isSameBenefit = get(negotiation, 'product.benefit.id') === values.benefitId;
        const isSameProvider = get(negotiation, 'product.provider.id') === values.providerId;
        const isSameContract = get(negotiation, 'contract.id') === values.contractId;
        return isSameBenefit && isSameProvider && isSameContract;
      })
      .map((negotiation: Negotiation) => {
        const { costCenter } = negotiation;
        return {
          text: costCenter.name,
          value: costCenter.id,
          ...costCenter,
        };
      });
    return uniqBy(list, 'value') as SelectOption[];
  }, [values.contractId, values.benefitId, values.providerId, negotiationById]);

  const listPlans = useMemo(() => {
    if (!values.costCenterId || !negotiationById) {
      return [];
    }
    const list = Object.values(negotiationById)
      .filter((negotiation: Negotiation) => {
        const isSameBenefit = get(negotiation, 'product.benefit.id') === values.benefitId;
        const isSameProvider = get(negotiation, 'product.provider.id') === values.providerId;
        const isSameContract = get(negotiation, 'contract.id') === values.contractId;
        const isSameCostCenter = get(negotiation, 'costCenter.id') === values.costCenterId;
        return isSameBenefit && isSameProvider && isSameContract && isSameCostCenter;
      })
      .map((negotiation: any) => {
        const { product } = negotiation;
        return {
          text:
            product.name +
            (product.providerInternalCode ? ` - ${product.providerInternalCode}` : ''),
          value: product.id,
          name: product.name,
          internalCode: product.providerInternalCode,
          negotiationId: negotiation.id,
        };
      });
    return uniqBy(list, 'value');
  }, [negotiationById, values]);

  const selectedNegotiation = useMemo(() => {
    const isAllSelected = Object.values(values).every((value) => !!value);
    if (isAllSelected) {
      return Object.values(negotiationById).find((negotiation: Negotiation) => {
        const isSameBenefit = get(negotiation, 'product.benefit.id') === values.benefitId;
        const isSameProvider = get(negotiation, 'product.provider.id') === values.providerId;
        const isSameContract = get(negotiation, 'contract.id') === values.contractId;
        const isSameCostCenter = get(negotiation, 'costCenter.id') === values.costCenterId;
        const isSamePlan = get(negotiation, 'product.id') === values.planId;
        return isSameBenefit && isSameProvider && isSameContract && isSameCostCenter && isSamePlan;
      });
    }
  }, [negotiationById, values]);

  return {
    benefitsList,
    providersList,
    listContracts,
    listCostCenters,
    listPlans,
    selectedNegotiation,
  };
};

export default useNegotiationRelationship;
