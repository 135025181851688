import { WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT, waitLogin } from '@/modules/authentication/utils/waitLogin';
import { getToken } from '@/utils/authentication';
import { dispatchEvent } from '@/utils/dispatchEvent/dispatchEvent';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

type ObjectType = { [key: string]: any };
type RequestConfigType = (...item: any) => Promise<ObjectType | undefined>;

export interface IRestfulService {
  apiInstance: AxiosInstance;
  post: RequestConfigType;
  remove: RequestConfigType;
  update: RequestConfigType;
  get: RequestConfigType;
}

type RestfulServiceType = {
  service: string;
  uri: string;
  headers?: ObjectType;
};

// Unificar pontos de requisição de dados, task https://vitta-dev.atlassian.net/browse/HEF-1831
const restfulService = (options: RestfulServiceType): IRestfulService => {
  const { uri, service, headers } = options;

  const api = axios.create({
    baseURL: uri,
    timeout: 60000,
    headers,
  });

  api.interceptors.response.use((response) => response.data);

  const request = async (config: AxiosRequestConfig) => {
    try {
      return await api.request(config);
    } catch (error) {
      const hasToken = getToken();
      if ((error as AxiosError).response?.status === 401 && hasToken && !config.url?.includes('auth')) {
        dispatchEvent('modal-login', { open: true });
        await waitLogin({ timeoutInMilliseconds: WAIT_LOGIN_TIMEOUT_IN_MILLISECONDS_DEFAULT });

        return api.request(config);
      }

      throw error;
    }
  }

  const post = async (data: object, resource: string): Promise<ObjectType | undefined> =>
    request({
      url: !!resource ? `/${service}/${resource}` : `/${service}`,
      method: 'POST',
      data,
    });

  const remove = async (id: number, resource: string): Promise<ObjectType | undefined> =>
    request({
      url: !!resource ? `/${service}/${resource}/${id}` : `/${service}/${id}`,
      method: 'DELETE',
    });

  const update = async (
    data: object,
    id: number,
    resource: string,
  ): Promise<ObjectType | undefined> =>
    request({
      url: !!resource ? `/${service}/${id}/${resource}` : `/${service}/${id}`,
      data,
      method: 'PATCH',
    });

  const get = async (params: object, resource: string): Promise<ObjectType | undefined> =>
    request({
      url: !!resource ? `/${service}/${resource}` : `/${service}`,
      params,
    });

  return {
    apiInstance: api,
    post,
    remove,
    update,
    get,
  };
};

export default restfulService;
