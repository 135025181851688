const validateDate = (value: string): boolean => {
  if (value.length >= 10) {
    const date = new Date(value.slice(0, 10));
    return !!date?.getDate();
  }

  return false;
};

const date = (fieldValue: string): string | null =>
  fieldValue === '' || validateDate(fieldValue) ? null : 'Data inválida';

export default date;
