import ContractTableList from '@/modules/contract/views/contractTableList/contractTableList';
import React, { useEffect } from 'react';
import { Tabs } from 'vkit/lib/components';
import { CONTRACTS_TABS, CONTRACTS_TABS_ID } from '../../entities/contract.const';
import { useList } from '@/modules/contract/hooks';
import { useContractsLoader } from '@/modules/contract/hooks/useContractsLoader/useContractsLoader';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { PAGE_SIZE } from '@/shareds/constants/pagination';

const List: React.FC = () => {
  const { activeTab, handleChangeTabs, renderTabs } = useList({
    initialActiveTab: CONTRACTS_TABS_ID.ACTIVE,
    contractsTabs: CONTRACTS_TABS,
  });

  const tabActiveValue = CONTRACTS_TABS[activeTab];
  const { company } = useCompany();
  const { loadContracts, changeStatusTab, data } = useContractsLoader({
    tabActiveValue,
  });

  useEffect(() => {
    if (!company.id) {
      return;
    }
    (async () =>
      loadContracts({
        page: 1,
        pageSize: PAGE_SIZE,
        sort:'status'
      }))();
  }, [company.id, loadContracts]);

  useEffect(() => {
    changeStatusTab();
  }, [activeTab, changeStatusTab]);

  return (
    <>
      <div data-testid='wrapper-tabs'>
        <Tabs iconDir='left' elements={[]} actions={renderTabs} onChange={handleChangeTabs} />
      </div>

      <ContractTableList
        data={data}
        columns={['logo', 'provider', 'benefit', 'code', 'validFrom', 'validUntil', 'status']}
        actions={['goToDetails']}
      />
    </>
  );
};

export default List;
