import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import React, { useCallback } from 'react';

import { BoxInfo, DataTable } from '@/components';
import ICONS from '@/shareds/constants/icons';
import { Button } from 'vkit/lib/components';
import { MoveAttachment } from '../../entities';

interface AttachmentsProps {
  attachments?: MoveAttachment[];
}

export const Attachments: React.FC<AttachmentsProps> = ({ attachments }) => {
  const { downloadFile, loading: loadingDownload } = useFileDownload();

  const renderDownloadAction = useCallback(
    (moveAttachment: MoveAttachment) => (
      <Button
        loading={loadingDownload}
        disabled={!moveAttachment?.id}
        icon={ICONS.DOWNLOAD}
        label='Download da planilha'
        onClick={() => (moveAttachment ? downloadFile(moveAttachment.id) : null)}
      />
    ),
    [downloadFile, loadingDownload],
  );

  return (
    <BoxInfo title='Anexos' icon={ICONS.ATTACHMENTS}>
      <DataTable
        noPagination
        loading={!attachments}
        data={attachments}
        elevation={0}
        columns={[
          {
            title: 'NOME DO ARQUIVO',
            path: 'name',
          },
        ]}
        actionsByLine={renderDownloadAction}
      />
    </BoxInfo>
  );
};
