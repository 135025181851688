import restfulService from '@/utils/restful';

export interface AddressViaCep {
  cep: string;
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
  complemento: string;
  erro?: boolean;
}

const { REACT_APP_API_SEARCH_ADDRESS = '' } = process.env;

const api = restfulService({
  service: 'ws',
  uri: REACT_APP_API_SEARCH_ADDRESS,
});

export const viaCepService = {
  find: async (cep: string): Promise<AddressViaCep> =>
    api.get({}, `${cep}/json`) as Promise<AddressViaCep>,
};
