import useCompany from '@/shared/hooks/useCompany/useCompany';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BreadcrumbItem } from '@/components/breadcrumb';
import { FileCategory } from '@/modules/files/entities/fileCategories';
import { useHistoryLocation } from '@/navigation';
import { capitalize } from 'lodash';
import FilesService from '../../services/filesService';

const filesService = new FilesService();

interface UseFilesCategoriesParams {
  selectedCategory: string | null;
  isUploadPage?: boolean;
}

const useFilesCategories = ({ selectedCategory, isUploadPage }: UseFilesCategoriesParams) => {
  const { company } = useCompany();
  const [categories, setCategories] = useState<FileCategory[]>([]);
  const [flattenCategories, setFlattenCategories] = useState<FileCategory[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const location = useHistoryLocation();

  const getFlattenCategories = (categories: FileCategory[]): FileCategory[] => {
    const children: FileCategory[] = [];

    function traverse(category: FileCategory) {
      children.push(category);

      for (const child of category.children) {
        traverse(child);
      }
    }

    for (const category of categories) {
      traverse(category);
    }

    return children;
  };

  const loadCategories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await filesService.getCategories();
      setFlattenCategories(getFlattenCategories(response));
      setCategories(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const generateBreadcrumb = useCallback(
    (selectedCategory: string | null): Array<{ [key: string]: any }> => {
      const current: FileCategory | undefined = flattenCategories.find(
        (category) => selectedCategory === category.id,
      );

      if (!current) {
        return [];
      }

      const breadcrumb: Array<{ [key: string]: any }> = [
        {
          label: capitalize(current.name),
          path: `${location.pathname}?idCategory=${current.id}`,
          actionOptions: flattenCategories
            .filter(
              (category) =>
                category.categoryParentId === current.categoryParentId &&
                category.id !== selectedCategory,
            )
            .map((category) => ({
              label: capitalize(category.name),
              path: `${location.pathname}?idCategory=${category.id}`,
            })),
        },
      ];

      if (current.categoryParentId) {
        return [...breadcrumb, ...generateBreadcrumb(current.categoryParentId)];
      }

      return breadcrumb.reverse();
    },
    [flattenCategories, location.pathname],
  );

  const listCategories = useMemo(() => {
    return flattenCategories.filter((category) => category.categoryParentId === selectedCategory);
  }, [flattenCategories, selectedCategory]);

  const breadcrumbCategories = useMemo(() => {
    const recursive = generateBreadcrumb(selectedCategory).reverse() as BreadcrumbItem[];

    const breadcrumb: BreadcrumbItem[] = [
      {
        label: 'Início',
        path: `/companies/${company.id}/files`,
      },
      ...recursive,
    ];

    if (!selectedCategory) {
      return breadcrumb;
    }

    if (isUploadPage) {
      breadcrumb.push({
        disabled: true,
        label: 'Enviar arquivos',
        path: `${location.pathname}/upload?idCategory=${selectedCategory}`,
      });
    }

    return breadcrumb;
  }, [company.id, generateBreadcrumb, isUploadPage, location.pathname, selectedCategory]);

  const hideUploadButton = useMemo(() => {
    const category = flattenCategories.find((category) => category.id === selectedCategory);
    return !category?.categoryParentId;
  }, [flattenCategories, selectedCategory]);

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadCategories();
  }, [company.id, loadCategories]);

  return {
    categories,
    breadcrumbCategories,
    listCategories,
    isLoadingCategories: isLoading,
    hideUploadButton,
  };
};

export default useFilesCategories;
