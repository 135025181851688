import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import Home from '../pages/home/home';
import List from '../views/list/list';
import UploadFrom from '../views/uploadForm/uploadForm';

export enum ROUTE_FILES_LIST {
  LIST = 'files-list',
  UPLOAD = 'files-upload',
}

const Routes: Route[] = [
  {
    path: '/companies/:companyId/files',
    Component: Home,
    id: ROUTE_FILES_LIST.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      nameMenu: 'Arquivos',
      icon: 'file-outline',
    },
    loader() {
      return createRouteLoaderPageDetails({
        name: 'Arquivos',
        icon: 'file-outline',
        title: 'Arquivos',
      });
    },
    children: [
      {
        index: true,
        Component: List,
      },
      {
        Component: UploadFrom,
        path: 'upload',
        id: ROUTE_FILES_LIST.UPLOAD,
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Arquivos',
            icon: 'file-outline',
            title: 'Arquivos | Upload',
          });
        },
      },
    ],
  },
];

export default Routes;
