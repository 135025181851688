import Tooltip from '@/components/tooltip';
import { PERSISTENCE_STATUS_INFO } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.const';
import { PERSISTENCE_STATUS } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.enum';
import React, { useMemo } from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface PersistenceStatusProps {
  status: PERSISTENCE_STATUS;
}

const PersistenceStatus: React.FC<PersistenceStatusProps> = ({ status }) => {
  const persistenceInfo = useMemo(() => PERSISTENCE_STATUS_INFO[status], [status]);

  return (
    <Grid>
      <Tooltip title={persistenceInfo.description}>
        <Chip rounded size='small' label={persistenceInfo.name} color='light' />
      </Tooltip>
    </Grid>
  );
};

export default PersistenceStatus;
