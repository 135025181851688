import { FileEntity } from '@/modules/files/entities/fileEntity';
import FilesService from '@/modules/files/services/filesService';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useState } from 'react';

const filesService = new FilesService();

const useFile = () => {
  const { company } = useCompany();
  const [file, setFile] = useState<FileEntity>();
  const [loading, setLoading] = useState(false);

  const loadFile = useCallback(
    async (id: string) => {
      if (!company.id || !id) {
        return;
      }

      setLoading(true);
      try {
        const response = await filesService.getDownloadToken(company.id, id);
        setFile(response);
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! Não foi possível carregar os dados do arquivo!',
        });
      } finally {
        setLoading(false);
      }
    },
    [company.id],
  );

  return {
    file,
    loadFile,
    loading,
  };
};

export default useFile;
