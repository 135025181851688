import restfulBase from '@/services/negotiations/core/base';

export type Address = {
  id?: string;
  zipCode: string;
  city: string;
  companyId: string;
  complement: string;
  neighborhood: string;
  number: string;
  location?: string;
  street?: string;
  state: string;
};

export const addressesService = (companyId: string) =>
  restfulBase(`companies/${companyId}/addresses`);

export default addressesService;
