import { Life } from '@/services/beneficiaries/endpoints/lives';
import { Base } from '@/shareds/types/base.type';

export enum CONTACT_TYPE {
  EMAIL = 'email',
  PHONE = 'phone',
}

export interface Contact extends Base {
  type: CONTACT_TYPE;
  value: string;
  lifeId: string;
  life?: Life;
}
