import {
  BoxInfo,
  FormValidate,
  GridGroup,
  TextFieldValidate,
  ToastStatusGlobal,
} from '@/components';
import { BodyForm } from '@/components/formValidate';
import ShowHide from '@/components/showHide';
import SelectCompanyChips from '@/modules/company/views/selectCompanyChips/selectCompanyChips';
import useCurrentUser from '@/modules/profile/hooks/useCurrentUser/useCurrentUser';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import React, { Dispatch, ReactNode, SetStateAction, useMemo } from 'react';
import { Text, TextField } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import {
  MAXLENGTH_USER_FORM,
  USER_STATUS,
  USER_STATUS_TRANSLATED,
  USER_TYPE,
  User,
} from '../../entities';
import { FileCategoryPermissions } from '../fileCategoryPermissions';
import SelectPermission from '../selectPermissions/selectPermissions';
import useCreateOrUpdateForm from './hooks/useCreateOrUpdateForm';

interface CreateOrUpdateFormProps {
  scope: `${USER_TYPE}`;
  user?: User;
  isLoading?: boolean;
  headerActions?: ReactNode;
  setFormCompanyIds?: Dispatch<SetStateAction<string[]>>;
}

const { REACT_APP_BROKER_ID = '' } = process.env;

const CreateOrUpdateFrom: React.FC<CreateOrUpdateFormProps> = ({
  scope,
  user,
  isLoading,
  headerActions,
  setFormCompanyIds,
}) => {
  const { company } = useCompany();
  const { toast } = ToastStatusGlobal();
  const { userCompanies: loggedUserCompanies, isCompanyUser } = useCurrentUser();

  const isDeletedUser = user?.status === USER_STATUS.INACTIVATED;
  const scopeId = useMemo(
    () => (scope === USER_TYPE.BROKER ? REACT_APP_BROKER_ID : company?.id || ''),
    [company.id, scope],
  );

  const {
    isSavingForm,
    checkEmailBroker,
    onChangeForm,
    formData,
    createOrUpdate,
    setFileFoldersPermission,
    scopedPermission,
  } = useCreateOrUpdateForm({ user, scope, scopeId });

  const disabledForm = useMemo(
    () => isSavingForm || isLoading || isDeletedUser,
    [isLoading, isSavingForm, isDeletedUser],
  );
  const isRelatedCurrentCompany = useMemo(
    () => formData.scopeIds.includes(company.id),
    [company.id, formData.scopeIds],
  );
  const companyFiltersToCompanyUser = useMemo(() => {
    if (scope === USER_TYPE.BROKER || !loggedUserCompanies?.length) {
      return;
    }

    return {
      id: loggedUserCompanies.map(({ id }) => id),
    };
  }, [scope, loggedUserCompanies]);

  const getFields = ({ onBlur, onChange, useErrors, useValues }: BodyForm) => (
    <>
      <GridGroup
        body={[
          {
            default: 33.33,
            component: (
              <TextFieldValidate
                label='Nome'
                maxLength={MAXLENGTH_USER_FORM}
                name='name'
                onBlur={onBlur}
                onChange={onChange}
                placeholder='Informe o nome do usuário'
                required
                useErrors={useErrors}
                useValues={useValues}
                disabled={disabledForm}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <TextFieldValidate
                disabled={Boolean(user?.id) || disabledForm}
                label={`E-mail ${scope === 'broker' ? '(@vitta ou @stone)' : ''}`}
                loading={isLoading}
                maxLength={MAXLENGTH_USER_FORM}
                name='email'
                onBlur={onBlur}
                onChange={onChange}
                placeholder='Informe o e-mail'
                required
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
          {
            default: 33.33,
            component: (
              <TextField
                label='Status'
                disabled
                value={
                  user?.status && !isLoading
                    ? USER_STATUS_TRANSLATED[user.status]
                    : 'Aguardando confirmação'
                }
              />
            ),
          },
          {
            middle: 100,
            default: 100,
            hidden:
              scope === USER_TYPE.BROKER ||
              (isCompanyUser && !companyFiltersToCompanyUser?.id.length),
            component: (
              <>
                <SelectCompanyChips
                  data-testid='select-company-chips'
                  id='selectCompanyChips'
                  label='Empresas'
                  value={useValues.scopeIds || []}
                  onChange={(value: string[]) => {
                    onChange('scopeIds', value);
                    onBlur('scopeIds', value);
                    setFormCompanyIds?.(value);
                  }}
                  messageError={useErrors.scopeIds}
                  disabled={disabledForm}
                  required
                  filters={companyFiltersToCompanyUser}
                  noRequestOnSearch={isCompanyUser}
                />

                <ShowHide visible={!isRelatedCurrentCompany} transition='slideToDown'>
                  <Text
                    data-testeid='message-not-related-company'
                    value={
                      `Este usuário não esta relacionado a empresa ${company.name}, ` +
                      'portanto ele não será exibido na listagem de usuários dessa empresa. ' +
                      `Para relacionar a empresa ${company.name} a este usuário, selecione-a no campo acima.`
                    }
                    applyBaseColor
                    color='warning'
                    iconColor='warning'
                    icon={ICONS.WARNING}
                    size='medium'
                    padding='8px 16px'
                    rounded
                    margin='8px 0 0'
                  />
                </ShowHide>
              </>
            ),
          },
          {
            middle: 100,
            default: 100,
            component: (
              <>
                <SelectPermission
                  scope={scope}
                  useValues={useValues}
                  onBlur={onBlur}
                  onChange={onChange}
                  isDisabled={disabledForm}
                />

                <ShowHide visible={formData.scopeIds.length > 1} transition='slideToDown'>
                  <Text
                    value={
                      'As permissões selecionadas no campo acima serão aplicadas ' +
                      `ao usuário em todas as ${formData.scopeIds.length} empresas selecionadas.`
                    }
                    applyBaseColor
                    color='light'
                    iconColor='light'
                    icon={ICONS.INFO}
                    size='medium'
                    padding='8px 16px'
                    rounded
                    margin='8px 0 0'
                  />
                </ShowHide>
              </>
            ),
          },
        ]}
      />
      <Row style={{ padding: '24px 0 0 0' }}>
        {!isLoading && (
          <FileCategoryPermissions
            isLoading={isSavingForm}
            updateFilesDirPermissions={setFileFoldersPermission}
            initialPermissions={scopedPermission}
            disabled={disabledForm}
            scope={scope}
          />
        )}
      </Row>
    </>
  );

  return (
    <BoxInfo icon={ICONS.USER} title='Dados do usuário' header={headerActions} loading={isLoading}>
      <FormValidate
        resource={formData}
        fields={{
          name: ['required'],
          email: ['required', 'email', ...(scope === 'broker' ? [checkEmailBroker] : [])],
          permissions: [],
          scopeIds: ['notEmpty'],
        }}
        onChangeForm={onChangeForm}
        onError={() =>
          toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning')
        }
        onSubmit={() => {
          if (!disabledForm) {
            createOrUpdate();
          }
        }}
        body={getFields}
        buttons={{
          submit: {
            disabled: disabledForm,
            tooltip: disabledForm
              ? 'Este usuário está inativo, portanto a funcionalidade de alteração não é permitida.'
              : null,
            loading: isSavingForm || isLoading,
            text: formData.id ? 'Alterar usuário' : 'Registrar novo usuário',
          },
        }}
      />
    </BoxInfo>
  );
};

export default CreateOrUpdateFrom;
