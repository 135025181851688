import { Negotiation } from '@/modules/negotiation';
import { get } from 'lodash';

export const isRequiredBankAccount = (
  negotiation: Negotiation,
  providersRequiresBank: string[],
) => {
  const providerName = get(negotiation, 'product.provider.name');
  return new RegExp(providersRequiresBank.join('|'), 'i').test(providerName);
};
