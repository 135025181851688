import { Color } from 'vkit/lib/shared/types';

export enum MOVE_TYPE {
  BRADESCO_CREATION = 'bradesco:creation',
  BRADESCO_REMOVAL = 'bradesco:removal',
  BRADESCO_UPDATE = 'bradesco:update',
  DEFAULT_CREATION = 'default:creation',
  DEFAULT_REMOVAL = 'default:removal',
  DEFAULT_UPDATE = 'default:update',
  DEFAULT_CREATION_STONE = 'default:creation:creation-stone',
  CNU_CREATION = 'cnu:creation',
  CNU_REMOVAL = 'cnu:removal',
  CNU_UPDATE = 'cnu:update',
  DEFAULT_CREATION_HEALTH_CADMUS = 'default:creation:creation-health-cadmus',
  DEFAULT_CREATION_DENTAL_CADMUS = 'default:creation:creation-dental-cadmus',
  DEFAULT_CREATION_INSPER = 'default:creation:creation-insper',
}

export const MOVE_TYPE_TRANSLATED: { [key: string]: string } = Object.freeze({
  [MOVE_TYPE.BRADESCO_CREATION]: 'Inclusão',
  [MOVE_TYPE.BRADESCO_REMOVAL]: 'Exclusão',
  [MOVE_TYPE.BRADESCO_UPDATE]: 'Alteração',
  [MOVE_TYPE.DEFAULT_CREATION]: 'Inclusão',
  [MOVE_TYPE.DEFAULT_REMOVAL]: 'Exclusão',
  [MOVE_TYPE.DEFAULT_UPDATE]: 'Alteração',
  [MOVE_TYPE.DEFAULT_CREATION_STONE]: 'Inclusão',
  [MOVE_TYPE.CNU_CREATION]: 'Inclusão',
  [MOVE_TYPE.CNU_UPDATE]: 'Alteração',
  [MOVE_TYPE.CNU_REMOVAL]: 'Exclusão',
  [MOVE_TYPE.DEFAULT_CREATION_HEALTH_CADMUS]: 'Inclusão',
  [MOVE_TYPE.DEFAULT_CREATION_DENTAL_CADMUS]: 'Inclusão',
  [MOVE_TYPE.DEFAULT_CREATION_INSPER]: 'Inclusão',
});

export const MOVE_TYPE_COLLECTION = Object.freeze([
  MOVE_TYPE.BRADESCO_CREATION,
  MOVE_TYPE.BRADESCO_REMOVAL,
  MOVE_TYPE.BRADESCO_UPDATE,
  MOVE_TYPE.DEFAULT_CREATION,
  MOVE_TYPE.DEFAULT_REMOVAL,
  MOVE_TYPE.DEFAULT_UPDATE,
  MOVE_TYPE.DEFAULT_CREATION_STONE,
  MOVE_TYPE.CNU_CREATION,
  MOVE_TYPE.CNU_UPDATE,
  MOVE_TYPE.CNU_REMOVAL,
  MOVE_TYPE.DEFAULT_CREATION_HEALTH_CADMUS,
  MOVE_TYPE.DEFAULT_CREATION_DENTAL_CADMUS,
  MOVE_TYPE.DEFAULT_CREATION_INSPER,
]);

export enum MOVE_STATUS {
  NOT_INITIALIZED = 'not-initialized',
  IN_PROCESS = 'in-process',
  PROCESS_ERROR = 'process-error',
  PROCESS_PROVIDER_ERROR = 'process-provider-error',
  PROCESSED = 'processed',
  REJECTED = 'rejected',
}

export const MOVE_STATUS_TRANSLATED: { [key: string]: string } = Object.freeze({
  [MOVE_STATUS.NOT_INITIALIZED]: 'Em aberto',
  [MOVE_STATUS.IN_PROCESS]: 'Em análise',
  [MOVE_STATUS.PROCESS_ERROR]: 'Erro de processamento',
  [MOVE_STATUS.PROCESS_PROVIDER_ERROR]: 'Erro apontado pelo fornecedor',
  [MOVE_STATUS.PROCESSED]: 'Processado',
  [MOVE_STATUS.REJECTED]: 'Rejeitado',
});

export const MOVE_STATUS_COLOR: Record<string, Color> = {
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.NOT_INITIALIZED]]: 'colorDefault',
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.IN_PROCESS]]: 'warning',
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.REJECTED]]: 'danger',
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.PROCESSED]]: 'success',
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.PROCESS_ERROR]]: 'danger',
  [MOVE_STATUS_TRANSLATED[MOVE_STATUS.PROCESS_PROVIDER_ERROR]]: 'danger',
};

export const MOVE_STATUS_TRANSLATED_REVERSE: { [key: string]: string } = Object.keys(
  MOVE_STATUS_TRANSLATED,
).reduce(
  (acc, statusKey) => ({
    ...acc,
    [MOVE_STATUS_TRANSLATED[statusKey]]: statusKey,
  }),
  {},
);

export enum MOVE_LIST_FILTERS {
  SEARCH = 'search',
  TYPE = 'type',
  DATE_MIN = 'createdAtMin',
}

export const MOVE_LIST_FILTERS_COLLECTION = Object.freeze([
  MOVE_LIST_FILTERS.SEARCH,
  MOVE_LIST_FILTERS.TYPE,
  MOVE_LIST_FILTERS.DATE_MIN,
]);

export enum MOVE_LIST_COLUMNS {
  NAME = 'name',
  USER_NAME = 'user_name',
  TYPE = 'type',
  PROVIDER = 'provider',
  STATUS = 'status',
  UPDATED_AT = 'updated_at',
}

export enum MOVE_LIST_ACTIONS {
  DETAILS = 'details',
  DOWNLOAD = 'download',
}

export const MOVE_LIST_PAGE_SIZE_DEFAUlT = 10;
export const MOVE_LIST_INITIAL_PAGE = 1;
export const MOVE_LAYOUT_DEFAULT = 'default';
