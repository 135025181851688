import React, { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'vkit/lib/components';

import { useMoveSheetLine } from '@/modules/move/hooks';
import { ReportListItemContent, ReportListItemHeader } from '..';
import { MoveSheetNormalized } from '../../entities';

interface ReportListItemProps {
  isOnlyError: boolean;
  item: MoveSheetNormalized;
}

export const ReportListItem: React.FC<ReportListItemProps> = ({ isOnlyError, item }) => {
  const [isExpanded, setExpanded] = useState<boolean>(isOnlyError);
  const { loadMoveSheetLine, isLoading, sheetLine } = useMoveSheetLine({
    rowId: item.id,
  });

  const handleExpanded = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const showContent = useMemo(() => {
    if (item.content) {
      return item.content;
    }
    return sheetLine?.content;
  }, [item.content, sheetLine]);

  useEffect(() => {
    if (!isExpanded || item.content || !item.id) {
      return;
    }
    loadMoveSheetLine(item.id);
  }, [isExpanded, item.content, item.id, loadMoveSheetLine]);

  return (
    <Accordion
      full
      box
      expanded={isExpanded}
      handleExpanded={handleExpanded}
      title={<ReportListItemHeader line={item.line} hasError={item.hasError} />}>
      <ReportListItemContent
        isLoading={isLoading}
        sheetLineId={item.id}
        content={showContent}
        showOnlyErrors={isOnlyError}
      />
    </Accordion>
  );
};
