import { ToastStatusGlobal } from '@/components';
import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { AuthConfirmations, authService } from '@/services/accessControl/endpoints/authConfirm';
import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';

type PathsToConfirmation = {
  [key in keyof AuthConfirmations]: string;
};

type MessagesConfirmation = {
  [key in keyof AuthConfirmations]: {
    title: string;
    message: string;
  };
};

interface Props {
  pathname: string;
  setLoading: (value: boolean) => void;
}

export const useCheckConfirmation = ({ pathname, setLoading }: Props) => {
  const { replace } = useHistoryNavigator();
  const { toast } = ToastStatusGlobal();
  const routeToexpiredOrNotFound = '/auth/confirmation-expired-or-not-exist';
  const { userId, code } = useHistoryQuery();

  const pathsToConfirmation = useMemo<PathsToConfirmation>(
    () => ({
      registration: '/auth/confirm-email',
      totp: '/auth/totp',
    }),
    [],
  );

  const pendingMessageConfirmation = useMemo<MessagesConfirmation>(
    () => ({
      registration: {
        title: 'Confirmação de e-mail pendente',
        message:
          'Seu e-mail ainda não foi confirmado. Por favor preencha os campos com o seu e-mail e senha.',
      },
      totp: {
        title: 'Confirmação de MFA pendente',
        message:
          'O MFA ainda não foi confirmado. Por favor preencha os campos com o seu e-mail e senha, e cadastre o MFA no seu aplicativo de autenticação.',
      },
    }),
    [],
  );

  const redirectIfUserNotConfirm = useCallback(async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      const confirmations = await authService.checkConfirm(userId);
      const pendingConfirmationPathKey = Object.keys(pathsToConfirmation).find(
        (pathToConfirmationKey) => !confirmations[pathToConfirmationKey as keyof AuthConfirmations],
      );

      const confirmationKey = pendingConfirmationPathKey as keyof AuthConfirmations;
      if (pendingConfirmationPathKey && pathname !== pathsToConfirmation[confirmationKey]) {
        replace(
          `${
            pathsToConfirmation[pendingConfirmationPathKey as keyof AuthConfirmations]
          }?userId=${userId}`,
        );
        toast(
          pendingMessageConfirmation[confirmationKey].title,
          pendingMessageConfirmation[confirmationKey].message,
          'info',
        );
      }

      if (!pendingConfirmationPathKey) {
        replace('/auth/login');
        toast(
          'E-mail confirmado',
          'Seu e-mail já está confirmado. Por favor faça o login com o seu e-mail e senha.',
          'info',
        );
      }
    } catch (e) {
      console.error(e);

      if ((e as AxiosError).response?.status === 404) {
        if (routeToexpiredOrNotFound !== pathname) {
          replace(`${routeToexpiredOrNotFound}?userId=${userId}&code=${code}`);
        }
        return;
      }

      toast('Oops', 'Falha ao verificar confirmação de usuário', 'error');
    } finally {
      setLoading(false);
    }
  }, [
    code,
    pathname,
    pathsToConfirmation,
    pendingMessageConfirmation,
    replace,
    setLoading,
    toast,
    userId,
  ]);

  return {
    redirectIfUserNotConfirm,
  };
};
