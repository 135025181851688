import { FinancialAdjustmentToTable } from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import { useCallback, useState } from 'react';

export const useFinancialAdjustmentCollection = () => {
  const [financialAdjustments, setFinancialAdjustments] = useState<FinancialAdjustmentToTable[]>(
    [],
  );

  const removeFinancialAdjustment = useCallback((key: string) => {
    setFinancialAdjustments((current) => {
      const index = current.findIndex((financialAdjustment) => financialAdjustment.key === key);
      if (index > -1) current.splice(index, 1);

      return [...current];
    });
  }, []);

  return {
    financialAdjustments,
    setFinancialAdjustments,
    removeFinancialAdjustment,
  };
};
