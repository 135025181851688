import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import { isEmpty } from 'lodash';

import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { removeInvalids } from '@/utils';

export const useQueryStringState = <T = any>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const queryParams = useRef(useHistoryQuery());
  const queryString = useRef('');
  const initialValue = useMemo<T>(
    () => (isEmpty(queryParams.current) ? initialState : queryParams.current),
    [initialState],
  );
  const [params, setParams] = useState<T>(initialValue);
  const { replace } = useHistoryNavigator();

  useEffect(() => {
    const validParams = removeInvalids(params);
    const searchString = qs.stringify(validParams, { arrayFormat: 'brackets', allowDots: true });
    if (queryString.current !== searchString) {
      queryString.current = searchString;
      replace({ search: searchString });
    }
  }, [params, replace]);

  return [params, setParams];
};
