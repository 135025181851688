import { ToastStatusGlobal } from '@/components';
import {
  ResendConfirmationPayload,
  authEmailConfirmationSendsErrors,
} from '@/modules/user/entities/confirmation';
import ConfirmationService from '@/modules/user/services/confirmationService';
import { getMessageError } from '@/utils/getMessageError';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useState } from 'react';

const confirmationService = new ConfirmationService();

const useHistoryConfirmationResend = () => {
  const { toast } = ToastStatusGlobal();
  const [loadingResend, setLoadingResend] = useState<boolean>(false);
  const [disabledResend, setDisabledResend] = useState<boolean>(false);

  const onResend = async ({ userId, type }: ResendConfirmationPayload) => {
    try {
      setLoadingResend(true);
      await confirmationService.resendConfirmation({ userId, type });
      toast('Feito', 'Reenviamos uma mensagem para o e-mail do usuário.', 'success');
      setDisabledResend(true);
    } catch (error) {
      dispatchToast({
        text: getMessageError({
          error,
          messageDefault: 'Falha ao redefinir autenticação em dois fatores.',
          messageErrorByMessageErrorRequest: authEmailConfirmationSendsErrors,
        }),
      });
    } finally {
      setDisabledResend(false);
      setLoadingResend(false);
    }
  };

  return {
    loadingResend,
    disabledResend,
    onResend,
  };
};

export default useHistoryConfirmationResend;
