import React, { useEffect, useRef } from 'react';
import { Colors } from '@/shareds/types';

import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

type DialogConfirmType = {
  open?: boolean;
  title?: string;
  text?: string;
  fnCallback?: (response: boolean) => void;
  modalType?: Colors;
};

const DialogConfirm: React.FC<DialogConfirmType> = ({
  open,
  title,
  text,
  fnCallback,
  modalType,
}) => {
  const useRefDialog = useRef({ open: () => {}, dismiss: () => {} });

  useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [open]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size='small'
      title={
        <Text
          value={title || 'Atenção'}
          title
          size='larger'
          icon='alert-triangle-outline'
          iconColor={modalType}
          color={modalType}
          iconSize={32}
          textAlign='left'
        />
      }
      body={
        <Grid alignContent='left'>
          <Text textAlign='left' value={text || 'Adiocione um texto'} size='medium' />
        </Grid>
      }
      footer={
        <Grid alignContent='right' gap={8}>
          <Button label='Deixa pra lá' solo color='light' onClick={() => fnCallback?.(false)} />
          <Button label='Quero' color='danger' onClick={() => fnCallback?.(true)} />
        </Grid>
      }
    />
  );
};

export default DialogConfirm;
