import { BoxInfo } from '@/components';
import Space from '@/components/space';
import { ObjectType } from '@/shareds/types';
import React, { ReactNode } from 'react';
import { Pagination } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import Divider from '../divider';
import { ContentList, Filter } from './components';
import { Column } from './hooks/useContentList';
import useDataList from './hooks/useDataList';

type DataListType = {
  loading?: boolean;
  onLoadData?: Function;
  noPagination?: boolean;
  filter?: {
    initialValues?: ObjectType;
    searchField?: string | ((...itens: any) => ReactNode);
    fields?: (...itens: any) => ReactNode;
  };
  header?: {
    right?: ReactNode;
    left?: ReactNode;
  };
  data: ObjectType[] | null;
  columns: Column[];
  totalPages?: number;
  linesLoader?: string | number;
  elevation?: number;
};

const DataList: React.FC<DataListType> = ({
  loading,
  onLoadData,
  filter,
  header,
  noPagination,
  data,
  columns,
  totalPages,
  linesLoader,
  elevation,
}) => {
  const { onChangeFilter, setListBlur, setPaginate, useListBlur, usePagination } = useDataList({
    loadData: onLoadData,
    totalPages,
    initialValues: filter?.initialValues,
  });

  return (
    <>
      <Row style={{ padding: 0 }}>
        <Grid alignContent='justify' alignItems='center' wrap>
          {header?.left}

          {(filter?.fields || filter?.searchField) && (
            <Filter
              loading={loading}
              onChange={onChangeFilter}
              onShow={setListBlur}
              initialValues={filter.initialValues}
              fields={filter.fields}
              label={filter.searchField ? 'MAIS FILTROS' : 'FILTROS'}
              searchField={filter.searchField}
            />
          )}

          <Space />
          {header?.right}
        </Grid>
      </Row>

      <Row style={{ padding: 0 }}>
        <BoxInfo elevation={elevation}>
          <Row style={{ padding: 0, margin: -20 }}>
            <ContentList
              linesLoader={linesLoader || usePagination.pageSize}
              columns={columns}
              data={data}
              blur={useListBlur}
            />
          </Row>

          {!noPagination && (
            <Row style={{ padding: 0, margin: '10px -20px 0' }}>
              <Divider />
              <Row style={{ padding: '12px' }}>
                <Pagination
                  page={usePagination.page}
                  totalPages={usePagination.totalPages}
                  total={usePagination.total}
                  pageSize={usePagination.pageSize}
                  onChangePage={(page: number) => setPaginate({ page })}
                  onChangePageSize={(pageSize: number) => setPaginate({ page: 1, pageSize })}
                />
              </Row>
            </Row>
          )}
        </BoxInfo>
      </Row>
    </>
  );
};

export default DataList;
