import React, { ReactNode } from 'react';
import { Row } from 'vkit/lib/context';
import GroupItem, { GroupItemProps } from '../groupItem/groupItem';

export interface GroupDataPagination {
  pageSize: number;
  page: number;
  total: number;
  totalPages: number;
}

export interface GroupContentData {
  list: object[];
  top?: ReactNode;
  foot?: ReactNode;
}

export interface GroupData extends Partial<GroupDataPagination> {
  key: string;
  content: GroupContentData;
  title: string;
}

export interface GroupProps extends Omit<GroupItemProps, 'groupTitle' | 'list' | 'item'> {
  data: (Partial<GroupDataPagination> & GroupData)[];
  showGroupMarkerWhenKey?: string;

  showPaginationReport?: boolean;
  handleChangePageSize?: (pageSize: number, groupTitleKey?: string) => void;
  handleChangePage?: (page: number, groupTitleKey?: string) => void;
}

const Group: React.FC<GroupProps> = ({
  isExpandedGroup,
  data,

  showGroupMarker,
  showGroupMarkerWhenKey,
  groupMarkerColor,
  groupMarkerText,

  columns,
  contentLinesLoader,
  handleExpandReportGroup,
  showEmptyList,

  showPaginationReport,
  handleChangePageSize,
  handleChangePage,
}) => {
  const handleShowMarkerWithRule = (item: any): boolean => {
    if (showGroupMarkerWhenKey) {
      return item[showGroupMarkerWhenKey];
    }
    return showGroupMarker || false;
  };

  return (
    <Row style={{ padding: 0, gap: '12px', marginBottom: '12px' }}>
      {data?.map((item) => (
        <GroupItem
          key={item.key}
          isExpandedGroup={isExpandedGroup}
          groupTitle={item?.title}
          showGroupMarker={handleShowMarkerWithRule(item)}
          groupMarkerColor={groupMarkerColor}
          groupMarkerText={groupMarkerText}
          showEmptyList={showEmptyList}
          columns={columns}
          list={item.content.list}
          contentLinesLoader={contentLinesLoader}
          handleExpandReportGroup={handleExpandReportGroup}
          contentTop={item?.content.top}
          contentFoot={item?.content.foot}
          showPaginationReport={showPaginationReport}
          pageSize={item?.pageSize}
          handleChangePageSize={(pageSize) => handleChangePageSize?.(pageSize, item.key)}
          page={item?.page}
          total={item?.total}
          handleChangePage={(page) => handleChangePage?.(page, item.key)}
          totalPages={item?.totalPages}
        />
      ))}
    </Row>
  );
};

export default Group;
