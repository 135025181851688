export const TRANSLATED_CONTRACT_STATUS = {
  expired: 'Expirado',
  active: 'Ativo',
  cancelled: 'Cancelado',
};

export enum CONTRACT_SVA_HOME_TABS_ID {
  CONTRACT,
  SVA,
}

export const CONTRACT_SVA_HOME_TABS = [
  {
    id: CONTRACT_SVA_HOME_TABS_ID.CONTRACT,
    label: 'Contratos',
    icon: 'file-text-outline',
    active: false,
  },
  {
    id: CONTRACT_SVA_HOME_TABS_ID.SVA,
    label: 'Serviços Adicionais',
    icon: 'pricetags-outline',
    active: false,
  },
];

export enum CONTRACTS_TABS_ID {
  ACTIVE,
  CANCELLED,
  EXPIRED,
  ALL,
}

export const CONTRACTS_TABS = [
  {
    id: CONTRACTS_TABS_ID.ACTIVE,
    label: 'Ativo',
    status: 'active',
    icon: 'checkmark-square-2-outline',
    active: true,
  },
  {
    id: CONTRACTS_TABS_ID.CANCELLED,
    label: 'Cancelado',
    status: 'cancelled',
    icon: 'slash-outline',
    active: false,
  },
  {
    id: CONTRACTS_TABS_ID.EXPIRED,
    label: 'Expirado',
    status: 'expired',
    icon: 'alert-triangle-outline',
    active: false,
  },
  {
    id: CONTRACTS_TABS_ID.ALL,
    label: 'Todos',
    status: '',
    icon: 'file-text-outline',
    active: false,
  },
];
