import { TextFieldDebounce } from '@/components';
import { useCurrentUser } from '@/modules/profile';
import {
  AvailableFilterFields,
  USER_AVAILABLE_FILTERS,
  USER_PERMISSIONS,
  USER_STATUS,
  UserSearchParams,
} from '@/modules/user/entities';
import { useHistoryLocation } from '@/navigation';
import { ReactNode } from 'react';
import SelectStatus from '../../selectStatus/selectStatus';

export interface FilterProps {
  fields?: AvailableFilterFields[];
  filters?: Partial<UserSearchParams>;
  onChange: (filters: Partial<UserSearchParams>) => void;
}

const useAvailableFilters = ({ filters, onChange }: FilterProps) => {
  const { checkUserHasPermission } = useCurrentUser();
  const { pathname } = useHistoryLocation();

  const isBrokerRoute = pathname?.includes('/administrators') || false;

  const isDisabledFields = isBrokerRoute
    ? !checkUserHasPermission(USER_PERMISSIONS.BROKER_USER_READ)
    : !checkUserHasPermission(USER_PERMISSIONS.COMPANY_USER_READ);

  const availableFilters: Record<`${USER_AVAILABLE_FILTERS}`, { field: ReactNode; size: number }> =
    {
      [USER_AVAILABLE_FILTERS.NAME_EMAIL]: {
        field: (
          <TextFieldDebounce
            name='search'
            disabled={isDisabledFields}
            clearable
            small
            iconDir='right'
            icon='search-outline'
            label='Nome ou e-mail'
            value={filters?.q}
            onChange={(value: string) => onChange({ q: value })}
          />
        ),
        size: 66.66,
      },
      [USER_AVAILABLE_FILTERS.STATUS]: {
        field: (
          <SelectStatus
            disabled={isDisabledFields}
            onChange={(value?: USER_STATUS | 'all') => onChange({ status: value })}
            value={filters?.status ?? 'all'}
          />
        ),
        size: 33.33,
      },
    };

  return {
    availableFilters,
  };
};

export default useAvailableFilters;
