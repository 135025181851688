import { DialogConfirm, Tooltip } from '@/components';
import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';
import React, { useCallback } from 'react';
import { Button } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { USER_STATUS, USER_TYPE } from '../../entities';
import useRecoveryActionsRules from './hooks/useRecoveryActionsRules';

interface RecoveryActionsProps {
  status?: `${USER_STATUS}`;
  scope: `${USER_TYPE}`;
  userId: string;
  onDelete: () => Promise<void>;
  onReactive: () => Promise<void>;
  onUnblock: () => Promise<void>;
}

const RecoveryActions: React.FC<RecoveryActionsProps> = ({
  status,
  scope,
  userId,
  onDelete,
  onReactive,
  onUnblock,
}) => {
  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm();

  const {
    showUnblock,
    disableUnblockAction,
    tooltipMessageUnblock,
    showInactive,
    disableInativeAction,
    tooltipMessageInactive,
    showRestore,
    disableRestoreAction,
    tooltipMessageRestore,
  } = useRecoveryActionsRules({ status, scope, userId });

  const onConfirmReactivateUser = useCallback(async (): Promise<void> => {
    if (disableRestoreAction) {
      return;
    }
    const canReactivate = await confirm('Atenção', 'Quer mesmo reativar este usuário?');

    if (canReactivate) {
      await onReactive();
    }
  }, [confirm, disableRestoreAction, onReactive]);

  const onConfirmDeleteUser = useCallback(async (): Promise<void> => {
    if (disableInativeAction) {
      return;
    }
    const response = await confirm('Atenção', 'Quer mesmo inativar este usuário?');

    if (response) {
      await onDelete();
    }
  }, [disableInativeAction, confirm, onDelete]);

  const onConfirmUnblock = useCallback(async (): Promise<void> => {
    if (disableUnblockAction) {
      return;
    }
    const canReset = await confirm('Atenção', 'Quer mesmo desbloquear este usuário?');

    if (canReset) {
      await onUnblock();
    }
  }, [onUnblock, confirm, disableUnblockAction]);

  return (
    <Row style={{ flexDirection: 'row', padding: 0 }}>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />

      <Grid gap={16}>
        {showUnblock && (
          <Tooltip title={tooltipMessageUnblock}>
            <Button
              solo
              outlined
              label='Desbloquear'
              disabled={disableUnblockAction}
              onClick={onConfirmUnblock}
            />
          </Tooltip>
        )}

        {showInactive && (
          <Tooltip title={tooltipMessageInactive}>
            <Button
              outlined
              label='Inativar usuário'
              loading={false}
              color='danger'
              onClick={onConfirmDeleteUser}
              disabled={disableInativeAction}
            />
          </Tooltip>
        )}

        {showRestore && (
          <Tooltip title={tooltipMessageRestore}>
            <Button
              outlined
              label='Reativar usuário'
              color='colorDefault'
              disabled={disableRestoreAction}
              onClick={onConfirmReactivateUser}
            />
          </Tooltip>
        )}
      </Grid>
    </Row>
  );
};

export default RecoveryActions;
