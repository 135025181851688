import { get } from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Pagination } from 'vkit/lib/components';

import { BoxInfo, Divider, EmptyMessage } from '@/components';
import { Action, Column } from '@/components/dataTable';
import { ContentListLines } from '@/components/dataTable/components';
import { Contract, ContractService, ParamsToFilter } from '@/modules/contract';
import ContractStatus from '@/modules/contract/components/contractStatus/contractStatus';
import { LogoProvider } from '@/modules/provider';
import { useHistoryNavigator } from '@/navigation';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import { PAGE_SIZE } from '@/shareds/constants/pagination';
import { removeEmptyParams } from '@/utils';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { Row } from 'vkit/lib/context';

export type ColumnNames =
  | 'logo'
  | 'code'
  | 'modelName'
  | 'provider'
  | 'validFrom'
  | 'validUntil'
  | 'benefit'
  | 'status';

export type ActionNames = 'goToDetails';

interface ContractTableProps {
  filters?: ParamsToFilter;
  deactiveFilter?: boolean;
  linesLoader?: number;
  columns?: ColumnNames[];
  actions?: ActionNames[];
  setFilters?: Dispatch<SetStateAction<ParamsToFilter>>;
  elevation?: number;
  isLoading?: boolean;
}

const contractService = new ContractService();

const ContractTable: React.FC<ContractTableProps> = ({
  filters,
  deactiveFilter,
  linesLoader,
  columns = [],
  actions,
  setFilters,
  elevation,
  isLoading,
}) => {
  const [loading, setLoading] = useState(isLoading || false);
  const [useData, setData] = useState<Negotiation[]>([]);
  const [linesLoaderSize] = useState(linesLoader ? linesLoader : 10);
  const [totalLines, setTotalLines] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { company } = useCompany();
  const navigate = useHistoryNavigator();

  const showPagination = useMemo(() => totalLines / PAGE_SIZE > 1, [totalLines]);

  const loadContracts = useCallback(
    async (updateFilters = {}): Promise<void> => {
      tryOrCatchMessageError(
        async () => {
          if (!company.id) {
            return;
          }

          const filter = removeEmptyParams(updateFilters);
          const { data, total, totalPages } =
            (await contractService.getContracts(company.id, filter)) || {};
          setTotalPages(totalPages);
          setData(data || []);
          setTotalLines(total);
        },
        {
          messageErrorDefault: 'Falha ao carregar contratos.',
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
        },
      );
    },
    [company.id],
  );

  const getLogoProvider = (contract: Contract) => {
    const providerCnpj = get(contract, 'provider.cnpj', 'not-found');
    return <LogoProvider providerCnpj={String(providerCnpj)} size='small' />;
  };

  const getContractStatus = (contract: Contract) => <ContractStatus contract={contract} />;

  const columnsToChoose: { [key in ColumnNames]: Column } = {
    logo: {
      custom: getLogoProvider,
    },
    code: {
      title: 'Código',
      path: 'code',
    },
    modelName: {
      title: 'Tipo',
      path: 'model.name',
    },
    provider: {
      title: 'Fornecedor',
      path: 'provider.name',
    },
    validFrom: {
      title: 'Início de vigência',
      path: 'validFrom',
      type: 'dateWithoutTZ',
    },
    validUntil: {
      title: 'Fim de vigência',
      path: 'validUntil',
      type: 'dateWithoutTZ',
    },
    benefit: {
      title: 'Benefício',
      custom: (contract: Contract) =>
        contract.negotiations
          ?.reduce((acc: string[], negotiation) => {
            const benefitName = get(negotiation, 'product.benefit.name');

            if (!benefitName || acc.includes(benefitName)) {
              return acc;
            }

            acc.push(benefitName);
            return acc.sort();
          }, [])
          .join(', ') || '-',
    },
    status: {
      title: 'Status',
      custom: getContractStatus,
    },
  };

  const getActions = useCallback(
    (contract: Contract) => {
      const actionsToChoose: { [key in ActionNames]: Action } = {
        goToDetails: {
          label: 'Ver detalhes',
          onClick: () => navigate.push(`${contract.id}`),
          icon: ICONS.DETAILS,
        },
      };

      if (actions?.length === 1) {
        const [actionKey] = actions;
        const action = actionsToChoose[actionKey];

        return <Button solo outlined label={action.label} onClick={action.onClick as () => void} />;
      }

      return actions?.map((actionKey) => actionsToChoose[actionKey]);
    },
    [actions, navigate],
  );

  useEffect(() => {
    if (!company.id) {
      return;
    }

    if (!deactiveFilter) {
      (async () =>
        loadContracts({
          page: 1,
          pageSize: PAGE_SIZE,
          ...filters,
        }))();
    } else {
      setData([]);
    }
  }, [company.id, loadContracts, deactiveFilter, filters]);

  return (
    <BoxInfo elevation={elevation} margin={0} padding={1}>
      {useData.length || loading ? (
        <ContentListLines
          actionsByLine={actions && getActions}
          columns={columns.map((column) => columnsToChoose[column])}
          data={useData}
          linesLoader={linesLoaderSize}
          loading={loading}
        />
      ) : null}

      {!useData.length && !loading ? (
        <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
      ) : null}

      {showPagination && !loading ? (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px' }}>
            <Pagination
              page={filters?.page || 1}
              totalPages={totalPages}
              total={totalLines}
              pageSize={filters?.pageSize || PAGE_SIZE}
              onChangePage={(page: number) => setFilters?.((current) => ({ ...current, page }))}
              onChangePageSize={(pageSize: number) =>
                setFilters?.((current) => ({ ...current, page: 1, pageSize }))
              }
            />
          </Row>
        </>
      ) : null}
    </BoxInfo>
  );
};

export default ContractTable;
