import React from 'react';

import { Select } from 'vkit/lib/components';

import { FormDataSteps } from '../../enum/FormDataSteps';
import { useProviders } from '../../hooks';
import { TFormData } from '../../types/FormData.type';

interface SelectProviderProps {
  formData: TFormData;
  setData: (key: string, value: any) => void;
  isLoading: boolean;
}

const SelectProvider: React.FC<SelectProviderProps> = ({ formData, setData, isLoading }) => {
  const { getProvider, selectableProviderList } = useProviders();

  const onChange = (value: string): void => {
    if (value) {
      setData(FormDataSteps.provider, getProvider(value));
    }
  };

  window.addEventListener('loadCompany', () => {
    if (formData?.provider?.id) {
      setData(FormDataSteps.provider, null);
    }
  });

  return (
    <Select
      disabled={isLoading || selectableProviderList.length === 0}
      data={selectableProviderList}
      label='Fornecedor'
      onChange={onChange}
      placeholder='Selecione um fornecedor'
      required
      searchable
      value={formData?.provider?.id || ''}
      customMessageNotFound='Nenhum fornecedor com contrato ativo foi encontrado.'
    />
  );
};

export default SelectProvider;
