import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Select } from 'vkit/lib/components';
import { MOVE_TYPE_COLLECTION, MoveType, MoveTypeCollection } from '../../entities';

export interface SelectTypeProps {
  value?: MoveTypeCollection;
  setValue: (value: MoveTypeCollection) => void;
}

export const SelectType: React.FC<SelectTypeProps> = ({ value, setValue }) => {
  const key = useRef(0);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const selectValue = useCallback((): string => {
    key.current += 1;
    if (value) {
      if (value.every((type: MoveType) => type.includes('creation'))) {
        return 'creation';
      }
      if (value.every((type: MoveType) => type.includes('removal'))) {
        return 'removal';
      }
      if (value.every((type: MoveType) => type.includes('update'))) {
        return 'update';
      }
    }
    return '';
  }, [value]);

  const handleChangeType = (value: string) => {
    if (!value) {
      setSelectedValue('');
      setValue(MOVE_TYPE_COLLECTION as MoveTypeCollection);
    } else {
      setValue(MOVE_TYPE_COLLECTION.filter((type) => type.includes(value)));
    }
  };

  useEffect(() => {
    setSelectedValue(selectValue());
  }, [selectValue]);

  return (
    <Select
      key={key.current}
      clearable
      data={[
        {
          text: 'Inclusão',
          value: 'creation',
        },
        {
          text: 'Exclusão',
          value: 'removal',
        },
        {
          text: 'Alteração',
          value: 'update',
        },
      ]}
      onChange={handleChangeType}
      label='Tipo'
      small
      value={selectedValue}
    />
  );
};
