import { BoxInfo, TextData } from '@/components';
import { Attendance } from '@/modules/attendance/entities';
import React from 'react';
import ICONS from '@/shareds/constants/icons';

interface InformationProps {
  attendance: Attendance;
}

const Information: React.FC<InformationProps> = ({ attendance }) => (
  <BoxInfo title='Dados do atendimento' icon={ICONS.ATTENDANCE}>
    <TextData
      loading={!attendance.id}
      data={[
        {
          label: 'Assunto',
          text: attendance.subject,
          sizeDefault: 75,
          sizeMedium: 100,
        },
        {
          label: 'Criado em',
          text: attendance.createdAt,
          sizeDefault: 25,
          sizeMedium: 100,
          type: 'date',
        },
        {
          label: 'Motivo',
          text: attendance.reason,
          sizeDefault: 50,
          sizeMedium: 100,
        },
        {
          label: 'Submotivo',
          text: attendance.subReason,
          sizeDefault: 50,
          sizeMedium: 100,
        },
        {
          label: 'Status',
          text: attendance.status,
          sizeDefault: 50,
          sizeMedium: 100,
        },
        {
          label: 'Previsão',
          text:
            attendance.expectedSolutionTime > 1
              ? `${attendance.expectedSolutionTime} dias úteis`
              : `${attendance.expectedSolutionTime} dia útil`,
          sizeDefault: 25,
          sizeMedium: 100,
        },
        {
          label: 'Atualizado em',
          text: attendance.updatedAt,
          sizeDefault: 25,
          sizeMedium: 100,
          type: 'date',
        },
        {
          label: 'Descrição',
          text: attendance.description,
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Nome da(o) usuária(o)',
          text: attendance.userName,
          sizeDefault: 50,
          sizeMedium: 100,
        },
        {
          label: 'E-mail da(o) usuária(o)',
          text: attendance.userEmail,
          sizeDefault: 50,
          sizeMedium: 100,
        },
      ]}
    />
  </BoxInfo>
);

export default Information;
