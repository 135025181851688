import { BoxInfo, TextData } from '@/components';
import { TextItemDataType } from '@/components/textData/types/textData.type';
import React, { useMemo } from 'react';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';
import { Product } from '@/services/negotiations/endpoints/products';
import { getCurrencyFormat } from '@/utils/format';

type InfoKey =
  | 'name'
  | 'ansCode'
  | 'accommodation'
  | 'coverage'
  | 'reimbursement'
  | 'scope'
  | 'costCenter';

interface DetailsProps {
  loading?: boolean;
  product: Product;
  negotiation?: Negotiation;
  infos: {
    key: InfoKey;
    size: number;
  }[];
}

const Details: React.FC<DetailsProps> = ({ loading, product, negotiation, infos }) => {
  const infosToChoose = useMemo<{ [key in InfoKey]: TextItemDataType }>(
    () => ({
      name: {
        label: 'Plano',
        text: product.providerInternalCode || product.name,
      },
      ansCode: {
        label: 'Código ANS',
        text: product.ansCode,
      },
      costCenter: {
        label: 'Centro de custo',
        text: negotiation?.costCenter.name,
      },
      accommodation: {
        label: 'Acomodação',
        text: product.accommodation?.name,
      },
      scope: {
        label: 'Abrangência',
        text: product.scope?.name,
      },
      coverage: {
        label: 'Cobertura',
        text: product.coverage?.name,
      },
      reimbursement: {
        label: 'Reembolso',
        text: getCurrencyFormat(negotiation?.reimbursement || 0),
      },
    }),
    [negotiation, product],
  );

  const data = useMemo(() => {
    return infos.map(({ key, size }) => ({
      ...infosToChoose[key],
      sizeDefault: size,
    }));
  }, [infosToChoose, infos]);

  return (
    <BoxInfo>
      <TextData loading={loading} data={data} />
    </BoxInfo>
  );
};

export default Details;
