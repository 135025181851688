import { useCurrentUser } from '@/modules/profile';
import { USER_PERMISSIONS, USER_TYPE } from '@/modules/user';
import { ROUTE_USER } from '@/modules/user/routes';
import TableAndFilterUser from '@/modules/user/views/tableAndFilterUser/tableAndFilterUser';
import React from 'react';

const HomeUserCompany: React.FC = () => {
  const { checkUserHasPermission } = useCurrentUser();

  return (
    <TableAndFilterUser
      routeIdToDetails={ROUTE_USER.BROKER_USER_CREATE}
      disabledCreateAction={!checkUserHasPermission(USER_PERMISSIONS.BROKER_USER_CREATE)}
      userType={USER_TYPE.BROKER}
    />
  );
};

export default HomeUserCompany;
