import { BoxInfo } from '@/components';
import ICONS from '@/shareds/constants/icons';
import React, { ReactNode } from 'react';
import { RecursiveCompanyTree } from '../../components';
import useCompanyTree from './hooks/useCompanyTree';
import { Text } from 'vkit/lib/components';

interface CompanyTreeProps {
  companyIds: string[];
  emptyListMessage?: ReactNode;
  notChildrenMessage?: ReactNode;
  loading?: boolean;
}

export const CompanyTree: React.FC<CompanyTreeProps> = ({
  companyIds,
  emptyListMessage,
  notChildrenMessage,
  loading: externalLoading,
}) => {
  const { isLoading, companies } = useCompanyTree(companyIds);

  return (
    <BoxInfo icon={ICONS.COMPANY} title='Empresas' loading={externalLoading || isLoading}>
      {Boolean(companies.length) && (
        <Text
          value='Este usuário poderá acessar as seguintes empresas do grupo:'
          size='medium'
          margin='0 0 16px'
        />
      )}

      <RecursiveCompanyTree
        list={companies}
        emptyListMessage={emptyListMessage}
        notChildrenMessage={notChildrenMessage}
      />
    </BoxInfo>
  );
};

export default CompanyTree;
