import Tooltip from '@/components/tooltip';
import ICONS from '@/shareds/constants/icons';
import { Button } from 'vkit/lib/components';
import React, { useEffect, useMemo } from 'react';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import { Grid } from 'vkit/lib/context';
import useFile from '@/modules/files/hooks/useFile/useFile';

interface ButtonsDownloadProps {
  loading: boolean;
  fileId?: string;
}

const ButtonsDownload: React.FC<ButtonsDownloadProps> = ({ loading, fileId }) => {
  const { downloadFile } = useFileDownload();

  const { file: modifiedFile, loadFile, loading: loadingFile } = useFile();

  useEffect(() => {
    if (!fileId) {
      return;
    }
    loadFile(fileId);
  }, [loadFile, fileId]);

  const resultFileId = useMemo(() => {
    const foundFile = modifiedFile?.relationChildren.find((relatedFile) => {
      return relatedFile.name.toLowerCase().includes('resultado_');
    });

    return foundFile?.id;
  }, [modifiedFile?.relationChildren]);

  return (
    <Grid gap={8}>
      <Button
        outlined
        solo
        loading={loading || loadingFile}
        disabled={loading || loadingFile || !fileId}
        icon={ICONS.DOWNLOAD}
        label='Download da planilha original'
        onClick={() => (fileId ? downloadFile(fileId) : null)}
        iconColor='colorDefault'
      />

      <Tooltip
        title={
          !resultFileId
            ? 'Arquivo indisponível no momento. Você poderá acessar o resultado assim que ele estiver concluído.'
            : ''
        }>
        <Button
          loading={loading || loadingFile}
          disabled={loading || loadingFile || !resultFileId}
          icon={ICONS.DOWNLOAD}
          iconColor={resultFileId ? 'clear' : 'light'}
          label='Download dos resultados de bate cadastral'
          onClick={() => (resultFileId ? downloadFile(resultFileId) : null)}
        />
      </Tooltip>
    </Grid>
  );
};

export default ButtonsDownload;
