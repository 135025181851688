import { useCallback, useEffect, useState } from 'react';

import { CompanyTree } from '@/modules/company/entities';
import CompanyService from '@/modules/company/services/companiesService';

const companyService = new CompanyService();

const useCompanyTree = (companyIds: string[] = []) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<CompanyTree[]>([]);

  const loadCompaniesTree = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { data = [] } = (await companyService.getCompaniesTree(companyIds)) || {};
      setCompanies(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [companyIds]);

  useEffect(() => {
    if (!companyIds.length) {
      setCompanies([]);
      return;
    }

    loadCompaniesTree();
  }, [companyIds, loadCompaniesTree]);

  return {
    isLoading,
    companies,
  };
};

export default useCompanyTree;
