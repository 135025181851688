import { ContentListLines } from '@/components/dataTable/components';
import React, { useMemo } from 'react';

import { Divider, EmptyMessage } from '@/components';
import { useMoveSheet } from '@/modules/move/hooks';
import { LIST_INITIAL_PAGE } from '@/shared/entities/constants/list';
import { Pagination } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { ReportListItem } from '..';
import { MOVE_SHEET_PAGE_SIZE_DEFAUlT, MoveSheetParams } from '../../entities';

interface ReportListProps {
  filters?: MoveSheetParams;
  handleFilters: (params: MoveSheetParams) => void;
  linesLoader?: number;
}

export const ReportList: React.FC<ReportListProps> = ({
  filters,
  handleFilters,
  linesLoader = 5,
}) => {
  const { isLoading, isEmptySheet, pagination, sheet, showPagination, showList } =
    useMoveSheet(filters);

  const handleChangePageSize = (value: number) => {
    handleFilters({
      ...filters,
      pageSize: value,
    });
  };

  const handleChangePage = (value: number) => {
    handleFilters({
      ...filters,
      page: value,
    });
  };

  const isOnlyErrors = useMemo(() => filters?.onlyErrors === 1, [filters?.onlyErrors]);

  const emptyMessage = useMemo(() => {
    return {
      title: isOnlyErrors ? 'Nenhum erro!' : 'Nenhuma linha processada!',
      message: isOnlyErrors
        ? 'Nenhum erro de validação foi encontrado'
        : 'Não encontramos nenhuma linha para processamento',
    };
  }, [isOnlyErrors]);

  return (
    <>
      {isLoading && (
        <ContentListLines columns={[{}]} linesLoader={linesLoader} loading={isLoading} data={[]} />
      )}

      {showList && (
        <Row style={{ padding: 0, gap: '12px', marginBottom: '12px' }}>
          {sheet.map((line) => (
            <ReportListItem key={line.id} isOnlyError={isOnlyErrors} item={line} />
          ))}
        </Row>
      )}

      {isEmptySheet && (
        <EmptyMessage title={emptyMessage.title} description={emptyMessage.message} />
      )}

      {showPagination && (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px 0 0' }}>
            <Pagination
              page={pagination.page || LIST_INITIAL_PAGE}
              totalPages={pagination.totalPages}
              total={pagination?.total || sheet.length}
              pageSize={filters?.pageSize || MOVE_SHEET_PAGE_SIZE_DEFAUlT}
              onChangePage={handleChangePage}
              onChangePageSize={handleChangePageSize}
            />
          </Row>
        </>
      )}
    </>
  );
};
