import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import React from 'react';
import AdditionalServiceTable from '../views/additionalServicesTable';
import { ADDITIONAL_SERVICES_TABLE_COLUMN } from '../entities/constants';
import { AdditionalServicesParams } from '../entities/interfaces';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';

const AdditionalServices: React.FC = () => {
  const [filters] = useQueryStringState<AdditionalServicesParams>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
  });
  return (
    <AdditionalServiceTable
      filters={filters}
      linesLoader={10}
      columns={[
        ADDITIONAL_SERVICES_TABLE_COLUMN.LOGO,
        ADDITIONAL_SERVICES_TABLE_COLUMN.PROVIDER,
        ADDITIONAL_SERVICES_TABLE_COLUMN.BENEFIT,
        ADDITIONAL_SERVICES_TABLE_COLUMN.STATUS,
        ADDITIONAL_SERVICES_TABLE_COLUMN.ACTIVATION,
      ]}
    />
  );
};

export default AdditionalServices;
