import React from 'react';

import { Button, Text } from 'vkit/lib/components';

import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { Row } from 'vkit/lib/context';

import ForgorPassword from '@/components/forms/auth/forgotPassword';
import { useRecovery } from '@/modules/authentication/hooks';

const Blocked: React.FC = () => {
  const navigate = useHistoryNavigator();
  const queryString = useHistoryQuery();
  const { recoveryOTP, isLoading, error } = useRecovery();

  const isBlockedEmail = !!queryString?.email;
  const isBlockTotp = !!queryString?.otp;

  const handleClick = async () => {
    const sended = await recoveryOTP(decodeURIComponent(queryString?.otp));

    if (sended) {
      navigate.push('/auth/success?recovery=totp');
    }
  };

  const title = 'Acesso bloqueado por segurança';

  const message = isBlockedEmail
    ? `Por motivos de segurança, seu acesso foi bloqueado devido a
    um número elevado de tentativas de login sem sucesso.
    Para restaurar o acesso à sua conta, informe seu e-mail no campo abaixo e solicite a redefinição de senha.`
    : `Por motivos de segurança, seu acesso foi bloqueado devido a
    um número elevado de tentativas inserção de código.
    Para restaurar o acesso à sua conta, clique no botão abaixo para solicitar a nossa equipe o desbloqueio.`;

  return (
    <>
      <Row>
        <Text
          value={title}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          size='medium'
          title
        />
      </Row>

      <Row>
        <Text value={message} rounded size='medium' />
      </Row>

      {isBlockedEmail && (
        <Row>
          <ForgorPassword email={decodeURIComponent(queryString?.email)} />
        </Row>
      )}

      {isBlockTotp && (
        <Row>
          {error && <Text size='medium' color='danger' value={error} margin='0 0 8px' />}
          <Button loading={isLoading} disabled={isLoading} onClick={handleClick}>
            <Row>Solicitar desbloqueio de acesso</Row>
          </Button>
        </Row>
      )}
    </>
  );
};

export default Blocked;
