import { STATE } from '@/shared/modules/address';

const isSameState = (value: string, compare: string) => {
  return value.localeCompare(compare, 'pt-BR', { sensitivity: 'base' }) === 0;
};

export const getStateAbbr = (value: string) => {
  if (!value) {
    return;
  }

  const [stateAbbr] =
    Object.entries(STATE).find(
      ([stateAbbr, stateTextfull]) =>
        isSameState(stateAbbr, value) || isSameState(stateTextfull, value),
    ) || [];

  return stateAbbr;
};
