import { ALLOWED_EXTENSIONS_MOVE } from '@/pages/beneficiaries/shareds/constants/allowedExtensions';

const MAX_FILE_SIZE_MB = 3;

export const validateFiles = (files: File[]) => {
  if (!files?.length) {
    return null;
  }

  const filesToValidate = Array.from(files);

  const invalidExtensionExists = filesToValidate.filter((file) => {
    const ext = file.name?.split('.').pop() || '';
    return !ALLOWED_EXTENSIONS_MOVE.includes(ext);
  });
  if (invalidExtensionExists.length) {
    return invalidExtensionExists.length > 1
      ? 'Alguns arquivos não estão no formato correto'
      : 'Um arquivo não está no formato correto';
  }

  const totalSizeBytes = filesToValidate.reduce((size, file) => size + file.size, 0);
  const totalSizeMb = Number((totalSizeBytes / 1_048_576).toFixed(1));
  if (totalSizeMb > MAX_FILE_SIZE_MB) {
    return `Soma do tamanho dos arquivos é ${totalSizeMb}Mb,
      mas não pode ser maior que ${MAX_FILE_SIZE_MB}Mb.`;
  }

  return null;
};
