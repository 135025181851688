import { Markup, Over } from '@/modules/contract/entities/over';
import { normalizeOver } from '@/modules/contract/services';
import React, { useMemo } from 'react';
import { Row } from 'vkit/lib/context';
import { OverItem } from '..';

export interface OverContentProps {
  markups: Markup[];
}

const OverContent: React.FC<OverContentProps> = ({ markups }) => {
  const listOvers = useMemo(() => {
    return normalizeOver(markups);
  }, [markups]);

  return (
    <Row style={{ gap: 16 }}>
      {listOvers.map((over: Over, index) => (
        <OverItem key={over.id} isFirstItem={index === 0} over={over} />
      ))}
    </Row>
  );
};

export default OverContent;
