import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { Permission } from '../entities/permission';

class PermissionService extends HttpClientAdapter {
  constructor() {
    super('AUTH');
  }

  getPermissions<T = Permission[]>() {
    return this.get<T>('/permissions');
  }
}

export default PermissionService;
