import { useHistoryLocation, useRouteMatch } from '@/navigation';
import { useMemo } from 'react';
import { RouterLoaderPageDetails } from '@/core/entities';

const useMainPageDetails = () => {
  const { pathname } = useHistoryLocation();
  const routes = useRouteMatch();

  const data = useMemo((): RouterLoaderPageDetails | undefined => {
    const findedRoute = routes.find((route) => route.pathname === pathname);
    return findedRoute?.data as RouterLoaderPageDetails;
  }, [pathname, routes]);

  return {
    data,
  };
};

export default useMainPageDetails;
