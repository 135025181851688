import { BoxInfo, GridGroup } from '@/components';
import React, { Dispatch, SetStateAction } from 'react';
import {
  CADASTRAL_DATA_MATCHING_LIST_FILTERS,
  CadastralDataMatchingParamsToFilter,
  useCadastralDataMatchingFilters,
} from '@/modules/cadastralDataMatching';

interface ContractFiltersProps {
  fields: CADASTRAL_DATA_MATCHING_LIST_FILTERS[];
  setFilters: Dispatch<SetStateAction<CadastralDataMatchingParamsToFilter>>;
}

const Filters: React.FC<ContractFiltersProps> = ({ fields, setFilters }) => {
  const { fieldsTochoose } = useCadastralDataMatchingFilters({ setFilters });

  return (
    <BoxInfo>
      <GridGroup body={fields.map((field) => fieldsTochoose[field])} />
    </BoxInfo>
  );
};

export default Filters;
