import { get } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classesBuilder from '@/utils/classesBuilder';
import { FloatLayer, Icon, Loader, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import style from './style/buttonActions.module.scss';

export interface ActionOption {
  icon?: string;
  label: string;
  onClick?: () => void;
}

interface ButtonActionsProps {
  actionOptions?: ActionOption[];
  children?: ReactNode;
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  label?: string;
  loaderType?:
    | 'dot'
    | 'dotSpinner'
    | 'dotSpinnerProgress'
    | 'spinnerProgress'
    | 'spinnerProgressFade';
  loading?: boolean;
  maxWidth?: string | number;
  onClick?: () => void;
  requestFindLabelFn?: (paramId: string) => Promise<unknown>;
  requestFindLabelParamId?: string;
  requestFindLabelResponseOnLoad?: (item: string) => void;
  requestFindLabelResponsePathText?: string;
  bold?: boolean;
  invertColor?: boolean;
  outlined?: boolean;
  color?: 'danger' | 'info' | 'light' | 'success' | 'warning' | 'default';
  size?: 'minor' | 'small' | 'medium' | 'larger' | 'xLarger';
}

const ButtonActions: React.FC<ButtonActionsProps> = ({
  actionOptions,
  children,
  disabled,
  label,
  loaderType,
  loading,
  maxWidth,
  onClick,
  requestFindLabelFn,
  requestFindLabelParamId,
  requestFindLabelResponseOnLoad,
  requestFindLabelResponsePathText,
  size = 'medium',
  bold,
  color,
  invertColor,
  outlined,
}) => {
  const [useLabel, setLabel] = useState('');
  const [useLoading, setLoading] = useState(false);
  const [useShowLayer, setShowLayer] = useState(false);
  const [positionLayer, setPositionLayer] = useState({ x: 0, y: 0 });
  const buttomRef = useRef<HTMLDivElement>(null);
  const showHideLayer = (): void => setShowLayer(!useShowLayer);
  const loadLabel = useCallback(async (): Promise<void> => {
    if (!requestFindLabelFn || !requestFindLabelParamId || !requestFindLabelResponsePathText) {
      return;
    }

    try {
      setLoading(true);
      const response = await requestFindLabelFn?.(requestFindLabelParamId);
      const label = get(response, requestFindLabelResponsePathText);
      setLabel(label);
      requestFindLabelResponseOnLoad?.(label);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [
    requestFindLabelResponseOnLoad,
    requestFindLabelParamId,
    requestFindLabelResponsePathText,
    requestFindLabelFn,
  ]);

  const openLayer = (): void => {
    if (!buttomRef.current) {
      return;
    }
    //const wrapper = document.querySelector('[class*="transitions_slide"]')
    const { left, top, height } = buttomRef.current.getBoundingClientRect();
    // const paddingLeft = window.innerWidth - Number(wrapper?.clientWidth)
    setPositionLayer({ x: left, y: top + height });
    setShowLayer(true);
  };

  useEffect(() => {
    (async () => loadLabel())();
  }, [loadLabel]);

  return (
    <div
      ref={buttomRef}
      className={classesBuilder(style, {
        [color!]: color,
        buttonActionsWrapper: true,
        invertColor,
        noPrincipalClick: !onClick,
        outlined,
      })}>
      <Grid alignItems='center'>
        <button
          disabled={disabled}
          onClick={onClick || openLayer}
          className={classesBuilder(style, {
            buttonActions: true,
            disabled,
            iconOnly: !label,
            loading,
            [size || '']: size,
            pointerEventNone: useShowLayer && !onClick,
          })}
          type='button'
          style={{
            maxWidth,
          }}>
          <>
            {(loading || useLoading) && (
              <div className={style.loader}>
                <Loader color='colorDefault' type={loaderType} />
              </div>
            )}

            <div
              className={classesBuilder(style, {
                text: true,
                bold,
              })}>
              {useLabel || children || label}
            </div>
          </>
        </button>

        {!!actionOptions?.length && (
          <button
            disabled={disabled}
            onClick={openLayer}
            className={classesBuilder(style, {
              buttonActionOptions: true,
              disabled,
              [size || '']: size,
              flip: useShowLayer,
              pointerEventNone: useShowLayer,
            })}
            type='button'>
            <Icon name='arrow-down' />
          </button>
        )}
      </Grid>

      <div
        className={style.boxFloatLayer}
        style={{
          left: positionLayer.x,
          top: positionLayer.y,
          width: buttomRef.current?.clientWidth,
        }}>
        <FloatLayer show={useShowLayer} onClose={showHideLayer} outlined rounded elevation={6} full>
          {actionOptions?.map((actionOption, key) => (
            <button
              key={key}
              className={style.buttonActionOption}
              onClick={() => {
                actionOption.onClick?.();
                showHideLayer();
              }}>
              <Grid alignItems='center' alignContent='right'>
                {actionOption.icon && (
                  <div className={style.iconSquare}>
                    <Icon color='colorDefault' name={actionOption.icon} />
                  </div>
                )}

                <Text size='medium' value={actionOption.label} />
              </Grid>
            </button>
          ))}
        </FloatLayer>
      </div>
    </div>
  );
};

export default ButtonActions;
