import React, { Fragment, useCallback, useMemo } from 'react';

import { Route } from '@/core/entities/types/routes';
import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import { Routes } from '@/core/routes';
import { findRoutePathByName } from '@/core/utils/routes.utils';
import { COMPANY_ROUTE_NAME } from '@/modules/company/routes';
import { useCurrentUser } from '@/modules/profile';
import classesBuilder from '@/utils/classesBuilder';
import { groupBy } from 'lodash';
import { NavLink, generatePath, useMatches } from 'react-router-dom';
import { Icon } from 'vkit/lib/components';
import style from './style/menu.module.scss';
import { Tooltip } from '@/components';
import { COMPANY_STATUS } from '@/modules/company/entities/company.enum';
import { ITEMS_DISABLE_COMPANY_INACTIVE, ITEMS_TOOLTIP } from './entities/menu';

export type MenuDataProps = {
  id?: string;
  customImageUrl?: string;
  icon?: string;
  name?: string;
  to?: string;
  end?: boolean;
  groupBy?: string;
  disabled?: boolean;
};

export type MenuGroupProps = {
  title?: string;
  data?: MenuDataProps[];
};

type MenuProps = {
  isOpen: boolean;
};

const Menu: React.FC<MenuProps> = ({ isOpen }) => {
  const { company, statusCompany } = useApplicationContext();
  const { user, isCompanyUser } = useCurrentUser();
  const matchRoutes = useMatches();

  const validatePermission = useCallback(
    (item: Route) => {
      let isValidCurrRoutePermissions = false;
      const hasPermission = !!item.meta && !!item.meta.permission;
      const currRouteType = item?.meta?.permission?.type;
      const currRoutePermissions = item?.meta?.permission?.values || [];
      const isValidCurrRouteType = !currRouteType?.length || currRouteType === user.type;

      if (!hasPermission) {
        return true;
      }

      if (!currRoutePermissions.length) {
        isValidCurrRoutePermissions = true;
      } else {
        isValidCurrRoutePermissions = currRoutePermissions.some((routePermission) =>
          user.permissions.includes(routePermission),
        );
      }

      return isValidCurrRouteType && isValidCurrRoutePermissions;
    },
    [user.permissions, user.type],
  );

  const menuData = useMemo((): MenuDataProps[] => {
    if (!Routes || !Routes.length || !company.id) {
      return [];
    }

    const filterItemsWithMenu = (items: Route[]): MenuDataProps[] => {
      const filteredItems = [];
      for (const item of items) {
        const hasPermissionToAccess = validatePermission(item);

        if (
          item.id &&
          item?.meta?.showApplicationMenu &&
          item.meta.groupedBy &&
          hasPermissionToAccess
        ) {
          const routePathByName = findRoutePathByName(item.id) || '';
          const path = generatePath(routePathByName, { companyId: company.id });

          const menuItem = {
            id: item.id,
            to: path,
            name: item.meta.nameMenu,
            groupBy: item.meta.groupedBy,
            icon: item.meta.icon,
            customImageUrl: item.meta.customImageUrl,
            end: item.id === COMPANY_ROUTE_NAME.COMPANY,
          };
          filteredItems.push(menuItem);
        }
        if (item.children) {
          const childItems = filterItemsWithMenu(item.children);
          filteredItems.push(...childItems);
        }
      }
      return filteredItems;
    };

    return filterItemsWithMenu(Routes);
  }, [company.id, validatePermission]);

  const groupedRoutes = groupBy(menuData, 'groupBy');

  const hasGroups = Object.keys(groupedRoutes).length > 1;

  const handleClassNames = useCallback(
    (isActive: boolean) =>
      classesBuilder(style, {
        menuItem: true,
        isOpenMenuItem: isOpen,
        active: isActive,
      }),
    [isOpen],
  );

  const renderItem = useCallback(
    (item, isActive: boolean, isMenuItemInactive: boolean) => {
      return (
        <NavLink
          className={() => handleClassNames(isActive)}
          to={ !isMenuItemInactive ? item.to : ''}
          end={item.end}
          onClick={(e) => {
            if (isMenuItemInactive) {
              e.preventDefault();
            }
          }}>
          <span className={style.menuItemIcon}>
            {item.customImageUrl ? (
              <span
                className={style.customImage}
                style={{
                  mask: `url(${item.customImageUrl}) no-repeat center / contain`,
                  WebkitMask: `url(${item.customImageUrl}) no-repeat center / contain`,
                }}
              />
            ) : (
              <Icon name={item.icon ?? ''} />
            )}
          </span>
          {isOpen && <span className={style.menuItemLabel}>{item.name}</span>}
        </NavLink>
      );
    },
    [handleClassNames, isOpen],
  );

  const checkDisableForInactivatedCompany = useCallback(() => {
    return statusCompany === COMPANY_STATUS.INACTIVE && isCompanyUser;
  }, [statusCompany, isCompanyUser]);

  const renderItens = useCallback(
    (routes: MenuDataProps[]) => {
      return routes.map((item) => {
        const isActive = matchRoutes.some((matchRoute) => matchRoute.id === item.id);
        const isMenuItemInactive =
          checkDisableForInactivatedCompany() &&
          ITEMS_DISABLE_COMPANY_INACTIVE.includes(item.name ?? '');
        const itemTooltip = ITEMS_TOOLTIP.find((itemTooltip) => itemTooltip.item === item.name);

        return (
          <li key={item.name} className={isMenuItemInactive ? style.menuItemDisabled : ''}>
            {itemTooltip?.show && isMenuItemInactive ? (
              <Tooltip title={itemTooltip.message} position='bottom'>
                {renderItem(item, isActive, isMenuItemInactive)}
              </Tooltip>
            ) : (
              renderItem(item, isActive, isMenuItemInactive)
            )}
          </li>
        );
      });
    },
    [checkDisableForInactivatedCompany, matchRoutes, renderItem],
  );

  return (
    <nav className={style.menu}>
      {Object.entries(groupedRoutes).map(([key, routes]) => (
        <Fragment key={key}>
          {hasGroups && (
            <div className={style.groupNameBox}>
              {isOpen ? (
                <h5 className={classesBuilder(style, { groupName: true })}> {key} </h5>
              ) : (
                <div className={style.divider} />
              )}
            </div>
          )}
          <ul>{renderItens(routes)}</ul>
        </Fragment>
      ))}
    </nav>
  );
};

export default Menu;
