import React from 'react';

interface SpaceProps {
  width?: number | 'auto';
  height?: number | 'auto';
  horizontal?: boolean;
}

const SIZE_DEFAULT = 8;

const Space: React.FC<SpaceProps> = ({
  width = SIZE_DEFAULT,
  height = SIZE_DEFAULT,
  horizontal,
}) => (
  <div
    style={{
      width: horizontal ? '100%' : width,
      height: horizontal ? height : 'auto',
    }}
  />
);

export default Space;
