import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import React from 'react';

import { Button, Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const Success: React.FC = () => {
  const navigate = useHistoryNavigator();
  const queryString = useHistoryQuery();

  const isPasswordRecovery = queryString?.recovery === 'email';

  const title = isPasswordRecovery
    ? 'Solicitação de redefinição de senha enviada'
    : 'Solicitação de desbloqueio do código de segurança';

  const message = isPasswordRecovery
    ? `Um e-mail contendo o link para redefinição de senha foi
    enviado para o endereço de e-mail informado. Por favor, verifique sua caixa de entrada,
    incluindo a pasta de spam. No e-mail estará contido um link que permitirá que você
    redefina sua senha de forma rápida e segura.`
    : `Seu pedido de desbloqueio foi realizado com sucesso! Nossa equipe de relacionamento iniciará o processo de desbloqueio em até 3 dias úteis
    a partir da data de solicitação de desbloqueio. Fique tranquilo(a), logo você poderá acessar sua conta normalmente.`;

  return (
    <>
      <Grid alignContent='center'>
        <Icon color='success' size={64} name='done-all-outline' />
      </Grid>

      <Row style={{ textAlign: 'center' }}>
        <Text title color='success' value={title} margin='0 0 8px' />

        <Text size='medium' value={message} />
      </Row>

      <Button full bold invertColor onClick={() => navigate.push('/auth/login')}>
        <Row>Retornar ao início</Row>
      </Button>
    </>
  );
};

export default Success;
