import React from 'react';

import { useHistoryParams } from '@/navigation';

import { BoxInfo } from '@/components';
import ButtonsDownload from '@/modules/cadastralDataMatching/components/buttonsDownload/buttonsDownload';
import { InformationData } from '@/modules/cadastralDataMatching';
import useCadastralDataMatchingDetails from '../../hooks/useCadastralDataMatchingDetails';

const Details: React.FC = () => {
  const { cadastralDataMatchingId } = useHistoryParams();
  const { isLoading, cadastralDataMatching } =
    useCadastralDataMatchingDetails(cadastralDataMatchingId);

  return (
    <BoxInfo
      title='Dados do bate cadastral'
      icon='info-outline'
      header={<ButtonsDownload loading={isLoading} fileId={cadastralDataMatching?.id} />}>
      <InformationData data={cadastralDataMatching} loading={isLoading} />
    </BoxInfo>
  );
};

export default Details;
