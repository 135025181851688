import React, { Fragment } from 'react';

import { Icon } from 'vkit/lib/components';

import { classesBuilder } from '@/utils';

import style from './style/steps.module.scss';

interface StepsInterface {
  data: {
    text: string;
    icon: string;
  }[];
  current: number;
}

const Steps: React.FC<StepsInterface> = ({ data, current }) => (
  <div className={style.steps}>
    {data.map(({ text, icon }, index) => (
      <Fragment key={index}>
        {index > 0 && (
          <div
            className={classesBuilder(style, {
              line: true,
              active: current >= index,
            })}
          />
        )}

        <div
          className={classesBuilder(style, {
            icon: true,
            active: current === index,
            done: current > index,
          })}>
          <Icon name={icon} color={current >= index ? 'colorDefault' : 'light'} />

          <div className={style.text}>{text}</div>
        </div>
      </Fragment>
    ))}
  </div>
);

export default Steps;
