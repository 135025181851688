import React, { useMemo } from 'react';
import { Text, TextArea } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

interface MessageProps {
  ratingValue: number;
  value: string;
  setValue: (value: string) => void;
}

const Message: React.FC<MessageProps> = ({ ratingValue, value, setValue }) => {
  const ratingMessage = useMemo(() => {
    // eslint-disable-next-line max-len
    const bad =
      'Ficamos tristes que sua experiência tenha sido negativa. Gostaria de nos contar o que ocorreu para podermos melhorar?';
    // eslint-disable-next-line max-len
    const good =
      'Ficamos felizes que sua experiência tenha sido positiva. Gostaria de nos contar o que mais lhe agradou?';
    const neutral = 'Estamos em busca de melhorar sempre e gostaríamos de ouvir suas sugestões.';

    if (ratingValue < 3) return `${bad}`;
    if (ratingValue > 3) return `${good}`;
    return `${neutral}`;
  }, [ratingValue]);

  return (
    <>
      <Row>
        <Text textAlign='center' value={ratingMessage} size='medium' />
      </Row>
      <Row>
        <TextArea
          rows={3}
          maxLength={255}
          value={value}
          onChange={(value) => {
            setValue(value.target.value);
          }}
          onBlur={(value) => {
            setValue(value.target.value);
          }}
        />
        <Text textAlign='right' value={`${value.length}/255`} size='small' />
      </Row>
    </>
  );
};

export default Message;
