import { ObjectType } from '@/shareds/types';

/**
 * Safely parses a JSON string and returns the result.
 *
 * @param str - The JSON string to be parsed.
 * @returns Parsed JSON object or array, or undefined if parsing fails.
 */
export const safeJsonParse = (str?: string | null): ObjectType | ObjectType[] | undefined => {
  if (!str) {
    return;
  }

  try {
    return JSON.parse(str);
  } catch {
    return;
  }
};
