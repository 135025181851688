import { Company } from '@/services/negotiations/endpoints/companies';
import { Base } from '@/shareds/types/base.type';

export enum USER_TYPE {
  BROKER = 'broker',
  COMPANY = 'company',
}

export type UserType = `${USER_TYPE}`;

export interface User extends Base {
  email: string | null;
  name: string;
  type: UserType;
  scopeId: string;
  scopeIds?: string[];
  permissions: string[];
  scopes?: Company[];
}
