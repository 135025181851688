export const checkPasswordValid = (password: string): string | null => {
  const rules = [
    {
      regex: /.{8}/,
      messageError: 'Senha deve ter pelo menos 8 caracteres',
    },
    {
      regex: /[A-Z]/,
      messageError: 'Senha deve ter pelo menos 1 letra maiúscula',
    },
    {
      regex: /[a-z]/,
      messageError: 'Senha deve ter pelo menos 1 letra minuscula',
    },
    {
      regex: /[\d]/,
      messageError: 'Senha deve ter pelo menos 1 número',
    },
    {
      regex: /[!@#$%^&*()\-_+.]/,
      messageError: 'Senha deve ter pelo menos 1 caracter especial (!@#$%^&*()-_+.)',
    },
    {
      regex: /^\S*$/,
      messageError: 'Senha não deve ter espaço',
    },
  ];

  const { messageError } = rules.find(({ regex }) => !regex.test(password)) || {};
  return messageError || null;
};
