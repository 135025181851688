export const FILES_ALLOWED_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'pdf',
  'doc',
  'docx',
  'txt',
  'tsv',
];
export const LIMIT_CHARACTERS_FILE_NAME = 255;
