import { Permission } from './permission';

export enum USER_TYPE {
  BROKER = 'broker',
  COMPANY = 'company',
}

export interface UserScope {
  id: string;
  name: string;
  cnpj: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  type: `${USER_TYPE}`;
  scopeIds: string[];
  scopes?: UserScope[];
  status: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  userPermissions: Permission[];
  permissions: string[];
}
