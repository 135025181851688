import { BoxInfo, GridGroup } from '@/components';
import React, { useMemo } from 'react';
import { AvailableFilterFields, USER_AVAILABLE_FILTERS } from '../../entities';
import useAvailableFilters, { FilterProps } from './hook/useAvailableFilters';

const Filter: React.FC<FilterProps> = ({ fields, filters, onChange }) => {
  const { availableFilters } = useAvailableFilters({
    filters,
    onChange,
  });
  const renderFields = useMemo(() => {
    let fieldsToUse: AvailableFilterFields[] = Object.keys(availableFilters).map(
      (key): AvailableFilterFields => ({
        field: key as `${USER_AVAILABLE_FILTERS}`,
        size: undefined,
      }),
    );
    if (fields?.length) {
      fieldsToUse = fields;
    }
    return fieldsToUse.map(({ field, size }) => ({
      component: availableFilters[field].field,
      default: size ?? availableFilters[field].size,
    }));
  }, [availableFilters, fields]);
  return (
    <BoxInfo>
      <GridGroup body={renderFields} />
    </BoxInfo>
  );
};

export default Filter;
