import { ColumnSelectOption } from '@/components/selectValidate';
import {
  SELECT_BENEFICIARY_AVAILABLE_COLUMNS,
  UniqueBeneficiaryColumns,
} from '@/modules/beneficiary/entities';
import { useNegotiations } from '@/modules/negotiation';
import { extractDocumentsFromBeneficiary } from '@/pages/beneficiaries/shareds/utils/extractDocumentsFromBeneficiary';
import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';
import { ObjectType } from '@/shareds/types';
import { maskCpf } from '@/utils/format';
import { get } from 'lodash';

export interface UseSelectBeneficiaryProps {
  columns?: UniqueBeneficiaryColumns;
}

const useSelectBeneficiary = ({ columns }: UseSelectBeneficiaryProps) => {
  const { negotiationsById } = useNegotiations();

  const AVAILABLE_COLUMNS: Record<SELECT_BENEFICIARY_AVAILABLE_COLUMNS, ColumnSelectOption> = {
    [SELECT_BENEFICIARY_AVAILABLE_COLUMNS.NAME]: {
      title: 'Nome',
      path: 'life.name',
    },
    [SELECT_BENEFICIARY_AVAILABLE_COLUMNS.CPF]: {
      title: 'CPF',
      path: '',
      custom: (beneficiary: ObjectType) => {
        const { cpf } = extractDocumentsFromBeneficiary(beneficiary as Beneficiary);
        return cpf && maskCpf(cpf);
      },
    },
    [SELECT_BENEFICIARY_AVAILABLE_COLUMNS.PLAN]: {
      title: 'Plano',
      path: '',
      custom: ({ negotiationId }: ObjectType) =>
        get(negotiationsById[negotiationId], 'product.name'),
    },
    [SELECT_BENEFICIARY_AVAILABLE_COLUMNS.BENEFIT]: {
      title: 'Benefício',
      path: '',
      custom: ({ negotiationId }: ObjectType) =>
        get(negotiationsById[negotiationId], 'product.benefit.name'),
    },
  };

  const getColumns = () => {
    if (!columns || !columns.length) {
      return Object.keys(AVAILABLE_COLUMNS).map(
        (columnKey) => AVAILABLE_COLUMNS[columnKey as SELECT_BENEFICIARY_AVAILABLE_COLUMNS],
      );
    }
    return columns.map(
      (columnKey) => AVAILABLE_COLUMNS[columnKey as SELECT_BENEFICIARY_AVAILABLE_COLUMNS],
    );
  };

  return {
    availableColumns: getColumns(),
  };
};

export default useSelectBeneficiary;
