import ICONS from '@/shareds/constants/icons';
import { USER_TYPE } from '@/services/users/endpoints/users';
import { APPLICATION_GROUP_BY, Route } from '@/core/entities';

import List from '@/modules/companyGroup/pages/list/list';
import Details from '@/modules/companyGroup/pages/details/details';
import Create from '@/modules/financialAdjustment/pages/create';
import { Main } from '@/core/views';
import { createRouteLoaderPageDetails } from '@/core/utils';

const pageName = 'Grupos de empresas';

export enum ROUTE_COMPANY_GROUP {
  LIST = 'company-group-list',
  DETAIL = 'company-group-detail',
  CREATE_FINANCIAL_ADJUSTMENTS = 'financial-adjustments-create',
}

export const companyGroupRoutes: Route[] = [
  {
    path: 'company-groups',
    Component: Main,
    id: ROUTE_COMPANY_GROUP.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.BROKER,
      showApplicationMenu: true,
      icon: ICONS.COMPANY_GROUP,
      nameMenu: pageName,
      permission: {
        type: USER_TYPE.BROKER,
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.BROKER,
        icon: ICONS.COMPANY_GROUP,
        name: pageName,
        title: 'Grupos de empresas',
      });
    },
    children: [
      {
        index: true,
        Component: List,
      },
      {
        path: ':companyGroupId/details',
        id: ROUTE_COMPANY_GROUP.DETAIL,
        Component: Details,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            icon: ICONS.COMPANY_GROUP,
            name: pageName,
            hasGoBackAction: true,
            title: 'Grupos de empresas | Detalhes',
          });
        },
      },
      {
        path: ':companyGroupId/financial-adjustments/actions/create',
        id: ROUTE_COMPANY_GROUP.CREATE_FINANCIAL_ADJUSTMENTS,
        Component: Create,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            icon: ICONS.COMPANY_GROUP,
            name: 'Ajustes financeiros',
            hasGoBackAction: true,
            title: 'Ajustes financeiros | Novo',
          });
        },
      },
    ],
  },
];

export default companyGroupRoutes;
