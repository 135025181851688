import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BasePagination } from '@/shared/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import AdditionalServicesService from '../services/AdditionalServicesService';
import {
  AdditionalServicesNormalized,
  AdditionalServicesParams,
  AdditionalServicesResponseNormalized,
} from '../entities/interfaces';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';

export interface UseAdditionalServices {
  isLoading: boolean;
  additionalServices: AdditionalServicesNormalized[];
  pagination: BasePagination;
  isEmpty: boolean;
  loadAdditionalServices: Function;
}

const useAdditionalServices = (filters?: AdditionalServicesParams): UseAdditionalServices => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [additionalServices, setAdditionalServices] = useState<AdditionalServicesNormalized[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);

  const additionalServicesService = useMemo(() => new AdditionalServicesService(), []);

  const loadAdditionalServices = useCallback(
    async (params: AdditionalServicesParams) => {
      if (!params || !company.id) {
        return;
      }

      setLoading(true);

      try {
        const { page, data, totalPages, total, pageSize } =
          await additionalServicesService.getAdditionalServices<AdditionalServicesResponseNormalized>(
            company.id,
            params,
          );
        setAdditionalServices(data);

        setPagination({
          page,
          pageSize,
          totalPages,
          total,
        });
      } catch (e) {
        dispatchToast({
          text: 'Ops! Devido a um erro, não conseguimos buscar os Serviços adicionais.',
        });
        setAdditionalServices([]);
        setPagination(LIST_INITIAL_PAGINATION);
      } finally {
        setLoading(false);
      }
    },
    [additionalServicesService, company.id],
  );

  useEffect(() => {
    if (!filters || !company.id) {
      return;
    }
    loadAdditionalServices(filters);
  }, [loadAdditionalServices, company.id, filters]);

  const isEmptyList = useMemo(
    () => !additionalServices.length && !isLoading,
    [additionalServices.length, isLoading],
  );

  return {
    isLoading,
    additionalServices,
    pagination,
    isEmpty: isEmptyList,
    loadAdditionalServices,
  };
};

export default useAdditionalServices;
