import { ComponentType } from 'react';
import { LoaderFunction } from 'react-router-dom';

import { UserType } from '@/modules/user';

export enum APPLICATION_GROUP_BY {
  COMPANY = 'Empresa',
  BROKER = 'Corretora',
}

/**
 * Represents the permission metadata for a route.
 */
export type RouteMetaPermission = {
  /**
   * The type of user that has permission for this route.
   */
  type?: UserType;

  /**
   * Specific values associated with the permission.
   */
  values?: string[];
};

/**
 * Represents the configuration for the application menu on a route.
 * When a route configuration includes a `menu` property, it will be automatically added to the application menu.
 */
export type RouteMetaMenu = {
  /**
   * The title to display for the menu item.
   */
  title?: string;

  /**
   * A name to identify the menu item.
   */
  name?: string;

  /**
   * The icon representing the menu item (e.g., "briefcase-outline").
   */
  icon?: string;

  /**
   * A custom image URL for the menu item.
   */
  customImageUrl?: string;
};

export type RouteMeta = {
  /**
   * The title of the route.
   */
  title?: string;

  /**
   * Permission-related configuration for the route.
   */
  permission?: RouteMetaPermission;

  /**
   * Menu-related configuration for the route.
   */
  menu?: RouteMetaMenu;

  /**
   * Define when route can show on application menu.
   */
  showApplicationMenu?: true;
  /**
   * The icon representing the menu item (e.g., "briefcase-outline").
   */
  icon?: string;

  /**
   * A custom image URL for the menu item.
   */
  customImageUrl?: string;

  /**
   * Text to show on menu
   */
  nameMenu?: string;

  /**
   * Name show on page heading
   */
  pageName?: string;

  /**
   * Define a group of routes
   */
  groupedBy?: APPLICATION_GROUP_BY;
};

export type Route = {
  /**
   * A unique name assigned to the route, enabling navigation by referring to the route by its name.
   */
  name?: string;

  /**
   * Additional metadata for the route.
   */
  meta?: RouteMeta;

  /**
   * Configure the children routes
   */
  children?: Route[];
  /**
   * We are bringing some properties of the RouterObject type from react-router-dom to our routes interface,
   * we set what we need here and as we need others we add them here.
   * @see https://reactrouter.com/en/main/route/route#type-declaration
   *
   * We are performing this operation because, when extending, we have an incompatibility
   * when setting the childrens and so we can manipulate and return the routes as we need.
   */
  path?: string;
  loader?: LoaderFunction;
  index?: boolean;
  Component?: ComponentType;
  id?: string;
};

export type RouterLoaderPageDetails = {
  name?: string;
  icon?: string;
  customImageUrl?: string;
  hasGoBackAction?: boolean;
  groupedBy?: APPLICATION_GROUP_BY;
  title?: string;
};
