import { CustomSelectCompany } from '../components';
import { useCurrentUser } from '@/modules/profile';
import { DataSelectType } from '@/shareds/types';

interface Data {
  option?: DataSelectType;
  isSelected?: boolean;
}

export const useCustomOptionCompany = () => {
  const { isBrokerUser } = useCurrentUser();

  const customOptionCompany = (data: Data) => (
    <CustomSelectCompany
      showCompanyStatus={isBrokerUser}
      isSelected={data.isSelected}
      option={data.option}
    />
  );

  return customOptionCompany;
};
