import React, { useEffect, useRef } from 'react';

import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import AuditUpdatedData, { AuditUpdatedDataProps } from '../auditUpdatedData/auditUpdatedData';

interface DetailsDialogProps extends AuditUpdatedDataProps {
  open: boolean;
  fnCallback?: (response: boolean) => void;
}

const DetailsDialog: React.FC<DetailsDialogProps> = ({ open, fnCallback, data }) => {
  const useRefDialog = useRef({ open: () => {}, dismiss: () => {} });

  useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [open]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size={window.innerWidth - 300}
      title={
        <Text
          value={'Detalhes'}
          title
          size='larger'
          icon='message-circle-outline'
          iconColor={'colorDefault'}
          color={'colorDefault'}
          iconSize={32}
          textAlign='left'
        />
      }
      body={<AuditUpdatedData data={data} />}
      footer={
        <Grid alignContent='right' gap={8}>
          <Button label={'Fechar'} color='colorDefault' onClick={() => fnCallback?.(true)} />
        </Grid>
      }
    />
  );
};

export default DetailsDialog;
