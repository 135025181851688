import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasePagination } from '@/shared/entities';

import { isEmpty as _isEmpty } from 'lodash';
import { useNegotiations } from '@/modules/negotiation';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import {
  BeneficiaryNormalized,
  BeneficiaryResponseNormalized,
  BeneficiarySearchParams,
} from '../../entities';
import { BeneficiaryService } from '../../services';

interface UseBeneficiaries {
  isLoading: boolean;
  beneficiaries: BeneficiaryNormalized[];
  pagination: BasePagination;
  isEmpty: boolean;
}

const beneficiaryService = new BeneficiaryService();

const useDependents = (filters?: BeneficiarySearchParams): UseBeneficiaries => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryNormalized[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);

  const { negotiationsById } = useNegotiations();

  const beneficiaryHolderKey = 'beneficiaryHolder.holderId';

  const loadBeneficiaries = useCallback(async (params: BeneficiarySearchParams) => {
    if (!params || !params.companyId || !params[beneficiaryHolderKey]) {
      return;
    }

    setLoading(true);

    try {
      const { page, data, totalPages, total, pageSize } =
        await beneficiaryService.getBeneficiaries<BeneficiaryResponseNormalized>(params);

      setBeneficiaries(data);

      setPagination({
        page,
        totalPages,
        total,
        pageSize,
      });
    } catch (e) {
      console.error(e);

      dispatchToast({
        text: 'Ops! Devido a um erro, não conseguimos buscar os dependentes.',
      });

      setBeneficiaries([]);
      setPagination(LIST_INITIAL_PAGINATION);
    } finally {
      setLoading(false);
    }
  }, []);

  const beneficiariesWithoutHolder = useMemo((): BeneficiaryNormalized[] => {
    if (_isEmpty(negotiationsById) || !beneficiaries.length) {
      return [];
    }
    return beneficiaries
      .filter((beneficiary) => beneficiary.id !== filters?.[beneficiaryHolderKey])
      .map((beneficiary) => {
        return {
          ...beneficiary,
          negotiation: negotiationsById[beneficiary.negotiationId],
        };
      });
  }, [beneficiaries, filters, negotiationsById]);

  const isEmptyList = useMemo(
    () => !beneficiariesWithoutHolder.length && !isLoading,
    [beneficiariesWithoutHolder.length, isLoading],
  );

  useEffect(() => {
    if (!filters || !filters.companyId || !filters[beneficiaryHolderKey]) {
      return;
    }
    loadBeneficiaries(filters);
  }, [filters, loadBeneficiaries]);

  return {
    isLoading,
    beneficiaries: beneficiariesWithoutHolder,
    pagination,
    isEmpty: isEmptyList,
  };
};

export default useDependents;
