import { get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Button } from 'vkit/lib/components';
import { Action, Column } from '@/components/dataTable';
import ContractTableListLines from '../contractTableListLines/contractTableListLines';
import { Contract, ContractData } from '@/modules/contract';
import ContractStatus from '@/modules/contract/components/contractStatus/contractStatus';
import { LogoProvider } from '@/modules/provider';
import { useHistoryNavigator } from '@/navigation';
import ICONS from '@/shareds/constants/icons';

export type ColumnNames =
  | 'logo'
  | 'code'
  | 'modelName'
  | 'provider'
  | 'validFrom'
  | 'validUntil'
  | 'benefit'
  | 'status';

export type ActionNames = 'goToDetails';

interface ContractTableProps {
  linesLoader?: number;
  columns?: ColumnNames[];
  actions?: ActionNames[];
  data: ContractData;
}

const ContractTableList: React.FC<ContractTableProps> = ({
  linesLoader,
  columns = [],
  actions,
  data,
}) => {
  const [linesLoaderSize] = useState(linesLoader ?? 10);
  const { contracts, loading } = data;

  const navigate = useHistoryNavigator();

  const getLogoProvider = (contract: Contract) => {
    const providerCnpj = get(contract, 'provider.cnpj', 'not-found');
    return <LogoProvider providerCnpj={String(providerCnpj)} size='small' />;
  };

  const getContractStatus = (contract: Contract) => <ContractStatus contract={contract} />;

  const columnsToChoose: { [key in ColumnNames]: Column } = {
    logo: {
      custom: getLogoProvider,
    },
    code: {
      title: 'Código',
      path: 'code',
    },
    modelName: {
      title: 'Tipo',
      path: 'model.name',
    },
    provider: {
      title: 'Fornecedor',
      path: 'provider.name',
    },
    validFrom: {
      title: 'Início de vigência',
      path: 'validFrom',
      type: 'dateWithoutTZ',
    },
    validUntil: {
      title: 'Fim de vigência',
      path: 'validUntil',
      type: 'dateWithoutTZ',
    },
    benefit: {
      title: 'Benefício',
      custom: (contract: Contract) =>
        contract.negotiations
          ?.reduce((acc: string[], negotiation) => {
            const benefitName = get(negotiation, 'product.benefit.name');

            if (!benefitName || acc.includes(benefitName)) {
              return acc;
            }

            acc.push(benefitName);
            return acc.sort();
          }, [])
          .join(', ') ?? '-',
    },
    status: {
      title: 'Status',
      custom: getContractStatus,
    },
  };

  const getActions = useCallback(
    (contract: Contract) => {
      const actionsToChoose: { [key in ActionNames]: Action } = {
        goToDetails: {
          label: 'Ver detalhes',
          onClick: () => navigate.push(`${contract.id}`),
          icon: ICONS.DETAILS,
        },
      };

      if (actions?.length === 1) {
        const [actionKey] = actions;
        const action = actionsToChoose[actionKey];

        return <Button solo outlined label={action.label} onClick={action.onClick as () => void} />;
      }

      return actions?.map((actionKey) => actionsToChoose[actionKey]);
    },
    [actions, navigate],
  );

  return (
    <ContractTableListLines
      actionsByLine={actions && getActions}
      columns={columns.map((column) => columnsToChoose[column])}
      data={contracts}
      linesLoader={linesLoaderSize}
      loading={loading}
    />
  );
};

export default ContractTableList;
