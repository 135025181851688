import React from 'react';
import { Grid, Row } from 'vkit/lib/context';
import { Button, Chip, Text } from 'vkit/lib/components';
import { STATUS_FILE, StatusFile } from '@/components/fileUpload/types/fileUpload.types';
import { IconStatus } from '@/components/fileUpload/components/iconStatus';
import { ValidatedFile } from '@/components/fileUpload/hooks/useFileUpload';
import { ObjectType } from '@/shareds/types';

interface MultipleFilesProps {
  validatedFiles: ValidatedFile[];
  removeFile: (fileIndex: number) => void;
  statusFiles?: StatusFile[];
  hasError: boolean;
  formValidatorErrors: ObjectType;
  handleUploadClick: Function;
  name: string;
}

export const MultipleFiles: React.FC<MultipleFilesProps> = ({
  validatedFiles,
  removeFile,
  statusFiles,
  hasError,
  formValidatorErrors,
  handleUploadClick,
  name,
}) => {
  const generateFileKey = (fileName: string, fileIndex: number) => `${fileName}_${fileIndex}`;

  return (
    <Grid alignItems='center' gap={4} column>
      {validatedFiles.map((validatedFile, index) => (
        <Grid alignItems='center' key={generateFileKey(validatedFile.name, index)} gap={8} column>
          <Grid alignItems='center'>
            <Chip
              color={
                validatedFile.valid && statusFiles?.[index]?.value !== STATUS_FILE.ERROR
                  ? 'light'
                  : 'danger'
              }
              label={validatedFile.name}
              button={
                !statusFiles?.[index]?.value ||
                [STATUS_FILE.PENDING, STATUS_FILE.ERROR].includes(statusFiles[index].value)
                  ? {
                      icon: 'close',
                      iconColor: 'light',
                      solo: true,
                      onClick: () => removeFile(index),
                    }
                  : undefined
              }
              wordBreak
            />

            {statusFiles?.[index]?.value && <IconStatus status={statusFiles[index].value} />}
          </Grid>

          {validatedFile.errors.map((error) => (
            <Text key={error} fontWeight='bold' value={error} size='medium' color='danger' />
          ))}

          {statusFiles?.[index]?.messageError ? (
            <Text
              fontWeight='bold'
              value={statusFiles?.[index]?.messageError || ''}
              size='medium'
              color='danger'
            />
          ) : null}
        </Grid>
      ))}

      {(hasError || formValidatorErrors[name]) && (
        <Row style={{ padding: '10px 0px 0px 0px' }}>
          <Text
            fontWeight='bold'
            value={
              formValidatorErrors[name] ||
              (validatedFiles.filter(({ valid }) => !valid).length > 1
                ? 'Alguns arquivos estão incorretos'
                : 'Um arquivo está incorreto')
            }
            size='medium'
            color='danger'
          />
          <Text
            value={
              validatedFiles.filter(({ valid }) => !valid).length > 1
                ? 'Ajuste ou remova os arquivos e tente novamente'
                : 'Ajuste ou remova o arquivo e tente novamente'
            }
            size='medium'
          />
        </Row>
      )}

      <Row>
        <Button
          label='Adicionar arquivos'
          solo
          outlined
          size='xLarger'
          onClick={() => {
            handleUploadClick();
          }}
        />
      </Row>
    </Grid>
  );
};
