import React from 'react';
import { Grid } from 'vkit/lib/context';
import { Text } from 'vkit/lib/components';
import imageURL from '@/assets/images/barrier.png';
import { classesBuilder } from '@/utils';
import style from './style/blockingMessage.module.scss';

export interface BlockingMessageProps {
  title?: string;
  description?: string;
  vertical?: boolean;
}

const BlockingMessage: React.FC<BlockingMessageProps> = ({ title, description, vertical }) => (
  <div
    className={classesBuilder(style, {
      blockingMessage: true,
      vertical,
    })}>
    <div className={style.content}>
      <div className={style.image} style={{ backgroundImage: `url(${imageURL})` }} />
      {vertical ? (
        <>
          {title && (
            <Text color='colorDefault' padding='16px 0 0' textAlign='center' title value={title} />
          )}

          {description && (
            <Text padding='16px 0 0' size='larger' textAlign='center' value={description} />
          )}
        </>
      ) : (
        <>
          <Grid growing alignItems='center'>
            <div>
              {title && <Text color='colorDefault' title value={title} padding='0 0 0 30px' />}

              {description && <Text padding='8px 0 0 30px' size='larger' value={description} />}
            </div>
          </Grid>
        </>
      )}
    </div>
  </div>
);

export default BlockingMessage;
