import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import React, { useMemo } from 'react';

import { BoxInfo, Divider, EmptyMessage, WordBreak } from '@/components';
import { ContentListLines } from '@/components/dataTable/components';
import { ActionsLayer, Pagination } from 'vkit/lib/components';
import  Status  from '../../components/status/Status';

import { useHistoryLocation, useHistoryNavigator } from '@/navigation';

import { Row } from 'vkit/lib/context';
import {
  MOVE_LIST_ACTIONS,
  MOVE_LIST_COLUMNS,
  MOVE_LIST_INITIAL_PAGE,
  MOVE_LIST_PAGE_SIZE_DEFAUlT,
  Move,
  MoveListActionsCollection,
  MoveListColumnsCollection,
  MoveParams,
} from '../../entities';
import { useMoveList } from '../../hooks';

const AVALIBLE_COLUMNS = {
  [MOVE_LIST_COLUMNS.NAME]: {
    title: 'NOME DO ARQUIVO',
    path: 'name',
    sortable: true,
    width: '40%',
    custom: ({ name }: Move) => <WordBreak>{name}</WordBreak>,
  },
  [MOVE_LIST_COLUMNS.USER_NAME]: {
    path: 'user.name',
    title: 'CRIADO POR',
  },
  [MOVE_LIST_COLUMNS.TYPE]: {
    title: 'TIPO',
    path: 'type',
  },
  [MOVE_LIST_COLUMNS.PROVIDER]: {
    title: 'FORNECEDOR',
    path: 'provider',
  },
  [MOVE_LIST_COLUMNS.STATUS]: {
    title: 'STATUS',
    custom: ({ status }: Move) => <Status label={status} />,
  },
  [MOVE_LIST_COLUMNS.UPDATED_AT]: {
    title: 'CRIADO EM',
    path: 'uploadedAt',
    sortable: true,
  },
};

interface TableProps {
  actions?: MoveListActionsCollection;
  columns?: MoveListColumnsCollection;
  linesLoader?: number;
  filters?: MoveParams;
  handleFilters: (params: MoveParams) => void;
}

const Table: React.FC<TableProps> = ({
  actions,
  columns,
  filters,
  handleFilters,
  linesLoader = 5,
}) => {
  const navigate = useHistoryNavigator();
  const { pathname } = useHistoryLocation();
  const { isLoading, moves, isEmptyMoves, showPagination, pagination } = useMoveList(filters);
  const { downloadFile, loading: loadingDownload } = useFileDownload();

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns]);

  const getActions = (id: string) => {
    let listActions = [];
    const AVALIBLE_ACTIONS = {
      [MOVE_LIST_ACTIONS.DETAILS]: {
        label: 'Ver detalhes',
        onClick: () => {
          navigate.push(`${pathname}/${id}/details`);
        },
        icon: 'eye-outline',
      },
      [MOVE_LIST_ACTIONS.DOWNLOAD]: {
        label: 'Baixar arquivo',
        onClick: () => {
          downloadFile(id);
        },
        icon: 'download-outline',
      },
    };

    if (!actions) {
      listActions = Object.values(AVALIBLE_ACTIONS).map((value) => value);
    } else {
      listActions = actions.map((action) => AVALIBLE_ACTIONS[action]);
    }

    return <ActionsLayer vertical actions={listActions} />;
  };

  const handleChangePageSize = (value: number) => {
    handleFilters({
      ...filters,
      page: 1,
      pageSize: value,
    });
  };

  const handlePageChange = (value: number) => {
    handleFilters({
      ...filters,
      page: value,
    });
  };

  return (
    <BoxInfo elevation={undefined} margin={0} padding={1}>
      {(moves.length || isLoading) && (
        <ContentListLines
          actionsByLine={({ id }: Move) => getActions(id || '')}
          columns={useColumns}
          data={moves}
          linesLoader={linesLoader}
          loading={isLoading || loadingDownload}
        />
      )}

      {isEmptyMoves && (
        <EmptyMessage title='Nada por aqui!' description='Nenhum arquivo encontrado.' />
      )}

      {showPagination && (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px' }}>
            <Pagination
              page={pagination.page || MOVE_LIST_INITIAL_PAGE}
              totalPages={pagination.totalPages}
              total={pagination.total || 0}
              pageSize={filters?.pageSize || MOVE_LIST_PAGE_SIZE_DEFAUlT}
              onChangePage={handlePageChange}
              onChangePageSize={handleChangePageSize}
            />
          </Row>
        </>
      )}
    </BoxInfo>
  );
};

export default Table;
