import { BodyForm } from '@/components/formValidate';
import React, { useMemo, useState } from 'react';

import { Radio, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import {
  BoxInfo,
  FileUpload,
  FormValidate,
  GridGroup,
  SelectValidate,
  TextFieldValidate,
  ToastStatusGlobal,
} from '@/components';

import { BeneficiaryWithDocuments } from '@/pages/beneficiaries/shareds/interfaces/beneficiary';
import {
  BENEFICIARY_OWNERSHIP,
  MOVE_FILE_COLUMN_NAME,
  MOVE_FILE_TYPE,
  REMOVAL_UNIT_MOVE_REASON,
} from '@/pages/moves/shareds/constants';
import { mountFile } from '@/pages/moves/utils/mountFile';
import { movesValidationService } from '@/services/files';
import { RemovalUnitMoveInForm } from '@/services/moves/endpoints/moves';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';

import { findRoutePathByName } from '@/core/utils';
import { ROUTE_MOVES } from '@/modules/move/routes/moveRoute';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import { ALLOWED_EXTENSIONS_MOVE } from '@/pages/beneficiaries/shareds/constants/allowedExtensions';
import { isBradesco } from '@/pages/beneficiaries/shareds/utils/checkProvider';
import { validateFiles } from '@/pages/beneficiaries/shareds/utils/validateFiles';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { decodedJwtToken } from '@/utils/authentication';
import { getMessageError } from '@/utils/getMessageError';
import { generatePath } from 'react-router-dom';

interface RemovalFormProps {
  negotiation: Negotiation;
  beneficiary: BeneficiaryWithDocuments;
  beneficiaryHolder: BeneficiaryWithDocuments;
}

const RemovalForm: React.FC<RemovalFormProps> = ({
  negotiation,
  beneficiary,
  beneficiaryHolder,
}) => {
  const { companyId } = useHistoryParams();
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [dataToMove, setDataToMove] = useState<BeneficiaryToMove>({
    [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]: 'NAO',
  });

  const { toast } = ToastStatusGlobal();
  const { company } = useCompany();
  const navigate = useHistoryNavigator();

  const moveSuccessRouteFound = findRoutePathByName(ROUTE_MOVES.UPLOAD_SUCCESS);

  const isHolder = useMemo(
    () => beneficiary.id === beneficiary.beneficiaryHolder?.holderId,
    [beneficiary.beneficiaryHolder?.holderId, beneficiary.id],
  );

  const onChangeForm = (newData: RemovalUnitMoveInForm) => {
    setDataToMove((oldData) => ({
      ...oldData,
      ...newData,
    }));
  };

  const getSheet = () => {
    return mountFile(
      [
        {
          ...dataToMove,
          [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: negotiation.contract.code,
          [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: negotiation.costCenter.name,
          [MOVE_FILE_COLUMN_NAME.CPF]: beneficiary.lifeDocuments?.cpf,
          [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: beneficiaryHolder.lifeDocuments?.cpf,
          [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: isHolder
            ? BENEFICIARY_OWNERSHIP.HOLDER
            : BENEFICIARY_OWNERSHIP.DEPENDENT,
        },
      ],
      MOVE_FILE_TYPE.REMOVAL,
      beneficiary.life!.name,
    );
  };

  const getFormDataValues = async () => {
    const formData = new FormData();
    const decodedToken = decodedJwtToken();
    const sheet = getSheet();

    formData.append('userName', decodedToken.user.name);
    formData.append('userId', decodedToken.sub);
    formData.append('userEmail', decodedToken.user.email);
    formData.append('scope', 'company');
    formData.append('companyId', company.id);
    formData.append('providerId', negotiation.product.providerId);
    formData.append('movimentationType', 'removal');
    formData.append('files', sheet);

    if (attachments?.length) {
      attachments.forEach((attachment) => formData.append('files', attachment));
    }

    return formData;
  };

  const create = async () => {
    try {
      setLoading(true);
      const formData = await getFormDataValues();
      await movesValidationService.create(formData);

      if (moveSuccessRouteFound) {
        navigate.push(generatePath(moveSuccessRouteFound, { companyId }));
      }
    } catch (error) {
      console.error(error);
      const messageError = getMessageError({
        error,
        messageDefault: 'Erro ao solicitar exclusão.',
      });
      toast('Puxa!', messageError, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const validateDependentCode = (value?: number) => {
    if (!value && isBradesco(negotiation) && !isHolder) {
      return 'Campo obrigatório';
    }

    if (value && value < 1) {
      return 'Valor deve ser maior que 0';
    }

    if (value && value > 99) {
      return 'Valor deve ser menor que 100';
    }

    return null;
  };

  const getFields = ({ useValues, useErrors, onBlur, onChange }: BodyForm) => (
    <GridGroup
      body={[
        {
          default: 80,
          middle: 100,
          component: (
            <SelectValidate
              required
              searchable
              name={MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO}
              label='Motivo da exclusão'
              data={[
                {
                  text: 'Aposentadoria',
                  value: REMOVAL_UNIT_MOVE_REASON.RETIREMENT,
                },
                {
                  text: 'Demissão com justa causa',
                  value: REMOVAL_UNIT_MOVE_REASON.DISMISSAL_WITH_JUST_CAUSE,
                },
                {
                  text: 'Demissão sem justa causa',
                  value: REMOVAL_UNIT_MOVE_REASON.DISMISSAL_WITHOUT_JUST_CAUSE,
                },
                {
                  text: 'Desistência',
                  value: REMOVAL_UNIT_MOVE_REASON.WITHDRAWAL,
                },
                {
                  text: 'Duplicidade',
                  value: REMOVAL_UNIT_MOVE_REASON.DUPLICITY,
                },
                {
                  text: 'Falecimento',
                  value: REMOVAL_UNIT_MOVE_REASON.DEATH,
                },
                {
                  text: 'Inadiplência',
                  value: REMOVAL_UNIT_MOVE_REASON.NON_PAYMENT,
                },
                {
                  text: 'Portabilidade',
                  value: REMOVAL_UNIT_MOVE_REASON.PORTABILITY,
                },
              ]}
              useValues={useValues}
              useErrors={useErrors}
              onBlur={onBlur}
              onChange={onChange}
            />
          ),
        },
        {
          default: 20,
          middle: 100,
          component: (
            <TextFieldValidate
              required
              label={MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO}
              name={MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO}
              type='date'
              useValues={useValues}
              useErrors={useErrors}
              onBlur={onBlur}
              onChange={onChange}
            />
          ),
        },
        {
          default: 40,
          middle: 100,
          component: (
            <TextFieldValidate
              required={isBradesco(negotiation) && !isHolder}
              label={MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE}
              name={MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE}
              type='number'
              maxLength={2}
              useValues={useValues}
              useErrors={useErrors}
              onBlur={onBlur}
              onChange={onChange}
            />
          ),
        },
        {
          default: 40,
          middle: 100,
          component: (
            <TextFieldValidate
              label={MOVE_FILE_COLUMN_NAME.CERTIFICADO}
              name={MOVE_FILE_COLUMN_NAME.CERTIFICADO}
              useValues={useValues}
              useErrors={useErrors}
              onBlur={onBlur}
              onChange={onChange}
            />
          ),
        },

        {
          default: 20,
          middle: 100,
          component: (
            <>
              <Text value={MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279} title padding='0 0 20px' />

              <Grid gap={8}>
                <Radio
                  checked={dataToMove[MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279] === 'SIM'}
                  onChange={() => onChange(MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279, 'SIM')}
                  value='SIM'
                  label='Sim'
                />

                <Radio
                  checked={dataToMove[MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279] === 'NAO'}
                  onChange={() => onChange(MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279, 'NAO')}
                  value='NAO'
                  label='Não'
                />
              </Grid>
            </>
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <FileUpload
              multiple
              label='Anexos'
              name='attachments'
              allowedExtensions={ALLOWED_EXTENSIONS_MOVE}
              onChange={(name: string, value: File[]) => {
                onChange(name, value);
                onBlur(name, value);
                setAttachments(value);
              }}
              formValidatorErrors={useErrors}
            />
          ),
        },
      ]}
    />
  );

  return (
    <BoxInfo margin={0} title='Formulário de exclusão' icon='person-remove-outline'>
      <FormValidate
        resource={dataToMove}
        onChangeForm={onChangeForm}
        onError={() => {
          toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning');
        }}
        onSubmit={create}
        fields={{
          [MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO]: ['required'],
          [MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO]: ['required'],
          [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]: [],
          [MOVE_FILE_COLUMN_NAME.CERTIFICADO]: [],
          [MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE]: [validateDependentCode],
          attachments: [validateFiles],
        }}
        body={getFields}
        buttons={{
          submit: {
            loading,
            text: 'Solicitar exclusão do beneficiário',
            color: 'danger',
          },
        }}
      />
    </BoxInfo>
  );
};

export default RemovalForm;
