import { BoxInfo, GridGroup } from '@/components';
import FinancialAdjustmentTable from '@/modules/financialAdjustment/components/financialAdjustmentTable';
import { useFinancialAdjustmentPersistence } from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentPersistence';
import { filter } from 'lodash';
import React, { useState } from 'react';
import useFinancialAdjustmentFilterFields from '@/modules/financialAdjustment/hooks/useFinancialAdjustmentFilterFields';
import {
  FINANCIAL_ADJUSTMENT_ACTIONS,
  FINANCIAL_ADJUSTMENT_COLUMNS,
  FINANCIAL_ADJUSTMENT_LIST_FILTERS,
} from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import {
  FinancialAdjustment,
  ParamsToFilterFinancialAdjustments,
} from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import { Grid } from 'vkit/lib/context';

interface FieldOpcions {
  type: FINANCIAL_ADJUSTMENT_LIST_FILTERS;
  size: number;
}

interface ContractFiltersProps {
  fields: FieldOpcions[];
  removeFinancialAdjustmentCallback?: Function;
  financialAdjustments: FinancialAdjustment[];
  loading?: boolean;
  columns: FINANCIAL_ADJUSTMENT_COLUMNS[];
  actions: FINANCIAL_ADJUSTMENT_ACTIONS[];
}

const FinancialAdjustmentTableAndFilters: React.FC<ContractFiltersProps> = ({
  fields,
  removeFinancialAdjustmentCallback,
  financialAdjustments,
  loading,
  columns,
  actions,
  children,
}) => {
  const [filters, setFilters] = useState<Partial<ParamsToFilterFinancialAdjustments>>({});
  const { fieldsToChoose } = useFinancialAdjustmentFilterFields({ setFilters, filters });

  const { removeFinancialAdjustment, updatingPersistence } = useFinancialAdjustmentPersistence({
    removeFinancialAdjustmentCallback,
  });

  return (
    <Grid column gap={16}>
      <BoxInfo>
        <GridGroup
          body={fields.map((field) => ({
            ...fieldsToChoose[field.type],
            default: field.size,
          }))}
        />
      </BoxInfo>

      {children}

      <FinancialAdjustmentTable
        loading={loading || updatingPersistence}
        financialAdjustments={filter<FinancialAdjustment>(financialAdjustments, filters)}
        onRemove={({ id }) => id && removeFinancialAdjustment(id)}
        columns={columns}
        actions={actions}
      />
    </Grid>
  );
};

export default FinancialAdjustmentTableAndFilters;
