import { ShowHide } from '@/components';
import { debounce } from 'lodash';
import { SelectReasonAndSubReason } from '@/modules/attendance/components';
import {
  AttendanceFilter,
  AttendanceFiltersName,
  FIELDS,
  ReasonAndSubReason,
  statusOptions,
} from '@/modules/attendance/entities';
import React, { useMemo, useState } from 'react';
import { Button, Select, TextField } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

export interface Filters {
  values: AttendanceFilter;
  onChangeFilter: (name: AttendanceFiltersName, value: string | number) => void;
  fields: FIELDS[];
  extraFields?: FIELDS[];
}

const Filters: React.FC<Filters> = ({ values, onChangeFilter, fields, extraFields }) => {
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  const setButtonIconColor = useMemo(
    () => (showMoreFilters ? 'light' : 'clear'),
    [showMoreFilters],
  );
  const setButtonLabel = useMemo(
    () => (showMoreFilters ? 'Menos filtros' : 'Mais filtros'),
    [showMoreFilters],
  );

  const useReasonSubReasonValues = useMemo(
    () => ({
      reason: values?.reason,
      subReason: values?.subReason,
    }),
    [values?.reason, values?.subReason],
  );

  const showMoreFiltersButton = useMemo(() => extraFields && !!extraFields.length, [extraFields]);

  const handleReasonSubReasonChange = (name: ReasonAndSubReason, value: string) => {
    onChangeFilter(name, value);
  };

  const AVAILABLE_FIELDS = {
    [FIELDS.SUBJECT]: (
      <TextField
        key={FIELDS.SUBJECT}
        iconDir='right'
        icon='search-outline'
        label='Assunto'
        value={values['subject']}
        onChange={debounce((value) => onChangeFilter('subject', value), 1000)}
        small
        clearable
      />
    ),
    [FIELDS.REASON_SUBREASON]: (
      <SelectReasonAndSubReason
        key={FIELDS.REASON_SUBREASON}
        values={useReasonSubReasonValues}
        onChangeSelect={handleReasonSubReasonChange}
        small
        showLabel
      />
    ),
    [FIELDS.STATUS]: (
      <Select
        key={FIELDS.STATUS}
        clearable
        data={statusOptions}
        label='Status'
        value={values.status!}
        onChange={(value: string) => onChangeFilter('status', value)}
        small
      />
    ),
    [FIELDS.CREATED_AT_MIN]: (
      <TextField
        key={FIELDS.CREATED_AT_MIN}
        clearable
        iconDir='right'
        icon='search-outline'
        label='Data início'
        placeholder='dia/mes/ano'
        type='date'
        value={values.createdAtMin}
        onChange={(value: string) => onChangeFilter('createdAtMin', value)}
        small
      />
    ),
    [FIELDS.CREATED_AT_MAX]: (
      <TextField
        key={FIELDS.CREATED_AT_MAX}
        clearable
        iconDir='right'
        icon='search-outline'
        label='Data final'
        placeholder='dia/mes/ano'
        type='date'
        value={values.createdAtMax}
        onChange={(value: string) => onChangeFilter('createdAtMax', value)}
        small
      />
    ),
  };

  return (
    <Row style={{ padding: 0 }}>
      <Grid gap={16} alignItems='bottom' alignContent='justify'>
        {fields && fields.map((field) => AVAILABLE_FIELDS[field])}

        {showMoreFiltersButton && (
          <Grid>
            <Button
              iconColor={setButtonIconColor}
              onClick={() => setShowMoreFilters(!showMoreFilters)}
              label={setButtonLabel}
              icon='options-2-outline'
              color='light'
              invertColor={showMoreFilters}
            />
          </Grid>
        )}
      </Grid>
      <ShowHide visible={showMoreFilters} transition='slideToDown'>
        <Row style={{ padding: '16px 0 0' }}>
          <Grid gap={16} alignItems='bottom' alignContent='justify'>
            {extraFields && extraFields.map((field) => AVAILABLE_FIELDS[field])}
          </Grid>
        </Row>
      </ShowHide>
    </Row>
  );
};

export default Filters;
