import { useCallback, useEffect, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';
import { CadastralDataMatching, DEFAULT_EMPTY_CADASTRAL_DATA_MATCHING } from '../entities';

const monitoringService = new MonitoringService();

const useCadastralDataMatchingDetails = (cadastralDataMatchingId: string) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cadastralDataMatching, setCadastralDataMatching] = useState<CadastralDataMatching>(
    DEFAULT_EMPTY_CADASTRAL_DATA_MATCHING,
  );

  const loadCadastralDataMatching = useCallback(async (cadastralDataMatchingId: string) => {
    if (!cadastralDataMatchingId) {
      return;
    }

    setLoading(true);

    try {
      const response =
        await monitoringService.getFile<CadastralDataMatching>(cadastralDataMatchingId);
      setCadastralDataMatching(response);
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não conseguimos buscar as informações deste bate cadastral.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!cadastralDataMatchingId) {
      return;
    }
    loadCadastralDataMatching(cadastralDataMatchingId);
  }, [loadCadastralDataMatching, cadastralDataMatchingId]);

  return {
    isLoading,
    cadastralDataMatching,
    setCadastralDataMatching,
  };
};

export default useCadastralDataMatchingDetails;
