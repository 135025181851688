import HttpClientAdapter from '@/shared/services/httpClientAdapter';

export class MonitoringService extends HttpClientAdapter {
  constructor() {
    super('MONITORING');
  }

  getFile<Response>(id: string) {
    return this.get<Response>(`/files/${id}`);
  }

  getFiles<Response, Filters>(params: Filters) {
    return this.get<Response, Filters>('/files', params);
  }

  getRows<Response, Filters>(params: Filters) {
    return this.get<Response, Filters>('/rows', params);
  }
}
