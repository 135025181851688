import React, { useEffect } from 'react';
import { Grid, Row } from 'vkit/lib/context';

import { CardNumber } from '@/components';

import useCompany from '@/shared/hooks/useCompany/useCompany';
import { BeneficiarySearchParams } from '../../entities';
import { useCountersBeneficiary } from '../../hooks';

interface CounterProps {
  filters?: BeneficiarySearchParams;
}

const Counter: React.FC<CounterProps> = ({ filters }) => {
  const { company } = useCompany();
  const { loadCounters, counters, isLoadingCounter } = useCountersBeneficiary();

  useEffect(() => {
    if (!company.id || !filters) {
      return;
    }
    loadCounters(company.id, filters);
  }, [company.id, filters, loadCounters]);

  return (
    <Row style={{ padding: 0 }}>
      <Grid gap={16}>
        <CardNumber
          loading={isLoadingCounter}
          icon='people-outline'
          number={counters.beneficiaries || 0}
          text='Beneficiários'
        />
        <CardNumber
          loading={isLoadingCounter}
          icon='people-outline'
          number={counters.holders || 0}
          text='Titulares'
        />
        <CardNumber
          loading={isLoadingCounter}
          icon='people-outline'
          number={counters.dependents || 0}
          text='Dependentes'
        />
      </Grid>
    </Row>
  );
};

export default Counter;
