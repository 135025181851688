import { Route } from '@/core/entities';
import { User } from '@/modules/profile';
import { isEmpty } from 'lodash';

export const validatePermission = (item: Route, user: User) => {
  let isValidCurrRoutePermissions = false;
  const hasPermission = !!item?.meta?.permission;
  const currRouteType = item?.meta?.permission?.type;
  const currRoutePermissions = item?.meta?.permission?.values || [];
  const isValidCurrRouteType = !currRouteType?.length || currRouteType === user.type;

  if (!hasPermission) {
    return true;
  }

  if (!currRoutePermissions.length) {
    isValidCurrRoutePermissions = true;
  } else if (!isEmpty(user)) {
    isValidCurrRoutePermissions = currRoutePermissions.some((routePermission) =>
      user.permissions.includes(routePermission),
    );
  }

  return isValidCurrRouteType && isValidCurrRoutePermissions;
};
