import { FILE_PERMISSION_ID } from '@/modules/files/entities/fileEntity';
import FilesService from '@/modules/files/services/filesService';
import { User } from '@/modules/profile/entities/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FORM_SCOPE_TYPE, UserPermission, UserPermissionPayload } from '../../../entities';
import { ListCategories, normalizeCategories } from '../utils';
import useDisabledManage, { UseDisabledManage } from './useDisabledManage';

interface UseFileCategoryPermissionsProps {
  initialPermissions: UserPermission[];
  userId?: string;
  currentUser?: User;
  disabled?: boolean;
  scope: `${FORM_SCOPE_TYPE}`;
}

interface UseFileCategoryPermissions extends Omit<UseDisabledManage, 'shouldDisableAll'> {
  loading: boolean;
  data: ListCategories[];
  listUserPermissions: UserPermissionPayload[];
  handleBlockPermission: (id: string, type: FILE_PERMISSION_ID, check: boolean) => void;
  handleItemPermission: (parentId: string, id: string, type: FILE_PERMISSION_ID) => void;
}

export type FilePermissionsId = `${FILE_PERMISSION_ID}`;

const filesService = new FilesService();

const { REACT_APP_DEFAULT_COMPANY_FILES_PERMISSION = '[]' } = process.env;

const useFileCategoryPermissions = ({
  userId,
  currentUser,
  initialPermissions,
  disabled,
  scope,
}: UseFileCategoryPermissionsProps): UseFileCategoryPermissions => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ListCategories[]>([]);
  const [listUserPermissions, setUserPermission] = useState<UserPermissionPayload[]>([]);

  const defaultPermissions = useMemo((): UserPermission[] | UserPermissionPayload[] => {
    if (
      !userId &&
      scope === FORM_SCOPE_TYPE.COMPANY &&
      REACT_APP_DEFAULT_COMPANY_FILES_PERMISSION &&
      !initialPermissions?.length
    ) {
      return JSON.parse(REACT_APP_DEFAULT_COMPANY_FILES_PERMISSION);
    }
    return initialPermissions;
  }, [initialPermissions, scope, userId]);

  const { shouldDisableCurrentItem, shouldDisableParent } = useDisabledManage({
    userId,
    currentUser,
    currentData: data,
    disabled,
  });

  const permissionActions: Record<
    FILE_PERMISSION_ID,
    (item: ListCategories, check: boolean) => void
  > = {
    [FILE_PERMISSION_ID.CREATE]: (item, check) => {
      item.hasAddPermission = check;
      if (check) {
        item.hasViewPermission = check;
      }
    },
    [FILE_PERMISSION_ID.DELETE]: (item, check) => {
      item.hasDeletePermission = check;
    },
    [FILE_PERMISSION_ID.READ]: (item, check) => {
      item.hasViewPermission = check;
    },
  };

  const currentPermissionValue: Record<FILE_PERMISSION_ID, (item: ListCategories) => boolean> = {
    [FILE_PERMISSION_ID.CREATE]: (item) => {
      return item.hasAddPermission;
    },
    [FILE_PERMISSION_ID.DELETE]: (item) => {
      return item.hasDeletePermission;
    },
    [FILE_PERMISSION_ID.READ]: (item) => {
      return item.hasViewPermission;
    },
  };

  const createUserPermissionPayload = (
    id: string,
    permission: FILE_PERMISSION_ID,
  ): UserPermissionPayload => {
    return { scope: id, permissionId: permission };
  };

  const processChildren = useCallback((category: ListCategories): UserPermissionPayload[] => {
    let permissions: UserPermissionPayload[] = [];

    if (category.hasAddPermission) {
      permissions.push(createUserPermissionPayload(category.id, FILE_PERMISSION_ID.CREATE));
    }
    if (category.hasViewPermission) {
      permissions.push(createUserPermissionPayload(category.id, FILE_PERMISSION_ID.READ));
    }
    if (category.hasDeletePermission) {
      permissions.push(createUserPermissionPayload(category.id, FILE_PERMISSION_ID.DELETE));
    }

    return permissions;
  }, []);

  const handlePayloadPermissions = useCallback((): void => {
    if (data.length === 0) return;
    let permissions: UserPermissionPayload[] = [];
    data.forEach((category) => {
      let childPermissions: UserPermissionPayload[] = [];
      category.children.forEach((child) => {
        childPermissions = childPermissions.concat(processChildren(child));
      });
      if (childPermissions.length) {
        permissions = [
          ...permissions,
          createUserPermissionPayload(category.id, FILE_PERMISSION_ID.READ),
          ...childPermissions,
        ];
      }
    });
    setUserPermission(permissions.flat());
  }, [data, processChildren]);

  const handleItemPermission = (parentId: string, id: string, type: FILE_PERMISSION_ID): void => {
    const updatedData = data.map((blockPermission) => {
      if (parentId === blockPermission.id) {
        const children = blockPermission.children.map((item) => {
          if (item.id === id) {
            const action = permissionActions[type];
            const currentPermission = currentPermissionValue[type](item);
            if (action && !shouldDisableCurrentItem(item.id, type)) {
              action(item, !currentPermission);
            }
          }
          return item;
        });
        blockPermission.children = children;
      }

      return blockPermission;
    });

    setData(updatedData);
  };

  const handleBlockPermission = (id: string, type: FILE_PERMISSION_ID, check: boolean): void => {
    const updatedData = data.map((blockPermission) => {
      if (id === blockPermission.id) {
        const children = blockPermission.children.map((item) => {
          const action = permissionActions[type];
          if (action && !shouldDisableCurrentItem(item.id, type)) {
            action(item, check);
          }
          return item;
        });

        blockPermission.children = children;
      }

      return blockPermission;
    });

    setData(updatedData);
  };

  const loadPermissions = useCallback(async () => {
    setLoading(true);

    try {
      const response = await filesService.getCategories();
      if (response.length > 0) {
        setData(normalizeCategories(response, defaultPermissions));
      }
    } catch (e) {
      console.error(e);
    } finally {
      handlePayloadPermissions();
      setLoading(false);
    }
  }, [handlePayloadPermissions, defaultPermissions]);

  useEffect(() => {
    if (!data.length) {
      loadPermissions();
    }
    if (data) {
      handlePayloadPermissions();
    }
  }, [data, handlePayloadPermissions, loadPermissions]);

  return {
    loading,
    data,
    listUserPermissions,
    handleBlockPermission,
    handleItemPermission,
    shouldDisableCurrentItem,
    shouldDisableParent,
  };
};

export default useFileCategoryPermissions;
