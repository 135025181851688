export const ALLOWED_EXTENSIONS_MOVE = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'pdf',
  'doc',
  'docx',
  'txt',
  'tsv',
];
export const ALLOWED_EXTENSIONS_ATTENDANCE = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'pdf',
  'doc',
  'docx',
  'txt',
  'tsv',
];
