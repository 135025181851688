export enum MOVE_FILE_TYPE {
  CREATION = 'CREATION',
  UPDATE = 'UPDATE',
  REMOVAL = 'REMOVAL',
}

export enum BENEFICIARY_OWNERSHIP {
  HOLDER = 'TITULAR',
  DEPENDENT = 'DEPENDENTE',
}

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
}

export enum MARITAL_STATUS {
  SINGLE = 'SOLTEIRO',
  MARRIED = 'CASADO',
  WIDOWER = 'VIUVO',
  OTHERS = 'OUTROS',
}

export enum COMPANY_LINK {
  EMPLOYEE = 'FUNCIONARIO',
  PARTNER = 'SOCIO',
  INTERN = 'ESTAGIARIO',
  APPRENTICE = 'APRENDIZ',
  TEMPORARY = 'TEMPORARIO',
  NO_LINK_IDENTIFICATION = 'SEM IDENTIFICACAO DE VINCULO',
}

export enum REMOVAL_UNIT_MOVE_REASON {
  RETIREMENT = 'APOSENTADORIA',
  DISMISSAL_WITH_JUST_CAUSE = 'DEMISSAO COM JUSTA CAUSA',
  DISMISSAL_WITHOUT_JUST_CAUSE = 'DEMISSÃO SEM JUSTA CAUSA',
  WITHDRAWAL = 'DESISTENCIA',
  DUPLICITY = 'DUPLICIDADE',
  DEATH = 'FALECIMENTO',
  NON_PAYMENT = 'INADIPLENCIA',
  PORTABILITY = 'PORTABILIDADE',
}

export enum KINSHIP_DEGREE {
  SPOUSE = 'CONJUGE',
  CHILD = 'FILHO',
  MOTHER = 'MAE',
  DAD = 'PAI',
  FATHER_IN_LAW = 'SOGRO',
  MOTHER_IN_LAW = 'SOGRA',
  PUPIL = 'TUTELADO',
  OTHERS = 'OUTROS',
}

export enum BANK_ACCOUNT_TYPE {
  INDIVIDUAL_CHECKING_ACCOUNT = 'CONTA CORRENTE INDIVIDUAL',
  JOINT_CHECKING_ACCOUNT = 'CONTA CORRENTE CONJUNTA',
  INDIVIDUAL_SAVINGS_ACCOUNT = 'CONTA POUPANCA INDIVIDUAL',
  JOINT_SAVINGS_ACCOUNT = 'CONTA POUPANCA CONJUNTA',
}

export enum MOVE_FILE_COLUMN_NAME {
  COLUNAS = 'Colunas',
  TITULARIDADE = 'Titularidade',
  CODIGO_DO_CONTRATO = 'Código do contrato',
  SUBFATURA_CENTRO_DE_CUSTO = 'Subfatura/Centro de custo',
  NOME = 'Nome',
  DATA_DE_NASCIMENTO = 'Data de nascimento',
  MATRICULA = 'Matrícula',
  CPF = 'CPF',
  CPF_DO_TITULAR = 'CPF do titular',
  SEXO = 'Sexo',
  ESTADO_CIVIL = 'Estado civil',
  DATA_DE_INICIO_DA_VIGENCIA = 'Data de início da vigência',
  NOME_DA_MAE = 'Nome da mãe',
  CEP = 'CEP',
  UF = 'UF',
  LOGRADOURO = 'Logradouro',
  BAIRRO = 'Bairro',
  CIDADE = 'Cidade',
  COMPLEMENTO = 'Complemento',
  NUMERO = 'Número',
  PLANO = 'Plano',
  NOME_DO_PLANO = 'Nome do Plano',
  EMAIL_DO_SEGURADO = 'E-mail do segurado',
  GRAU_DE_PARENTESCO = 'Grau de parentesco',
  MOTIVO_DA_DELECAO = 'Motivo da Deleção',
  DATA_DE_CANCELAMENTO = 'Data de cancelamento',
  CERTIFICADO = 'Certificado',
  OPCAO_PELA_RN_279 = 'Opção pela RN279',
  CODIGO_DO_DEPENDENTE = 'Código do dependente',
  CNS = 'CNS',
  DATA_DE_ADMISSAO = 'Data de admissão',
  CODIGO_DO_ANGARIADOR = 'Código do angariador',
  DATA_DA_ANGARIACAO = 'Data da angariação',
  VINCULO_COM_A_EMPRESA = 'Vínculo com a empresa',
  LOTACAO = 'Lotação',
  DATA_DE_CASAMENTO = 'Data de casamento',
  CODIGO_DA_ELEGIBILIDADE = 'Código da elegibilidade',
  TIPO_DE_CONTA_BANCARIA = 'Tipo de conta bancária',
  BANCO = 'Banco',
  AGENCIA = 'Agência',
  DV_DA_AGENCIA = 'DV da agência',
  CONTA = 'Conta',
  DV_DA_CONTA = 'DV da conta',
}

export const MOVE_FILE_COLUMN_DESCRIPTION: { [key in MOVE_FILE_COLUMN_NAME]: string } = {
  [MOVE_FILE_COLUMN_NAME.COLUNAS]: 'Descrição',
  [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: 'TITULAR\nDEPENDENTE',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: 'Contrato relacionado à vida criada.',
  [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: 'Subfatura relacionada ao contrato.',
  [MOVE_FILE_COLUMN_NAME.NOME]: 'Nome do assegurado',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.MATRICULA]:
    'Matrícula da pessoa na empresa (Se a empresa não tem esse número, o valor é os 6 primeiros dígitos do cpf)',
  [MOVE_FILE_COLUMN_NAME.CPF]: '11 números, sem pontos ou espaços.',
  [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: '11 números, sem pontos ou espaços.',
  [MOVE_FILE_COLUMN_NAME.SEXO]: 'FEMININO\nMASCULINO',
  [MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL]: 'SOLTEIRO\nCASADO\nVIUVO\nOUTROS',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.NOME_DA_MAE]: 'Nome da mãe do assegurado',
  [MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO]:
    'DESISTENCIA\nDEMISSÃO SEM JUSTA CAUSA  \nAPOSENTADORIA\nFALECIMENTO\nDUPLICIDADE\nPORTABILIDADE\nINADIPLENCIA\nDEMISSAO COM JUSTA CAUSA',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.CERTIFICADO]: 'Código identificador do grupo familiar.',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE]:
    'Número identificador do dependente dentro do grupo familiar. Varia de 1 a 99.',
  [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]: 'SIM\nNAO',
  [MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO]:
    'CONJUGE  \nFILHO\nMAE  \nPAI  \nSOGRO  \nSOGRA  \nTUTELADO  \nOUTROS',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE]: 'Obrigatório na inclusão de cônjuge',
  [MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO]: 'E-mail do segurado',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]:
    'FUNCIONARIO \nSOCIO \nESTAGIARIO\nAPRENDIZ\nTEMPORARIO \nSEM IDENTIFICACAO DE VINCULO',
  [MOVE_FILE_COLUMN_NAME.CEP]:
    'Deve ser um CEP válido, preenchido sem separadores ou espaços (Deve ter 8 caracteres)',
  [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: '',
  [MOVE_FILE_COLUMN_NAME.BAIRRO]: 'Até 25 caracteres',
  [MOVE_FILE_COLUMN_NAME.CIDADE]: '',
  [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: '',
  [MOVE_FILE_COLUMN_NAME.NUMERO]: '',
  [MOVE_FILE_COLUMN_NAME.UF]: '2 caracteres',
  [MOVE_FILE_COLUMN_NAME.PLANO]: 'O plano do assegurado (Deve possuir 4 posições)',
  [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: 'Nome do plano',
  [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]:
    'ONTA CORRENTE INDIVIDUAL\nCONTA CORRENTE CONJUNTA\nCONTA POUPANCA INDIVIDUAL\nCONTA POUPANCA CONJUNTA',
  [MOVE_FILE_COLUMN_NAME.BANCO]:
    'Número do banco (https://www.conta-corrente.com/codigo-dos-bancos/)',
  [MOVE_FILE_COLUMN_NAME.AGENCIA]: 'Número da agência',
  [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: 'Dígito verificador da agência',
  [MOVE_FILE_COLUMN_NAME.CONTA]: 'Número da conta',
  [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: 'Dígito verificador da conta',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]:
    'Código da corretora dentro da bradesco (Muda de acordo com a apólice)',
  [MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO]: 'Formato aceito: dd/mm/aaaa',
  [MOVE_FILE_COLUMN_NAME.LOTACAO]: '',
  [MOVE_FILE_COLUMN_NAME.CNS]: 'Cartão Nacional de Saúde',
};

export const MOVE_FILE_COLUMN_EXAMPLE: { [key in MOVE_FILE_COLUMN_NAME]?: string } = {
  [MOVE_FILE_COLUMN_NAME.COLUNAS]: 'Exemplo',
  [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: 'DEPENDENTE',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: '75530',
  [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: 'default',
  [MOVE_FILE_COLUMN_NAME.CPF]: '76730259060',
  [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: '76730259060',
  [MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO]: 'DEMISSAO COM JUSTA CAUSA',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO]: '19/01/2022',
  [MOVE_FILE_COLUMN_NAME.CERTIFICADO]: '123',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE]: '123',
  [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]: 'SIM',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO]: '16/08/1988',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE]: '21/02/1999',
  [MOVE_FILE_COLUMN_NAME.NOME]: 'Emanoel Santos',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO]: '16/08/1988',
  [MOVE_FILE_COLUMN_NAME.MATRICULA]: '767302',
  [MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO]: 'emanoel.santos@gmail.com',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: '14/11/2010',
  [MOVE_FILE_COLUMN_NAME.SEXO]: 'MASCULINO',
  [MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL]: 'CASADO',
  [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: 'SOCIO',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA]: '19/05/2022',
  [MOVE_FILE_COLUMN_NAME.NOME_DA_MAE]: 'Emanoela Santos',
  [MOVE_FILE_COLUMN_NAME.CEP]: '47951002',
  [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: 'Avenida das Nações unidas',
  [MOVE_FILE_COLUMN_NAME.NUMERO]: '11857',
  [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: '6 andar',
  [MOVE_FILE_COLUMN_NAME.CIDADE]: 'São paulo',
  [MOVE_FILE_COLUMN_NAME.BAIRRO]: 'Avenida das Nações unidas',
  [MOVE_FILE_COLUMN_NAME.UF]: '11857',
  [MOVE_FILE_COLUMN_NAME.PLANO]: 'TNQ2',
  [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: 'Plano Bradesco',
  [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]: 'Itaim Bibi',
  [MOVE_FILE_COLUMN_NAME.BANCO]: 'São paulo',
  [MOVE_FILE_COLUMN_NAME.AGENCIA]: 'SP',
  [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: 'TNQ2',
  [MOVE_FILE_COLUMN_NAME.CONTA]: 'CONTA POUPANCA CONJUNTA',
  [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: '237',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]: '0000',
  [MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO]: '0',
  [MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO]: 'CONJUGE',
};

export const MOVE_FILE_COLUMN_REQUIRE: { [key in MOVE_FILE_COLUMN_NAME]?: string } = {
  [MOVE_FILE_COLUMN_NAME.COLUNAS]: 'Obrigatório',
  [MOVE_FILE_COLUMN_NAME.TITULARIDADE]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.CPF]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: 'Sim se for DEPENDENTE',
  [MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.CERTIFICADO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE]: 'Não',
  [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]:
    'Sim se for DEMISSAO SEM JUSTA CAUSA  ou APOSENTADORIA',
  [MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO]: 'Sim se for DEPENDENTE',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE]: 'Sim se for CONJUGE',
  [MOVE_FILE_COLUMN_NAME.NOME]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.MATRICULA]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: 'Sim se for TITULAR',
  [MOVE_FILE_COLUMN_NAME.SEXO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.NOME_DA_MAE]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.CEP]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.NUMERO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: 'Não',
  [MOVE_FILE_COLUMN_NAME.CIDADE]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.BAIRRO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.UF]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.PLANO]: 'Sim se houver código interno do fornecedor',
  [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.BANCO]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.AGENCIA]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.CONTA]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: 'Sim se for a empresa Insper',
  [MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]: 'Sim',
  [MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO]: 'Sim',
};

export const REMOVAL_MOVE_FILE_HEADER = [
  MOVE_FILE_COLUMN_NAME.COLUNAS,
  MOVE_FILE_COLUMN_NAME.TITULARIDADE,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO,
  MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO,
  MOVE_FILE_COLUMN_NAME.CPF,
  MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR,
  MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO,
  MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO,
  MOVE_FILE_COLUMN_NAME.CERTIFICADO,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE,
  MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279,
];

export const CREATION_MOVE_FILE_HEADER = [
  MOVE_FILE_COLUMN_NAME.COLUNAS,
  MOVE_FILE_COLUMN_NAME.TITULARIDADE,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO,
  MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO,
  MOVE_FILE_COLUMN_NAME.NOME,
  MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO,
  MOVE_FILE_COLUMN_NAME.MATRICULA,
  MOVE_FILE_COLUMN_NAME.CPF,
  MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR,
  MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO,
  MOVE_FILE_COLUMN_NAME.SEXO,
  MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL,
  MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA,
  MOVE_FILE_COLUMN_NAME.NOME_DA_MAE,
  MOVE_FILE_COLUMN_NAME.CEP,
  MOVE_FILE_COLUMN_NAME.UF,
  MOVE_FILE_COLUMN_NAME.LOGRADOURO,
  MOVE_FILE_COLUMN_NAME.BAIRRO,
  MOVE_FILE_COLUMN_NAME.CIDADE,
  MOVE_FILE_COLUMN_NAME.COMPLEMENTO,
  MOVE_FILE_COLUMN_NAME.NUMERO,
  MOVE_FILE_COLUMN_NAME.PLANO,
  MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO,
  MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA,
  MOVE_FILE_COLUMN_NAME.BANCO,
  MOVE_FILE_COLUMN_NAME.AGENCIA,
  MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA,
  MOVE_FILE_COLUMN_NAME.CONTA,
  MOVE_FILE_COLUMN_NAME.DV_DA_CONTA,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR,
  MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO,
  MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO,
  MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA,
  MOVE_FILE_COLUMN_NAME.CNS,
  MOVE_FILE_COLUMN_NAME.LOTACAO,
  MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO,
  MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO,
  MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE,
  MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO,
  MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO,
  MOVE_FILE_COLUMN_NAME.CERTIFICADO,
  MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE,
];

export const UPDATE_MOVE_FILE_HEADER = [
  MOVE_FILE_COLUMN_NAME.COLUNAS,
  MOVE_FILE_COLUMN_NAME.TITULARIDADE,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO,
  MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO,
  MOVE_FILE_COLUMN_NAME.NOME,
  MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO,
  MOVE_FILE_COLUMN_NAME.MATRICULA,
  MOVE_FILE_COLUMN_NAME.CPF,
  MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR,
  MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO,
  MOVE_FILE_COLUMN_NAME.SEXO,
  MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL,
  MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA,
  MOVE_FILE_COLUMN_NAME.NOME_DA_MAE,
  MOVE_FILE_COLUMN_NAME.CEP,
  MOVE_FILE_COLUMN_NAME.UF,
  MOVE_FILE_COLUMN_NAME.LOGRADOURO,
  MOVE_FILE_COLUMN_NAME.BAIRRO,
  MOVE_FILE_COLUMN_NAME.CIDADE,
  MOVE_FILE_COLUMN_NAME.COMPLEMENTO,
  MOVE_FILE_COLUMN_NAME.NUMERO,
  MOVE_FILE_COLUMN_NAME.PLANO,
  MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO,
  MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA,
  MOVE_FILE_COLUMN_NAME.BANCO,
  MOVE_FILE_COLUMN_NAME.AGENCIA,
  MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA,
  MOVE_FILE_COLUMN_NAME.CONTA,
  MOVE_FILE_COLUMN_NAME.DV_DA_CONTA,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR,
  MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO,
  MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO,
  MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA,
  MOVE_FILE_COLUMN_NAME.CNS,
  MOVE_FILE_COLUMN_NAME.LOTACAO,
  MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO,
  MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO,
  MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE,
  MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO,
  MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO,
  MOVE_FILE_COLUMN_NAME.CERTIFICADO,
  MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279,
  MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE,
];

const orderColumnsByTemplate = (
  template: MOVE_FILE_COLUMN_NAME[],
  columns: { [key in MOVE_FILE_COLUMN_NAME]?: string },
) => {
  return template.reduce(
    (acc, columnName) => ({
      ...acc,
      [columnName]: columns[columnName],
    }),
    {},
  );
};

export const FIRST_LINES_REMOVAL_MOVE_FILE = [
  orderColumnsByTemplate(REMOVAL_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_DESCRIPTION),
  orderColumnsByTemplate(REMOVAL_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_EXAMPLE),
  orderColumnsByTemplate(REMOVAL_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_REQUIRE),
];

export const FIRST_LINES_CREATION_MOVE_FILE = [
  orderColumnsByTemplate(CREATION_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_DESCRIPTION),
  orderColumnsByTemplate(CREATION_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_EXAMPLE),
  orderColumnsByTemplate(CREATION_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_REQUIRE),
];
export const FIRST_LINES_UPDATE_MOVE_FILE = [
  orderColumnsByTemplate(UPDATE_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_DESCRIPTION),
  orderColumnsByTemplate(UPDATE_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_EXAMPLE),
  orderColumnsByTemplate(UPDATE_MOVE_FILE_HEADER, MOVE_FILE_COLUMN_REQUIRE),
];
