import { BoxInfo, GridGroup } from '@/components';
import React, { Dispatch, SetStateAction } from 'react';
import useCompanyGroupFilters from '@/modules/companyGroup/hooks/useCompanyGroupFilters';
import { COMPANY_GROUP_LIST_FILTERS } from '@/modules/companyGroup/entities/companyGroup.enum';
import { ParamsToFilterCompanyGroups } from '@/modules/companyGroup/entities/companyGroup.interface';

interface FieldOptions {
  type: COMPANY_GROUP_LIST_FILTERS;
  size: number;
}

interface ContractFiltersProps {
  fields: FieldOptions[];
  setFilters: Dispatch<SetStateAction<ParamsToFilterCompanyGroups>>;
  filters: ParamsToFilterCompanyGroups;
}

const CompanyGroupFilters: React.FC<ContractFiltersProps> = ({ fields, setFilters, filters }) => {
  const { fieldsToChoose } = useCompanyGroupFilters({ setFilters, filters });

  return (
    <BoxInfo>
      <GridGroup
        body={fields.map((field) => ({
          ...fieldsToChoose[field.type],
          default: field.size,
        }))}
      />
    </BoxInfo>
  );
};

export default CompanyGroupFilters;
