import { SelectOption } from '@/components/selectValidate';
import { useCallback, useEffect, useMemo } from 'react';
import { ContractItem, ContractItemKey } from './useNegotiationRelationship';

interface UseContractRulesProps {
  setValues: (values: ContractItem) => void;
  benefitsList: SelectOption[];
  providersList: SelectOption[];
  listContracts: SelectOption[];
  listCostCenters: SelectOption[];
  listPlans: SelectOption[];
  values: ContractItem;
}

const useContractRules = ({
  benefitsList,
  providersList,
  listContracts,
  listCostCenters,
  listPlans,
  setValues,
  values,
}: UseContractRulesProps) => {
  const handleContractItemSelect = useCallback(
    (name: ContractItemKey, value: string): void => {
      const properties: ContractItemKey[] = ['providerId', 'contractId', 'costCenterId', 'planId'];

      const resetPropertiesWhenChangeKey: Record<ContractItemKey, ContractItemKey[]> = {
        benefitId: properties.slice(0),
        providerId: properties.slice(1),
        contractId: properties.slice(2),
        costCenterId: properties.slice(3),
        planId: [],
      };

      const newValues = { ...values, [name]: !value ? null : value };

      resetPropertiesWhenChangeKey[name]?.forEach((property: ContractItemKey) => {
        newValues[property] = null;
      });

      setValues(newValues);
    },
    [setValues, values],
  );

  const isDisabledList = (list: SelectOption[]): boolean => {
    return !list.length || list.length === 1;
  };

  const isDisabledBenefitSelect = useMemo(() => {
    return isDisabledList(benefitsList);
  }, [benefitsList]);

  const isDisabledProviderSelect = useMemo(() => {
    return isDisabledList(providersList);
  }, [providersList]);

  const isDisabledContractSelect = useMemo(() => {
    return isDisabledList(listContracts);
  }, [listContracts]);

  const isDisabledCostCenterSelect = useMemo(() => {
    return isDisabledList(listCostCenters);
  }, [listCostCenters]);

  const isDisabledPlanSelect = useMemo(() => {
    return isDisabledList(listPlans);
  }, [listPlans]);

  useEffect(() => {
    if (!values.benefitId && benefitsList.length === 1) {
      handleContractItemSelect('benefitId', benefitsList[0].value as string);
    }
  }, [benefitsList, benefitsList.length, handleContractItemSelect, values.benefitId]);

  useEffect(() => {
    if (!values.providerId && providersList.length === 1) {
      handleContractItemSelect('providerId', providersList[0].value as string);
    }
  }, [handleContractItemSelect, providersList, values.providerId]);

  useEffect(() => {
    if (!values.contractId && listContracts.length === 1) {
      handleContractItemSelect('contractId', listContracts[0].value as string);
    }
  }, [handleContractItemSelect, listContracts, values.contractId]);

  useEffect(() => {
    if (!values.costCenterId && listCostCenters.length === 1) {
      handleContractItemSelect('costCenterId', listCostCenters[0].value as string);
    }
  }, [handleContractItemSelect, listCostCenters, values.costCenterId]);

  useEffect(() => {
    if (!values.planId && listPlans.length === 1) {
      handleContractItemSelect('planId', listPlans[0].value as string);
    }
  }, [handleContractItemSelect, listPlans, values.planId]);

  return {
    isDisabledBenefitSelect,
    isDisabledProviderSelect,
    isDisabledContractSelect,
    isDisabledCostCenterSelect,
    isDisabledPlanSelect,
    handleContractItemSelect,
  };
};

export default useContractRules;
