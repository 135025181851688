import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { AdditionalServicesParams, AdditionalServicesResponse } from '../entities/interfaces';
import { normalizeAdditionalServices } from '../utils/normalize';

class AdditionalServicesService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getAdditionalServices<AdditionalServicesResponse>(
    companyId: string,
    filters: AdditionalServicesParams = {},
  ) {
    const url = `/companies/${companyId}/svas`;
    return this.get<AdditionalServicesResponse>(url, filters, {
      transformResponse: [this.normalizeCollection],
    });
  }

  private normalizeCollection(response: AdditionalServicesResponse) {
    const { page, data, totalPages, total, pageSize } = response;
    const normalized = data.map((item) => normalizeAdditionalServices(item));
    return {
      page,
      totalPages,
      total,
      data: normalized,
      pageSize,
    };
  }
}

export default AdditionalServicesService;
