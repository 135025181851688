import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'vkit/lib/components';
import ButtonType from 'vkit/lib/shared/components/button/types/button.type';
import { FeedbackDialog } from '..';

interface FeedbackActionProps extends Omit<ButtonType, 'onClick'> {}

const FeedbackAction: React.FC<FeedbackActionProps> = (props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const useButtonProps = useMemo(
    (): ButtonType => ({
      solo: true,
      icon: 'message-circle-outline',
      color: props.color || 'light',
      iconColor: props.color || 'light',
      size: 'xLarger',
      ...props,
    }),
    [props],
  );

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const dialogCallback = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    window.addEventListener('feedbackModal', handleOpenDialog);
    return () => {
      window.removeEventListener('feedbackModal', handleOpenDialog);
    };
  });

  return (
    <div title='Clique para adicionar uma opinião sobre o sistema'>
      <FeedbackDialog open={openDialog} fnCallback={dialogCallback} />
      <Button {...useButtonProps} onClick={handleOpenDialog} />
    </div>
  );
};

export default FeedbackAction;
