import { Color } from 'vkit/lib/shared/types';

export enum STATUS {
  NEW = 'Novo',
  OPEN = 'Aberto',
  PENDING = 'Pendente',
  IN_OPERATION = 'Em operação',
  SOLVED = 'Solucionado',
  REFUSED = 'Recusado',
}

export const RH_STATUS_MAP = {
  [STATUS.NEW]: 'Em aberto',
  [STATUS.OPEN]: 'Em aberto',
  [STATUS.PENDING]: 'Em aberto',
  [STATUS.IN_OPERATION]: 'Em análise',
  [STATUS.SOLVED]: 'Solucionado',
  [STATUS.REFUSED]: 'Recusado',
};

export const STATUS_COLOR: Record<STATUS, Color> = {
  [STATUS.IN_OPERATION]: 'colorDefault',
  [STATUS.NEW]: 'colorDefault',
  [STATUS.OPEN]: 'colorDefault',
  [STATUS.PENDING]: 'warning',
  [STATUS.REFUSED]: 'danger',
  [STATUS.SOLVED]: 'success',
};

export const statusOptions = Object.values(STATUS).map((value) => ({
  text: value,
  value,
}));
