import { Divider } from '@/components';
import React from 'react';
import { Pagination } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

export interface ReportPaginationProps {
  showPaginationReport?: boolean;
  pageSize?: number;
  handleChangePageSize?: (pageSize: number) => void;
  page?: number;
  total?: number;
  handleChangePage?: (page: number) => void;
  totalPages?: number;
}

const ReportPagination: React.FC<ReportPaginationProps> = ({
  showPaginationReport,
  pageSize = 10,
  handleChangePageSize,
  page = 1,
  total = 1,
  handleChangePage,
  totalPages = 1,
}) => {
  return (
    <>
      {showPaginationReport && (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px' }}>
            <Pagination
              page={page}
              totalPages={totalPages}
              total={total}
              pageSize={pageSize}
              onChangePage={(page: number) => handleChangePage?.(page)}
              onChangePageSize={(pageSize: number) => handleChangePageSize?.(pageSize)}
              showSelectPageSize={false}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default ReportPagination;
