import { IconSquare, ShowHide } from '@/components';
import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import { Company } from '@/modules/company';
import DialogSwitch from '@/modules/company/components/dialogSwitch/dialogSwitch';
import { useBrokerRoute } from '@/modules/company/hooks/useBrokerRoute';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
import { useHistoryNavigator } from '@/navigation';
import ICONS from '@/shareds/constants/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text } from 'vkit/lib/components';
import { useCurrentUser } from '@/modules/profile';
import { analytics } from '@/shared/telemetry/telemetry';
import { Grid } from 'vkit/lib/context';
import { Selected } from '../../components';

const Switcher: React.FC = () => {
  const { company, setCompany, isMainCompany, statusCompany } = useApplicationContext();
  const { isBrokerRoute } = useBrokerRoute();
  const { propagateCompany } = useCompanyPropagate();
  const { replace } = useHistoryNavigator();

  const useRefDialog = useRef({ open: () => ({}), dismiss: () => ({}) });

  const { isCompanyUser, defaultCompany, canSwitcherCompany, userCompanies, isBrokerUser } =
    useCurrentUser();

  const [switchCompany, setSwitchCompany] = useState<boolean>(false);

  useMemo((): void => {
    if (isCompanyUser) {
      if (!company.id && defaultCompany) {
        setCompany(defaultCompany);

        analytics.addUserIdentityProperties({
          DEFAULT_COMPANY_NAME: defaultCompany.name,
          DEFAULT_COMPANY_CNPJ: defaultCompany.cnpj,
        });

        localStorage.setItem('company', JSON.stringify(defaultCompany));
        window.dispatchEvent(new CustomEvent('loadCompany'));
      }
    }
  }, [isCompanyUser, company.id, defaultCompany, setCompany]);

  const showSelectedCompany = useMemo(() => {
    return !isBrokerRoute && company?.id && !switchCompany;
  }, [company?.id, isBrokerRoute, switchCompany]);

  useEffect(() => {
    if (!isBrokerRoute) {
      return;
    }

    setSwitchCompany(false);
  }, [isBrokerRoute]);

  return (
    <Grid row alignItems='center'>
      <ShowHide absolute transition='slideToDown' visible={showSelectedCompany}>
        <Selected
          name={company?.name}
          showSwitcherButton={canSwitcherCompany}
          handleSwitcher={() => useRefDialog.current.open()}
          isMainCompany={isMainCompany}
          statusCompany={statusCompany}
          showStatusChip={isBrokerUser}
        />
      </ShowHide>

      <ShowHide absolute transition='slideToDown' visible={isBrokerRoute}>
        <Grid alignItems='center' gap={8}>
          <IconSquare iconImageUrl={ICONS.VITTA} />
          <Text title color='colorDefault' value='Vitta corretora' />
        </Grid>
      </ShowHide>

      <DialogSwitch
        companies={userCompanies}
        instance={useRefDialog.current}
        selectedCompanyId={company.id}
        onChangeSelectedCompany={(company: Company) => {
          propagateCompany(company);
          replace(`/companies/${company.id}`);
          useRefDialog.current.dismiss();
        }}
      />
    </Grid>
  );
};

export default Switcher;
