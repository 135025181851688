const removeInvalids = <T = Record<string, any>>(filters: T): Partial<T> =>
  Object.entries(filters || {}).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});

export default removeInvalids;
