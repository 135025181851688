import { Route } from '@/core/entities';
import { useHandleRoutes } from '@/core/hooks/useHandleRoutes';
import { useCurrentUser } from '@/modules/profile';
import { USER_TYPE } from '@/modules/user';
import { telemetryIdentifyUser } from '@/shared/telemetry/telemetry';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

interface RouteRenderProps {
  routes: Route[];
}

const RouteRender: React.FC<RouteRenderProps> = ({ routes }) => {
  const { user } = useCurrentUser();
  const { handledRoutes } = useHandleRoutes({ routes, user });

  telemetryIdentifyUser({
    ...user,
    type: user.type as USER_TYPE
  });

  return <RouterProvider router={createBrowserRouter(handledRoutes)} />;
};

export default RouteRender;
