import { useEffect, useState } from 'react';

import { User } from '@/modules/profile/entities';

const useAuthenticatedUser = () => {
  const [user, setUser] = useState<User>(JSON.parse(localStorage.getItem('user') ?? '{}'));

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return {
    user,
    setUser,
  };
};

export default useAuthenticatedUser;
