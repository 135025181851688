import { STATUS_FILE } from '@/components/fileUpload/types/fileUpload.types';
import React from 'react';
import { Icon, Loader } from 'vkit/lib/components';

interface IconStatusProps {
  status: STATUS_FILE;
}

export const IconStatus: React.FC<IconStatusProps> = ({ status }) => (
  <>
    {status === STATUS_FILE.UPLOADING && <Loader color='colorDefault' />}

    {status === STATUS_FILE.SUCCESS && <Icon name='checkmark-circle-outline' color='success' />}

    {status === STATUS_FILE.ERROR && <Icon name='close-square-outline' color='danger' />}
  </>
);
