import { BoxInfo } from '@/components';
import ContractTable from '@/modules/contract/views/contractTable/contractTable';
import React, { useMemo } from 'react';
import { AttendanceContract } from '@/services/salesforce/attendances';
import ICONS from '@/shareds/constants/icons';

interface InformationContractsProps {
  contracts?: AttendanceContract[];
  isLoading: boolean;
}

const InformationContracts: React.FC<InformationContractsProps> = ({ contracts, isLoading }) => {
  const code = useMemo((): string[] | undefined => {
    if (contracts?.length) {
      return contracts.map((contract) => contract.code);
    }
    return [];
  }, [contracts]);

  return (
    <BoxInfo title='Contratos' iconImageUrl={ICONS.CONTRACT}>
      <ContractTable
        elevation={0}
        filters={{ code }}
        linesLoader={3}
        deactiveFilter={code?.length === 0}
        columns={['code', 'status', 'modelName', 'validFrom', 'validUntil']}
        isLoading={isLoading}
      />
    </BoxInfo>
  );
};

export default InformationContracts;
