import { Route } from '@/core/entities';
import { INITIAL_ROUTE } from '@/core/entities/types/routes.const';
import { loaderOldRoutes, protectedLoader } from '@/core/middleware/loaders';
import Application from '@/core/pages/application/application';

import { redirect } from '@/core/utils/routes.utils';

import attendanceRoutes from '@/modules/attendance/routes';
import auditRoute from '@/modules/audit/routes';
import { Routes as AuthRoutes } from '@/modules/authentication';
import AuthContainer from '@/modules/authentication/pages/authContainer/authContainer';
import beneficiaryRoutes from '@/modules/beneficiary/routes';
import cadastralDataMatchingRoutes from '@/modules/cadastralDataMatching/routes';
import cadastralPositionRoutes from '@/modules/cadastralPosition/routes/routes';
import companyRoute from '@/modules/company/routes';
import cadastraPositionRoutes from '@/modules/companyGroup/routes/routes';
import contractRoutes from '@/modules/contract/routes';
import filesRoute from '@/modules/files/routes';
import usersRoute from '@/modules/user/routes';
import NotFound from '../views/notFound/notFound';

/**
 * Definition of routes, which require permission checks to determine if a user has access.
 */

const Routes: Route[] = [
  ...AuthRoutes,
  /**
   *
   * Remover estas rotas antigas assim que as comunicações enviadas pelo portal forem normalizadas.
   */
  {
    path: '/confirm-email',
    Component: AuthContainer,
    loader: loaderOldRoutes,
  },
  {
    path: '/login',
    Component: AuthContainer,
    loader: loaderOldRoutes,
  },
  {
    path: '/reset-password',
    Component: AuthContainer,
    loader: loaderOldRoutes,
  },
  {
    path: '/totp',
    Component: AuthContainer,
    loader: loaderOldRoutes,
  },
  {
    path: '/',
    Component: Application,
    loader: protectedLoader,
    children: [
      {
        index: true,
        loader: () => {
          return redirect(INITIAL_ROUTE);
        },
      },
      {
        path: '/company',
        loader: () => {
          return redirect(INITIAL_ROUTE);
        },
      },
      ...companyRoute,
      ...cadastraPositionRoutes,
      ...contractRoutes,
      ...filesRoute,
      ...beneficiaryRoutes,
      ...attendanceRoutes,
      ...cadastralDataMatchingRoutes,
      ...cadastralPositionRoutes,
      ...auditRoute,
      ...usersRoute,
      {
        path: '*',
        Component: NotFound,
      },
    ],
  },
];

export { Routes };
