import {
  CheckPassword,
  FormValidate,
  GridGroup,
  ShowHide,
  Space,
  TextFieldValidate,
} from '@/components';
import { BodyForm } from '@/components/formValidate';
import { useCheckConfirmation } from '@/components/publicRoute/hooks/useCheckConfirmation';
import { useHistoryNavigator } from '@/navigation';
import { checkPasswordValid } from '@/utils/checkPassword';
import React, { useEffect } from 'react';
import { Button, Text } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import useAuthConfirmEmail from './hooks/useAuthConfirmEmail';

const AuthConfirmEmail: React.FC = () => {
  const {
    checkConfirmPasswordsValid,
    setData,
    setMessageError,
    useData,
    useLoading,
    useMessageError,
    confirmEmail,
    useDisabledForm,
    setLoading,
  } = useAuthConfirmEmail();

  const { pathname } = window.location;
  const { redirectIfUserNotConfirm } = useCheckConfirmation({
    pathname,
    setLoading,
  });

  const navigate = useHistoryNavigator();

  useEffect(() => {
    redirectIfUserNotConfirm();
  }, [redirectIfUserNotConfirm]);

  const getFields = ({ onBlur, onChange, useErrors, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          default: 100,
          middle: 100,
          component: (
            <Text
              size='medium'
              value={
                'Para confirmar sua conta, digite seu e-mail e cadastre uma senha nos campos abaixo.'
              }
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              icon='email-outline'
              label='E-mail'
              name='email'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Informe seu e-mail'
              required
              useErrors={useErrors}
              useValues={useValues}
              disabled={useDisabledForm || useLoading}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <Text
              size='medium'
              textAlign='left'
              rounded
              value={`
                      <b>Sua senha deve atender aos critérios mínimos de segurança do sistema.</b>
                      <br /><br />
                      Tente criar uma senha intercalando letras maiúsculas, minúsculas,
                      caracteres especiais (como %, # e *) e números, evitar sequências,
                      palavras conhecidas, como nomes de empresas e produtos,
                      e referências de datas (2021, 2022).
                    `}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              icon='lock-outline'
              label='Senha'
              name='newPassword'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Digite uma senha'
              required
              type='password'
              useErrors={useErrors}
              useValues={useValues}
              disabled={useDisabledForm || useLoading}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              icon='lock-outline'
              label='Confirmar senha'
              name='confirmPassword'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Confirme a senha'
              required
              type='password'
              useErrors={useErrors}
              useValues={useValues}
              disabled={useDisabledForm || useLoading}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: <CheckPassword password={useValues.newPassword} />,
        },
      ]}
    />
  );

  return (
    <div
      style={{
        maxWidth: 350,
      }}>
      <FormValidate
        resource={useData}
        fields={{
          email: ['required', 'email'],
          newPassword: ['required', checkPasswordValid],
          confirmPassword: ['required', checkConfirmPasswordsValid],
        }}
        onChangeForm={setData}
        onError={() => setMessageError('Existem alguns campos com preenchimento irregular.')}
        onSubmit={confirmEmail}
        body={getFields}
        footer={
          <Row style={{ padding: '32px 0 0' }}>
            <Button submit full bold loading={useLoading} disabled={useDisabledForm}>
              <Row>Confirmar conta</Row>
            </Button>
            <Space horizontal height={16} />
            <Button
              full
              bold
              invertColor
              disabled={useLoading}
              onClick={() => navigate.push('/auth/login')}>
              <Row>Já tenho um cadastro</Row>
            </Button>
          </Row>
        }
      />
      <ShowHide transition='slideToDown' visible={!!useMessageError}>
        <Text
          value={useMessageError}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          margin='16px 0 0'
          size='medium'
        />
      </ShowHide>
    </div>
  );
};

export default AuthConfirmEmail;
