import restfulService from '@/utils/restful';
import { ObjectType } from '@/shareds/types';

export interface IRestfulBase<T, R> {
  create: (params: T, url?: string) => Promise<R>;
  find: (params: Partial<T>, url?: string) => Promise<any>;
}

export const restfulBase = <T = ObjectType, R = ObjectType>(
  service: string,
): IRestfulBase<T, R> => {
  const { REACT_APP_API_AUTH_URL = '' } = process.env;

  const api = restfulService({
    service,
    uri: REACT_APP_API_AUTH_URL,
  });
  return {
    create: async (params: T, url?: string): Promise<R> => {
      const response = await api.post(params, url);
      return response?.data || response;
    },
    find: async (params: Partial<T>, url?: string): Promise<any> => {
      const response = await api.get(params, url);
      return response?.data || response;
    },
  };
};

export default restfulBase;
