import React, { useMemo } from 'react';
import { Select } from 'vkit/lib/components';

import { OWNERSHIP_TRANSLATED } from '../../entities';

interface SelectOwnershipProps {
  onChange: (value: string) => void;
  value: string;
}

const SelectOwnership: React.FC<SelectOwnershipProps> = ({ onChange, value }) => {
  const selectableOwnerShip = useMemo(() => {
    return Object.entries(OWNERSHIP_TRANSLATED).map(([key, val]) => ({
      text: val,
      value: key,
    }));
  }, []);

  return (
    <Select
      data={selectableOwnerShip}
      label='Tipo'
      value={value}
      onChange={onChange}
      small
      clearable
    />
  );
};

export default SelectOwnership;
