import { Confirmation } from '@/modules/user/entities/confirmation';
import ConfirmationService from '@/modules/user/services/confirmationService';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useState } from 'react';

interface UseUserConfirmationHistoryProps {
  userId: string;
}

const confirmationService = new ConfirmationService();

const useUserConfirmationHistory = ({ userId }: UseUserConfirmationHistoryProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<Confirmation[]>([]);

  const loadHistory = useCallback(async () => {
    if (!userId) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await confirmationService.getConfirmations({
        userId,
        include: 'createdByUser',
        sort: '-createdAt',
        pageSize: 100,
      });
      setHistory(data);
    } catch (error) {
      dispatchToast({
        text: 'Falha ao buscar o histórico de confirmações deste usuário',
      });
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  return {
    loading,
    history,
  };
};

export default useUserConfirmationHistory;
