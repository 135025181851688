import { LifeGender } from '../types';

export enum LIFE_CONTACT_TYPE {
  EMAIL = 'email',
  PHONE = 'phone',
}

export const LIFE_CONTACT_TYPE_LABEL = Object.freeze({
  [LIFE_CONTACT_TYPE.EMAIL]: 'E-mail',
  [LIFE_CONTACT_TYPE.PHONE]: 'Telefone',
});

export enum LIFE_DOCUMENT_TYPE {
  CPF = 'cpf',
  RG = 'rg',
  CARTAO_SUS = 'cartao_sus',
}

export enum GENDER {
  MALE = 'Masculino',
  FEMALE = 'Feminino',
}

export const GENDER_TRANSLATE: Record<LifeGender, string> = {
  Male: GENDER.MALE,
  male: GENDER.MALE,
  M: GENDER.MALE,
  m: GENDER.MALE,
  Female: GENDER.FEMALE,
  female: GENDER.FEMALE,
  F: GENDER.FEMALE,
  f: GENDER.FEMALE,
};

export const MARITAL_TRANSLATE: Record<string, string> = {
  single: 'Solteiro(a)',
  married: 'Casado(a)',
  divorced: 'Divorciado(a)',
  widowed: 'Viúvo(a)',
  'stable-relationship': 'Relacionamento estável',
};
