import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import {
  ConfirmationResponse,
  ConfirmationSearchParams,
  ResendConfirmationPayload,
} from '../entities/confirmation';

class ConfirmationService extends HttpClientAdapter {
  constructor() {
    super('AUTH');
  }

  getConfirmations<T = ConfirmationResponse>(params?: ConfirmationSearchParams) {
    return this.get<T>('/auth/email-confirmations', params);
  }

  resendConfirmation(params: ResendConfirmationPayload) {
    return this.post<Promise<void>>('/auth/email-confirmations/sends', params);
  }
}

export default ConfirmationService;
