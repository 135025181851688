import { AdditionalServices, AdditionalServicesNormalized } from './../entities/interfaces';
import { getFormattedDate } from '@/utils/format';

export const normalizeAdditionalServices = (
  data: AdditionalServices,
): AdditionalServicesNormalized => {
  return {
    ...data,
    validUntil: getFormattedDate(data.validUntil || '', { showHours: false }),
    validFrom: getFormattedDate(data.validFrom || '', { showHours: false }),
  };
};
