import { Tooltip } from '@/components';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Button } from 'vkit/lib/components';

const ButtonGoToUpload: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { getFirstCompanyConfigByKey } = useCompany();
  const layoutType = getFirstCompanyConfigByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);

  return (
    <Tooltip
      title={
        layoutType
          ? undefined
          : 'Esta empresa não possui as configurações necessárias para habilitar o envio de bate cadastral.'
      }>
      <Button
        label='Enviar arquivo'
        iconColor='light'
        icon={ICONS.UPLOAD}
        disabled={!layoutType}
        onClick={() => navigate.push('actions/upload')}
      />
    </Tooltip>
  );
};

export default ButtonGoToUpload;
