import { EventLog, EventLogParams } from '@/modules/audit/entities';
import { AuditService } from '@/modules/audit/services';
import { BasePagination } from '@/shared/entities';
import { removeEmptyParams } from '@/utils';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useAuditRecords = (params: EventLogParams) => {
  const eventTimeLTEKey = 'eventTime.LTE';
  const eventTimeGteKey = 'eventTime.GTE';

  const [isLoading, setLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<BasePagination>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });

  const [logs, setLogs] = useState<EventLog[]>([]);

  const auditService = useMemo(() => new AuditService(), []);

  const showTable = useMemo(() => {
    return logs.length || isLoading;
  }, [isLoading, logs.length]);

  const isEmptyLogs = useMemo(() => {
    return logs.length === 0 && !isLoading;
  }, [isLoading, logs.length]);

  const normalizeParamsToFilter = useCallback((queryParams: EventLogParams) => {
    const params = removeEmptyParams(queryParams);

    if (params[eventTimeLTEKey]) {
      params[eventTimeLTEKey] = new Date(`${params[eventTimeLTEKey]} 23:59:59`);
    }

    if (params[eventTimeGteKey]) {
      params[eventTimeGteKey] = new Date(`${params[eventTimeGteKey]} 00:00:00`);
    }

    return params;
  }, []);

  const loadEvents = useCallback(
    async (queryParams: EventLogParams) => {
      if (isEmpty(queryParams)) {
        return;
      }
      setLoading(true);

      try {
        const { data, page, pageSize, total, totalPages } = await auditService.getEventLogs(
          normalizeParamsToFilter(queryParams),
        );
        setLogs(data);
        setPagination({
          page,
          pageSize,
          total,
          totalPages,
        });
      } catch (e) {
        dispatchToast({
          text: 'Ops! Não conseguimos buscar os eventos de auditoria',
        });
        setLogs([]);
        setPagination({
          page: 1,
          pageSize: 10,
          total: 0,
          totalPages: 0,
        });
      } finally {
        setLoading(false);
      }
    },
    [auditService, normalizeParamsToFilter],
  );

  useEffect(() => {
    if (isEmpty(params)) {
      return;
    }
    loadEvents(params);
  }, [loadEvents, params]);

  return {
    isLoading,
    pagination,
    logs,
    isEmptyLogs,
    showTable,
  };
};

export default useAuditRecords;
