import React, { ReactNode } from 'react';
import { Tooltip } from 'vkit/lib/context';
import { MOVE_CUSTOM_SHEET } from '../../entities';

interface WrapperTransformedDataActionProps {
  children: ReactNode;
  type: `${MOVE_CUSTOM_SHEET}`;
  count: number;
}

const WrapperTransformedDataAction: React.FC<WrapperTransformedDataActionProps> = ({
  type,
  children,
  count,
}) => {
  const getMessage = () => {
    return type === MOVE_CUSTOM_SHEET.TRANSFORMED
      ? 'Não foi gerado nenhum dado transformado'
      : 'Não foi gerado nenhum dado de observação';
  };
  const tooltipMessage = count === 0 ? getMessage() : undefined;

  return <Tooltip title={tooltipMessage}>{children}</Tooltip>;
};

export default WrapperTransformedDataAction;
