import { BoxInfo } from '@/components';
import useBeneficiaries from '@/modules/beneficiary/hooks/useBeneficiaries/useBeneficiaries';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';

import React, { useMemo, useState } from 'react';
import { DataTable } from 'vkit/lib/components';
import { DataTableAction } from 'vkit/lib/components/dataTable/types/dataTableAction.types';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { BeneficiaryNormalized, BeneficiarySearchParams } from '../../entities';
import Status from '../status/Status';

interface DependentsProps {
  isHolder?: boolean;
  beneficiaryId?: string;
  handleChangeTabs: (value: number) => void;
}

const RelatedBeneficiaries: React.FC<DependentsProps> = ({
  beneficiaryId,
  handleChangeTabs,
  isHolder,
}) => {
  const { companyId } = useHistoryParams();
  const navigate = useHistoryNavigator();
  const [searchParams] = useState<BeneficiarySearchParams>({
    companyId,
    pageSize: 30,
    id: !isHolder ? beneficiaryId : undefined,
    'beneficiaryHolder.holderId': isHolder ? beneficiaryId : undefined,
  });
  const { beneficiaries, isLoading } = useBeneficiaries(searchParams);

  const getStatus = ({ status }: BeneficiaryNormalized) => <Status label={status} />;

  const getColumns: DataTableColumn[] = [
    {
      name: 'NOME',
      path: 'life.name',
    },
    {
      name: 'CPF',
      path: 'life.cpf',
    },
    {
      name: 'CARTEIRINHA',
      path: 'healthCardNumber',
    },
    {
      name: 'STATUS',
      path: 'status',
      format: getStatus,
    },
  ];

  const action: DataTableAction = {
    items: (data: any) => [
      {
        outlined: true,
        solo: true,
        size: 'medium',
        label: 'Ver',
        onClick: () => {
          navigate.push(`/companies/${companyId}/beneficiaries/${data?.id}/details`);
          handleChangeTabs(0);
        },
      },
    ],
  };

  const title = isHolder ? 'Dependentes' : 'Titular';

  const isEmptyDescription = `Nenhum ${isHolder ? 'dependente' : 'titular'} encontrado`;

  const getFilteredBeneficiaries = useMemo(() => {
    if (!beneficiaries.length || !beneficiaryId) {
      return [];
    }
    if (isHolder) {
      return beneficiaries.filter((beneficiary) => beneficiary.id !== beneficiaryId);
    }
    return beneficiaries;
  }, [beneficiaries, beneficiaryId, isHolder]);

  return (
    <BoxInfo title={title} icon='people-outline'>
      <DataTable
        columns={getColumns}
        isLoading={isLoading}
        emptyDescription={isEmptyDescription}
        action={action}
        showPagination={false}
        elevation={0}
        outlined={false}
        data={getFilteredBeneficiaries}
      />
    </BoxInfo>
  );
};

export default RelatedBeneficiaries;
