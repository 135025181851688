import { Column } from '@/components/dataTable';
import { ContentListLines } from '@/components/dataTable/components';
import { ObjectType } from '@/shareds/types';
import React from 'react';

export interface GroupItemContentProps {
  columns: Column[];
  list: ObjectType[];
  contentLinesLoader: number;
  isLoadingContent?: boolean;
}

export const GroupItemContent: React.FC<GroupItemContentProps> = ({
  columns,
  list,
  contentLinesLoader = 3,
  isLoadingContent,
}) => {
  return (
    <ContentListLines
      columns={columns}
      linesLoader={contentLinesLoader}
      loading={isLoadingContent}
      data={list}
    />
  );
};
