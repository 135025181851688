import useCompany from '@/shared/hooks/useCompany/useCompany';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Benefit, useNegotiations } from '@/modules/negotiation';

import { BeneficiaryNormalized } from '../../entities';
import { BeneficiaryService } from '../../services';
import { isRemovableBeneficiary } from '../../utils';

const beneficiaryService = new BeneficiaryService();

const useBeneficiary = (id: string) => {
  const { company } = useCompany();
  const { getBenefitByNegotiationId, negotiationsById } = useNegotiations();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [beneficiary, setBeneficiary] = useState<BeneficiaryNormalized>();

  const loadBeneficiary = useCallback(async (companyId: string, beneficiaryId: string) => {
    if (!companyId || !beneficiaryId) {
      return;
    }

    setLoading(true);

    try {
      const response = await beneficiaryService.getBeneficiary<BeneficiaryNormalized>(
        companyId,
        beneficiaryId,
      );
      setBeneficiary(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const benefit = useMemo((): Benefit | undefined => {
    if (beneficiary?.negotiationId) {
      return getBenefitByNegotiationId(beneficiary?.negotiationId);
    }
  }, [beneficiary?.negotiationId, getBenefitByNegotiationId]);

  const negotiation = useMemo(() => {
    if (beneficiary?.negotiationId) {
      return negotiationsById[beneficiary?.negotiationId];
    }
  }, [beneficiary?.negotiationId, negotiationsById]);

  const isRemovable = useMemo((): boolean => {
    if (negotiation && beneficiary) {
      return isRemovableBeneficiary(beneficiary, negotiation);
    }
    return false;
  }, [beneficiary, negotiation]);

  useEffect(() => {
    if (!company.id || !id) {
      return;
    }
    loadBeneficiary(company.id, id);
  }, [company.id, id, loadBeneficiary]);

  return {
    isLoading,
    beneficiary,
    benefit,
    negotiation,
    isRemovable,
  };
};

export default useBeneficiary;
