import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { CompanyListResponse, CompanyTreeResponse, ParamsToFilterCompanies } from '../entities';

class CompanyService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getCompaniesTree<T = CompanyTreeResponse>(companyIds: string[]) {
    return this.get<T>('/companies', {
      withTree: true,
      id: companyIds,
    });
  }

  getCompanies<T = CompanyListResponse>(filters?: Partial<ParamsToFilterCompanies>) {
    return this.get<T>('/companies', filters);
  }
}

export default CompanyService;
