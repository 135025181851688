import UserService from '@/modules/user/services/userService';
import { useCallback, useEffect, useState } from 'react';

import { Permission } from '@/modules/profile/entities/permission';
import { User } from '@/modules/profile/entities/user';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { isString } from 'lodash';

const userService = new UserService();

const useLoggedUser = () => {
  const { company } = useCompany();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const loadUser = useCallback(
    async (companyId: string) => {
      if (!companyId) {
        return;
      }

      setIsLoading(true);

      try {
        const userFound = await userService.getMe();
        const permissions = await userService.getUserPermissions(userFound.id);

        setUser({
          ...userFound,
          userPermissions: permissions || [],
        });
        setPermissions(permissions);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );

  const hasScopedPermission = (scopeId: string, permissionId: string): boolean => {
    const scopedPermissons = permissions.filter((permission) => isString(permission.scope));
    return scopedPermissons.some(
      (permission) => permission.scope === scopeId && permission.permissionId === permissionId,
    );
  };

  const hasPermission = (permissionId: string): boolean => {
    return permissions.some((permission) => permission.permissionId === permissionId);
  };

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadUser(company.id);
  }, [company.id, loadUser]);

  return {
    isLoading,
    user,
    permissions,
    hasScopedPermission,
    hasPermission,
  };
};

export default useLoggedUser;
