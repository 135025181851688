import { contactService } from '@/modules/company/services';
import { useCallback, useState } from 'react';
import { Contact } from '../../entities';

const useContact = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<Contact>();

  const loadContact = useCallback(async (companyId: string) => {
    if (!companyId) {
      return;
    }

    setLoading(true);

    try {
      const contact = ((await contactService(companyId).list({ companyId })) as Contact[]).find(
        (contact) => contact.isMainContact,
      );
      setContact(contact);
    } catch (e) {
      console.error(e);

      const toastEvent = new CustomEvent('toast', {
        detail: {
          icon: 'close-circle-outline',
          title: 'Oops',
          text: 'Ops! Devido a um erro, não conseguimos buscar as informações do contato da empresa.',
          type: 'danger',
          align: 'right',
          titleColor: 'danger',
          position: 'bottom',
          shadow: true,
          duration: 10,
        },
      });

      window.dispatchEvent(toastEvent);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    loadContact,
    contact,
  };
};

export default useContact;
