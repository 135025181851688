import React from 'react';

import { useHistoryParams } from '@/navigation';

import { Information, Report } from '../../components';

const Details: React.FC = () => {
  const { moveId } = useHistoryParams();

  return (
    <Information moveId={moveId}>
      <Report moveId={moveId} />
    </Information>
  );
};

export default Details;
