/**
 * Enumeration for ownership types.
 * Represents the possible ownership values: HOLDER and DEPENDENT.
 */
export enum OWNERSHIP {
  HOLDER = 'HOLDER',
  DEPENDENT = 'DEPENDENT',
}

/**
 * Translated version of ownership types.
 * Represents the translated strings for each ownership value.
 */
export enum OWNERSHIP_TRANSLATED {
  HOLDER = 'Titular',
  DEPENDENT = 'Dependente',
}
