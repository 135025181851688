import restfulBase from '@/services/accessControl/base';

export interface AuthLogin {
  email: string;
  password: string;
}

export const authLoginInitState = {
  email: '',
  password: '',
};

export const authLoginMessageErrorByMessageErrorRequest = {
  Unauthorized: 'E-mail ou/e senha inválido.',
  'Invalid credentials': 'E-mail ou/e senha inválido.',
};

export const authLoginService = restfulBase<AuthLogin>('auth/login');
