import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import { stringArrayToText } from '@/utils/arrayToText/stringArrayToText';

const errorDescriptions: Record<string, (error: MovesValidationErrorDetail) => string> = {
  unique: ({ fields }: MovesValidationErrorDetail) => {
    const regexToSearchLineAndFieldName = /^(\d+)\.(.+)/; // <linha>.<Nome do campo>
    const templateToReplace = '$1 na coluna "$2"'; // <linha> na coluna <Nome do campo>
    const linesAndFieldNames = fields.map(field => field.replace(regexToSearchLineAndFieldName, templateToReplace));
    return `As linhas ${stringArrayToText(linesAndFieldNames)}, contém o mesmo valor; o que não é permitido`;
  }
}

export const getErrorDescription = (error: MovesValidationErrorDetail) => {
  return errorDescriptions[error.rule]?.(error) || '';
}
