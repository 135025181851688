import { Space } from '@/components';
import { CADASTRAL_DATA_MATCHING_UPLOAD_STEPS } from '@/modules/cadastralDataMatching/entities/cadastralDataMatching.enum';
import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames, getLines } from '@/pages/moves/upload/utils/utils';
import {
  MovesValidationError,
  MovesValidationErrorDetail,
  VALIDATION_ERROR_TYPE,
} from '@/services/files/endpoints/moves';
import { GroupProps } from '@/shared/components/report/components/group/group';
import Report from '@/shared/components/report/report';
import { LIST_INITIAL_PAGE } from '@/shared/entities/constants/list';
import translateRulesValidator from '@/utils/translateRulesValidator';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface ErrosProps {
  errors: MovesValidationError;
  setCurrentStep: (step: CADASTRAL_DATA_MATCHING_UPLOAD_STEPS) => void;
}

const Errors: React.FC<ErrosProps> = ({ errors, setCurrentStep }) => {
  const countErrorsByPage = 10;
  const [pageByGroupKey, setPageByGroupKey] = useState<Record<string, number>>({});

  const getValue = ({ value }: MovesValidationErrorDetail) => (
    <p style={{ color: '#ff413a' }}>{value?.toString() || '-'}</p>
  );

  const columns = [
    {
      title: 'Linha',
      path: 'field',
      custom: getLines,
    },
    {
      title: 'Coluna',
      path: 'field',
      custom: getFieldNames,
    },
    {
      title: 'Valor encontrado',
      path: 'value',
      custom: getValue,
    },
    {
      title: 'Valor esperado/Problema',
      custom: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
        translateRulesValidator(movesValidationErrorDetail.rule, movesValidationErrorDetail.details?.expected)
        || getErrorDescription(movesValidationErrorDetail),
    },
  ];

  const handleChangePage = useCallback((page: number, groupTitleKey?: string) => {
    if (!groupTitleKey) {
      return;
    }

    setPageByGroupKey((currentPages) => ({
      ...currentPages,
      [groupTitleKey]: page,
    }));
  }, []);

  const getFieldNameErrorMessage = useCallback((fieldNameErrors: MovesValidationErrorDetail[]) => {
    const fieldNames = fieldNameErrors.map((fieldNameError) => fieldNameError.fields);
    const lastFieldName = fieldNames.pop();
    const fieldNamesToText = fieldNames.length
      ? `${fieldNames.join(', ')} e ${lastFieldName}`
      : lastFieldName;
    const messageError = `Erro no upload da planilha: Um ou mais nomes de coluna não foram encontrados no layout. 
      Verifique e corrija esse erro para garantir uma importação bem-sucedida. 
      Coluna(s) não encontrada(s): <b style="color: var(--vkit-color-danger)">${fieldNamesToText}</b>.`;

    return (
      <Text
        rounded
        padding='8px'
        color='light'
        applyBaseColor
        size='larger'
        value={messageError}
        margin='0 0 8px'
      />
    );
  }, []);

  const listErrors = useMemo((): GroupProps['data'] => {
    if (!errors.details) {
      return [];
    }

    const errorsByWorksheet = groupBy(errors.details, 'page');

    return Object.keys(errorsByWorksheet).map((groupKey = 'Página única') => {
      const errorsByType = groupBy(errorsByWorksheet[groupKey], 'type');
      const fieldValueErrors = errorsByType[VALIDATION_ERROR_TYPE.FIELD_VALUE] || [];
      const fieldNameErrors = errorsByType[VALIDATION_ERROR_TYPE.FIELD_NAME] || [];
      const total = fieldValueErrors.length;
      const page = pageByGroupKey[groupKey] || LIST_INITIAL_PAGE;
      const startSlice = (page - 1) * countErrorsByPage;
      const endSlice = page * countErrorsByPage;

      return {
        key: groupKey,
        title: groupKey,
        content: {
          top: fieldNameErrors.length ? getFieldNameErrorMessage(fieldNameErrors) : null,
          list: fieldValueErrors.slice(startSlice, endSlice),
        },
        pageSize: countErrorsByPage,
        page,
        total,
        totalPages: Math.ceil(total / countErrorsByPage),
      };
    });
  }, [errors.details, getFieldNameErrorMessage, pageByGroupKey]);

  useEffect(() => {
    if (!errors.details) {
      return;
    }

    const groupPages = groupBy(errors.details, 'page');
    setPageByGroupKey(
      Object.keys(groupPages).reduce(
        (acc, key) => ({
          [key]: LIST_INITIAL_PAGE,
          ...acc,
        }),
        {},
      ),
    );
  }, [errors.details]);

  return (
    <>
      <Text
        applyBaseColor
        color='danger'
        fontWeight='bold'
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size='larger'
        value={`Ops! Não conseguimos processar a sua solicitação de bate cadastral. 
        Detectamos algumas informações incorretas ou ausentes nos campos destacados na tabela abaixo. 
        Por favor, verifique os seguintes campos abaixo e tente novamente.`}
      />

      <Report
        data={listErrors}
        showGroupMarker
        groupMarkerColor='light'
        groupMarkerText='Contém erro(s)'
        columns={columns}
        isExpandedGroup
        showPaginationReport
        handleChangePage={handleChangePage}
      />

      <Space horizontal />

      <Grid alignContent='right'>
        <Button
          label='Enviar novo arquivo'
          onClick={() => setCurrentStep(CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.FORM)}
        />
      </Grid>
    </>
  );
};

export default Errors;
