import ShowHide from '@/components/showHide';
import { ParamsToFilterCompanies } from '@/modules/company';
import React, { useMemo } from 'react';

import useSelectCompanyChips from '@/modules/company/hooks/useSelectCompanyChips/useSelectCompanyChips';
import Multiselect from 'multiselect-react-dropdown';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { useCustomOptionCompany } from '../../hooks/useCustomOptionCompany';

export interface SelectCompanyChipsProps {
  value: string[];
  onChange: (value: string[]) => void;
  maxSelecteds?: number;
  label?: string;
  messageError?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  id?: string;
  required?: boolean;
  filters?: Partial<ParamsToFilterCompanies>;
  noRequestOnSearch?: boolean;
  ['data-testid']?: string;
}

const SelectCompanyChips: React.FC<SelectCompanyChipsProps> = ({
  onChange,
  maxSelecteds,
  label,
  value,
  messageError,
  disabled,
  size = 'medium',
  id,
  required,
  filters,
  noRequestOnSearch,
  'data-testid': testid,
}) => {
  const customOptionCompany = useCustomOptionCompany();
  const {
    selectedCompanies,
    message,
    addSelected,
    removeSelected,
    onSearch,
    loading,
    loadedCompanies,
    getTextFromSelected,
  } = useSelectCompanyChips({
    filters,
    value,
    onChange,
  });

  const labelStyle = useMemo(() => {
    return {
      small: {
        fontSize: 14,
        marginBottom: 0,
      },
      medium: {
        fontSize: 16,
        marginBottom: 10,
      },
      large: {
        fontSize: 18,
        marginBottom: 15,
      },
    }[size];
  }, [size]);

  const minHeight = useMemo(() => {
    return {
      small: 26,
      medium: 48,
      large: 62,
    }[size];
  }, [size]);

  const statusStyleSearchBox = useMemo(() => {
    if (messageError) {
      return {
        borderColor: 'var(--vkit-color-danger)',
        boxShadow: '0 0 0 4px rgba(255, 65, 58, 0.15)',
      };
    }

    return {
      borderColor: 'var(--vkit-color-contrast-9)',
    };
  }, [messageError]);

  return (
    <div data-testid={testid}>
      <Grid column stretch gap={4}>
        <Grid alignContent='justify' alignItems='bottom' stretch>
          {label && (
            <span style={{ ...labelStyle, fontFamily: 'Ubuntu-Bold' }}>
              {label}
              {required && <span style={{ color: 'var(--vkit-color-danger)' }}>*</span>}
            </span>
          )}
          {maxSelecteds ? <Text value={`${value.length} de ${maxSelecteds}`} /> : null}
        </Grid>
        <div style={{ width: '100%' }}>
          <Multiselect
            id={id}
            disable={disabled}
            loading={loading}
            displayValue='name'
            options={loadedCompanies}
            optionValueDecorator={(_value, option) => customOptionCompany({ option })}
            selectedValueDecorator={(_value, option) => getTextFromSelected(option)}
            onSelect={addSelected}
            onRemove={removeSelected}
            selectionLimit={maxSelecteds}
            placeholder='Digite algo para buscar uma empresa'
            emptyRecordMsg={message}
            closeIcon='circle'
            selectedValues={selectedCompanies}
            avoidHighlightFirstOption
            style={{
              searchBox: {
                minHeight,
                padding: 6,
                borderRadius: 5,
                gap: 8,
                display: 'flex',
                flexWrap: 'wrap',
                ...statusStyleSearchBox,
              },
              inputField: {
                color: 'var(--vkit-color-default)',
                margin: '-3px 0',
                flexGrow: 1,
              },
              chips: {
                margin: 0,
                whiteSpace: 'break-spaces',
              },
              optionListContainer: {
                background: 'transparent',
              },
              optionContainer: {
                marginTop: 8,
                background: 'var(--vkit-color-contrast)',
                boxShadow: '0 5px 15px var(--vkit-color-blackless)',
                border: '1px solid var(--vkit-color-contrast-5)',
                width: '100%',
                borderRadius: 9,
                position: 'absolute',
              },
              option: {
                borderBottom: '1px solid var(--vkit-color-contrast-2)',
              },
            }}
            {...(!noRequestOnSearch && { onSearch })}
          />

          <ShowHide visible={messageError} transition='slideToDown'>
            <Text padding='11px 0 0' value={messageError || ''} title size='minor' color='light' />
          </ShowHide>
        </div>
      </Grid>
    </div>
  );
};

export default SelectCompanyChips;
