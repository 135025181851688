import { useMemo, useState, useCallback } from 'react';
import { ParamsToFilter, Contract } from '@/modules/contract/entities/contract';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { removeEmptyParams } from '@/utils';
import { ContractService } from '@/modules/contract';
interface UseContractsLoaderInterface {
  isLoading?: boolean;
  activeTab?: string;
  tabActiveValue?: { status: string };
}

export const useContractsLoader = ({ isLoading, tabActiveValue }: UseContractsLoaderInterface) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contractsByStatus, setContractsByStatus] = useState<Contract[]>([]);
  const [filters, setFilters] = useState<ParamsToFilter>({});
  const [totalLines, setTotalLines] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(isLoading || false);

  const { company } = useCompany();
  const contractService = useMemo(() => new ContractService(), []);
  const data = {
    filters,
    totalLines,
    totalPages,
    contracts: tabActiveValue?.status ? contractsByStatus : contracts,
    loading,
  };

  const loadContracts = useCallback(
    async (updateFilters = {}): Promise<void> => {
      tryOrCatchMessageError(
        async () => {
          if (!company.id) {
            return;
          }

          const filter = removeEmptyParams(updateFilters);
          const { data, total, totalPages } =
            (await contractService.getContracts(company.id, filter)) || {};
          setTotalPages(totalPages);
          setContracts(data || []);
          setTotalLines(total);
        },
        {
          messageErrorDefault: 'Falha ao carregar contratos.',
          tryBefore: () => setLoading(true),
          tryAfter: () => setLoading(false),
        },
      );
    },
    [company.id, contractService],
  );

  const changeStatusTab = useCallback(() => {
    if (tabActiveValue?.status) {
      return setContractsByStatus(
        contracts.filter((contract) => contract.status === tabActiveValue?.status),
      );
    }
  }, [contracts, tabActiveValue]);

  return {
    filters,
    setFilters,
    loadContracts,
    setContracts,
    changeStatusTab,
    data,
  };
};
