import SelectContract from '@/modules/financialAdjustment/components/selectContract';
import { FINANCIAL_ADJUSTMENT_LIST_FILTERS } from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import { ParamsToFilterFinancialAdjustments } from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import SelectFinancialUnit from '@/modules/financialAdjustment/components/selectFinancialUnit/selectFinancialUnit';
import { removeEmptyParams } from '@/utils';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

interface UseFinancialAdjustmentFiltersProps {
  setFilters: Dispatch<SetStateAction<Partial<ParamsToFilterFinancialAdjustments>>>;
  filters: Partial<ParamsToFilterFinancialAdjustments>;
}

const useFinancialAdjustmentFilterFields = ({
  setFilters,
  filters,
}: UseFinancialAdjustmentFiltersProps) => {
  const onChange = useCallback(
    (key: string, value: string | string[]) => {
      setFilters((oldValues) =>
        removeEmptyParams<ParamsToFilterFinancialAdjustments>({
          ...oldValues,
          [key]: value,
        }),
      );
    },
    [setFilters],
  );

  const fieldsToChoose = {
    [FINANCIAL_ADJUSTMENT_LIST_FILTERS.CONTRACT]: {
      component: <SelectContract onChange={onChange} useValues={filters} small />,
    },
    [FINANCIAL_ADJUSTMENT_LIST_FILTERS.FINANCIAL_UNIT]: {
      component: <SelectFinancialUnit useValues={filters} onChange={onChange} small />,
    },
  };

  return {
    fieldsToChoose,
  };
};

export default useFinancialAdjustmentFilterFields;
