export const VALIDATION_MESSAGES: { [key: string]: string } = {
  IsAdmissionDateValid: 'A <strong>[variable]</strong> retroativa não é permitida.',
  IsBankDataRequired: 'Dados bancários são necessários para este número de contrato.',
  IsBirthDateValid:
    'Data de nascimento <i>[value]</i> inválida. Por favor, insira o formato DD/MM/YYYY para uma data válida.',
  IsCPFValid: 'O CPF deve ser válido, contendo apenas dígitos e 11 números.',
  IsDateValid:
    'Data <i>[value]</i> inválida. Por favor, insira o formato DD/MM/YYYY para uma data válida.',
  IsEmailValid: 'E-mail <i>[value]</i> inválido.',
  IsInt: '<strong>[variable]</strong> deve ser um número inteiro.',
  IsMovementTypeValid:
    'Tipo de movimentação <i>[value]</i> não reconhecido. Utilize entre \'CREATE\' e \'DELETE\'.',
  IsNotEmpty: '<strong>[variable]</strong> não deve estar vazio.',
  IsNumberString: '<strong>[variable]</strong> deve ser uma string numérica.',
  IsParentageSpouse: 'Este parâmetro é necessário para parentesco \'cônjuge\'.',
  IsPortalValid: 'Portal não reconhecido. Portais suportados são: \'SSBE\'.',
  IsPositive: '<strong>[variable]</strong> deve ser um número positivo.',
  IsString: '<strong>[variable]</strong> deve ser uma string.',
  IsUppercase: '<strong>[variable]</strong> deve estar em maiúsculas.',
  maxLength: '<strong>[variable]</strong> deve ter no máximo <i>[count]</i> caracteres.',
  minLength: '<strong>[variable]</strong> deve ter no mínimo <i>[count]</i> caracteres.',
  isString: '<strong>[variable]</strong> deve ser um texto.',
  isNotEmpty: '<strong>[variable]</strong> é de preenchimento obrigatório.',
  isNumberString:
    '<strong>[variable]</strong> deve ser preenchido em formato texto mas somente com números.',
  IsCEPValid: '<strong>[variable]</strong> informado não é válido',
};
