import { CardNumber, ShowHide } from '@/components';
import useFamilyGroup from '@/modules/cadastralPosition/hooks/useFamilyGroup';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Grid } from 'vkit/lib/context';

interface ProcessedFamilyGroupsCounterProps {
  cadastralPositionId: string;
}

const ProcessedFamilyGroupsCounter: React.FC<ProcessedFamilyGroupsCounterProps> = ({
  cadastralPositionId,
}) => {
  const { processedFamilyGroupsCounter } = useFamilyGroup({ cadastralPositionId });

  return (
    <ShowHide visible={processedFamilyGroupsCounter.length} transition='slideToDown'>
      <Grid alignContent='justify' gap={16}>
        {processedFamilyGroupsCounter.map(({ count, description }) => (
          <CardNumber
            key={description}
            number={count}
            text={description}
            icon={ICONS.FAMILY_GROUP}
          />
        ))}
      </Grid>
    </ShowHide>
  );
};

export default ProcessedFamilyGroupsCounter;
