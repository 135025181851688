import { get } from 'lodash';
import { ObjectType } from '@/shareds/types';

export interface TreatErrorOptions {
  error: unknown;
  messageDefault?: string;
  messageErrorByMessageErrorRequest?: ObjectType;
  pathMessageRequest?: string;
}

export const getMessageError = ({
  error,
  messageDefault,
  messageErrorByMessageErrorRequest,
  pathMessageRequest = 'response.data.message',
}: TreatErrorOptions): string => {
  const messageErrorRequest = get(error, pathMessageRequest, '');

  const messageErrorKey = Object.keys(messageErrorByMessageErrorRequest || {}).find((key) =>
    new RegExp(key, 'i').test(messageErrorRequest),
  );

  return (
    (messageErrorKey && messageErrorByMessageErrorRequest?.[messageErrorKey]) ||
    messageErrorRequest ||
    messageDefault
  );
};
