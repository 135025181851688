import { BoxInfo, FileUpload, FormValidate, ToastStatusGlobal } from '@/components';
import { EXTENSIONS_BY_MIMETYPE, Mimetype } from '@/components/fileExtensionIcon/utils';
import { STATUS_FILE, StatusFile } from '@/components/fileUpload/types/fileUpload.types';
import { BodyForm } from '@/components/formValidate';
import { messageErrors } from '@/modules/files/constants/errors';
import {
  FILES_ALLOWED_EXTENSIONS,
  LIMIT_CHARACTERS_FILE_NAME,
} from '@/modules/files/constants/files';
import { FileToUpload } from '@/modules/files/entities/fileEntity';
import FilesService from '@/modules/files/services/filesService';
import { checkMaxFileSize } from '@/modules/files/utils';
import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { deepClone } from '@/utils';
import { getMessageError } from '@/utils/getMessageError';
import React, { useState } from 'react';

const filesService = new FilesService();

interface FilesForm {
  files: [];
}

const UploadForm: React.FC = () => {
  const [isLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState({
    files: '',
  });
  const [data, setData] = useState<FilesForm>({ files: [] });
  const onChangeForm = (newData: Partial<FilesForm>): void => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  };
  const navigate = useHistoryNavigator();
  const query = useHistoryQuery();
  const [statusFiles, setStatusFiles] = useState<StatusFile[]>([]);
  const { company } = useCompany();
  const { toast } = ToastStatusGlobal();

  const onChangeStatusFile = (index: number, value: STATUS_FILE, messageError?: string): void =>
    setStatusFiles((status) => {
      status[index] = {
        value,
        messageError,
      };

      return deepClone(status);
    });

  const checkMimetype = (mimetype: Mimetype) => {
    const extension = EXTENSIONS_BY_MIMETYPE[mimetype];
    return FILES_ALLOWED_EXTENSIONS.includes(extension);
  };

  const uploadFile = async (index: number, data: FileToUpload) => {
    let errorMessage = 'Erro ao fazer upload de arquivo.';
    if (!company?.id) {
      return;
    }

    try {
      if (!checkMimetype(data.file.type as Mimetype)) {
        errorMessage = 'Formato incorreto!';
        throw new Error(errorMessage);
      }
      if (!checkMaxFileSize(data.file)) {
        errorMessage = 'Excede tamanho máximo de arquivo';
        throw new Error(errorMessage);
      }

      onChangeStatusFile(index, STATUS_FILE.UPLOADING);
      await filesService.uploadFile(company.id, data);
      onChangeStatusFile(index, STATUS_FILE.SUCCESS);
      return true;
    } catch (error) {
      console.error(error);
      const messageError = getMessageError({
        error,
        messageErrorByMessageErrorRequest: messageErrors,
        messageDefault: errorMessage,
      });
      onChangeStatusFile(index, STATUS_FILE.ERROR, messageError);
      return false;
    }
  };

  const uploadManyFiles = async (files: File[]): Promise<boolean> => {
    let errorExists = false;

    for (const [index, file] of Object.entries(files)) {
      const { value } = statusFiles[Number(index)] || {};
      if (value !== 'success') {
        const created = await uploadFile(Number(index), {
          categoryIds: [query.idCategory],
          file,
        });

        if (!created) {
          errorExists = true;
        }
      }
    }

    return !errorExists;
  };

  const onSubmit = async ({ files }: FilesForm) => {
    if (!files.length) {
      setFieldError({
        files: 'Campo obrigatório',
      });
      return false;
    }

    const success = await uploadManyFiles(files);

    if (!success) {
      const message =
        files.length > 1 ? 'Erro ao fazer upload dos arquivos' : 'Erro ao fazer upload do arquivo';
      toast('Pronto', message, 'error');
      return;
    }

    const message =
      files.length > 1 ? 'Upload dos arquivos foram realizados' : 'Upload do arquivo foi realizado';
    toast('Pronto', message, 'success', undefined, {
      text: 'Clique no botão ao lado para nos contar como está sendo sua experiência com a aplicação.',
      action: {
        label: 'Feedback',
        icon: 'message-circle-outline',
        onClick: () => {
          const feedbackEvent = new CustomEvent('feedbackModal');
          window.dispatchEvent(feedbackEvent);
        },
      },
    });
    navigate.goBack();
  };

  const getFields = ({ useErrors, onBlur, onChange }: BodyForm) => (
    <FileUpload
      label='Upload de arquivos'
      multiple
      statusFiles={statusFiles}
      name='files'
      onChange={(name: string, value: File[], isValid: boolean) => {
        onChange(name, value);
        onBlur(name, value);
        setHasError(!isValid);
        setFieldError({ files: '' });
      }}
      onDelete={(_: File, fileIndex: number) => {
        setStatusFiles((statusList) => {
          statusList.splice(fileIndex, 1);
          return statusList;
        });
      }}
      formValidatorErrors={useErrors}
      allowedExtensions={FILES_ALLOWED_EXTENSIONS}
      maxCharacterLimit={LIMIT_CHARACTERS_FILE_NAME}
      required
    />
  );

  return (
    <BoxInfo>
      <FormValidate
        resource={data}
        onChangeForm={onChangeForm}
        onBack={navigate.goBack}
        onSubmit={onSubmit}
        fields={{
          files: ['required'],
        }}
        errors={fieldError}
        body={getFields}
        buttons={{
          submit: {
            text: 'Concluir',
            loading: isLoading,
            disabled: hasError,
          },
          back: {
            confirm: true,
            text: 'Voltar',
          },
        }}
      />
    </BoxInfo>
  );
};

export default UploadForm;
