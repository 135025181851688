import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { FileParams, FileResponse, FileToUpload } from '../entities/fileEntity';
import { FileCategory } from '../entities/fileCategories';

class FilesService extends HttpClientAdapter {
  constructor() {
    super('FILES');
  }

  getCategories(): Promise<FileCategory[]> {
    return this.get<FileCategory[]>('/categories');
  }

  getFiles(companyId: string, params?: Partial<FileParams>): Promise<FileResponse> {
    const url = `/companies/${companyId}/files`;
    return this.get<FileResponse>(url, params);
  }

  getDownloadToken(companyId: string, idFile: string) {
    return this.get(`/companies/${companyId}/files/${idFile}`);
  }

  deleteFile(companyId: string, idFile: string) {
    return this.delete(`/companies/${companyId}/files/${idFile}`);
  }

  uploadFile(companyId: string, data: FileToUpload) {
    const formData = new FormData();
    formData.append('file', data.file);
    data.categoryIds.forEach((categoryId) => {
      formData.append('categoryIds[]', categoryId);
    });

    return this.post(`/companies/${companyId}/files`, formData);
  }
}

export default FilesService;
