import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { MOVE_LAYOUT_DEFAULT, MoveConfig } from '@/modules/move/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { extractSelectOptions } from '@/utils';
import React from 'react';

import { Select } from 'vkit/lib/components';

import { FormDataSteps } from '../../enum/FormDataSteps';
import { TFormData } from '../../types/FormData.type';

interface OnChangeData {
  text: string;
  value: string | number;
  data?: MoveConfig;
}

interface SelectLayoutProps {
  formData: TFormData;
  setData: (key: string, value: any) => void;
  isLoading: boolean;
  setCompanyMoveConfig: (data: MoveConfig | null) => void;
}

const SelectLayout: React.FC<SelectLayoutProps> = ({
  formData,
  setData,
  isLoading,
  setCompanyMoveConfig,
}) => {
  const { getAllCompanyConfigsByKey } = useCompany();

  const onChange = (value: string, { data }: OnChangeData): void => {
    if (value) {
      setData(FormDataSteps.layout, value);
    }
    setCompanyMoveConfig(data ?? null);
  };

  window.addEventListener('loadCompany', () => {
    if (formData?.provider?.id) {
      setData(FormDataSteps.layout, null);
    }
  });

  const companyConfigs = getAllCompanyConfigsByKey(COMPANY_CONFIG.MOVES_LAYOUT);
  const layoutConfigs = companyConfigs.map(
    (companyConfig) => JSON.parse(companyConfig) as MoveConfig,
  );
  const selectOptions = [
    ...extractSelectOptions(layoutConfigs, 'label', 'layout'),
    {
      text: 'Layout padrão',
      value: MOVE_LAYOUT_DEFAULT,
    },
  ];

  return (
    <Select
      disabled={isLoading || companyConfigs.length === 0}
      data={selectOptions}
      label='Selecione um layout'
      onChange={onChange}
      placeholder='Selecione um layout'
      required
      searchable
      value={formData?.layout || ''}
      customMessageNotFound='Nenhum layout encontrado.'
    />
  );
};

export default SelectLayout;
