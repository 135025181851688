import Detail from '../pages/detail/detail';
import List from '../pages/list/list';
import { Form } from '../views';
import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import { Main } from '@/core/views';
import { ATTENDANCE_ICON } from '../entities';

export enum ROUTES_ATTENDANCE {
  LIST = 'attendance-list',
  DETAIL = 'attendance-detail',
  CREATE = 'attendance-crerate',
}

const Routes: Route[] = [
  {
    path: '/companies/:companyId/attendances',
    Component: Main,
    id: ROUTES_ATTENDANCE.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      nameMenu: 'Atendimentos',
      icon: ATTENDANCE_ICON,
    },
    loader() {
      return createRouteLoaderPageDetails({
        title: 'Atendimentos',
      });
    },
    children: [
      {
        index: true,
        Component: List,
      },
      {
        id: ROUTES_ATTENDANCE.DETAIL,
        Component: Detail,
        path: ':attendanceId',
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Detalhes do atendimento',
            icon: ATTENDANCE_ICON,
            hasGoBackAction: true,
            title: 'Atendimentos | Detalhes',
          });
        },
      },
      {
        id: ROUTES_ATTENDANCE.CREATE,
        Component: Form,
        path: 'actions/create',
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Novo atendimento',
            icon: ATTENDANCE_ICON,
            hasGoBackAction: true,
            title: 'Atendimentos | Solicitação',
          });
        },
      },
    ],
  },
];

export default Routes;
