export enum CADASTRAL_POSITION_ROW_STATUS {
  NOT_INITIALIZED = 'not-initialized',
  VALIDATED = 'validated',
  VALIDATED_WITH_ERROR = 'validated-with-error',
  PROCESSED = 'processed',
  PROCESSED_WITH_ERROR = 'processed-with-error',
  UNKNOWN = 'unknown',
}

export enum CADASTRAL_POSITION_ROW_COLUMNS {
  LINE = 'line',
  LIFE_STATUS = 'life-status',
  BENEFICIARY_STATUS = 'beneficiary-status',
}

export enum MESSAGE_TYPES_ROW {
  SUCCESS = 'success',
  ERROR = 'error',
}
