import { Provider } from '@/services/negotiations/endpoints/providers';
import { Base } from '@/shareds/types/base.type';

export interface Benefit extends Base {
  name: string;
  providers: Provider[];
}

export const BENEFITS_SORT_MAPPING = ['Saúde', 'Odonto', 'Medicamentos'];
export const BENEFIT_BASE_IDS = {
  HEALTH: 1,
  DENTAL: 2,
  MEDICATION: 3,
  LIFE:5,
  HEALTH_TEAM: 6,
};
export const BENEFIT_BASE_IDS_FOR_MOVE = [
  BENEFIT_BASE_IDS.HEALTH,
  BENEFIT_BASE_IDS.DENTAL,
  BENEFIT_BASE_IDS.LIFE,
  BENEFIT_BASE_IDS.HEALTH_TEAM,
];
