import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { MoveSheetNormalized, MoveSheetNormalizedResponse, MoveSheetParams } from '../../entities';
import { MoveSheetService } from '../../services';

const moveService = new MoveSheetService();

const useMoveSheet = (params?: MoveSheetParams) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sheet, setSheet] = useState<MoveSheetNormalized[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);

  const isEmptySheet = useMemo(() => sheet.length === 0 && !isLoading, [isLoading, sheet.length]);

  const showPagination = useMemo(
    () => (pagination?.totalPages ? pagination?.totalPages > 1 : false),
    [pagination],
  );

  const showList = useMemo(() => !isLoading && !!sheet.length, [isLoading, sheet.length]);

  useEffect(() => {
    const fetchData = async (queryParams: MoveSheetParams) => {
      if (!queryParams?.fileId) {
        return;
      }

      setSheet([]);
      setLoading(true);

      try {
        const { data, page, totalPages, total, pageSize } =
          await moveService.getMoveSheet<MoveSheetNormalizedResponse>(queryParams);
        setSheet(data);
        setPagination({
          page,
          totalPages,
          total,
          pageSize,
        });
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! não foi possível buscar os dados de validação desta movimentação.',
        });
      } finally {
        setLoading(false);
      }
    };

    if (isEmpty(params) || !params) {
      return;
    }
    fetchData(params);
  }, [params]);

  return {
    isLoading,
    sheet,
    pagination,
    isEmptySheet,
    showPagination,
    showList,
  };
};

export default useMoveSheet;
