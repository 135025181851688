import fileUploadImage from '@/assets/images/polegar-para-cima.png';
import { BoxInfo } from '@/components';
import { findRoutePathByName } from '@/core/utils';
import { FeedbackAction } from '@/modules/feedback';
import { ROUTE_MOVES } from '@/modules/move/routes/moveRoute';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { Button, Image, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const Success: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { companyId } = useHistoryParams();

  const handleClick = () => {
    const moveRouteFinded = findRoutePathByName(ROUTE_MOVES.LIST);
    if (moveRouteFinded) {
      navigate.push(generatePath(moveRouteFinded, { companyId }));
    }
  };

  return (
    <BoxInfo>
      <Row style={{ alignItems: 'center' }}>
        <Image
          src={fileUploadImage}
          height='90px'
          width='90px'
          type='rounded'
          alt='Ícone de polegar para cima'
        />
        <Text title color='colorDefault' value='Tudo certo' margin='16px 0 8px' />
        <Text
          fontWeight='bold'
          value='A solicitação foi enviada e em alguns minutos uma movimentação será criada,<br />você pode acessar a lista de movimentações para acompanhar o processo.'
          textAlign='center'
          margin='0 0 16px'
          size='medium'
        />

        <Row style={{ alignItems: 'center' }}>
          <Grid alignContent='center' alignItems='center' gap={24}>
            <Text
              value='Caso se interesse, acionando o botão ao lado, <br /> você pode nos contar como está sua experiência no portal.'
              textAlign='center'
            />
            <FeedbackAction label='Feedback' size='small' outlined color='colorDefault' />
          </Grid>
        </Row>

        <Button onClick={handleClick} label='Ir para histórico de movimentações' bold />
      </Row>
    </BoxInfo>
  );
};

export default Success;
