import { analytics } from '@/shared/telemetry/telemetry';
import { VALIDATION_MESSAGES } from '../entities';
import { MOVE_SHEET_TRANSLATE_VALUES } from '../entities/constants/moveSheetValues';

const obterNumeroAntesDeCaracteres = (msg: string): string | null => {
  const regex = /(\d+)\s*characters/g;
  const match = regex.exec(msg);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const translateValidationMessage = (
  messageKey: string,
  field: string,
  value: string | null,
  message: string,
) => {
  let translatedMessage = VALIDATION_MESSAGES[messageKey] || message;
  const hasCounter = obterNumeroAntesDeCaracteres(message);
  if (!VALIDATION_MESSAGES[messageKey]) {
    analytics.track('MOVE_ERROR_TRANSLATE', {
      messageKey,
      field,
      value,
      message,
    });
  }

  if (translatedMessage.includes('[value]') && value) {
    translatedMessage = translatedMessage.replace('[value]', value);
  }

  if (translatedMessage.includes('[variable]')) {
    translatedMessage = translatedMessage.replace('[variable]', field);
  }

  if (translatedMessage.includes('[count]') && hasCounter) {
    translatedMessage = translatedMessage.replace('[count]', hasCounter);
  }

  return translatedMessage;
};

export const translateSheetValues = (key: string, value: any): string => {
  let translate = MOVE_SHEET_TRANSLATE_VALUES[key];

  if (translate) {
    return translate[value];
  }

  return value;
};
