import React, { ReactNode } from 'react';

interface WordBreakProps {
  children: ReactNode;
}

const WordBreak: React.FC<WordBreakProps> = ({ children }) => {
  return <div style={{ wordBreak: 'break-all' }}>{children}</div>;
};

export default WordBreak;
