import { SelectOption } from '@/components/selectValidate';
import { CONTRACT_STATUS, Contract, SELECT_CONTRACT_FILTER } from '@/modules/contract';
import { contractsService } from '@/services/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { extractSelectOptions } from '@/utils';
import { isBefore, parseISO } from 'date-fns';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface UseContractOptions {
  filterKeys?: SELECT_CONTRACT_FILTER[];
  setOriginalContracts?: (contracts: Contract[]) => void;
}

const useContractOptions = ({ filterKeys, setOriginalContracts }: UseContractOptions) => {
  const { company } = useCompany();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<SelectOption[]>([]);
  const filterKeysRef = useRef(filterKeys);

  const extractedFilters = useMemo(() => {
    const filterByKey = {
      [SELECT_CONTRACT_FILTER.STATUS_ACTIVE]: {
        status: 'active',
      },
    };

    return filterKeysRef.current?.reduce(
      (acc, filterKey) => ({
        ...acc,
        ...filterByKey[filterKey],
      }),
      {},
    ) as Record<keyof Contract, string>;
  }, []);

  const extractContractsToForm = (contracts: Contract[]) => {
    return contracts.map((contract) => {
      const isExperied =
        !!contract?.validUntil && isBefore(parseISO(contract.validUntil), new Date());
      const isCancelled = contract?.status === 'cancelled';

      let statusToForm = CONTRACT_STATUS.ACTIVE;

      if (isExperied) {
        statusToForm = CONTRACT_STATUS.EXPIRED;
      }

      if (isCancelled) {
        statusToForm = CONTRACT_STATUS.CANCELLED;
      }

      return {
        ...contract,
        statusToForm,
      };
    });
  };

  const loadContracts = useCallback(
    async (companyId: string, filters?: Record<keyof Contract, string>) => {
      try {
        setLoading(true);

        const response = await contractsService(companyId).listAll(filters);

        const contractsToForm = extractContractsToForm(response);
        const options = extractSelectOptions(contractsToForm, 'code', 'id');
        const sortedOptions = sortBy(options, ['data.statusToForm', 'text']);

        setContracts(sortedOptions);
        setOriginalContracts?.(response);
      } catch (error) {
        console.error(error);

        const toastEvent = new CustomEvent('toast', {
          detail: {
            icon: 'close-circle-outline',
            title: 'Oops',
            text: 'Ops! Erro ao carregar lista de contratos.',
            type: 'danger',
            align: 'right',
            titleColor: 'danger',
            position: 'bottom',
            shadow: true,
            duration: 10,
          },
        });

        window.dispatchEvent(toastEvent);
      } finally {
        setLoading(false);
      }
    },
    [setOriginalContracts],
  );

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadContracts(company.id, extractedFilters);
  }, [company.id, extractedFilters, loadContracts]);

  return {
    isLoading,
    contracts,
  };
};

export default useContractOptions;
