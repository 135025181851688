import restfulBase from '@/services/negotiations/core/base';
import { Base } from '@/shareds/types/base.type';

export interface Benefit extends Base {
  name: string;
  baseId: number;
}

export const benefitInitalState: Benefit = {
  name: '',
  baseId: 0,
};

const apiService = restfulBase('benefits');

export default apiService;
