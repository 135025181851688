import { Unit } from '@/shared/entities';
import { Contract, ContractModel, Rule } from '../entities';

export const MOCK_CONTRACT_MODEL: ContractModel = {
  id: 1,
  createdAt: '2023-05-01T12:00:00Z',
  updatedAt: '2023-05-02T10:30:00Z',
  deletedAt: null,
  deletionReason: null,
  name: 'Mock Contract Model',
};

export const MOCK_CONTRACT_RULE: Rule = {
  type: 'Type A',
  description: 'Rule description for Type A',
};

export const MOCK_CONTRACT: Contract = {
  id: '30464e47-17e1-4d6b-999e-3b92a2a45036',
  createdAt: '2023-05-01T12:00:00Z',
  updatedAt: '2023-05-02T10:30:00Z',
  deletedAt: null,
  deletionReason: null,
  code: 'CONTRACT001',
  companyId: '123456789',
  negotiations: [],
  note: 'This is a mock contract',
  status: 'active',
  type: 'Health',
  validFrom: '2023-05-01',
  validUntil: '2024-04-30',
  reimbursementCoefficient: 0.8,
  copayValue: 20,
  copayUnit: Unit.PERCENTAGE,
  contributionValue: 100,
  contributionUnit: Unit.PERCENTAGE,
  model: MOCK_CONTRACT_MODEL,
  rules: [MOCK_CONTRACT_RULE],
};
