import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

import {
  MOVE_STATUS,
  MOVE_STATUS_COLOR,
  MOVE_STATUS_TRANSLATED,
} from '../../entities/constants/move';
export interface StatusProps {
  label: string;
}

const Status: React.FC<StatusProps> = ({ label }) => (
  <Row style={{ padding: '6px 0px', display: 'flex', width: 'max-content' }}>
    <Chip
      label={label}
      color={MOVE_STATUS_COLOR[label]}
      rounded
      invertColor={label === MOVE_STATUS_TRANSLATED[MOVE_STATUS.NOT_INITIALIZED]}
    />
  </Row>
);

export default Status;
