import { Company } from '@/modules/company';
import RecentCompanies from '@/modules/company/components/recentCompanies/recentCompanies';
import React, { useMemo } from 'react';

import { IconSquare } from '@/components';
import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

import { SelectCompany, SelectCompanyProps } from '../../views';

interface DialogSwitchProps extends SelectCompanyProps {
  instance: {
    open: () => void;
    dismiss: () => void;
  };
  persistent?: boolean;
  companies?: Company[];
}

const DialogSwitch: React.FC<DialogSwitchProps> = ({
  selectedCompanyId,
  onChangeSelectedCompany,
  instance,
  persistent,
  companies,
}) => {
  const companySelectOptions = useMemo(() => {
    return companies?.map((company) => ({
      ...company,
      text: company.name,
      value: company.id,
    }));
  }, [companies]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent={persistent}
      instanceEmitter={instance}
      elevation={2}
      size='medium'
      body={
        <Row>
          <Grid column alignItems='center' alignContent='center' gap={16}>
            <Grid row alignItems='center' gap={16}>
              <IconSquare icon='briefcase-outline' size={64} />
              <Text
                size='larger'
                value='Selecione uma empresa para ter uma melhor experiência no portal.'
              />
            </Grid>

            <SelectCompany
              data={companySelectOptions}
              selectedCompanyId={selectedCompanyId}
              onChangeSelectedCompany={(_, selectedCompany) =>
                onChangeSelectedCompany(selectedCompany)
              }
            />

            <RecentCompanies onClick={onChangeSelectedCompany} />
          </Grid>
        </Row>
      }
      footer={
        !persistent ? (
          <Grid alignContent='right'>
            <Button label='Fechar' onClick={instance.dismiss} />
          </Grid>
        ) : undefined
      }
    />
  );
};

export default DialogSwitch;
