import { Breadcrumb } from '@/components';
import { BreadcrumbItem } from '@/components/breadcrumb';
import { useHistoryNavigator } from '@/navigation';
import React from 'react';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface NavigatorProps {
  navigation: BreadcrumbItem[];
}

const Navigator: React.FC<NavigatorProps> = ({ navigation }) => {
  const navigator = useHistoryNavigator();

  return (
    <Grid alignContent='justify'>
      <Breadcrumb items={navigation} />
      <div>
        <Button
          icon='arrow-ios-back-outline'
          iconColor='colorDefault'
          label='Voltar'
          invertColor
          onClick={() => navigator.goBack()}
        />
      </div>
    </Grid>
  );
};

export default Navigator;
