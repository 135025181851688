import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import DialogSwitch from '@/modules/company/components/dialogSwitch/dialogSwitch';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
import { useHistoryLocation, useHistoryNavigator, useHistoryQuery } from '@/navigation';
import React, { useEffect, useRef } from 'react';
import useCurrentUser from '@/modules/profile/hooks/useCurrentUser/useCurrentUser';

const NoCompany: React.FC = () => {
  const { pathname } = useHistoryLocation();
  const { replace } = useHistoryNavigator();
  const queryParams = useHistoryQuery();
  const context = useApplicationContext();
  const { propagateCompany } = useCompanyPropagate();
  const useRefDialog = useRef({ open: () => ({}), dismiss: () => ({}) });
  const { defaultCompany } = useCurrentUser();

  const companyId = context.company?.id || queryParams.companyId || defaultCompany?.id;

  useEffect(() => {
    if (companyId) {
      replace(`/companies/${companyId}`);
    }
  }, [companyId, replace, pathname]);

  useEffect(() => {
    if (!companyId) {
      useRefDialog.current.open();
    }
  }, [companyId]);

  return (
    <DialogSwitch
      persistent
      instance={useRefDialog.current}
      selectedCompanyId={companyId}
      onChangeSelectedCompany={propagateCompany}
    />
  );
};

export default NoCompany;
