import {
  CheckPassword,
  FormValidate,
  GridGroup,
  ShowHide,
  Space,
  TextFieldValidate,
} from '@/components';
import { BodyForm } from '@/components/formValidate';
import { useHistoryNavigator } from '@/navigation';
import { checkPasswordValid } from '@/utils/checkPassword';
import React from 'react';
import { Button, Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import useAuthResetPassword from './hooks/useAuthResetPassword';

const AuthConfirmEmail: React.FC = () => {
  const {
    checkConfirmPasswordsValid,
    setData,
    setMessageError,
    useData,
    useLoading,
    useMessageError,
    confirmPassword,
    useDisabledForm,
    useCurrentStep,
  } = useAuthResetPassword();

  const navigate = useHistoryNavigator();

  const baseRouteNavigate = '/auth/login';

  const getFields = ({ onBlur, onChange, useErrors, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          middle: 100,
          default: 100,
          component: (
            <Text size='medium' value={'Para alterar sua senha preencha os campos abaixo.'} />
          ),
        },

        {
          default: 100,
          middle: 100,
          component: (
            <Text
              size='medium'
              textAlign='left'
              rounded
              value={`
                      <b>Sua senha deve atender aos critérios mínimos de segurança do sistema.</b>
                      <br /><br />
                      Tente criar uma senha intercalando letras maiúsculas, minúsculas,
                      caracteres especiais (como %, # e *) e números, evitar sequências,
                      palavras conhecidas, como nomes de empresas e produtos,
                      e referências de datas (2021, 2022).
                    `}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              icon='lock-outline'
              label='Senha'
              name='newPassword'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Digite uma senha'
              required
              type='password'
              useErrors={useErrors}
              useValues={useValues}
              disabled={useDisabledForm}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: (
            <TextFieldValidate
              icon='lock-outline'
              label='Confirmar senha'
              name='confirmPassword'
              onBlur={onBlur}
              onChange={onChange}
              placeholder='Confirme a senha'
              required
              type='password'
              useErrors={useErrors}
              useValues={useValues}
              disabled={useDisabledForm}
            />
          ),
        },

        {
          middle: 100,
          default: 100,
          component: <CheckPassword password={useValues.newPassword} />,
        },
      ]}
    />
  );

  return (
    <div
      style={{
        maxWidth: 400,
        width: '100%',
      }}>
      <ShowHide transition='slideToDown' visible={!!useMessageError}>
        <Text
          value={useMessageError}
          icon='alert-triangle-outline'
          iconColor='danger'
          color='danger'
          applyBaseColor
          padding='8px'
          rounded
          margin='0 0 16px'
          size='medium'
        />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={useCurrentStep === 'newPassword'}>
        <FormValidate
          resource={useData}
          fields={{
            newPassword: ['required', checkPasswordValid],
            confirmPassword: ['required', checkConfirmPasswordsValid],
          }}
          onChangeForm={setData}
          onError={() => setMessageError('Existem alguns campos com preenchimento irregular.')}
          onSubmit={confirmPassword}
          body={getFields}
          footer={
            <Row style={{ padding: '32px 0 0' }}>
              <Button submit full bold loading={useLoading} disabled={useDisabledForm}>
                <Row>Alterar minha senha</Row>
              </Button>
              <Space horizontal height={16} />
              <Button
                full
                bold
                solo
                outlined
                disabled={useLoading}
                onClick={() => navigate.push(baseRouteNavigate)}>
                <Row>Voltar para login</Row>
              </Button>
            </Row>
          }
        />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={useCurrentStep === 'success'}>
        <Grid alignContent='center'>
          <Icon color='success' size={64} name='done-all-outline' />
        </Grid>

        <Row style={{ textAlign: 'center' }}>
          <Text title color='success' value='Tudo certo' margin='0 0 8px' />

          <Text
            size='medium'
            value='Sua senha foi alterada, volte para tela de login para acessar o portal.'
            margin='0 0 8px'
          />

          <Button
            full
            bold
            invertColor
            disabled={useLoading}
            onClick={() => navigate.push(baseRouteNavigate)}>
            <Row>Voltar para login</Row>
          </Button>
        </Row>
      </ShowHide>

      <ShowHide transition='slideToDown' visible={useCurrentStep === 'expired'}>
        <Button full bold onClick={() => navigate.push('/auth/forgot-password')}>
          <Row>Recomeçar</Row>
        </Button>
        <Space horizontal height={16} />
        <Button
          full
          bold
          solo
          outlined
          disabled={useLoading}
          onClick={() => navigate.push(baseRouteNavigate)}>
          <Row>Voltar para login</Row>
        </Button>
      </ShowHide>
    </div>
  );
};

export default AuthConfirmEmail;
