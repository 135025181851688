import { useHistoryNavigator } from '@/navigation';
import { ObjectType } from '@/shareds/types';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Icon } from 'vkit/lib/components';
import { ActionOption as ButtonActionOptions } from '../buttonActions';
import ButtonActions from './components/buttonActions';
import style from './style/breadcrumb.module.scss';

export interface ActionOption {
  disabled?: boolean;
  icon?: string;
  label: string;
  path?: string;
}

export interface BreadcrumbItem {
  accessId?: string;
  disabled?: boolean;
  label: string;
  path?: string;
  requestFindLabelParamIdName?: string;
  requestFindLabelResponsePathText?: string;
  requestFindLabelResponseOnLoad?: (label: string) => void;
  requestFindLabelFn?: (paramId: string) => Promise<unknown>;
  actionOptions?: ActionOption[];
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const MAX_WIDTH_BUTTON = 250;

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useHistoryNavigator();
  const params = useParams<ObjectType>();

  const goToPath = (path: string): void => {
    const url = generatePath(path, params) || {};
    navigate.push(url);
  };

  const actionOptionsToButtonActionOptions = (
    actionOptions: ActionOption[],
  ): ButtonActionOptions[] =>
    actionOptions.map((actionOption) => ({
      disabled: actionOption.disabled,
      icon: actionOption.icon,
      label: actionOption.label,
      onClick: () => goToPath(actionOption.path!),
    }));

  return (
    <div className={style.breadcrumb}>
      <div className={style.items}>
        {items?.map((item, key) => (
          <div key={key} className={style.item}>
            <div className={style.icon}>
              <Icon name='chevron-right-outline' size={24} />
            </div>
            <ButtonActions
              disabled={item.disabled}
              label={item.label}
              {...(item.path ? { onClick: () => goToPath(item.path!) } : {})}
              requestFindLabelParamId={
                item.requestFindLabelParamIdName && params[item.requestFindLabelParamIdName]
              }
              requestFindLabelResponsePathText={item.requestFindLabelResponsePathText}
              requestFindLabelFn={item.requestFindLabelFn}
              requestFindLabelResponseOnLoad={item.requestFindLabelResponseOnLoad}
              maxWidth={MAX_WIDTH_BUTTON}
              actionOptions={actionOptionsToButtonActionOptions(item.actionOptions || [])}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
