import PlanDetails from '@/modules/plan/views/details/details';
import { useCurrentUser } from '@/modules/profile';
import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { negotiationsService } from '@/services/negotiations';
import {
  Negotiation,
  negotiationInitalState,
} from '@/services/negotiations/endpoints/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { DialogOver } from '../../components';

const Plan: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { isBrokerUser } = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [negotiation, setNegotiation] = useState<Negotiation>(negotiationInitalState);
  const [showOverDialog, setShowOverDialog] = useState<boolean>(false);

  const { company } = useCompany();
  const [currentCompanyId] = useState(company.id);
  const { negotiationId } = useHistoryQuery();

  const product = useMemo(() => negotiation.product || {}, [negotiation.product]);

  const loadNegotiation = useCallback(async () => {
    return tryOrCatchMessageError(
      async () => {
        if (!company.id || !negotiationId) {
          return;
        }

        const [negotiationFound] =
          (await negotiationsService(company.id).list({ id: negotiationId })) || {};

        setNegotiation(negotiationFound);
      },
      {
        messageErrorDefault: 'Erro ao carregar plano.',
        tryBefore: () => setLoading(true),
        tryAfter: () => setLoading(false),
      },
    );
  }, [company.id, negotiationId]);

  const dialogOverProps = useMemo(() => {
    return {
      negotiation: get(negotiation, 'contract.code'),
      plan: get(negotiation, 'product.name'),
      costCenter: get(negotiation, 'costCenter.name'),
      markups: get(negotiation, 'markups', []),
    };
  }, [negotiation]);

  useEffect(() => {
    loadNegotiation();
  }, [loadNegotiation]);

  useEffect(() => {
    if (company.id && currentCompanyId && company.id !== currentCompanyId) {
      navigate.push('/contracts');
    }
  }, [company.id, currentCompanyId, navigate]);

  return (
    <>
      {isBrokerUser && (
        <Row style={{ padding: 0 }}>
          <Grid alignContent='right'>
            <Button onClick={() => setShowOverDialog(true)} label='Over' />

            <DialogOver
              open={showOverDialog}
              onClose={() => setShowOverDialog(false)}
              negotiation={dialogOverProps.negotiation}
              plan={dialogOverProps.plan}
              costCenter={dialogOverProps.costCenter}
              markups={dialogOverProps.markups}
            />
          </Grid>
        </Row>
      )}

      <PlanDetails
        loading={loading}
        product={product}
        negotiation={negotiation}
        infos={[
          { key: 'name', size: 100 },
          { key: 'costCenter', size: 33.33 },
          { key: 'ansCode', size: 33.33 },
          { key: 'accommodation', size: 33.33 },
          { key: 'coverage', size: 33.33 },
          { key: 'scope', size: 33.33 },
          { key: 'reimbursement', size: 33.33 },
        ]}
      />
    </>
  );
};

export default Plan;
