import { SelectValidate } from '@/components';
import { useContractOptions } from '@/modules/contract/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BodyForm } from '@/components/formValidate';
import { SelectOption } from '@/components/selectValidate';
import {
  CONTRACT_STATUS,
  Contract,
  SELECT_CONTRACT_COLUMN,
  SELECT_CONTRACT_FILTER,
} from '@/modules/contract';
import { ObjectType } from '@/shareds/types';
import { Negotiation } from '@/shareds/types/negotiation.type';
import { get, uniqBy } from 'lodash';
import { MultiSelectContractStatusOption } from '../../components';
import { addHours, format, parseISO } from 'date-fns';

export interface ContractToForm extends Contract {
  statusToForm: CONTRACT_STATUS;
}

export type SelectedContractsType = SelectOption<ContractToForm>[] | SelectOption<ContractToForm>;

interface SelectContractProps extends Omit<BodyForm, 'onChange' | 'onBlur' | 'useErrors'> {
  name?: string;
  isDisabled?: boolean;
  onChange: (key: string, values: string | string[], contracts?: SelectedContractsType) => void;
  multiple?: boolean;
  columns: SELECT_CONTRACT_COLUMN[];
  filters?: SELECT_CONTRACT_FILTER[];
  setOriginalContracts?: (contracts: Contract[]) => void;
  small?: boolean;
  onBlur?: (...item: any) => void;
  useErrors?: ObjectType;
  errorMessage?: string;
  required?: boolean;
}

const SelectContract: React.FC<SelectContractProps> = ({
  name = 'contractIds',
  isDisabled,
  useValues,
  useErrors,
  onBlur,
  onChange,
  multiple,
  columns,
  filters,
  setOriginalContracts,
  errorMessage,
  required,
  small,
}) => {
  const [isTriggerSelect, setTriggerSelect] = useState<boolean>(false);
  const { isLoading, contracts } = useContractOptions({
    filterKeys: filters,
    setOriginalContracts,
  });

  const handleSelect = useCallback(
    (name: string, value: string, selecteds: SelectedContractsType) => {
      onChange(name, value, selecteds);
      setTriggerSelect(true);
    },
    [onChange],
  );

  const getBenefits = (option: ObjectType) => {
    const negotiations = get(option.data, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'product.benefit.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'product.benefit.name', ''))
      .join(', ');
  };

  const getValidUntil = (option: ObjectType) => {
    return option.data?.validUntil
      ? format(addHours(parseISO(option.data.validUntil), 3), 'dd/MM/yyyy')
      : 'Não informado';
  };

  const getProviders = (option: ObjectType) => {
    const negotiations = get(option.data, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'product.provider.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'product.provider.name', ''))
      .join(', ');
  };

  const getCostCenters = (option: ObjectType) => {
    const negotiations = get(option.data, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'costCenter.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'costCenter.name', ''))
      .join(', ');
  };

  const getStatus = (option: ObjectType) => {
    return <MultiSelectContractStatusOption option={option as SelectOption<ContractToForm>} />;
  };

  const getColumns = (columnKeys: SELECT_CONTRACT_COLUMN[]) => {
    const columnByKey = {
      [SELECT_CONTRACT_COLUMN.CODE]: {
        title: 'Código',
        path: 'text',
      },
      [SELECT_CONTRACT_COLUMN.PROVIDER]: {
        title: 'Fornecedor(es)',
        custom: getProviders,
        path: '',
      },
      [SELECT_CONTRACT_COLUMN.BENEFIT]: {
        title: 'Benefício(s)',
        path: '',
        custom: getBenefits,
      },
      [SELECT_CONTRACT_COLUMN.STATUS]: {
        title: 'Status',
        path: '',
        custom: getStatus,
      },
      [SELECT_CONTRACT_COLUMN.VALID_UNTIL]: {
        title: 'Data de expiração',
        path: '',
        custom: getValidUntil,
      },
      [SELECT_CONTRACT_COLUMN.COST_CENTER]: {
        title: 'Centro de custo',
        path: '',
        custom: getCostCenters,
      },
    };

    return columnKeys.map((columnKey) => columnByKey[columnKey]);
  };

  const showError = useMemo(() => {
    if (isLoading) {
      return { [name]: '' };
    }
    if (useErrors?.[name]) {
      return useErrors;
    }
    if (
      useValues?.[name] &&
      !isLoading &&
      !contracts.find((contract) => contract.value === useValues?.[name]) &&
      !multiple
    ) {
      return {
        [name]: errorMessage || 'Contrato com registro incorreto, por favor tente outro.',
      };
    }
  }, [contracts, errorMessage, isLoading, multiple, name, useErrors, useValues]);

  useEffect(() => {
    if (useValues?.[name] && !isTriggerSelect && !isLoading) {
      if (multiple) {
        const selecteds = useValues?.[name].map((id: string) => {
          if (Array.isArray(contracts)) {
            const selected = contracts.find((contract) => contract.value === id);
            if (selected) {
              return selected;
            }
          }
        });
        handleSelect(name, useValues?.[name], selecteds);
      } else {
        const contract = contracts.find((contract) => contract.value === useValues?.[name]);
        if (contract) {
          handleSelect(
            name,
            useValues?.[name],
            contract as unknown as SelectOption<ContractToForm>,
          );
        }
      }
    }
  }, [contracts, handleSelect, isLoading, isTriggerSelect, multiple, name, useValues]);

  return (
    <SelectValidate
      required={required}
      multiple={multiple}
      searchable
      disabled={!contracts.length || isDisabled || isLoading}
      label='Contratos'
      name={name}
      data={contracts.length ? contracts : null}
      useValues={useValues}
      useErrors={showError}
      onBlur={onBlur}
      onChange={handleSelect}
      loading={isLoading}
      columns={getColumns(columns)}
      small={small}
    />
  );
};

export default SelectContract;
