import React from 'react';
import { Button, Text } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { ValidatedFile } from '@/components/fileUpload/hooks/useFileUpload';

interface SingleFileProps {
  validatedFiles: ValidatedFile[];
  hasError: boolean;
  handleUploadClick: Function;
}

export const SingleFile: React.FC<SingleFileProps> = ({
  validatedFiles,
  hasError,
  handleUploadClick,
}) => {
  const getFileName = (fileName: any): string =>
    fileName.name ? fileName.name.replace(/^.*[\\\/]/, '') : fileName.replace(/^.*[\\\/]/, '');

  return (
    <Row style={{ flex: 1 }}>
      <Text value={`<b> Arquivo:</b> ${getFileName(validatedFiles[0])}`} size='medium' />

      {hasError && (
        <Row style={{ padding: '10px 0px 0px 0px' }}>
          {validatedFiles[0].errors.map((error) => (
            <Text key={error} fontWeight='bold' value={error} size='medium' color='danger' />
          ))}
          <Text value='Ajuste de acordo com o arquivo modelo e tente novamente' size='medium' />
          <Row style={{ padding: '10px 0px 0px 0px' }}>
            <Button
              label='Alterar arquivo'
              solo
              outlined
              size='xLarger'
              onClick={() => {
                handleUploadClick();
              }}
            />
          </Row>
        </Row>
      )}
      {!hasError && (
        <Row style={{ marginTop: '30px', padding: 0 }}>
          <Button
            label='Alterar arquivo'
            solo
            outlined
            size='xLarger'
            onClick={() => {
              handleUploadClick();
            }}
          />
        </Row>
      )}
    </Row>
  );
};
