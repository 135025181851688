import * as Sentry from '@sentry/react';
import { UserIdentity } from '../telemetry.type';
import { ErrorReportCategory, ErrorReportEvent, ErrorReports } from './errorReports.type';

const {
  REACT_APP_ENVIRONMENT = 'development',
  REACT_APP_SENTRY_KEY = '',
  REACT_APP_SENTRY_SIMPLE_RATING = '0',
} = process.env;

const init = (): void => {
  if (!REACT_APP_SENTRY_KEY) {
    return;
  }

  Sentry.init({
    dsn: REACT_APP_SENTRY_KEY,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: Number(REACT_APP_SENTRY_SIMPLE_RATING) || 0,
    environment: REACT_APP_ENVIRONMENT.toUpperCase(),
    enabled: REACT_APP_ENVIRONMENT !== 'development',
    beforeSend: (event) => {
      if (REACT_APP_ENVIRONMENT === 'development') {
        return null;
      }
      return event;
    },
  });
};

const identifyUser = (user: UserIdentity) => {
  if (!REACT_APP_SENTRY_KEY) {
    return;
  }
  Sentry.setUser(user);
};

const category = (register: ErrorReportCategory) => {
  if (!REACT_APP_SENTRY_KEY) {
    return;
  }

  Sentry.addBreadcrumb({
    category: register.name,
    message: register.description,
    level: register.level as Sentry.SeverityLevel,
  });
};

const event = (event: ErrorReportEvent) => {
  if (!REACT_APP_SENTRY_KEY) {
    return;
  }

  Sentry.captureEvent({
    message: event.name,
    extra: event.content,
    level: event.level as Sentry.SeverityLevel,
  });
};

const exception = (exception: any) => {
  if (!REACT_APP_SENTRY_KEY) {
    return;
  }

  Sentry.captureException(exception);
};

export const errorReports: ErrorReports = {
  init,
  identifyUser,
  category,
  event,
  exception,
};
