import React from 'react';

import { Grid } from 'vkit/lib/context';
import { Text } from 'vkit/lib/components';

import ButtonCard from './selectionCard';

import { ButtonCardType } from './types/buttonCard.type';

interface ButtonCardsInterface {
  textNotFound?: string;
  data: ButtonCardType[];
}

const ButtonCards: React.FC<ButtonCardsInterface> = ({
  data,
  textNotFound = 'Nenhum item encontrado para executar essa ação.',
}) => (
  <>
    {data.length === 0 && (
      <Text
        icon='alert-triangle-outline'
        iconColor='danger'
        rounded
        applyBaseColor
        value={textNotFound}
        padding='16px'
        size='medium'
      />
    )}

    {data.length > 0 && (
      <div style={{ marginRight: -24 }}>
        <Grid growing wrap>
          {data.map((selectionCard, index) => (
            <ButtonCard
              key={index}
              onClick={selectionCard.onClick}
              icon={selectionCard.icon}
              title={selectionCard.title}
              description={selectionCard.description}
            />
          ))}
        </Grid>
      </div>
    )}
  </>
);

export default ButtonCards;
