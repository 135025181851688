export enum EXTENSIONS_COLOR {
  jpg = '#000080',
  jpeg = '#000080',
  png = '#ff7300',
  gif = '#008080',
  xls = '#1D6F42',
  xlsx = '#1D6F42',
  csv = '#ff0044',
  zip = '#5e00ff',
  pdf = '#FF0000',
  doc = '#1B5EBE',
  docx = '#1B5EBE',
  txt = '#808080',
  tsv = '#008000',
}

export enum FILES_MIME_TYPE {
  'image/jpeg' = EXTENSIONS_COLOR.jpg,
  'image/png' = EXTENSIONS_COLOR.png,
  'image/gif' = EXTENSIONS_COLOR.gif,
  'application/vnd.ms-excel' = EXTENSIONS_COLOR.xlsx,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = EXTENSIONS_COLOR.xlsx,
  'text/csv' = EXTENSIONS_COLOR.csv,
  'application/zip' = EXTENSIONS_COLOR.zip,
  'application/pdf' = EXTENSIONS_COLOR.pdf,
  'application/msword' = EXTENSIONS_COLOR.xlsx,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = EXTENSIONS_COLOR.xlsx,
  'text/plain' = EXTENSIONS_COLOR.txt,
  'text/tab-separated-values' = EXTENSIONS_COLOR.tsv,
}

export enum EXTENSIONS_BY_MIMETYPE {
  'image/jpeg' = 'jpg',
  'image/png' = 'png',
  'image/gif' = 'gif',
  'application/vnd.ms-excel' = 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
  'text/csv' = 'csv',
  'application/zip' = 'zip',
  'application/pdf' = 'pdf',
  'application/msword' = 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
  'text/plain' = 'txt',
  'text/tab-separated-values' = 'tsv',
}

export type Extension = keyof typeof EXTENSIONS_COLOR;
export type Mimetype = keyof typeof EXTENSIONS_BY_MIMETYPE;
