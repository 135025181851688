import Exercise from '@/assets/images/Exercise.svg';
import Life from '@/assets/images/Health.svg';
import HealthTeam from '@/assets/images/HealthTeam.svg';
import Medicine from '@/assets/images/Medicine.svg';
import Tooth from '@/assets/images/Tooth.svg';
import Health from '@/assets/images/hand-plus.png';
import React, { useEffect, useMemo, useState } from 'react';
import { classesBuilder } from '@/utils';
import { Icon, Image, Text } from 'vkit/lib/components';
import style from './style/iconBenefit.module.scss';
import { BenefitSlugType } from './types/benefitSlug.type';

type IconBenefitType = {
  benefitSlug?: BenefitSlugType;
  small?: boolean;
};

const IconBenefit: React.FC<IconBenefitType> = ({ benefitSlug, small }) => {
  const [useProps, setProps] = useState<{ [key: string]: any }>();

  const benefits = useMemo(
    () => ({
      'plano-de-saude': { color: 'default', image: Health },
      'plano-odontologico': { color: 'default', image: Tooth },
      'plano-de-medicamentos': { color: 'default', image: Medicine },
      medicamentos: { color: 'default', image: Medicine },
      primecare: { color: 'default', image: HealthTeam },
      'equipe-de-saude': { color: 'default', image: HealthTeam },
      'seguro-de-vida': { color: 'default', image: Life },
      gympass: { color: 'default', image: Exercise },
      saude: { color: 'default', image: Health },
      odonto: { color: 'default', image: Tooth },
      vida: { color: 'default', image: Life },
    }),
    [],
  );

  useEffect(() => {
    if (benefitSlug) {
      setProps(benefits[benefitSlug]);
    }
  }, [benefitSlug, benefits]);

  return (
    <>
      {!benefitSlug && (
        <div style={{ width: 48, marginRight: 20 }}>
          <Text rounded value='' lines={2} />
        </div>
      )}

      {benefitSlug && (
        <div
          className={classesBuilder(style, {
            iconBenefit: true,
            small,
            [useProps?.color]: true,
          })}>
          {useProps?.image ? (
            <Image width='32px' height='32px' src={useProps?.image} alt='Benefit icon' />
          ) : (
            <Icon color={useProps?.color} name={useProps?.icon} size={32} />
          )}
        </div>
      )}
    </>
  );
};

export default IconBenefit;
