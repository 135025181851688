import * as amplitude from '@amplitude/analytics-browser';
import { datadogLogs } from '@datadog/browser-logs';

import { UserIdentity } from '../telemetry.type';
import { Analytics } from './analytics.type';

const { REACT_APP_AMPLITUDE_KEY = '', REACT_APP_AMPLITUDE_LOG_LEVEL = '0' } = process.env;

const init = (): void => {
  if (!REACT_APP_AMPLITUDE_KEY) {
    return;
  }
  /**
   * * @see {@link https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-default-events|Amplitude Default Event Tracking}
   */
  amplitude.init(REACT_APP_AMPLITUDE_KEY, {
    logLevel: Number(REACT_APP_AMPLITUDE_LOG_LEVEL) as amplitude.Types.LogLevel,
    defaultTracking: true,
  });
};

/**
 * Track an event with optional properties.
 * @param eventName - The name of the event to track.
 * @param properties - Additional properties to associate with the event.
 *
 * @see {@link https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-an-event|Amplitude Tracking Event}
 */
const track = (eventName: string, properties?: Record<string, any>): void => {
  if (!REACT_APP_AMPLITUDE_KEY) {
    return;
  }
  // Track an event with optional properties.
  amplitude.track(eventName, {
    ...properties,
  });

  datadogLogs.logger.info(eventName, properties);
};

const addUserIdentityProperties = (properties: Record<string, string>) => {
  if (!REACT_APP_AMPLITUDE_KEY) {
    return;
  }

  const identity = new amplitude.Identify();

  Object.keys(properties).forEach((key) => {
    identity.set(key, properties[key]);
  });

  amplitude.identify(identity);
};

const identifyUser = (user: UserIdentity) => {
  if (!REACT_APP_AMPLITUDE_KEY) {
    return;
  }

  addUserIdentityProperties({
    id: user.id,
    type: user.type,
    email: user.email,
    name: user.name,
  });

  amplitude.setGroup('TYPE', user.type);
  amplitude.setUserId(user.id);
};

/**
 * The Analytics interface with initialization, user identification, grouping, and event tracking.
 * @public
 */
export const analytics: Analytics = {
  init,
  identifyUser,
  addUserIdentityProperties,
  track,
};
