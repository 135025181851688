import React, { useMemo } from 'react';
import bannerImg from '@/assets/images/banner.jpg';
import { BoxInfo } from '@/components';
import { Row } from 'vkit/lib/context';
import { Link } from 'react-router-dom';

interface EnvoirementConfig {
  initialDate: string;
  endDate?: string;
  redirect: string;
}

const { REACT_APP_COMPANY_BANNER = '' } = process.env;

const Banner: React.FC = () => {
  const envConfig = useMemo((): EnvoirementConfig | null => {
    try {
      return JSON.parse(REACT_APP_COMPANY_BANNER);
    } catch (error) {
      return null;
    }
  }, []);

  const showBanner = useMemo(() => {
    if (!envConfig) {
      return false;
    }

    const { initialDate, endDate } = envConfig;

    if (!initialDate || !new Date(initialDate).getTime()) {
      return false;
    }

    if (
      new Date(initialDate).getTime() <= new Date().getTime() &&
      (!endDate ||
        !new Date(endDate).getTime() ||
        new Date(endDate).getTime() >= new Date().getTime())
    ) {
      return true;
    }

    return false;
  }, [envConfig]);

  const redirect = envConfig?.redirect ?? '/';

  return showBanner ? (
    <Row style={{ padding: 0 }}>
      <Link to={redirect} target='_blank' rel='noopener noreferrer'>
        <BoxInfo
          inlineStyle={{
            backgroundImage: `url(${bannerImg})`,
            backgroundSize: 'content',
            height: '362px',
            position: 'relative',
          }}></BoxInfo>
      </Link>
    </Row>
  ) : null;
};

export default Banner;
