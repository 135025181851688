import React from 'react';
import { Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const Success: React.FC = () => {
  return (
    <Row>
      <Grid alignContent='left' column gap={6}>
        <Row style={{ padding: 0 }}>
          <Text textAlign='center' value='Agradecemos seu feedback!' size='larger' />
        </Row>
        <Text
          textAlign='center'
          size='medium'
          value='Sua opinião será muito útil para que possamos continuar melhorando nosso sistema!'
        />
      </Grid>
    </Row>
  );
};

export default Success;
