import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

import {
  ADDITIONAL_SERVICES_STATUS,
  ADDITIONAL_SERVICES_STATUS_ALIAS_TRANSLATED,
  ADDITIONAL_SERVICES_STATUS_COLOR,
} from '../../entities/constants';

export interface StatusProps {
  label: ADDITIONAL_SERVICES_STATUS;
}

const Status: React.FC<StatusProps> = ({ label }) => (
  <Row style={{ padding: '6px 0px', display: 'flex', width: 'max-content' }}>
    <Chip
      label={ADDITIONAL_SERVICES_STATUS_ALIAS_TRANSLATED[label]}
      color={ADDITIONAL_SERVICES_STATUS_COLOR[label]}
      rounded
    />
  </Row>
);

export default Status;
