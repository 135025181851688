import { Base } from '@/shareds/types/base.type';
import restfulBase from '@/services/negotiations/core/base';

export interface CostCenter extends Base {
  name: string;
  isDefault: boolean;
  companyId: string;
}

export const costCenterInitialState: CostCenter = {
  name: '',
  isDefault: false,
  companyId: '',
};

export const costCenterService = restfulBase('cost-centers');
