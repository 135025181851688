import ICONS from '@/shareds/constants/icons';
import { Attendance, AttendanceForm } from './attendance';

export const ATTENDANCE_ICON = ICONS.ATTENDANCE;

export const ATTENDANCE_PAZE_SIZE = 10;

export const attendanceInitialState: Attendance = {
  subject: '',
  reason: '',
  subReason: '',
  status: '',
  description: '',
  expectedSolutionTime: 0,
  userEmail: '',
  userName: '',
  attachments: [],
  contracts: [],
};

export const attendanceFormInitialState: AttendanceForm = {
  subject: '',
  reason: '',
  subReason: '',
  description: '',
};
