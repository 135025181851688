import { findRoutePathByName } from '@/core/utils';
import { USER_AVAILABLE_TABLE_ACTIONS, USER_STATUS, USER_TYPE } from '@/modules/user/entities';
import { ROUTE_USER } from '@/modules/user/routes';
import { useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import { generatePath } from 'react-router-dom';
import ButtonType from 'vkit/lib/shared/components/button/types/button.type';

interface UseTableUserActions {
  type: `${USER_TYPE}`;
  actions?: `${USER_AVAILABLE_TABLE_ACTIONS}`[];
}

const useTableUserActions = ({ type, actions }: UseTableUserActions) => {
  const { company } = useCompany();
  const navigate = useHistoryNavigator();

  const getActions = (userId: string, userStatus?: `${USER_STATUS}`): ButtonType[] => {
    const findedBrokerUserDetailsRoute = findRoutePathByName(ROUTE_USER.BROKER_USER_DETAILS);
    const findedCompanyUserDetailsRoute = findRoutePathByName(ROUTE_USER.COMPANY_USER_DETAILS);

    const currentDetailsRoute =
      type === USER_TYPE.BROKER ? findedBrokerUserDetailsRoute : findedCompanyUserDetailsRoute;
    let generatedRoute =
      type === USER_TYPE.BROKER
        ? generatePath(currentDetailsRoute ?? '', { userId })
        : generatePath(currentDetailsRoute ?? '', { userId, companyId: company?.id });

    if (userStatus === 'deleted') {
      generatedRoute = generatedRoute + `?userStatus=${userStatus}`;
    }

    const availableActions: Record<`${USER_AVAILABLE_TABLE_ACTIONS}`, ButtonType> = {
      [USER_AVAILABLE_TABLE_ACTIONS.DETAILS]: {
        onClick: () => {
          navigate.push(generatedRoute);
        },
        label: 'Detalhes',
        solo: true,
        outlined: true,
        icon: ICONS.DETAILS,
        color: 'colorDefault',
        iconColor: 'colorDefault',
      },
    };

    return actions
      ? actions.map((action) => availableActions[action])
      : Object.values(availableActions);
  };

  return {
    getActions,
  };
};

export default useTableUserActions;
