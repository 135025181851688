import React from 'react';

import { Row } from 'vkit/lib/context';
import { Icon, Text } from 'vkit/lib/components';

import style from './style/buttonCard.module.scss';

import { ButtonCardType } from './types/buttonCard.type';

const SelectionCard: React.FC<ButtonCardType> = ({ onClick, icon, title, description }) => (
  <div className={style.buttonCard} {...(title && { onClick })}>
    <Row>
      {icon ? (
        <Icon name={icon} size={48} />
      ) : (
        <div style={{ width: 48 }}>
          <Text lines={2} rounded value='' />
        </div>
      )}
    </Row>

    <Row>
      <Text color='colorDefault' margin='0 0 8px' rounded size='medium' title value={title || ''} />

      {description && <Text rounded size='medium' value={description || ''} />}
    </Row>
  </div>
);

export default SelectionCard;
