import { authOTPRecoveryService } from '@/services/accessControl/endpoints/authTOTPRecovery';
import { useState } from 'react';

const useRecovery = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const recoveryOTP = async (email: string): Promise<boolean> => {
    if (!email) {
      return false;
    }

    setLoading(true);

    try {
      await authOTPRecoveryService.create({ userEmail: email });
      return true;
    } catch (e) {
      console.error(e);
      setError('Não foi possível concluir sua solicitação, tente novamente em breve.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    isLoading,
    recoveryOTP,
  };
};

export default useRecovery;
