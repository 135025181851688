import { BasePagination } from '@/shared/entities';
import { User } from './user';

export enum TYPES {
  MFA_DISABLE = 'mfa-disable',
  TOTP_RESET = 'totp-reset',
  PASSWORD_RECOVERY = 'password-recovery',
  REGISTRATION = 'registration',
}

export const TYPES_TRANSLATE = {
  [TYPES.MFA_DISABLE]: 'Autenticação em dois fatores desabilitado',
  [TYPES.TOTP_RESET]: 'Redefinição de autenticação em dois fatores',
  [TYPES.PASSWORD_RECOVERY]: 'Redefinição senha',
  [TYPES.REGISTRATION]: 'Registro',
};

export interface Confirmation {
  id: string;
  userId: string;
  email: string;
  type: TYPES;
  createdAt: string;
  createdBy: string;
  confirmedAt: string | null;
  createdByUser: User;
  isExpired: boolean;
  expiresAt: string | null;
}

export interface ConfirmationSearchParams {
  userId?: string;
  include?: 'user' | 'createdByUser';
  sort?: string | `-${string}`;
  page?: number;
  pageSize?: number;
}

export interface ConfirmationResponse extends BasePagination {
  data: Confirmation[];
}

export enum CONFIRMATION_TABLE_COLUMNS {
  OPERATION = 'operation',
  CREATED_AT = 'created_at',
  CONFIRMED_AT = 'confirmed_at',
  STATUS = 'status',
  CREATED_BY = 'created_by',
}

export const authEmailConfirmationSendsErrors = {
  'No pending email confirmations': 'Confirmação de e-mail não encontrada',
  'Latest email confirmation already confirmed': 'Confirmação de e-mail já confirmada',
};

export interface ResendConfirmationPayload {
  userId: string;
  type: `${TYPES}`;
}
