import { useCallback, useEffect, useState } from 'react';

import { SelectOption } from '@/components/selectValidate';
import { BROKER_PERMISSION, COMPANY_PERMISSION } from '@/core/entities/interfaces/permissions';
import { useCurrentUser } from '@/modules/profile';
import { USER_TYPE } from '@/modules/user/entities';
import { Permission } from '../../../entities/permission';
import { PermissionService } from '../../../services';

interface UseSelectPermissionProps {
  scope: `${USER_TYPE}`;
}

const permissionService = new PermissionService();

const useSelectPermission = ({ scope }: UseSelectPermissionProps) => {
  const { checkUserHasPermission } = useCurrentUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [permissionOptions, setPermissionOptions] = useState<SelectOption[]>([]);

  const permissionsToOptions = useCallback(
    (permissions: Permission[]): void => {
      let currentPermissions: string[] = Object.values(COMPANY_PERMISSION);
      if (scope === USER_TYPE.BROKER) {
        currentPermissions = [
          ...Object.values(COMPANY_PERMISSION),
          ...Object.values(BROKER_PERMISSION),
        ];
      }
      const permissionOptions = permissions
        .filter((permission) => !permission.scoped && currentPermissions.includes(permission.id))
        .map((permission) => {
          const isDisabled = !checkUserHasPermission(permission.id);
          return {
            text: permission.name,
            value: permission.id,
            disabled: isDisabled,
            title: isDisabled ? 'Você não pode adicionar ou alterar esta permissão!' : undefined,
          };
        });
      setPermissionOptions(permissionOptions);
    },
    [checkUserHasPermission, scope],
  );

  const loadPermissionOptions = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const permissions = await permissionService.getPermissions();
      permissionsToOptions(permissions);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [permissionsToOptions]);

  useEffect(() => {
    if (permissionOptions.length) {
      return;
    }
    loadPermissionOptions();
  }, [loadPermissionOptions, permissionOptions.length]);

  return {
    isLoading,
    permissionOptions,
  };
};

export default useSelectPermission;
