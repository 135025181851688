import HttpClientAdapter from '@/shared/services/httpClientAdapter';

import {
  Attendance,
  AttendanceFilter,
  AttendanceReasonAndSubReason,
  AttendanceReasonAndSubReasonNormalized,
  AttendanceResponse,
  AttendanceResponseNormalized,
} from '../entities';
import { normalizeAttendances, normalizeReasonAndSubReasonResponse } from '../utils';

class AttendanceService extends HttpClientAdapter {
  constructor() {
    super('SALESFORCE');
  }

  getReasonsAndSubReasons<T = any>() {
    return this.get<T>(
      '/cases/reasons',
      {},
      {
        transformResponse: [this.normalizeReasonsAndSubReasonsResponse],
      },
    );
  }

  getAttendances<T = any>(companyCNPJ: string, params?: AttendanceFilter) {
    const url = `/companies/${companyCNPJ}/cases`;
    return this.get<T>(url, params, {
      transformResponse: [this.normalizeAttendances],
    });
  }

  getAttendance<T = any>(companyCNPJ: string, id: string) {
    const url = `/companies/${companyCNPJ}/cases/${id}`;
    return this.get<T>(url);
  }

  create<T = any>(companyCNPJ: string, data: Partial<Attendance>) {
    const url = `/companies/${companyCNPJ}/cases`;
    return this.post<T>(url, data);
  }

  createAttachment<T = any>(companyCNPJ: string, caseId: string, file: File) {
    const url = `/companies/${companyCNPJ}/cases/${caseId}/attachments?fileName=${encodeURIComponent(
      file.name,
    )}`;
    return this.post<T>(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  private normalizeReasonsAndSubReasonsResponse(
    data: AttendanceReasonAndSubReason,
  ): AttendanceReasonAndSubReasonNormalized {
    return normalizeReasonAndSubReasonResponse(data);
  }

  private normalizeAttendances({
    page,
    total,
    totalPages,
    data,
    pageSize,
  }: AttendanceResponse): AttendanceResponseNormalized {
    return {
      page,
      total,
      totalPages,
      pageSize,
      data: normalizeAttendances(data),
    };
  }
}

export default AttendanceService;
