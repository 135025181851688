import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import React, { useState } from 'react';

import {
  CADASTRAL_DATA_MATCHING_UPLOAD_STEPS,
  Errors,
  Success,
  UploadForm,
} from '@/modules/cadastralDataMatching';

import { ShowHide } from '@/components';
import {
  MovesValidationError,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';

import useCompany from '@/shared/hooks/useCompany/useCompany';
import { Text } from 'vkit/lib/components';

const Upload: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<CADASTRAL_DATA_MATCHING_UPLOAD_STEPS>(
    CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.FORM,
  );
  const [errors, setErrors] = useState<MovesValidationError>(movesValidationErrorInitialState);
  const { hasCompanyConfig } = useCompany();

  return (
    <>
      <ShowHide
        transition='slideToDown'
        visible={!hasCompanyConfig(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT)}>
        <Text
          title
          color='warning'
          value='Esta empresa não possui as configurações necessárias para habilitar o envio de bate cadastral.'
          padding='8px'
          applyBaseColor
          rounded
        />
      </ShowHide>

      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.FORM}>
        <UploadForm setCurrentStep={setCurrentStep} setErrors={setErrors} />
      </ShowHide>

      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.ERROR}>
        <Errors errors={errors} setCurrentStep={setCurrentStep} />
      </ShowHide>

      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_DATA_MATCHING_UPLOAD_STEPS.SUCCESS}>
        <Success />
      </ShowHide>
    </>
  );
};

export default Upload;
