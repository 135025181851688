import React from 'react';

import { DataTable } from 'vkit/lib/components';

import {
  CADASTRAL_POSITION_COLUMNS,
  CADASTRAL_POSITION_LIST_ACTIONS,
} from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import useCadastralPositionTable from '@/modules/cadastralPosition/hooks/useCadastralPositionTable';

interface TableProps {
  actions?: CADASTRAL_POSITION_LIST_ACTIONS[];
  columns?: CADASTRAL_POSITION_COLUMNS[];
  linesLoader?: number;
  filters: ParamsToFilter;
  handleFilters: (params: ParamsToFilter) => void;
}

const CadastralPositionTable: React.FC<TableProps> = ({
  actions,
  columns,
  filters,
  handleFilters,
  linesLoader = 5,
}) => {
  const {
    loading,
    cadastralPositions,
    showPagination,
    pagination,
    handleChangePageSize,
    handlePageChange,
    getActions,
    useColumns,
  } = useCadastralPositionTable({
    actions,
    columns,
    handleFilters,
    filters,
  });

  return (
    <DataTable
      action={actions && actions.length > 0 ? { items: getActions } : {}}
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={cadastralPositions}
      isLoading={loading}
      showPagination={showPagination && !loading}
      total={pagination.total}
      totalPages={pagination.totalPages}
      page={pagination.page}
      pageSize={filters?.pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handleChangePageSize}
    />
  );
};

export default CadastralPositionTable;
