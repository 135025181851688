import { FILE_PERMISSION_ID } from '@/modules/files/entities/fileEntity';
import { capitalize } from 'lodash';
import React from 'react';
import { Checkbox, List, Text } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { DataType } from 'vkit/lib/shared/components/list/types/list.type';
import { ListCategories } from '../../utils';

interface BlockPermissionChildrenListProps {
  data: ListCategories[];
  parentId: string;
  handleItemPermission: (parentId: string, id: string, type: FILE_PERMISSION_ID) => void;
  shouldDisable: (id: string, type: FILE_PERMISSION_ID) => boolean;
  isLoading: boolean;
}

const BlockPermissionChildrenList: React.FC<BlockPermissionChildrenListProps> = ({
  data,
  parentId,
  handleItemPermission,
  shouldDisable,
  isLoading,
}) => {
  const renderTitle = (name: string) => (
    <Row style={{ padding: 0 }}>
      <Text size='medium' value={capitalize(name)} />
    </Row>
  );

  const renderCheckbox = (
    id: string,
    hasPermission: boolean,
    filePermissionId: FILE_PERMISSION_ID,
  ) => {
    const isDisabled = shouldDisable(id, filePermissionId) || isLoading;
    return (
      <Checkbox
        small
        checked={hasPermission}
        onChange={() => handleItemPermission(parentId, id, filePermissionId)}
        disabled={isDisabled}
        title={isDisabled ? 'Você não pode adicionar ou alterar esta permissão.' : ''}
      />
    );
  };

  const columns: DataType[] = [
    {
      title: '',
      value: ({ name }: ListCategories) => renderTitle(name),
    },
    {
      align: 'center',
      title: 'Adicionar',
      value: ({ id, hasAddPermission }: ListCategories) =>
        renderCheckbox(id, hasAddPermission, FILE_PERMISSION_ID.CREATE),
    },
    {
      align: 'center',
      title: 'Visualizar',
      value: ({ id, hasViewPermission }: ListCategories) =>
        renderCheckbox(id, hasViewPermission, FILE_PERMISSION_ID.READ),
    },
    {
      align: 'center',
      title: 'Excluir',
      value: ({ id, hasDeletePermission }: ListCategories) =>
        renderCheckbox(id, hasDeletePermission, FILE_PERMISSION_ID.DELETE),
    },
  ];

  return <List data={data} dataList={columns} />;
};

export default BlockPermissionChildrenList;
