import React, { ReactNode } from 'react';

import formatText from '@/utils/formatText';
import { Accordion, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { CompanyTree } from '../../entities';

interface RecursiveCompanyTreeProps {
  list: CompanyTree[];
  isChildren?: boolean;
  emptyListMessage?: ReactNode;
  notChildrenMessage?: ReactNode;
}

const RecursiveCompanyTree: React.FC<RecursiveCompanyTreeProps> = ({
  list = [],
  isChildren,
  emptyListMessage,
  notChildrenMessage,
}) => {
  const margin: string = `0 0 0 ${isChildren ? '24px' : '0'}`;

  const generateDataTestId = (isChildren?: boolean): string => {
    return isChildren ? 'recursive-company-tree-children' : 'recursive-company-tree';
  };

  const getCompanyNameAndCnpj = (company: CompanyTree) => (
    <Grid column>
      <Text value={company.name} size='medium' />
      <Text value={formatText(company.cnpj, 'cnpj')} />
    </Grid>
  );

  return list.length ? (
    <Grid column gap={8} margin={margin} stretch>
      {list.map((company) => (
        <div
          key={company.cnpj}
          data-testid={generateDataTestId(isChildren)}
          style={{ width: '100%' }}>
          {!isChildren ? (
            <Accordion
              box
              full
              expanded
              overflowVisibleWhenOpen
              title={getCompanyNameAndCnpj(company)}>
              <RecursiveCompanyTree
                isChildren
                list={company.children || []}
                notChildrenMessage={notChildrenMessage}
                emptyListMessage={emptyListMessage}
              />
            </Accordion>
          ) : (
            getCompanyNameAndCnpj(company)
          )}
        </div>
      ))}
    </Grid>
  ) : (
    <>{isChildren ? notChildrenMessage : emptyListMessage}</>
  );
};

export default RecursiveCompanyTree;
