import { ObjectType } from '@/shareds/types';
import React from 'react';
import SelectValidate from '@/components/selectValidate';

export interface SelectPortionTypeProps {
  name?: string;
  onBlur?: Function;
  onChange?: Function;
  useErrors?: ObjectType;
  useValues: ObjectType;
  label?: string;
  required?: boolean;
}

const SelectPortionType: React.FC<SelectPortionTypeProps> = ({
  name = 'portionType',
  onBlur,
  onChange,
  useErrors,
  useValues,
  label = 'Tipo de ajuste',
  required,
}) => {
  return (
    <SelectValidate
      name={name}
      label={label}
      useValues={useValues}
      onChange={onChange}
      onBlur={onBlur}
      useErrors={useErrors}
      data={[
        {
          text: 'Desconto',
          value: 'discount',
        },
        {
          text: 'Acréscimo',
          value: 'charge',
        },
      ]}
      required={required}
    />
  );
};

export default SelectPortionType;
