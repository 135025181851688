import React from 'react';
import { getToken } from '@/utils/authentication';
import { Select } from 'vkit/lib/components';

interface SelectUserProps {
  small?: boolean;
  label?: string;
  selectedUserId?: string;
  onChangeSelectedUser: (...item: any) => void;
}

const SelectUser: React.FC<SelectUserProps> = ({
  small,
  label,
  selectedUserId,
  onChangeSelectedUser,
}) => {
  return (
    <Select
      small={small}
      clearable
      fixedList
      autoRequest
      searchable
      label={label}
      placeholder='Selecione um usuário'
      value={selectedUserId || ''}
      requestPageParam='page'
      requestResponseRootPath='data'
      requestResponseText='name'
      requestResponseValue='id'
      requestRouter='/users'
      requestSearchParam='q'
      requestUri={process.env.REACT_APP_API_AUTH_URL}
      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
      onChange={onChangeSelectedUser}
    />
  );
};

export default SelectUser;
