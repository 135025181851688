import React from 'react';
import { DialogConfirm } from '@/components';
import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';

const Confirm = () => {
  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm();

  return {
    dialogConfirm: (
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />
    ),
    confirm,
  };
};

export default Confirm;
