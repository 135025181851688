import {
  BENEFICIARY_STATUS,
  BENEFICIARY_STATUS_TRANSLATED,
  Beneficiary,
  BeneficiaryNormalized,
  BeneficiaryOwnershipTranslated,
  OWNERSHIP_TRANSLATED,
} from '../entities';

import { normalizeLife } from './normalizeLife';

export const getOwnershipName = (isHolder: boolean): BeneficiaryOwnershipTranslated => {
  return isHolder
    ? OWNERSHIP_TRANSLATED.HOLDER
    : (OWNERSHIP_TRANSLATED.DEPENDENT as BeneficiaryOwnershipTranslated);
};

export const getStatusName = (status: BENEFICIARY_STATUS) => {
  return BENEFICIARY_STATUS_TRANSLATED[status];
};

export const normalizeBeneficiary = (data: Beneficiary): BeneficiaryNormalized => {
  const { status, beneficiaryHolder, id } = data;

  const isHolder = id === beneficiaryHolder.holderId;
  const life = normalizeLife(data.life);

  return {
    ...data,
    isHolder,
    status: getStatusName(status),
    ownership: getOwnershipName(isHolder),
    healthCardNumber: data?.healthCard?.number,
    life,
  };
};
