import React, { ReactNode, useMemo, useState } from 'react';
import { Grid, Row } from 'vkit/lib/context';
import { Button, Text, FloatLayer, TextField } from 'vkit/lib/components';
import { ObjectType } from '@/shareds/types';
import { debounce } from 'lodash';

type FilterType = {
  loading?: boolean;
  onChange?: Function;
  onShow: Function;
  initialValues?: ObjectType;
  fields?: (...itens: any) => ReactNode;
  label?: string;
  searchField?: string | ((...itens: any) => ReactNode);
};

const Filter: React.FC<FilterType> = ({
  loading,
  onChange,
  onShow,
  initialValues,
  fields,
  label,
  searchField,
}) => {
  const [useShow, setShow] = useState(false);
  const [useData, setData] = useState(initialValues || {});
  const [useTermSearch, setTermSearch] = useState('');
  const debounceOnChange = useMemo(
    () => debounce((newFilters: ObjectType) => onChange?.({ ...useData, ...newFilters }), 500),
    [useData, onChange],
  );

  const onSearch = (term: string): void => {
    if (typeof searchField !== 'string') {
      return;
    }

    setTermSearch(term);
    debounceOnChange({ [searchField]: term });
  };

  const onChangeData = (key: string, value: string): void => {
    setData({
      ...useData,
      [key]: value,
    });
  };

  const resetData = (): void => setData(initialValues || {});

  return (
    <>
      {typeof searchField === 'string' ? (
        <Row style={{ padding: '0 8px 0 0', minWidth: 300 }}>
          <TextField
            clearable
            icon='search-outline'
            iconDir='right'
            onChange={onSearch}
            placeholder='Pesquisar'
            small
            value={useTermSearch}
          />
        </Row>
      ) : (
        searchField?.({
          loading,
          onChangeData,
          debounceLoad: debounceOnChange,
          values: useData,
        })
      )}

      {fields && (
        <Row style={{ flex: 1, alignSelf: 'center', padding: '0 0 16px' }}>
          <Grid alignItems='center'>
            <Button
              outlined
              solo={useShow}
              iconColor={useShow ? 'colorDefault' : 'light'}
              onClick={() => {
                setShow(!useShow);
                onShow(!useShow);
              }}
              label={label || 'FILTROS'}
              color={useShow ? 'colorDefault' : 'light'}
              icon='options-2-outline'
            />
          </Grid>

          <FloatLayer
            show={useShow}
            elevation={2}
            onClose={() => {
              setShow(!useShow);
              onShow(!useShow);
            }}
            header={
              <Row>
                <Grid alignContent='justify' alignItems='center'>
                  <Text title value='FILTRAR POR' color='colorDefault' textAlign='center' size='minor' />
                  <Button
                    solo
                    color='light'
                    label='Limpar todos'
                    icon='close-circle-outline'
                    onClick={() => {
                      setShow(false);
                      onShow(false);
                      resetData();
                      onChange?.({});
                      setTermSearch('');
                    }}
                  />
                </Grid>
              </Row>
            }
            footer={
              <Row>
                <Button
                  outlined
                  solo
                  iconColor='colorDefault'
                  onClick={() => {
                    setShow(false);
                    onShow(false);
                    onChange?.(useData);
                  }}
                  label='BUSCAR'
                  icon='search-outline'
                  full
                />
              </Row>
            }>
            <div
              style={{
                padding: '0 20px',
              }}>
              {fields?.({
                loading,
                onChangeData,
                values: useData,
              })}
            </div>
          </FloatLayer>
        </Row>
      )}
    </>
  );
};

export default Filter;
