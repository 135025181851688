import { ToastStatusGlobal } from '@/components/index';
import React, { useCallback, useState } from 'react';
import { Button } from 'vkit/lib/components';
import ButtonType from 'vkit/lib/shared/components/button/types/button.type';

interface DownloadAttachmentProps extends ButtonType {
  attachment: {
    title: string;
    link: string;
  };
}

const DownloadAttachment: React.FC<DownloadAttachmentProps> = ({ attachment, ...props }) => {
  const [loading, setLoading] = useState(false);
  const { toast } = ToastStatusGlobal();

  const downloadFileLink = async (link: string, name: string): Promise<void> => {
    const linkElement = document.createElement('a');
    linkElement.download = name;
    linkElement.href = `${process.env.REACT_APP_API_SALESFORCE_GATEWAY_URL}${link}`;
    linkElement.target = '_blank';
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  };

  const download = useCallback(async () => {
    try {
      setLoading(true);
      await downloadFileLink(attachment.link, attachment.title);
    } catch (error) {
      console.error(error);
      toast('Oops', 'Falha no download do arquivo.', 'error');
    } finally {
      setLoading(false);
    }
  }, [attachment, toast]);

  return <Button loading={loading} onClick={download} {...props} />;
};

export default DownloadAttachment;
