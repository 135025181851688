import React, { ReactNode } from 'react';

import { ObjectType } from '@/shareds/types';
import { StatusEnum } from '@/shareds/types/status.type';
import { Select } from 'vkit/lib/components';

export interface SelectOption<T = Record<string, any>> {
  text: string;
  value: string | number;
  [key: string]: any;
  data?: T;
}

export interface ColumnSelectOption {
  title: string;
  path: string;
  custom?: (option: any) => ReactNode;
}

export interface SelectValidateProps {
  autoRequest?: boolean;
  columns?: ColumnSelectOption[];
  data?: SelectOption[] | null;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  multiple?: boolean;
  name: string;
  onBlur?: Function;
  onChange?: Function;
  placeholder?: string;
  requestHeaders?: { [key: string]: string };
  requestPageParam?: string;
  requestParams?: { [key: string]: string };
  requestResponseRootPath?: string;
  requestResponseText?: string;
  requestResponseValue?: string;
  requestRouter?: string;
  requestSearchParam?: string;
  requestUri?: string;
  required?: boolean;
  searchable?: boolean;
  small?: boolean;
  useErrors?: ObjectType;
  useValues: ObjectType;
  customOption?: (option: SelectOption) => ReactNode;
  customMessageInitial?: string;
  customMessageNotFound?: string;
  fixedList?: boolean;
}

const SelectValidate: React.FC<SelectValidateProps> = ({
  autoRequest,
  columns,
  data,
  disabled,
  label,
  loading,
  multiple,
  name,
  onBlur,
  onChange,
  placeholder,
  requestHeaders,
  requestPageParam,
  requestParams,
  requestResponseRootPath,
  requestResponseText,
  requestResponseValue,
  requestRouter,
  requestSearchParam,
  requestUri,
  required,
  searchable,
  small,
  useErrors,
  useValues,
  customOption,
  customMessageInitial,
  customMessageNotFound,
  fixedList,
}) => (
  <Select
    autoRequest={autoRequest}
    columns={columns}
    clearable
    customOption={customOption}
    data={data}
    disabled={disabled}
    label={label}
    loading={loading}
    multiple={multiple}
    onBlur={(value) => !disabled && onBlur?.(name, value)}
    onChange={(value, selected: SelectOption) => !disabled && onChange?.(name, value, selected)}
    placeholder={placeholder}
    requestHeaders={requestHeaders}
    requestPageParam={requestPageParam}
    requestParams={requestParams}
    requestResponseRootPath={requestResponseRootPath}
    requestResponseText={requestResponseText}
    requestResponseValue={requestResponseValue}
    requestRouter={requestRouter}
    requestSearchParam={requestSearchParam}
    requestUri={requestUri}
    requiredSign={required}
    searchable={searchable}
    small={small}
    status={!disabled && useErrors?.[name] ? StatusEnum.error : undefined}
    textHelper={!disabled ? useErrors?.[name] ?? '' : undefined}
    value={useValues?.[name]}
    customMessageInitial={customMessageInitial}
    customMessageNotFound={customMessageNotFound}
    fixedList={fixedList}
  />
);

export default SelectValidate;
