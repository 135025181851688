import React, { useMemo } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import { Colors } from '@/shareds/types';
import { BENEFICIARY_STATUS_ALIAS_TRANSLATED } from '../../entities';
import Status from '../status/Status';

interface BeneficiaryInfoProps {
  summary?: boolean;
  ownership?: string;
  name?: string;
  benefit?: string;
  status?: BENEFICIARY_STATUS_ALIAS_TRANSLATED;
  isHolder?: boolean;
}

const BeneficiaryInfo: React.FC<BeneficiaryInfoProps> = ({
  isHolder,
  summary,
  ownership,
  name,
  benefit,
  status,
}) => {
  const ownershipSize = summary ? 'minor' : 'small';
  const nameSize = summary ? 'small' : 'larger';
  const gap = summary ? 2 : 8;
  const ownershipColor = useMemo(() => (isHolder ? 'success' : ('colorDefault' as Colors)), [isHolder]);

  return (
    <Grid growing alignItems='center' gap={32}>
      <Grid column gap={gap}>
        {ownership && <Text value={ownership} title color={ownershipColor} size={ownershipSize} />}
        {name && <Text rounded title value={name} size={nameSize} />}
        {benefit && <Text rounded value={benefit} size={nameSize} />}
      </Grid>
      {status && (
        <Grid>
          <Status label={status} />
        </Grid>
      )}
    </Grid>
  );
};

export default BeneficiaryInfo;
