import { useCallback, useMemo, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { BeneficiaryCounters, BeneficiarySearchParams } from '../../entities';
import { BeneficiaryService } from '../../services';

/**
 * Interface for the custom hook `useCountersBeneficiary`.
 * Contains the properties and methods returned by the hook.
 */
export interface UseCountersBeneficiary {
  amountBeneficiaries: number;
  amountDependents: number;
  amountHolders: number;
  amountLives: number;
  counters: BeneficiaryCounters;
  isLoadingCounter: boolean;
  loadCounters: (companyId: string, filters: BeneficiarySearchParams) => void;
}

const beneficiaryService = new BeneficiaryService();

/**
 * Custom hook for fetching and managing beneficiary counters.
 * Returns the counters and related properties and methods.
 */
const useCountersBeneficiary = (): UseCountersBeneficiary => {
  const [isLoadingCounter, setLoadingCounter] = useState<boolean>(false);

  const [counters, setCounters] = useState<BeneficiaryCounters>({
    beneficiaries: 0,
    dependents: 0,
    holders: 0,
    lives: 0,
  });

  const amountBeneficiaries = useMemo(() => counters.beneficiaries || 0, [counters.beneficiaries]);
  const amountHolders = useMemo(() => counters.holders || 0, [counters.holders]);
  const amountLives = useMemo(() => counters.lives || 0, [counters.lives]);
  const amountDependents = useMemo(() => counters.dependents || 0, [counters.dependents]);

  /**
   * Method for loading the beneficiary counters.
   * Fetches the counters from the server based on the provided companyId and filters.
   * Displays a toast message in case of an error.
   */
  const loadCounters = useCallback(async (companyId: string, filters?: BeneficiarySearchParams) => {
    if (!companyId) {
      return;
    }

    setLoadingCounter(true);

    try {
      const response = await beneficiaryService.getCounter<BeneficiaryCounters>(companyId, filters);
      setCounters(response);
    } catch (e) {
      console.error(e);

      dispatchToast({
        text: 'Ops! Não foi possível buscar a contagem de beneficiários.',
      });
    } finally {
      setLoadingCounter(false);
    }
  }, []);

  return {
    amountBeneficiaries,
    amountDependents,
    amountHolders,
    amountLives,
    counters,
    isLoadingCounter,
    loadCounters,
  };
};

export default useCountersBeneficiary;
