import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import { Company } from '@/modules/company/entities';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';

interface IUseCompany {
  company: Company;
  companyId: string | null;
  setCompany: (company: Company) => void;
  getAllCompanyConfigsByKey: (key: COMPANY_CONFIG) => string[];
  getFirstCompanyConfigByKey: (key: COMPANY_CONFIG) => string | undefined;
  hasCompanyConfig: (key: COMPANY_CONFIG) => boolean;
  hasSettingsDataMatchingDefaultLayout: () => boolean;
}

const useCompany = (): IUseCompany => {
  const {
    company,
    companyId,
    setCompany,
    getAllCompanyConfigsByKey,
    getFirstCompanyConfigByKey,
    hasCompanyConfig,
    hasSettingsDataMatchingDefaultLayout,
  } = useApplicationContext();

  return {
    company,
    companyId,
    setCompany,
    getAllCompanyConfigsByKey,
    getFirstCompanyConfigByKey,
    hasCompanyConfig,
    hasSettingsDataMatchingDefaultLayout,
  };
};

export default useCompany;
