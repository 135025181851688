import { get } from 'lodash';
import { Negotiation } from '../entities';

const { REACT_APP_PROVIDER_BY_ID = '{}' } = process.env;

const providerById = JSON.parse(REACT_APP_PROVIDER_BY_ID);

export const isBradesco = (negotiation: Negotiation) => {
  const providerId = get(negotiation, 'product.providerId');
  return providerById[providerId] === 'bradesco';
};
