import imageUrl from '@/assets/images/vwing.svg';
import { Space } from '@/components';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Image, Text } from 'vkit/lib/components';
import { Box, Grid, Row, Scrollbar } from 'vkit/lib/context';
import { StructureAuth } from 'vkit/lib/modules';

const AuthContainer: React.FC = () => (
  <StructureAuth>
    <Scrollbar>
      <div style={{ width: '100vw' }}>
        <Grid alignContent='center'>
          <Row>
            <Row style={{ padding: 0 }}>
              <Grid alignContent='center'>
                <Image width='83px' height='36px' src={imageUrl} />
              </Grid>
              <Row>
                <Grid alignContent='center'>
                  <Text value='Portal do RH' title />
                </Grid>
              </Row>
              <Space horizontal height={16} />
            </Row>
            <div style={{ minWidth: '400px', maxWidth: 750, margin: 'auto' }}>
              <Box elevation={10} type='card' outlined>
                <Row>
                  <Outlet />
                </Row>
              </Box>
            </div>
            <Row>
              <Grid alignContent='center'>
                <Text
                  value={`${new Date().getFullYear()} © Vitta Coordenação em saúde`}
                  color='light'
                />
              </Grid>
            </Row>
          </Row>
        </Grid>
      </div>
    </Scrollbar>
  </StructureAuth>
);

export default AuthContainer;
