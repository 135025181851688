import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { MOVE_LAYOUT_DEFAULT } from '@/modules/move/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { useMemo } from 'react';
import { TFormData } from '../types/FormData.type';

export type ComponentsType = { [key: string]: any };

interface IUseComponentsVisibility {
  showMovimentationType: boolean;
  showUploadField: boolean;
  showFormComponents: boolean;
  showSubmitError: boolean;
  showSubmitSuccess: boolean;
  showSelectProvider: boolean;
  showSelectLayout: boolean;
  showDownloadField: boolean;
}

type UseComponentsVisibilityProps = {
  formData: TFormData;
  hasError: boolean;
  hasSuccess: boolean;
};

const useComponentsVisibility = ({
  formData,
  hasError,
  hasSuccess,
}: UseComponentsVisibilityProps): IUseComponentsVisibility => {
  const { getAllCompanyConfigsByKey } = useCompany();
  const hasMoveLayoutConfig = getAllCompanyConfigsByKey(COMPANY_CONFIG.MOVES_LAYOUT).length > 0;

  const showMovimentationType = useMemo(() => {
    return !!formData.provider;
  }, [formData]);

  const showUploadField = useMemo(() => {
    if (
      hasMoveLayoutConfig &&
      Boolean(formData.layout) &&
      formData.layout !== MOVE_LAYOUT_DEFAULT
    ) {
      return true;
    }

    return !!formData.movimentationType;
  }, [formData.layout, formData.movimentationType, hasMoveLayoutConfig]);

  const showFormComponents = useMemo(() => {
    return !hasError && !hasSuccess;
  }, [hasError, hasSuccess]);

  const showSubmitError = useMemo(() => {
    return hasError && !hasSuccess;
  }, [hasError, hasSuccess]);

  const showSubmitSuccess = useMemo(() => {
    return !hasError && hasSuccess;
  }, [hasError, hasSuccess]);

  const showSelectProvider = useMemo(() => {
    return !hasMoveLayoutConfig || formData.layout === MOVE_LAYOUT_DEFAULT;
  }, [formData.layout, hasMoveLayoutConfig]);

  const showSelectLayout = useMemo(() => {
    return hasMoveLayoutConfig;
  }, [hasMoveLayoutConfig]);

  const showDownloadField = useMemo(() => {
    if (
      hasMoveLayoutConfig &&
      Boolean(formData.layout) &&
      formData.layout !== MOVE_LAYOUT_DEFAULT
    ) {
      return false;
    }

    return !!formData.movimentationType;
  }, [formData.layout, formData.movimentationType, hasMoveLayoutConfig]);

  return {
    showMovimentationType,
    showUploadField,
    showFormComponents,
    showSubmitError,
    showSubmitSuccess,
    showSelectProvider,
    showSelectLayout,
    showDownloadField,
  };
};

export default useComponentsVisibility;
