import { ToastStatusGlobal } from '@/components';
import useConfirm from '@/shareds/hooks/useConfirm';
import { useRef, useState } from 'react';
import {
  MoveStatusDialogConfirm,
  MOVE_STATUS,
  MOVE_STATUS_TRANSLATED,
  MoveNormalized,
} from '@/modules/move/entities';
import { MoveService } from '@/modules/move/services';

interface UseMoveStatusArgs {
  move?: MoveNormalized;
  onChangeStatusCallback?: (status: MOVE_STATUS) => void;
}

const moveService = new MoveService();

const useMoveStatus = ({ move, onChangeStatusCallback }: UseMoveStatusArgs) => {
  const { toast } = ToastStatusGlobal();
  const { confirm, dialogConfirm } = useConfirm();
  const [openDialogConfirmUpdateStatusForm, setOpenDialogConfirmUpdateStatusForm] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const fnCallback = useRef<(response: MoveStatusDialogConfirm) => void>();

  const confirmUpdateStatusMoveAndGetReason = async (status: string) => {
    let confirmUpdate = true;
    let reason = '';

    if (status === MOVE_STATUS.PROCESSED) {
      confirmUpdate = await confirm(
        'Atenção',
        `Ao alterar o status para <b>Processado</b> você não
            poderá alterá-lo novamente. Quer mesmo fazer isso?`,
      );
    }

    if (status === MOVE_STATUS.REJECTED) {
      await new Promise((resolve) => {
        fnCallback.current = (response: MoveStatusDialogConfirm): void => {
          setOpenDialogConfirmUpdateStatusForm(false);
          confirmUpdate = response.confirm;
          reason = response.reason;
          resolve(response);
        };

        setOpenDialogConfirmUpdateStatusForm(true);
      });
    }

    return {
      confirmUpdate,
      reason,
    };
  };

  const onChangeStatus = async (status: MOVE_STATUS) => {
    if (move && move.id && MOVE_STATUS_TRANSLATED[status] !== move.status) {
      setLoadingStatus(true);

      try {
        const { confirmUpdate, reason } = await confirmUpdateStatusMoveAndGetReason(status);

        if (confirmUpdate) {
          await moveService.updateStatus(move.id, { status, rejectionReason: reason });
          toast('Feito!', 'Status do arquivo alterado com sucesso!', 'success');
          onChangeStatusCallback?.(status);
        }
      } catch (e) {
        toast('Oops!', 'Não foi possível alterar o status do arquivo.', 'error');
        console.error('error', e);
      } finally {
        setLoadingStatus(false);
      }
    }
  };

  return {
    onChangeStatus,
    dialogConfirm,
    openDialogConfirmUpdateStatusForm,
    callbackDialogConfirmUpdateStatusForm: fnCallback.current,
    loadingStatus,
  };
};

export default useMoveStatus;
