import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasePagination } from '@/shared/entities';

import { useNegotiations } from '@/modules/negotiation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty as _isEmpty } from 'lodash';
import {
  BeneficiaryNormalized,
  BeneficiaryResponseNormalized,
  BeneficiarySearchParams,
} from '../../entities';
import { BeneficiaryService } from '../../services';

export interface UseBeneficiaries {
  isLoading: boolean;
  beneficiaries: BeneficiaryNormalized[];
  pagination: BasePagination;
  isEmpty: boolean;
}

const beneficiaryService = new BeneficiaryService();

const useBeneficiaries = (filters?: BeneficiarySearchParams): UseBeneficiaries => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryNormalized[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);

  const { negotiationsById } = useNegotiations();

  const loadBeneficiaries = useCallback(
    async (params: BeneficiarySearchParams) => {
      if (!params || !company.id) {
        return;
      }

      setLoading(true);

      try {
        const { page, data, totalPages, total, pageSize } =
          await beneficiaryService.getBeneficiaries<BeneficiaryResponseNormalized>({
            ...params,
            companyId: company.id,
          });

        setBeneficiaries(data);

        setPagination({
          page,
          pageSize,
          totalPages,
          total,
        });
      } catch (e) {
        console.error(e);

        dispatchToast({
          text: 'Ops! Devido a um erro, não conseguimos buscar os beneficiários.',
        });

        setBeneficiaries([]);
        setPagination(LIST_INITIAL_PAGINATION);
      } finally {
        setLoading(false);
      }
    },
    [company.id],
  );

  const isEmptyList = useMemo(
    () => !beneficiaries.length && !isLoading,
    [beneficiaries.length, isLoading],
  );

  const beneficiariesWithNegotiation = useMemo((): BeneficiaryNormalized[] => {
    if (_isEmpty(negotiationsById) || !beneficiaries.length) {
      return [];
    }
    return beneficiaries.map((beneficiary) => {
      return {
        ...beneficiary,
        negotiation: negotiationsById[beneficiary.negotiationId],
      };
    });
  }, [beneficiaries, negotiationsById]);

  useEffect(() => {
    if (!filters || !company.id) {
      return;
    }
    loadBeneficiaries(filters);
  }, [company.id, filters, loadBeneficiaries]);

  return {
    isLoading,
    beneficiaries: beneficiariesWithNegotiation,
    pagination,
    isEmpty: isEmptyList,
  };
};

export default useBeneficiaries;
