import axios from 'axios';

export interface AuthConfirmations {
  registration: boolean;
  totp: boolean;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL,
});

api.interceptors.response.use((response) => response.data.data);

export const authService = {
  checkConfirm: async (userId: string): Promise<AuthConfirmations> => {
    return api.request({
      method: 'GET',
      url: 'auth/confirm',
      params: { userId },
    });
  },

  createEmailConfirm: async (data: { userId: string; code: string }) => {
    return api.request({
      method: 'POST',
      url: 'auth/email-confirmations/sends',
      data,
    });
  },
};
