import { ModalLogin } from '@/modules/authentication/components/modalLogin';
import React, { useEffect } from 'react';

import { checkMobile, classesBuilder } from '@/utils';

import { Header, HeaderProps } from '../../components';

import useMainPageDetails from '@/core/hooks/useMainPageDetails';
import { Outlet } from 'react-router-dom';
import { Grid } from 'vkit/lib/context';
import style from '../../styles/main.module.scss';
import useSetPageTabTitle from '@/core/hooks/useSetPageTabTitle';

interface MainProps extends HeaderProps {
  invertPageHeaderAndChildren?: boolean;
}

const Main: React.FC<MainProps> = ({ children, invertPageHeaderAndChildren }) => {
  const { data } = useMainPageDetails();
  const { setPageTabTitle } = useSetPageTabTitle();

  useEffect(() => {
    setPageTabTitle(data?.title);
  }, [data?.title, setPageTabTitle]);

  const renderChildrenAndHeader = () => {
    if (invertPageHeaderAndChildren) {
      return (
        <>
          {children}

          {data && (
            <Header
              title={data?.name}
              icon={data?.icon}
              iconSrc={data?.customImageUrl}
              hasGoBackAction={data?.hasGoBackAction}
            />
          )}
        </>
      );
    }

    return (
      <>
        {data && (
          <Header
            title={data?.name}
            icon={data?.icon}
            iconSrc={data?.customImageUrl}
            hasGoBackAction={data?.hasGoBackAction}
          />
        )}

        {children}
      </>
    );
  };

  return (
    <div
      className={classesBuilder(style, {
        main: true,
        mobile: checkMobile(),
      })}
    >
      <ModalLogin />
      <div className={style.content}>
        <Grid column gap={24}>
          {renderChildrenAndHeader()}
          <Outlet />
        </Grid>
      </div>
    </div>
  );
};

export default Main;
