import { Negotiation } from '@/modules/negotiation';
import React, { useEffect, useMemo } from 'react';
import { BodyForm } from '@/components/formValidate';
import { BoxInfo, GridGroup, SelectValidate, TextFieldValidate } from '@/components';
import { BANK_ACCOUNT_TYPE, MOVE_FILE_COLUMN_NAME } from '@/pages/moves/shareds/constants';
import TooltipDescription from '@/pages/beneficiaries/createOrUpdate/components/tooltipDescription';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import { isRequiredBankAccount } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/isRequiredBankAccount';
import { PROVIDERS_REQUIRES_BANK } from '@/pages/beneficiaries/shareds/constants/providersRequires';

interface BankAccountFormProps extends BodyForm {
  isDependent: boolean;
  dataToCopy?: BeneficiaryToMove;
  updateBeneficiaryToMove: (beneficiaryToMove: BeneficiaryToMove) => void;
  negotiationById?: { [key: string]: Negotiation };
}

const BankAccountForm: React.FC<BankAccountFormProps> = ({
  onChange,
  onBlur,
  useErrors,
  useValues,
  isDependent,
  dataToCopy,
  updateBeneficiaryToMove,
  negotiationById,
}) => {
  const isRequired = useMemo(() => {
    if (!negotiationById || !useValues.negotiationId) {
      return false;
    }

    return (
      isRequiredBankAccount(negotiationById[useValues.negotiationId], PROVIDERS_REQUIRES_BANK) &&
      !isDependent
    );
  }, [isDependent, negotiationById, useValues.negotiationId]);

  useEffect(() => {
    if (!isDependent) {
      return;
    }
    updateBeneficiaryToMove({
      [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]:
        dataToCopy?.[MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA],
      [MOVE_FILE_COLUMN_NAME.BANCO]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.BANCO],
      [MOVE_FILE_COLUMN_NAME.AGENCIA]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.AGENCIA],
      [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA],
      [MOVE_FILE_COLUMN_NAME.CONTA]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.CONTA],
      [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.DV_DA_CONTA],
    });
  }, [dataToCopy, isDependent, updateBeneficiaryToMove]);

  return (
    <BoxInfo margin={0} title='Dados bancários' icon='inbox-outline'>
      <GridGroup
        body={[
          {
            default: 25,
            component: (
              <SelectValidate
                disabled={isDependent}
                required={isRequired}
                label={MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA}
                name={MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                data={[
                  {
                    text: 'Conta corrente individual',
                    value: BANK_ACCOUNT_TYPE.INDIVIDUAL_CHECKING_ACCOUNT,
                  },
                  {
                    text: 'Conta corrente conjunta',
                    value: BANK_ACCOUNT_TYPE.JOINT_CHECKING_ACCOUNT,
                  },
                  {
                    text: 'Conta poupança individual',
                    value: BANK_ACCOUNT_TYPE.INDIVIDUAL_SAVINGS_ACCOUNT,
                  },
                  {
                    text: 'Conta poupança conjunta',
                    value: BANK_ACCOUNT_TYPE.JOINT_SAVINGS_ACCOUNT,
                  },
                ]}
              />
            ),
          },
          {
            default: 15,
            component: (
              <TextFieldValidate
                required={isRequired}
                disabled={isDependent}
                header={<TooltipDescription description='Número do banco' />}
                maxLength={10}
                label={MOVE_FILE_COLUMN_NAME.BANCO}
                name={MOVE_FILE_COLUMN_NAME.BANCO}
                onBlur={onBlur}
                onChange={onChange}
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
          {
            default: 15,
            component: (
              <TextFieldValidate
                required={isRequired}
                disabled={isDependent}
                maxLength={16}
                label={MOVE_FILE_COLUMN_NAME.AGENCIA}
                name={MOVE_FILE_COLUMN_NAME.AGENCIA}
                onBlur={onBlur}
                onChange={onChange}
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
          {
            default: 15,
            component: (
              <TextFieldValidate
                required={isRequired}
                disabled={isDependent}
                maxLength={1}
                label={MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA}
                name={MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA}
                onBlur={onBlur}
                onChange={onChange}
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
          {
            default: 15,
            component: (
              <TextFieldValidate
                required={isRequired}
                disabled={isDependent}
                maxLength={16}
                label={MOVE_FILE_COLUMN_NAME.CONTA}
                name={MOVE_FILE_COLUMN_NAME.CONTA}
                onBlur={onBlur}
                onChange={onChange}
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
          {
            default: 15,
            component: (
              <TextFieldValidate
                required={isRequired}
                disabled={isDependent}
                maxLength={1}
                label={MOVE_FILE_COLUMN_NAME.DV_DA_CONTA}
                name={MOVE_FILE_COLUMN_NAME.DV_DA_CONTA}
                onBlur={onBlur}
                onChange={onChange}
                useErrors={useErrors}
                useValues={useValues}
              />
            ),
          },
        ]}
      />
    </BoxInfo>
  );
};

export default BankAccountForm;
