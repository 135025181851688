import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useState } from 'react';

import { MoveSheetNormalized } from '../../entities';
import { MoveSheetService } from '../../services';

interface UseMoveSheetLineProps {
  rowId: string;
  triggerRequest?: boolean;
}

const moveService = new MoveSheetService();

const useMoveSheetLine = ({ rowId, triggerRequest }: UseMoveSheetLineProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sheetLine, setSheetLine] = useState<MoveSheetNormalized>();

  const loadMoveSheetLine = useCallback(async (idRow: string) => {
    if (!idRow) {
      return;
    }

    setLoading(true);

    try {
      const response = await moveService.getMoveSheetLine<MoveSheetNormalized>(idRow);
      setSheetLine(response);
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! não foi possível buscar os dados de validação desta movimentação.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!rowId || !triggerRequest) {
      return;
    }

    loadMoveSheetLine(rowId);
  }, [loadMoveSheetLine, rowId, triggerRequest]);

  return {
    isLoading,
    sheetLine,
    loadMoveSheetLine,
  };
};

export default useMoveSheetLine;
