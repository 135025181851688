import { LoaderFunctionArgs } from 'react-router-dom';

import { INITIAL_ROUTE } from '@/core/entities/types/routes.const';
import { isAuthenticated, removeStoredData } from '@/utils/authentication';
import { redirect } from '../utils';

import AuthRoutes from '@/modules/authentication/routes/routes';

const IGNORING_ROUTES_TO_REDIRECT = ['/', INITIAL_ROUTE];

export const protectedLoader = ({ request }: LoaderFunctionArgs) => {
  if (!isAuthenticated()) {
    const queryParams = new URLSearchParams();
    let currentUrl = new URL(request.url).pathname;
    const currentSearch = new URL(request.url).search;

    if (currentSearch) {
      currentUrl = currentUrl + currentSearch;
    }

    if (!IGNORING_ROUTES_TO_REDIRECT.includes(currentUrl)) {
      queryParams.set('redirect', encodeURIComponent(currentUrl));
    }

    removeStoredData();
    return redirect('/auth/login?' + queryParams.toString());
  }

  return null;
};

/**
 *
 * Esta função só existe enquanto todas as rotas enviadas em comunicação, ainda não foram
 * atualizadas, assim garantimos que comunicações que foram enviadas mais recentemente e ainda estão em uso
 * funcionam.
 * @param param
 * @returns
 */
export const loaderOldRoutes = ({ request }: LoaderFunctionArgs) => {
  const pathname = new URL(request.url).pathname;
  const searchParams = new URL(request.url).search;

  const findedRoute = AuthRoutes.find((route) => {
    return route.children && route.children.find((child) => `/${child.path}` === pathname);
  });

  if (findedRoute) {
    return redirect(`/auth${pathname}${searchParams}`);
  }

  return null;
};
