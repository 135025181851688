import { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { AxiosError } from 'axios';
import {
  AuthResetPasswordConfirmation,
  authResetPasswordConfirmationError,
  authResetPasswordConfirmationInitState,
  authResetPasswordConfirmationService,
} from '@/services/accessControl/endpoints/authResetPasswordConfirmation';
import { useHistoryQuery } from '@/navigation';
import { ObjectType } from '@/shareds/types';
import {
  authPasswordRecoveryUsersError,
  authPasswordRecoveryUsersService,
} from '@/services/accessControl/endpoints/authPasswordRecoveryUsers';

export type Steps = 'newPassword' | 'success' | 'expired';

interface IUseAuthResetPasswordConfirmation {
  checkConfirmPasswordsValid: (password: string) => string | null;
  confirmPassword: () => Promise<void>;
  setData: (data: AuthResetPasswordConfirmation) => void;
  setMessageError: (messageError: string) => void;
  useCurrentStep: Steps;
  useData: AuthResetPasswordConfirmation;
  useDisabledForm: boolean;
  useLoading: boolean;
  useMessageError: string;
}

const useAuthResetPassword = (): IUseAuthResetPasswordConfirmation => {
  const [useData, setData] = useState<AuthResetPasswordConfirmation>(
    authResetPasswordConfirmationInitState,
  );
  const [useLoading, setLoading] = useState(false);
  const [useDisabledForm, setDisabledForm] = useState(false);
  const [useMessageError, setMessageError] = useState('');
  const [useCurrentStep, setCurrentStep] = useState<Steps>('newPassword');
  const queryString = useHistoryQuery();

  const checkConfirmPasswordsValid = (confirmPassword: string): string | null => {
    if (confirmPassword !== useData.newPassword) {
      return 'Senhas não conferem';
    }

    return null;
  };

  const authResetPasswordConfirmationDTO = (data: ObjectType): AuthResetPasswordConfirmation => ({
    code: queryString.code,
    newPassword: data.newPassword,
    userId: queryString.userId,
  });

  const getMessageError = (error: AxiosError, errorMap: ObjectType): string => {
    let messageError = get(error, 'response.data.message', '');
    Object.keys(errorMap).forEach((key) => {
      if (RegExp(key).test(messageError)) {
        messageError = errorMap[key];
      }
    });
    return messageError;
  };

  const confirmPassword = async (): Promise<void> => {
    try {
      setLoading(true);
      setMessageError('');
      await authResetPasswordConfirmationService.create(authResetPasswordConfirmationDTO(useData));
      setCurrentStep('success');
    } catch (error) {
      console.error(error);
      const messageError = getMessageError(error as AxiosError, authResetPasswordConfirmationError);
      setMessageError(messageError || 'Falha ao confirmar a conta.');
    } finally {
      setLoading(false);
    }
  };

  const validateCode = useCallback(
    async (params: { code: string; userId: string }): Promise<void> => {
      try {
        setLoading(true);
        setMessageError('');
        await authPasswordRecoveryUsersService.find({}, `${params.userId}/${params.code}`);
      } catch (error) {
        console.error(error);
        const messageError = getMessageError(error as AxiosError, authPasswordRecoveryUsersError);
        setMessageError(messageError || 'Link inválido ou expirado.');
        setCurrentStep('expired');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (!queryString.code || !queryString.userId) {
      setMessageError(
        'Falha nos dados desta conta, não temos informações suficientes para alterar a senha.',
      );
      setDisabledForm(true);
      return;
    }

    (async () =>
      validateCode({
        code: queryString.code,
        userId: queryString.userId,
      }))();
  }, [validateCode, queryString.code, queryString.userId]);

  return {
    checkConfirmPasswordsValid,
    confirmPassword,
    setData,
    setMessageError,
    useCurrentStep,
    useData,
    useDisabledForm,
    useLoading,
    useMessageError,
  };
};

export default useAuthResetPassword;
