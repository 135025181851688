import { BoxInfo, FileUpload, FormValidate, GridGroup, ToastStatusGlobal } from '@/components';
import { BodyForm } from '@/components/formValidate';
import { CADASTRAL_POSITION_ALLOWED_EXTENSIONS } from '@/modules/cadastralPosition/entities/cadastralPosition.const';
import { CADASTRAL_POSITION_UPLOAD_STEPS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import useCadastralPositionUpload from '@/modules/cadastralPosition/hooks/useCadastralPositionUpload';
import { SelectCompany } from '@/modules/company';
import { SELECT_NEGOTIATION_COLUMNS } from '@/modules/negotiation/entities/enums/selectNegotiation';
import SelectNegotiations from '@/modules/negotiation/views/selectNegotiations/selectNegotiations';
import { useHistoryNavigator } from '@/navigation';
import { MovesValidationError } from '@/services/files/endpoints/moves';
import ICONS from '@/shareds/constants/icons';
import { StatusEnum } from '@/shareds/types/status.type';
import { downloadFileLink } from '@/utils/common';
import React from 'react';
import { Button } from 'vkit/lib/components';

interface UploadFormProps {
  setCurrentStep: (step: CADASTRAL_POSITION_UPLOAD_STEPS) => void;
  setErrors: (errors: MovesValidationError) => void;
}

const UploadForm: React.FC<UploadFormProps> = ({ setCurrentStep, setErrors }) => {
  const navigate = useHistoryNavigator();
  const { toast } = ToastStatusGlobal();

  const { formValues, loading, setFormValues, handleSubmit, handleError } =
    useCadastralPositionUpload({
      setCurrentStep,
      setErrors,
    });

  const downloadTemplateFile = (): void => {
    const cadastralPositonTemplate = process.env.REACT_APP_API_CADASTRAL_POSITION_TEMPLATE_FILE_URL;
    if (cadastralPositonTemplate) {
      downloadFileLink(cadastralPositonTemplate, 'posicao-cadastral-template.csv');
    } else {
      toast('Oops!', 'Falha ao baixar arquivo.', 'error');
    }
  };

  const getFields = ({ onChange, onBlur, useErrors, useValues }: BodyForm) => (
    <GridGroup
      body={[
        {
          default: 100,
          middle: 100,
          component: (
            <SelectCompany
              required
              label='Empresa'
              selectedCompanyId={useValues.companyId}
              onChangeSelectedCompany={(value) => {
                setFormValues((current) => ({
                  ...current,
                  companyId: value,
                  negotiationId: '',
                }));
              }}
              errorMessage={useErrors.companyId}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <SelectNegotiations
              key={useValues.companyId}
              onChange={(value) => onChange('negotiationId', value)}
              value={useValues.negotiationId}
              columns={[
                SELECT_NEGOTIATION_COLUMNS.CONTRACT,
                SELECT_NEGOTIATION_COLUMNS.PRODUCT,
                SELECT_NEGOTIATION_COLUMNS.BENEFIT,
                SELECT_NEGOTIATION_COLUMNS.ANS,
                SELECT_NEGOTIATION_COLUMNS.COST_CENTER,
              ]}
              status={useErrors?.negotiationId ? StatusEnum.error : undefined}
              textHelper={useErrors?.negotiationId}
              required
              disabled={!useValues.companyId}
              companyId={useValues.companyId}
              searchable
              onlyActiveContract
            />
          ),
        },

        {
          default: 100,
          middle: 100,
          component: (
            <FileUpload
              label='Selecione a planilha correspondente'
              name='files'
              allowedExtensions={CADASTRAL_POSITION_ALLOWED_EXTENSIONS}
              onChange={(name: string, value: File) => {
                onChange(name, value);
                onBlur(name, value);
              }}
              formValidatorErrors={useErrors}
            />
          ),
        },
      ]}
    />
  );

  return (
    <BoxInfo
      title='Upload de posição cadastral'
      icon={ICONS.CADASTRAL_POSITION}
      header={
        <Button bold invertColor onClick={downloadTemplateFile} label='Download do arquivo modelo' />
      }>
      <FormValidate
        resource={formValues}
        onChangeForm={setFormValues}
        onBack={navigate.goBack}
        onSubmit={handleSubmit}
        onError={handleError}
        fields={{
          companyId: ['required'],
          negotiationId: ['required'],
          files: ['required'],
        }}
        body={getFields}
        buttons={{
          submit: {
            text: 'Concluir',
            loading,
          },
          back: {
            text: 'Voltar',
          },
        }}
      />
    </BoxInfo>
  );
};

export default UploadForm;
