export enum COLUMN {
  SUBJECT = 'subject',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  FORECAST = 'forecast',
  REASON = 'reason',
  UPDATED_AT = 'updatedAt',
}

export enum ACTION {
  DETAIL = 'detail',
}

export type Column = `${COLUMN}`;

export type Action = `${ACTION}`;

export interface List {
  columns: Column[];
  actions: Action[];
}

export const AVAILABLE_COLUMNS = {
  [COLUMN.SUBJECT]: {
    title: 'ASSUNTO',
    path: 'subject',
    width: '30%',
  },
  [COLUMN.CREATED_AT]: {
    title: 'CRIADO EM',
    path: 'createdAt',
    width: '100px',
  },
  [COLUMN.STATUS]: {
    title: 'STATUS',
    path: 'status',
  },
  [COLUMN.FORECAST]: {
    title: 'PREVISÃO',
    width: '120px',
    path: 'forecast',
  },
  [COLUMN.REASON]: {
    title: 'MOTIVO',
    path: 'reason',
  },
  [COLUMN.UPDATED_AT]: {
    title: 'ATUALIZADO EM',
    path: 'updatedAt',
    width: '120px',
  },
};
