import React from 'react';
import { DataTable } from 'vkit/lib/components';
import {
  INITIAL_PAGINATION_PARAMS,
  USER_AVAILABLE_TABLE_ACTIONS,
  USER_AVAILABLE_TABLE_COLUMNS,
  USER_TYPE,
  User,
  UserSearchParams,
} from '../../entities';
import { useUsers } from '../../hooks';
import useTableUserActions from './hooks/useTableUserActions';
import useTableUserColumns from './hooks/useTableUserColumns';

interface TableUsersProps {
  type: `${USER_TYPE}`;
  filters?: Partial<UserSearchParams>;
  onChange?: (filters: Partial<UserSearchParams>) => void;
  actions?: `${USER_AVAILABLE_TABLE_ACTIONS}`[];
  columns?: `${USER_AVAILABLE_TABLE_COLUMNS}`[];
}

const TableUsers: React.FC<TableUsersProps> = ({ type, filters, onChange, actions, columns }) => {
  const { isLoading, users, pagination, hasPermission } = useUsers({
    type,
    filters,
  });

  const { getActions } = useTableUserActions({
    type,
    actions,
  });
  const { currentColumns } = useTableUserColumns({ columns });

  const emptyTitle = !hasPermission ? 'Sem permissão' : undefined;
  const emptyMessage = !hasPermission
    ? 'Não é possível exibir os registros de usuários'
    : undefined;

  return (
    <DataTable
      action={{
        items: ({ id, status }: User) => getActions(id ?? '', status),
      }}
      data={users}
      columns={currentColumns}
      isLoading={isLoading}
      emptyTitle={emptyTitle}
      emptyDescription={emptyMessage}
      total={pagination?.total}
      totalPages={pagination?.totalPages || 1}
      page={pagination?.page ?? INITIAL_PAGINATION_PARAMS.PAGE}
      pageSize={filters?.pageSize}
      onChangePage={(value: number) => onChange?.({ page: value })}
      onChangePageSize={(value: number) => onChange?.({ pageSize: value })}
    />
  );
};

export default TableUsers;
