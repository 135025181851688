import { ButtonActions } from '@/components';
import { ActionOption } from '@/components/buttonActions';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Button } from 'vkit/lib/components';
import { MOVE_CUSTOM_SHEET, MoveAttachment } from '../../entities';

interface TransformedDataActionProps {
  type: `${MOVE_CUSTOM_SHEET}`;
  attachments: MoveAttachment[];
}

const TransformedDataAction: React.FC<TransformedDataActionProps> = ({ type, attachments }) => {
  const { downloadFile } = useFileDownload();

  const getLabel = (current: `${MOVE_CUSTOM_SHEET}`, multiple: boolean) => {
    if (multiple) {
      if (current === MOVE_CUSTOM_SHEET.TRANSFORMED) {
        return 'Dados transformados';
      }
      return 'Dados com observações';
    }
    if (current === MOVE_CUSTOM_SHEET.TRANSFORMED) {
      return 'Download dados transformados';
    }
    return 'Download dados com observações';
  };

  /**
   * Extract provider name from filename
   * @param filename
   * @returns { string }
   *
   * @example
   * filename: transformado_example_company_criacao.xls.xlsx
   * returns: example
   */
  const extractProviderFromFilename = (filename: string): string => {
    const parts = filename.split('_');
    let provider = 'Sem provedor';

    if (parts[1]) {
      provider = parts[1].toLowerCase();
    }

    return provider;
  };

  const mappingInfos = (
    attachment: MoveAttachment,
    isSingle: boolean,
  ): Record<string, string | Function> => {
    if (isSingle) {
      return {
        label: getLabel(type, false),
        onClick: () => downloadFile(attachment.id),
      };
    }

    const provider = extractProviderFromFilename(attachment.name);
    return {
      label: `Para ${provider}`,
      onClick: () => downloadFile(attachment.id),
    };
  };

  const renderInsightsAction = () => {
    if (!attachments.length) {
      return (
        <Button
          outlined
          solo
          iconColor='light'
          disabled
          icon={ICONS.DOWNLOAD}
          label={getLabel(type, false)}
        />
      );
    }
    if (attachments.length === 1) {
      const { label, onClick } = mappingInfos(attachments[0], true);
      return (
        <Button
          outlined
          solo
          iconColor='colorDefault'
          icon={ICONS.DOWNLOAD}
          label={label as string}
          onClick={onClick as () => {}}
        />
      );
    }
    return (
      <ButtonActions
        outlined
        color='default'
        label={getLabel(type, false)}
        actionOptions={
          attachments.map((attachment) => {
            const { label, onClick } = mappingInfos(attachment, true);
            return {
              label,
              onClick,
            };
          }) as ActionOption[]
        }
      />
    );
  };

  return renderInsightsAction();
};

export default TransformedDataAction;
