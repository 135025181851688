import { SELECT_NEGOTIATION_COLUMNS } from '@/modules/negotiation/entities/enums/selectNegotiation';
import { ColumnSelectOption } from '@/components/selectValidate';
import { Negotiation } from '@/modules/negotiation';
import { useCallback, useMemo } from 'react';

interface UseSelectNegotiations {
  negotiations: Negotiation[];
  columns?: SELECT_NEGOTIATION_COLUMNS[];
}

const useSelectNegotiations = ({ negotiations, columns }: UseSelectNegotiations) => {
  const columnsToChoose: Record<SELECT_NEGOTIATION_COLUMNS, ColumnSelectOption> = useMemo(
    () => ({
      [SELECT_NEGOTIATION_COLUMNS.CONTRACT]: {
        title: 'Contrato',
        path: 'contract.code',
      },
      [SELECT_NEGOTIATION_COLUMNS.COST_CENTER]: {
        title: 'Centro de custo',
        path: 'costCenter.name',
      },
      [SELECT_NEGOTIATION_COLUMNS.PRODUCT]: {
        title: 'Produto',
        path: 'product.name',
      },
      [SELECT_NEGOTIATION_COLUMNS.BENEFIT]: {
        title: 'Benefício',
        path: 'product.benefit.name',
      },
      [SELECT_NEGOTIATION_COLUMNS.ANS]: {
        title: 'ANS',
        path: 'product.ansCode',
      },
    }),
    [],
  );

  const getSelectOptionText = useCallback(
    ({ contract, product, costCenter }: Negotiation) => {
      const textByColumns = {
        [SELECT_NEGOTIATION_COLUMNS.CONTRACT]: `contrato: ${contract.code || 'Não informado'}`,
        [SELECT_NEGOTIATION_COLUMNS.COST_CENTER]: `centro de custo: ${
          costCenter.name || 'Não informado'
        }`,
        [SELECT_NEGOTIATION_COLUMNS.PRODUCT]: `produto: ${product.name || 'Não informado'}`,
        [SELECT_NEGOTIATION_COLUMNS.BENEFIT]: `benefício: ${product.benefit.name || 'Não informado'}`,
        [SELECT_NEGOTIATION_COLUMNS.ANS]: `ANS: ${product.ansCode || 'Não informado'}`,
      };

      const texts = columns
        ? columns.map((column) => textByColumns[column])
        : Object.values(textByColumns);

      return texts.join(' | ');
    },
    [columns],
  );

  const negotiationOptions = useMemo(() => {
    return negotiations.map((negotiation: Negotiation) => ({
      ...negotiation,
      text: getSelectOptionText(negotiation),
      value: negotiation.id,
    }));
  }, [getSelectOptionText, negotiations]);

  const columnSelectOptions = useMemo(() => {
    return columns
      ? columns.map((column) => columnsToChoose[column])
      : Object.values(columnsToChoose);
  }, [columns, columnsToChoose]);

  return {
    columnSelectOptions,
    negotiationOptions,
  };
};

export default useSelectNegotiations;
