import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { USER_TYPE } from '@/services/users/endpoints/users';
import ICONS from '@/shareds/constants/icons';

import { createRouteLoaderPageDetails } from '@/core/utils';
import { Main } from '@/core/views';
import Details from '@/modules/cadastralPosition/pages/details/details';
import List from '@/modules/cadastralPosition/pages/list/list';
import Upload from '@/modules/cadastralPosition/pages/upload/upload';

const pageName = 'Posição cadastral';

export enum ROUTE_CADASTRAL_POSITION {
  LIST = 'cadastral-position-list',
  DETAIL = 'cadastral-position-detail',
  UPLOAD = 'cadastral-position-upload',
  SUCCESS = 'cadastral-position-success',
}

export const cadastraPositionRoutes: Route[] = [
  {
    path: '/cadastral-positions',
    Component: Main,
    id: ROUTE_CADASTRAL_POSITION.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.BROKER,
      showApplicationMenu: true,
      icon: ICONS.CADASTRAL_POSITION,
      nameMenu: pageName,
      permission: {
        type: USER_TYPE.BROKER,
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        groupedBy: APPLICATION_GROUP_BY.BROKER,
        icon: ICONS.CADASTRAL_POSITION,
        name: pageName,
        title: 'Posição Cadastral',
      });
    },
    children: [
      {
        index: true,
        Component: List,
      },
      {
        path: ':cadastralPositionId/details',
        id: ROUTE_CADASTRAL_POSITION.DETAIL,
        Component: Details,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            icon: ICONS.CADASTRAL_POSITION,
            name: pageName,
            hasGoBackAction: true,
            title: 'Posição Cadastral | Detalhes',
          });
        },
      },
      {
        path: 'actions/upload',
        id: ROUTE_CADASTRAL_POSITION.UPLOAD,
        Component: Upload,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.BROKER,
            hasGoBackAction: true,
            name: 'Envio de arquivo',
            icon: ICONS.UPLOAD,
            title: 'Posição Cadastral | Upload',
          });
        },
      },
    ],
  },
];

export default cadastraPositionRoutes;
