import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { EventLogParams, ResponseEventLog } from '../entities';

class AuditService extends HttpClientAdapter {
  constructor() {
    super('AUTH');
  }

  getEventLogs(params: Partial<EventLogParams>): Promise<ResponseEventLog> {
    return this.get<ResponseEventLog>('/event-logs', params);
  }
}

export default AuditService;
