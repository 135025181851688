import { useCallback, useState } from 'react';
import addressesService, { Address } from '@/services/negotiations/endpoints/addresses';

const useAddress = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<Address[]>([]);

  const loadAddress = useCallback(async (companyId: string) => {
    if (!companyId) {
      return;
    }

    setLoading(true);

    try {
      const address = (await addressesService(companyId).list({ companyId })) as Address[];
      setAddress(address);
    } catch (e) {
      console.error(e);

      const toastEvent = new CustomEvent('toast', {
        detail: {
          icon: 'close-circle-outline',
          title: 'Oops',
          text: 'Ops! Devido a um erro, não conseguimos buscar as informações de endereço da empresa.',
          type: 'danger',
          align: 'right',
          titleColor: 'danger',
          position: 'bottom',
          shadow: true,
          duration: 10,
        },
      });

      window.dispatchEvent(toastEvent);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    loadAddress,
    address,
  };
};

export default useAddress;
