import {
  FinancialAdjustment,
  FinancialAdjustmentToTable,
} from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import { PERSISTENCE_STATUS } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.enum';
import NegotiationsService from '@/shared/services/negotiationsService';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

interface UseFinancialAdjustmentPersistenceStatusProps {
  removeFinancialAdjustmentCallback?: Function;
  saveManyFinancialAdjustmentsCallback?: () => void;
  financialAdjustments?: FinancialAdjustmentToTable[];
}

const negotiationsService = new NegotiationsService();

export const useFinancialAdjustmentPersistence = ({
  financialAdjustments,
  removeFinancialAdjustmentCallback,
  saveManyFinancialAdjustmentsCallback,
}: UseFinancialAdjustmentPersistenceStatusProps) => {
  const [persistenceStatusByFinancialAdjustmentKey, setPersistenceStatusByFinancialAdjustmentKey] =
    useState<Record<string, PERSISTENCE_STATUS>>({});

  const [updatingPersistence, setUpdatingPersistence] = useState(false);

  const removeFinancialAdjustment = useCallback(
    async (id: string) => {
      setUpdatingPersistence(true);
      try {
        await negotiationsService.removeFinancialAdjustment(id);
        removeFinancialAdjustmentCallback?.();
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! Não foi possível excluir este ajuste financeiro.',
        });
      } finally {
        setUpdatingPersistence(false);
      }
    },
    [removeFinancialAdjustmentCallback],
  );

  const updatePersistenceStatus = useCallback((key: string, status: PERSISTENCE_STATUS) => {
    setPersistenceStatusByFinancialAdjustmentKey((current) => ({
      ...current,
      [key]: status,
    }));
  }, []);

  const normalizeFinancialAdjustment = useCallback(
    (financialAdjustmentToSave: FinancialAdjustmentToTable) => {
      const date = parseISO(financialAdjustmentToSave.date);

      return {
        companyGroupId: financialAdjustmentToSave.companyGroupId,
        contractId: financialAdjustmentToSave.contractId,
        description: financialAdjustmentToSave.description,
        unit: financialAdjustmentToSave.unit,
        date: format(date, 'dd/MM/yyyy'),
        value: financialAdjustmentToSave.value,
      } as FinancialAdjustment;
    },
    [],
  );

  const saveFinancialAdjustment = useCallback(
    async (financialAdjustmentToSave?: FinancialAdjustmentToTable) => {
      if (!financialAdjustmentToSave) {
        return PERSISTENCE_STATUS.ERROR;
      }

      setUpdatingPersistence(true);
      updatePersistenceStatus(financialAdjustmentToSave.key, PERSISTENCE_STATUS.PERSISTING);

      try {
        await negotiationsService.createFinancialAdjustment<FinancialAdjustment>(
          normalizeFinancialAdjustment(financialAdjustmentToSave),
        );
        updatePersistenceStatus(financialAdjustmentToSave.key, PERSISTENCE_STATUS.PERSISTED);
        return PERSISTENCE_STATUS.PERSISTED;
      } catch (e) {
        console.error(e);
        updatePersistenceStatus(financialAdjustmentToSave.key, PERSISTENCE_STATUS.ERROR);
        return PERSISTENCE_STATUS.ERROR;
      } finally {
        setUpdatingPersistence(false);
      }
    },
    [normalizeFinancialAdjustment, updatePersistenceStatus],
  );

  const saveManyFinancialAdjustments = useCallback(
    async (financialAdjustmentsToSave: FinancialAdjustmentToTable[]) => {
      const statusList: PERSISTENCE_STATUS[] = [];

      for (const financialAdjustmentToSave of financialAdjustmentsToSave) {
        const result = await saveFinancialAdjustment(financialAdjustmentToSave);
        statusList.push(result);
      }

      const allPersisted = statusList.every((status) => status === PERSISTENCE_STATUS.PERSISTED);

      if (allPersisted) {
        saveManyFinancialAdjustmentsCallback?.();
      }
    },
    [saveFinancialAdjustment, saveManyFinancialAdjustmentsCallback],
  );

  useEffect(() => {
    if (!financialAdjustments) {
      return;
    }

    setPersistenceStatusByFinancialAdjustmentKey((current) => {
      return financialAdjustments.reduce((acc, financialAdjustment) => {
        if (!financialAdjustment.key) {
          return acc;
        }

        return {
          ...acc,
          [financialAdjustment.key]:
            current[financialAdjustment.key] || PERSISTENCE_STATUS.NOT_PERSISTED,
        };
      }, {});
    });
  }, [financialAdjustments]);

  return {
    persistenceStatusByFinancialAdjustmentKey,
    updatePersistenceStatus,
    updatingPersistence,
    removeFinancialAdjustment,
    saveManyFinancialAdjustments,
  };
};
