import React from 'react';
import { DataTable } from 'vkit/lib/components';
import { CONFIRMATION_TABLE_COLUMNS, Confirmation, TYPES } from '../../entities/confirmation';
import useHistoryConfirmationResend from './hooks/useHistoryConfirmationResend';
import useUserConfirmationHistory from './hooks/useUserConfirmationHistory';
import useUserConfirmationTableColumns from './hooks/useUserConfirmationTableColumns';

interface ListConfirmationHistoryProps {
  userId: string;
  columns?: `${CONFIRMATION_TABLE_COLUMNS}`[];
  loading?: boolean;
}

const ListConfirmationHistory: React.FC<ListConfirmationHistoryProps> = ({
  userId,
  columns,
  loading: externalLoading,
}) => {
  const { loading, history } = useUserConfirmationHistory({ userId });

  const { availableColumns } = useUserConfirmationTableColumns({ columns });

  const { loadingResend, disabledResend, onResend } = useHistoryConfirmationResend();

  return (
    <DataTable
      columns={availableColumns}
      isLoading={externalLoading || loading}
      data={history}
      showPagination={false}
      action={{
        items: ({ isExpired, type, confirmedAt }: Confirmation) => {
          if (!confirmedAt && (!isExpired || type === TYPES.REGISTRATION)) {
            return [
              {
                disabled: disabledResend,
                label: 'Reenviar',
                loading: loadingResend,
                onClick: () => onResend({ userId, type }),
                outlined: true,
                size: 'small',
                solo: true,
              },
            ];
          }
        },
      }}
    />
  );
};

export default ListConfirmationHistory;
