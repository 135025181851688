import { Route } from '@/core/entities';
import Auth from '@/pages/auth/auth';
import AuthContainer from '../pages/authContainer/authContainer';
import { Blocked, Success } from '../views';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';

export enum AUTH_ROUTE_NAMES {
  LOGIN = 'authentication-login',
  CONFIRM_EMAIL = 'authentication-confirm-email',
  TOTP = 'authentication-totp',
  FORGOT_PASSWORD = 'authentication-forgot-password',
  RESET_PASSWORD = 'authentication-reset-password',
  CONFIRMATION = 'authentication-confirmation-expired-or-not-exist',
  SUCCESS = 'authentication-success',
  BLOCKED = 'authentication-blocked',
}

const Routes: Route[] = [
  {
    path: '/auth',
    Component: AuthContainer,
    children: [
      {
        path: 'login',
        name: AUTH_ROUTE_NAMES.LOGIN,
        Component: Auth,
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Login',
          });
        },
      },
      {
        path: 'confirm-email',
        name: AUTH_ROUTE_NAMES.CONFIRM_EMAIL,
        Component: Auth,
      },
      {
        path: 'totp',
        name: AUTH_ROUTE_NAMES.TOTP,
        Component: Auth,
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Código de verificação',
          });
        },
      },
      {
        path: 'forgot-password',
        name: AUTH_ROUTE_NAMES.FORGOT_PASSWORD,
        Component: Auth,
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Esqueci minha senha',
          });
        },
      },
      {
        path: 'reset-password',
        name: AUTH_ROUTE_NAMES.RESET_PASSWORD,
        Component: Auth,
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Atualizar senha',
          });
        },
      },
      {
        path: 'confirmation-expired-or-not-exist',
        name: AUTH_ROUTE_NAMES.CONFIRMATION,
        Component: Auth,
      },
      {
        path: 'success',
        name: AUTH_ROUTE_NAMES.SUCCESS,
        Component: Success,
      },
      {
        path: 'blocked',
        name: AUTH_ROUTE_NAMES.BLOCKED,
        Component: Blocked,
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Bloqueado',
          });
        },
      },
    ],
  },
];

export default Routes;
