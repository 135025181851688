export enum COMPANY_PERMISSION {
  COMPANY_USER_UNBLOCK = 'company_user:unblock',
  COMPANY_USER_CREATE = 'company_user:create',
  COMPANY_USER_DELETE = 'company_user:delete',
  COMPANY_USER_READ = 'company_user:read',
  COMPANY_USER_RESTORE = 'company_user:restore',
  COMPANY_USER_UPDATE = 'company_user:update',
  MOVE_CREATE = 'movimentation:create',
}

export enum BROKER_PERMISSION {
  BROKER_USER_UNBLOCK = 'broker_user:unblock',
  BROKER_USER_CREATE = 'broker_user:create',
  BROKER_USER_DELETE = 'broker_user:delete',
  BROKER_USER_READ = 'broker_user:read',
  BROKER_USER_RESTORE = 'broker_user:restore',
  BROKER_USER_UPDATE = 'broker_user:update',
  CADASTRAL_DATA_MATCHING_CREATE = 'cadastral-data-matching:create',
}
