import { get, hasIn } from 'lodash';
import { ObjectType } from '@/shareds/types';
import { isBradesco } from '@/pages/beneficiaries/shareds/utils/checkProvider';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';

export const canRemoveBeneficiary = (life: ObjectType, negotiation: ObjectType, status: string) => {
  const isActive = ['pre-canceled', 'active', 'waiting', 'pre-active', 'extended'].includes(status);
  if (!isActive) {
    return false;
  }

  const hasEnoughAttributesInNegotiation = [
    'costCenter.name',
    'contract.code',
    'product.providerId',
    'product.benefit.name',
    'product.provider.name',
  ].every((key) => hasIn(negotiation, key));
  if (!hasEnoughAttributesInNegotiation) {
    return false;
  }

  const costCenterName = get(negotiation, 'costCenter.name');
  if (isBradesco(negotiation as Negotiation) && isNaN(costCenterName)) {
    return false;
  }

  return ['name', 'cpf'].every((key) => hasIn(life, key));
};
