import { BoxInfo, Divider, EmptyMessage, Tooltip } from '@/components';
import { ContentListLines } from '@/components/dataTable/components';
import { Device, EntityInfo } from '@/modules/audit/components';
import {
  AUDIT_LOGS_DEFAULT_INITIAL_PAGE,
  AUDIT_LOGS_DEFAULT_PAGE_SIZE,
  AUDIT_TABLE_COLUMNS,
  EventLog,
  EventLogData,
  EventLogParams,
} from '@/modules/audit/entities';
import { useAuditRecords } from '@/modules/audit/hooks';
import { UserView } from '@/modules/profile';
import { analytics } from '@/shared/telemetry/telemetry';
import { getFormattedDate } from '@/utils/format';
import React, { useMemo, useState } from 'react';
import { Button, Pagination } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import DetailsDialog from '../detailsDialog/detailsDialog';

export interface TableProps {
  columns?: `${AUDIT_TABLE_COLUMNS}`[];
  filters: EventLogParams;
  handleFilters: (params: EventLogParams) => void;
}

const AVALIBLE_COLUMNS = {
  [AUDIT_TABLE_COLUMNS.USER]: {
    title: 'NOME',
    custom: ({ userName, userEmail }: EventLog) => (
      <UserView userName={userName} userEmail={userEmail} />
    ),
  },
  [AUDIT_TABLE_COLUMNS.ACTION]: {
    title: 'AÇÃO',
    path: 'eventLogAction.name',
  },
  [AUDIT_TABLE_COLUMNS.USER_IP]: {
    title: 'IP/NAVEGADOR',
    width: '25%',
    custom: ({ userIp, userAgent }: EventLog) =>
      userIp || userAgent ? <Device data={{ userIp, userAgent }} /> : '-',
  },
  [AUDIT_TABLE_COLUMNS.ENTITY]: {
    title: 'ENTIDADE',
    custom: ({ eventLogEntity, entityRecordId }: EventLog) =>
      eventLogEntity?.name ? (
        <EntityInfo
          data={{
            name: eventLogEntity.name,
            recordId: entityRecordId,
          }}
        />
      ) : (
        '-'
      ),
    path: 'eventLogEntity.name',
  },
  [AUDIT_TABLE_COLUMNS.DATE]: {
    title: 'DATA',
    custom: ({ eventTime }: EventLog) =>
      getFormattedDate(eventTime.toString(), { showHours: true }),
  },
};

const Table: React.FC<TableProps> = ({ columns, filters, handleFilters }) => {
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [currEventLogData, setCurrEventLogData] = useState<EventLogData[]>([]);

  const { isEmptyLogs, pagination, showTable, logs, isLoading } = useAuditRecords(filters);

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column: `${AUDIT_TABLE_COLUMNS}`) => AVALIBLE_COLUMNS[column]);
  }, [columns]);

  const handleDetails = (id: string) => {
    analytics.track('AUDIT_DETAILS');
    const updated = logs.find((log) => log.id === id);
    if (updated) {
      setCurrEventLogData(updated.eventLogData);
    }
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    setCurrEventLogData([]);
  };

  const getAction = (id: string, disabled: boolean) => {
    const tooltipMessage = disabled
      ? 'Ação desabilitada por não possuir registro de detalhes'
      : undefined;
    return (
      <Grid alignContent='left'>
        <Tooltip title={tooltipMessage}>
          <Button
            outlined
            solo
            size='medium'
            label='Detalhes'
            icon='eye'
            iconColor={disabled ? 'light' : 'colorDefault'}
            onClick={() => handleDetails(id)}
            disabled={disabled}
          />
        </Tooltip>
      </Grid>
    );
  };

  const onChangePageSize = (pageSize: number) => {
    handleFilters({
      ...filters,
      pageSize,
      page: 1,
    });
  };

  const onChangePage = (page: number) => {
    handleFilters({
      ...filters,
      page,
    });
  };

  return (
    <BoxInfo elevation={undefined} margin={0} padding={1}>
      <DetailsDialog open={isDetailsOpen} data={currEventLogData} fnCallback={handleCloseDetails} />

      {showTable && (
        <ContentListLines
          actionsByLine={({ id, eventLogData }: EventLog) => getAction(id, !eventLogData.length)}
          columns={useColumns}
          data={logs}
          linesLoader={3}
          loading={isLoading}
        />
      )}

      {isEmptyLogs && (
        <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
      )}

      {showTable && (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px' }}>
            <Pagination
              page={pagination?.page || AUDIT_LOGS_DEFAULT_INITIAL_PAGE}
              totalPages={pagination.totalPages}
              total={pagination.total || 0}
              pageSize={pagination?.pageSize || AUDIT_LOGS_DEFAULT_PAGE_SIZE}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </Row>
        </>
      )}
    </BoxInfo>
  );
};

export default Table;
