import { BoxInfo, EmptyMessage } from '@/components';
import { ContentListLines } from '@/components/dataTable/components';
import { EventLogData } from '@/modules/audit/entities';
import React, { useMemo } from 'react';
import { getFormattedDate } from '@/utils/format';

export interface AuditUpdatedDataProps {
  data: EventLogData[];
}

const AuditUpdatedData: React.FC<AuditUpdatedDataProps> = ({ data }) => {
  const isEmpty = useMemo(() => !data?.length, [data]);

  const useColumns = [
    {
      title: 'CAMPO',
      path: 'field',
    },
    {
      title: 'VALOR ANTERIOR',
      path: 'oldValue',
    },
    {
      title: 'NOVO VALOR',
      path: 'newValue',
    },
    {
      title: 'Data',
      custom: ({ createdAt }: EventLogData) => getFormattedDate(createdAt, { showHours: true }),
    },
  ];

  return (
    <BoxInfo elevation={undefined} margin={0} padding={1}>
      {!isEmpty ? <ContentListLines columns={useColumns} data={data} linesLoader={3} /> : null}

      {isEmpty ? (
        <EmptyMessage title='Nada por aqui!' description='Nenhum registro encontrado.' />
      ) : null}
    </BoxInfo>
  );
};

export default AuditUpdatedData;
