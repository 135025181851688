import { GridGroup } from '@/components';
import { Over } from '@/modules/contract/entities/over';
import React from 'react';
import { TextField } from 'vkit/lib/components';

interface OverItemProps {
  over: Over;
  isFirstItem: boolean;
}

const OverItem: React.FC<OverItemProps> = ({ over, isFirstItem }) => {
  return (
    <GridGroup
      body={[
        {
          default: 30,
          middle: 30,
          component: (
            <TextField
              label={isFirstItem ? 'De' : undefined}
              readOnly
              type='number'
              value={over.ageBegin}
              disabled
            />
          ),
        },
        {
          default: 30,
          middle: 30,
          component: (
            <TextField
              label={isFirstItem ? 'Até' : undefined}
              readOnly
              type='number'
              value={over.ageEnd}
              disabled
            />
          ),
        },
        {
          default: 40,
          middle: 40,
          component: (
            <TextField
              label={isFirstItem ? 'Over (%)' : undefined}
              readOnly
              type='number'
              value={over.markup === 0 ? '0' : over.markup}
              disabled
            />
          ),
        },
      ]}
    />
  );
};

export default OverItem;
