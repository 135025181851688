import { DataTable } from '@/modules/audit/views';
import React from 'react';
import { Row } from 'vkit/lib/context';

const Home: React.FC = () => {
  return (
    <Row style={{ padding: 0 }}>
      <DataTable />
    </Row>
  );
};

export default Home;
