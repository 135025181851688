import { MOVE_SHEET_COLUMN } from './moveSheetColumn';

export enum MOVE_SHEET_OWNERSHIP {
  HOLDER,
  DEPENDENT,
}

export const MOVE_SHEET_OWNERSHIP_TRANSLATE = Object.freeze({
  [MOVE_SHEET_OWNERSHIP.HOLDER]: 'Titular',
  [MOVE_SHEET_OWNERSHIP.DEPENDENT]: 'Dependente',
});

export enum MOVE_SHEET_GENDER {
  MALE = 1,
  FEMALE = 2,
}

export const MOVE_SHEET_GENDER_TRANSLATE = Object.freeze({
  [MOVE_SHEET_GENDER.MALE]: 'Masculino',
  [MOVE_SHEET_GENDER.FEMALE]: 'Feminino',
});

export enum MOVE_SHEET_MARITAL_STATUS {
  SINGLE = 1,
  MARRIED = 2,
  WIDOWED = 3,
  OTHERS = 4,
}

export const MOVE_SHEET_MARITAL_STATUS_TRANSLATE = Object.freeze({
  [MOVE_SHEET_MARITAL_STATUS.SINGLE]: 'Solteiro(a)',
  [MOVE_SHEET_MARITAL_STATUS.MARRIED]: 'Casado(a)',
  [MOVE_SHEET_MARITAL_STATUS.WIDOWED]: 'Viúvo(a)',
  [MOVE_SHEET_MARITAL_STATUS.OTHERS]: 'Outros',
});

export enum MOVE_SHEET_DEGREE_KINSHIP {
  SPOUSE = 1,
  CHILD = 2,
  MOTHER = 3,
  FATHER = 4,
  FATHER_IN_LAW = 5,
  MOTHER_IN_LAW = 6,
  GUARDIAN = 7,
  OTHERS = 8,
}

export const MOVE_SHEET_DEGREE_KINSHIP_TRANSLATE = Object.freeze({
  [MOVE_SHEET_DEGREE_KINSHIP.SPOUSE]: 'Conjuge',
  [MOVE_SHEET_DEGREE_KINSHIP.CHILD]: 'Filho(a)',
  [MOVE_SHEET_DEGREE_KINSHIP.MOTHER]: 'Mãe',
  [MOVE_SHEET_DEGREE_KINSHIP.FATHER]: 'Pai',
  [MOVE_SHEET_DEGREE_KINSHIP.FATHER_IN_LAW]: 'Sogro',
  [MOVE_SHEET_DEGREE_KINSHIP.MOTHER_IN_LAW]: 'Sogra',
  [MOVE_SHEET_DEGREE_KINSHIP.GUARDIAN]: 'Tutelado(a)',
  [MOVE_SHEET_DEGREE_KINSHIP.OTHERS]: 'Outros',
});

export enum MOVE_SHEET_COMPANY_LINK {
  NO_LINK_IDENTIFICATION,
  EMPLOYEE,
  PARTNER,
  TRAINEE,
  APPRENTICE,
  TEMPORARY,
}

export const MOVE_SHEET_COMPANY_LINK_TRANSLATE = Object.freeze({
  [MOVE_SHEET_COMPANY_LINK.NO_LINK_IDENTIFICATION]: 'Sem identificação de vínculo',
  [MOVE_SHEET_COMPANY_LINK.EMPLOYEE]: 'Funcionário',
  [MOVE_SHEET_COMPANY_LINK.PARTNER]: 'Sócio(a)',
  [MOVE_SHEET_COMPANY_LINK.TRAINEE]: 'Estagiário(a)',
  [MOVE_SHEET_COMPANY_LINK.APPRENTICE]: 'Aprendiz',
  [MOVE_SHEET_COMPANY_LINK.TEMPORARY]: 'Temporário(a)',
});

export enum MOVE_SHEET_DELETE_REASON {
  WITHDRAWAL = 1,
  DISMISSAL_WITHOUT_JUST_CAUSE = 2,
  RETIREMENT = 3,
  DEATH = 4,
  DUPLICITY = 5,
  PORTABILITY = 6,
  NON_PAYMENT = 7,
  DISMISSAL_WITH_JUST_CAUSE = 8,
}

export const MOVE_SHEET_DELETE_REASON_TRANSLATE = Object.freeze({
  [MOVE_SHEET_DELETE_REASON.WITHDRAWAL]: 'Desitência',
  [MOVE_SHEET_DELETE_REASON.DISMISSAL_WITHOUT_JUST_CAUSE]: 'Demissão s/ justa causa',
  [MOVE_SHEET_DELETE_REASON.RETIREMENT]: 'Aposentadoria',
  [MOVE_SHEET_DELETE_REASON.DEATH]: 'Falecimento',
  [MOVE_SHEET_DELETE_REASON.DUPLICITY]: 'Duplicidade',
  [MOVE_SHEET_DELETE_REASON.PORTABILITY]: 'Portabilidade',
  [MOVE_SHEET_DELETE_REASON.NON_PAYMENT]: 'Inadimplência',
  [MOVE_SHEET_DELETE_REASON.DISMISSAL_WITH_JUST_CAUSE]: 'Demissão c/ justa causa',
});

export enum MOVE_SHEET_YES_OR_NO {
  s = 's',
  n = 'n',
  S = 'S',
  N = 'N',
}

export const MOVE_SHEET_YES_OR_NO_TRANSLATE = Object.freeze({
  [MOVE_SHEET_YES_OR_NO.s]: 'Sim',
  [MOVE_SHEET_YES_OR_NO.S]: 'Sim',
  [MOVE_SHEET_YES_OR_NO.n]: 'Não',
  [MOVE_SHEET_YES_OR_NO.N]: 'Não',
});

export enum MOVE_SHEET_BANK_ACCOUNT_TYPE {
  INDIVIDUAL_CHECKING_ACCOUNT,
  JOINT_CHECKING_ACCOUNT,
  INDIVIDUAL_SAVINGS_ACCOUNT,
  JOINT_SAVINGS_ACCOUNT,
}

export const BANK_ACCOUNT_TYPE_TRANSLATE = Object.freeze({
  [MOVE_SHEET_BANK_ACCOUNT_TYPE.INDIVIDUAL_CHECKING_ACCOUNT]: 'Conta corrente individual',
  [MOVE_SHEET_BANK_ACCOUNT_TYPE.JOINT_CHECKING_ACCOUNT]: 'Conta corrente conjunta',
  [MOVE_SHEET_BANK_ACCOUNT_TYPE.INDIVIDUAL_SAVINGS_ACCOUNT]: 'Conta poupança individual',
  [MOVE_SHEET_BANK_ACCOUNT_TYPE.JOINT_SAVINGS_ACCOUNT]: 'Conta poupança conjunta',
});

export const MOVE_SHEET_TRANSLATE_VALUES: { [key: string]: any } = Object.freeze({
  [MOVE_SHEET_COLUMN.titularidade]: MOVE_SHEET_OWNERSHIP_TRANSLATE,
  [MOVE_SHEET_COLUMN.sexo]: MOVE_SHEET_GENDER_TRANSLATE,
  [MOVE_SHEET_COLUMN.estadoCivil]: MOVE_SHEET_MARITAL_STATUS_TRANSLATE,
  [MOVE_SHEET_COLUMN.cpt]: MOVE_SHEET_YES_OR_NO_TRANSLATE,
  [MOVE_SHEET_COLUMN.grauParentesco]: MOVE_SHEET_DEGREE_KINSHIP_TRANSLATE,
  [MOVE_SHEET_COLUMN.vinculoComEmpresa]: MOVE_SHEET_COMPANY_LINK_TRANSLATE,
  [MOVE_SHEET_COLUMN.motivoDelecao]: MOVE_SHEET_DELETE_REASON_TRANSLATE,
  [MOVE_SHEET_COLUMN.opcaoPelaRN279]: MOVE_SHEET_YES_OR_NO_TRANSLATE,
  [MOVE_SHEET_COLUMN.tipoContaBancaria]: BANK_ACCOUNT_TYPE_TRANSLATE,
});
