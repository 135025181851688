export enum AUDIT_PERMISSION {
  READ = 'audit:read',
}

export const AUDIT_LOGS_DEFAULT_PAGE_SIZE = 10;

export const AUDIT_LOGS_DEFAULT_INITIAL_PAGE = 1;

export enum AUDIT_TABLE_COLUMNS {
  ACTION = 'action',
  DATE = 'date',
  ENTITY = 'entity',
  USER = 'user',
  USER_IP = 'userIp',
}
