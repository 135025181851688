import { BoxInfo, ShowHide } from '@/components';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Loader, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const { REACT_APP_ORIGIN_URL_USER = '' } = process.env;

interface IFrameUsersProps {
  breadcrumb?: {
    name: string;
    href?: string;
  }[];
  loading?: boolean;
  scopeId: string;
  scopeType: 'broker' | 'company';
  title?: string;
}

const IFrameUsers: React.FC<IFrameUsersProps> = ({
  breadcrumb,
  loading = false,
  scopeId,
  scopeType,
  title,
}) => {
  const useFrame = useRef<HTMLIFrameElement | null>();
  const [useShowError, setShowError] = useState(false);
  const [useIFrameLoading, setIFrameLoading] = useState(true);

  let observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      if (useFrame.current?.contentWindow) {
        useFrame.current.contentWindow.postMessage(
          {
            'vkit-theme': document.body.getAttribute('data-theme'),
          },
          REACT_APP_ORIGIN_URL_USER,
        );
      }
    });
  });

  const sendTokenToFrame = (): void => {
    if (!useFrame.current?.contentWindow) {
      return setShowError(true);
    }

    useFrame.current.contentWindow.postMessage(
      {
        token: localStorage.getItem('token'),
        title,
        breadcrumb: breadcrumb && JSON.stringify(breadcrumb),
        'vkit-theme': localStorage.getItem('vkit-theme'),
      },
      REACT_APP_ORIGIN_URL_USER,
    );
  };

  const onLoad = (): void => {
    setTimeout(() => {
      sendTokenToFrame();
      setIFrameLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setIFrameLoading(true);
  }, [scopeId]);

  useEffect(() => {
    const { body } = document;
    observer.observe(body, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  });

  return (
    <div style={{ margin: '0 -20px' }}>
      <ShowHide transition='fade' visible={!!scopeId && (loading || useIFrameLoading)}>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Loader color='colorDefault' />
        </div>
      </ShowHide>

      <ShowHide transition='fade' visible={!!scopeId}>
        <iframe
          src={`${REACT_APP_ORIGIN_URL_USER}/${scopeType}/${scopeId}/users`}
          ref={(frame) => (useFrame.current = frame)}
          onLoad={onLoad}
          loading='lazy'
          style={{
            height: 'calc(100vh - 150px)',
            width: '100%',
            marginBottom: '-150px',
          }}
        />
      </ShowHide>

      <ShowHide transition='fade' visible={useShowError}>
        <BoxInfo>
          <Row>
            <Grid alignContent='center'>
              <Icon color='danger' size={64} name='close-circle-outline' />
            </Grid>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Text title color='danger' value='Erro ao carregar a página!' margin='0 0 8px' />

            <Text
              size='medium'
              value='Parece que tivemos algum problema<br>ao carregar essa página, tente de novo mais tarde.'
            />
          </Row>
        </BoxInfo>
      </ShowHide>
    </div>
  );
};

export default IFrameUsers;
