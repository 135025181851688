import { Company } from '@/modules/company';
import { COMPANY_CONFIG, COMPANY_CONFIG_VALUE } from '@/modules/company/entities/company.enum';
import { useCallback, useMemo, useState } from 'react';

interface TemplateDefaultConfig {
  name: string | null;
  value: string | null;
}

const useCompanyApplication = () => {
  const [company, setCompany] = useState<Company>(
    JSON.parse(localStorage.getItem('company') ?? '{}'),
  );

  const companyId = useMemo((): string | null => {
    return company?.id || null;
  }, [company]);

  const isMainCompany = useMemo(() => {
    return typeof company?.salesforceId === 'string' && company?.salesforceParentId === null;
  }, [company]);

  const isInactiveCompany = useMemo(() => {
    return !!company?.inactive;
  }, [company?.inactive]);

  const statusCompany = useMemo(() => {
    return company?.status;
  }, [company?.status]);

  const companyConfigsByKey = useMemo(() => {
    if (!company.companyConfigs) {
      return {};
    }

    return company.companyConfigs.reduce(
      (acc: Partial<Record<COMPANY_CONFIG, string[]>>, config) => {
        if (!acc[config.name]) {
          acc[config.name] = [];
        }

        if (config.value) {
          acc[config.name]?.push(config.value);
        }

        return acc;
      },
      {},
    );
  }, [company.companyConfigs]);

  const getAllCompanyConfigsByKey = useCallback(
    (key: COMPANY_CONFIG) => {
      return companyConfigsByKey[key] ?? [];
    },
    [companyConfigsByKey],
  );

  const getFirstCompanyConfigByKey = useCallback(
    (key: COMPANY_CONFIG) => {
      return companyConfigsByKey[key]?.[0];
    },
    [companyConfigsByKey],
  );

  const hasCompanyConfig = useCallback(
    (key: COMPANY_CONFIG) => {
      return company.companyConfigs?.some((companyConfig) => companyConfig.name === key) ?? false;
    },
    [company.companyConfigs],
  );

  const hasSettingsDataMatchingDefaultLayout = useCallback(() => {
    if (company?.companyConfigs) {
      return company.companyConfigs.some((config: TemplateDefaultConfig) => {
        return (
          config.name === COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT &&
          config.value === COMPANY_CONFIG_VALUE.CADASTRAL_DATA_MATCHING_LAYOUT_DEFAULT
        );
      });
    }
    return false;
  }, [company]);

  return {
    company,
    setCompany,
    companyId,
    isMainCompany,
    getAllCompanyConfigsByKey,
    getFirstCompanyConfigByKey,
    hasCompanyConfig,
    hasSettingsDataMatchingDefaultLayout,
    isInactiveCompany,
    statusCompany,
  };
};

export default useCompanyApplication;
