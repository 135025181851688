import { Contract } from '@/modules/contract/entities/contract';
import { CONTRACT_STATUS } from '@/modules/contract/entities/contract.enum';
import contractsService from '@/services/negotiations/endpoints/contracts';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { isBefore, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

interface UseContract {
  contractId?: string | null;
}

const useContract = ({ contractId }: UseContract) => {
  const { company } = useCompany();

  const [isLoadingContract, setLoadingContract] = useState<boolean>(false);
  const [contract, setContract] = useState<Contract>();

  const extractContractsToForm = (contract: Contract) => {
    const isExperied =
      !!contract?.validUntil && isBefore(parseISO(contract.validUntil), new Date());
    const isCancelled = contract?.status === 'cancelled';

    let statusToForm = CONTRACT_STATUS.ACTIVE;

    if (isExperied) {
      statusToForm = CONTRACT_STATUS.EXPIRED;
    }

    if (isCancelled) {
      statusToForm = CONTRACT_STATUS.CANCELLED;
    }

    return {
      ...contract,
      statusToForm,
    };
  };

  const loadContract = useCallback(
    async (contractIdToFind: string) => {
      if (!company.id || !contractIdToFind) {
        return;
      }
      setLoadingContract(true);

      try {
        const response = await contractsService(company.id || '').find(contractIdToFind);
        setContract(extractContractsToForm(response));
        return extractContractsToForm(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingContract(false);
      }
    },
    [company.id],
  );

  useEffect(() => {
    if (!contractId || !company.id) {
      return;
    }
    loadContract(contractId);
  }, [company.id, contractId, loadContract]);

  return {
    isLoadingContract,
    contract,
  };
};

export default useContract;
