import { getFormattedDate } from '@/utils/format';
import {
  Move,
  MOVE_STATUS_TRANSLATED,
  MOVE_TYPE_TRANSLATED,
  MoveNormalized,
  MoveNormalizedProvider,
  MoveStatusTranslation,
} from '../entities';

export const normalizeStatus = ({ status }: Move): MoveStatusTranslation => {
  return MOVE_STATUS_TRANSLATED[status];
};

export const normalizeType = ({ type }: Move) => {
  return MOVE_TYPE_TRANSLATED[type];
};

export const normalizeProvider = ({ type }: Move): MoveNormalizedProvider => {
  return type?.includes('bradesco') ? 'Bradesco' : 'Outro';
};

export const normalizeList = (moves: Move[]): MoveNormalized[] => {
  if (!Array.isArray(moves)) {
    return [];
  }

  return moves.map(
    (move: Move): MoveNormalized => ({
      ...move,
      status: normalizeStatus(move),
      type: normalizeType(move),
      typeKey: move.type,
      provider: normalizeProvider(move),
      createdAt: getFormattedDate(move.createdAt || '', { showHours: true }),
      updatedAt: getFormattedDate(move.updatedAt || '', { showHours: true }),
      uploadedAt: getFormattedDate(move.uploadedAt || '', { showHours: true }),
    }),
  );
};
