import useCompanyApplication from '@/core/hooks/useCompanyApplication';
import { Company } from '@/modules/company';
import { COMPANY_CONFIG, COMPANY_STATUS } from '@/modules/company/entities/company.enum';
import { User, userInitialState } from '@/modules/profile';
import React, { createContext, useMemo } from 'react';
import useAuthenticatedUser from '../hooks/useAuthenticatedUser';

export type ApplicationContext = {
  company: Company;
  setCompany: (company: Company) => void;
  companyId: string | null;
  isMainCompany: boolean;
  isInactiveCompany: boolean;
  statusCompany?: COMPANY_STATUS | null;
  user: User;
  setUser: (user: User) => void;
  getAllCompanyConfigsByKey: (key: COMPANY_CONFIG) => string[];
  getFirstCompanyConfigByKey: (key: COMPANY_CONFIG) => string | undefined;
  hasCompanyConfig: (key: COMPANY_CONFIG) => boolean;
  hasSettingsDataMatchingDefaultLayout: () => boolean;
};

const defaultApplicationContext: ApplicationContext = {
  company: {
    id: '',
    name: '',
    cnpj: '',
  },
  setCompany: () => {
    throw new Error('setCompany not implemented');
  },
  companyId: null,
  isMainCompany: false,
  user: userInitialState,
  isInactiveCompany: false,
  statusCompany: null,
  setUser: () => {
    throw new Error('setUser not implemented');
  },
  getAllCompanyConfigsByKey: () => {
    throw new Error('getAllCompanyConfigsByKey not implemented');
  },
  getFirstCompanyConfigByKey: () => {
    throw new Error('getFirstCompanyConfigByKey not implemented');
  },
  hasCompanyConfig: () => {
    throw new Error('hasCompanyConfig not implemented');
  },
  hasSettingsDataMatchingDefaultLayout: () => {
    throw new Error('hasSettingsDataMatchingDefaultLayout not implemented');
  },
};

export const applicationContext = createContext<ApplicationContext>(defaultApplicationContext);

const ProvideContext: React.FC = ({ children }) => {
  const company = useCompanyApplication();
  const user = useAuthenticatedUser();

  const contextValue = useMemo(
    () => ({
      ...company,
      ...user,
    }),
    [company, user],
  );

  return <applicationContext.Provider value={contextValue}>{children}</applicationContext.Provider>;
};
export default ProvideContext;
