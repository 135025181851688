/**
 * useCurrentUser Hook
 *
 * This hook retrieves the user token and returns the corresponding user object,
 * along with additional properties related to user type, default company, and user companies.
 *
 */

import { useMemo } from 'react';

import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import { Company } from '@/modules/company';
import { USER_TYPE, User } from '../../entities';

/**
 * Represents the return type of the useCurrentUser hook.
 */
interface UseCurrentUser {
  /**
   * The user object containing user information.
   */
  user: User;

  /**
   * Indicates whether the user is a broker user.
   */
  isBrokerUser: boolean;

  /**
   * Indicates whether the user is a company user.
   */
  isCompanyUser: boolean;

  /**
   * The default company associated with the user (for company users only).
   * This property may be undefined if no default company is set.
   */
  defaultCompany?: Company;

  /**
   * The companies associated with the user (for company users only).
   * This property may be undefined if no companies are associated.
   */
  userCompanies?: Company[];

  /**
   * Indicates whether the use can switch companies
   */
  canSwitcherCompany: boolean;

  /**
   * Indicates a user collection of permissions
   */
  userPermissions: string[];

  /** */
  checkUserHasPermission: (permissionId: string) => boolean;
}

const useCurrentUser = (): UseCurrentUser => {
  const { user } = useApplicationContext();

  const isBrokerUser = useMemo(() => {
    return user.type === USER_TYPE.BROKER;
  }, [user.type]);

  const isCompanyUser = useMemo(() => {
    return !isBrokerUser;
  }, [isBrokerUser]);

  const defaultCompany = useMemo((): Company | undefined => {
    return user.scopes?.find(({ id }) => user.scopeIds[0] === id);
  }, [user.scopeIds, user.scopes]);

  const userCompanies = useMemo((): Company[] | undefined => {
    return user.scopes;
  }, [user.scopes]);

  const canSwitcherCompany = useMemo(() => {
    return isBrokerUser || (isCompanyUser && !!userCompanies && userCompanies?.length > 1);
  }, [isBrokerUser, isCompanyUser, userCompanies]);

  const userPermissions = useMemo(() => {
    return user.permissions;
  }, [user]);

  const checkUserHasPermission = (permissionId: string) => {
    return user.permissions.some((permission) => permission === permissionId);
  };

  return {
    user,
    isBrokerUser,
    isCompanyUser,
    defaultCompany,
    userCompanies,
    canSwitcherCompany,
    userPermissions,
    checkUserHasPermission,
  };
};

export default useCurrentUser;
