import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Text, Select } from 'vkit/lib/components';
import { Box, Grid } from 'vkit/lib/context';
import translate from '../utils';
import { maskText, getFormattedDate, maskMobileNumber, maskPhoneNumber } from '@/utils/format';
import style from '../style/textData.module.scss';
import { ParsedTextItemDataType } from '../types/textData.type';

const formatters = {
  cardCode: (value: string) => maskText(value, '9999 '.repeat(20)),
  cep: (value: string) => maskText(value, '99999-999'),
  cpf: (value: string) => maskText(value, '999.999.999-99'),
  cnpj: (value: string) => maskText(value, '99.999.999/9999-99'),
  date: (value: string) => getFormattedDate(value),
  dateWithoutTZ: (value: string) => getFormattedDate(value, { discardTimezone: true }),
  dateTime: (value: string) => getFormattedDate(value, { showHours: true }),
  marital: (value: string) => translate.marital(value),
  mobileNumber: (value: string) => maskMobileNumber(value),
  phoneNumber: (value: string) => maskPhoneNumber(value),
  relationship: (value: string) => translate.relationship(value),
  boolean: (value: string) => translate.boolean(value),
  rg: (value: string) => maskText(value, '99.999.999-9'),
  sex: (value: string) => translate.sex(value),
  text: (value: string) => value,
}

export type FormatData = keyof typeof formatters

const TextItemData: React.FC<ParsedTextItemDataType> = ({
  label,
  loading,
  text,
  type = 'text',
  sizeDefault,
  sizeMedium,
  sizeMobile,
  data,
  selected,
  onSelectectChange,
  abbreviationOf,
  component,
  disabled,
}) => {
  const [useText, setText] = useState<string>('');
  const [useSize, setSize] = useState(0);

  const formatText = useCallback((value: string, key: FormatData): string => {
    if (!value) {
      return '';
    }

    return formatters[key](value) || '-'
  }, []);

  const currentComponent = useMemo(() => {
    if (type === 'select') {
      return (
        <Select
          label={label}
          data={data}
          value={selected || ''}
          onChange={onSelectectChange}
          small
          disabled={disabled}
          loading={loading}
        />
      );
    }
    return (
      <div>
        <Text
          color='colorDefault'
          padding='0 0 6px'
          size='medium'
          value={
            abbreviationOf
              ? `<abbr title='${abbreviationOf}'><b>${label}</b></abbr>`
              : `<b>${label}</b>`
          }
        />
        <Text rounded size='larger' value={useText} />
      </div>
    );
  }, [abbreviationOf, data, disabled, label, onSelectectChange, selected, type, useText, loading]);

  useEffect(() => {
    if (loading) {
      setText('');
    } else if (type !== 'select'){
      setText(formatText(text ?? '', type));
    }
  }, [text, type, loading, formatText]);

  useEffect(() => {
    if (sizeMobile && window.innerWidth <= 768) {
      setSize(sizeMobile);
    } else if (sizeMedium && window.innerWidth <= 1024) {
      setSize(sizeMedium);
    } else if (sizeDefault) {
      setSize(sizeDefault);
    }
  }, [sizeMobile, sizeMedium, sizeDefault]);

  return (
    <div
      className={style.textItemData}
      style={{
        maxWidth: useSize ? `calc(${useSize}% - 19px)` : undefined,
      }}>
      <Box elevation={1} type='sheet' outlined rounded>
        <Grid alignItems='center' gap={8} padding={12}>
          {component}
          {currentComponent}
        </Grid>
      </Box>
    </div>
  );
};

export default TextItemData;
