import React from 'react';
import { Select } from 'vkit/lib/components';
import DataType from 'vkit/lib/components/webform/select/types/data.type';
import { USER_STATUS, USER_STATUS_TRANSLATED } from '../../entities';

interface SelectStatusProps {
  onChange: (status?: USER_STATUS | 'all') => void;
  value?: USER_STATUS | 'all';
  disabled?: boolean;
}

const SelectStatus: React.FC<SelectStatusProps> = ({ onChange, value, disabled }) => {
  const selectOptions: DataType[] = Object.entries(USER_STATUS_TRANSLATED).map(([value, text]) => ({
    value,
    text,
  }));

  const allOptions = [
    {
      text: 'Todos',
      value: 'all',
    },
    ...selectOptions,
  ];

  return (
    <Select
      disabled={disabled}
      label='Status'
      small
      value={value ?? ''}
      data={allOptions}
      onChange={onChange}
    />
  );
};

export default SelectStatus;
