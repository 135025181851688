import { ButtonDialog, FileExtensionIcon, WordBreak } from '@/components';
import { FILES_MIME_TYPE } from '@/components/fileExtensionIcon/utils';
import { FileCategory } from '@/modules/files/entities/fileCategories';
import { FILE_PERMISSION_ID, FileEntity } from '@/modules/files/entities/fileEntity';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import useFiles from '@/modules/files/hooks/useFiles/useFiles';
import { useLoggedUser } from '@/modules/profile/hooks';
import { useHistoryNavigator, useHistoryQuery } from '@/navigation';
import { getFormattedDate } from '@/utils/format';
import React, { useMemo } from 'react';
import { Button, DataTable, Icon } from 'vkit/lib/components';
import { DataTableAction } from 'vkit/lib/components/dataTable/types/dataTableAction.types';
import { Grid, Row } from 'vkit/lib/context';
import useFilesCategories from '../../hooks/useFilesCategories/useFilesCategories';

interface FileCategoryUnion extends FileCategory {
  type: 'folder' | 'file';
}
interface FileEntityUnion extends FileEntity {
  type: 'folder' | 'file';
}
type CombinedList = FileCategoryUnion | FileEntityUnion;

const List: React.FC = () => {
  const history = useHistoryNavigator();
  const { hasScopedPermission } = useLoggedUser();

  const { idCategory } = useHistoryQuery();

  const { listCategories, isLoadingCategories, hideUploadButton } = useFilesCategories({
    selectedCategory: idCategory || null,
  });

  const canCreate = useMemo(
    () => !hideUploadButton && hasScopedPermission(idCategory, FILE_PERMISSION_ID.CREATE),
    [hasScopedPermission, hideUploadButton, idCategory],
  );

  const { files, isLoading, showPagination, pagination, setFilters, filters, deleteFile } =
    useFiles({
      idCategory,
    });

  const { downloadFile } = useFileDownload();

  const loadingList = useMemo(
    () => isLoading || isLoadingCategories,
    [isLoading, isLoadingCategories],
  );

  const CombinedList = useMemo((): CombinedList[] => {
    if (loadingList) {
      return [];
    }

    const folders: FileCategoryUnion[] = listCategories.map((category) => ({
      ...category,
      type: 'folder',
    }));

    const listFiles: FileEntityUnion[] = files.map((file) => ({
      ...file,
      type: 'file',
    }));

    return [...folders, ...listFiles] as unknown as CombinedList[];
  }, [files, listCategories, loadingList]);

  const getFileName = ({ type, mimetype, name }: FileEntityUnion) => {
    if (type === 'file') {
      return (
        <Grid gap={4} alignItems='center'>
          <FileExtensionIcon mimetype={mimetype as keyof typeof FILES_MIME_TYPE} />
          <WordBreak>{name}</WordBreak>
        </Grid>
      );
    }
    return (
      <Grid gap={4} alignItems='center'>
        <Icon name='folder' size={30} />
        <WordBreak>{name}</WordBreak>
      </Grid>
    );
  };

  const columns = [
    {
      name: 'NOME',
      path: 'name',
      with: '100px',
      format: getFileName,
    },
    {
      omit: !CombinedList.some(item => item.type === 'file'),
      name: 'DISPONÍVEL EM',
      path: 'updatedAt',
      with: '100px',
      format: ({ updatedAt, type }: FileEntityUnion) => updatedAt && type === 'file'
        ? getFormattedDate(updatedAt, { showHours: true })
        : '',
    },
  ];

  const navigateCategory = (id: string) => {
    setTimeout(() => {
      history.push(`?idCategory=${id}`);
    }, 300);
  };

  const getActions = (type: string, id: string) => {
    if (type === 'folder') {
      return [
        <Button
          key='folder'
          solo
          size='medium'
          label='Acessar pasta'
          icon='folder'
          onClick={() => navigateCategory(id)}
          iconColor='colorDefault'
        />,
      ];
    }
    if (!hasScopedPermission(idCategory, FILE_PERMISSION_ID.DELETE)) {
      return [
        <Button
          key='download'
          outlined
          solo
          size='medium'
          label='Download'
          icon='cloud-download-outline'
          iconColor='colorDefault'
          onClick={() => downloadFile(id)}
        />,
      ];
    } else {
      return [
        <Grid key='download-delete'>
          <Button
            solo
            size='medium'
            label='Download'
            icon='cloud-download-outline'
            iconColor='colorDefault'
            onClick={() => {
              downloadFile(id);
            }}
          />
          <ButtonDialog
            solo
            color='danger'
            iconColor='danger'
            label='Excluir'
            icon='trash-2-outline'
            text='Tem certeza que deseja excluir este arquivo?'
            onClick={() => {
              deleteFile(id);
            }}
            modalType='danger'
          />
        </Grid>,
      ];
    }
  };

  const handleChangePageSize = (pageSize: number): void => {
    setFilters({
      ...filters,
      pageSize,
    });
  };

  const handlePageChange = (page: number): void => {
    setFilters({
      ...filters,
      page,
    });
  };

  const onClick = (): void => {
    history.push(`upload?idCategory=${idCategory}`);
  };

  const handleRowClick = (data: CombinedList) => {
    if (data.type === 'folder') {
      return ({ id }: CombinedList) => navigateCategory(id);
    }
  };

  return (
    <>
      {canCreate && (
        <Row style={{ padding: '0 0 12px' }}>
          <Grid alignContent='right'>
            <Button
              size='medium'
              label='Enviar arquivo'
              icon='cloud-upload-outline'
              iconColor='colorDefault'
              onClick={onClick}
            />
          </Grid>
        </Row>
      )}

      <DataTable
        action={{
          items: (data) => getActions(data.type, data.id) as unknown as DataTableAction[],
        }}
        columns={columns}
        data={CombinedList}
        isLoading={loadingList}
        showPagination={showPagination && !loadingList}
        onRowClick={(row: any) => handleRowClick(row)}
        total={pagination.total}
        totalPages={pagination.totalPages}
        page={pagination.page}
        pageSize={filters?.pageSize}
        onChangePage={handlePageChange}
        onChangePageSize={handleChangePageSize}
      />
    </>
  );
};

export default List;
