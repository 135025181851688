import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';
import { DialogConfirm, Tooltip } from '@/components/index';
import { ObjectType } from '@/shareds/types';
import ColorsType from '@/shareds/types/colors.type';
import { isEqual } from 'lodash';
import React, {
  CSSProperties,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import useFormValidates from './hooks/useFormValidates';
import { FormValidateHandler, useValidateType, validateNamesType } from './types/formValidate.type';

type Size = 'minor' | 'small' | 'medium' | 'larger' | 'xLarger';

export interface BodyForm {
  onChange: (...item: any) => void;
  onBlur: (...item: any) => void;
  useErrors: ObjectType;
  useValidate?: useValidateType;
  useValues: ObjectType;
  updateFieldErrors?: (...item: any) => void;
}

interface FormValidateProps {
  objectName?: string;
  resource: any;
  resourceDefault?: any;
  fields: {
    [key: string]: (validateNamesType | Function)[];
  };
  body: (item: BodyForm) => ReactNode;
  footer?: ReactNode;
  onBeforeSubmit?: Function;
  onSubmit?: Function;
  onChangeForm?: Function;
  onBack?: Function;
  onError?: Function;
  errors?: { [key: string]: string };
  buttons?: {
    style?: CSSProperties;
    submit?: {
      color?: ColorsType;
      icon?: string;
      iconDir?: 'left' | 'right';
      loading?: boolean;
      size?: Size;
      text?: string;
      disabled?: boolean;
      tooltip?: string | null;
    };
    back?: {
      confirm?: boolean;
      color?: ColorsType;
      icon?: string;
      iconDir?: 'left' | 'right';
      size?: Size;
      text?: string;
    };
  };
}

const FormValidate: ForwardRefRenderFunction<FormValidateHandler, FormValidateProps> = (
  props,
  forwardedRef,
) => {
  const {
    objectName,
    resource,
    resourceDefault,
    buttons,
    fields,
    body,
    onChangeForm,
    onSubmit,
    onBack,
    onBeforeSubmit,
    onError,
    footer,
    errors,
  } = props;

  const { onBlur, onChange, onValidateForm, useErrors, useValidate, useValues, updateFieldErrors } =
    useFormValidates({
      fields,
      fnCallback: onSubmit,
      onChangeForm,
      onError,
      onBeforeValidateForm: onBeforeSubmit,
      objectName,
      resource,
      resourceDefault,
      externalErrors: errors,
    });

  const initialValues = useRef(objectName ? resource[objectName] : resource);

  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm();

  const confirmOnBack = useCallback(
    async (...args): Promise<void> => {
      const canDelete = await confirm(
        'Atenção',
        'Ao sair deste formulário você perderá o preenchimento dos campos, quer mesmo sair?',
      );

      if (canDelete) {
        onBack?.(...args);
      }
    },
    [onBack, confirm],
  );

  const checkAndConfirmOnBack = useCallback(
    async (...args) => {
      const filledFieldsExists = !isEqual(initialValues.current, useValues);
      if (buttons?.back?.confirm && filledFieldsExists) {
        return confirmOnBack(...args);
      }

      onBack?.(...args);
    },
    [buttons?.back?.confirm, confirmOnBack, initialValues, onBack, useValues],
  );

  useImperativeHandle(forwardedRef, () => ({
    async submit() {
      return onValidateForm?.();
    },
  }));

  return (
    <>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />

      <form onSubmit={onValidateForm} autoComplete='off'>
        {body({
          onBlur,
          onChange,
          useErrors,
          useValidate,
          useValues,
          updateFieldErrors,
        })}

        {buttons && (
          <Grid alignContent='right' margin='36px 0 0'>
            {buttons.back && (
              <Button
                icon={buttons.back.icon}
                iconDir={buttons.back.iconDir}
                solo
                label={buttons.back.text}
                onClick={() => checkAndConfirmOnBack?.(useValues)}
              />
            )}

            {buttons.submit && !buttons.submit.tooltip && (
              <Button
                icon={buttons.submit.icon}
                iconDir={buttons.submit.iconDir}
                label={buttons.submit.text}
                submit
                loading={buttons.submit.loading}
                disabled={buttons.submit.disabled}
              />
            )}

            {buttons.submit?.tooltip && (
              <Tooltip title={buttons.submit.tooltip}>
                <Button
                  icon={buttons.submit.icon}
                  iconDir={buttons.submit.iconDir}
                  label={buttons.submit.text}
                  submit
                  loading={buttons.submit.loading}
                  disabled={buttons.submit.disabled}
                />
              </Tooltip>
            )}
          </Grid>
        )}

        {footer}
      </form>
    </>
  );
};

export default React.forwardRef(FormValidate);
