import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import React, { useEffect, useMemo, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { isEmpty } from 'lodash';
import { ActionsLayer, Button, Chip } from 'vkit/lib/components';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

import {
  CADASTRAL_DATA_MATCHING_COLUMNS,
  CADASTRAL_DATA_MATCHING_LIST_ACTIONS, CADASTRAL_DATA_MATCHING_STATUS_COLOR,
  CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED,
  CadastralDataMatchingParamsToFilter,
} from '@/modules/cadastralDataMatching/entities';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import { Move, MoveResponse } from '@/modules/move/entities';
import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';

import { WordBreak } from '@/components';
import { useHistoryNavigator } from '@/navigation';
import { getFormattedDate } from '@/utils/format';

import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import { Grid } from 'vkit/lib/context';

interface UsecadastralDataMatching {
  actions?: CADASTRAL_DATA_MATCHING_LIST_ACTIONS[];
  columns?: CADASTRAL_DATA_MATCHING_COLUMNS[];
  filters: CadastralDataMatchingParamsToFilter;
  handleFilters: (params: CadastralDataMatchingParamsToFilter) => void;
}

const monitoringService = new MonitoringService();

const useCadastralDataMatchingTable = ({
  actions,
  columns,
  filters,
  handleFilters,
}: UsecadastralDataMatching) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [cadastralDataMatchings, setCadastralDataMatchings] = useState<Move[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const { company, getAllCompanyConfigsByKey } = useCompany();
  const companyId = useMemo(() => company.id, [company.id]);

  const isEmptyMoves = useMemo(
    () => !cadastralDataMatchings?.length && !isLoading,
    [isLoading, cadastralDataMatchings?.length],
  );

  const AVALIBLE_COLUMNS: Record<CADASTRAL_DATA_MATCHING_COLUMNS, DataTableColumn> = useMemo(
    () => ({
      [CADASTRAL_DATA_MATCHING_COLUMNS.FILE_NAME]: {
        name: 'NOME DO ARQUIVO',
        path: 'name',
        format: ({ name }: Move) => <WordBreak>{name}</WordBreak>,
      },
      [CADASTRAL_DATA_MATCHING_COLUMNS.STATUS]: {
        name: 'STATUS',
        path: 'status',
        format: ({ status }: Move) => (
          <Grid>
            <Chip
              rounded
              size='small'
              label={CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED[status]}
              color={CADASTRAL_DATA_MATCHING_STATUS_COLOR[status]}
            />
          </Grid>
        ),
      },
      [CADASTRAL_DATA_MATCHING_COLUMNS.UPLOADED_AT]: {
        name: 'DATA DO UPLOAD',
        path: 'updatedAt',
        format: ({ updatedAt }: Move) =>
          updatedAt ? getFormattedDate(updatedAt, { showHours: true }) : null,
      },
      [CADASTRAL_DATA_MATCHING_COLUMNS.CREATED_AT]: {
        name: 'DATA DA CRIAÇÃO',
        path: 'createdAt',
        format: ({ createdAt }: Move) =>
          createdAt ? getFormattedDate(createdAt, { showHours: true }) : null,
      },
    }),
    [],
  );

  const navigate = useHistoryNavigator();
  const { downloadFile } = useFileDownload();

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  const getActions = ({ id }: Move) => {
    const AVALIBLE_ACTIONS = {
      [CADASTRAL_DATA_MATCHING_LIST_ACTIONS.DETAILS]: {
        label: 'Ver detalhes',
        onClick: () => {
          navigate.push(`${id}/details`);
        },
        icon: ICONS.DETAILS,
      },
      [CADASTRAL_DATA_MATCHING_LIST_ACTIONS.DOWNLOAD]: {
        label: 'Baixar arquivo',
        onClick: () => downloadFile(id),
        icon: ICONS.DOWNLOAD,
      },
    };

    const listActions = actions
      ? actions.map((action) => AVALIBLE_ACTIONS[action])
      : Object.values(AVALIBLE_ACTIONS).map((value) => value);

    if (listActions.length === 1) {
      return [
        <Button
          key={id}
          solo
          outlined
          label={listActions[0].label}
          onClick={listActions[0].onClick}
          color='colorDefault'
          iconColor='colorDefault'
        />,
      ];
    }

    return [<ActionsLayer key={id} vertical actions={listActions} />];
  };

  const handleChangePageSize = (value: number) => {
    handleFilters({
      ...filters,
      pageSize: value,
      page: 1,
    });
  };

  const handlePageChange = (value: number) => {
    handleFilters({
      ...filters,
      page: value,
    });
  };

  useEffect(() => {
    const fetchData = async (queryParams: CadastralDataMatchingParamsToFilter) => {
      const fileTypes = getAllCompanyConfigsByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);
      if (!Array.isArray(fileTypes) || fileTypes.length === 0) {
        return;
      }

      setLoading(true);

      try {
        const { data, page, totalPages, total, pageSize } = await monitoringService.getFiles<
          MoveResponse,
          CadastralDataMatchingParamsToFilter
        >({
          ...queryParams,
          companyId,
          type: fileTypes,
          sort: '-uploadedAt',
        });
        setCadastralDataMatchings(data);
        setPagination({
          page,
          totalPages,
          pageSize,
          total,
        });
      } catch (e) {
        console.error(e);
        dispatchToast({
          text: 'Ops! Não foi possível buscar os bates cadastrais.',
        });
      } finally {
        setLoading(false);
      }
    };

    if (isEmpty(filters) || !companyId) {
      return;
    }

    fetchData(filters);

    return () => {
      setCadastralDataMatchings([]);
    };
  }, [filters, companyId, getAllCompanyConfigsByKey]);

  const showPagination = useMemo(() => {
    return (
      (pagination?.totalPages !== undefined && pagination.totalPages > 1) ||
      (cadastralDataMatchings?.length > 10 && !isLoading)
    );
  }, [isLoading, cadastralDataMatchings?.length, pagination.totalPages]);

  return {
    isLoading,
    cadastralDataMatchings,
    isEmptyMoves,
    showPagination,
    pagination,
    useColumns,
    getActions,
    handleChangePageSize,
    handlePageChange,
  };
};

export default useCadastralDataMatchingTable;
