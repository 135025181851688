import { CompanyGroup } from '@/modules/companyGroup/entities/companyGroup.interface';
import React from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface CompanyGroupTitleProps {
  companyGroup: CompanyGroup;
  loading: boolean;
}

const CompanyGroupTitle: React.FC<CompanyGroupTitleProps> = ({ companyGroup, loading }) => {
  return (
    <Grid column gap={8} stretch>
      {loading || companyGroup.name ? (
        <Text rounded title value={companyGroup.name} size='larger' />
      ) : null}
    </Grid>
  );
};

export default CompanyGroupTitle;
