import { MAX_UPLOAD_FILE_SIZE_MB } from '../entities/fileEntity';

export const checkMaxFileSize = (file: File): boolean => {
  if (!file) {
    return false;
  }

  const totalFileSizeMb = Number((file.size / 1_048_576).toFixed(1));
  return totalFileSizeMb <= MAX_UPLOAD_FILE_SIZE_MB;
};
