import restfulService from '@/utils/restful';
import { BENEFICIARY_STATUS } from '../beneficiaries';
import { AxiosRequestHeaders } from 'axios';

export interface CountBeneficiaries {
  beneficiaries: number;
  dependents: number;
  holders: number;
  lives: number;
}

interface CountBeneficiariesByCompany {
  data: CountBeneficiaries;
}

export interface CountParams {
  status?: BENEFICIARY_STATUS[];
  benefitId?: string;
}

const { REACT_APP_API_BENEFICIARIES_URL = '' } = process.env;

const apiService = (companyId: string) => {
  const api = restfulService({
    service: `companies/${companyId}/beneficiaries`,
    uri: REACT_APP_API_BENEFICIARIES_URL,
  });

  api.apiInstance.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  });

  return {
    count: async (params: { [key: string]: any }) => {
      const { data } = (await api.get(params)) as CountBeneficiariesByCompany;
      return data;
    },
  };
};

export default apiService;
