import restfulBase from '@/services/accessControl/base';
import { ObjectType } from '@/shareds/types';

export interface AuthConfirmEmail {
  code: string;
  email: string;
  newPassword: string;
  userId: string;
}

export interface AuthConfirmEmailResponse {
  token: string;
}

export const authConfirmEmailInitState = {
  code: '',
  email: '',
  newPassword: '',
  userId: '',
};

export const authConfirmEmailError: ObjectType = {
  'This password is not secure.':
    'Senha não é segura, evite usar sequências de caracteres (exemplo: abc, 123), datas (2021, 2022) ou palavras muito previsível (teste, senha).', // NOSONAR
  'Password is too short': 'Senha é muito curta',
  'Password is too long': 'Senha é muito longa',
  'Password is not strong enough':
    'Senha não é segura, evite usar sequências de caracteres (exemplo: abc, 123), datas (2021, 2022) ou palavras muito previsível (teste, senha).', // NOSONAR
};

export const authConfirmEmailValidationErrorEmail: ObjectType = {
  isDefined: 'E-mail é de preenchimento obrigatório.',
  isEmail: 'E-mail é inválido.',
  isLength: 'E-mail deve ser maior ou igual a 3 caracteres.',
};

export const authConfirmEmailValidationErrorPassword: ObjectType = {
  isDefined: 'Senha é de preenchimento obrigatório.',
  isLength: 'Deve ser maior ou igual a 8 caracteres.',
  isString: 'Deve ser um texto.',
  passwordStrength:
    'Senha não é segura, evite usar sequências de caracteres (exemplo: abc, 123), datas (2021, 2022) ou palavras muito previsível (teste, senha).', // NOSONAR
};

export const authConfirmEmailValidationError: ObjectType = {
  userId:
    'Um erro foi gerado ao tentar registrar sua senha. Se o link foi copiado por favor confira o link copiado, caso contrario entre em contato com quem criou seu usuário.',
  code: 'Um erro foi gerado ao tentar registrar sua senha. Se o link foi copiado por favor confira o link copiado, caso contrario entre em contato com quem criou seu usuário.',
  email: authConfirmEmailValidationErrorEmail,
  newPassword: authConfirmEmailValidationErrorPassword,
};

export const authConfirmEmailService = restfulBase<AuthConfirmEmail, AuthConfirmEmailResponse>(
  'auth/email-confirmations',
);
