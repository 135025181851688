import { Rating } from '@/modules/feedback/entities';
import React from 'react';
import { Grid, Row } from 'vkit/lib/context';
import RatingItem from '../ratingItem/ratingItem';

interface RatingProps {
  data: Rating[];
  handleRatingCheck: (value: number) => void;
}

const RatingList: React.FC<RatingProps> = ({ data, handleRatingCheck }) => {
  return (
    <Row>
      <Grid alignContent='center' alignItems='center' gap={16}>
        {data.map((rating) => (
          <RatingItem key={rating.value} rating={rating} handleSelected={handleRatingCheck} />
        ))}
      </Grid>
    </Row>
  );
};

export default RatingList;
