import fileUploadImage from '@/assets/images/file-upload.png';
import { BoxInfo } from '@/components';
import { FeedbackAction } from '@/modules/feedback';
import { useHistoryNavigator } from '@/navigation';
import React from 'react';
import { Button, Image, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const Success: React.FC = () => {
  const navigate = useHistoryNavigator();

  return (
    <BoxInfo>
      <Row style={{ alignItems: 'center' }}>
        <Image
          src={fileUploadImage}
          height='86px'
          width='77px'
          type='rounded'
          alt='Ícone de arquivo de upload'
        />
        <Text title color='colorDefault' value='Tudo certo' margin='16px 0 8px' />
        <Text
          fontWeight='bold'
          value='O upload foi realizado com sucesso e<br />em alguns minutos seu arquivos estará disponível na lista de posição cadastral.'
          textAlign='center'
          margin='0 0 8px'
        />

        <Row style={{ alignItems: 'center' }}>
          <Grid alignContent='center' alignItems='center' gap={24}>
            <Text
              value='Caso se interesse, acionando o botão ao lado, <br /> você pode nos contar como está sua experiência no portal.'
              textAlign='center'
            />
            <FeedbackAction label='Feedback' size='small' outlined color='colorDefault' />
          </Grid>
        </Row>

        <Button
          onClick={() => navigate.push('/cadastral-positions')}
          label='Ir para lista de posição cadastral'
          bold
        />
      </Row>
    </BoxInfo>
  );
};

export default Success;
