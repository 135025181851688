import restfulBase from '@/services/negotiations/core/base';
import { Contract } from '@/modules/contract';

export const contractInitialState: Contract = {
  code: '',
  companyId: '',
  status: 'active',
  type: '',
  model: {
    id: 1,
    name: 'Adesão',
  },
  rules: [],
};

export const contractsService = (companyId: string) =>
  restfulBase(`companies/${companyId}/contracts`);

export default contractsService;
