import React from 'react';

import { Address as SharedAddress } from '@/services/negotiations/endpoints/addresses';
import { Address, Views } from '@/shared/modules/address';

import { BoxInfo, EmptyMessage } from '@/components';
import { LifeNormalized } from '@/modules/beneficiary/entities';

interface LifeAddressesProps {
  life?: Partial<LifeNormalized>;
}

const LifeAddresses: React.FC<LifeAddressesProps> = ({ life }) => {
  const addresses = life?.addresses || [];
  const hasAddresses = addresses.length > 0;

  const renderAddressDisplay = (address: Address, index: number) => (
    <Views.Display
      key={index}
      isLoading={false}
      title={`Dados de endereço ${addresses.length > 1 ? index + 1 : ''}`}
      icon='pin-outline'
      address={address as SharedAddress}
    />
  );

  const addressDisplays = hasAddresses ? addresses.map(renderAddressDisplay) : null;

  return (
    <>
      {addressDisplays}

      {!hasAddresses && (
        <BoxInfo icon='pin-outline' title='Dados de endereço'>
          <EmptyMessage title='Nada por aqui!' description='Nenhum endereço cadastrado.' />
        </BoxInfo>
      )}
    </>
  );
};

export default LifeAddresses;
