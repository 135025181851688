import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import {
  Move,
  MoveNormalized,
  MoveParams,
  MoveReponseNormalized,
  MoveResponse,
  MoveStatusUpdate,
} from '../entities';
import { normalizeList } from '../utils';

class MoveService extends HttpClientAdapter {
  constructor() {
    super('MONITORING');
  }

  getMoves<MoveResponse>(params: MoveParams) {
    return this.get<MoveResponse>('/files', params, {
      transformResponse: [this.normalizeMovesResponse.bind(this)],
    });
  }

  getMove<Move>(id: string) {
    const url = `/files/${id}`;
    return this.get<Move>(
      url,
      {},
      {
        transformResponse: [this.normalizeMoveResponse.bind(this)],
      },
    );
  }

  updateStatus(id: string, data: MoveStatusUpdate) {
    const url = `/files/update-status/${id}`;
    return this.patch<MoveStatusUpdate>(url, data);
  }

  private normalizeMoveResponse(move: Move): MoveNormalized {
    return normalizeList([move])[0];
  }

  private normalizeMovesResponse(response: MoveResponse): MoveReponseNormalized {
    if (response.page) {
      return {
        ...response,
        data: normalizeList(response.data),
      };
    }
    return {
      ...response,
      data: normalizeList(response as unknown as Move[]),
    };
  }
}

export default MoveService;
