import React from 'react';

import { BoxInfo } from '@/components';
import ICONS from '@/shareds/constants/icons';

import { Row } from 'vkit/lib/context';
import { Address, Contact, Info } from '../../components';

const About: React.FC = () => {
  return (
    <>
      <BoxInfo title='Dados da empresa' icon={ICONS.COMPANY}>
        <Row style={{ padding: '0 0 20px' }}>
          <Info />
        </Row>
        <Row style={{ padding: 0 }}>
          <Contact />
        </Row>
      </BoxInfo>

      <Address />
    </>
  );
};

export default About;
