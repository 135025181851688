import { useMemo, useState } from 'react';
import { ContractTab } from '@/modules/contract/entities/contract';

interface UseListInterface {
  initialActiveTab?: number;
  contractsTabs?: ContractTab[];
}

const useList = ({ initialActiveTab, contractsTabs }: UseListInterface) => {
  const [activeTab, setActiveTab] = useState<number>(initialActiveTab ?? 0);

  const handleChangeTabs = (newTab: number) => {
    setActiveTab(newTab);
  };

  const renderTabs = useMemo(() => {
    if (!contractsTabs) {
      return [];
    }
    return contractsTabs.map((tab) => ({
      ...tab,
      active: tab.id === activeTab,
    }));
  }, [activeTab, contractsTabs]);

  return {
    activeTab,
    handleChangeTabs,
    renderTabs,
  };
};

export default useList;
