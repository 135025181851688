import { useAddress } from '@/modules/company/hooks';
import React, { useEffect } from 'react';

import useCompany from '@/shared/hooks/useCompany/useCompany';
import { Views } from '@/shared/modules/address';
import ICONS from '@/shareds/constants/icons';

const Address: React.FC = () => {
  const { company } = useCompany();
  const { isLoading, address, loadAddress } = useAddress();

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadAddress(company.id);
  }, [company.id, loadAddress]);

  return (
    <Views.Display
      title='Endereço'
      icon={ICONS.ADDRESS}
      isLoading={isLoading}
      address={address[0]}
    />
  );
};

export default Address;
