import {
  CADASTRAL_DATA_MATCHING_STATUS,
  CadastralDataMatching,
  CadastralDataMatchingParamsToUpload,
} from '@/modules/cadastralDataMatching/entities';
import { Colors } from '@/shareds/types';

export const CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED: Record<string, string> = {
  [CADASTRAL_DATA_MATCHING_STATUS.NOT_INITIALIZED]: 'Não iniciado',
  [CADASTRAL_DATA_MATCHING_STATUS.IN_PROCESS]: 'Em análise',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESS_ERROR]: 'Erro de processamento',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESS_PROVIDER_ERROR]: 'Erro apontado pelo fornecedor',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESSED]: 'Finalizado',
  [CADASTRAL_DATA_MATCHING_STATUS.REJECTED]: 'Rejeitado',
};

export const CADASTRAL_DATA_MATCHING_STATUS_COLOR: Record<string, Colors> = {
  [CADASTRAL_DATA_MATCHING_STATUS.NOT_INITIALIZED]: 'light',
  [CADASTRAL_DATA_MATCHING_STATUS.IN_PROCESS]: 'warning',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESS_ERROR]: 'danger',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESS_PROVIDER_ERROR]: 'danger',
  [CADASTRAL_DATA_MATCHING_STATUS.PROCESSED]: 'success',
  [CADASTRAL_DATA_MATCHING_STATUS.REJECTED]: 'danger',
};

export const CADASTRAL_DATA_MATCHING_ALLOWED_EXTENSIONS = ['xls', 'xlsx'];

export const CADASTRAL_DATA_MATCHING_FORM_DATA: CadastralDataMatchingParamsToUpload = {
  companyId: '',
  files: null,
};

export const DEFAULT_EMPTY_CADASTRAL_DATA_MATCHING: CadastralDataMatching = {
  companyId: '',
  createdAt: '',
  extension: '',
  id: '',
  lines: 0,
  link: '',
  name: '',
  rejectedBy: null,
  rejectedById: null,
  rejectionReason: null,
  status: '',
  uploadedAt: '',
  userId: '',
};
