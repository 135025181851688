import React from 'react';
import { getToken } from '@/utils/authentication';
import { Select } from 'vkit/lib/components';

interface SelectOptionsProps {
  small?: boolean;
  label?: string;
  placeholder?: string;
  value?: string;
  onChangeValue: (value: string) => void;
  endpoint: string;
}

const SelectOptions: React.FC<SelectOptionsProps> = ({
  small,
  label,
  placeholder,
  value,
  onChangeValue,
  endpoint,
}) => {
  return (
    <Select
      small={small}
      fixedList
      autoRequest
      searchable
      clearable
      label={label}
      placeholder={placeholder}
      value={value || ''}
      requestPageParam='page'
      requestResponseRootPath='data'
      requestResponseText='name'
      requestResponseValue='id'
      requestRouter={endpoint}
      requestSearchParam='q'
      requestUri={process.env.REACT_APP_API_AUTH_URL}
      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
      onChange={onChangeValue}
    />
  );
};

export default SelectOptions;
