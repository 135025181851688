import { User } from './user';

export const userInitialState: User = {
  id: '',
  name: '',
  email: '',
  type: 'company',
  scopeIds: [],
  status: '',
  createdAt: new Date(),
  updatedAt: null,
  deletedAt: null,
  userPermissions: [],
  permissions: [],
};
