import React from 'react';
import { Rule } from '@/modules/contract/entities/contract';

interface RuleListProps {
  rules: Rule[];
}

const RuleList: React.FC<RuleListProps> = ({ rules }) => {
  return rules.length === 1 ? (
    <>{rules[0].description}</>
  ) : (
    <ul>
      {rules
        .filter((rule) => rule.description)
        .map((rule) => (
          <li key={rule.description} style={{ marginLeft: 20 }}>
            {rule.description}
          </li>
        ))}
    </ul>
  );
};

export default RuleList;
