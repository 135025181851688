import React from 'react';
import { Tooltip } from '@/components';

interface TooltipDescriptionProps {
  description: string;
}

const TooltipDescription: React.FC<TooltipDescriptionProps> = ({ description }) => {
  return (
    <Tooltip title={description}>
      <div style={{ cursor: 'default' }}>Descrição</div>
    </Tooltip>
  );
};

export default TooltipDescription;
