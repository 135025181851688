import { companiesService } from '@/services/negotiations';
import { useCallback, useState } from 'react';
import { Company } from '../../entities';

const useLoadCompany = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadedCompany, setLoadedCompany] = useState<Company>();

  const loadCompany = useCallback(async (companyId: string) => {
    if (!companyId) {
      return;
    }

    setLoading(true);

    try {
      const company = (await companiesService.find(companyId)) as Company;
      setLoadedCompany(company);
      return company;
    } catch (e) {
      console.error(e);

      const toastEvent = new CustomEvent('toast', {
        detail: {
          icon: 'close-circle-outline',
          title: 'Oops',
          text: 'Ops! Devido a um erro, não conseguimos buscar as informações da empresa.',
          type: 'danger',
          align: 'right',
          titleColor: 'danger',
          position: 'bottom',
          shadow: true,
          duration: 10,
        },
      });

      window.dispatchEvent(toastEvent);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    loadCompany,
    loadedCompany,
  };
};

export default useLoadCompany;
