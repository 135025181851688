import React, { MouseEventHandler, ReactNode, useMemo } from 'react';
import { get } from 'lodash';
import formatText from '@/utils/formatText';
import { ActionsLayer, Text } from 'vkit/lib/components';
import { ObjectType } from '@/shareds/types';
import { BoxInfo, EmptyMessage, GridGroup } from '@/components';
import { Grid, Row } from 'vkit/lib/context';
import verifyPermissions from '@/utils/verifyPermissions';
import { Column } from '@/components/dataTable';

export interface Action {
  label: string;
  icon?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

interface ContentListBoxesProps {
  actionsByLine?: (...itens: any) => Action[] | ReactNode;
  columns: Column[];
  data: ObjectType[];
  icon?: string;
  linesLoader?: number;
  refreshData: (updateFilters?: ObjectType) => Promise<void>;
  loading?: boolean;
}

interface ContentListBoxProps {
  actionsByLine?: (...itens: any) => Action[] | ReactNode;
  columns: Column[];
  firstColumn: Column;
  icon?: string;
  objectLine: ObjectType;
  refreshData: (updateFilters?: ObjectType) => Promise<void>;
}

const Box: React.FC<ContentListBoxProps> = ({
  actionsByLine,
  columns,
  firstColumn,
  icon,
  refreshData,
  objectLine,
}) => {
  const actions = useMemo(() => {
    if (actionsByLine) {
      return actionsByLine(objectLine, refreshData);
    }
    return null;
  }, [actionsByLine, objectLine, refreshData]);

  return (
    <BoxInfo
      elevation={1}
      icon={icon}
      margin={0}
      title={
        <div>
          {firstColumn.custom?.(objectLine) ||
            (firstColumn.path && (
              <Text
                color='colorDefault'
                iconColor='colorDefault'
                size='small'
                title
                value={formatText(get(objectLine, firstColumn.path), firstColumn.type || 'text')}
              />
            ))}
        </div>
      }
      header={
        Array.isArray(actions) && (
          <ActionsLayer vertical actions={actions.filter(verifyPermissions)} />
        )
      }
      footer={!Array.isArray(actions) && actions}>
      <div>
        {columns.map((column, index) => (
          <Row style={{ padding: '0 0 10px' }} key={index}>
            {column.title && <Text value={column.title} title color='colorDefault' size='minor' />}
            {column.custom?.(objectLine) ||
              (column.path && formatText(get(objectLine, column.path), column.type || 'text'))}
          </Row>
        ))}
      </div>
    </BoxInfo>
  );
};

const LINES_LOADER_DEFAULT = 3;
const ContentListBoxes: React.FC<ContentListBoxesProps> = ({
  actionsByLine,
  columns = [],
  data = [],
  refreshData,
  loading,
  linesLoader = LINES_LOADER_DEFAULT,
  icon,
}) => {
  const cloneColumns = useMemo(() => columns.slice(), [columns]);
  const firstColumn = useMemo(() => (cloneColumns.shift() || {}) as Column, [cloneColumns]);

  return (
    <>
      {!loading && !!data.length && (
        <GridGroup
          body={data.map((item) => ({
            component: (
              <Box
                actionsByLine={actionsByLine}
                columns={cloneColumns}
                firstColumn={firstColumn}
                icon={icon}
                refreshData={refreshData}
                objectLine={item}
              />
            ),
          }))}
        />
      )}

      {loading && (
        <GridGroup
          body={Array.from({ length: linesLoader }).map(() => ({
            component: (
              <BoxInfo margin={0} elevation={1}>
                {columns.map((_, index) => (
                  <Row style={{ padding: '10px 0' }} key={index}>
                    <div style={{ width: '30%' }}>
                      <Text value='' size='minor' rounded />
                    </div>
                    <Text value='' margin='8px 0 0' rounded />
                  </Row>
                ))}
              </BoxInfo>
            ),
          }))}
        />
      )}

      {!loading && !data.length && (
        <Grid alignContent='center'>
          <EmptyMessage
            vertical
            title='Nada por aqui!'
            description='Não encontramos nenhum registro em nossa base de dados.'
          />
        </Grid>
      )}
    </>
  );
};

export default ContentListBoxes;
