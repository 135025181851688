import {
  AuthConfirmEmailForm,
  AuthForgotPasswordForm,
  AuthLoginForm,
  AuthResetPasswordForm,
} from '@/components/forms';
import { INITIAL_ROUTE } from '@/core/entities/types/routes.const';
import useMainPageDetails from '@/core/hooks/useMainPageDetails';
import { ConfirmationExpiredOrNotExist } from '@/modules/authentication/views';
import { useHistoryLocation } from '@/navigation';
import React, { useEffect } from 'react';
import { Row } from 'vkit/lib/context';
import useSetPageTabTitle from '@/core/hooks/useSetPageTabTitle';

const Auth: React.FC = () => {
  const { pathname } = useHistoryLocation();
  const { data } = useMainPageDetails();

  const { setPageTabTitle } = useSetPageTabTitle();

  useEffect(() => {
    setPageTabTitle(data?.title);
  }, [data?.title, setPageTabTitle]);

  return (
    <Row>
      {pathname === '/auth/confirm-email' && <AuthConfirmEmailForm />}

      {pathname === '/auth/login' && <AuthLoginForm
        description={'Para acessar, digite seu e-mail e sua senha.'}
        onLogin={() => {
          const redirect = new URLSearchParams(window.location.search).get('redirect');
          if (redirect) {
            window.location.replace(decodeURIComponent(redirect));
          } else {
            window.location.replace(INITIAL_ROUTE);
          }
        }}
      />}

      {pathname === '/auth/forgot-password' && <AuthForgotPasswordForm />}

      {pathname === '/auth/reset-password' && <AuthResetPasswordForm />}

      {pathname === '/auth/confirmation-expired-or-not-exist' && <ConfirmationExpiredOrNotExist />}
    </Row>
  );
};

export default Auth;
