import React, { useCallback, useMemo } from 'react';
import { Select } from 'vkit/lib/components';
import { SelectOption } from '@/components/selectValidate';
import { ObjectType } from '@/shareds/types';
import { CADASTRAL_POSITION_STATUS_TRANSLATED } from '@/modules/cadastralPosition/entities/cadastralPosition.const';

interface SelectProviderProps {
  name: string;
  onChange: Function;
  value?: string;
  onBlur?: Function;
  errors?: ObjectType;
  small?: boolean;
  disabled?: boolean;
}

const SelectCadastralPositionStatus: React.FC<SelectProviderProps> = ({
  name,
  onChange,
  value,
  onBlur,
  errors,
  small,
  disabled,
}) => {
  const cadastralDataMatchingStatusOptions = useMemo(() => {
    return Object.entries(CADASTRAL_POSITION_STATUS_TRANSLATED).map(([key, value]) => ({
      text: value,
      value: key,
    }));
  }, []);

  const handleChange = useCallback(
    (selectedValue, selectedOption: SelectOption) => {
      if (!disabled && selectedValue !== value) {
        onChange?.(name, selectedValue, selectedOption);
      }
    },
    [disabled, name, onChange, value],
  );

  return (
    <Select
      label='Status'
      clearable
      onBlur={(value) => !disabled && onBlur?.(name, value)}
      onChange={handleChange}
      textHelper={!disabled ? errors?.[name] ?? '' : undefined}
      value={value || ''}
      small={small}
      data={cadastralDataMatchingStatusOptions}
    />
  );
};

export default SelectCadastralPositionStatus;
