import React from 'react';
import { Tabs } from 'vkit/lib/components';

interface Action {
  id: number;
  label: string;
  icon: string;
  active: boolean;
}

interface TabMainProps {
  actions: Action[];
  onChange: (newTab: number) => void;
}

const TabMain: React.FC<TabMainProps> = ({ actions, onChange }) => {
  const handleClick = (index: number) => {
    onChange(index);
  };

  return (
    <Tabs elements={[]} actions={actions} onChange={handleClick} outlined iconDir='left' />
  );
};

export default TabMain;
