import { ToastStatusGlobal } from '@/components';
import { Routes } from '@/core/routes';
import ProvideContext from '@/core/context/applicationContext';
import RouteRender from '@/core/context/routeRender';
import React from 'react';

const App: React.FC = () => {
  const { toastComponent } = ToastStatusGlobal();

  return (
    <ProvideContext>
      <RouteRender routes={Routes} />
      {toastComponent}
    </ProvideContext>
  );
};

export default App;
