import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { Chip } from 'vkit/lib/components';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { Grid } from 'vkit/lib/context';

import { BasePagination } from '@/shared/entities';
import { LIST_INITIAL_PAGINATION } from '@/shared/entities/constants/list';
import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';

import { CADASTRAL_POSITION_ROW_STATUS_TRANSLATED } from '@/modules/cadastralPosition/entities/cadastralPositionRow.const';
import { CADASTRAL_POSITION_ROW_COLUMNS } from '@/modules/cadastralPosition/entities/cadastralPositionRow.enum';
import {
  ParamsToFilterRows,
  Row,
  RowResponse,
} from '@/modules/cadastralPosition/entities/cadastralPositionRow.interface';
import useCompany from '@/shared/hooks/useCompany/useCompany';

interface UseCadastralPosition {
  columns?: CADASTRAL_POSITION_ROW_COLUMNS[];
  filters: ParamsToFilterRows;
  handleFilters: (params: ParamsToFilterRows) => void;
}

const monitoringService = new MonitoringService();

const useCadastralPositionRowTable = ({
  columns,
  filters,
  handleFilters,
}: UseCadastralPosition) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<Row[]>([]);
  const [pagination, setPagination] = useState<BasePagination>(LIST_INITIAL_PAGINATION);
  const { company } = useCompany();

  const companyId = useMemo(() => company.id, [company.id]);
  const isEmptyMoves = useMemo(() => !rows.length && !loading, [loading, rows.length]);

  const AVALIBLE_COLUMNS: Record<CADASTRAL_POSITION_ROW_COLUMNS, DataTableColumn<Row>> = useMemo(
    () => ({
      [CADASTRAL_POSITION_ROW_COLUMNS.LINE]: {
        name: 'LINHA',
        path: 'line',
      },
      [CADASTRAL_POSITION_ROW_COLUMNS.LIFE_STATUS]: {
        name: 'STATUS DA VIDA',
        path: 'lifeStatus',
        format: ({ lifeStatus }: Row) => (
          <Grid>
            <Chip
              rounded
              size='small'
              label={CADASTRAL_POSITION_ROW_STATUS_TRANSLATED[lifeStatus]}
              color='light'
            />
          </Grid>
        ),
      },
      [CADASTRAL_POSITION_ROW_COLUMNS.BENEFICIARY_STATUS]: {
        name: 'STATUS DO BENEFICIÁRIO',
        path: 'beneficiaryStatus',
        format: ({ beneficiaryStatus }: Row) => (
          <Grid>
            <Chip
              rounded
              size='small'
              label={CADASTRAL_POSITION_ROW_STATUS_TRANSLATED[beneficiaryStatus]}
              color='light'
            />
          </Grid>
        ),
      },
    }),
    [],
  );

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  const handleChangePageSize = (value: number) => {
    handleFilters({
      ...filters,
      pageSize: value,
      page: 1,
    });
  };

  const handlePageChange = (value: number) => {
    handleFilters({
      ...filters,
      page: value,
    });
  };

  const fetchData = useCallback(async (queryParams: ParamsToFilterRows) => {
    setLoading(true);

    try {
      const { data, page, totalPages, pageSize, total } = await monitoringService.getRows<
        RowResponse,
        ParamsToFilterRows
      >({
        ...queryParams,
        sort: 'line',
      });
      setRows(data);
      setPagination({
        page,
        totalPages,
        pageSize,
        total,
      });
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não foi possível buscar as posições cadastrais.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(filters) || !companyId) {
      return;
    }

    fetchData(filters);

    return () => {
      setRows([]);
    };
  }, [filters, companyId, fetchData]);

  const showPagination = useMemo(() => {
    return (
      (pagination?.totalPages !== undefined && pagination.totalPages > 1) ||
      (rows.length > 10 && !loading)
    );
  }, [loading, rows.length, pagination.totalPages]);

  return {
    loading,
    rows,
    isEmptyMoves,
    showPagination,
    pagination,
    useColumns,
    handleChangePageSize,
    handlePageChange,
  };
};

export default useCadastralPositionRowTable;
