import { BoxInfo } from '@/components';
import InformationData from '@/modules/cadastralPosition/components/informationData/informationData';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import { MoveNormalized } from '@/modules/move/entities';
import React, { useMemo } from 'react';
import { Button } from 'vkit/lib/components';

interface CadastralPositionInfoProps {
  loading: boolean;
  cadastralPositionId: string;
  cadastralPosition?: MoveNormalized;
}

const CadastralPositionInfo: React.FC<CadastralPositionInfoProps> = ({
  loading,
  cadastralPositionId,
  cadastralPosition,
}) => {
  const disabledDownloadButton = useMemo(() => {
    return loading || !cadastralPositionId || !cadastralPosition?.id;
  }, [cadastralPosition?.id, cadastralPositionId, loading]);

  const { downloadFile, loading: loadingDownload } = useFileDownload();

  return (
    <BoxInfo
      title='Dados da posição cadastral'
      icon='info-outline'
      header={
        <Button
          loading={loading || loadingDownload}
          disabled={disabledDownloadButton}
          icon='download-outline'
          label='Baixar arquivo'
          onClick={() => downloadFile(cadastralPositionId)}
          iconColor={disabledDownloadButton ? 'light' : 'clear'}
        />
      }>
      <InformationData data={cadastralPosition} loading={loading} />
    </BoxInfo>
  );
};

export default CadastralPositionInfo;
