import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import {
  ProcessedFamilyGroupCounterResponse,
  ProcessedFamilyGroupCounter,
} from '@/modules/cadastralPosition/entities/familyGroup.interface';

class FamilyGroupsService extends HttpClientAdapter {
  constructor() {
    super('DATA_TEAM');
  }

  getProcessResult(cadastralPositionId: string) {
    return this.get<ProcessedFamilyGroupCounter[]>(
      `/posicao-cadastral/${cadastralPositionId}`,
      undefined,
      {
        transformResponse: [this.normalizeProcessResult],
      },
    );
  }

  normalizeProcessResult({
    totalMessagesWithError = 0,
    totalMessagesProcessed = 0,
    totalBeneficiariesBySov = 0,
    totalBeneficiariesInsertedOrUpdatedInDatabase = 0,
  }: ProcessedFamilyGroupCounterResponse): ProcessedFamilyGroupCounter[] {
    return [
      {
        count: totalMessagesWithError,
        description: 'Famílias com erro',
      },
      {
        count: totalMessagesProcessed,
        description: 'Famílias processadas',
      },
      {
        count: totalBeneficiariesBySov,
        description: 'Beneficiários previstos',
      },
      {
        count: totalBeneficiariesInsertedOrUpdatedInDatabase,
        description: 'Beneficiários alterados ou criados',
      },
    ];
  }
}

export default FamilyGroupsService;
