import React from 'react';
import { Text } from 'vkit/lib/components';
import { ShowHide } from '@/components';

interface AlertMessageProps {
  show: boolean;
  benefitName: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ show, benefitName }) => {
  return (
    <ShowHide visible={show} transition='slideToDown'>
      <Text
        rounded
        size='medium'
        applyBaseColor
        color='warning'
        iconColor='warning'
        padding='8px 12px'
        icon='alert-triangle-outline'
        value={`Certifique-se de que é esse beneficiário que você quer excluir,
          ao excluir o beneficiário ele perderá o benefício de
          <b>${benefitName}.</b>`}
      />
    </ShowHide>
  );
};

export default AlertMessage;
