import useToastStatus from '@/components/toastStatusGlobal';
import FilesService from '@/modules/files/services/filesService';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useState } from 'react';

const filesService = new FilesService();

const useFileDownload = () => {
  const { company } = useCompany();
  const { toast } = useToastStatus();
  const [loading, setLoading] = useState(false);

  const downloadFile = async (id: string) => {
    if (!company.id || !id) {
      dispatchToast({
        text: 'Ops! Dados insuficientes para download do arquivo!',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await filesService.getDownloadToken(company.id, id);
      window.open(response.link, '_blank');
      toast(
        'Sucesso',
        'Download do arquivo começará em uma nova aba em instantes!',
        'success',
        undefined,
        {
          text: 'Clique no botão ao lado para nos contar como está sendo sua experiência com a aplicação.',
          action: {
            label: 'Feedback',
            icon: 'message-circle-outline',
            onClick: () => {
              const feedbackEvent = new CustomEvent('feedbackModal');
              window.dispatchEvent(feedbackEvent);
            },
          },
        },
      );
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não foi possível realizar o download do arquivo!',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    downloadFile,
    loading,
  };
};

export default useFileDownload;
