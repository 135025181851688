import { BoxInfo, EmptyMessage } from '@/components';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatches } from 'react-router-dom';

const NotFound: React.FC = () => {
  const { companyId } = useCompany();
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const navigate = useHistoryNavigator();
  const matches = useMatches();
  const [redirected, setRedirected] = useState(false);

  const OLD_ROUTES = useMemo(
    () => ['/files', '/contracts', '/beneficiaries', '/moves', '/attendances', '/users'],
    [],
  );

  const { pathname } = useHistoryLocation();
  const canRedirect = useMemo(() => OLD_ROUTES.includes(pathname), [OLD_ROUTES, pathname]);

  const redirectToNewRoute = useCallback(() => {
    const companyIdFromQueryString = queryParams.get('companyId');
    const companyIdToRedirect = companyIdFromQueryString || companyId;

    if (!companyIdToRedirect || redirected) {
      return;
    }

    queryParams.delete('companyId');
    setRedirected(true);
    navigate.replace(`/companies/${companyIdToRedirect}${pathname}?${queryParams.toString()}`);
  }, [companyId, navigate, pathname, queryParams, redirected]);

  const componentMessages = useMemo(() => {
    if (matches.some((match) => match.pathname.includes('401'))) {
      return {
        title: 'Sem permissão',
        description: 'Desculpe, você não tem permissão para acessar esta funcionalidade.',
      };
    }

    return {
      title: 'Página não encontrada',
      description: 'A Página que está tentando acessar não existe',
    };
  }, [matches]);

  useEffect(() => {
    const companyIdFromQueryString = queryParams.get('companyId');
    if (!companyId && !companyIdFromQueryString) {
      return;
    }
    if (canRedirect) {
      // O setTimeout foi incluído porque o redirecionamento ocorre tão rapidamente
      // que não há tempo suficiente para que a barra de endereço seja atualizada.
      // Por exemplo, quando a rota é direcionada para /files, ela é redirecionada
      // para /companies/:companyId/files,
      // mas a barra de endereço ainda exibe /files.
      window.setTimeout(redirectToNewRoute, 300);
    }
  }, [OLD_ROUTES, canRedirect, companyId, pathname, queryParams, redirectToNewRoute]);

  return (
    <BoxInfo>
      <EmptyMessage {...componentMessages} />
    </BoxInfo>
  );
};

export default NotFound;
