import HttpClientAdapter from '@/shared/services/httpClientAdapter';

class NegotiationsService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getCompanyGroups<Response, Filters>(params: Filters) {
    return this.get<Response>('/company-groups', params);
  }

  getCompanyGroup<Response>(id: string) {
    return this.get<Response>(`/company-groups/${id}`);
  }

  createFinancialAdjustment<Type>(params: Type) {
    return this.post<Response>('/financial-adjustments', params);
  }

  removeFinancialAdjustment(id: string) {
    return this.delete<Response>(`/financial-adjustments/${id}`);
  }
}

export default NegotiationsService;
