import React from 'react';
import { BoxInfo, FileUpload } from '@/components';
import { BodyForm } from '@/components/formValidate';
import { ALLOWED_EXTENSIONS_MOVE } from '@/pages/beneficiaries/shareds/constants/allowedExtensions';

interface AttachmentFormProps extends BodyForm {
  setAttachments: (attachments: File[]) => void;
}

const AttachmentForm: React.FC<AttachmentFormProps> = ({
  onChange,
  onBlur,
  useErrors,
  setAttachments,
}) => {
  return (
    <BoxInfo margin={0} title='Anexos' icon='attach-outline'>
      <FileUpload
        multiple
        name='attachments'
        allowedExtensions={ALLOWED_EXTENSIONS_MOVE}
        onChange={(name: string, value: File[]) => {
          onChange(name, value);
          onBlur(name, value);
          setAttachments(value);
        }}
        formValidatorErrors={useErrors}
      />
    </BoxInfo>
  );
};

export default AttachmentForm;
