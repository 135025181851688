import emptyImageURL from '@/assets/images/empty.png';
import { classesBuilder } from '@/utils';
import React, { ReactNode } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import style from './style/emptyMessage.module.scss';

export interface EmptyMessageProps {
  title?: string;
  description?: string;
  vertical?: boolean;
  children?: ReactNode;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({ title, description, vertical, children }) => (
  <div
    className={classesBuilder(style, {
      emptyMessage: true,
      vertical,
    })}>
    <div className={style.content}>
      <div className={style.image} style={{ backgroundImage: `url(${emptyImageURL})` }} />
      {vertical ? (
        <>
          {title && (
            <Text color='colorDefault' padding='16px 0 0' textAlign='center' title value={title} />
          )}

          {description && (
            <Text padding='16px 0 0' size='larger' textAlign='center' value={description} />
          )}

          {children}
        </>
      ) : (
        <>
          <Grid growing alignItems='center' margin='0 0 0 30px'>
            <div>
              {title && <Text color='colorDefault' title value={title} />}

              {description && <Text padding='8px 0 0' size='larger' value={description} />}

              {children}
            </div>
          </Grid>
        </>
      )}
    </div>
  </div>
);

export default EmptyMessage;
