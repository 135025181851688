import { COMPANY_GROUP_TAB_IDS } from '@/modules/companyGroup/entities/companyGroup.enum';
import { CompanyGroup } from '@/modules/companyGroup/entities/companyGroup.interface';
import ICONS from '@/shareds/constants/icons';

export const COMPANY_GROUP_INITIAL_STATES: CompanyGroup = {
  createdAt: '',
  updatedAt: '',
  id: '',
  name: '',
  supportEmail: '',
  salesforceCompanyId: '',
  companies: [],
  adjustments: [],
};

export const COMPANY_GROUP_DETAILS_TABS = [
  {
    id: COMPANY_GROUP_TAB_IDS.COMPANIES,
    label: 'Empresas do grupo',
    icon: ICONS.COMPANY,
    active: false,
    disabled: false,
  },
  {
    id: COMPANY_GROUP_TAB_IDS.FINANCIAL_ADJUSTMENT,
    label: 'Ajustes financeiros',
    icon: ICONS.FINANCIAL_ADJUSTMENTS,
    active: false,
    disabled: false,
  },
];
