import { maskText } from '@/utils/format';
import React, { useMemo } from 'react';
import { Chip } from 'vkit/lib/components';
import { Tooltip } from '@/components';
import { Grid } from 'vkit/lib/context';
import { COMPANY_STATUS } from '@/modules/company/entities/company.enum';

const CustomSelectCompany: React.FC<{
  option: any;
  isSelected?: boolean;
  showCompanyStatus?: boolean;
}> = ({ option, isSelected, showCompanyStatus }) => {
  const isMainCompany = useMemo((): boolean => {
    return typeof option?.salesforceId === 'string' && option?.salesforceParentId === null;
  }, [option]);

  const chipColor = isSelected ? 'colorDefault' : 'light';

  return (
    <div>
      <p>
        <strong data-testid='company-name'>{option?.name}</strong>
      </p>

      <small>
        <Grid gap={4} alignItems='center' wrap>
          {maskText(option?.cnpj, '99.999.999/9999-99')}

          {isMainCompany && (
            <Chip size='small' label='Principal' icon='award-outline' rounded color={chipColor} />
          )}

          {option?.status == COMPANY_STATUS.INACTIVE && showCompanyStatus ? (
            <Tooltip title='Esta empresa está com todos os contratos cancelados.' position='bottom'>
              <Chip
                size='small'
                label='Inativa'
                icon='slash-outline'
                rounded
                invertColor
                color='danger'
              />
            </Tooltip>
          ) : null}

          {option?.status == COMPANY_STATUS.PENDING && showCompanyStatus ? (
            <Tooltip
              title='Empresa possui contratos expirados, caso a informação esteja incorreta verificar contratos no Salesforce.'
              position='bottom'>
              <Chip
                size='small'
                label='Pendente'
                icon='alert-triangle-outline'
                rounded
                invertColor
                color='warning'
              />
            </Tooltip>
          ) : null}
        </Grid>
      </small>
    </div>
  );
};

export default CustomSelectCompany;
