import { BodyForm } from '@/components/formValidate';
import React from 'react';

import { Text, TextArea } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

import { BoxInfo, FileUpload, FormValidate, TextFieldValidate } from '@/components';
import { useHistoryNavigator } from '@/navigation';

import { SelectContract } from '@/modules/contract/views';

import { SELECT_CONTRACT_COLUMN } from '@/modules/contract';
import { StatusEnum } from 'vkit/lib/components/webform/builder/enums';
import { SelectReasonAndSubReason } from '../../components';
import {
  ATTENDANCE_FORM_ATTACHMENT_ALLOWED_EXTENSIONS,
  ATTENDANCE_FORM_DESCRIPTION_MAX_LENGTH,
  ATTENDANCE_FORM_SUBJECT_MAX_LENGTH,
  ATTENDANCE_ICON,
  AttendanceCreateParams,
  ReasonAndSubReason,
} from '../../entities';
import { useCreateAttendance } from '../../hooks';

interface FormProps {
  title?: string;
  params?: AttendanceCreateParams;
}

const Form: React.FC<FormProps> = ({ title = 'Dados da solicitação de atendimento', params }) => {
  const navigate = useHistoryNavigator();

  const {
    attendance,
    onChangeAttendanceForm,
    handleSubmitAttendanceFormData,
    attendanceFormValidationSchema,
    isLoadingForm,
    isDisabledForm,
    attachmentsStatus,
    setSelectedContracts,
  } = useCreateAttendance({
    params,
  });

  const onChangeReasonSubReason = (name: ReasonAndSubReason, value: string) => {
    onChangeAttendanceForm({ [name]: value });
  };

  const getFields = ({ useErrors, useValues, onBlur, onChange }: BodyForm) => (
    <Row style={{ padding: 0 }}>
      <Grid column gap={24}>
        <TextFieldValidate
          label='Assunto'
          name='subject'
          useValues={useValues}
          useErrors={useErrors}
          onBlur={onBlur}
          onChange={onChange}
          required
          maxLength={ATTENDANCE_FORM_SUBJECT_MAX_LENGTH}
          header={
            <Text value={`${useValues.subject.length}/${ATTENDANCE_FORM_SUBJECT_MAX_LENGTH}`} />
          }
          disabled={isDisabledForm || isLoadingForm}
        />
        <SelectContract
          isDisabled={isDisabledForm || isLoadingForm}
          useErrors={useErrors}
          useValues={useValues}
          onBlur={onBlur}
          onChange={(key: string, values: string[] | string, contracts: any) => {
            onChange(key, values);
            setSelectedContracts(contracts);
          }}
          multiple
          columns={[
            SELECT_CONTRACT_COLUMN.CODE,
            SELECT_CONTRACT_COLUMN.PROVIDER,
            SELECT_CONTRACT_COLUMN.BENEFIT,
            SELECT_CONTRACT_COLUMN.STATUS,
            SELECT_CONTRACT_COLUMN.VALID_UNTIL,
          ]}
        />
        <Row style={{ padding: 0 }}>
          <Grid gap={24}>
            <SelectReasonAndSubReason
              isDisabled={isLoadingForm || isDisabledForm}
              values={useValues}
              onChangeSelect={onChangeReasonSubReason}
              useErrors={useErrors}
              onBlur={onBlur}
              onChange={onChange}
              showLabel
              required
            />
          </Grid>
        </Row>
        <TextArea
          rows={3}
          required
          label='Descrição'
          maxLength={ATTENDANCE_FORM_DESCRIPTION_MAX_LENGTH}
          value={useValues.description}
          textHelper={useErrors.description}
          onChange={(value) => {
            onChange('description', value.target.value);
          }}
          onBlur={(value) => {
            onBlur('description', value.target.value);
          }}
          status={useErrors.description ? StatusEnum.error : undefined}
          disabled={isDisabledForm || isLoadingForm}
          header={
            <Text
              value={`${useValues.description.length}/${ATTENDANCE_FORM_DESCRIPTION_MAX_LENGTH}`}
            />
          }
        />
        <Row style={{ padding: 0 }}>
          <FileUpload
            multiple
            statusFiles={attachmentsStatus}
            label='Anexos'
            name='attachments'
            allowedExtensions={ATTENDANCE_FORM_ATTACHMENT_ALLOWED_EXTENSIONS}
            onChange={(name: string, value: File[]) => {
              onChange(name, value);
              onBlur(name, value);
            }}
            formValidatorErrors={useErrors}
          />
        </Row>
      </Grid>
    </Row>
  );

  return (
    <BoxInfo icon={ATTENDANCE_ICON} title={title}>
      <FormValidate
        resource={attendance}
        onChangeForm={onChangeAttendanceForm}
        onBack={navigate.goBack}
        onSubmit={handleSubmitAttendanceFormData}
        fields={attendanceFormValidationSchema}
        body={getFields}
        buttons={{
          submit: {
            text: 'Concluir',
            disabled: isLoadingForm || isDisabledForm,
            loading: isLoadingForm,
          },
          back: {
            confirm: true,
            text: 'Voltar',
          },
        }}
      />
    </BoxInfo>
  );
};

export default Form;
