import removeInvalids from '@/utils/removeInvalids';
import qs from 'qs';
import { useCallback } from 'react';
import {
  URLSearchParamsInit,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export const useHistoryLocation = (): any => useLocation();
export const useHistoryParams = (): any => useParams();
export const useHistoryQuery = (): any => {
  const { search } = useLocation();
  if (!search) return {};
  const queryString = search.substring(1);
  return qs.parse(queryString);
};
export const useHistoryNavigator = (): {
  goBack: Function;
  push: Function;
  replace: Function;
  search: (...args: any) => any;
} => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    replace: useCallback((to: string) => navigate(to, { replace: true }), [navigate]),
    goBack: () => navigate(-1),
    push: useCallback((to: string, props: any) => navigate(to, { ...props }), [navigate]),
    search: (params: any) => {
      const validParams = removeInvalids({ ...searchParams, ...params });
      const searchString = qs.stringify(validParams, { arrayFormat: 'brackets', allowDots: true });
      setSearchParams(searchString);
    },
  };
};

export const useHistorySearchParams = (defaultInit?: URLSearchParamsInit) =>
  useSearchParams(defaultInit);

export const useRouteMatch = () => {
  return useMatches();
};
