import { Divider, ShowHide } from '@/components';
import useAuthenticatedUser from '@/core/hooks/useAuthenticatedUser';
import { CompanyTreeList } from '@/modules/company/views';
import { getCommomCompanyIds } from '@/modules/user/utils/compareCompanyIds';
import { useHistoryLocation, useHistoryParams, useHistoryQuery } from '@/navigation';
import ICONS from '@/shareds/constants/icons';
import React, { useEffect, useMemo, useState } from 'react';
import EmptyMessage from '@/components/emptyMessage';
import { Button, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { CreateOrUpdateForm, ListConfirmationHistory, RecoveryActions } from '../../components';
import { USER_STATUS, USER_TYPE } from '../../entities';
import { useUser } from '../../hooks';

const CreateOrUpdate: React.FC = () => {
  const { userId, companyId } = useHistoryParams();
  const { userStatus } = useHistoryQuery();
  const { pathname } = useHistoryLocation();
  const [formCompanyIds, setFormCompanyIds] = useState<string[]>([]);
  const { user: authenticatedUser } = useAuthenticatedUser();
  const isBrokerRoute = useMemo((): boolean => pathname.includes('/administrators'), [pathname]);

  const currentScopeOfUser = useMemo((): `${USER_TYPE}` => {
    return isBrokerRoute ? USER_TYPE.BROKER : USER_TYPE.COMPANY;
  }, [isBrokerRoute]);

  const { user, isLoading, deleteUser, reactivateUser, unblockUser } = useUser({
    userId,
    status: userStatus,
  });

  const isDeletedUserRoute = useMemo(() => {
    return userStatus === USER_STATUS.INACTIVATED && user?.status === USER_STATUS.INACTIVATED;
  }, [user?.status, userStatus]);

  useEffect(() => {
    if (userId && !user?.id) {
      return;
    }

    if (user?.id) {
      setFormCompanyIds(getCommomCompanyIds(user, authenticatedUser));
    } else {
      setFormCompanyIds([companyId]);
    }
  }, [authenticatedUser, companyId, user, userId]);

  return (
    <Row style={{ padding: 0 }}>
      <ShowHide visible={isDeletedUserRoute} transition='slideToDown'>
        <Text
          rounded
          size='medium'
          applyBaseColor
          color='warning'
          iconColor='warning'
          padding='8px 12px'
          icon='alert-triangle-outline'
          value='Este usuário está inativo, portanto a funcionalidade de alteração não é permitida.'
        />
        <Divider noBorder />
      </ShowHide>

      <CreateOrUpdateForm
        scope={currentScopeOfUser}
        user={user}
        isLoading={isLoading}
        setFormCompanyIds={setFormCompanyIds}
        headerActions={
          user?.id && (
            <RecoveryActions
              scope={currentScopeOfUser}
              status={user?.status}
              userId={user?.id}
              onDelete={deleteUser}
              onReactive={reactivateUser}
              onUnblock={unblockUser}
            />
          )
        }
      />

      {!isBrokerRoute && companyId && (
        <CompanyTreeList
          loading={isLoading}
          companyIds={formCompanyIds}
          emptyListMessage={
            <EmptyMessage
              title='Este usuário não está relacionado a nenhuma empresa.'
              description='Clique no botão abaixo para relacionar o usuário a uma empresa.'>
              <Grid margin='8px 0 0'>
                <Button
                  label='Relacionar este usuário a uma empresa'
                  onClick={() => document.getElementById('selectCompanyChips_input')?.focus()}
                />
              </Grid>
            </EmptyMessage>
          }
          notChildrenMessage={
            <Text
              value='Esta empresa é a única do grupo.'
              applyBaseColor
              padding='8px'
              rounded
              size='medium'
              icon={ICONS.INFO}
              iconColor='light'
            />
          }
        />
      )}

      {userId && <ListConfirmationHistory loading={isLoading} userId={userId} />}
    </Row>
  );
};

export default CreateOrUpdate;
