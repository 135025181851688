import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistoryNavigator } from '@/navigation';

import { findRoutePathByName } from '@/core/utils';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { analytics } from '@/shared/telemetry/telemetry';
import { generatePath } from 'react-router-dom';
import { Button, Icon } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { MOVE_TYPE_COLLECTION, MoveParams, MoveTypeCollection } from '../../entities';
import { useMoveList } from '../../hooks';
import { ROUTE_MOVES } from '../../routes/moveRoute';

const LastMove: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { company } = useCompany();

  const [filters, setFilters] = useState<MoveParams>({});

  const { moves, isEmptyMoves } = useMoveList(filters);

  const showLastMoveUploaded = useMemo(() => {
    const move = moves[0];
    if (!isEmptyMoves && move) {
      return move.uploadedAt;
    }
    return '';
  }, [moves, isEmptyMoves]);

  const getLastMoveId = useMemo(() => {
    const move = moves[0];
    if (!move) {
      return '';
    }
    return move.id;
  }, [moves]);

  const onClick = useCallback(() => {
    const moveRouteFinded = findRoutePathByName(ROUTE_MOVES.DETAIL);
    if (moveRouteFinded) {
      analytics.track('ACCESS_LAST_REGISTRATION_MOVEMENT');
      navigate.push(
        generatePath(moveRouteFinded, {
          companyId: company.id,
          moveId: getLastMoveId,
        }),
      );
    }
  }, [navigate, company.id, getLastMoveId]);

  useEffect(() => {
    if (!company.id) {
      return;
    }
    setFilters({
      companyId: company.id,
      pageSize: 1,
      sort: '-uploadedAt',
      type: MOVE_TYPE_COLLECTION as MoveTypeCollection,
    });
  }, [company.id]);
  return (
    <>
      {showLastMoveUploaded && (
        <Button solo color='light' onClick={onClick}>
          <Grid gap={6} alignItems='center' alignContent='left'>
            <strong>Última Movimentação cadastral: </strong>
            {showLastMoveUploaded}
            <Icon name='external-link-outline' color='colorDefault' />
          </Grid>
        </Button>
      )}
    </>
  );
};

export default LastMove;
