import { useHistoryNavigator } from '@/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SelectOption } from '@/components/selectValidate';

import { ContractToForm } from '@/modules/contract/views';

import { StatusFile } from '@/components/fileUpload/types/fileUpload.types';
import { useContract } from '@/modules/contract';
import { extractSelectOptions } from '@/utils';

import { AttendanceService } from '@/modules/attendance/services';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { get, pick } from 'lodash';
import {
  ATTENDANCE_INITIAL_FORM,
  Attendance,
  AttendanceCreateFormData,
  AttendanceCreateParams,
} from '../../entities';
import { getAutofillMessages } from '../../utils';
import useCreateAttendanceAttachments from '../useCreateAttendanceAttachments/useCreateAttendanceAttachments';

interface UseCreateAttendanceProps {
  params?: AttendanceCreateParams;
}

interface UseCreateAttendance {
  attendance: AttendanceCreateFormData;
  onChangeAttendanceForm: (changedValue: Partial<AttendanceCreateFormData>) => void;
  handleSubmitAttendanceFormData: () => void;
  attendanceFormValidationSchema: Record<string, any[]>;
  isLoadingForm: boolean;
  isDisabledForm: boolean;
  attachmentsStatus: StatusFile[];
  setSelectedContracts: (values: SelectOption<ContractToForm>[]) => void;
}

const attendanceService = new AttendanceService();

const useCreateAttendance = ({ params }: UseCreateAttendanceProps): UseCreateAttendance => {
  const getContractId = useMemo(() => {
    if (params && params.baseId && params.contractId && params.ruleType) {
      return params.contractId;
    }
    return undefined;
  }, [params]);

  const navigate = useHistoryNavigator();
  const { company } = useCompany();
  const { contract, isLoadingContract } = useContract({
    contractId: getContractId,
  });

  const [isLoadingForm, setLoadingForm] = useState<boolean>(false);
  const [attendance, setAttendance] = useState<AttendanceCreateFormData>(ATTENDANCE_INITIAL_FORM);
  const [selectedContracts, setSelectedContracts] = useState<SelectOption<ContractToForm>[]>([]);

  const { attachmentsStatus, createAttachments } = useCreateAttendanceAttachments({
    companyCNPJ: company?.cnpj,
    attachments: attendance.attachments,
  });

  const attendanceFormValidationSchema: Record<string, any[]> = {
    subject: ['required'],
    contractIds: [],
    reason: ['required'],
    subReason: [
      (subReason: string) => {
        if (attendance.reason && !subReason) {
          return 'Campo obrigatório';
        }
        return null;
      },
    ],
    description: ['required'],
    attachments: [],
  };

  const onChangeAttendanceForm = (changedValue: Partial<AttendanceCreateFormData>): void => {
    setAttendance((currData) => ({
      ...currData,
      ...changedValue,
    }));
  };

  const getAttendanceCreateDto = () => {
    const dataToCreate = pick(attendance, [
      'subject',
      'description',
      'reason',
      'subReason',
    ]) as Attendance;

    dataToCreate.contracts = selectedContracts.map((selected) => ({
      code: get(selected, 'data.code'),
      providerCnpj: get(selected, 'data.provider.cnpj'),
      salesforceId: get(selected, 'data.salesforceId') || undefined,
    }));

    return dataToCreate;
  };

  const handleSubmitAttendanceFormData = async (): Promise<void> => {
    const successMessage = attendance.attachments?.length
      ? 'A solicitação de atendimento foi criada. Agora só falta enviar os anexos.'
      : 'A solicitação de atendimento foi criada.';

    setLoadingForm(true);

    const attendanceDto = getAttendanceCreateDto();

    try {
      const createdAttendance = await attendanceService.create<Attendance>(
        company.cnpj,
        attendanceDto,
      );

      if (createdAttendance.id) {
        dispatchToast({
          title: 'Sucesso',
          text: successMessage,
          type: 'success',
          titleColor: 'success',
        });

        await createAttachments(createdAttendance.id);

        navigate.goBack();
      }
    } catch (error) {
      console.error(error);
      dispatchToast({
        text: 'Ops! Não foi possível criar um novo atendimento.',
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const loadInitialValues = useCallback(
    ({ ruleType, baseId, contractId }: AttendanceCreateParams) => {
      if (!contract || !ruleType || !baseId || !contractId) {
        return;
      }

      const { code } = contract;

      const { subject, description } = getAutofillMessages(ruleType, baseId, code);

      const normalizedContract = extractSelectOptions(
        [contract],
        'code',
        'id',
      ) as SelectOption<ContractToForm>[];

      setSelectedContracts(normalizedContract);

      setAttendance((curr) => ({
        ...curr,
        subject,
        description,
        contractIds: [contractId],
        reason: 'Contrato',
        subReason: 'Contrato',
      }));
    },
    [contract],
  );

  const isDisabledForm = useMemo(
    (): boolean => isLoadingContract || isLoadingForm,
    [isLoadingContract, isLoadingForm],
  );

  useEffect(() => {
    if (params) {
      loadInitialValues(params);
    }
  }, [contract, loadInitialValues, params]);

  useEffect(() => {
    if (attachmentsStatus.length) {
      const isOnlySuccess = attachmentsStatus.every((status) => {
        return status.value && status.value === 'success';
      });

      const isFinished = attachmentsStatus.every((status) => {
        return status.value && (status.value === 'success' || status.value === 'error');
      });
      if (isOnlySuccess && isFinished) {
        const attachmentsSuccess =
          attachmentsStatus.length > 1
            ? 'Todos os anexos foram enviados com sucesso.'
            : 'Anexo enviado com sucesso';
        dispatchToast({
          title: 'Sucesso',
          text: attachmentsSuccess,
          type: 'success',
          titleColor: 'success',
        });
      } else if (isFinished) {
        const attachmentsError =
          attachmentsStatus.length > 1
            ? 'Um ou mais anexos não puderam ser enviados.'
            : 'O anexo não pode ser enviado.';
        dispatchToast({
          text: attachmentsError,
        });
      }
    }
  }, [attachmentsStatus]);

  return {
    attendance,
    onChangeAttendanceForm,
    handleSubmitAttendanceFormData,
    setSelectedContracts,
    attendanceFormValidationSchema,
    isLoadingForm,
    isDisabledForm,
    attachmentsStatus,
  };
};

export default useCreateAttendance;
