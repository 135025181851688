import { BeneficiaryInfo, Status } from '@/modules/beneficiary/components';
import { BENEFICIARY_TABLE_COLUMN, BeneficiaryNormalized } from '@/modules/beneficiary/entities';
import React, { useMemo } from 'react';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

interface UseBeneficiariesTaableColumnsProps {
  columns?: BENEFICIARY_TABLE_COLUMN[];
}

const useBeneficiariesTableColumns = ({ columns }: UseBeneficiariesTaableColumnsProps) => {
  const AVALIBLE_COLUMNS: Record<BENEFICIARY_TABLE_COLUMN, DataTableColumn> = useMemo(
    () => ({
      [BENEFICIARY_TABLE_COLUMN.NAME]: {
        name: 'NOME',
        path: 'life.name',
        format: ({ life, isHolder, ownership }: BeneficiaryNormalized) => (
          <BeneficiaryInfo summary isHolder={isHolder} ownership={ownership} name={life.name} />
        ),
      },
      [BENEFICIARY_TABLE_COLUMN.CPF]: {
        name: 'CPF',
        path: 'life.cpf',
      },
      [BENEFICIARY_TABLE_COLUMN.HEALTH_CARD]: {
        name: 'CARTEIRINHA',
        path: 'healthCardNumber',
      },
      [BENEFICIARY_TABLE_COLUMN.BENEFIT]: {
        name: 'BENEFÍCIO',
        path: 'negotiation.product.benefit.name',
      },
      [BENEFICIARY_TABLE_COLUMN.STATUS]: {
        name: 'STATUS',
        path: 'status',
        format: ({ status }: BeneficiaryNormalized) => <Status label={status} />,
      },
    }),
    [],
  );

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  return {
    useColumns,
  };
};

export default useBeneficiariesTableColumns;
