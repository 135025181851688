import { COMPANY_STATUS } from '@/modules/company/entities/company.enum';
import { useApplicationContext } from '@/core/hooks/useApplicationContext';
import { Company, CompanyConfigType } from '@/modules/company';
import { useCurrentUser } from '@/modules/profile';
import { analytics } from '@/shared/telemetry/analytics/analytics';
import storage from '@/utils/storage/storage';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

interface CompanyToPropagate {
  id: string;
  name: string;
  cnpj: string;
  companyConfigs?: CompanyConfigType[];
  salesforceId?: string | null;
  salesforceParentId?: string | null;
  inactive?: boolean;
  status?: COMPANY_STATUS | null;
}

export const useCompanyPropagate = () => {
  const { setCompany } = useApplicationContext();
  const { user } = useCurrentUser();

  const addRecentCompanyInLocalStorage = useCallback(
    (company: Company) => {
      const recentCompanies = storage.get<Company[]>(`${user.id}.recentCompanies`) ?? [];
      const isCompanyInLocalStage = recentCompanies.some(
        (recentCompany) => recentCompany.id === company.id,
      );
      if (isCompanyInLocalStage) {
        return;
      }
      recentCompanies.unshift(company);
      storage.set<Company[]>(`${user.id}.recentCompanies`, recentCompanies.slice(0, 3));
    },
    [user],
  );

  const extractCompanyToPropagate = useCallback(
    (company: Company): CompanyToPropagate => ({
      id: company.id,
      name: company.name,
      cnpj: company.cnpj,
      companyConfigs: company.companyConfigs,
      salesforceId: company.salesforceId,
      salesforceParentId: company.salesforceParentId,
      inactive: company?.inactive,
      status: company?.status,
    }),
    [],
  );

  const propagateCompany = useCallback(
    (selectedCompany: Company) => {
      const companyToPropagate = extractCompanyToPropagate(selectedCompany);
      setCompany(companyToPropagate);
      mixpanel.people.set(companyToPropagate);
      analytics.track('CHANGE_COMPANY');
      localStorage.setItem('company', JSON.stringify(companyToPropagate));
      addRecentCompanyInLocalStorage(companyToPropagate);
      window.dispatchEvent(new CustomEvent('loadCompany'));
    },
    [addRecentCompanyInLocalStorage, extractCompanyToPropagate, setCompany],
  );

  return {
    propagateCompany,
  };
};
