import { Over } from './over';

export const OverModel: Over[] = [
  {
    id: '0-18',
    ageBegin: '0',
    ageEnd: 18,
    markup: '0',
  },
  {
    id: '19-23',
    ageBegin: 19,
    ageEnd: 23,
    markup: '0',
  },
  {
    id: '24-28',
    ageBegin: 24,
    ageEnd: 28,
    markup: '0',
  },
  {
    id: '29-33',
    ageBegin: 29,
    ageEnd: 33,
    markup: '0',
  },
  {
    id: '34-38',
    ageBegin: 34,
    ageEnd: 38,
    markup: '0',
  },
  {
    id: '39-43',
    ageBegin: 39,
    ageEnd: 43,
    markup: '0',
  },
  {
    id: '44-48',
    ageBegin: 44,
    ageEnd: 48,
    markup: '0',
  },
  {
    id: '49-53',
    ageBegin: 49,
    ageEnd: 53,
    markup: '0',
  },
  {
    id: '54-58',
    ageBegin: 54,
    ageEnd: 58,
    markup: '0',
  },
  {
    id: '59-199',
    ageBegin: 59,
    ageEnd: 199,
    markup: '0',
  },
];
