import { findRoutePathByName } from '@/core/utils';
import { Main } from '@/core/views';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import React, { useCallback, useMemo } from 'react';
import useCompanyApplication from '@/core/hooks/useCompanyApplication';
import { generatePath } from 'react-router-dom';
import TabMain from '../../components/tabMain/tabMain';
import { CONTRACT_SVA_HOME_TABS, CONTRACT_SVA_HOME_TABS_ID } from '../../entities';
import { ROUTE_CONTRACT_NAME, ROUTE_SVA_NAME } from '../../routes';

const Home: React.FC = () => {
  const { pathname } = useHistoryLocation();
  const navigate = useHistoryNavigator();
  const { companyId } = useCompanyApplication();

  const getTabActive = useCallback(
    (tab) => {
      if (tab.id === CONTRACT_SVA_HOME_TABS_ID.SVA && pathname.includes('svas')) {
        return true;
      }
      if (tab.id === CONTRACT_SVA_HOME_TABS_ID.CONTRACT && !pathname.includes('svas')) {
        return true;
      }
      return false;
    },
    [pathname],
  );

  const renderTabs = useMemo(() => {
    return CONTRACT_SVA_HOME_TABS.map((tab) => ({
      ...tab,
      active: getTabActive(tab),
    }));
  }, [getTabActive]);

  const handleChangeTabs = (newTab: number) => {
    const contractRouteFound = findRoutePathByName(ROUTE_CONTRACT_NAME.CONTRACT_LIST);
    const svaRouteFound = findRoutePathByName(ROUTE_SVA_NAME.SVA_LIST);

    if (newTab === CONTRACT_SVA_HOME_TABS_ID.CONTRACT && contractRouteFound) {
      return navigate.push(generatePath(contractRouteFound, { companyId }));
    }
    if (newTab === CONTRACT_SVA_HOME_TABS_ID.SVA && svaRouteFound) {
      return navigate.push(generatePath(svaRouteFound, { companyId }));
    }
  };

  return (
    <>
      <TabMain actions={renderTabs} onChange={handleChangeTabs} />
      <Main invertPageHeaderAndChildren data-testid='main' />
    </>
  );
};

export default Home;
