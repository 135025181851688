import { classesBuilder } from '@/utils';
import React, { CSSProperties, ReactNode } from 'react';
import { Icon, Loader, Text } from 'vkit/lib/components';
import { Box, Grid } from 'vkit/lib/context';
import style from './style/boxInfo.module.scss';

type BoxInfoType = {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  title?: any;
  icon?: string;
  margin?: string | number;
  elevation?: number;
  titleSticky?: boolean;
  padding?: string | number;
  iconImageUrl?: string;
  inlineStyle?: CSSProperties;
  loading?: boolean;
};

const BoxInfo: React.FC<BoxInfoType> = ({
  children,
  elevation = 4,
  header,
  footer,
  icon,
  margin,
  title,
  titleSticky,
  padding,
  iconImageUrl,
  inlineStyle,
  loading,
}) => (
  <div
    className={classesBuilder(style, { boxInfo: true, noBorder: !elevation })}
    style={{
      margin,
    }}>
    <Box elevation={elevation} type='sheet' outlined rounded>
      <div className={style.content} style={inlineStyle}>
        {loading ? (
          <Grid alignItems='center' alignContent='center' padding='48px 0'>
            <Loader color='colorDefault' />
          </Grid>
        ) : (
          <>
            {(title || header) && (
              <div
                className={classesBuilder(style, {
                  title: true,
                  sticky: titleSticky,
                })}>
                <Grid alignItems='center'>
                  {(icon || iconImageUrl) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 35,
                        height: 35,
                        marginRight: 8,
                        borderRadius: 6,
                        backgroundColor: 'rgba(0, 123, 255, 0.1)',
                      }}>
                      {icon ? (
                        <Icon name={icon} size={24} color='colorDefault' />
                      ) : (
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            mask: `url(${iconImageUrl}) no-repeat center / contain`,
                            WebkitMask: `url(${iconImageUrl}) no-repeat center / contain`,
                            backgroundColor: '#007bff',
                          }}
                        />
                      )}
                    </div>
                  )}

                  {typeof title === 'string' ? (
                    <Text color='colorDefault' iconColor='colorDefault' size='small' title value={title} />
                  ) : (
                    title
                  )}
                </Grid>

                {header}
              </div>
            )}

            <div className={style.childrenContent} style={{ padding }}>
              {children}
            </div>

            {footer && (
              <Grid padding='0 20px 20px' alignContent='right' alignItems='center'>
                {footer}
              </Grid>
            )}
          </>
        )}
      </div>
    </Box>
  </div>
);

export default BoxInfo;
