import { ObjectType } from '@/shareds/types';

const removeEmptyParams = <T = ObjectType>(obj: ObjectType) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (![undefined, '', null].includes(value)) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {}) as T;

export default removeEmptyParams;
