import React from 'react';

import { DataTable } from 'vkit/lib/components';

import { CADASTRAL_POSITION_ROW_COLUMNS } from '@/modules/cadastralPosition/entities/cadastralPositionRow.enum';
import { ParamsToFilterRows } from '@/modules/cadastralPosition/entities/cadastralPositionRow.interface';
import useCadastralPositionRowTable from '@/modules/cadastralPosition/hooks/useCadastralPositionRowTable';

interface TableProps {
  columns?: CADASTRAL_POSITION_ROW_COLUMNS[];
  linesLoader?: number;
  filters: ParamsToFilterRows;
  handleFilters: (params: ParamsToFilterRows) => void;
}

const CadastralPositionRowTable: React.FC<TableProps> = ({
  columns,
  filters,
  handleFilters,
  linesLoader = 5,
}) => {
  const {
    loading,
    showPagination,
    pagination,
    handleChangePageSize,
    handlePageChange,
    useColumns,
    rows,
  } = useCadastralPositionRowTable({
    columns,
    handleFilters,
    filters,
  });

  return (
    <DataTable
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={rows}
      isLoading={loading}
      showPagination={showPagination && !loading}
      total={pagination.total}
      totalPages={pagination.totalPages}
      page={pagination.page}
      pageSize={filters?.pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handleChangePageSize}
    />
  );
};

export default CadastralPositionRowTable;
