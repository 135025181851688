import React from 'react';

import { DataTable } from 'vkit/lib/components';

import useAdditionalServices from '../hooks/useAdditionalServices';
import useAdditionalServicesTableColumns from '../hooks/useAdditionalServicesTableColumns';
import { ADDITIONAL_SERVICES_TABLE_COLUMN } from '../entities/constants';
import { AdditionalServicesParams } from '../entities/interfaces';
import style from './additionalServiceTable.module.scss';

interface TableProps {
  filters?: AdditionalServicesParams;
  columns?: ADDITIONAL_SERVICES_TABLE_COLUMN[];
  linesLoader?: number;
}

const AdditionalServiceTable: React.FC<TableProps> = ({ linesLoader, columns, filters }) => {
  const { isLoading, additionalServices } = useAdditionalServices(filters);

  const { useColumns } = useAdditionalServicesTableColumns({
    columns,
  });

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <DataTable
        className={style.table}
        loadingLinesNumber={linesLoader}
        columns={useColumns}
        data={!isLoading ? additionalServices : []}
        isLoading={isLoading}
        showPagination={false}
        elevation={0}
        outlined={false}
        backGroundColorHide
      />
    </div>
  );
};

export default AdditionalServiceTable;
