import { EmptyMessage } from '@/components';
import React from 'react';

export interface EmptyProps {
  isEmptyReport?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
}

const Empty: React.FC<EmptyProps> = ({
  isEmptyReport,
  emptyTitle = 'Nenhum erro!',
  emptyMessage = 'Nenhum erro de validação foi encontrado',
}) => {
  return <>{isEmptyReport && <EmptyMessage title={emptyTitle} description={emptyMessage} />}</>;
};

export default Empty;
