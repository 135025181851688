import { COMPANY_GROUP_INITIAL_STATES } from '@/modules/companyGroup/entities/companyGroup.const';
import { CompanyGroup } from '@/modules/companyGroup/entities/companyGroup.interface';
import NegotiationsService from '@/shared/services/negotiationsService';
import { useCallback, useEffect, useState } from 'react';

import { dispatchToast } from '@/utils/tryOrCatchMessageError';

const negotiationsService = new NegotiationsService();

const useCompanyGroupDetails = (companyGroupId: string) => {
  const [loading, setLoading] = useState(false);
  const [companyGroup, setCompanyGroup] = useState(COMPANY_GROUP_INITIAL_STATES);

  const loadCompanyGroup = useCallback(async (companyGroupIdToFind: string) => {
    if (!companyGroupIdToFind) {
      return;
    }

    setLoading(true);

    try {
      const response =
        await negotiationsService.getCompanyGroup<CompanyGroup>(companyGroupIdToFind);
      setCompanyGroup(response);
    } catch (e) {
      console.error(e);
      dispatchToast({
        text: 'Ops! Não conseguimos buscar as informações deste grupo de empresa.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!companyGroupId) {
      return;
    }
    loadCompanyGroup(companyGroupId);
  }, [loadCompanyGroup, companyGroupId]);

  return {
    loading,
    companyGroup,
    loadCompanyGroup,
  };
};

export default useCompanyGroupDetails;
