import React, { ReactNode } from 'react';

import style from './style/gridGroup.module.scss';

type DataType = {
  hidden?: boolean;
  mobile?: number;
  middle?: number;
  default?: number;
  component: ReactNode;
};

interface GridGroupInterface {
  gap?: number;
  body: DataType[];
}

const GridGroup: React.FC<GridGroupInterface> = ({ gap = 20, body }) => {
  const getWidth = (item: DataType): number => {
    if (window.innerWidth <= 768) {
      return item.mobile || 100;
    } else if (window.innerWidth <= 1024) {
      return item.middle || 50;
    }

    return item.default || 33.33;
  };

  return (
    <div
      className={style.gridGroup}
      style={{
        margin: `0 -${gap}px -${gap}px 0`,
      }}>
      {body.map((content, key) =>
        !content.hidden ? (
          <div
            className={style.gridGroupItem}
            key={key}
            style={{
              margin: `0 ${gap}px ${gap}px 0`,
              width: `calc(100% - ${gap}px)`,
              maxWidth: `calc(${getWidth(content)}% - ${gap}px)`,
            }}>
            {content.component}
          </div>
        ) : null,
      )}
    </div>
  );
};

export default GridGroup;
