import { PERSISTENCE_STATUS } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.enum';
import { PesistenceInfo } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.interface';

export const PERSISTENCE_STATUS_INFO: Record<PERSISTENCE_STATUS, PesistenceInfo> = {
  [PERSISTENCE_STATUS.NOT_PERSISTED]: {
    description: 'Este item ainda não foi salvo.',
    name: 'Não salvo',
  },
  [PERSISTENCE_STATUS.PERSISTED]: {
    description: 'Este item foi salvo, aguarde o final do processo.',
    name: 'Salvo',
  },
  [PERSISTENCE_STATUS.PERSISTING]: {
    description: 'Estamos salvando este item, aguarde o final do processo.',
    name: 'Salvando',
  },
  [PERSISTENCE_STATUS.ERROR]: {
    description: 'Falha ao salvar este item, tente novamente.',
    name: 'Erro',
  },
};
