import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';
import { get } from 'lodash';
import { Contact, CONTACT_TYPE } from '@/services/beneficiaries/endpoints/contacts';

export type LifeContactsByType = { [K in CONTACT_TYPE]: string };

export const extractContactsFromBeneficiary = (beneficiary: Beneficiary) =>
  Object.values(CONTACT_TYPE).reduce((acc, type) => {
    const lifeDocumentFound: Contact | undefined = get(beneficiary, 'life.contacts', []).find(
      (contact: Contact) => contact.type === type,
    );

    return {
      ...acc,
      [type]: lifeDocumentFound?.value,
    };
  }, {}) as LifeContactsByType;
