import ShowHide from '@/components/showHide';
import { COMPANY_COLUMNS } from '@/modules/company/entities/company.enum';
import CompanyTable from '@/modules/company/views/companyTable/companyTable';
import { COMPANY_GROUP_DETAILS_TABS } from '@/modules/companyGroup/entities/companyGroup.const';
import { COMPANY_GROUP_TAB_IDS } from '@/modules/companyGroup/entities/companyGroup.enum';
import useCompanyGroupDetails from '@/modules/companyGroup/hooks/useCompanyGroupDetails';
import CompanyGroupTitle from '@/modules/companyGroup/views/companyGroupTitle/companyGroupTitle';
import {
  FINANCIAL_ADJUSTMENT_ACTIONS,
  FINANCIAL_ADJUSTMENT_COLUMNS,
  FINANCIAL_ADJUSTMENT_LIST_FILTERS,
} from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import FinancialAdjustmentTableAndFilters from '@/modules/financialAdjustment/views/financialAdjustmentTableAndFilters';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import React, { useMemo } from 'react';
import { Button, Tabs } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

const Details: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { companyGroupId } = useHistoryParams();
  const [controlTabs, setControlTabs] = useQueryStringState({
    currentTab: 0,
  });
  const currentTab = useMemo(() => Number(controlTabs.currentTab), [controlTabs.currentTab]);

  const { companyGroup, loading, loadCompanyGroup } = useCompanyGroupDetails(companyGroupId);

  const tabs = useMemo(() => {
    return COMPANY_GROUP_DETAILS_TABS.map((companyGroupDetailsTab) => ({
      ...companyGroupDetailsTab,
      active: companyGroupDetailsTab.id === currentTab,
    }));
  }, [currentTab]);

  return (
    <>
      <CompanyGroupTitle companyGroup={companyGroup} loading={loading} />

      <Tabs
        iconDir='left'
        elements={[]}
        actions={tabs}
        onChange={(currentTab) => setControlTabs({ currentTab })}
      />

      <ShowHide displayNone visible={currentTab === COMPANY_GROUP_TAB_IDS.COMPANIES}>
        <CompanyTable
          loading={loading}
          companies={companyGroup.companies}
          columns={[
            COMPANY_COLUMNS.NAME,
            COMPANY_COLUMNS.SUPPORT_NAME,
            COMPANY_COLUMNS.CNPJ,
            COMPANY_COLUMNS.CREATED_AT,
          ]}
        />
      </ShowHide>

      <ShowHide displayNone visible={currentTab === COMPANY_GROUP_TAB_IDS.FINANCIAL_ADJUSTMENT}>
        <FinancialAdjustmentTableAndFilters
          fields={[
            {
              type: FINANCIAL_ADJUSTMENT_LIST_FILTERS.CONTRACT,
              size: 75,
            },
            {
              type: FINANCIAL_ADJUSTMENT_LIST_FILTERS.FINANCIAL_UNIT,
              size: 25,
            },
          ]}
          columns={[
            FINANCIAL_ADJUSTMENT_COLUMNS.CONTRACT,
            FINANCIAL_ADJUSTMENT_COLUMNS.DATE,
            FINANCIAL_ADJUSTMENT_COLUMNS.VALUE,
            FINANCIAL_ADJUSTMENT_COLUMNS.DESCRIPTION,
          ]}
          actions={[FINANCIAL_ADJUSTMENT_ACTIONS.REMOVE]}
          removeFinancialAdjustmentCallback={() => loadCompanyGroup(companyGroupId)}
          financialAdjustments={companyGroup.adjustments}
          loading={loading}>
          <Grid alignContent='right' stretch>
            <Button
              loading={loading}
              label='Novo ajuste financeiro'
              onClick={() =>
                navigate.push(
                  `/company-groups/${companyGroupId}/financial-adjustments/actions/create`,
                )
              }
              color='colorDefault'
            />
          </Grid>
        </FinancialAdjustmentTableAndFilters>
      </ShowHide>
    </>
  );
};

export default Details;
