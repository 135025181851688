import { useCallback, useState } from 'react';
import { dispatchToast, tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { Address } from '../../entities';
import { normalizeSearchedAddress } from '../../services';
import { viaCepService } from '../../services/viacep';

interface UseAddressFinderArgs {
  findedAddressCallback?: (addressFound: Address) => void;
}

const useAddressFinder = ({ findedAddressCallback }: UseAddressFinderArgs) => {
  const [isFinding, setFinding] = useState<boolean>(false);
  const [findedAddress, setFindendAddress] = useState<Address>({});

  const findAddress = useCallback(
    (zipcode: string) => {
      if (zipcode === findedAddress.zipCode) {
        return;
      }

      if (!zipcode || zipcode.length !== 8) {
        setFindendAddress({});
        findedAddressCallback?.({});
        return;
      }

      setFinding(true);

      tryOrCatchMessageError(
        async () => {
          const response = await viaCepService.find(zipcode);
          if (response.erro) {
            dispatchToast({
              text: 'Ops! Não foi possível encontrar o endereço com base no CEP informado.',
            });
            setFindendAddress({});
            findedAddressCallback?.({});

            return;
          }

          const addressFound = normalizeSearchedAddress(response);
          setFindendAddress(addressFound);
          findedAddressCallback?.(addressFound);
        },
        {
          messageErrorDefault:
            'Ops! Não foi possível encontrar o endereço com base no CEP informado.',
        },
      );

      setFinding(false);
    },
    [findedAddress.zipCode, findedAddressCallback],
  );

  return {
    findedAddress,
    isFinding,
    findAddress,
  };
};

export default useAddressFinder;
