import { Dispatch, SetStateAction } from 'react';

export const useChangeState = <T>(setStateAction: Dispatch<SetStateAction<T>>, defaultValues?: Partial<T>) => {
  return (value: T) =>
    setStateAction((current) => ({
      ...defaultValues,
      ...current,
      ...value,
    }));
};
