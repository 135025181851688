import { GridGroup, SelectValidate, TextFieldValidate } from '@/components';
import { ObjectType } from '@/shareds/types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ADDRESS_LABEL, ADDRESS_NAME, Address, ADDRESS } from '../../entities';
import { useAddressFinder } from '../../hooks';
import { getStateOptions } from '../../services';

interface AddressFormProps {
  values: Address;
  onBlur?: Function;
  useErrors?: ObjectType;
  onChange: Function;
}

const Form: React.FC<AddressFormProps> = ({ values, onBlur, useErrors, onChange }) => {
  const initialValues = useRef(values);
  const isInitialValuesEmpty = useMemo(
    () => Object.values(initialValues.current).every((value) => !value),
    [],
  );

  const { isFinding, findAddress } = useAddressFinder({
    findedAddressCallback: (addressFound) => {
      onChange({
        [ADDRESS.CITY]: addressFound.city,
        [ADDRESS.NEIGHBORHOOD]: addressFound.neighborhood,
        [ADDRESS.LOCATION]: addressFound.location,
        [ADDRESS.STATE_ABBR]: addressFound.state_abbr,
      });

      setDisabled({
        [ADDRESS.CITY]: true,
        [ADDRESS.NEIGHBORHOOD]: true,
        [ADDRESS.LOCATION]: true,
        [ADDRESS.STATE_ABBR]: true,
        [ADDRESS.NUMBER]: Object.keys(addressFound).length === 0,
        [ADDRESS.COMPLEMENT]: Object.keys(addressFound).length === 0,
      });
    },
  });

  const [disabled, setDisabled] = useState<Partial<Record<ADDRESS, boolean>>>({
    [ADDRESS.CITY]: isInitialValuesEmpty || Boolean(initialValues.current.city),
    [ADDRESS.NEIGHBORHOOD]: isInitialValuesEmpty || Boolean(initialValues.current.neighborhood),
    [ADDRESS.LOCATION]: isInitialValuesEmpty || Boolean(initialValues.current.location),
    [ADDRESS.STATE_ABBR]: isInitialValuesEmpty || Boolean(initialValues.current.state_abbr),
    [ADDRESS.NUMBER]: isInitialValuesEmpty || Boolean(initialValues.current.number),
    [ADDRESS.COMPLEMENT]: isInitialValuesEmpty || Boolean(initialValues.current.complement),
  });

  const useValues = values as ObjectType;

  const handleChange = useCallback(
    (name: string, value: string) => {
      if (value !== useValues[name]) {
        onChange(name, value);
      }
    },
    [onChange, useValues],
  );

  return (
    <GridGroup
      body={[
        {
          default: 25,
          component: (
            <TextFieldValidate
              label={ADDRESS_LABEL.ZIPCODE}
              name={ADDRESS_NAME.ZIPCODE}
              onBlur={onBlur}
              onChange={(name: string, value: string) => {
                onChange(name, value);
                findAddress(value);
              }}
              required
              useErrors={useErrors}
              useValues={useValues}
              mask='99999-999'
              loading={isFinding}
            />
          ),
        },
        {
          default: 55,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.LOCATION}
              name={ADDRESS_NAME.LOCATION}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={isFinding}
              disabled={disabled[ADDRESS.LOCATION]}
            />
          ),
        },
        {
          default: 20,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.NUMBER}
              name={ADDRESS_NAME.NUMBER}
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              loading={isFinding}
              disabled={disabled[ADDRESS.NUMBER]}
            />
          ),
        },
        {
          default: 40,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.NEIGHBORHOOD}
              name={ADDRESS_NAME.NEIGHBORHOOD}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={isFinding}
              disabled={disabled[ADDRESS.NEIGHBORHOOD]}
            />
          ),
        },
        {
          default: 40,
          component: (
            <TextFieldValidate
              required
              label={ADDRESS_LABEL.CITY}
              name={ADDRESS_NAME.CITY}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              loading={isFinding}
              disabled={disabled[ADDRESS.CITY]}
            />
          ),
        },
        {
          default: 20,
          component: (
            <SelectValidate
              required
              searchable
              label={ADDRESS_LABEL.STATE_ABBR}
              name={ADDRESS_NAME.STATE_ABBR}
              useValues={useValues}
              useErrors={useErrors}
              onChange={handleChange}
              onBlur={onBlur}
              data={getStateOptions()}
              loading={isFinding}
              disabled={disabled[ADDRESS.STATE_ABBR]}
              key={useValues[ADDRESS_NAME.STATE_ABBR]}
            />
          ),
        },
        {
          default: 100,
          middle: 100,
          component: (
            <TextFieldValidate
              label={ADDRESS_LABEL.COMPLEMENT}
              name={ADDRESS_NAME.COMPLEMENT}
              useValues={useValues}
              useErrors={useErrors}
              onChange={onChange}
              onBlur={onBlur}
              loading={isFinding}
              disabled={disabled[ADDRESS.COMPLEMENT]}
            />
          ),
        },
      ]}
    />
  );
};

export default Form;
