import restfulBase from '@/services/beneficiaries/core/base';
import { BeneficiaryHolder } from '@/services/beneficiaries/endpoints/beneficiaryHolders';
import { Life } from '@/services/beneficiaries/endpoints/lives';
import { BankAccount } from '@/services/beneficiaries/endpoints/bankAccounts';
import { HealthCard } from '@/services/beneficiaries/endpoints/healthCards';
import { Base } from '@/shareds/types/base.type';

export enum BENEFICIARY_STATUS {
  WAITING = 'waiting',
  PRE_CANCELLED = 'pre-canceled',
  CANCELLED = 'canceled',
  DELETED = 'deleted',
  EXTENDED = 'extended',
  ACTIVE = 'active',
  PRE_ACTIVE = 'pre-active',
}

export interface Beneficiary extends Base {
  status: BENEFICIARY_STATUS;
  validFrom: string;
  validUntil?: string;
  negotiationId: string;
  lifeId: string;
  companyId: string;
  beneficiaryHolder?: BeneficiaryHolder;
  life?: Life;
  bankAccount?: BankAccount;
  healthCard?: HealthCard;
}

export const beneficiaryInitialState: Beneficiary = {
  status: BENEFICIARY_STATUS.WAITING,
  validFrom: '',
  negotiationId: '',
  lifeId: '',
  companyId: '',
};

const apiService = restfulBase('beneficiaries');

export default apiService;
