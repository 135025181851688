import { Toolbar } from '@/core/components';
import Menu from '@/core/components/menu/menu';
import { useCompanyWhenLoadPage } from '@/modules/company/hooks/useCompanyWhenLoadPage';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'vkit/lib/modules';
import app from '@/../package.json';

const Application: React.FC = () => {
  useCompanyWhenLoadPage();

  const footer = {
    description: {
      portal: 'Portal do RH',
      general: `${new Date().getFullYear()} © Vitta Coordenação em Saúde`,
      version: `v${app.version}`,
    },
  };

  const getMenu = (isOpen: boolean) => <Menu isOpen={isOpen} />;

  return (
    <Layout header={<Toolbar />} asideFooter={footer} asideContent={getMenu}>
      <Outlet />
    </Layout>
  );
};

export default Application;
