import React from 'react';

import { Button } from 'vkit/lib/components';

type ActionProps = {
  isDisabled: boolean;
  onClick: () => void;
  isLoading: boolean;
};

const Action: React.FC<ActionProps> = ({ isDisabled, onClick, isLoading }) => {
  return (
    <div>
      <Button
        bold
        loading={isLoading}
        onClick={() => onClick()}
        disabled={isDisabled}
        label={'Enviar movimentação'}
      />
    </div>
  );
};

export default Action;
