import { TextData } from '@/components';
import { useLoadCompany } from '@/modules/company/hooks';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import React, { useEffect } from 'react';

const Info: React.FC = () => {
  const { company } = useCompany();
  const { isLoading, loadedCompany, loadCompany } = useLoadCompany();

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadCompany(company.id);
  }, [company.id, loadCompany]);

  return (
    <TextData
      loading={isLoading}
      data={[
        {
          label: 'Razão Social',
          text: loadedCompany?.name || '-',
          sizeDefault: 70,
          sizeMedium: 100,
        },
        {
          label: 'CNPJ',
          text: loadedCompany?.cnpj || '-',
          sizeDefault: 30,
          sizeMedium: 100,
          type: 'cnpj',
        },
        {
          label: 'Executivo de relacionamento',
          text: loadedCompany?.supportName || '-',
          sizeDefault: 60,
          sizeMedium: 100,
        },
        {
          label: 'Início de vigência na Vitta',
          text: loadedCompany?.startValidityVitta || '-',
          type: 'date',
          sizeDefault: 40,
          sizeMedium: 100,
        },
      ]}
    />
  );
};

export default Info;
