import { ToastStatusGlobal } from '@/components';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import { getMessageError } from '@/utils/getMessageError';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useState } from 'react';
import { TOTP_RESET_ERRORS, USER_STATUS, User } from '../../entities';
import { UserService } from '../../services';
interface UseUserProps {
  userId?: string;
  status?: `${USER_STATUS}`;
}

const userService = new UserService();

const useUser = ({ userId, status }: UseUserProps) => {
  const { pathname } = useHistoryLocation();
  const { toast } = ToastStatusGlobal();
  const navigate = useHistoryNavigator();
  const isDeletedUser = status === USER_STATUS.INACTIVATED;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const loadUser = useCallback(async () => {
    if (!userId) {
      return;
    }
    setIsLoading(true);
    setUser(undefined);
    try {
      const user = isDeletedUser
        ? await userService.getDeletedUser(userId)
        : await userService.getUser(userId, {
            withDeleted: true,
          });

      const permissions = await userService.getUserPermissions(user.id);

      setUser({
        ...user,
        userPermissions: permissions || [],
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [isDeletedUser, userId]);

  const deleteUser = async () => {
    if (!userId) {
      return;
    }
    try {
      setIsLoading(true);
      await userService.remove(userId);
      navigate.goBack();
      toast('Feito', 'O usuário foi inativado com sucesso.', 'success');
    } catch (error) {
      console.error(error);
      dispatchToast({
        text: 'Falha ao inativar o usuário',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const reactivateUser = async (): Promise<void> => {
    if (!userId) {
      return;
    }
    try {
      setIsLoading(true);
      await userService.reactive(userId);
      toast('Feito', 'O usuário foi reativado com sucesso.', 'success');
      navigate.replace(pathname.replace('?userStatus=deleted', ''));
      loadUser();
    } catch (error) {
      console.error(error);
      dispatchToast({
        text: 'Falha ao reativar o usuário',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const unblockUser = async (): Promise<void> => {
    if (!userId) {
      return;
    }
    try {
      setIsLoading(true);
      await userService.unblockUser(userId);
      toast('Feito', 'O usuário já foi desbloqueado.', 'success');
      loadUser();
    } catch (error) {
      dispatchToast({
        text: getMessageError({
          error,
          messageDefault: 'Falha ao desbloquear usuário.',
          messageErrorByMessageErrorRequest: TOTP_RESET_ERRORS,
          pathMessageRequest: 'response.data.message',
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return {
    isLoading,
    user,
    loadUser,
    deleteUser,
    reactivateUser,
    unblockUser,
  };
};

export default useUser;
