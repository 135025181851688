import { Switcher } from '@/modules/company';
import { FeedbackAction } from '@/modules/feedback';
import { LoggedUser } from '@/modules/profile';
import { SupportActions } from '@/modules/support';
import React from 'react';
import { SwitchTheme } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

const Toolbar: React.FC = () => {
  const styleWithBorder = {
    borderRight: ' 1px solid rgba(0, 123, 255, .2)',
    minWidth: '40px',
    padding: '0 16px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  };

  const styleWithoutBorder = { minWidth: '40px', paddingLeft: '16px' };

  return (
    <Row style={{ width: '100%', padding: '16px 0' }}>
      <Grid alignContent='justify' alignItems='center'>
        <Switcher />

        <Grid alignItems='center'>
          <div style={styleWithBorder} data-testid='feedback-action'>
            <FeedbackAction />
          </div>
          <div
            data-testid='guides-action'
            style={{
              ...styleWithBorder,
              ...{ padding: '0 8px 0 0' },
            }}>
            <SupportActions />
          </div>
          <div data-testid='theme-mode-action' style={styleWithBorder}>
            <SwitchTheme />
          </div>
          <div data-testid='logged-user-action' style={styleWithoutBorder}>
            <LoggedUser />
          </div>
        </Grid>
      </Grid>
    </Row>
  );
};

export default Toolbar;
