import {
  AttendanceFilter,
  AttendanceParams,
  AttendanceResponseNormalized,
  AttendanceTable,
} from '@/modules/attendance/entities';
import { AttendanceService } from '@/modules/attendance/services';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useMemo, useState } from 'react';

export interface UseAttendances {
  filters: AttendanceFilter;
}

const attendanceService = new AttendanceService();

const useAttendances = ({ filters }: UseAttendances) => {
  const { company } = useCompany();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [attendances, setAttendances] = useState<AttendanceTable[]>([]);
  const [totalAttendances, setTotalAttendances] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const isEmpty = useMemo(() => !attendances.length && !isLoading, [attendances.length, isLoading]);
  const hasPagination = useMemo(() => totalPages > 1, [totalPages]);

  const loadAttendances = useCallback(
    async (companyCNPJ: string) => {
      if (!companyCNPJ || !company.id) {
        return;
      }

      setLoading(true);

      const params: AttendanceParams = {
        ...filters,
        companyId: company.id,
      };

      try {
        const { data, totalPages, total } =
          await attendanceService.getAttendances<AttendanceResponseNormalized>(companyCNPJ, params);
        setAttendances(data);
        setTotalPages(totalPages || 1);
        setTotalAttendances(total || 1);
      } catch (e) {
        console.error(e);

        dispatchToast({
          text: 'Ops! Devido a um erro, não conseguimos buscar os atendimentos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [company.id, filters],
  );

  return {
    attendances,
    isLoading,
    isEmpty,
    loadAttendances,
    totalAttendances,
    hasPagination,
    totalPages,
  };
};

export default useAttendances;
