import { TextData } from '@/components';
import { useContact } from '@/modules/company/hooks';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import React, { useEffect } from 'react';

const Contact: React.FC = () => {
  const { company } = useCompany();
  const { isLoading, contact, loadContact } = useContact();

  useEffect(() => {
    if (!company.id) {
      return;
    }
    loadContact(company.id);
  }, [company.id, loadContact]);

  return (
    <TextData
      loading={isLoading}
      data={[
        {
          label: 'Nome da(o) responsável',
          text: contact?.name || '-',
          type: 'date',
          sizeDefault: 35,
          sizeMedium: 100,
        },
        {
          label: 'E-mail da(o) responsável empresa',
          text: contact?.email || '-',
          sizeDefault: 35,
          sizeMedium: 100,
        },
        {
          label: 'Telefone da(o) responsável',
          text: contact?.phone || '-',
          type: 'mobileNumber',
          sizeDefault: 30,
          sizeMedium: 100,
        },
      ]}
    />
  );
};

export default Contact;
