import useCompany from '@/shared/hooks/useCompany/useCompany';
import React from 'react';

import ICONS from '@/shareds/constants/icons';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import {
  CADASTRAL_POSITION_COLUMNS,
  CADASTRAL_POSITION_LIST_ACTIONS,
  CADASTRAL_POSITION_LIST_FILTERS,
} from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import CadastralPositionFilters from '@/modules/cadastralPosition/views/cadastralPositionFilters/cadastralPositionFilters';
import CadastralPositionTable from '@/modules/cadastralPosition/views/cadastralPositionTable/cadastralPositionTable';
import { Grid } from 'vkit/lib/context';
import { Button } from 'vkit/lib/components';
import { useHistoryNavigator } from '@/navigation';

const List: React.FC = () => {
  const navigate = useHistoryNavigator();
  const { company } = useCompany();
  const [filters, setFilters] = useQueryStringState<ParamsToFilter>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    companyId: [company.id],
  });

  return (
    <>
      <CadastralPositionFilters
        fields={[
          {
            type: CADASTRAL_POSITION_LIST_FILTERS.SEARCH,
            size: 25,
          },
          {
            type: CADASTRAL_POSITION_LIST_FILTERS.STATUS,
            size: 25,
          },
          {
            type: CADASTRAL_POSITION_LIST_FILTERS.CREATED_AT_MIN,
            size: 25,
          },
          {
            type: CADASTRAL_POSITION_LIST_FILTERS.CREATED_AT_MAX,
            size: 25,
          },
          {
            type: CADASTRAL_POSITION_LIST_FILTERS.COMPANIES,
            size: 100,
          },
        ]}
        setFilters={setFilters}
        filters={filters}
      />

      <Grid alignContent='right' stretch>
        <Button
          label='Enviar arquivo'
          iconColor='light'
          icon={ICONS.UPLOAD}
          onClick={() => navigate.push('/cadastral-positions/actions/upload')}
        />
      </Grid>

      <CadastralPositionTable
        columns={[
          CADASTRAL_POSITION_COLUMNS.FILE_NAME,
          CADASTRAL_POSITION_COLUMNS.COMPANY_NAME,
          CADASTRAL_POSITION_COLUMNS.STATUS,
          CADASTRAL_POSITION_COLUMNS.CREATED_AT,
        ]}
        actions={[CADASTRAL_POSITION_LIST_ACTIONS.DETAILS]}
        filters={filters}
        handleFilters={setFilters}
      />
    </>
  );
};

export default List;
