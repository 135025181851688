import restfulBase from '@/services/negotiations/core/base';
import { Benefit, benefitInitalState } from '@/services/negotiations/endpoints/benefits';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';
import { Provider, providerInitalState } from '@/services/negotiations/endpoints/providers';
import { Base } from '@/shareds/types/base.type';

interface EntityWithName extends Base {
  name: string;
}

export interface Product extends Base {
  name: string;
  baseId: number;
  ansCode?: string;
  refund?: number;
  accommodation?: EntityWithName;
  coverage?: EntityWithName;
  scope?: EntityWithName;
  benefitId: string;
  providerId: string;
  benefit: Benefit;
  provider: Provider;
  negotiations: Negotiation[];
  providerInternalCode?: string;
}

export const productInitialState: Product = {
  name: '',
  baseId: 0,
  benefitId: '',
  providerId: '',
  benefit: benefitInitalState,
  provider: providerInitalState,
  negotiations: [],
};

const apiService = restfulBase('products');

export default apiService;
