import ChipTranslate from '@/components/chipTranslate';
import { PERSISTENCE_STATUS } from '@/modules/financialAdjustment/components/persistenceStatus/entities/persistenceStatus.enum';
import PersistenceStatus from '@/modules/financialAdjustment/components/persistenceStatus/persistenceStatus';
import { useMemo } from 'react';

import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';

import { getCurrencyFormat, getFormattedDate } from '@/utils/format';

import {
  FINANCIAL_ADJUSTMENT_ACTIONS,
  FINANCIAL_ADJUSTMENT_COLUMNS,
} from '@/modules/financialAdjustment/entities/financialAdjustment.enum';
import { FinancialAdjustmentToTable } from '@/modules/financialAdjustment/entities/financialAdjustment.interface';

interface UseFinancialAdjustmentColumns {
  columns?: FINANCIAL_ADJUSTMENT_COLUMNS[];
  persistenceStatusByFinancialAdjustmentKey?: Record<string, PERSISTENCE_STATUS>;
  onRemove?: (financialAdjustment: FinancialAdjustmentToTable) => void;
  actions?: FINANCIAL_ADJUSTMENT_ACTIONS[];
}

const useFinancialAdjustmentTableColumns = ({
  columns,
  persistenceStatusByFinancialAdjustmentKey,
}: UseFinancialAdjustmentColumns) => {
  const AVALIBLE_COLUMNS: Record<FINANCIAL_ADJUSTMENT_COLUMNS, DataTableColumn> = useMemo(
    () => ({
      [FINANCIAL_ADJUSTMENT_COLUMNS.DATE]: {
        name: 'DATA',
        path: 'date',
        minWidth: '180px',
        format: ({ date }: FinancialAdjustmentToTable) => (date ? getFormattedDate(date) : null),
      },
      [FINANCIAL_ADJUSTMENT_COLUMNS.VALUE]: {
        name: 'VALOR',
        path: 'valor',
        format: ({ value, unit }: FinancialAdjustmentToTable) => (
          <ChipTranslate
            term={unit}
            translate={{
              percentage: {
                text: `${value}%`,
                color: 'light',
              },
              currency: {
                text: getCurrencyFormat(value, 'BRL'),
                color: 'light',
              },
            }}
          />
        ),
      },
      [FINANCIAL_ADJUSTMENT_COLUMNS.CONTRACT]: {
        name: 'CONTRATO',
        path: 'contract.code',
      },
      [FINANCIAL_ADJUSTMENT_COLUMNS.DESCRIPTION]: {
        name: 'DESCRIÇÃO',
        path: 'description',
      },
      [FINANCIAL_ADJUSTMENT_COLUMNS.STATUS]: {
        name: 'STATUS',
        path: 'status',
        format: ({ key }: FinancialAdjustmentToTable) =>
          persistenceStatusByFinancialAdjustmentKey && key ? (
            <PersistenceStatus status={persistenceStatusByFinancialAdjustmentKey?.[key]} />
          ) : (
            '-'
          ),
      },
    }),
    [persistenceStatusByFinancialAdjustmentKey],
  );

  const useColumns = useMemo(() => {
    if (!columns) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columns.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columns, AVALIBLE_COLUMNS]);

  return {
    useColumns,
  };
};

export default useFinancialAdjustmentTableColumns;
