import { MoveSheetColumnKey } from '../types/moveSheetColumn';

export enum MOVE_SHEET_COLUMN {
  tipoMovimentacao = 'tipoMovimentacao',
  titularidade = 'titularidade',
  contrato = 'contrato',
  subfatura = 'subfatura',
  matriculaEspecial = 'matriculaEspecial',
  cpf = 'cpf',
  cpfTitular = 'cpfTitular',
  nome = 'nome',
  dataNascimento = 'dataNascimento',
  sexo = 'sexo',
  estadoCivil = 'estadoCivil',
  nomeMae = 'nomeMae',
  cpt = 'cpt',
  dataInicioVigencia = 'dataInicioVigencia',
  numeroDependentes = 'numeroDependentes',
  matriculaFuncional = 'matriculaFuncional',
  dataAdmissao = 'dataAdmissao',
  cep = 'cep',
  uf = 'uf',
  logradouro = 'logradouro',
  bairro = 'bairro',
  cidade = 'cidade',
  plano = 'plano',
  nomePlano = 'nomePlano',
  tipoContaBancaria = 'tipoContaBancaria',
  creditoBanco = 'creditoBanco',
  creditoNumAgencia = 'creditoNumAgencia',
  creditoDVAgencia = 'creditoDVAgencia',
  creditoNumConta = 'creditoNumConta',
  creditoDVConta = 'creditoDVConta',
  codAngariador = 'codAngariador',
  dataAngariacao = 'dataAngariacao',
  emailSegurado = 'emailSegurado',
  grauParentesco = 'grauParentesco',
  dataCasamento = 'dataCasamento',
  codElegibilidade = 'codElegibilidade',
  motivoDelecao = 'motivoDelecao',
  certificado = 'certificado',
  dataCancelamento = 'dataCancelamento',
  opcaoPelaRN279 = 'opcaoPelaRN279',
  codDependente = 'codDependente',
  vinculoComEmpresa = 'vinculoComEmpresa',
  cns = 'cns',
  lotacao = 'lotacao',
  codigoCarencia = 'codigoCarencia',
}

export const MOVE_SHEET_COLUMN_LABEL: { [key in MoveSheetColumnKey]: string } = Object.freeze({
  [MOVE_SHEET_COLUMN.tipoMovimentacao]: 'Tipo de Movimentação',
  [MOVE_SHEET_COLUMN.titularidade]: 'Titularidade',
  [MOVE_SHEET_COLUMN.contrato]: 'Contrato',
  [MOVE_SHEET_COLUMN.subfatura]: 'Subfatura',
  [MOVE_SHEET_COLUMN.matriculaEspecial]: 'Matrícula Especial',
  [MOVE_SHEET_COLUMN.cpf]: 'CPF',
  [MOVE_SHEET_COLUMN.cpfTitular]: 'CPF do Titular',
  [MOVE_SHEET_COLUMN.nome]: 'Nome',
  [MOVE_SHEET_COLUMN.dataNascimento]: 'Data de Nascimento',
  [MOVE_SHEET_COLUMN.sexo]: 'Sexo',
  [MOVE_SHEET_COLUMN.estadoCivil]: 'Estado Civil',
  [MOVE_SHEET_COLUMN.nomeMae]: 'Nome da Mãe',
  [MOVE_SHEET_COLUMN.cpt]: 'CPT',
  [MOVE_SHEET_COLUMN.dataInicioVigencia]: 'Data de Início da Vigência',
  [MOVE_SHEET_COLUMN.numeroDependentes]: 'Número de Dependentes',
  [MOVE_SHEET_COLUMN.matriculaFuncional]: 'Matrícula Funcional',
  [MOVE_SHEET_COLUMN.dataAdmissao]: 'Data de Admissão',
  [MOVE_SHEET_COLUMN.cep]: 'CEP',
  [MOVE_SHEET_COLUMN.uf]: 'UF',
  [MOVE_SHEET_COLUMN.logradouro]: 'Logradouro',
  [MOVE_SHEET_COLUMN.bairro]: 'Bairro',
  [MOVE_SHEET_COLUMN.cidade]: 'Cidade',
  [MOVE_SHEET_COLUMN.plano]: 'Plano',
  [MOVE_SHEET_COLUMN.nomePlano]: 'Nome do plano',
  [MOVE_SHEET_COLUMN.tipoContaBancaria]: 'Tipo de Conta Bancária',
  [MOVE_SHEET_COLUMN.creditoBanco]: 'Banco de Crédito',
  [MOVE_SHEET_COLUMN.creditoNumAgencia]: 'Número da Agência de Crédito',
  [MOVE_SHEET_COLUMN.creditoDVAgencia]: 'Dígito Verificador da Agência de Crédito',
  [MOVE_SHEET_COLUMN.creditoNumConta]: 'Número da Conta de Crédito',
  [MOVE_SHEET_COLUMN.creditoDVConta]: 'Dígito Verificador da Conta de Crédito',
  [MOVE_SHEET_COLUMN.codAngariador]: 'Código do Angariador',
  [MOVE_SHEET_COLUMN.dataAngariacao]: 'Data de Angariação',
  [MOVE_SHEET_COLUMN.emailSegurado]: 'E-mail do Segurado',
  [MOVE_SHEET_COLUMN.grauParentesco]: 'Grau de parentesco',
  [MOVE_SHEET_COLUMN.dataCasamento]: 'Data de casamento',
  [MOVE_SHEET_COLUMN.codElegibilidade]: 'Código de elegibilidade',
  [MOVE_SHEET_COLUMN.motivoDelecao]: 'Motivo da deleção',
  [MOVE_SHEET_COLUMN.certificado]: 'Certificado',
  [MOVE_SHEET_COLUMN.dataCancelamento]: 'Data do cancelamento',
  [MOVE_SHEET_COLUMN.opcaoPelaRN279]: 'Opção pela RN279',
  [MOVE_SHEET_COLUMN.codDependente]: 'Código do dependente',
  [MOVE_SHEET_COLUMN.vinculoComEmpresa]: 'Vínculo com a empresa',
  [MOVE_SHEET_COLUMN.cns]: 'CNS',
  [MOVE_SHEET_COLUMN.lotacao]: 'Lotação',
  [MOVE_SHEET_COLUMN.codigoCarencia]: 'Código de carência',
});
