import { Permission, User } from '@/modules/profile';
import HttpClientAdapter from '@/shared/services/httpClientAdapter';
import { UserPayload, UserResponse, UserSearchParams } from '../entities';

class UserService extends HttpClientAdapter {
  constructor() {
    super('AUTH');
  }

  create<T = Partial<UserPayload>>(data: Partial<UserPayload>) {
    const url = '/users';
    return this.post<T>(url, data);
  }

  update(userId: string, data: Partial<UserPayload>) {
    const url = `/users/${userId}`;
    return this.patch<Partial<UserPayload>>(url, data);
  }

  getUsers<T = UserResponse>(filters?: Partial<UserSearchParams>) {
    const url = '/users';
    return this.get<T>(url, filters);
  }

  getUser<T = any>(userId: string, filters?: Partial<UserSearchParams>) {
    const url = `/users/${userId}`;
    return this.get<T>(url, filters);
  }

  getDeletedUser<T = any>(userId: string, filters?: Partial<UserSearchParams>) {
    const url = `/deleted-users/${userId}`;
    return this.get<T>(url, filters);
  }

  remove(userId: string) {
    return this.delete<Promise<void>>(`/users/${userId}`);
  }

  reactive(userId: string) {
    return this.delete<Promise<void>>(`/deleted-users/${userId}`);
  }

  unblockUser(userId: string) {
    return this.post<Promise<void>>(`/auth/unblock-user/${userId}`);
  }

  getUserPermissions(userId: string) {
    return this.get<Permission[]>(`/users/${userId}/permissions`);
  }

  getMe() {
    return this.get<User>('/auth/me');
  }
}

export default UserService;
