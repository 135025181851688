export enum COMPANY_COLUMNS {
  NAME = 'name',
  SUPPORT_NAME = 'support-name',
  CNPJ = 'cnpj',
  CREATED_AT = 'created-at',
}

export enum COMPANY_CONFIG {
  CADASTRAL_DATA_MATCHING_LAYOUT = 'cadastral-data-matching:layout',
  MOVES_LAYOUT = 'moves:layout',
  EXTERNAL_LINK_INSIGHTS = 'external_link:insights',
}

export enum COMPANY_CONFIG_VALUE {
  CADASTRAL_DATA_MATCHING_LAYOUT_DEFAULT = 'cadastral-data-matching:default',
}

export enum COMPANY_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}
