import { Translate } from '@/shared/entities/types/translate';
import React from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface ChipSubstitleProps {
  term: string;
  translate: Translate;
}

const ChipTranslate: React.FC<ChipSubstitleProps> = ({ term, translate = {} }) => (
  <Grid>
    <Chip rounded size='small' label={translate[term]?.text} color={translate[term]?.color} />
  </Grid>
);

export default ChipTranslate;
