import {
  COMPANY_GROUP_COLUMNS,
  COMPANY_GROUP_LIST_ACTIONS,
  COMPANY_GROUP_LIST_FILTERS,
} from '@/modules/companyGroup/entities/companyGroup.enum';
import { ParamsToFilterCompanyGroups } from '@/modules/companyGroup/entities/companyGroup.interface';
import CompanyGroupFilters from '@/modules/companyGroup/views/companyGroupFilters/companyGroupFilters';
import CompanyGroupTable from '@/modules/companyGroup/views/companyGroupTable/companyGroupTable';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import React from 'react';

const List: React.FC = () => {
  const [filters, setFilters] = useQueryStringState<ParamsToFilterCompanyGroups>({
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    q: '',
  });

  return (
    <>
      <CompanyGroupFilters
        fields={[
          {
            type: COMPANY_GROUP_LIST_FILTERS.SEARCH,
            size: 100,
          },
        ]}
        setFilters={setFilters}
        filters={filters}
      />

      <CompanyGroupTable
        columns={[COMPANY_GROUP_COLUMNS.NAME, COMPANY_GROUP_COLUMNS.CREATED_AT]}
        actions={[COMPANY_GROUP_LIST_ACTIONS.DETAILS]}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};

export default List;
