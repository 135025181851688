import { useCallback, ReactNode, useRef } from 'react';
import { Toast } from 'vkit/lib/components';

type ColorType = 'danger' | 'info' | 'success' | 'warning' | 'colorDefault';
type StatusType = 'info' | 'success' | 'warning' | 'error' | 'waiting';

type OptionsType = {
  [key: string]: {
    icon: string;
    color?: ColorType;
  };
};

const useToastStatus = (): {
  toast?: ReactNode;
  useToast: Function;
} => {
  const { toast, open: openToast } = Toast();
  const optionsRef = useRef<OptionsType>({
    info: {
      icon: 'info-outline',
      color: 'info',
    },
    success: {
      icon: 'checkmark-circle-2-outline',
      color: 'success',
    },
    warning: {
      icon: 'alert-triangle-outline',
      color: 'warning',
    },
    error: {
      icon: 'close-circle-outline',
      color: 'danger',
    },
    waiting: {
      icon: 'clock-outline',
      color: 'colorDefault',
    },
  });

  const useToast = useCallback(
    (title: string, description: string, status: StatusType, duration?: number): void => {
      const options = optionsRef.current[status];

      openToast({
        icon: options.icon,
        title: title || 'Atenção',
        text: description,
        type: options.color,
        align: 'right',
        titleColor: options.color,
        position: 'bottom',
        shadow: true,
        duration: duration || 10,
      });
    },
    [openToast],
  );

  return {
    toast,
    useToast,
  };
};

export default useToastStatus;
