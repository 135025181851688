import { Colors } from '@/shareds/types';
import React from 'react';
import { Chip, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface GroupItemHeaderProps {
  groupTitle?: string;
  showGroupMarker?: boolean;
  groupMarkerColor?: Colors;
  groupMarkerText?: string;
}

export const GroupItemHeader: React.FC<GroupItemHeaderProps> = ({
  groupTitle,
  showGroupMarker,
  groupMarkerColor,
  groupMarkerText,
}) => {
  return (
    <Grid gap={24} alignItems='center'>
      <Grid gap={8}>
        <Text fontWeight='bold' value={groupTitle || ''} size='medium' />
      </Grid>
      {showGroupMarker && (
        <Grid gap={8}>
          <Chip size='small' rounded color={groupMarkerColor} label={groupMarkerText} />
        </Grid>
      )}
    </Grid>
  );
};
