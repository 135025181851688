export enum FIELD_NAMES {
  PROVIDER = 'provider',
  BENEFIT = 'benefit',
  STATUS = 'status',
}

export enum CONTRACT_STATUS {
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
}

export enum SELECT_CONTRACT_COLUMN {
  CODE = 'code',
  PROVIDER = 'provider',
  BENEFIT = 'benefit',
  STATUS = 'status',
  VALID_UNTIL = 'validUntil',
  COST_CENTER = 'costCenter',
}

export enum SELECT_CONTRACT_FILTER {
  STATUS_ACTIVE = 'statusActive',
}
