import React from 'react';
import { CustomList } from '@/components';
import { ObjectType } from '@/shareds/types';
import useContentList, { Column } from '../../hooks/useContentList';

const innerHeight = window.innerWidth > 768 ? 60 : 80;

type ContentListType = {
  data: ObjectType[] | null;
  blur: boolean;
  columns: Column[];
  linesLoader?: string | number;
};

const ContentList: React.FC<ContentListType> = ({ data, blur, columns, linesLoader }) => {
  const { useDataList, useListRef } = useContentList(columns);

  return (
    <CustomList
      blur={blur}
      listRef={useListRef}
      linesLoader={linesLoader}
      data={data}
      innerHeight={innerHeight}
      dataList={useDataList}
    />
  );
};

export default ContentList;
