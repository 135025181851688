import { useAttendance } from '@/modules/attendance/hooks';
import { useHistoryParams } from '@/navigation';
import React from 'react';

import { Information, InformationAttachments, InformationContracts } from '../../components';

const Detail: React.FC = () => {
  const { attendanceId } = useHistoryParams();
  const { isLoading, attendance } = useAttendance({ attendanceId });

  return (
    <>
      <Information attendance={attendance} />

      <InformationContracts isLoading={isLoading} contracts={attendance.contracts} />

      <InformationAttachments isLoading={isLoading} attachments={attendance.attachments} />
    </>
  );
};

export default Detail;
