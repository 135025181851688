import React, { useMemo } from 'react';
import { Select } from 'vkit/lib/components';

import { BENEFICIARY_STATUS_ALIAS_TRANSLATED } from '../../entities';

interface SelectStatusProps {
  onChange: (name: string, value: string) => void;
  value: string;
}

const SelectStatus: React.FC<SelectStatusProps> = ({ onChange, value }) => {
  const selectableStatus = useMemo(() => {
    return Object.entries(BENEFICIARY_STATUS_ALIAS_TRANSLATED).map(([key, val]) => ({
      text: `${val}s`,
      value: key,
    }));
  }, []);

  return (
    <Select
      data={selectableStatus}
      label='Status'
      value={value}
      onChange={onChange}
      small
      clearable
    />
  );
};

export default SelectStatus;
