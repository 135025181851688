import React from 'react';

import { DataTable } from 'vkit/lib/components';

import {
  COMPANY_GROUP_COLUMNS,
  COMPANY_GROUP_LIST_ACTIONS,
} from '@/modules/companyGroup/entities/companyGroup.enum';
import { ParamsToFilterCompanyGroups } from '@/modules/companyGroup/entities/companyGroup.interface';
import useCompanyGroupTable from '@/modules/companyGroup/hooks/useCompanyGroupTable';

interface TableProps {
  actions?: COMPANY_GROUP_LIST_ACTIONS[];
  columns?: COMPANY_GROUP_COLUMNS[];
  linesLoader?: number;
  filters: ParamsToFilterCompanyGroups;
  setFilters: (params: ParamsToFilterCompanyGroups) => void;
}

const CompanyGroupTable: React.FC<TableProps> = ({
  actions,
  columns,
  filters,
  setFilters,
  linesLoader = 5,
}) => {
  const {
    loading,
    companyGroups,
    showPagination,
    pagination,
    handleChangePageSize,
    handlePageChange,
    getActions,
    useColumns,
  } = useCompanyGroupTable({
    actions,
    columns,
    setFilters,
    filters,
  });

  return (
    <DataTable
      action={actions && actions.length > 0 ? { items: getActions } : {}}
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={companyGroups}
      isLoading={loading}
      showPagination={showPagination && !loading}
      total={pagination.total}
      totalPages={pagination.totalPages}
      page={pagination.page}
      pageSize={filters?.pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handleChangePageSize}
    />
  );
};

export default CompanyGroupTable;
