export enum Scopes {
  BROKER = 'broker',
  COMPANY = 'company',
}

export interface Permission {
  id: string;
  name: string;
  type: Scopes;
  createdAt: string | null;
  deletedAt: string | null;
  updatedAt: string | null;
  scoped: boolean | null;
  description?: string | null;
}

export interface UserPermission extends Omit<Permission, 'name' | 'type' | 'description'> {
  createdBy: string | null;
  deletedBy: string | null;
  updatedBy: string | null;
  userId: string;
  permissionId: string;
  scope: null;
}

export interface UserPermissionPayload {
  permissionId: string;
  scope?: string;
}
