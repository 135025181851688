import HttpClientAdapter from '@/shared/services/httpClientAdapter';

export class ContractService extends HttpClientAdapter {
  constructor() {
    super('NEGOTIATION');
  }

  getContracts<T = any>(companyId: string, params: any) {
    const url = `/companies/${companyId}/contracts`;
    return this.get<T>(url, params);
  }
}
