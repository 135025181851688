import { Beneficiary, BENEFICIARY_TABLE_ACTION } from '@/modules/beneficiary/entities';
import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import React from 'react';
import { ActionsLayer, Button } from 'vkit/lib/components';

interface UseBeneficiariesActionsProps {
  actions?: BENEFICIARY_TABLE_ACTION[];
}

export const useBeneficiariesTableActions = ({ actions }: UseBeneficiariesActionsProps) => {
  const { pathname } = useHistoryLocation();
  const navigate = useHistoryNavigator();

  const getActions = (beneficiary: Beneficiary) => {
    const AVALIBLE_ACTIONS = {
      [BENEFICIARY_TABLE_ACTION.DETAIL]: {
        size: 'medium',
        label: 'Ver detalhes',
        onClick: () => navigate.push(`${pathname}/${beneficiary.id}/details`),
      },
    };

    const listActions = actions
      ? actions.map((action) => AVALIBLE_ACTIONS[action])
      : Object.values(AVALIBLE_ACTIONS);

    if (listActions.length === 1) {
      return [
        <Button
          key={beneficiary.id}
          solo
          outlined
          label={listActions[0].label}
          onClick={listActions[0].onClick}
          color='colorDefault'
          iconColor='colorDefault'
        />,
      ];
    }

    return [<ActionsLayer key={beneficiary.id} vertical actions={listActions} />];
  };

  return {
    getActions,
  };
};

export default useBeneficiariesTableActions;
