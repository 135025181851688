import ToastStatusGlobal from '@/components/toastStatusGlobal';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
import useLoadCompany from '@/modules/company/hooks/useLoadCompany/useLoadCompany';
import useCurrentUser from '@/modules/profile/hooks/useCurrentUser/useCurrentUser';
import { useHistoryParams } from '@/navigation';
import { useCallback, useEffect, useRef } from 'react';

export const useCompanyWhenLoadPage = () => {
  const routeParamsRef = useRef(useHistoryParams());
  const { loadCompany } = useLoadCompany();
  const { propagateCompany } = useCompanyPropagate();
  const { isCompanyUser, userCompanies } = useCurrentUser();
  const { toast } = ToastStatusGlobal();

  const loadAndPropagateCompany = useCallback(
    async (companyIdToLoad) => {
      if (!companyIdToLoad) {
        return;
      }

      try {
        const loadedCompany = isCompanyUser
          ? userCompanies?.find((company) => company.id === companyIdToLoad)
          : await loadCompany(companyIdToLoad);

        if (!loadedCompany) {
          return;
        }

        propagateCompany(loadedCompany);
      } catch (e) {
        console.warn(e);
        toast('Oops', 'Falha no carregar empresa.', 'error');
      }
    },
    [isCompanyUser, loadCompany, propagateCompany, toast, userCompanies],
  );

  useEffect(() => {
    if (!routeParamsRef.current.companyId) {
      return;
    }
    loadAndPropagateCompany(routeParamsRef.current.companyId);
  }, [loadAndPropagateCompany]);
};
