import AuthLogin from '@/components/forms/auth/login';
import toastStatusGlobal from '@/components/toastStatusGlobal';
import { INITIAL_ROUTE } from '@/core/entities/types/routes.const';
import { decodedJwtToken } from '@/utils/authentication';
import { dispatchEvent } from '@/utils/dispatchEvent/dispatchEvent';
import React, { useEffect, useMemo } from 'react';
import { Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export const ModalLogin: React.FC = () => {
  const { toast } = toastStatusGlobal();
  const useRefDialog = React.useRef({ open: () => ({}), dismiss: () => ({}) });
  const decodedOldToken = useMemo(() => decodedJwtToken(), [])

  const treatAfterClosingModal = () => {
    dispatchEvent('can-open-toast', { canOpen: true });
  }

  useEffect(() => {
    const handler = () => {
      useRefDialog.current.open();
      dispatchEvent('can-open-toast', { canOpen: false });
    };
    window.addEventListener('modal-login', handler);

    return () => {
      window.removeEventListener('modal-login', handler);
    };
  }, []);

  return (
    <Dialog
      elevation={4}
      instanceEmitter={useRefDialog.current}
      outlined
      rounded
      persistent
      onClose={treatAfterClosingModal}
      size={450}
      head={<Text size='larger' textAlign='center' title value='Login' />}
    >
      <Grid padding={16}>
        <AuthLogin
          description={'A sessão foi encerrada. Para continuar, faça login novamente.'}
          onLogin={() => {
            const decodedNewToken = decodedJwtToken();
            if (decodedNewToken.user.id !== decodedOldToken.user.id) {
              window.location.replace(INITIAL_ROUTE);
            }

            useRefDialog.current.dismiss();
            toast('Sucesso', 'Login efetuado com sucesso!', 'success');
          }}
        />
      </Grid>
    </Dialog>
  );
};
