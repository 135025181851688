import ICONS from '@/shareds/constants/icons';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { removeEmptyParams } from '@/utils';
import { TextFieldDebounce } from '@/components';
import { ParamsToFilterCompanyGroups } from '@/modules/companyGroup/entities/companyGroup.interface';
import { COMPANY_GROUP_LIST_FILTERS } from '@/modules/companyGroup/entities/companyGroup.enum';

interface UseCompanyGroupFilters {
  setFilters: Dispatch<SetStateAction<ParamsToFilterCompanyGroups>>;
  filters: ParamsToFilterCompanyGroups;
}

const useCompanyGroupFilters = ({ setFilters, filters }: UseCompanyGroupFilters) => {
  const onChange = useCallback(
    (key: string, value: string) => {
      setFilters((oldValues) =>
        removeEmptyParams<ParamsToFilterCompanyGroups>({
          ...oldValues,
          [key]: value,
          page: 1,
        }),
      );
    },
    [setFilters],
  );

  const fieldsToChoose = {
    [COMPANY_GROUP_LIST_FILTERS.SEARCH]: {
      component: (
        <TextFieldDebounce
          clearable
          small
          iconDir='right'
          icon={ICONS.SEARCH}
          label='Buscar'
          value={filters.q}
          onChange={(value: string) => onChange('q', value)}
        />
      ),
    },
  };

  return {
    fieldsToChoose,
  };
};

export default useCompanyGroupFilters;
