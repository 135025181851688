import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Contract } from '@/modules/contract/entities/contract';
import Infos from '@/modules/contract/views/infos/infos';
import Plans from '@/modules/contract/views/plans/plans';
import Rules from '@/modules/contract/views/rules/rules';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import { contractsService, negotiationsService } from '@/services/negotiations';
import { contractInitialState } from '@/services/negotiations/endpoints/contracts';
import { Negotiation } from '@/services/negotiations/endpoints/negotiations';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';

const Details: React.FC = () => {
  const navigate = useHistoryNavigator();
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<Contract>(contractInitialState);
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);
  const { contractId } = useHistoryParams();
  const { company } = useCompany();
  const [currentCompanyId] = useState(company.id);

  const ruleTypesByBenefit = useMemo(
    () => JSON.parse(process.env.REACT_APP_RULE_TYPES || '{}'),
    [],
  );

  const baseId = useMemo(() => {
    const negotiation = negotiations.find((item) => {
      return Object.keys(ruleTypesByBenefit).some(
        (key) => String(key) === String(get(item, 'product.benefit.baseId', '')),
      );
    });
    return get(negotiation, 'product.benefit.baseId', '');
  }, [negotiations, ruleTypesByBenefit]);

  const plans = useMemo(() => {
    return negotiations.map((negotiation) => ({
      ...negotiation.product,
      negotiation,
    }));
  }, [negotiations]);

  const loadNegotiation = useCallback(async () => {
    return tryOrCatchMessageError(
      async () => {
        if (!company.id) {
          return;
        }

        const negotiationsFound =
          (await negotiationsService(company.id).list({ contractId })) || [];

        setNegotiations(negotiationsFound);
      },
      {
        messageErrorDefault: 'Erro ao carregar negociação.',
        tryBefore: () => setLoading(true),
        tryAfter: () => setLoading(false),
      },
    );
  }, [company.id, contractId]);

  const loadContract = useCallback(async () => {
    return tryOrCatchMessageError(
      async () => {
        if (!company.id) {
          return;
        }

        const contractFound = (await contractsService(company.id).find(contractId)) || [];

        setContract(contractFound);
      },
      {
        messageErrorDefault: 'Erro ao carregar contrato.',
        tryBefore: () => setLoading(true),
        tryAfter: () => setLoading(false),
      },
    );
  }, [company.id, contractId]);

  useEffect(() => {
    loadContract();
    loadNegotiation();
  }, [loadContract, loadNegotiation]);

  useEffect(() => {
    if (company.id && currentCompanyId && company.id !== currentCompanyId) {
      navigate.push(`/companies/${company.id}/contracts`);
    }
  }, [company.id, currentCompanyId, navigate]);

  return (
    <>
      <Infos
        loading={loading}
        contract={contract}
        items={[
          { key: 'provider', size: 100 },
          { key: 'code', size: 25 },
          { key: 'contribution', size: 25 },
          { key: 'copay', size: 25 },
          { key: 'status', size: 25 },
          { key: 'validFrom', size: 25 },
          { key: 'validUntil', size: 25 },
          { key: 'modalName', size: 25 },
          { key: 'reimbursementCoefficient', size: 25 },
        ]}
      />

      {ruleTypesByBenefit[baseId] ? (
        <Rules
          contractId={contractId}
          items={contract.rules || []}
          ruleTypes={ruleTypesByBenefit[baseId] || []}
          baseId={String(baseId)}
        />
      ) : null}

      <Plans data={plans} />
    </>
  );
};

export default Details;
