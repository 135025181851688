import { Colors, ObjectType } from '@/shareds/types';
import { getMessageError } from '@/utils/getMessageError';
import ToastComplementaryType from 'vkit/lib/components/toast/types/toast.type';

let isToastShowing = false;
export interface MessageErrorOptions {
  messageErrorDefault?: string;
  messageErrorByMessageErrorRequest?: ObjectType;
  pathMessageRequest?: string;
  tryBefore?: () => void;
  tryAfter?: () => void;
}

export interface DispatchToast {
  icon?: string;
  title?: string;
  text: string;
  showJustOne?:boolean;
  type?: Colors;
  align?: 'right';
  titleColor?: Colors;
  position?: 'bottom';
  shadow?: true;
  duration?: number;
  complementary?: ToastComplementaryType;
}

export const dispatchToast = ({
  icon = 'close-circle-outline',
  title = 'Oops',
  showJustOne = false,
  text,
  type = 'danger',
  align = 'right',
  titleColor = 'danger',
  position = 'bottom',
  shadow = true,
  duration = 10,
  complementary,
}: DispatchToast): void => {
  if ( !showJustOne || !isToastShowing) {
    isToastShowing = true;
    const toastEvent = new CustomEvent('toast', {
      detail: {
        icon,
        title,
        text,
        showJustOne,
        type,
        align,
        titleColor,
        position,
        shadow,
        duration,
        complementary,
      },
    });

    window.dispatchEvent(toastEvent);
    setTimeout(() => {
      isToastShowing = false;
    }, duration * 1000);
  }

};

export const tryOrCatchMessageError = async (
  tryFunction: Function,
  options: MessageErrorOptions,
) => {
  try {
    options.tryBefore?.();
    return await tryFunction();
  } catch (error) {
    console.error(error);

    const messageError = getMessageError({
      error,
      messageDefault: options.messageErrorDefault,
      messageErrorByMessageErrorRequest: options.messageErrorByMessageErrorRequest,
      pathMessageRequest: options.pathMessageRequest ?? 'response.data.message',
    });

    dispatchToast({
      text: messageError,
    });
  } finally {
    options.tryAfter?.();
  }
};
