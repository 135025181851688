import { BoxInfo, GridGroup } from '@/components';
import React, { Dispatch, SetStateAction } from 'react';
import useCadastralPositionFilters from '@/modules/cadastralPosition/hooks/useCadastralPositionFilters';
import { CADASTRAL_POSITION_LIST_FILTERS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';

interface FieldOpcions {
  type: CADASTRAL_POSITION_LIST_FILTERS;
  size: number;
}

interface ContractFiltersProps {
  fields: FieldOpcions[];
  setFilters: Dispatch<SetStateAction<ParamsToFilter>>;
  filters: ParamsToFilter;
}

const CadastralPositionFilters: React.FC<ContractFiltersProps> = ({
  fields,
  setFilters,
  filters,
}) => {
  const { fieldsToChoose } = useCadastralPositionFilters({ setFilters, filters });

  return (
    <BoxInfo>
      <GridGroup
        body={fields.map((field) => ({
          ...fieldsToChoose[field.type],
          default: field.size,
        }))}
      />
    </BoxInfo>
  );
};

export default CadastralPositionFilters;
