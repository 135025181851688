import {
  CREATION_MOVE_FILE_HEADER,
  FIRST_LINES_CREATION_MOVE_FILE,
  FIRST_LINES_REMOVAL_MOVE_FILE,
  FIRST_LINES_UPDATE_MOVE_FILE,
  MOVE_FILE_COLUMN_NAME,
  MOVE_FILE_TYPE,
  REMOVAL_MOVE_FILE_HEADER,
  UPDATE_MOVE_FILE_HEADER,
} from '@/pages/moves/shareds/constants';
import { ObjectType } from '@/shareds/types';
import { removeEmptyParams } from '@/utils';
import { format, isValid, parseISO } from 'date-fns';
import { snakeCase } from 'lodash';
import * as parserSheet from 'xlsx';

const parseJsonToSheet = (json: ObjectType[], fileName: string) => {
  const sheet = parserSheet.utils.json_to_sheet(json);
  const wb = parserSheet.utils.book_new();
  parserSheet.utils.book_append_sheet(wb, sheet);

  const opts: parserSheet.WritingOptions = { bookType: 'xls', type: 'array' };
  const fileUpload = parserSheet.write(wb, opts);
  return new File([fileUpload], `${fileName}.xls`, { type: 'application/vnd.ms-excel' });
};

const transformGender = (dataItem: ObjectType) =>
  dataItem[MOVE_FILE_COLUMN_NAME.SEXO]
    ? {
        ...dataItem,
        [MOVE_FILE_COLUMN_NAME.SEXO]:
          dataItem[MOVE_FILE_COLUMN_NAME.SEXO].toUpperCase() === 'M' ? 'MASCULINO' : 'FEMININO',
      }
    : dataItem;

const formatDatesInDataItem = (dataItem: ObjectType) => {
  const transformedDates = [
    MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO,
    MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO,
    MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO,
    MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA,
    MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO,
    MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO,
  ].reduce((acc, DateColumnName) => {
    const parsedDate = parseISO(dataItem[DateColumnName]);
    if (!isValid(parsedDate)) {
      return acc;
    }
    const transformedDate = format(parsedDate, 'dd/MM/yyyy');

    return {
      ...acc,
      [DateColumnName]: transformedDate,
    };
  }, {});

  return {
    ...dataItem,
    ...transformedDates,
  };
};

const setFirstLines = (data: ObjectType[], type: MOVE_FILE_TYPE) => {
  const firstLines = {
    [MOVE_FILE_TYPE.CREATION]: FIRST_LINES_CREATION_MOVE_FILE,
    [MOVE_FILE_TYPE.UPDATE]: FIRST_LINES_UPDATE_MOVE_FILE,
    [MOVE_FILE_TYPE.REMOVAL]: FIRST_LINES_REMOVAL_MOVE_FILE,
  }[type];

  return [...firstLines, ...data];
};

const fitDataInColumns = (data: ObjectType[], type: MOVE_FILE_TYPE) => {
  const headerColumnNames: string[] = {
    [MOVE_FILE_TYPE.CREATION]: CREATION_MOVE_FILE_HEADER,
    [MOVE_FILE_TYPE.UPDATE]: UPDATE_MOVE_FILE_HEADER,
    [MOVE_FILE_TYPE.REMOVAL]: REMOVAL_MOVE_FILE_HEADER,
  }[type];

  return data.map((item) =>
    headerColumnNames.reduce(
      (acc, columnName) => ({
        ...acc,
        [columnName]: item[columnName],
      }),
      {},
    ),
  );
};

const translateTypeName = (type: MOVE_FILE_TYPE) =>
  ({
    [MOVE_FILE_TYPE.CREATION]: 'CRIACAO_UNITARIA',
    [MOVE_FILE_TYPE.UPDATE]: 'ALTERACAO_UNITARIA',
    [MOVE_FILE_TYPE.REMOVAL]: 'EXCLUSAO_UNITARIA',
  })[type] || type;

export const mountFile = (data: ObjectType[], type: MOVE_FILE_TYPE, lifeName: string) => {
  const timestamp = new Date().getTime();
  const typeName = translateTypeName(type);
  const fileName = `${typeName}-${snakeCase(lifeName.trim())}-${timestamp}`.toUpperCase();
  const dataWithoutEmptyFields = data.map(removeEmptyParams);
  const dataWithFormatedDates = dataWithoutEmptyFields.map(formatDatesInDataItem);
  const dataWithFormatedGender = dataWithFormatedDates.map(transformGender);
  const dataWithFirstLines = setFirstLines(dataWithFormatedGender, type);
  const dataFittedInColumns = fitDataInColumns(dataWithFirstLines, type);

  return parseJsonToSheet(dataFittedInColumns, fileName);
};
