import React, { ReactNode, useCallback, useMemo } from 'react';

import { BoxInfo, ShowHide } from '@/components';
import { debounce } from 'lodash';
import { Button, TextField } from 'vkit/lib/components';

import { SelectNegotiationsByBenefit } from '@/modules/negotiation';
import { Grid, Row } from 'vkit/lib/context';
import { SelectOwnership, SelectStatus } from '../../components';
import {
  BENEFICIARY_EXTRA_FILTERS,
  BENEFICIARY_MAIN_FILTERS,
  BeneficiaryFilterKeys,
  BeneficiarySearchParams,
} from '../../entities';

interface FiltersProps {
  hideExtraFilters?: boolean;
  mainFilters?: BENEFICIARY_MAIN_FILTERS[];
  extraFilters?: BENEFICIARY_EXTRA_FILTERS[];
  values: BeneficiarySearchParams;
  handleChange: (params: Partial<BeneficiarySearchParams>) => void;
  showMoreFilters?: boolean;
  setShowMoreFilters: (showMoreFilters: boolean) => void;
}

const Filters: React.FC<FiltersProps> = ({
  hideExtraFilters,
  extraFilters,
  mainFilters,
  values,
  handleChange,
  showMoreFilters,
  setShowMoreFilters,
}) => {
  const setButtonIconColor = useMemo(
    () => (showMoreFilters ? 'light' : 'clear'),
    [showMoreFilters],
  );
  const setButtonLabel = useMemo(
    () => (showMoreFilters ? 'Menos filtros' : 'Mais filtros'),
    [showMoreFilters],
  );

  const handleChangeFilters = useCallback(
    (filter: BeneficiaryFilterKeys, value?: any) => {
      handleChange({
        ...values,
        [filter]: value,
        page: 1,
      });
    },
    [handleChange, values],
  );

  const handleChangeBenefit = useCallback(
    (benefit: string, negotiationId: string[]) => {
      handleChange({
        ...values,
        benefit,
        negotiationId,
        page: 1,
      });
    },
    [handleChange, values],
  );

  const MAIN_FILTERS: Record<string, ReactNode> = {
    [BENEFICIARY_MAIN_FILTERS.NAME]: (
      <TextField
        key={BENEFICIARY_MAIN_FILTERS.NAME}
        iconDir='right'
        icon='search-outline'
        label='Nome'
        value={values?.['name']}
        onChange={debounce((value: string) => handleChangeFilters('name', value), 1000)}
        small
        clearable
      />
    ),
    [BENEFICIARY_MAIN_FILTERS.CPF]: (
      <TextField
        key={BENEFICIARY_MAIN_FILTERS.CPF}
        mask='999.999.999-99'
        iconDir='right'
        icon='search-outline'
        label='CPF'
        value={values?.['cpf']}
        onChange={debounce((value) => handleChangeFilters('cpf', value), 1000)}
        small
        clearable
      />
    ),
    [BENEFICIARY_MAIN_FILTERS.STATUS]: (
      <SelectStatus
        key={values?.['status']}
        onChange={(value) => handleChangeFilters('status', value)}
        value={values?.['status'] || ''}
      />
    ),
  };

  const EXTRA_FILTERS: Record<string, ReactNode> = {
    [BENEFICIARY_EXTRA_FILTERS.OWNERSHIP]: (
      <SelectOwnership
        key={values?.['type'] || BENEFICIARY_EXTRA_FILTERS.OWNERSHIP}
        value={values?.['type'] || ''}
        onChange={(value) => handleChangeFilters('type', value)}
      />
    ),
    [BENEFICIARY_EXTRA_FILTERS.HEALTH_CARD]: (
      <TextField
        key={BENEFICIARY_EXTRA_FILTERS.HEALTH_CARD}
        iconDir='right'
        icon='search-outline'
        label='Carteirinha'
        value={values?.['healthCard']}
        onChange={debounce((value) => handleChangeFilters('healthCard', value), 1000)}
        small
        clearable
      />
    ),
    [BENEFICIARY_EXTRA_FILTERS.BENEFIT]: (
      <SelectNegotiationsByBenefit
        key={values?.['benefit'] || BENEFICIARY_EXTRA_FILTERS.BENEFIT}
        value={values?.['benefit']}
        onChangeSelected={handleChangeBenefit}
      />
    ),
  };

  const getMainFilters = () => {
    if (!mainFilters) {
      return Object.values(MAIN_FILTERS).map((filter) => filter);
    }
    return Object.keys(MAIN_FILTERS).filter((key: string) => MAIN_FILTERS[key]);
  };

  const getExtraFilters = () => {
    if (!extraFilters) {
      return Object.values(EXTRA_FILTERS).map((filter) => filter);
    }
    return Object.keys(EXTRA_FILTERS).filter((key: string) => MAIN_FILTERS[key]);
  };

  return (
    <BoxInfo>
      <Grid gap={16} alignItems='bottom' alignContent='justify'>
        {getMainFilters()}

        {hideExtraFilters !== true && (
          <Grid>
            <Button
              iconColor={setButtonIconColor}
              onClick={() => setShowMoreFilters(!showMoreFilters)}
              label={setButtonLabel}
              icon='options-2-outline'
              color='light'
              invertColor={showMoreFilters}
            />
          </Grid>
        )}
      </Grid>
      <ShowHide visible={showMoreFilters} transition='slideToDown'>
        <Row style={{ padding: '16px 0 0' }}>
          <Grid gap={16} alignItems='bottom' alignContent='justify'>
            {getExtraFilters()}
          </Grid>
        </Row>
      </ShowHide>
    </BoxInfo>
  );
};

export default Filters;
