import { ContentListLines } from '@/components/dataTable/components';
import React from 'react';

export interface LoadingProps {
  isLoadingReport?: boolean;
  numberLinesLoader?: number;
}

const Loading: React.FC<LoadingProps> = ({ isLoadingReport, numberLinesLoader = 5 }) => {
  return (
    <>
      {isLoadingReport && (
        <ContentListLines
          columns={[{}]}
          linesLoader={numberLinesLoader}
          loading={isLoadingReport}
          data={[]}
        />
      )}
    </>
  );
};

export default Loading;
