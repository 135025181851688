import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '@/../package.json'

const {
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN
} = process.env;

export const init = () => {
  if (!REACT_APP_DATADOG_APPLICATION_ID || !REACT_APP_DATADOG_CLIENT_TOKEN) {
    return
  }

  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'sov-v2-app-companies-browser',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    traceContextInjection: 'all',
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [/empresas\.vitta\.com\.br/],
  });

  datadogLogs.init({
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'sov-v2-app-companies-browser',
    env: process.env.NODE_ENV,
  });
}
