import { ShowHide } from '@/components';

import {
  BeneficiaryDetailsActions,
  BeneficiaryInfo,
  BenefitData,
  LifeAddresses,
  LifeContacts,
  LifeInformation,
  RelatedBeneficiaries,
} from '@/modules/beneficiary/components';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { useBeneficiary } from '../../hooks';

interface DetailsProps {
  holderId?: string;
}

const Details: React.FC<DetailsProps> = ({ holderId }) => {
  const { beneficiaryId } = useHistoryParams();

  const [currentTab, setCurrentTab] = useState<number>(0);

  const { isRemovable, beneficiary, negotiation, benefit, isLoading } = useBeneficiary(
    beneficiaryId || holderId,
  );

  const navigate = useHistoryNavigator();
  const { company } = useCompany();
  const [currentCompanyId] = useState(company.id);

  const getHolderId = useMemo(() => {
    if (beneficiary?.isHolder && beneficiary?.id) {
      return beneficiary.id;
    }
  }, [beneficiary?.id, beneficiary?.isHolder]);

  useEffect(() => {
    if (company.id && currentCompanyId && company.id !== currentCompanyId) {
      navigate.push(`/companies/${company.id}/beneficiaries`);
    }
  }, [company.id, currentCompanyId, navigate]);

  return (
    <>
      {isLoading && (
        <Loader color='colorDefault' full label='Estamos buscando as informações do beneficiário!' />
      )}

      {!isLoading && beneficiary?.id && (
        <>
          <Row style={{ padding: 0 }}>
            <Grid alignContent='justify' column gap={24}>
              <BeneficiaryInfo
                isHolder={beneficiary?.isHolder}
                ownership={beneficiary?.ownership}
                name={beneficiary?.life.name}
                benefit={benefit?.name}
                status={beneficiary?.status}
              />

              <BeneficiaryDetailsActions
                isLoading={isLoading}
                isHolder={beneficiary?.isHolder}
                showUpdateAndRemoveActions={!holderId}
                disableUpdateAction={!beneficiary?.id || !negotiation}
                disableRemoveAction={!isRemovable}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            </Grid>
          </Row>

          <ShowHide visible={currentTab === 0}>
            <LifeInformation life={beneficiary?.life} />
          </ShowHide>

          <ShowHide visible={currentTab === 1}>
            <LifeAddresses life={beneficiary?.life} />
          </ShowHide>

          <ShowHide visible={currentTab === 2}>
            <LifeContacts life={beneficiary?.life} />
          </ShowHide>

          <ShowHide visible={currentTab === 3}>
            <BenefitData negotiation={negotiation} beneficiary={beneficiary} />
          </ShowHide>

          <ShowHide visible={currentTab === 4}>
            {currentTab === 4 && (
              <RelatedBeneficiaries
                beneficiaryId={
                  beneficiary?.isHolder ? getHolderId : beneficiary?.beneficiaryHolder.holderId
                }
                handleChangeTabs={setCurrentTab}
                isHolder={beneficiary?.isHolder}
              />
            )}
          </ShowHide>
        </>
      )}
    </>
  );
};

export default Details;
