import { useCallback, useEffect, useRef } from 'react';

type RuleNames =
  | 'atLeast8Chars'
  | 'upperCaseLetters'
  | 'lowerCaseLetters'
  | 'numbers'
  | 'especialChars'
  | 'noSpace';

interface UseCheckPasswordProps {
  password: string;
  onChange?: (isValid: boolean) => void;
}

interface Check {
  text: string;
  ruleName: RuleNames;
}

interface IUseCheckPassword {
  useChecks: Check[];
  checkPassword: (ruleName: RuleNames, password: string) => boolean;
}

const useCheckPassword = ({ password, onChange }: UseCheckPasswordProps): IUseCheckPassword => {
  const useChecks = useRef<Check[]>([
    {
      text: 'Pelo menos 8 caracteres',
      ruleName: 'atLeast8Chars',
    },
    {
      text: 'Pelo menos 1 letra maiúscula',
      ruleName: 'upperCaseLetters',
    },
    {
      text: 'Pelo menos 1 letra minuscula',
      ruleName: 'lowerCaseLetters',
    },
    {
      text: 'Pelo menos 1 número',
      ruleName: 'numbers',
    },
    {
      text: 'Pelo menos 1 caracter especial',
      ruleName: 'especialChars',
    },
    {
      text: 'Sem espaço',
      ruleName: 'noSpace',
    },
  ]);

  const ruleRegexs = useRef({
    atLeast8Chars: /.{8}/,
    upperCaseLetters: /[A-Z]/,
    lowerCaseLetters: /[a-z]/,
    numbers: /[\d]/,
    especialChars: /[!@#$%^&*()\-_+.]/,
    noSpace: /^\S*$/,
  });

  const checkPassword = useCallback(
    (ruleName: RuleNames, password: string): boolean => ruleRegexs.current[ruleName].test(password),
    [],
  );

  useEffect(() => {
    const isPasswordValid = Object.keys(ruleRegexs.current).every((ruleName) =>
      checkPassword(ruleName as RuleNames, password),
    );

    onChange?.(isPasswordValid);
  }, [password, checkPassword, onChange]);

  return {
    useChecks: useChecks.current,
    checkPassword,
  };
};

export default useCheckPassword;
