import { PARAMS_FROM_FORM_INITIAL_STATE } from '@/modules/financialAdjustment/entities/financialAdjustment.const';
import {
  FinancialAdjustmentToTable,
  ParamsToCrateFinancialAdjustment,
} from '@/modules/financialAdjustment/entities/financialAdjustment.interface';
import { addMonths, format, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface UseProcessParamsFromFormProps {
  processParamsFromFormCallback: (financialAdjustments: FinancialAdjustmentToTable[]) => void;
  companyGroupId: string;
}

export const useProcessParamsFromForm = ({
  processParamsFromFormCallback,
  companyGroupId,
}: UseProcessParamsFromFormProps) => {
  const [paramsFromForm, setParamsFromForm] = useState<ParamsToCrateFinancialAdjustment>(
    PARAMS_FROM_FORM_INITIAL_STATE,
  );
  const [processKey, setProcessKey] = useState(uuid());

  const normalizeFinancialAdjustmentToTable = useCallback(
    (params: ParamsToCrateFinancialAdjustment, countMonth: number) => {
      const date = parseISO(params.date);

      return {
        key: uuid(),
        description: params.description,
        value: params.portionType === 'charge' ? Math.abs(params.value) : -Math.abs(params.value),
        date: format(addMonths(date, countMonth), 'yyyy-MM-dd'),
        unit: params.unit,
        contractId: params.contractId,
        contract: params.contract,
        companyGroupId,
      } as FinancialAdjustmentToTable;
    },
    [companyGroupId],
  );

  const processParamsFromForm = useCallback(
    (manyParamsFromForm: ParamsToCrateFinancialAdjustment) => {
      const financialAdjustments = Array.from({ length: manyParamsFromForm.countMonths || 1 }).map(
        (_, countMonth) => normalizeFinancialAdjustmentToTable(manyParamsFromForm, countMonth),
      );

      processParamsFromFormCallback(financialAdjustments);
      setParamsFromForm(PARAMS_FROM_FORM_INITIAL_STATE);
      setProcessKey(uuid());

      return financialAdjustments;
    },
    [normalizeFinancialAdjustmentToTable, processParamsFromFormCallback],
  );

  return {
    processParamsFromForm,
    paramsFromForm,
    setParamsFromForm,
    processKey,
  };
};
