import ICONS from '@/shareds/constants/icons';

import { APPLICATION_GROUP_BY, Route } from '@/core/entities';

import { Details, List, Plan } from '../pages';
import AdditionalServices from '@/modules/additionalServices/pages/additionalServices';

import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import Home from '../pages/home/home';

export enum ROUTE_CONTRACT_NAME {
  CONTRACT_LIST = 'contract-list',
  CONTRACT_DETAILS = 'contract-details',
  CONTRACT_DETAILS_PLAN = 'contract-details-plan',
  ADDITIONAL_SERVICES = 'additional-services',
}

export enum ROUTE_SVA_NAME {
  SVA_LIST = 'sva-list',
}

const contractRoutes: Route[] = [
  {
    path: '/companies/:companyId/contracts',
    Component: Home,
    id: ROUTE_CONTRACT_NAME.CONTRACT_LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      nameMenu: 'Contratos',
      customImageUrl: ICONS.CONTRACT,
    },
    loader() {
      return createRouteLoaderPageDetails({
        title: 'Contratos',
      });
    },
    children: [
      {
        index: true,
        Component: List,
      },

      {
        Component: Details,
        id: ROUTE_CONTRACT_NAME.CONTRACT_DETAILS,
        path: ':contractId',
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Detalhes do contrato',
            customImageUrl: ICONS.CONTRACT,
            hasGoBackAction: true,
            title: 'Contratos | Detalhes',
          });
        },
      },
      {
        Component: Plan,
        id: ROUTE_CONTRACT_NAME.CONTRACT_DETAILS_PLAN,
        path: ':contractId/plan',
        loader() {
          return createRouteLoaderPageDetails({
            name: 'Detalhes do plano',
            customImageUrl: ICONS.PLAN,
            hasGoBackAction: true,
            title: 'Contratos | Planos',
          });
        },
      },
      {
        Component: AdditionalServices,
        id: ROUTE_SVA_NAME.SVA_LIST,
        path: 'svas',
        loader() {
          return createRouteLoaderPageDetails({
            title: 'Serviços Adicionais',
          });
        },
      },
    ],
  },
];

export default contractRoutes;
