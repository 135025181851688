import restfulBase from '@/services/accessControl/base';

export interface AuthLoginTotp {
  email: string;
  password: string;
  otp: string;
}

export interface AuthLoginTotpResponse {
  token: string;
}

export const authLoginTotpInitState = {
  email: '',
  password: '',
  otp: '',
};

export const authLoginTotpService = restfulBase<AuthLoginTotp, AuthLoginTotpResponse>(
  'auth/login/otp',
);
