import React from 'react';

import { TextField } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import { BoxInfo, TextFieldDebounce } from '@/components';

import { SelectType } from '../../components';
import {
  MOVE_LIST_FILTERS,
  MoveListFiltersCollection,
  MoveParams,
  MoveTypeCollection,
} from '../../entities';

export interface FiltersProps {
  fields?: MoveListFiltersCollection;
  values?: MoveParams;
  setValues: (params: Partial<MoveParams>) => void;
}

const Filters: React.FC<FiltersProps> = ({ fields, values, setValues }) => {
  const handleChangeTextParam = (value: string) => {
    setValues({
      ...values,
      page: 1,
      q: value || '',
    });
  };

  const handleChangeType = (value: MoveTypeCollection) => {
    setValues({
      ...values,
      page: 1,
      type: value,
    });
  };

  const handleChangeDate = (value: string) => {
    setValues({
      ...values,
      page: 1,
      createdAtMin: value || undefined,
    });
  };

  const AVALIBLE_FILTERS = {
    [MOVE_LIST_FILTERS.SEARCH]: {
      component: (
        <TextFieldDebounce
          key='search'
          clearable
          small
          iconDir='right'
          icon='search-outline'
          label='Buscar'
          value={values?.q}
          onChange={handleChangeTextParam}
        />
      ),
    },
    [MOVE_LIST_FILTERS.TYPE]: {
      component: <SelectType key={'type'} value={values?.type} setValue={handleChangeType} />,
    },
    [MOVE_LIST_FILTERS.DATE_MIN]: {
      component: (
        <TextField
          key='date_min'
          clearable
          small
          label='Data'
          value={values?.createdAtMin}
          onChange={handleChangeDate}
          type='date'
        />
      ),
    },
  };

  const useFields = () => {
    if (!fields) {
      return Object.values(AVALIBLE_FILTERS).map((item) => item.component);
    }
    return fields.map((field) => AVALIBLE_FILTERS[field].component);
  };

  return (
    <BoxInfo>
      <Grid gap={24}>{useFields()}</Grid>
    </BoxInfo>
  );
};

export default Filters;
