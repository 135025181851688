import { BoxInfo } from '@/components';
import React, { useEffect, useState } from 'react';
import { Text, Toggle } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import { ReportList } from '..';
import {
  MOVE_SHEET_INITIAL_PAGE,
  MOVE_SHEET_PAGE_SIZE_DEFAUlT,
  MoveSheetParams,
} from '../../entities';

interface ReportProps {
  moveId?: string;
}

export const Report: React.FC<ReportProps> = ({ moveId }) => {
  const [filters, setFilters] = useState<MoveSheetParams>({});

  const handleChangeToggleFilter = () => {
    const onlyErrors = filters?.onlyErrors === 1 ? undefined : 1;
    setFilters({
      ...filters,
      onlyErrors,
    });
  };

  useEffect(() => {
    if (!moveId) {
      return;
    }
    setFilters({
      onlyErrors: 1,
      fileId: moveId,
      page: MOVE_SHEET_INITIAL_PAGE,
      pageSize: MOVE_SHEET_PAGE_SIZE_DEFAUlT,
      sort: 'line',
    });
  }, [moveId]);

  return (
    <BoxInfo
      icon='trending-up-outline'
      title='Validação do arquivo'
      header={
        <Grid row margin='0 0 8px' alignItems='center' gap={12}>
          <Toggle
            checked={filters?.onlyErrors === 1}
            handleChange={handleChangeToggleFilter}
            small
          />
          <Text size='medium' margin='0 0 0 8px' value='Mostrar apenas linhas com erro.' />
        </Grid>
      }>
      <ReportList filters={filters} handleFilters={setFilters} />
    </BoxInfo>
  );
};
