import { DataList, Space } from '@/components';
import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames, getLines } from '@/pages/moves/upload/utils/utils';
import React from 'react';
import { MovesValidationError, MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import translateRulesValidator from '@/utils/translateRulesValidator';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { CADASTRAL_POSITION_UPLOAD_STEPS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';

export interface ErrosProps {
  errors: MovesValidationError;
  setCurrentStep: (step: CADASTRAL_POSITION_UPLOAD_STEPS) => void;
}

const Errors: React.FC<ErrosProps> = ({ errors, setCurrentStep }) => {
  return (
    <>
      <Text
        applyBaseColor
        color='danger'
        fontWeight='bold'
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size='larger'
        value={`Ops! Não conseguimos processar o arquivo de posição cadastral. 
          Detectamos algumas informações incorretas ou ausentes nos campos destacados na tabela abaixo. 
          Por favor, verifique os seguintes campos abaixo e tente novamente.`}
      />

      <DataList
        noPagination
        elevation={4}
        data={errors.details}
        columns={[
          {
            darken: true,
            title: 'Linha',
            value: getLines,
          },
          {
            darken: true,
            title: 'Coluna',
            value: getFieldNames,
          },
          {
            darken: true,
            title: 'Valor encontrado',
            value: 'value',
          },
          {
            darken: true,
            title: 'Valor esperado/Problema',
            value: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
              translateRulesValidator(movesValidationErrorDetail.rule, movesValidationErrorDetail.details?.expected)
              || getErrorDescription(movesValidationErrorDetail),
          },
        ]}
      />

      <Space horizontal />

      <Grid alignContent='right'>
        <Button
          label='Enviar novo arquivo'
          onClick={() => setCurrentStep(CADASTRAL_POSITION_UPLOAD_STEPS.FORM)}
        />
      </Grid>
    </>
  );
};

export default Errors;
