import { Company } from '@/modules/company';
import { COMPANY_COLUMNS } from '@/modules/company/entities/company.enum';
import useCompanyColumns from '@/modules/company/hooks/useCompanyColumns';
import React, { useCallback } from 'react';

import { DataTable } from 'vkit/lib/components';

interface TableProps {
  columns?: COMPANY_COLUMNS[];
  linesLoader?: number;
  companies: Company[];
  loading: boolean;
}

const CompanyTable: React.FC<TableProps> = ({ columns, linesLoader = 5, companies, loading }) => {
  const { useColumns } = useCompanyColumns({
    columns,
  });

  const normalizedCompanies = useCallback((companies: Company[]) => {
    return companies.map((company: Company) => ({
      ...company,
    }));
  }, []);

  return (
    <DataTable
      loadingLinesNumber={linesLoader}
      columns={useColumns}
      data={normalizedCompanies(companies)}
      isLoading={loading}
      showPagination={false}
    />
  );
};

export default CompanyTable;
