import { useCurrentUser } from '@/modules/profile';
import { BasePagination } from '@/shared/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { USER_PERMISSIONS, USER_TYPE, User, UserSearchParams } from '../../entities';
import { UserService } from '../../services';

interface UseUsersProps {
  type: `${USER_TYPE}`;
  filters?: Partial<UserSearchParams>;
}

const { REACT_APP_BROKER_ID = '' } = process.env;

const userService = new UserService();

const useUsers = ({ type, filters }: UseUsersProps) => {
  const { checkUserHasPermission } = useCurrentUser();
  const { company } = useCompany();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState<Partial<BasePagination>>();

  const scopeId = useMemo(() => {
    return type === USER_TYPE.BROKER ? REACT_APP_BROKER_ID : company?.id || '';
  }, [company?.id, type]);

  const hasPermission = useMemo(() => {
    if (type === USER_TYPE.BROKER) {
      return checkUserHasPermission(USER_PERMISSIONS.BROKER_USER_READ);
    }
    return checkUserHasPermission(USER_PERMISSIONS.COMPANY_USER_READ);
  }, [checkUserHasPermission, type]);

  const loadUsers = useCallback(async () => {
    if (!type || !scopeId || !hasPermission) {
      return;
    }

    setUsers([]);

    const mergeFixedParams: Partial<UserSearchParams> = {
      ...filters,
      type,
      scopeId,
    };
    if (mergeFixedParams.status === 'all' || mergeFixedParams.status === 'deleted') {
      mergeFixedParams.withDeleted = true;
    }
    if (mergeFixedParams.status === 'all') {
      delete mergeFixedParams.status;
    }

    setIsLoading(true);

    try {
      const { data, page, pageSize, total, totalPages } =
        await userService.getUsers(mergeFixedParams);
      setUsers(data);
      setPagination({
        page,
        pageSize,
        total,
        totalPages,
      });
    } catch (e) {
      console.error(e);

      dispatchToast({
        text: 'Ops! Falha ao carregar a lista de usuários.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [filters, hasPermission, scopeId, type]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return {
    isLoading,
    users,
    pagination,
    hasPermission,
  };
};

export default useUsers;
