import React from 'react';
import { FileUpload } from '@/components';
import { Text } from 'vkit/lib/components';
import { ObjectType } from '@/shareds/types';
import { FormDataSteps } from '../../enum/FormDataSteps';

type UploadFileProps = {
  isAttachment?: boolean;
  setData: (key: string, value: any) => void;
};

type FileUploadConfig = {
  name: string;
  extensions: string[];
};

const SelectType: React.FC<UploadFileProps> = ({ isAttachment, setData }) => {
  const [fileUploadConfig] = React.useState<FileUploadConfig>({
    name: isAttachment ? FormDataSteps.attachment : FormDataSteps.files,
    extensions: isAttachment ? ['zip'] : ['xls', 'xlsx'],
  });

  const useTitle = React.useMemo((): string => {
    return isAttachment ? 'Selecione o arquivo de anexo' : 'Selecione a planilha correspondente';
  }, [isAttachment]);

  const useTextHelper = React.useMemo((): string => {
    return isAttachment
      ? 'o arquivo de anexo a sua movimentação <br /> <b> para atualização.</b>'
      : 'o arquivo de movimentação <br /> <b> para atualização.</b>';
  }, [isAttachment]);

  const useError = React.useState<ObjectType>({});

  const onChangeFileUpload = (name: string, value: string, isValid: boolean): void => {
    if (isValid) {
      setData(name, value);
    } else {
      setData(name, null);
    }
  };

  return (
    <div>
      <Text value={useTitle} title padding='0 0 18px' />
      <FileUpload
        name={fileUploadConfig.name}
        textHelper={useTextHelper}
        allowedExtensions={fileUploadConfig.extensions}
        onChange={onChangeFileUpload}
        formValidatorErrors={useError}
      />
    </div>
  );
};

export default SelectType;
