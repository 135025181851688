import { useNegotiations } from '@/modules/negotiation/hooks';
import React, { useMemo } from 'react';
import { Select } from 'vkit/lib/components';
import DataType from 'vkit/lib/components/webform/select/types/data.type';
import { Benefit, Negotiation } from '../../entities';

export interface SelectNegotiationsByBenefitProps {
  value?: string;
  onChangeBenefit?: (beneditId: string) => void;
  onChangeNegotiationIds?: (negotiationIds: string[]) => void;
  disabled?: boolean;
  onChangeSelected?: (beneditId: string, negotiationIds: string[]) => void;
}

const SelectNegotiationsByBenefit: React.FC<SelectNegotiationsByBenefitProps> = ({
  value,
  disabled,
  onChangeBenefit,
  onChangeNegotiationIds,
  onChangeSelected,
}) => {
  const { benefits, getNegotiationsIdByBenefitId } = useNegotiations();

  const benefitsDataType = useMemo((): DataType[] | null => {
    if (!benefits || !benefits.length) {
      return null;
    }
    return benefits.map(
      (benefit: Benefit): DataType => ({
        text: benefit.name || '',
        value: benefit.id || '',
      }),
    );
  }, [benefits]);

  const onChangeSelectBenefit = (value: string) => {
    const negotiationIds = getNegotiationsIdByBenefitId(value).map(
      (negotiation: Negotiation) => negotiation.id,
    ) as string[];

    onChangeNegotiationIds?.(negotiationIds);
    onChangeBenefit?.(value);
    onChangeSelected?.(value, negotiationIds);
  };

  return (
    <Select
      data={benefitsDataType}
      onChange={onChangeSelectBenefit}
      value={value || ''}
      small
      label='Benefício'
      clearable
      disabled={disabled}
    />
  );
};

export default SelectNegotiationsByBenefit;
