import UserService from '@/modules/user/services/userService';
import { useHistoryNavigator } from '@/navigation';
import {
  authLoginMessageErrorByMessageErrorRequest,
  authLoginService,
} from '@/services/accessControl/endpoints/authLogin';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

import { AUTH_STEP } from '@/modules/authentication/types/authSteps.enum';
import {
  AuthLoginTotp,
  authLoginTotpInitState,
  authLoginTotpService,
} from '@/services/accessControl/endpoints/authLoginTotp';
import { telemetryIdentifyUser } from '@/shared/telemetry/telemetry';
import { getMessageError } from '@/utils/getMessageError';
import { get } from 'lodash';
import Mixpanel from 'mixpanel-browser';

interface IUseAuthLogin {
  isCodeValid: (code: string) => string | null;
  login: (callback?: () => void) => Promise<void>;
  loginCheck: () => Promise<void>;
  setCurrentStep: (step: `${AUTH_STEP}`) => void;
  setData: Function;
  setMessageError: (messageError: string) => void;
  useData: AuthLoginTotp;
  useLoading: boolean;
  useMessageError: string;
  useCurrentStep: `${AUTH_STEP}`;
}

const SIZE_CODE = 6;

const useAuthLogin = (): IUseAuthLogin => {
  const [useData, setData] = useState<AuthLoginTotp>(authLoginTotpInitState);
  const [useLoading, setLoading] = useState(false);
  const [useMessageError, setMessageError] = useState('');
  const [useCurrentStep, setCurrentStep] = useState<`${AUTH_STEP}`>(AUTH_STEP.EMAIL_PASSWORD);
  const navigate = useHistoryNavigator();

  const isCodeValid = (code: string): string | null => {
    if (code.length !== SIZE_CODE) {
      return 'Código inválido';
    }
    return null;
  };

  const login = async (callback?: () => void): Promise<void> => {
    try {
      setLoading(true);
      setMessageError('');
      const { token } = (await authLoginTotpService.create(useData)) || {};
      if (!token) {
        return setMessageError('Falha ao fazer login.');
      }
      localStorage.removeItem('company');
      localStorage.setItem('token', token);

      const { user } = jwtDecode(token) as any;
      const { scopes } = await new UserService().getMe();

      localStorage.setItem('user', JSON.stringify({
        ...user,
        scopes,
      }));

      window.dispatchEvent(new Event('sov-v2:auth'));

      telemetryIdentifyUser({
        id: user.id,
        name: user.name,
        email: user.email,
        type: user.type,
      });

      Mixpanel.identify(user.id);
      Mixpanel.register_once({ TYPE: user.type });
      Mixpanel.people.set_once({
        name: user.name,
        email: user.email,
        type: user.type,
      });

      callback?.();
    } catch (error) {
      const status: number = get(error, 'response.status', 401);
      const dataError = get(error, 'response.data', {
        attempt: null,
        message: 'Invalid credentials',
        statusCode: 401,
        totalAttempts: 3,
      });
      if (dataError?.attempt) {
        setMessageError(`Verifique o código de acesso em seu e-mail ou utilize a opção de reenviar código.
        `);
      } else if (status === 403) {
        navigate.push(`/auth/blocked?otp=${encodeURIComponent(useData.email)}`);
      } else {
        const messageError = getMessageError({
          error,
          messageErrorByMessageErrorRequest: authLoginMessageErrorByMessageErrorRequest,
          messageDefault: 'Falha ao verificar e-mail e senha.',
        });
        setMessageError(messageError);
      }
    } finally {
      setLoading(false);
    }
  };

  const loginCheck = async () => {
    try {
      setLoading(true);
      setMessageError('');
      await authLoginService.create({
        email: useData.email,
        password: useData.password,
      });
      setCurrentStep(AUTH_STEP.OTP);
    } catch (error) {
      const status: number = get(error, 'response.status', 401);
      const details = get(error, 'response.data.details', { type: 'email' });
      const dataError = get(error, 'response.data', {
        attempt: null,
        message: 'Invalid credentials',
        statusCode: 401,
        totalAttempts: 3,
      });
      if (dataError?.attempt) {
        setMessageError(`E-mail e/ou senha inválidos. <br>
          Tentativa ${dataError.attempt} de ${dataError.totalAttempts}, caso tenha esquecido a senha, por favor, utilize o link Esqueceu sua senha!`);
      } else if (status === 403) {
        const query = details.type === 'mfa' ? 'otp' : 'email';
        navigate.push(`/auth/blocked?${query}=${encodeURIComponent(useData.email)}`);
      } else {
        const messageError = getMessageError({
          error,
          messageErrorByMessageErrorRequest: authLoginMessageErrorByMessageErrorRequest,
          messageDefault: 'Falha ao verificar e-mail e senha.',
        });
        setMessageError(messageError);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return {
    isCodeValid,
    login,
    loginCheck,
    setCurrentStep,
    setData,
    setMessageError,
    useCurrentStep,
    useData,
    useLoading,
    useMessageError,
  };
};

export default useAuthLogin;
