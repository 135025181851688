import { debounce } from 'lodash';
import React from 'react';
import { TextField } from 'vkit/lib/components';
import TextFieldType from 'vkit/lib/components/webform/textField/types/textField.type';

export interface TextFieldDebounceProps extends TextFieldType {
  waitTime?: number;
  charLengthMin?: number;
}

const TextFieldDebounce: React.FC<TextFieldDebounceProps> = (props) => {
  const { onChange, waitTime = 500, charLengthMin = 2, ...rest } = props;

  const handleOnChange = debounce((value: string) => {
    if (value.length > charLengthMin || value.length === 0) {
      return onChange?.(value);
    }
  }, waitTime);

  return <TextField {...rest} onChange={handleOnChange} />;
};

export default TextFieldDebounce;
