import React from 'react';
import { Icon } from 'vkit/lib/components';

import style from './style/fileExtensionIcon.module.scss';
import { EXTENSIONS_BY_MIMETYPE, EXTENSIONS_COLOR, Extension, FILES_MIME_TYPE } from './utils';

export type FileExtensionIconSize = 'small' | 'medium' | 'large';

export interface FileExtensionIconProps {
  extension?: Extension;
  mimetype?: keyof typeof FILES_MIME_TYPE;
  size?: FileExtensionIconSize;
}

const FileExtensionIcon: React.FC<FileExtensionIconProps> = ({
  size = 'small',
  extension,
  mimetype,
}) => {
  const getClassnameSize = (): string => {
    return style[size];
  };

  const getIconSize = (): number => {
    return {
      small: 34,
      medium: 54,
      large: 70,
    }[size];
  };

  const classNames = (): string => {
    return `${style.fileExtensionIcon} ${getClassnameSize()}`;
  };

  const getBackgroundColor = () => {
    if (mimetype) {
      return FILES_MIME_TYPE[mimetype];
    }
    if (extension) {
      return EXTENSIONS_COLOR[extension];
    }
  };

  const getExtensionName = () => {
    if (extension) {
      return extension;
    }
    if (mimetype) {
      return EXTENSIONS_BY_MIMETYPE[mimetype];
    }
  };

  return (
    <div className={classNames()}>
      <Icon name='file' size={getIconSize()} />
      <span className={style.fileExtensionText} style={{ background: getBackgroundColor() }}>
        {getExtensionName()}
      </span>
    </div>
  );
};

export default FileExtensionIcon;
