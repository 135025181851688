import { BodyForm } from '@/components/formValidate';
import { Contract, CONTRACT_STATUS } from '@/modules/contract';
import { MultiSelectContractStatusOption } from '@/modules/contract/components';
import { ContractToForm } from '@/modules/contract/views';
import { ObjectType } from '@/shareds/types';
import { Negotiation } from '@/shareds/types/negotiation.type';
import { getToken } from '@/utils/authentication';
import { isBefore, parseISO } from 'date-fns';
import { get, uniqBy } from 'lodash';
import React from 'react';
import SelectValidate, { SelectOption } from '@/components/selectValidate';

interface SelectContractProps extends Partial<BodyForm> {
  small?: boolean;
  required?: boolean;
}

const SelectContract: React.FC<SelectContractProps> = ({
  onBlur,
  onChange,
  useValues,
  useErrors,
  small,
  required,
}) => {
  const extractContractsToForm = (contract: Contract) => {
    const isExperied =
      !!contract?.validUntil && isBefore(parseISO(contract.validUntil), new Date());
    const isCancelled = contract?.status === 'cancelled';

    let statusToForm = CONTRACT_STATUS.ACTIVE;

    if (isExperied) {
      statusToForm = CONTRACT_STATUS.EXPIRED;
    }

    if (isCancelled) {
      statusToForm = CONTRACT_STATUS.CANCELLED;
    }

    return statusToForm;
  };

  const getBenefits = (option: ObjectType) => {
    const negotiations = get(option, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'product.benefit.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'product.benefit.name', ''))
      .join(', ');
  };

  const getProviders = (option: ObjectType) => {
    const negotiations = get(option, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'product.provider.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'product.provider.name', ''))
      .join(', ');
  };

  const getCostCenters = (option: ObjectType) => {
    const negotiations = get(option, 'negotiations', []);
    return uniqBy(negotiations, (negotiation: Negotiation) =>
      get(negotiation, 'costCenter.name', ''),
    )
      .map((negotiation: Negotiation) => get(negotiation, 'costCenter.name', ''))
      .join(', ');
  };

  const getStatus = (contract: ObjectType) => {
    const contractToStatus = {
      ...contract,
      statusToForm: extractContractsToForm(contract as Contract),
    };

    return (
      <MultiSelectContractStatusOption
        option={{ data: contractToStatus } as SelectOption<ContractToForm>}
      />
    );
  };

  return (
    <SelectValidate
      autoRequest
      label='Contrato'
      name='contractId'
      onBlur={onBlur}
      onChange={onChange}
      placeholder='Selecione um contrato'
      requestPageParam='page'
      requestResponseRootPath='data'
      requestResponseText='code'
      requestResponseValue='id'
      requestRouter='/contracts'
      requestSearchParam='q'
      requestUri={process.env.REACT_APP_API_NEGOTIATIONS_URL}
      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
      required={required}
      searchable
      useErrors={useErrors}
      useValues={useValues || {}}
      small={small}
      columns={[
        {
          title: 'Código',
          path: 'text',
        },
        {
          title: 'Benefício',
          path: '',
          custom: getBenefits,
        },
        {
          title: 'Fornecedor',
          path: '',
          custom: getProviders,
        },
        {
          title: 'Centro de custo',
          path: '',
          custom: getCostCenters,
        },
        {
          title: 'Status',
          path: '',
          custom: getStatus,
        },
      ]}
    />
  );
};

export default SelectContract;
