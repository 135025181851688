import ReportPagination, {
  ReportPaginationProps,
} from '@/shared/components/report/components/pagination/pagination';
import { ObjectType } from '@/shareds/types';
import React, { ReactNode, useState } from 'react';
import { Accordion } from 'vkit/lib/components';
import { GroupItemContent, GroupItemContentProps } from '../groupItemContent/groupItemContent';
import { GroupItemHeader, GroupItemHeaderProps } from '../groupItemHeader/groupItemHeader';

export interface GroupItemProps
  extends GroupItemHeaderProps,
    GroupItemContentProps,
    ReportPaginationProps {
  isExpandedGroup?: boolean;
  handleExpandReportGroup?: (isExpanded: boolean, item: any) => Promise<{ content: ObjectType[] }>;
  contentTop?: ReactNode;
  contentFoot?: ReactNode;
  showEmptyList?: boolean;
}

const GroupItem: React.FC<GroupItemProps> = ({
  isExpandedGroup,

  groupTitle,
  showGroupMarker,
  groupMarkerColor,
  groupMarkerText,

  columns,
  contentLinesLoader,
  list,
  showEmptyList,

  contentTop,
  contentFoot,

  showPaginationReport,
  pageSize = 10,
  handleChangePageSize,
  page = 1,
  total = 1,
  handleChangePage,
  totalPages = 1,
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(isExpandedGroup || false);

  const handleExpanded = async (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      full
      box
      expanded={isExpanded}
      handleExpanded={handleExpanded}
      overflowVisibleWhenOpen
      title={
        <GroupItemHeader
          groupTitle={groupTitle}
          showGroupMarker={showGroupMarker}
          groupMarkerColor={groupMarkerColor}
          groupMarkerText={groupMarkerText}
        />
      }>
      {contentTop}

      {(showEmptyList || list.length > 0) && (
        <GroupItemContent list={list} columns={columns} contentLinesLoader={contentLinesLoader} />
      )}

      {contentFoot}

      <ReportPagination
        showPaginationReport={list.length > 0 && showPaginationReport}
        pageSize={pageSize}
        handleChangePageSize={handleChangePageSize}
        page={page}
        total={total}
        handleChangePage={handleChangePage}
        totalPages={totalPages}
      />
    </Accordion>
  );
};

export default GroupItem;
