import { useLoggedUser } from '@/modules/profile';
import { useHistoryParams } from '@/navigation';
import React, { useEffect } from 'react';
import { Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { USER_TYPE, UserPermission, UserPermissionPayload } from '../../entities';
import { BlockPermission, BlockPermissionChildrenList } from './components';
import useFileCategoryPermissions from './hooks/useFileCategoryPermissions';

interface FileCategoryPermissionsProps {
  initialPermissions: UserPermission[];
  updateFilesDirPermissions: (userPermissions: UserPermissionPayload[]) => void;
  isLoading: boolean;
  disabled?: boolean;
  scope: `${USER_TYPE}`;
}

const FileCategoryPermissions: React.FC<FileCategoryPermissionsProps> = ({
  updateFilesDirPermissions,
  initialPermissions,
  isLoading,
  disabled,
  scope,
}) => {
  const { userId } = useHistoryParams();
  const { user } = useLoggedUser();

  const {
    data,
    listUserPermissions,
    handleBlockPermission,
    handleItemPermission,
    shouldDisableCurrentItem,
    shouldDisableParent,
  } = useFileCategoryPermissions({
    userId,
    currentUser: user,
    initialPermissions,
    disabled,
    scope,
  });

  const handleExpanded = (id: string): boolean => {
    const parentBlock = data.find((item) => item.id === id);

    if (parentBlock) {
      return parentBlock.children.some(
        (child) => child.hasAddPermission || child.hasDeletePermission || child.hasViewPermission,
      );
    }

    return false;
  };

  useEffect(() => {
    updateFilesDirPermissions(listUserPermissions);
  }, [listUserPermissions, updateFilesDirPermissions]);

  return data.length ? (
    <>
      <Row style={{ padding: '0 0 12px' }}>
        <Grid gap={8}>
          <Icon name={'file-outline'} color='colorDefault' />
          <Text color='colorDefault' size='small' title value={'Permissões de pastas'} />
        </Grid>
      </Row>

      {data.map((item) => (
        <BlockPermission
          isLoading={isLoading}
          key={item.id}
          shouldDisabled={shouldDisableParent}
          category={item}
          expanded={handleExpanded(item.id)}
          handleBlockPermission={handleBlockPermission}>
          <BlockPermissionChildrenList
            isLoading={isLoading}
            data={item.children}
            parentId={item.id}
            handleItemPermission={handleItemPermission}
            shouldDisable={shouldDisableCurrentItem}
          />
        </BlockPermission>
      ))}
    </>
  ) : null;
};

export default FileCategoryPermissions;
