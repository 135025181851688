import React from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface UserProps {
  userName?: string;
  userEmail?: string;
}

const UserView: React.FC<UserProps> = ({ userName, userEmail }) => {
  return (
    <Grid column gap={4}>
      {userName && <Text value={userName} size='medium' />}
      {userEmail && <Text value={userEmail} size='small' />}
    </Grid>
  );
};

export default UserView;
