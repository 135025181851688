import { beneficiariesService } from '@/services/beneficiaries';
import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';

export const getBeneficiaryById = async (beneficiaryId: string, companyId: string) => {
  const [beneficiaryFound] = await beneficiariesService.list({
    id: beneficiaryId,
    companyId,
  });

  return beneficiaryFound as Beneficiary;
};
